<div>
  <div>
    <div class="container">
      <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
      <div class="logoHolder">
              <img src="../../../assets/images/logo.png" class="img-fluid logo" alt="">
      </div>
      <div class="instBox">
          <div class="signUpBox" *ngIf="isNew && !isLoading">
              <h2>Payment Successful!<br><img src="../../../assets/images/icons8-rocket-50.png" alt="">Get - Set - Go!</h2>
              <p>We have set up your account & sent you the password on email. You have the option to reset your password through the link in the email.</p>
              <ul>
                  <li>
                    <i class="fa fa-check" aria-hidden="true"></i><p>   Get matched to the perfect therapist as per your needs from our vast pool</p>
                  </li>
                  <li>
                    <i class="fa fa-check" aria-hidden="true"></i><p>   Schedule video sessions or send messages to your therapist from your personalized dashboard</p>
                  </li>
                  <li>
                    <i class="fa fa-check" aria-hidden="true"></i><p>   We're always by your side! Connect with us over Phone, Whatsapp or Email for any assistance - 24/7!</p>
                  </li>
              </ul>
          </div>
          <div class="signUpBox" *ngIf="!isNew && !isLoading">
            <h2>Payment Successful!<br><img src="../../../assets/images/icons8-rocket-50.png" alt="">Get - Set - Go!</h2>
            <p>Welcome back to Felicity!
                Your plan has been credited to your account.</p>
            <ul>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i><p>   Schedule video sessions or send messages to your therapist from your personalized dashboard</p>
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i><p>   We're always by your side! Connect with us over Phone, Whatsapp or Email for any assistance - 24/7!</p>
                </li>
            </ul>
          </div>
          <div class="letsDo">
              <h3>Let's do this!</h3>
          </div>
          <div class="redirectionBox" *ngIf="isNew && !isLoading">
              <p>Redirecting to dashboord in <strong>{{countDown}} seconds</strong> or click <a href="https://felicity.care/user-dashboard">here.</a></p>
          </div>
          <div class="redirectionBox" *ngIf="!isNew && !isLoading && isLoggedIn">
            <p>Redirecting to dashboord in <strong>{{countDown}} seconds</strong> or click <a href="https://felicity.care/user-dashboard">here.</a></p>
        </div>
        <div class="redirectionBox" *ngIf="!isNew && !isLoading && !isLoggedIn">
            <p>Redirecting to Login in <strong>{{countDown}} seconds</strong> or click <a href="https://felicity.care/login">here.</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
