<div class="bodyPageWrap">
  <div style="display: flex;align-items: center;margin-bottom: 20px;">
    <span><a routerLink="../../client-notes" style="font-size:16px; color: #07006e; cursor: pointer;text-decoration: underline;">Client Notes</a></span>
    <span style="font-size:16px">&nbsp;>&nbsp;</span>
    <span style="font-size:16px; color: #808080;">{{ user?.name ?user?.name + "'s Notes":"fetching notes..."}}</span> 
  </div>
  <div class="welcomeBoxWrap">
    <div class="contentBoxWrap">
      <h3>Track Client Progress By Making Notes For Every Session</h3>
    </div>
    <div class="welcomeImage">
      <img
        src="../../../assets/images/notes.svg"
        class="img-fluid"
        draggable="false"
        alt=""
      />
    </div>
  </div>
  <div class="sectionPadding-top">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="sectionHeading">
          <h3>User Profile</h3>
          <hr />
        </div>
      </div>
    </div>
    <app-user-profile [isClientNotes]="true"></app-user-profile>
  </div>
  <div class="sectionPadding-top" *ngIf="intakeFormData">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="sectionHeading">
          <h3>Therapy Intake Form</h3>
          <hr />
        </div>
      </div>
    </div>
    <div class="intakeFormData">
      <div *ngIf="intakeFormData">
        <div *ngIf="intakeFormData.answer">
          <div class="intakeData" *ngIf="intakeFormData.answer.age">
            <h5>Age</h5> <p>{{intakeFormData.answer.age}}</p>
          </div>
          <div class="intakeData" *ngIf="intakeFormData.answer.gender">
            <h5>Gender</h5> <p>{{intakeFormData.answer.gender}}</p>
          </div>
          <div class="intakeData" *ngIf="intakeFormData.answer.city">
            <h5>City</h5> <p>{{intakeFormData.answer.city}}</p>
          </div>
          <div class="intakeData" *ngIf="intakeFormData.answer.doAtTheMoment">
            <h5>What do you do at the moment?</h5> <p *ngIf="intakeFormData.answer.doAtTheMoment !== 'Others'">{{intakeFormData.answer.doAtTheMoment}}</p>
            <p *ngIf="intakeFormData.answer.doAtTheMoment === 'Others'">{{intakeFormData?.answer?.otherdoAtMoment}}</p>
          </div>
          <div class="intakeData" *ngIf="intakeFormData.answer.therapyBefore">
            <h5>Have you consulted a psychologist or a psychiatrist before?</h5> <p>{{intakeFormData.answer.therapyBefore}}</p>
          </div>
          <div class="intakeData" *ngIf="intakeFormData.answer.medication">
            <h5>Are you on any medication for your mental health?</h5> <p>{{intakeFormData.answer.medication}}</p>
          </div>
          <div class="intakeData" *ngIf="intakeFormData.answer.selfHarm">
            <h5>In the last 14 days have you had any thoughts of harming yourself?</h5> <p>{{intakeFormData.answer.selfHarm}}</p>
          </div>
          <div class="intakeData" *ngIf="intakeFormData.answer.seekingHelpFor">
            <h5>What are you seeking help for?</h5> <p>{{intakeFormData.answer.seekingHelpFor}}</p>
          </div>
          <div class="intakeData" *ngIf="intakeFormData.answer.extraInfo">
            <h5>Is there anything else that you would like your therapist to know before the session?</h5> <p>{{intakeFormData.answer.extraInfo}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sectionPadding-top">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="sectionHeading">
          <h3>Client Notes</h3>
          <hr />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let x of notesData">
        <div class="thoughtboxWrapap">
          <p class="notesBy" *ngIf="x.therapistId">Note added by - {{x.therapistId.firstname}} {{x.therapistId.lastname}}</p>
          <div class="thoughBoxContent" *ngIf="x?.noteType === 'old-data'">
            <p [innerHTML]="x.notes"></p>
            <span [innerHTML]="message"></span>
          </div>
          <div class="thoughBoxContent" *ngIf="x?.noteType === 'later'">
            <p>{{x?.noteObj?.goal}}</p>
          </div>
          <div class="thoughBoxContent" *ngIf="x?.noteType === 'first'">
            <p>{{x?.noteObj?.issues}}</p>
          </div>
          <div class="thoughtButton">
            <span>{{ x.createdAt | date: "dd MMM y" }}</span>
            <ul>
              <li>
                <a (click)="navigateToViewNote(x._id, 'view')"
                  ><i class="fa fa-eye"></i
                ></a>
              </li>
              <li>
                <a (click)="navigateToViewNote(x._id, 'edit')"
                  ><i class="fa fa-edit"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sectionPadding-top" *ngIf="sharedJournal">
    <div class="sectionHeading">
      <h3>Shared Journals</h3>
      <hr />
    </div>
    <div class="row">
      <div
        class="col-lg-4 col-md-6 col-sm-6"
        *ngFor="let i of sharedJournal; let idx = index"
      >
        <div class="thoughtboxWrapap">
          <div class="thoughBoxContent">
            <p [innerHTML]="i.title"></p>
          </div>
          <div class="thoughtButton">
            <span>Entry {{ idx + 1 }}</span>
            <ul>
              <li>
                <a><i class="fa fa-eye"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
