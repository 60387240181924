import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { TherapistAuthService } from './therapist-auth/therapist-auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private therapistAuthService : TherapistAuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getToken();
    const therapistAuthToken = this.therapistAuthService.getToken();
    if(authToken){
      const authRequest = request.clone({
        headers: request.headers.set("Authorization", "Bearer "+ authToken)
      })
      return next.handle(authRequest);
    } else {
          const authRequest = request.clone({
      headers: request.headers.set("Authorization", "Bearer "+ therapistAuthToken)
    })
    return next.handle(authRequest);
    }
  }
}
