import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';

const helpdeskUrl = environment.apiUrl + '/user/helpdesk/'
@Injectable({
  providedIn: 'root'
})
export class HelpDeskService {

  constructor(private http: HttpClient) { }

  createRequest(request){
    return this.http.post(helpdeskUrl + 'create', request);
  }

  requestCallback(){
    return this.http.get(helpdeskUrl + 'request-callback')
  }

  getRequestById(id){
    return this.http.get(helpdeskUrl + 'read/' +id)
  }

  getOldRequests(){
    return this.http.get(helpdeskUrl + 'read-all')
  }

  getReplies(id){
    return this.http.get(helpdeskUrl + 'get-replies/' + id)
  }

  addReply(id, reply){
    return this.http.post(helpdeskUrl + 'reply-to-ticket/' + id, reply)
  }
}
