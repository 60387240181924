import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TherapistHelpdeskService } from "../therapist-helpdesk.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';



@Component({
  selector: "app-view-single-ticket",
  templateUrl: "./view-single-ticket.component.html",
  styleUrls: ["./view-single-ticket.component.css"],
})
export class ViewSingleTicketComponent implements OnInit {
  userData;
  isLoading = false;
  isRequestLoading = true;
  userDataAnswers = [];
  replyForm = new FormGroup({
    response: new FormControl("", Validators.required),
  });
  constructor(
    private actRoute: ActivatedRoute,
    private helpdeskService: TherapistHelpdeskService,
    private confirmationService: ConfirmationDialogService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) { }
  itemid: string;
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit() {
    this.actRoute.queryParamMap.subscribe((params) => {
      this.itemid = params.get("ticketId");
      this.helpdeskService.getRequestById(this.itemid).subscribe((data) => {
        this.userData = data;
        this.isRequestLoading = false;
      });
      this.helpdeskService.getReplies(this.itemid).subscribe((data) => {
        this.userDataAnswers = data["replies"];
        this.isRequestLoading = false;
      });
    }, (error) => {
      this.isRequestLoading = false;
    });
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  onSubmit() {
    if (this.replyForm.invalid) {
      return;
    }
    this.confirmationService
      .confirm(
        "Are you sure?",
        "If the ticket is closed, this will repopen it",
        "Continue",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          this.isLoading = true;
          this.helpdeskService
            .addReply(this.itemid, this.replyForm.value)
            .subscribe((data) => {
              this.isLoading = false;
              this.userDataAnswers.unshift(data);
              this.replyForm.reset()
            }, (err) => {
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
          this.replyForm.reset()
        }

      });
  }
}
