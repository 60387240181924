import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { RegionSelectionService } from "src/app/region-selection.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-how-it-works",
  templateUrl: "./how-it-works.component.html",
  styleUrls: ["./how-it-works.component.css"],
})
export class HowItWorksComponent implements OnInit {
  isLoggedin: boolean = false;
  regionData;
  constructor(
    private sidenavService: SideNavBarService,
    private userAuth: AuthService,
    private metaSeo: SeoMetaService,
    private regionSelectionService: RegionSelectionService
  ) {}

  ngOnInit(): void {
    this.regionSelectionService.getRegionDataListener().subscribe((data) => {
      this.regionData = data;
    })
    this.regionSelectionService.setRegionData(() => {});
    this.isLoggedin = this.userAuth.getIsAuth();
    this.metaSeo.updateTitle(
      "How Online Counseling & Therapy Works | Find a Psychologist | Felicity"
    );
    this.metaSeo.updateMetaInfo(
      "Therapy on-the-go. Get matched with a psychologist by answering a few quick questions. Connect via chat & video on our web or mobile app.",
      "Find a psychologist, how online therapy works, how online counseling works, book counseling session, book therapy session"
    );
    
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
}
