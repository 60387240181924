import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapAlert } from 'src/app/shared/ng-bootstrap-alert/bootstrap-alert';
import { BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { TherapistAuthService } from '../therapist-auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  resetCode = null;
  isValid = false;
  constructor(private authService: TherapistAuthService,
    private alertService: BootstrapAlertService,
    private actRoute: ActivatedRoute,
    private router: Router) { }

    ngOnInit(): void {
      this.initForms();
      this.actRoute.paramMap.subscribe((param) => {
        this.resetCode = param.get("id")
        this.authService.verifyResetCode(this.resetCode).subscribe((res) => {
          if(res){
            this.isValid = true
          }
        })
      })
    }
  
    initForms(){
      this.forgotPassForm = new FormGroup({
        password : new FormControl("", [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$")]),
        confirmPassword : new FormControl("", [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$")])
      })
    }
  
    onSubmit(){
      if(this.forgotPassForm.invalid){
        return;
      } else {
        this.authService.resetPassword({...this.forgotPassForm.value, resetCode : this.resetCode}).subscribe((res) => {
          if(res){
            this.alertService.alert(
              new BootstrapAlert("Your password was reset successfully, taking you to login page", "alert-success")
            );
            setTimeout(
              () => {
                this.router.navigate(['/therapist-login'])
              },2000
            )
            this.forgotPassForm.reset()
          }
        })
      }
    }

}
