// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  /**
   * localhost:3000 local server
   */

  // production: false,
  apiUrl: 'https://api.fuzzycircuit.com',
  socketUrl: 'ws://localhost:3000',
  // socketPath: '/socket.io',
  // base_url: 'http://localhost:4200',
  // RAZORPAY_KEY: "rzp_test_ymFjjc3fYtTVXi",
  // zoomSdkKey: '8b0h09YpXlzOXEkOeTVRRTswLCQzLsSZJNmG',
  // deepLinkUser:'https://felicity.onelink.me/6OmI/26bbb366?type=join_session&sessionId=',
  // deepLinkTherapist:"https://therapist.onelink.me/f0U4/8xcq23dz?type=join_session&sessionId=",
  // firebaseConfig: {
  //   apiKey: "AIzaSyBe4r3nOpOeq0Wg_R3X868r2x0gHjllb6o",
  //   authDomain: "app.fuzzycircuit.com",
  //   projectId: "felicity-3a815",
  //   storageBucket: "felicity-3a815.appspot.com",
  //   messagingSenderId: "61689101115",
  //   appId: "1:61689101115:web:93822d89af9dbfe111551e",
  //   measurementId: "G-N9HF6CLHNB"
  // }

  /**
   * int.fuzzycircuit.com server
   */

  production: false,
  // apiUrl: 'https://int.fuzzycircuit.com',
  // socketUrl: 'wss://int.fuzzycircuit.com/',
  socketPath: '/socket.io',
  base_url: 'https://fuzzycircuit.com',
  RAZORPAY_KEY: "rzp_test_ymFjjc3fYtTVXi",
  zoomSdkKey: '8b0h09YpXlzOXEkOeTVRRTswLCQzLsSZJNmG',
  deepLinkUser: 'https://felicity.onelink.me/6OmI/26bbb366?type=join_session&sessionId=',
  deepLinkTherapist: "https://felicity.onelink.me/6OmI/pa1wsc2o?type=join_session&sessionId=",
  firebaseConfig: {
    apiKey: "AIzaSyBe4r3nOpOeq0Wg_R3X868r2x0gHjllb6o",
    authDomain: "app.fuzzycircuit.com",
    projectId: "felicity-3a815",
    storageBucket: "felicity-3a815.appspot.com",
    messagingSenderId: "61689101115",
    appId: "1:61689101115:web:93822d89af9dbfe111551e",
    measurementId: "G-N9HF6CLHNB"
  }

  /**
   * int.fuzzycircuit.com server
   */

  // production: false,
  // apiUrl: 'https://int.fuzzycircuit.com',
  // socketUrl: 'wss://int.fuzzycircuit.com/',
  // socketPath: '/socket.io',
  // base_url: 'https://fuzzycircuit.com',
  // RAZORPAY_KEY: "rzp_test_ymFjjc3fYtTVXi",
  // zoomSdkKey: '8b0h09YpXlzOXEkOeTVRRTswLCQzLsSZJNmG',
  // firebaseConfig: {
  //   apiKey: "AIzaSyBe4r3nOpOeq0Wg_R3X868r2x0gHjllb6o",
  //   authDomain: "app.fuzzycircuit.com",
  //   projectId: "felicity-3a815",
  //   storageBucket: "felicity-3a815.appspot.com",
  //   messagingSenderId: "61689101115",
  //   appId: "1:61689101115:web:93822d89af9dbfe111551e",
  //   measurementId: "G-N9HF6CLHNB"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.