import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { DeleteAccountDialogComponent } from './delete-account-dialog/delete-account-dialog.component';
@Injectable({
  providedIn: 'root'
})
export class DeleteAccountDialogService {

  constructor(private modalService: BsModalService) { }

  public confirm(title: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel'): Subject<boolean> {
    const modalRef = this.modalService.show(DeleteAccountDialogComponent)
    modalRef.content.title = title;
    modalRef.content.btnCancelText = btnCancelText;
    modalRef.content.btnOkText = btnOkText;
    return modalRef.content.onClose
  }
}
