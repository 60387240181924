import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FreeReportService } from "src/app/guest/free-report.service";
import { ClientNotesService } from "../client-notes.service";
import { TherapistProfileService } from "../therapist-profile.service";

@Component({
  selector: "app-client-notes-view",
  templateUrl: "./client-notes-view.component.html",
  styleUrls: ["./client-notes-view.component.css"],
})
export class ClientNotesViewComponent implements OnInit {
  userId;
  userEmail = "";
  testName = "";
  user = null;
  testResults = null;
  questions = null;
  options = null;
  answers = null;
  answerText = null;
  intakeFormData = null;
  constructor(
    private actRoute: ActivatedRoute,
    private notesService: ClientNotesService,
    private router: Router,
    private therapistProfileService : TherapistProfileService,
    private freeReportService : FreeReportService,
  ) {}
  notesData = null;
  sharedJournal = [];
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((param) => {
      this.userId = param.get("id");
      this.notesService.getNotesByUser(this.userId).subscribe((res) => {
        this.notesData = res;
      });
      this.notesService.getSharedJournals(this.userId).subscribe((res) => {
        this.sharedJournal = res["sharedJournals"];
      });
      this.therapistProfileService
      .getUserProfile(this.userId)
      .subscribe((data) => {
        this.user = data;
        this.userEmail = data["email"];
        this.freeReportService
          .getReportByEmail(this.userEmail)
          .subscribe((report) => {
            this.testResults = report["report"];
          });
        let languageArray = [];
        if (this.user.selectedLanguageArray) {
          this.user.selectedLanguageArray.forEach((element) => {
            languageArray.push(element.language);
          });
          this.user.selectedLanguageArray = languageArray;
        }
      });
      this.therapistProfileService.getUserIntakeForm(this.userId).subscribe((res) => {
        this.intakeFormData = res;
      })
    });
  }

  navigateToViewNote(id, mode) {
    this.router.navigateByUrl(
      "/therapist-dashboard/client-notes-edit/" + id + "?mode=" + mode
    );
  }
}
