import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

const discussionUrl = environment.apiUrl + "/therapist/discussion";
const discussionCommentUrl =
  environment.apiUrl + "/therapist/discussion-comment";
@Injectable({
  providedIn: "root",
})
export class TherapistDiscussionFourmService {
  constructor(private http: HttpClient) {}
  getCategories() {
    return this.http.get(discussionUrl + "/categories");
  }

  createDiscussion(data) {
    return this.http.post(discussionUrl + "/create", data);
  }

  getForums(paginObject) {
    return this.http.post(discussionUrl + "/get-all", paginObject);
  }

  getForumById(id) {
    return this.http.get(discussionUrl + "/get/" + id);
  }

  getDiscussionComments(id) {
    return this.http.get(
      discussionCommentUrl + "/get-comments/" + id + "?limit=10&skip=0"
    );
  }

  postDiscussionComments(id, commentObj) {
    return this.http.post(discussionCommentUrl + "/create/" + id, commentObj);
  }

  updateDiscussionForumById(id, commentObj) {
    return this.http.put(discussionUrl + "/update/" + id, commentObj);
  }

  toggleLikeOnDiscussion(id){
    return this.http.get(discussionUrl + '/like/' + id)
  }
}
