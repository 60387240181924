import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

const userBackendUrl = environment.apiUrl + "/user/video/";
const sessionUrl = environment.apiUrl + "/user/booking/";
const videoCallUrl = environment.apiUrl + '/zoom/';
@Injectable({
  providedIn: "root",
})
export class UserVideoService {
  constructor(private http: HttpClient) { }

  getVideoToken() {
    return this.http.get(userBackendUrl + "video-token");
  }

  setStats(id, data) {
    return this.http.post(sessionUrl + "setStats/" + id, data);
  }

  setUserStartedAt(id) {
    return this.http.get(sessionUrl + "set-user-started/" + id);
  }

  setSessionAsStarted(id) {
    return this.http.get(sessionUrl + "set-session-started/" + id)
  }

  statsLogger(obj) {
    return this.http.post(sessionUrl + "stats-logger", obj)
  }
  getMeetingById(id, email) {
    return this.http.get(videoCallUrl + `retrieveMeeting/${id}/${email}?type=user`)
  }
  getMeetingSignature(id) {
    return this.http.post(videoCallUrl + `generateSignatureToken`, {
      meetingId: id,
      role: 0
    }
    )
  }

  async checkMediaPermissionState(){
    let statusObj = {
      isChrome : false,
      cameraStatus : '',
      micStatus : ''
    };
    if(navigator && navigator.userAgent && navigator.userAgent.indexOf('Chrome') < 0){
      console.log('not chrome')
      statusObj.isChrome = false;
    } else {
      statusObj.isChrome = true;
      let camePermissionStatus = await navigator['permissions'].query({name : 'camera'});
      statusObj.cameraStatus = camePermissionStatus.state;
      console.log('camera status', camePermissionStatus)
      let grantCheck = await new Promise<string>(res => {
        camePermissionStatus.onchange = (_: Event) => {
          statusObj.cameraStatus = _.target['state']
        }
        res(statusObj.cameraStatus);
      })
      let misPermissionStatus = await navigator['permissions'].query({name : 'microphone'});
      console.log('microphone status', misPermissionStatus)
      statusObj.micStatus = misPermissionStatus.state;
      let camGrantCheck = await new Promise<string>(res => {
        misPermissionStatus.onchange = (_: Event) => {
          statusObj.micStatus = _.target['state']
        }
        res(statusObj.micStatus);
      })
    }
    return statusObj;
  }

}
