<div class="bodyPageWrap">
  <div *ngIf="isLoading">
    <div style="display: flex; flex-direction: row">
      <div class="col-lg-6">
        <ngx-skeleton-loader appearance="line" [theme]="{
            'border-radius': '12px',
            height: '25rem',
            width: '47rem',
            display: 'flex !important',
            'margin-top': '15rem',
            'margin-left': '11rem'
          }"></ngx-skeleton-loader>
      </div>

      <div class="col-lg-6">
        <ngx-skeleton-loader appearance="line" [theme]="{
            'border-radius': '12px',
            height: '12rem',
            width: '48rem',
            'margin-top': '19rem',
            'margin-left': '6rem'
          }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <!-- <ng-template #displayNoneShimmer> -->
  <div class="warning-card" *ngIf="status == 'NOT_STARTED'">
    <div class="secPadding">
      <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
      <div class="row align-items-center">
        <div class="col-sm-3">
          <div class="joinBoxImageHolder">
            <img src="../../../assets/images/blue-yellow-session-icon.svg" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-lg-6 col-sm-9">
          <div class="joinBoxTxt">
            <h3>Your upcoming session starts in {{ remainingTimeText }}</h3>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="joinButton">
            <button (click)="doHandleJoinCall()" class="buttonTheme1">
              Join Session
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </ng-template> -->
  <div class="secPadding joinBox" *ngIf="status === 'NOT_FOUND'">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="joinBoxTxt">
          <h3>
            This session does not belong to the logged in user. Make sure you
            are using the correct credentials to login.
          </h3>
        </div>
      </div>
    </div>
  </div>

    <!-- Changed *ngIf condition below -->
  <div class="container-fluid" *ngIf="status == 'ABOUT_TO_START-'">
    <div class="row">
      <div class="col-md-6">
        <div style="
            display: flex;
            align-items: center;
            margin-top: 15px;
            position: relative;
            left: 14.5%;
          ">
          <span><a routerLink="../sessions"><img src="../../../assets/images/video-chat/back.png"
                style="height: 20px; width: 20px" /></a></span>
          <span style="font-size: 24px; margin-left: 20px"><a routerLink="../sessions"
              style="color: black">Session</a></span>
          <!-- <span style="font-size: 16px; color: #808080">Join session</span> -->
        </div>
        <div class="previewBox">
          <div class="videoChatBox">
            <video class="userPreviewVideo" id="inputPreview" autoplay [ngClass]="{ hiddenVideo: !isCameraON }"></video>
            <span class="helperSpan" [ngClass]="{ hiddenVideo: isCameraON }"></span>
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"
              class="img-fluid userPreviewImage" [ngClass]="{ hiddenVideo: isCameraON }" /> -->
            <div class="deviceStatus" *ngIf="
                deviceInitialization == device_status_text.CHECKING ||
                deviceInitialization == device_status_text.CAMERA_NOT_FOUND ||
                deviceInitialization == device_status_text.MIC_NOT_FOUND ||
                deviceInitialization == device_status_text.PERMISSION_DENIED ||
                deviceInitialization == device_status_text.STARTING
              ">
              {{ deviceInitialization }}
            </div>
            <div class="deviceStatus" [hidden]="isCameraON" *ngIf="isCamAvailable">
              CAMERA IS OFF
            </div>
            <div class="joiningAudioControl localVideo" [style.background]="
                isMicON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'
              ">
              <a title="Un-Mute Audio" [hidden]="isMicON" *ngIf="isMicAvailable" style="cursor: pointer"
                (click)="doToggleMic()" class="vOrng"><img src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a title="Mute Audio" [hidden]="!isMicON" *ngIf="isMicAvailable" style="cursor: pointer"
                (click)="doToggleMic()" class="vOrng"><img src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a title="Permission Denied Click To Retry" *ngIf="!isMicAvailable" style="cursor: pointer"
                (click)="doRetryMicTracks()" class="vOrng"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div class="joiningVideoControl localVideo" [style.background]="
                isCameraON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'
              ">
              <a title="Switch off Video" [hidden]="!isCameraON" *ngIf="isCamAvailable" style="cursor: pointer"
                (click)="doToggleCamera()" class="vOrng"><img
                  src="../../../assets/images/video-chat/CameraOff.png" /></a>
              <a title="Switch on Video" *ngIf="isCamAvailable" [hidden]="isCameraON" style="cursor: pointer"
                (click)="doToggleCamera()" class="vOrng"><img
                  src="../../../assets/images/video-chat/CameraOff.png" /></a>
              <a title="Permission Denied Click To Retry" *ngIf="!isCamAvailable" style="cursor: pointer"
                (click)="doRetryCameraTracks()" class="vOrng"><img src="../../../assets/images/cam_error.svg" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="aboutStart">
          <div class="secPadding joinBox">
            <div class="row align-items-center">
              <div class="col-lg-12 col-sm-12">
                <div class="joinBoxTxt">
                  <h3>Ready to join the session?</h3>
                  <p>
                    Please ensure that you have given the required permission to
                    your camera & microphone.
                  </p>
                </div>
              </div>
              <div>
                <div class="joinButton">
                  <div *ngIf="!device_status_text.CHECKING; else startButton">
                    <ng-lottie height="150px" [options]="options" (animationCreated)="animationCreated($event)">
                    </ng-lottie>
                  </div>

                  <ng-template #startButton>
                    <button (click)="doHandleJoinCall()" class="buttonTheme1"
                      style="margin-top: 40px; padding: 12px 79px">
                      START NOW
                    </button>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="status === 'ABOUT_TO_START'">
    <div class="col-md-6">
      <div style="
          display: flex;
          align-items: center;
          margin-top: 15px;
          position: relative;
          left: 14.5%;
        ">
        <span><a routerLink="../sessions"><img src="../../../assets/images/video-chat/back.png"
              style="height: 20px; width: 20px" /></a></span>
        <span style="font-size: 24px; margin-left: 20px"><a routerLink="../sessions"
            style="color: black">Session</a></span>
        <!-- <span style="font-size: 16px; color: #808080">Join session</span> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <div class="aboutStart">
          <div class="secPadding joinBox">
            <div class="row align-items-center">
              <div class="col-lg-12 col-sm-12">
                <div class="joinBoxTxt">
                  <h3>Ready to join the session?</h3>
                  <p>
                    Please ensure that you have given the required permission to
                    your camera & microphone.
                  </p>
                </div>
              </div>
              <div>
                <div class="joinButton">
                  <div *ngIf="!device_status_text.CHECKING; else startButton">
                    <ng-lottie height="150px" [options]="options" (animationCreated)="animationCreated($event)">
                    </ng-lottie>
                  </div>

                  <ng-template #startButton>
                    <button (click)="doHandleJoinCall()" class="buttonTheme1"
                      style="margin-top: 40px; padding: 12px 79px">
                      START NOW
                    </button>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="secPadding joinBox" *ngIf="status == 'ABOUT_TO_START'">
    <div class="row align-items-center">
      <div class="col-sm-3">
        <div class="joinBoxImageHolder">
          <img src="../../../assets/images/blue-yellow-session-icon.svg" class="img-fluid" alt="" />
        </div>
      </div>
      <div class="col-lg-6 col-sm-9">
        <div class="joinBoxTxt">
          <h3>Ready to start the session?</h3>
          <p>
            Please ensure that you have given the required permission to your
            camera & microphone.
          </p>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="joinButton">
          <button (click)="doHandleJoinCall()" class="buttonTheme1">START NOW</button>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="previewBox" *ngIf="status == 'ABOUT_TO_START'">
    <div class="videoChatBox">
      <video class="userPreviewVideo" id="inputPreview" autoplay [ngClass]="{ hiddenVideo: !isCameraON }"></video> -->
  <!-- <div class="audioIndCont">
        <div class="audioIndicator" [style.height.px]="audioLevel" [style.width.px]="audioLevel">
      </div>
      </div> -->
  <!-- <span class="helperSpan" [ngClass]="{ hiddenVideo: isCameraON }"></span>
      <img *ngIf="deviceInitialization==device_status_text.STARTING"
        src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"
        class="img-fluid userPreviewImage" [ngClass]="{ hiddenVideo: isCameraON }" />
      <div class="deviceStatus" *ngIf="deviceInitialization==device_status_text.CHECKING 
          ||deviceInitialization==device_status_text.CAMERA_NOT_FOUND
          ||deviceInitialization==device_status_text.MIC_NOT_FOUND
          ||deviceInitialization==device_status_text.STARTING">{{deviceInitialization}}</div>
      <div class="videoControl localVideo">
        <a title="Un-Mute Audio" [hidden]="isMicON" *ngIf="isMicAvailable" style="cursor: pointer"
          (click)="doToggleMic()" class="vOrng"><img src="../../../assets/images/mic_off.svg" /></a>
        <a title="Mute Audio" [hidden]="!isMicON" *ngIf="isMicAvailable" style="cursor: pointer" (click)="doToggleMic()"
          class="vOrng"><img src="../../../assets/images/mic_on_small.svg" /></a>
        <a title="Error Audio" *ngIf="!isMicAvailable" style="cursor: pointer" (click)="retryMedia('audio')"
          class="vOrng"><img src="../../../assets/images/mic_error.svg" /></a>
        <a title="Switch off Video" [hidden]="!isCameraON" *ngIf="isCamAvailable" style="cursor: pointer"
          (click)="doToggleCamera()" class="vOrng"><img src="../../../assets/images/camera_on_big.svg" /></a>
        <a title="Switch on Video" *ngIf="isCamAvailable" [hidden]="isCameraON" style="cursor: pointer"
          (click)="doToggleCamera()" class="vOrng"><img src="../../../assets/images/camera_off_darker.svg" /></a>
        <a title="Error Video" [hidden]="!isCameraON" *ngIf="!isCamAvailable" style="cursor: pointer"
          (click)="retryMedia('video')" class="vOrng"><img src="../../../assets/images/cam_error.svg" /></a>
      </div>
    </div>
  </div> -->
  <div class="secPadding" *ngIf="status == 'STARTED'">
    <div class="videoBox" id="videCallBox">
      <video class="remoteVideo" id="remoteParticipant" *ngIf="isRemoteJoined"></video>
      <div *ngIf="!isRemoteJoined" class="loadingAnimation">
        <img src="../../../assets/images/video-chat/wait_screen.gif" />
        <p>Still the mind. Inhale peace. Exhale worries.</p>
        <p class="plsWaitText">{{ videoCallStatus }}</p>
      </div>
      <div *ngIf="
          isRemoteJoined &&
          isRemoteScreenSharingEnabled == false &&
          isRemoteVideoMuted == true
        " class="loadingAnimation">
        <img [ngClass]="{
            hiddenPlaceHolder:
              isRemoteScreenSharingEnabled == false && isRemoteVideoMuted,
            block:
              isRemoteScreenSharingEnabled == false &&
              isRemoteVideoMuted == true
          }" src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" />
      </div>
      <div class="preview" id="ThisIsMaximizedScreen">
        <div class="minimize" (click)="doMinMaxFunc('doMin')" title="Minimize"></div>
        <div class="maximize" title="Maximize"></div>
        <div class="swipe1"></div>
        <div class="swipe2"></div>
        <div class="inputPreview">
          <video class="inputVideo" id="inputPreviewMin" autoplay></video>
          <div class="userPreview" [hidden]="isCameraON" [style.width]="isMicAvailable ? '120.94px' : '85.94px'">
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"		
            class="img-fluid userPreviewImageMin" [ngClass]="{ hiddenVideo: isCameraON }" id="remoteParticipant" /> -->
            <div class="mic-img-box" [style.background]="
                isMicON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'
              " [style.left]="isMicAvailable ? '21%' : '31%'">
              <a [hidden]="isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a [hidden]="!isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a *ngIf="!isMicAvailable" class="vOrng mic-img"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div *ngIf="isCamAvailable" class="cam-img-box">
              <a class="vOrng cam-img"><img src="../../../assets/images/video-chat/CameraOff.png" /></a>
            </div>
            <div class="text-you">Me</div>
          </div>
          <div class="userPreview" [hidden]="!isCameraON" style="width: 85.94px !important">
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"	
            class="img-fluid userPreviewImageMin" [ngClass]="{ hiddenVideo: isCameraON }" id="remoteParticipant" /> -->
            <div class="mic-img-box" style="left: 30% !important" [style.background]="
                isMicON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'
              ">
              <a [hidden]="isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a [hidden]="!isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a *ngIf="!isMicAvailable" class="vOrng mic-img"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div class="text-you">Me</div>
          </div>
        </div>
      </div>
      <div class="user-preview" *ngIf="remoteScreenShare" id="ThisIsUserMaximizedScreen" screenShareOn>
        <div class="minimize" (click)="doUserMinMaxFunc('doMin')" title="Minimize"></div>
        <div class="maximize" title="Maximize"></div>
        <div *ngIf="doShowSwapButton">
          <div class="swipe1" (click)="doSwapRemotePreview()"></div>
          <div class="swipe2" (click)="doSwapRemotePreview()"></div>
        </div>
        <div class="inputPreview">
          <video class="inputVideo" id="inputPreviewMin3" autoplay></video>
          <div class="userPreview" [hidden]="isCameraON">
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"	
            class="img-fluid userPreviewImageMin" [ngClass]="{ hiddenVideo: isCameraON }" id="remoteParticipant" /> -->
            <div class="mic-img-box">
              <a [hidden]="isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a [hidden]="!isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a *ngIf="!isMicAvailable" class="vOrng mic-img"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div *ngIf="isCamAvailable" class="cam-img-box">
              <a class="vOrng cam-img"><img src="../../../assets/images/video-chat/CameraOff.png" /></a>
            </div>
            <div class="user-name" title="{{ sessionDetails?.userId?.name }}">
              {{ sessionDetails?.userId?.name }}
            </div>
          </div>
          <div class="userPreview" [hidden]="!isCameraON" style="width: 85.94px !important">
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"	
            class="img-fluid userPreviewImageMin" [ngClass]="{ hiddenVideo: isCameraON }" id="remoteParticipant" /> -->
            <div class="mic-img-box" style="left: 30% !important">
              <a [hidden]="isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a [hidden]="!isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a *ngIf="!isMicAvailable" class="vOrng mic-img"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div class="user-name" title="{{ sessionDetails?.userId?.name }}">
              {{ sessionDetails?.userId?.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="minimizedscreen" id="ThisIsMinimizedScreen" style="display: none">
        <div class="minimize-screen">
          <div class="userPreview" [hidden]="isCameraON" [style.width]="isMicAvailable ? '120.94px' : '85.94px'">
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"	
        class="img-fluid userPreviewImageMin" [ngClass]="{ hiddenVideo: isCameraON }" id="remoteParticipant" /> -->
            <div class="mic-img-box" [style.background]="
                isMicON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'
              " [style.left]="isMicAvailable ? '21%' : '31%'">
              <a [hidden]="isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a [hidden]="!isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a *ngIf="!isMicAvailable" class="vOrng mic-img"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div *ngIf="isCamAvailable" class="cam-img-box">
              <a class="vOrng cam-img"><img src="../../../assets/images/video-chat/CameraOff.png" /></a>
            </div>
            <div class="text-you">Me</div>
          </div>
          <div class="userPreview" [hidden]="!isCameraON" style="width: 85.94px !important">
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"	
        class="img-fluid userPreviewImageMin" [ngClass]="{ hiddenVideo: isCameraON }" id="remoteParticipant" /> -->
            <div class="mic-img-box" style="left: 30% !important" [style.background]="
                isMicON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'
              ">
              <a [hidden]="isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a [hidden]="!isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a *ngIf="!isMicAvailable" class="vOrng mic-img"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div class="text-you">Me</div>
          </div>
        </div>
        <div class="minimize-screen-icons">
          <div class="minimize" style="background: rgba(0, 0, 0, 0.6) !important"></div>
          <div class="maximize" style="background: rgba(0, 0, 0, 0.2) !important" (click)="doMinMaxFunc('doMax')"></div>
          <div class="swipe1"></div>
          <div class="swipe2"></div>
        </div>
      </div>

      <div class="user-minimizedscreen" *ngIf="screenShareOn" id="ThisIsUserMinimizedScreen" style="display: none">
        <div class="minimize-screen">
          <div class="userPreview" [hidden]="isCameraON" [style.width]="isMicAvailable ? '120.94px' : '95.94px'">
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"	
        class="img-fluid userPreviewImageMin" [ngClass]="{ hiddenVideo: isCameraON }" id="remoteParticipant" /> -->
            <div class="mic-img-box" [style.background]="
                isMicON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'
              " [style.left]="isMicAvailable ? '21%' : '31%'">
              <a [hidden]="isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a [hidden]="!isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a *ngIf="!isMicAvailable" class="vOrng mic-img"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div *ngIf="isCamAvailable" class="cam-img-box">
              <a class="vOrng cam-img"><img src="../../../assets/images/video-chat/CameraOff.png" /></a>
            </div>
            <div class="user-name">{{ sessionDetails?.userId?.name }}</div>
          </div>
          <div class="userPreview" [hidden]="!isCameraON" style="width: 85.94px !important">
            <!-- <img src="{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}" draggable="false" alt="img"	
        class="img-fluid userPreviewImageMin" [ngClass]="{ hiddenVideo: isCameraON }" id="remoteParticipant" /> -->
            <div class="mic-img-box" style="left: 30% !important" [style.background]="
                isMicON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'
              ">
              <a [hidden]="isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a [hidden]="!isMicON" *ngIf="isMicAvailable" class="vOrng mic-img"><img
                  src="../../../assets/images/video-chat/AudioOff.png" /></a>
              <a *ngIf="!isMicAvailable" class="vOrng mic-img"><img src="../../../assets/images/mic_error.svg" /></a>
            </div>
            <div class="text-you">Me</div>
          </div>
        </div>
        <div class="minimize-screen-icons">
          <div class="minimize" style="background: rgba(0, 0, 0, 0.6) !important"></div>
          <div class="maximize" style="background: rgba(0, 0, 0, 0.2) !important" (click)="doUserMinMaxFunc('doMax')">
          </div>
          <div class="swipe1"></div>
          <div class="swipe2"></div>
        </div>
      </div>

      <div class="fullScreen" id="doChangeFullscreen">
        <a title="Full Screen" [hidden]="isFullScreen" style="cursor: pointer" (click)="fullScreenToggle()"
          class=""><img src="../../../assets/images/video-chat/full-screen.png" /></a>
        <a title="Exit Full Screen" style="cursor: pointer" [hidden]="!isFullScreen" (click)="fullScreenToggle()"
          class=""><img src="../../../assets/images/video-chat/exit-full-screen.png" /></a>
      </div>
      <div class="audioControl" [style.background]="isMicON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'">
        <a title="Un-Mute Audio" [hidden]="isMicON" *ngIf="isMicAvailable" style="cursor: pointer"
          (click)="doToggleMic()" class="vOrng"><img src="../../../assets/images/video-chat/AudioOff.png" /></a>
        <a title="Mute Audio" [hidden]="!isMicON" *ngIf="isMicAvailable" style="cursor: pointer" (click)="doToggleMic()"
          class="vOrng"><img src="../../../assets/images/video-chat/AudioOff.png" /></a>
        <a title="Permission Denied Click To Retry" *ngIf="!isMicAvailable" style="cursor: pointer"
          (click)="doRetryMicTracks()" class="vOrng"><img src="../../../assets/images/mic_error.svg" /></a>
      </div>
      <div class="videoControl" [style.background]="isCameraON ? 'rgba(255, 255, 255, 0.6)' : '#FF3459'">
        <a title="Switch off Video" [hidden]="!isCameraON" *ngIf="isCamAvailable" style="cursor: pointer"
          (click)="doToggleCamera()" class="vOrng"><img src="../../../assets/images/video-chat/CameraOff.png" /></a>
        <a title="Switch on Video" [hidden]="isCameraON" *ngIf="isCamAvailable" style="cursor: pointer"
          (click)="doToggleCamera()" class="vOrng"><img src="../../../assets/images/video-chat/CameraOff.png" /></a>
        <a title="Permission Denied Click To Retry" *ngIf="!isCamAvailable" style="cursor: pointer"
          (click)="doRetryCameraTracks()" class="vOrng"><img src="../../../assets/images/cam_error.svg" /></a>
      </div>
      <div class="screenshareControl" *ngIf="isRemoteJoined">
        <a title="{{
            isRemoteScreenSharingEnabled == false
              ? 'ScreenShare'
              : 'Currently User is Sharing screen '
          }}" class="vOrng" (click)="doStartScreenSharing()" [ngClass]="{
            disabled: isRemoteScreenSharingEnabled == true,
            'cursor-pointer': isRemoteScreenSharingEnabled == false
          }" *ngIf="!screenShareOn">
          <img src="../../../assets/images/video-chat/Screen.png" />
        </a>
        <a title="Screen Share" class="vOrng" (click)="doStopScreenSharing()"
          *ngIf="screenShareOn && isRemoteScreenSharingEnabled == false"><img
            src="../../../assets/images/video-chat/Vector-1.png" />
        </a>
      </div>
      <div class="pictureMode">
        <a title="Enable Picture in picture" (click)="doEnabledPictureInPicture()" *ngIf="
            isRemoteJoined && isCameraON && isRemotePictureInPicture == false
          "><img src="../../../assets/images/pipIcon.png" /></a>

        <a title="Back to Tab" (click)="doDisablePictureInPicture()" *ngIf="isRemotePictureInPicture"><img
            src="../../../assets/images/pipOut.png" /></a>
      </div>
      <div class="help-button" id="doChangeHelpButton">
        <a title="Help" [routerLink]="['../requestform']" [queryParams]="{ report: 'session', sessionId: roomName }"
          target="_blank"><img src="../../../assets/images/video-chat/Help icon.png" /></a>
      </div>
      <div *ngIf="!isNotesSidebarVisible">
        <div class="notes-button" (click)="openNotes()">
          <a title="Notes"><img src="../../../assets/images/video-chat/bi_journal-plus-off.png" /></a>
        </div>
      </div>
      <div *ngIf="isNotesSidebarVisible">
        <div class="notes-button" id="NotesButtonClose" style="background-color: #ff3459; right: 30%"
          (click)="closeNotes()">
          <a title="Notes"><img src="../../../assets/images/video-chat/bi_journal-plus.png" /></a>
        </div>
      </div>
      <div class="call-end">
        <a title="End Call" (click)="endCall()" style="cursor: pointer" class=""><img
            src="../../../assets/images/video-chat/out.png" style="
              height: 35px;
              width: 40px;
              margin-left: 6px;
              margin-top: 10px;
            " /></a>
      </div>

      <div class="sidebar-open" [style.display]="isNotesSidebarVisible ? 'flex' : 'none'"
        style="flex-direction: column">
        <div style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;
            padding: 0px 20px;
          ">
          <h1 style="font-size: 20px">Notes</h1>
          <i class="fas fa-times" style="font-size: 20px; cursor: pointer" (click)="closeNotes()"></i>
        </div>
        <div>
          <p style="display: flex; margin-left: 20px">
            {{ sessionStartDate }}
          </p>
        </div>
        <div style="
            display: flex;
            margin-left: 20px;
            width: 100%;
            height: 100%;
            margin-bottom: 50px;
          ">
          <textarea style="
              width: 85%;
              height: 100%;
              border: none;
              outline: none;
              resize: none;
            " placeholder="Please Enter Your Notes Here" [(ngModel)]="sessionNote" (ngModelChange)="doUpdateSessionNotes()"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="rejoin" *ngIf="status == 'CALL_ENDED'">
    <div style="
        display: flex;
        align-items: center;
        margin-top: 15px;
        position: relative;
        left: 10%;
        width: 68vw;
      ">
      <span><a routerLink="../sessions"><img src="../../../assets/images/video-chat/back.png"
            style="height: 20px; width: 20px" /></a></span>
      <span style="font-size: 22px; margin-left: 20px"><a routerLink="../sessions"
          style="color: black">Session</a></span>
    </div>

    <div class="left-Session-card">
      <div class="secPadding">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="joinBoxTxt">
              <h3 style="font-weight: 200 !important">
                You have left the session
              </h3>
            </div>
          </div>
        </div>
        <div class="row justify-content-around">
          <div>
            <button class="buttonTheme1" (click)="rejoin()" style="margin-top: 20px; padding: 11px 75px">
              Rejoin Session
            </button>
          </div>
        </div>
      </div>
    </div>
    <div style="
        font-weight: 300;
        text-align: center;
        align-items: center;
        margin-top: 15%;
        margin-left: 12%;
        margin-left: 43%;
        display: flex;
      ">
      <img src="../../../assets/images/video-chat/lock.png" class="img-fluid" alt=""
        style="height: 20.59px; width: 20.59px" />
      <div style="margin-left: 14px">
        Your session is completely encrypted and secure
      </div>
    </div>
  </div>
  <div *ngIf="status == 'MOBILE_SCREEN'" class="mobile-card">
    <div class="row">
      <div class="col-md-12 col-12">
        <h1 style="
            font-size: 1.8rem;
            margin-top: 40px;
            text-align: center;
            margin-bottom: 50px;
          ">
          Ready to get started?
        </h1>
      </div>
      <div class="col-lg-3">
        <div class="joinButton">
          <button (click)="openLink()" style="
              transform: scale(1.2);
              width: '60%';
              border-radius: 10px !important;
            " class="buttonTheme1">
            Join Session
          </button>
        </div>
      </div>
      <div class="col-lg-12 col-12" style="text-align: center; margin: 10px 0px; font-size: 14px">
        <p>First time user of Felicity?</p>
        <a href="{{responsiveRoute}}"
        (click)="openLink()"
          target="_blank">Download From
          {{ MobileText }}</a>
      </div>
    </div>
  </div>
</div>