import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talk-therapy-related',
  templateUrl: './talk-therapy-related.component.html',
  styleUrls: ['./talk-therapy-related.component.css']
})
export class TalkTherapyRelatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
