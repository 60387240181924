<div class="container-fluid cal-mainContainer">
  <div class="cal-sectionHeading" *ngIf="!isDisabled && !isLoading">
    <h3>Schedule Your Session</h3>
    <hr />
  </div>
  <div
    class="cal-mainBox"
    [ngClass]="{ 'cal-slotBoxOpen': slots.length > 0 }"
    *ngIf="!isDisabled && !isLoading"
  >
    <div
      class="cal-sessionBox"
      [ngClass]="{ 'cal-sessionBoxNew': slots.length > 0 }"
    >
      <div class="cal-therapistInfo">
        <div class="cal-sessionImageBox">
          <img
            src="{{ therapist?.imageURL }}"
            draggable="false"
            alt="img"
            onerror="this.src='../../../../assets/images/user.png';"
            class="img-fluid"
          />
        </div>
        <div class="cal-connectSectionWrap">
          <h3>{{ therapist?.firstname }} {{ therapist?.lastname }}</h3>
          <p *ngIf="therapist?.jobqualification != 'Other (Please Mention)'">
            {{ therapist?.jobqualification }}
          </p>
          <p *ngIf="therapist?.jobqualification == 'Other (Please Mention)'">
            {{ therapist?.jobqualificationOtherOptionAnswer }}
          </p>
          <a routerLink="/community-talk-therapists-detail/{{ therapist?._id }}"
            >View Profile ></a
          >
          <hr />
        </div>
      </div>
      <div class="cal-instructions">
        <div class="cal-durBox">
          <i
            class="fa fa-clock-o fa-2x"
            style="font-size: 1.4em; margin-top: 8px"
            aria-hidden="true"
          ></i>
          <p>45 Minutes</p>
        </div>
        <div class="cal-instBox">
          <ul>
            <li>
              <p>
                Please join the session 5 minutes before the scheduled time,
                should you face any technical issues.
              </p>
            </li>
            <li>
              <p>
                For an ideal experience, please ensure that you have a high
                speed internet connection.
              </p>
            </li>
            <li>
              <p>Please choose a well-lit & a quiet spot for your session.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="cal-calendarHolder"
      [ngClass]="{ 'cal-calNewWidth': slots.length > 0 }"
    >
      <ng-template
        [ngIf]="isShimmer"
        [ngStyle]="{
          visibility: isShimmer ? 'hidden' : 'visible'
        }"
      >
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            'border-radius': '5px',
            height: '50px',
            width: '635px',
            border: '1px solid white',
            'margin-left': '31px',
            'margin-top': '10px',
            'margin-bottom': '10px'
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="24"
          appearance="line"
          [theme]="{
            'border-radius': '5px',
            height: '80px',
            width: '80px',
            border: '1px solid white',
            'margin-left': '30px',
            'margin-top': '10px',
            'margin-bottom': '8px'
          }"
        >
        </ngx-skeleton-loader>
      </ng-template>
      <div
        [ngStyle]="{
          visibility: isShimmer ? 'hidden' : 'visible'
        }"
      >
        <div class="cal-sessionHeading" style="display: flex">
          <h3>Select a date</h3>
          <div style="padding-left: 10px">
            <div
              *ngIf="isChangeMonthLoader"
              class="spinner-border"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
          </div>
        </div>
        <hr />
        <div class="cal-calHolder">
          <full-calendar
            class="myCalendar"
            #calendar
            [options]="calendarOptions"
          ></full-calendar>
        </div>

        <div class="cal-timezoneHolder formRound">
          <i class="fa fa-globe"></i>
          <form [formGroup]="timeZoneForm">
            <ng-select
              formControlName="TimeZone"
              [items]="TimeZone"
              (change)="changeTimeZone($event)"
              class="timezoneSelect"
              [clearable]="timeZoneForm.value ? true : false"
            ></ng-select>
          </form>
        </div>
      </div>
    </div>
    <div class="cal-slotBox" *ngIf="slots.length > 0">
      <div class="slotHeading">
        <div class="cal-sessionHeading cal-slotBoxHeading">
          <h3>Selected date</h3>
          <span class="cal-selectedDate">
            <p>{{ selectedDate | date: "longDate" }}</p>
          </span>
        </div>
      </div>
      <div class="cal-slotHeading">
        <div class="cal-slot-box" *ngFor="let slot of slots">
          <div
            class="cal-slot"
            [ngClass]="{ 'cal-slot-shrink': selectedSlot == slot }"
            (click)="selectSlot(slot)"
          >
            <button>{{ slot }}</button>
          </div>
          <div
            class="cal-confirm-slot text-center"
            *ngIf="selectedSlot == slot"
          >
            <button
              (click)="!isBookSlotLoader && bookNow()"
              [ngClass]="{ 'confirm-loader': isBookSlotLoader }"
            >
              <span *ngIf="!isBookSlotLoader"> Confirm </span>
              <div *ngIf="isBookSlotLoader" class="confirm-spinner">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cal-disabledSecton" *ngIf="isDisabled && !isLoading">
    <h3>Ooopsss!😔</h3>
    <p>
      It looks like the counselor is not available right now. Please choose
      another counselor 🤗
    </p>
    <div class="cal-ctaHolder">
      <button routerLink="../../our-counselors" class="buttonTheme1">
        Our Counselors
      </button>
    </div>
  </div>
</div>
