import { Component, OnInit } from "@angular/core";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-for-counselors",
  templateUrl: "./for-counselors.component.html",
  styleUrls: ["./for-counselors.component.css"],
})
export class ForCounselorsComponent implements OnInit {
  constructor(private sidenavService: SideNavBarService) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {}
}
