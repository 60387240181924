import { Component, OnInit } from "@angular/core";
import { RegionSelectionService } from "src/app/region-selection.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-meet-therapists",
  templateUrl: "./meet-therapists.component.html",
  styleUrls: ["./meet-therapists.component.css"],
})
export class MeetTherapistsComponent implements OnInit {
  regionData;
  constructor(private sidenavService: SideNavBarService, private regionSelectionService : RegionSelectionService) {}

  ngOnInit(): void {
    this.regionSelectionService.getRegionDataListener().subscribe((data) => {
      this.regionData = data;
    })
    this.regionSelectionService.setRegionData(() => {});
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
}
