import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFromNow'
})
export class DateFromNow implements PipeTransform {
    transform(value: any, args?: any): any {
        value._isUTC = false;
        let currentDate = moment()
        let givenDate = moment(value, "DD_MMM_YYYY HH:mm")
        let dateDiff = currentDate.diff(givenDate, 'days')
        let YESTERDAY = currentDate.add(-1, 'days').startOf('day');
        if (givenDate.format('DD-MM-YYYY') == YESTERDAY.format('DD-MM-YYYY')) {
            return "yesterday"
        }
        if (dateDiff == 0) {
            return moment(value).format('hh:mm A');
        }

        if (dateDiff > 1) {
            return moment(value).format('DD/MM/YY');
        }
        return moment(value).fromNow();
    }
}