<div (click)="closenavbar()" class="topMenuMargin">
  <div class="container mt-3">
    <p class="disclaimerTxt">
      <strong><i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for screening and
      monitoring symptom severity and cannot replace a clinical assessment and
      diagnosis. You are encouraged to share these results with a mental health
      professional for a better understanding.
    </p>
    <div class="report-user mt-3">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user">
      <h3>Congratulations on taking that first step!</h3>
      <p><strong>Your score: </strong> {{report?.reportDetails?.score}} <span class="scrollTxt"
          (click)="scrollToElem('target')">Detailed Analysis</span> </p>
          <br>
      <p>To understand this score in terms of intensity, get in touch with a mental health professional. Since this is a
        clinical scale, you have to get a detailed interpretation from someone who specializes in this field.</p>
    </div>
  </div>
  <div class="container graph-section mt-3">
    <div>
      <p class="mb-3" id="target">
        Seeing this report will give you an idea of the level of grief that you are experiencing.
      </p>
    </div>
  </div>
  <div class="container about-scores">
    <div class="report-user">
      <h3 class="">Detailed Analysis</h3>
      <p class="mb-3">
        The International Prolonged Grief Disorder Scale is a comprehensive assessment that measures several factors
        like core symptoms, display of emotional pain, functional impairment and cultural norms.
      </p>

      <p class="mb-3">
        <strong>Core Symptoms: </strong> Your score on this subscale is {{subScore1}}, where 1 is the least score and
        10 is the highest score.
      </p>

      <p class="mb-3">
        <strong>
          Display of Emotional Pain :
        </strong>
        Your score on this subscale is {{subScore2}}, where 1 is the least score and 50 is the highest score. This
        subscale measures the degree of emotional pain that one is going through as a result of the pain.
      </p>
      <p class="mb-3">
        <strong>Functional Impairment :</strong> Interruption in one’s daily life routine can be a result of grief and
        it can be mild to severe at times. Your score on this subscale is {{subScore3}}, where 1 is the least score and
        5 is the highest score.
      </p>
      <p class="mb-3"><strong>Cultural Norms </strong> means the influence of culture on one’s grief. Your
        score on this
        subscale is {{subScore4}}, where 1 is the least score and 5 is the highest score.</p>

      <h6>Note:</h6>
      <p>The above test does not replace a formal diagnosis made by a mental health professional. The test is
        only predictive and solely for self-report measures. If you face any form of distress or wish to address any
        concerns, please book an appointment with a mental health professional to get a clinical diagnosis and
        treatment.</p>
    </div>

    <div class="mt-4 mb-4">
    </div>
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
  <br />
</div>