import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../../subscription.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {
  shortUrl = '';
  isLoading = true;
  isFound  = false;

  constructor(private router: Router, private actRoute: ActivatedRoute, private subService: SubscriptionService, private deviceDetectorService : DeviceDetectorService) { }

  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  ngOnInit(): void {
    console.log(this.deviceDetectorService.isMobile())
    this.shortUrl = this.router.url.substring(1)
    this.isLoading = true;
    this.subService.getFullUrl(this.shortUrl).subscribe((res) => {
      console.log(res)
      if(res['fullUrl']){
        this.router.navigateByUrl(res['fullUrl'])
      }
      if(res['oneLink']){
        window.location.href = res['oneLink']
      }
    }, (err) => {
      if(err.status===404){
        this.isLoading = false;
        this.isFound = false;
      }
    })
  }

}
