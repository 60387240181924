import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../guest/subscription.service';

@Component({
  selector: 'app-error',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFound implements OnInit {
  shortUrl = '';
  isLoading = true;
  isFound  = false;
  constructor(private router: Router, private actRoute: ActivatedRoute, private subService: SubscriptionService) { }

  ngOnInit(): void {
    this.shortUrl = this.router.url.substring(1)
    this.isLoading = true;
    this.subService.getFullUrl(this.shortUrl).subscribe((res) => {
      if(res['fullUrl']){
        this.router.navigateByUrl(res['fullUrl'])
      }
      if(res['oneLink']){
        window.location.href = res['oneLink']
      }
    }, (err) => {
      if(err.status===404){
        this.isLoading = false;
        this.isFound = false;
      }
    })
  }

}
