import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { BootstrapAlertService } from "./shared/ng-bootstrap-alert/bootstrap-alert.service";
import { catchError } from "rxjs/operators";
import { BootstrapAlert } from "./shared/ng-bootstrap-alert/bootstrap-alert";
import { Router } from "@angular/router";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: BootstrapAlertService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.error === 'Invalid Therapist Token'){
          localStorage.clear();
          this.router.navigate(['/therapist-login']);
          return;
        }
        if(error.error === 'Invalid User Token'){
          localStorage.clear();
          this.router.navigate(['/login']);
          return;
        }
        let errorMessage = "An unknown error!";
        if(error.error){
          if (error.error.message) {
            errorMessage = error.error.message;
          }
        }
        this.alertService.alert(
          new BootstrapAlert(errorMessage, "alert-danger")
        );
        return throwError(error);
      })
    );
  }
}
