import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
  rating = 0;
  sessionId = null;
  ratingData = null;
  ratingForm = new FormGroup({
    technicalDifficulties: new FormControl(""),
    startSessionOnTime:  new FormControl(""),
    comment: new FormControl("")
  })
  options = ['Yes', 'No'];

  constructor(private sessionsService: BookingService, private actRoute: ActivatedRoute, private confirmationService: ConfirmationDialogService, private router: Router) { }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((param) =>{
      this.sessionId = param.get('id')
      this.sessionsService.getRating(this.sessionId).subscribe((res) => {
        this.ratingData = res['rating']
        this.ratingForm.setValue({
          technicalDifficulties : this.ratingData['technicalDifficulties'] ? this.ratingData['technicalDifficulties'] : '',
          startSessionOnTime : this.ratingData['startSessionOnTime'] ? this.ratingData['startSessionOnTime'] : '',
          comment : this.ratingData['comment'] ? this.ratingData['comment'] : '',
        })
        this.rating = Number(this.ratingData.rating) ? Number(this.ratingData.rating) : 0
      })
    })
  }

  setRating(rating){
    this.rating = Number(rating)
  }

  onSubmit(){
    this.confirmationService.confirm('Are you sure?', 'This will update the session\'s rating.').subscribe((res) => {
      if(res){
        let reqObj = {
          ...this.ratingForm.value,
          rating :  this.rating,
        }
        this.sessionsService.setRating(this.sessionId, reqObj).subscribe((res) => {
          this.router.navigateByUrl('user-dashboard/sessions')
        })
      }
    })
  }

}
