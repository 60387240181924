import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";

import { UserDashboardComponent } from "./user-dashboard/user-dashboard.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProfileComponent } from "./profile/profile.component";
import { AppRoutingModule } from "../app-routing.module";
import { SessionsComponent } from "./sessions/sessions.component";
import { DisscussionForumComponent } from "./disscussion-forum/disscussion-forum.component";
import { MoodJournalComponent } from "./mood-journal/mood-journal.component";
import { MyChatsComponent } from "./my-chats/my-chats.component";
import { HelpdeskComponent } from "./helpdesk/helpdesk.component";
import { RaiseNewRequestComponent } from "./raise-new-request/raise-new-request.component";
import { ViewOldRequestsComponent } from "./view-old-requests/view-old-requests.component";
import { TechnologyRelatedComponent } from "./technology-related/technology-related.component";
import { AccountRelatedComponent } from "./account-related/account-related.component";
import { ProgramRelatedComponent } from "./program-related/program-related.component";
import { TalkTherapyRelatedComponent } from "./talk-therapy-related/talk-therapy-related.component";
import { SubscriptionRelatedComponent } from "./subscription-related/subscription-related.component";
import { UserDiscussionFormComponent } from "./user-discussion-form/user-discussion-form.component";
import { AnswerDiscussionComponent } from "./answer-discussion/answer-discussion.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { NgxEditorModule } from "ngx-editor";
import { SharedModule } from "../shared/shared.module";
import { ViewSingleTicketComponent } from "./view-single-ticket/view-single-ticket.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { OrderSummaryComponent } from "./order-summary/order-summary.component";
import { JoinSessionComponent } from "./join-session/join-session.component";
import { ViewCalendarComponent } from "./view-calendar/view-calendar.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { MoodCheckInComponent } from './mood-check-in/mood-check-in.component';
import { AddThoughtsComponent } from './add-thoughts/add-thoughts.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { BootstrapAlertModule } from "../shared/ng-bootstrap-alert/bootstrap-alert.module";
import { SessionBookedComponent } from './session-booked/session-booked.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { QuestionsComponent } from './questions/questions.component';
import { OurRecommendationComponent } from './our-recommendation/our-recommendation.component';
import { ViewOldPlansComponent } from './view-old-plans/view-old-plans.component';
import { RatingComponent } from './rating/rating.component';
import { TroubleshootSessionComponent } from './troubleshoot-session/troubleshoot-session.component';
// import { UserRoutingModule } from "./user-routing.module";
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { TherapyIntakeFormComponent } from './therapy-intake-form/therapy-intake-form.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SessionJoinUserComponent } from './session-join-user/session-join-user.component';
import { SessionJoinCallComponent } from "./session-join-call/session-join-call.component";
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    UserDashboardComponent,
    DashboardComponent,
    ProfileComponent,
    SessionsComponent,
    DisscussionForumComponent,
    MoodJournalComponent,
    MyChatsComponent,
    HelpdeskComponent,
    RaiseNewRequestComponent,
    ViewOldRequestsComponent,
    TechnologyRelatedComponent,
    AccountRelatedComponent,
    ProgramRelatedComponent,
    TalkTherapyRelatedComponent,
    SubscriptionRelatedComponent,
    UserDiscussionFormComponent,
    AnswerDiscussionComponent,
    ViewSingleTicketComponent,
    ChangePasswordComponent,
    OrderSummaryComponent,
    JoinSessionComponent,
    ViewCalendarComponent,
    MoodCheckInComponent,
    AddThoughtsComponent,
    DashboardHeaderComponent,
    SessionBookedComponent,
    NotificationsComponent,
    QuestionsComponent,
    OurRecommendationComponent,
    ViewOldPlansComponent,
    RatingComponent,
    TroubleshootSessionComponent,
    CompleteProfileComponent,
    TherapyIntakeFormComponent,
    SessionJoinUserComponent,
    SessionJoinCallComponent,
  ],
  imports: [
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
    CommonModule,
    AppRoutingModule,
    // UserRoutingModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    FullCalendarModule,
    BsDatepickerModule.forRoot(),
    NgxEditorModule,
    BootstrapAlertModule,
    Ng2TelInputModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  entryComponents: [ConfirmationDialogComponent],
})
export class UserModule { }
