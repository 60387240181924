<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left title" >{{ title }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Warning! This cannot be undone.
    </div>
    <ul>
      <li>Chat with the therapist will be removed.</li>
      <li>Booked slot will be removed.</li>
      <li>Any post or blog made by you will be removed.</li>
      <li>Any comments made by you will be removed.</li>
    </ul>
    <div class="btnHolder">
      <button class="btn btn-cancel" (click)="decline()">
        {{ btnCancelText }}
      </button>
      <button class="btn btn-accept" (click)="accept()">{{ btnOkText }}</button>
    </div>
  </div>
</div>
