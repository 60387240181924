import { Component, OnInit } from "@angular/core";
import { Editor } from "ngx-editor";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MoodJournalService } from "../mood-journal.service";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import clevertap from "clevertap-web-sdk";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: "app-add-thoughts",
  templateUrl: "./add-thoughts.component.html",
  styleUrls: ["./add-thoughts.component.css"],
})
export class AddThoughtsComponent implements OnInit {
  editor: Editor;
  journalId = null;
  isLoading = false;
  mode = "create";
  constructor(
    private moodService: MoodJournalService,
    private confirmationService: ConfirmationDialogService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) { }
  // addThoughtsForm = new FormGroup({
  // title: new FormControl("", Validators.required),
  // });
  addThoughtsForm: FormGroup;
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    this.editor = new Editor();
    this.initForms();
    this.actRoute.queryParamMap.subscribe((params) => {
      this.journalId = params.get("thoughtId");
      if (this.journalId != null) {
        this.moodService.getJournalById(this.journalId).subscribe((data) => {
          this.mode = "edit";
          this.addThoughtsForm.setValue({
            title: data["title"] ? data["title"] : null,
          });
        });
      }
    });
  }

  closenavbar() {
    this.sidenavService.closesidenav()
  }
  initForms() {
    this.addThoughtsForm = new FormGroup({
      title: new FormControl("", Validators.required),
    });
  }
  formSubmission() {
    if (this.addThoughtsForm.invalid) {
      return;
    }
    if (this.mode == "create") {

      this.confirmationService
        .confirm(
          "Are you sure?",
          "Do you want to add this thought in your mood journal?",
          "Yes",
          "No, change something"
        ).subscribe((res) => {
          if (res) {
            this.isLoading = true;
            this.moodService
              .createJournal(this.addThoughtsForm.value)
              .subscribe((res) => {
                //action successful
                this.isLoading = false;
                if (clevertap) {
                  clevertap.event.push("Journal Entry Added")
                }
                if (res) {
                  this.alertService.alert(
                    new BootstrapAlert(
                      "Journal was added successfully",
                      "alert-success"
                    )
                  );
                  this.router.navigateByUrl("user-dashboard/moodboard");
                }
              }, (err) => {
                this.isLoading = false;
              });
          }
        });
    }
    if (this.mode == "edit") {
      this.confirmationService
        .confirm(
          "Are you sure?",
          "Are you sure you want to update this journal?",
          "Yes",
          "No"
        )
        .subscribe((res) => {
          if (res) {
            this.moodService
              .updateJournal(this.journalId, this.addThoughtsForm.value)
              .subscribe((res) => {
                if (res) {
                  this.alertService.alert(
                    new BootstrapAlert(
                      "Journal was updated successfully",
                      "alert-success"
                    )
                  );
                  this.router.navigateByUrl("user-dashboard/moodboard");
                }
              });
          }
        });
      this.isLoading = false;
    }
  }
}
