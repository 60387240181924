import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Editor } from "ngx-editor";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { ClientNotesService } from "../client-notes.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import * as momentTz from "moment-timezone";

@Component({
  selector: "app-create-notes",
  templateUrl: "./create-notes.component.html",
  styleUrls: ["./create-notes.component.css"],
})
export class CreateNotesComponent implements OnInit {
  editor: Editor;
  noteId = null;
  userId = null;
  mode = "create";
  formType: String = "";
  clientUserId;
  userName = "";
  addNoteForm: FormGroup;
  userCompletedSessions = 0;
  firstNoteForm = new FormGroup({
    sessionDate: new FormControl(""),
    history: new FormControl(""),
    issues: new FormControl(""),
    therapyGoals: new FormControl(""),
    refer: new FormControl(""),
    notesForNext: new FormControl(""),
    dateForNextSession: new FormControl("NA"),
  });
  laterNoteForm = new FormGroup({
    sessionDate: new FormControl(""),
    changesSince: new FormControl(""),
    goal: new FormControl(""),
    progress: new FormControl(""),
    refer: new FormControl(""),
    notesForNext: new FormControl(""),
    dateForNextSession: new FormControl("NA"),
  });
  bsConfig: Partial<BsDatepickerConfig>;
  notesData = null;
  sessionsDate = [];
  isEditable;
  constructor(
    private actRoute: ActivatedRoute,
    private noteService: ClientNotesService,
    private confirmationService: ConfirmationDialogService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userName = history?.state?.name || "user's note"
    this.editor = new Editor();
    this.initForms();
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    this.actRoute.paramMap.subscribe((params) => {
      this.noteId = params.get("id");
      if (this.noteId != null) {
        this.noteService.getNoteById(this.noteId).subscribe((res) => {
          this.notesData = res
          this.userId = this.notesData['userId']
          this.isEditable = this.notesData.edit
          if (res["noteType"] === "old-data") {
            this.addNoteForm.setValue({
              notes: res["notes"] ? res["notes"] : null,
            });
            this.mode = "view";
            this.formType = "old-data";
          }
            if (res["noteType"] === "later") {
              if(res["noteObj"]){
                this.laterNoteForm.setValue({
                  changesSince: res["noteObj"]["changesSince"] ? res["noteObj"]["changesSince"] : "",
                  goal: res["noteObj"]["goal"] ? res["noteObj"]["goal"] : "",
                  progress: res["noteObj"]["progress"]
                    ? res["noteObj"]["progress"]
                    : "",
                  refer: res["noteObj"]["refer"] ? res["noteObj"]["refer"] : "",
                  notesForNext: res["noteObj"]["notesForNext"]
                    ? res["noteObj"]["notesForNext"]
                    : "",
                  dateForNextSession: res["noteObj"]["dateForNextSession"]
                    ? new Date(res["noteObj"]["dateForNextSession"])
                    : "",
                  sessionDate: res["sessionDate"] ? momentTz(res["sessionDate"]).format("LLLL") : "",
                });
              }
              if (res["sessionDate"]) {
                this.laterNoteForm.controls["sessionDate"].disable();
              }
              this.formType = "later";
            }
            if (res["noteType"] === "first") {
              if(res["noteObj"]){
                this.firstNoteForm.setValue({
                  history: res["noteObj"]["history"]
                    ? res["noteObj"]["history"]
                    : "",
                  issues: res["noteObj"]["issues"] ? res["noteObj"]["issues"] : "",
                  therapyGoals: res["noteObj"]["therapyGoals"]
                    ? res["noteObj"]["therapyGoals"]
                    : "",
                  refer: res["noteObj"]["refer"] ? res["noteObj"]["refer"] : "",
                  notesForNext: res["noteObj"]["notesForNext"]
                    ? res["noteObj"]["notesForNext"]
                    : "",
                  dateForNextSession: res["noteObj"]["dateForNextSession"]
                    ? res["noteObj"]["dateForNextSession"]
                    : "",
                  sessionDate: res["sessionDate"] ? momentTz(res["sessionDate"]).format("LLLL") : "",
                });
              }
              if (res["sessionDate"]) {
                this.firstNoteForm.controls["sessionDate"].disable();
              }
              this.formType = "first";
            }
          if ((res["noteType"] === "first" || res["noteType"] === "later") && (!res["noteObj"])) {
            this.getSessionDates();
          } else if(!res["noteObj"]["sessionDate"]) {
            this.getSessionDates();
          }
        });
      }
    });
    this.clientUserId = localStorage.getItem("clientUserId");
  }

  getSessionDates(){
    this.noteService.getUserCompletedSessions(this.userId).subscribe((res) => {
      res["sessions"] = res["sessions"].map((ses) => {
        return {
          _id :ses._id,
          date : momentTz(ses.startDate).format("LLLL")
        }
      })
      this.sessionsDate = res["sessions"]
    })
  }

  initForms() {
    this.addNoteForm = new FormGroup({
      notes: new FormControl("", Validators.required),
    });
  }

  formSubmission() {
    if (!this.isEditable && this.noteId != null) {
      return;
    }
    //create note function
    this.confirmationService
      .confirm(
        "Are you sure?",
        "Are you sure you want to save this note?",
        "Yes",
        "No, Cancel"
      )
      .subscribe((res) => {
        if (res) {
          let noteObj = {};
          if (this.formType === "later") {
            noteObj = {
              noteObj: this.laterNoteForm.value,
              userId: this.userId,
              noteType: "later",
            };
            if(this.notesData["noteObj"]){
              if(this.notesData["noteObj"]["sessionDate"]){
                noteObj["noteObj"]["sessionDate"] = this.notesData["noteObj"]["sessionDate"];
              }
            }
          }
          if (this.formType === "first") {
            noteObj = {
              noteObj: this.firstNoteForm.value,
              userId: this.userId,
              noteType: "first",
            };
            if(this.notesData["noteObj"]){
              if(this.notesData["noteObj"]["sessionDate"]){
                noteObj["noteObj"]["sessionDate"] = this.notesData["noteObj"]["sessionDate"];
              }
            }
          }
          if (this.noteId === null) {
            this.noteService.createNote(noteObj).subscribe((res) => {
              this.router.navigateByUrl(
                "therapist-dashboard/client-notes-view/" + this.userId
              );
            });
          } else {
            this.noteService
              .updateNote(this.noteId, noteObj)
              .subscribe((res) => {
                this.router.navigateByUrl(
                  "/therapist-dashboard/client-notes-view/" + this.notesData['userId']
                );
                localStorage.removeItem("clientUserId");
              });
          }
        }
      });
  }
}
