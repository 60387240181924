import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from "../../free-report.service";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-grief-test-results",
  templateUrl: "./grief-test-results.component.html",
  styleUrls: ["./grief-test-results.component.css"],
})
export class GriefTestResultsComponent implements OnInit {
  itemId;
  report;
  isProlongedGrief = false;
  subScore1 = 0;
  subScore2 = 0;
  subScore3 = 0;
  subScore4 = 0;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private actRoute: ActivatedRoute,
    private freeReportService: FreeReportService,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res['report'];
        let core = false;
        let acc = false;
        let impairment = false;
        let culture = false;
        let answersArray = Object.values(this.report.reportDetails.answers);
        this.subScore1 = Number(answersArray[0]) + Number(answersArray[1]);
        this.subScore2 = Number(answersArray[2]) + Number(answersArray[3]) + Number(answersArray[4]) + Number(answersArray[5]) + Number(answersArray[6]) + Number(answersArray[7]) + Number(answersArray[8]) + Number(answersArray[9]) + Number(answersArray[10]) + Number(answersArray[11]);
        this.subScore3 = Number(answersArray[12])
        this.subScore4 = Number(answersArray[13])
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "Prolonged Grief",
            "Score" : this.report.reportDetails.score
          })
        }
        answersArray.forEach((answer, index) => {
          if(index === 0 || index === 1){
            if(Number(answer) >= 4){
              core = true;
            }
          }
          if(index >= 2 && index < 12){
            if(Number(answer) >= 4){
              acc = true;
            }
          }
          if(index === 12){
            if(Number(answer) >= 4){
              impairment = true;
            }
          }
          if(index === 13){
            if(Number(answer) >= 4){
              culture = true;
            }
          }
        })

        if(core && acc && impairment && culture){
          this.isProlongedGrief = true;
        }
      });
    });
  }

  bookConsultation(){

  }

  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'grief'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }
}
