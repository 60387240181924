<div class="bodyPageWrap">
  <div *ngIf="isShimmerLoader; else boxOne">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        'border-radius': '12px',
        height: '265px',
        display: 'flex !important',
        'margin-top': '3px',
        'margin-right': '10px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <ng-template #boxOne>
    <div class="welcomeBoxWrap">
      <div class="row">
        <div class="contentBoxWrap col-md-8 col-sm-9">
          <h3>Write Your Thoughts</h3>
          <p>
            "I can shake off everything as I write, my sorrows disappear, my
            courage is reborn." - Anne Frank
          </p>
          <div class="middleButton">
            <a routerLink="../add-thoughts" class="thm-2">Create New Journal</a>
          </div>
        </div>
        <div class="imageWrap col-md-4 col-sm-3">
          <img
            src="../../../assets/images/mind.svg"
            class="img-fluid"
            draggable="false"
            alt=""
          />
        </div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="isShimmerLoader; else boxTwo">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        'border-radius': '12px',
        height: '205px',
        display: 'flex !important',
        'margin-top': '3px',
        'margin-right': '10px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <ng-template #boxTwo>
    <div class="welcomeBoxWrap">
      <div class="row">
        <div class="imageWrap col-md-4 col-sm-4">
          <img
            src="../../../assets/images/moodcheck.png"
            class="img-fluid"
            draggable="false"
            alt=""
          />
        </div>
        <div class="col-md-8 col-sm-8 text-center">
          <h3>How do you feel today ?</h3>
          <br />
          <a routerLink="../moodcheckin" class="thm-3 extraThm-3">Check In</a>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="isShimmerLoader; else noShimmerLoading" style="margin-top: 50px">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '200px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '20px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '200px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '20px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '200px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '20px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '200px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '20px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '200px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '20px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '200px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '12px',
            height: '20px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="sectionPadding-top">
    <ng-template #noShimmerLoading>
      <div class="row" style="margin-top: 50px">
        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let x of items">
          <div class="thoughtboxWrap">
            <a (click)="redirectTo(x._id)">
              <div class="thoughBoxContent">
                <p [innerHTML]="x.title"></p>
                <span [innerHTML]="message"></span>
              </div>
            </a>
            <div class="thoughtButton">
              <span>{{ x.createdAt | date: "dd MMM y" }}</span>
              <ul>
                <li>
                  <a (click)="redirectTo(x._id)"><i class="fa fa-edit"></i></a>
                </li>
                <li>
                  <a (click)="deleteOne(x._id)"><i class="fa fa-trash"></i></a>
                </li>
                <li class="liTag">
                  <a
                    (click)="UnShareMoodBoardTherpy(x._id)"
                    *ngIf="x.sharedStatus == true"
                    ><i class="fa fa-share-alt"></i
                  ></a>
                </li>
                <li class="liTag">
                  <a
                    (click)="ShareMoodBoardTherpy(x._id)"
                    *ngIf="x.sharedStatus == false"
                    ><img
                      src="../../../assets/images/notshare.svg"
                      class="extraWidthHeight"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="text-center" *ngIf="!isLoading">
      <button
        *ngIf="showNext"
        (click)="nextPage()"
        class="buttonTheme1 colorCss"
      >
        Load More
      </button>
    </div>
  </div>
</div>
