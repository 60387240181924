import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { COMMON_DATA } from 'src/app/shared/common';
import { ProfileService } from '../profile.service';
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlertService } from "../../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { Router } from '@angular/router';

@Component({
  selector: 'app-therapy-intake-form',
  templateUrl: './therapy-intake-form.component.html',
  styleUrls: ['./therapy-intake-form.component.css']
})
export class TherapyIntakeFormComponent implements OnInit {
  intakeForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  moment = COMMON_DATA.moment;
  preferredMediumOptions = COMMON_DATA.preferredMediumOptions;
  selected;
  ageOptions = COMMON_DATA.ages;
  genderOptions = COMMON_DATA.gender;
  specializeareas1 = COMMON_DATA.specializeareasOptions;
  genderSelected = '';
  selectedOptionspecializeareas;
  showSpecificArea = false;
  constructor(
    private sidenavService: SideNavBarService,
    private profileService: ProfileService,
    private confirmationService: ConfirmationDialogService,
    private alertService: BootstrapAlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.profileService.getIntakeFormData().subscribe((res) => {
      let genderFound = false;
      if(res['answer'].gender !== '') {
        this.genderOptions.forEach((gender) => {
          if(gender === res['answer'].gender) {
            genderFound = true;
          }
        })
      }
      if(res['answer'].gender !== '' && !genderFound) {
        this.intakeForm.patchValue({
          gender : 'Let me type...'
        })
        this.genderSelected = res['answer'].gender
      }
      this.intakeForm.patchValue({
        name : res['answer'].name ? res['answer'].name : '',
        age : res['answer'].age ? res['answer'].age : '',
        gender : res['answer'].gender ? res['answer'].gender : '',
        city : res['answer'].city ? res['answer'].city : '',
        doAtTheMoment : res['answer'].doAtTheMoment ? res['answer'].doAtTheMoment : '',
        otherdoAtMoment : res['answer'].otherdoAtMoment ? res['answer'].otherdoAtMoment : '',
        therapyBefore : res['answer'].therapyBefore ? res['answer'].therapyBefore : '',
        medication : res['answer'].medication ? res['answer'].medication : '',
        selfHarm : res['answer'].selfHarm ? res['answer'].selfHarm : '',
        extraInfo : res['answer'].extraInfo ? res['answer'].extraInfo : '',
        seekingHelpFor : res['answer'].seekingHelpFor ? res['answer'].seekingHelpFor : '',
        specifyNeed : res['answer'].specifyNeed ? res['answer'].specifyNeed : '',
        emergencyContactName : res['answer'].emergencyContactName ? res['answer'].emergencyContactName : '',
        emergencyContactDetails : res['answer'].emergencyContactDetails ? res['answer'].emergencyContactDetails : '',
      })
    })
    this.intakeForm.patchValue({
      name : localStorage.getItem('name') ? localStorage.getItem('name') : ''
    })
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
  }

  initForms(){
    this.intakeForm = new FormGroup({
      name : new FormControl(""),
      age: new FormControl(""),
      gender: new FormControl(""),
      city: new FormControl(""),
      doAtTheMoment : new FormControl(""),
      otherdoAtMoment: new FormControl(""),
      therapyBefore : new FormControl(""),
      medication : new FormControl(""),
      selfHarm : new FormControl(""),
      extraInfo : new FormControl(""),
      seekingHelpFor : new FormControl(""),
      specifyNeed : new FormControl(""),
      emergencyContactName : new FormControl(""),
      emergencyContactDetails : new FormControl(""),
    })
  }

  onSpecializeAreaChanged(event){
    event.forEach((area) => {
      if(area === 'Not Sure'){
        this.showSpecificArea = true;
      }
    })
  }

  showOptions(){
    this.showSpecificArea = false;
    let indexInArray = -1;
    if(this.intakeForm.value.seekingHelpFor.length > 0){
      this.intakeForm.value.seekingHelpFor.find((area, index) => {
        if(area === 'Not Sure'){
          indexInArray = index
        }
      })
    }
    if(indexInArray > -1){
      let tempArray = this.intakeForm.value.seekingHelpFor
      tempArray.splice(indexInArray, 1)
      this.intakeForm.patchValue({
        seekingHelpFor: tempArray
      })
    }
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  onSubmit() {
    if(this.intakeForm.invalid){
      return;
    }
    if(this.intakeForm.value.selfHarm === 'Yes' && this.intakeForm.value.emergencyContactDetails === ''){
      this.alertService.alert(
        new BootstrapAlert(
          "Please add Emergency Contact Details to proceed",
          "alert-danger"
        )
      );
    }
    this.confirmationService.confirm("Confirm", "Are you sure you want to submit?", "Yes", "No").subscribe((res) => {
      if(res){
        let reqData = { ...this.intakeForm.value}
        if(this.intakeForm.value.gender === 'Let me type...'){
          reqData.gender = this.genderSelected
        }
        this.profileService.updateIntakeForm(reqData).subscribe((res) => {
          this.router.navigate(["../user-dashboard"])
        })
      }
    })
  }

  selectFromOptions(){
    this.intakeForm.patchValue({
      gender : ''
    })
  }

}
