import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { FreeReportService } from "../../free-report.service";
import clevertap from "clevertap-web-sdk";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-obsessive-compulsive-disorder-test-results",
  templateUrl: "./obsessive-compulsive-disorder-test-results.component.html",
  styleUrls: ["./obsessive-compulsive-disorder-test-results.component.css"],
})
export class ObsessiveCompulsiveDisorderTestResultsComponent implements OnInit {
  bcosFinal = "";
  bcosScore;
  obsessionScore;
  compulsiveScore;
  itemId;
  ocdPhrase = "";
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        // this.bcosScore = res["report"]["ocd"]["score"];
        this.obsessionScore = res["report"]["reportDetails"]["obsessionScore"];
        this.compulsiveScore = res["report"]["reportDetails"]["compulsionScore"];
        this.bcosScore = this.obsessionScore + this.compulsiveScore;
        this.bcosfinalcal();
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "OCD",
            "Obsession Score" : this.obsessionScore,
            "Compulsion Score" : this.compulsiveScore 
          })
        }
      });
    });
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'ocd'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  bookConsultation() {
  }
  bcosfinalcal() {
    if (this.bcosScore >= 0 && this.bcosScore <= 7) {
      this.bcosFinal = "subclinical ";
    } else if (this.bcosScore >= 8 && this.bcosScore <= 15) {
      this.bcosFinal = "mild";
    } else if (this.bcosScore >= 16 && this.bcosScore <= 23) {
      this.bcosFinal = "moderate";
    } else if (this.bcosScore >= 24 && this.bcosScore <= 31) {
      this.bcosFinal = "severe";
    } else if (this.bcosScore >= 32 && this.bcosScore <= 40) {
      this.bcosFinal = "extreme";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
