<div class="welcomeBoxWrap">
  <div class="row justify-content-center">
    <div class="contentBoxWrapper col-md-6 col-sm-6">
      <h3>Need Help?</h3>
      <p>We are here for you.</p>
      <div class="buttonClass">
        <a routerLink="../requestform" class="thm-2">Raise New Request</a>
      </div>
    </div>
    <div class="welcomeImage col-md-6 col-sm-6">
      <img
        src="../../../assets/images/lapt.svg"
        class="img-fluid"
        draggable="false"
        alt=""
      />
    </div>
  </div>
</div>
<br />
<div class="faquchaticon">
  <div class="row justify-content-center">
    <div
      class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper"
      routerLink="../viewRequest"
    >
      <div class="contentBoxImg moreCss">
        <img
          src="../../../assets/images/eye.svg"
          class="img-fluid"
          draggable="false"
          alt=""
        />
        <h3 class="h3TagClass">View Old Requests</h3>
      </div>
    </div>
    <div
      class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper web-view" *ngIf="regionData?.tag === 'IN'"
    >
      <div class="contentBoxImg moreCss">
          <img
          src="../../../assets/images/whatsapp-qr.png"
          class="img-fluid"
          draggable="false"
          alt=""
        />
        <h3 class="h3TagClass">Chat With Us</h3>
      </div>
    </div>
    <div
    class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper web-view" *ngIf="regionData?.tag === 'intl'"
  >
    <div class="contentBoxImg moreCss">
      <a href="https://wa.me/8764152996?text=I%20want%20to%20know%20more">
        <img
        src="../../../assets/images/icons8-whatsapp.svg"
        class="img-fluid"
        draggable="false"
        alt=""
      />
      <h3 class="h3TagClass">Chat With Us</h3>
    </a>
    </div>
  </div>
    <div
    class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper mobile-view" *ngIf="regionData?.tag === 'IN'"
  >
    <div class="contentBoxImg moreCss">
      <a href="https://wa.me/916377327550">
        <img src="../../../assets/images/icons8-whatsapp.svg" class="img-fluid" draggable="false" alt="">
      <h3 class="h3TagClass">Chat With Us</h3>
    </a>
    </div>
  </div>
  <div
  class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper mobile-view" *ngIf="regionData?.tag === 'intl'"
>
  <div class="contentBoxImg moreCss">
    <a href="https://wa.me/8764152996?text=I%20want%20to%20know%20more">
      <img src="../../../assets/images/icons8-whatsapp.svg" class="img-fluid" draggable="false" alt="">
    <h3 class="h3TagClass">Chat With Us</h3>
  </a>
  </div>
</div>
    <div
      class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper" *ngIf="regionData?.tag === 'IN'"
    >
    <div class="contentBoxImg moreCss">
        <a href="tel:+916377327550">
        <img
          src="../../../assets/images/call.svg"
          class="img-fluid"
          draggable="false"
          alt=""
        />
        <h3 class="h3TagClass">+916377327550</h3>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="sectionPadding-top">
  <div class="frequentlyAskedQues">
    <div class="row">
      <div class="col-md-6">
        <div class="sectionHeading">
          <h2>Frequently Asked Questions</h2>
          <hr />
        </div>
      </div>
      <!-- <div class="col-md-6">
                      <div class="searbcoxFAQ">
                        <div class="counsearc">
                          <input type="search" placeholder="Search">
                          <i class="fa fa-search"></i> </div>
                      </div>
                    </div> -->
    </div>
  </div>

  <div class="faqWrap">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Subscription Related</h4>
          <ul>
            <li>
              <a routerLink="../subscription-faq">Changing talk therapist</a>
            </li>
            <li>
              <a routerLink="../subscription-faq">Rescheduling sessions</a>
            </li>
            <li>
              <a routerLink="../subscription-faq">I missed a session</a>
            </li>
            <li>
              <a routerLink="../subscription-faq">Contacting talk therapist</a>
            </li>
          </ul>
          <div class="viewMore">
            <a routerLink="../subscription-faq">See More</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Talk therapy Related</h4>
          <ul>
            <li>
              <a routerLink="../talk-therapy-faq"
                >What to expect from a session?</a
              >
            </li>
            <li>
              <a routerLink="../talk-therapy-faq"
                >Effectiveness of online therapy</a
              >
            </li>
          </ul>
          <div class="viewMore">
            <a routerLink="../talk-therapy-faq">See More</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Program Related</h4>
          <ul>
            <li>
              <a routerLink="../program-related-faq"
                >Right platform for my needs?</a
              >
            </li>
            <li>
              <a routerLink="../program-related-faq"
                >Choosing the right program</a
              >
            </li>
            <li>
              <a routerLink="../program-related-faq"
                >Talk therapist credentials</a
              >
            </li>
          </ul>
          <div class="viewMore">
            <a routerLink="../program-related-faq">See More</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row extraCss">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Account Related</h4>
          <ul>
            <li>
              <a routerLink="../account-related-faq">Access and devices</a>
            </li>
            <li>
              <a routerLink="../account-related-faq">Change password</a>
            </li>
            <li>
              <a routerLink="../account-related-faq">Privacy and anonymity </a>
            </li>
            <br />
            <br />
          </ul>
          <div class="viewMore">
            <a routerLink="../account-related-faq">See More</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Technology Related</h4>
          <ul>
            <li>
              <a routerLink="../technology-related-faq">Ideal Expreience</a>
            </li>
            <li>
              <a routerLink="../technology-related-faq"
                >My Screen froze, what should I do ?</a
              >
            </li>
            <div class="divTagCss"></div>
          </ul>
          <div class="viewMore">
            <a routerLink="../technology-related-faq">See More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
