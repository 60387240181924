import { Component, OnInit } from "@angular/core";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { RegionSelectionService } from "src/app/region-selection.service";
import { HelpDeskService } from "../help-desk.service";
declare global {
  interface Window {
    Tawk_API: any;
  }
}
@Component({
  selector: "app-helpdesk",
  templateUrl: "./helpdesk.component.html",
  styleUrls: ["./helpdesk.component.css"],
})
export class HelpdeskComponent implements OnInit {
  regionData;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private helpdeskService: HelpDeskService,
    private regionSelectionService: RegionSelectionService
  ) {}

  async ngOnInit(){
    if(localStorage.getItem("regionData")){
      this.regionData = JSON.parse(localStorage.getItem("regionData"))
    } else {
      this.regionData = await this.regionSelectionService.returnRegionData();
    }
  }

  openChat() {
    window["$zoho"].salesiq.floatbutton.visible("show");
    let chatElem = document.getElementById('zsiq_agtpic')
		chatElem.click();
  }

  requestCallback() {
    this.confirmationService
      .confirm(
        "Are you sure?",
        "You will get a callback from us if you continue",
        "Continue",
        "Cancel"
      )
      .subscribe((res) => {
        
        console.log('yes')
        if (res) {
          this.helpdeskService.requestCallback().subscribe((data) => {
            console.log(data)
            console.log('yes')
          });
        }
      });
  }
}
