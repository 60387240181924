<div class="bodyPageWrap">
  <div style="display: flex;align-items: center;margin-bottom: 20px;">
    <span ><a routerLink="../helpdesk" style="font-size:16px; color: #07006e; cursor: pointer;text-decoration: underline;">Helpdesk</a></span>
    <span style="font-size:16px">&nbsp;>&nbsp;</span>
    <span style="font-size:16px; color: #808080;">Selection Related FAQ's</span> 
  </div>
    <div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div class="sectionHeading">
              <h2>Selection Related FAQ's</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div id="1" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>What criteria do I need to meet to get selected as a therapist on felicity?</h4>
              <p class="ptagCss">
                A degree in counselling psychology or a related discipline is a must. You should have some practice experience even if it’s under training. At least 2 references are mandatory. In the interview round you are assessed on empathy, communication skills, relationship building and other relevant competencies. You are required to get a minimum rating of 4 in the demo round.            </div>
          </div>
          <div id="2" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>What infrastructure do I require to be able to practice on felicity?</h4>
              <p class="ptagCss">A smart device – laptop/mobile/tab with a camera and a high-speed internet connection.</p>
              <p class="ptagCss">A conducive environment – quite private room for a video session.</p>
            </div>
          </div>
          <div id="3" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>What is the basis of my rating?</h4>
              <p class="ptagCss">
                Firstly, we check if you start all your sessions on time. Secondly, are you able to conduct the session without any technical or administrative glitches from your end. And finally, a subjective assessment by the client of the session.              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div>
              <h3>
                Have More Questions?
                <span class="spanCss">(Access following heads)</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../contract-related-faq">Contract Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../talk-therapy-faq">Talk therapy Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../payment-related-faq">Payment Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
              <div class="questionWrapper">
                <b><a routerLink="../account-related-faq">Account Related</a></b>
              </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12">
              <div class="questionWrapper">
                <b><a routerLink="../benefits-related-faq">Benefits Related</a></b>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  