import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistAuthService } from "src/app/therapist-auth/therapist-auth.service";
import { NotificationService } from "src/app/therapist/notification.service";

@Component({
  selector: "app-therapist-dashboard",
  templateUrl: "./therapist-dashboard.component.html",
  styleUrls: ["./therapist-dashboard.component.css"],
})
export class TherapistDashboardComponent implements OnInit {
  isDashboard = false;
  isSideNav = false;
  newNotifications = 0;
  isDataLoad = false;
  isChatTab = false;
  isHeaderActions = true
  isMeetingSection
  constructor(
    private authService: TherapistAuthService,
    private router: Router,
    private sideNavService: SideNavBarService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.checkRoute();
    this.router.events.subscribe((route) => {
      this.checkRoute();
    });
    this.sideNavService.sideNavBarListener().subscribe((toggle) => {
      this.isSideNav = toggle;
    });

    this.sideNavService.dashboardsideNavAndHeader().subscribe((toggle) => {
      this.isHeaderActions = toggle;
    });
    this.notificationService.getUnreadNotifications().subscribe((data) => {
      this.newNotifications = data["count"];
    });
    setTimeout(() => {
      if(window["$zoho"]){
        if(window["$zoho"].salesiq){
          window["$zoho"].salesiq.floatbutton.visible("hide");
        }
      }
      if(window["kiwi"]){
        window["kiwi"]["hide"]();
      }
    }, 2000)
  }

  logOut() {
    this.authService.logout();
  }

  closeSidenav() {
    this.sideNavService.closesidenav();
  }

  checkRoute() {
    if (this.router.url.includes('/therapist-dashboard/session-join')) {
      this.isMeetingSection = true
    }
    if (this.router.url == "/therapist-dashboard/my-chats") {
      this.isChatTab = true;
    } else {
      this.isChatTab = false;
    }
  }
}
