<div class="secPadding">
  <div class="container-fluid">
    <div class="secondaryHeading">
      <h2>Help Us Become Better</h2>
      <hr />
    </div>
    <div class="queWrap">
      <div class="formRound">
        <form [formGroup]="ratingForm">
          <div class="insideFormDivTags">
            <h3>Did your talk therapist start the session on time?</h3>
            <div>
              <select formControlName="startSessionOnTime" class="form-control">
                <option *ngFor="let option of options" value="{{ option }}">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="insideFormDivTags">
            <h3>Did you face any technical difficulties during the session?</h3>
            <div>
              <select
                formControlName="technicalDifficulties"
                class="form-control"
              >
                <option *ngFor="let option of options" value="{{ option }}">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="insideFormDivTags">
            <h3>How would you rate the session overall?</h3>
            <div class="ratingHolder">
              <i
                class="fa"
                [ngClass]="{
                  'fa-star-o': rating < 1,
                  'fa-star': rating >= 1
                }"
                (click)="setRating(1)"
              ></i>
              <i
                class="fa"
                [ngClass]="{
                  'fa-star-o': rating < 2,
                  'fa-star': rating >= 2
                }"
                (click)="setRating(2)"
              ></i>
              <i
                class="fa"
                [ngClass]="{
                  'fa-star-o': rating < 3,
                  'fa-star': rating >= 3
                }"
                (click)="setRating(3)"
              ></i>
              <i
                class="fa"
                [ngClass]="{
                  'fa-star-o': rating < 4,
                  'fa-star': rating >= 4
                }"
                (click)="setRating(4)"
              ></i>
              <i
                class="fa"
                [ngClass]="{
                  'fa-star-o': rating < 5,
                  'fa-star': rating >= 5
                }"
                (click)="setRating(5)"
              ></i>
            </div>
          </div>
          <div class="insideFormDivTags">
            <h3>Anything you want to say about the session?</h3>
            <div>
              <textarea cols="30" rows="4" class="form-control" formControlName="comment"></textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="text-center btnHolder">
          <button class="buttonTheme1" (click)="onSubmit()">Save</button>
      </div>
    </div>
  </div>
</div>
