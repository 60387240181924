import { Component, OnInit } from "@angular/core";
import { QuoteService } from "src/app/shared/quote.service";
import { TherapistProfileService } from "../therapist-profile.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  userName = ''
  quoteforuser = "";
  isDataLoad = false;
  constructor(private profileService: TherapistProfileService, private quoteService: QuoteService) { }

  ngOnInit(): void {
    this.isDataLoad = true;
    this.profileService.getProfile().subscribe((data) => {
      this.userName = data['firstname']
      this.isDataLoad = false;
    })
    this.quoteService.getQuote('therapist').subscribe((data) => {
      this.quoteforuser = data['quote'].toString()
      this.isDataLoad = false;
    })
  }
}
