import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { SingleButtonAlertComponent } from './single-button-alert/confirmation-dialog.component';
@Injectable({
  providedIn: 'root'
})
export class SingleButtonAlertService {

  constructor(private modalService: BsModalService) { }

  public confirm(title: string,
    message: string,
    btnOkText: string = 'OK',): Subject<boolean> {
    const modalRef = this.modalService.show(SingleButtonAlertComponent)
    modalRef.content.title = title;
    modalRef.content.message = message;
    modalRef.content.btnOkText = btnOkText;
    return modalRef.content.onClose
  }
}
