import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { FreeReportService } from "src/app/guest/free-report.service";
import { RegionSelectionService } from "src/app/region-selection.service";
import { COMMON_DATA } from "src/app/shared/common";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-adhd-test",
  templateUrl: "./adhd-test.component.html",
  styleUrls: ["./adhd-test.component.css"],
})
export class AdhdTestComponent implements OnInit {
  freeReportForm: FormGroup;
  freeReportForm1: FormGroup;
  userForm2: FormGroup;
  hideModal = true;
  firstPart: boolean = true;
  secondPart: boolean = false;
  thirdPart: boolean = false;
  fourthPart: boolean = false;
  currentIndex = 0;
  answers = COMMON_DATA.adhd_options;
  answers1 = COMMON_DATA.adhd_options1;
  questions = COMMON_DATA.adhd_questions;
  questions1 = COMMON_DATA.adhd_questions1;
  progressPercentage = 0;
  progressPercentage1 = 0;
  userCountryCode = {
    countryCode:  localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : "in",
    dialCode: localStorage.getItem("dialCode") ? localStorage.getItem("dialCode") : "91",
  };
  regionData;
  constructor(
    private reportService: FreeReportService,
    private router: Router,
    private sidenavService: SideNavBarService,
    private regionSelectionService : RegionSelectionService
  ) {}
  checkError(valid) {
    if(!valid){
      this.userForm2.controls["phone"].setErrors({ 'invalidPhone' : true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.userForm2.patchValue({
      phone : phoneNew
    })
  }

  async telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
    let regionData = await this.regionSelectionService.returnRegionData();
    event.setCountry(String(regionData["regionCode"]).toLowerCase());
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }
  previousPage() {
    this.currentIndex--;
  }
  currentIndexInc() {
    this.currentIndex++;
  }
  ShowHide = function () {
    this.firstPart = false;
    this.secondPart = true;
    this.thirdPart = false;
    this.fourthPart = false;
  };
  nextTo4 = function () {
    this.firstPart = false;
    this.secondPart = false;
    this.thirdPart = false;
    this.fourthPart = true;
    this.currentIndex = 0;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  ShowHide1 = function () {
    this.firstPart = false;
    this.secondPart = false;
    this.thirdPart = true;
    this.fourthPart = false;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  previousPage1 = function () {
    this.firstPart = false;
    this.secondPart = true;
    this.thirdPart = false;
    this.fourthPart = false;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  instruction = function () {
    this.firstPart = false;
    this.secondPart = false;
    this.thirdPart = true;
    this.fourthPart = false;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  instruction1 = function () {
    this.firstPart = true;
    this.secondPart = false;
    this.thirdPart = false;
    this.fourthPart = false;
  };
  ngOnInit() {
    this.loadGTMCid();
    window.onload = (event) => {
      this.loadGTMCid();
    }
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  loadGTMCid(){
    if (window["dataLayer"]) {
      let dataLayer = window["dataLayer"];
      dataLayer.forEach((data) => {
        if (data["cid"]) {
          localStorage.setItem("GTMcid", data["cid"]);
        }
      });
    }
  }
}
