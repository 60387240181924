import { Component, OnInit } from '@angular/core';
import { SideNavBarService } from 'src/app/side-nav-bar.service';

@Component({
  selector: 'app-account-related',
  templateUrl: './account-related.component.html',
  styleUrls: ['./account-related.component.css']
})
export class AccountRelatedComponent implements OnInit {

  constructor(private sidenavService: SideNavBarService) { }

  ngOnInit(): void {
  }

  closenavbar(){
    this.sidenavService.closesidenav()
  }

}
