import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { Location } from "@angular/common";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { RegionSelectionService } from "src/app/region-selection.service";
import { PlansService } from "src/app/plans.service";

@Component({
  selector: "app-general-counselling",
  templateUrl: "./general-counselling.component.html",
  styleUrls: ["./general-counselling.component.css"],
})
export class GeneralCounsellingComponent implements OnInit {
  isLoggedIn = false;
  therapistRedirection = '';
  faArrowLeft = faArrowLeft;
  regionData;
  plansData;
  planImages = {
    plan_a : "explore-plan-1.svg",
    plan_b : "first-steps-plan-2.svg",
    plan_c : "mind-your-mind-plan-3.svg",
    plan_d : "break-free-plan-4.svg"
  }
  constructor(
    private sidenavService: SideNavBarService,
    private userAuth: AuthService,
    private router: Router,
    private metaSeo :  SeoMetaService,
    private actRoute: ActivatedRoute,
    private location : Location,
    private regionSelectionService: RegionSelectionService,
    private plansService: PlansService
  ) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  async ngOnInit() {
    if(localStorage.getItem("regionData")){
      this.regionData = JSON.parse(localStorage.getItem("regionData"))
    } else {
      this.regionData = await this.regionSelectionService.returnRegionData();
    }
    this.plansService.getvisiblePlans(this.regionData.tag).subscribe((res) => {
      let tmpArray = res;
      this.plansData = tmpArray.map((plan) => {
        if(this.regionData.regionCode === "IN" || localStorage.getItem("partner_source") === "amber"){
          plan['avgPrice'] = "₹ " + Math.ceil(Number(plan["price"]) / Number(plan["no_of_sessions"]));
          plan.price = "₹ " + plan.price;
        } else if(this.regionData.regionCode === "GB"){
          plan['avgPrice'] = "£ " + Math.ceil(Number(plan["gbPrice"]) / Number(plan["no_of_sessions"]));
          plan.price = "£ " + plan.gbPrice;
        } else{
          plan['avgPrice'] = "$ " + Math.ceil(Number(plan["usdPrice"]) / Number(plan["no_of_sessions"]));
          plan.price = "$ " + plan.usdPrice;
        }
        plan.imageUrl = this.planImages[plan.key]
        return plan;
      })
    })
    this.isLoggedIn = this.userAuth.getIsAuth();
    this.metaSeo.updateTitle("Online Counseling & Therapy Packages | Plans & Pricing | Felicity")
    this.metaSeo.updateMetaInfo("Affordable counseling & therapy with best in class psychologists. Buy now & elevate your life.", "Counselor price, cost of counseling, therapy cost");
    this.actRoute.queryParamMap.subscribe((params) => {
      this.therapistRedirection = params.get("counselor") 
    })
  }

  goBack(){
    if(this.therapistRedirection){
      window.history.go(-2)
    } else{
      this.location.back()
    }
  }

  buyNow(planKey) {
    if (this.isLoggedIn) {
      this.router.navigate(["../order-summary/" + planKey], { queryParams : {therapistId : this.therapistRedirection} });
    } else {
      this.router.navigateByUrl("/sign-up?plan=" + planKey);
    }
  }
}
