<div *ngIf="isLoading">
  <ngx-skeleton-loader appearance="line" [theme]="{
      'border-radius': '12px',
      height: '200px',
      display: 'flex !important',
      'margin-top': '3px',
      'margin-right': '10px'
    }"></ngx-skeleton-loader>
</div>
<div class="secPadding joinBox" *ngIf="status == 'NOT_STARTED'">
  <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
  <div class="row align-items-center">
    <div class="col-sm-3">
      <div class="joinBoxImageHolder">
        <img src="../../../assets/images/blue-yellow-session-icon.svg" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="col-lg-6 col-sm-9">
      <div class="joinBoxTxt">
        <h3>Your upcoming session starts in {{ remainingTimeText }}</h3>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="joinButton">
        <button (click)="joinCall()" class="buttonTheme1">Join Session</button>
      </div>
    </div>
  </div>
</div>
 <div class="secPadding joinBox" *ngIf="status == 'ABOUT_TO_START'">
  <div class="row align-items-center">
    <div class="col-sm-3">
      <div class="joinBoxImageHolder">
        <img
          src="../../../assets/images/blue-yellow-session-icon.svg"
          class="img-fluid"
          alt=""
        />
      </div>
    </div>
    <div class="col-lg-6 col-sm-9">
      <div class="joinBoxTxt">
        <h3>Ready to start the session?</h3>
        <p>
          Please ensure that you have given the required permission to your
          camera & microphone.
        </p>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="joinButton">
        <div *ngIf="isMeetingFetchLoader" class="justify-content-center d-flex"> 
          <div
          class="spinner-border"
          style="width: 2rem; height: 2rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        </div>
        <button (click)="checkJoin()" class="buttonTheme1"
        *ngIf="!isMeetingFetchLoader"
        >START NOW</button>
      </div>
    </div>
  </div>
</div> 
<div class="secPadding joinBox" *ngIf="status == 'WAITING'">
  <div class="row align-items-center">
    <div class="col-sm-3">
      <div class="joinBoxImageHolder">
        <img
          src="../../../assets/images/blue-yellow-session-icon.svg"
          class="img-fluid"
          alt=""
        />
      </div>
    </div>
    <div class="col-lg-6 col-sm-9">
      <div class="joinBoxTxt">
        <h3>Waiting for therapist to join.</h3>
        <p>
          {{hasTherapistJoined.message}}
        </p>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="joinButton">
        <div  class="justify-content-center d-flex"> 
          <div
          class="spinner-border"
          style="width: 2rem; height: 2rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        </div>
      </div>
    </div>
  </div>
</div> 
<div class="secPadding joinBox" *ngIf="status == 'CALL_ENDED'">
  <div class="row justify-content-center">
    <div class="col-md-5">
      <div class="joinBoxTxt">
        <h3>You've left the session</h3>
      </div>
    </div>
  </div>
  <div class="row justify-content-around">
    <div class="col-md-3">
      <button class="buttonTheme1" (click)="checkJoin()">Rejoin</button>
    </div>
    <div class="col-md-3">
      <button class="buttonTheme2" routerLink="../give-rating/{{ roomName }}">
        Rate Session
      </button>
    </div>
  </div>
</div>
<div class="secPadding joinBox" *ngIf="status === 'NOT_FOUND'">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="joinBoxTxt">
        <h3>
          This session does not belong to the logged in user. Make sure you are
          using the correct credentials to login.
        </h3>
      </div>
    </div>
  </div>
</div>
<div class="secPadding joinBox" *ngIf="status === 'PAST_MEETING'">
  <div class="row justify-content-center">
    <div class="col-md-12" style="text-align: center;">
      <div class="joinBoxTxt">
        <h3>
          This session has been expired!
        </h3>
      </div>
    </div>
  </div>
</div>
<div *ngIf="mobileScreen">
  <div class="row">
    <div class="col-md-12 col-12">
      <h1 style="
          font-size: 1.8rem;
          margin-top: 40px;
          text-align: center;
          margin-bottom: 50px;
        ">
        Ready to get started?
      </h1>
    </div>
    <div class="col-lg-3">
      <div class="joinButton">
        <button (click)="openLink()" style="
            transform: scale(1.2);
            width: '80%';
            border-radius: 10px !important;
          " class="buttonTheme1">
          Join Session
        </button>
      </div>
    </div>
    <div class="col-lg-12 col-12" style="text-align: center; margin: 10px 0px; font-size: 14px">
      <p>First time user of Felicity?</p>
      <a href="{{responsiveRoute}}"
      (click)="openLink()"
        target="_blank">Download From
        {{ MobileText }}</a>
    </div>
  </div>
</div>