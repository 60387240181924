import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

const offerUrl = environment.apiUrl + "/user/offer/";
const checkoutUrl = environment.apiUrl + "/user/checkout/";
const userPaymentUrl = environment.apiUrl + "/user/";
@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(private http: HttpClient) {}

  applyCoupon(couponObj, initalPrice) {
    let finalPrice = initalPrice;
    let discount = 0;
    if (couponObj.offerClass == "Flat Discount") {
      if (couponObj.minAmount <= finalPrice) {
        let dicsountedAmount = finalPrice * (couponObj.discount / 100);
        dicsountedAmount = Math.round(dicsountedAmount * 100) / 100;
        discount = dicsountedAmount;
        finalPrice = Math.round((finalPrice - dicsountedAmount) * 100) / 100;
      }
    }
    if (couponObj.offerClass == "Cash Discount") {
      if (couponObj.minAmount <= finalPrice) {
        let dicsountedAmount = couponObj.discount;
        discount = dicsountedAmount;
        finalPrice = finalPrice - dicsountedAmount;
      }
    }

    if (couponObj.offerClass == "Flat Discount Upto") {
      if (couponObj.minAmount <= finalPrice) {
        let dicsountedAmount = finalPrice * (couponObj.discount / 100);
        dicsountedAmount = Math.round(dicsountedAmount * 100) / 100;
        if (dicsountedAmount > couponObj.discountUpto) {
          dicsountedAmount = couponObj.discountUpto;
        }
        discount = dicsountedAmount;
        finalPrice = finalPrice - dicsountedAmount;
      }
    }

    return {
      discount: discount,
      finalPrice: finalPrice,
    };
  }

  getCheckoutStatus(id){
    return this.http.get(checkoutUrl + 'get-checkout-status?checkoutId='+id)
  }

  getCoupon(code) {
    return this.http.get(offerUrl + code);
  }

  getPaymentIntent(data){
    return this.http.post(checkoutUrl + "create-payment-intent", data)
  }

  getCheckoutUrl(data){
    return this.http.post(checkoutUrl + "create-payment-url", data)
  }

  initPayment(paymentObj) {
    return this.http.post(checkoutUrl, paymentObj);
  }

  confirmPayment(paymentObj) {
    return this.http.post(checkoutUrl + "confirm-payment", paymentObj)
  }

  getRegion(){
    return this.http.get(checkoutUrl + "get-region")
  }
}
