import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SideNavBarService } from 'src/app/side-nav-bar.service';

@Component({
  selector: 'app-couples-counselling',
  templateUrl: './couples-counselling.component.html',
  styleUrls: ['./couples-counselling.component.css']
})
export class CouplesCounsellingComponent implements OnInit {
  isLoggedIn = false;
  constructor(private sidenavService:SideNavBarService,
    private authService : AuthService,
    private router : Router) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.getIsAuth()
  }
  closenavbar(){
    this.sidenavService.closesidenav();
  }

  buyNow(planKey) {
    if (this.isLoggedIn) {
      this.router.navigate(["../order-summary/" + planKey]);
    } else {
      this.router.navigateByUrl("/sign-up?plan=" + planKey);
    }
  }

}
