<div (click)="closenavbar()">
  <div style="display: flex; align-items: center; margin-bottom: 20px">
    <span
      ><a
        routerLink="../moodboard"
        style="
          font-size: 16px;
          color: #07006e;
          cursor: pointer;
          text-decoration: underline;
        "
        >Mood Journal</a
      ></span
    >
    <span style="font-size: 16px">&nbsp;>&nbsp;</span>
    <span style="font-size: 16px; color: #808080" *ngIf="journalId == null"
      >Add Thoughts</span
    >
    <span style="font-size: 16px; color: #808080" *ngIf="journalId != null"
      >Edit Thoughts</span
    >
  </div>

  <form [formGroup]="addThoughtsForm" name="userForm">
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="eachOptionsDivTag">
      <h3 *ngIf="journalId == null">Add Your Thoughts</h3>
      <h3 *ngIf="journalId != null">Edit this journal</h3>
    </div>
    <div class="sectionWrap">
      <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
      <ngx-editor
        [editor]="editor"
        [placeholder]="'Type here...'"
        formControlName="title"
        [ngClass]="{
          'has-error':
            !addThoughtsForm.controls.title.valid &&
            addThoughtsForm.controls.title.touched,
          'has-success':
            addThoughtsForm.controls.title.valid &&
            addThoughtsForm.controls.title.touched
        }"
      ></ngx-editor>
    </div>
    <div class="form-group extraCss">
      <div *ngIf="isLoading" class="d-flex justify-content-center">
        <div
          class="spinner-border"
          style="width: 2rem; height: 2rem; margin-top: 23px"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
      </div>
      <button
        type="submit"
        (click)="formSubmission()"
        class="btn btn-warning"
        *ngIf="!isLoading"
      >
        Save
      </button>
    </div>
  </form>
</div>
