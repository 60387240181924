import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { COMMON_DATA } from './shared/common';

@Injectable({
  providedIn: 'root'
})
export class RegionSelectionService {
  region: String = 'IN';
  regionData;
  regionFetched = false;
  private regionDataListener = new Subject<any>();
  constructor(private http: HttpClient) { }

  fetchRegion(_callBack) {
    this.http.get("https://cwxu7m7dv4.execute-api.us-west-2.amazonaws.com/prod/guest/region").subscribe((res) => {
      this.region = res["region"];
      _callBack();
    })
  }

  returnRegionData(){
    return new Promise(resolve => {
      this.setRegionData(() => {
        resolve(this.regionData)
      })
    })
  }

  getRegionDataListener() {
    return this.regionDataListener.asObservable();
  }

  setRegionData(_callback) {
    if (localStorage.getItem("regionData")) {
      this.regionData = JSON.parse(localStorage.getItem("regionData"));
      this.regionDataListener.next(this.regionData);
      this.fetchRegion(() => {
        if (this.regionData.tag !== this.region) {
          this.regionData = this.getRegionData(this.region);
          this.regionDataListener.next(this.regionData);
          localStorage.setItem("regionData", JSON.stringify(this.regionData));
          _callback();
        } else {
          _callback();
        }
      })
    } else {
      this.fetchRegion(() => {
        this.regionData = this.getRegionData(this.region)
        this.regionDataListener.next(this.regionData);
        localStorage.setItem("regionData", JSON.stringify(this.regionData))
        _callback();
      })
    }
  }

  getRegionData(region: String) {
    switch (region) {
      case "IN":
        return {...COMMON_DATA.inRegionData, regionCode : region};
      default:
        return {...COMMON_DATA.intlRegionData, regionCode : region};
    }
  }

}
