import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { OrderService } from 'src/app/user/order.service';

@Component({
  selector: 'app-payment-redirection',
  templateUrl: './payment-redirection.component.html',
  styleUrls: ['./payment-redirection.component.css']
})
export class PaymentRedirectionComponent implements OnInit {
  checkoutId;
  constructor(private router : ActivatedRoute, private orderService: OrderService) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe((params) => {
      this.checkoutId = params["session_id"];
      console.log(this.checkoutId)
      this.orderService.getCheckoutStatus(this.checkoutId).subscribe((res) => {
        console.log(res)
      })
    })
  }

}
