import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from '../../free-report.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-depression-test-results',
  templateUrl: './depression-test-results.component.html',
  styleUrls: ['./depression-test-results.component.css']
})
export class DepressionTestResultsComponent implements OnInit {
  depressionFinal = "";
  anxietyFinal = "";
  depressionScore = 0;
  itemId;
  anhedonia = 1;
  depressedMood = 1;
  sleepDifficulties = 1;
  fatigue = 1;
  appetiteChanges = 1;
  worthlessness = 1;
  concentration = 1;
  agitation = 1;
  death = 1;
  title = 'Depression Analysis';
   type = 'PieChart';
   data = [
      ['Anhedonia', this.anhedonia],
      ['Depressed mood', this.depressedMood], 
      ['Sleep difficulties', this.sleepDifficulties], 
      ['Fatigue', this.fatigue], 
      ['Appetite changes', this.appetiteChanges], 
      ['Feeling of worthlessness', this.worthlessness], 
      ['Concentration difficulties', this.concentration], 
      ['Psychomotor agitation', this.agitation], 
      ['Thoughts of death', this.death], 
   ];
   options = {    
   };
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  answersArray: any[]
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.depressionScore = res["report"]["reportDetails"]["score"];
        let report = res['report'];
        this.answersArray = Object.values(report.reportDetails.answers)
        this.anhedonia = parseInt(this.answersArray[0],10)
        this.depressedMood = parseInt(this.answersArray[1],10);
        this.sleepDifficulties = parseInt(this.answersArray[2],10);
        this.fatigue = parseInt(this.answersArray[3],10)
        this.appetiteChanges = parseInt(this.answersArray[4],10);
        this.worthlessness = parseInt(this.answersArray[5],10);
        this.concentration = parseInt(this.answersArray[6],10);
        this.agitation = parseInt(this.answersArray[7],10);
        this.death = parseInt(this.answersArray[8],10);
        this.data = [
          ['Anhedonia', this.anhedonia],
          ['Depressed mood', this.depressedMood], 
          ['Sleep difficulties', this.sleepDifficulties], 
          ['Fatigue', this.fatigue], 
          ['Appetite changes', this.appetiteChanges], 
          ['Feeling of worthlessness', this.worthlessness], 
          ['Concentration difficulties', this.concentration], 
          ['Psychomotor agitation', this.agitation], 
          ['Thoughts of death', this.death], 
       ];
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "Depression",
            "Score" : this.depressionScore
          })
        }
        this.depressionfinalcal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'depression'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }

  depressionfinalcal() {
    if (this.depressionScore >= 0 && this.depressionScore <= 4) {
      this.depressionFinal = "negligible level of depression";
    } else if (this.depressionScore >= 5 && this.depressionScore <= 9) {
      this.depressionFinal = "mild level of depression";
    } else if (this.depressionScore >= 10 && this.depressionScore <= 14) {
      this.depressionFinal = "moderate level of depression";
    } else if (this.depressionScore >= 15 && this.depressionScore <= 19) {
      this.depressionFinal = "moderately severe level of depression";
    } else {
      this.depressionFinal = "severe level of depression";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
