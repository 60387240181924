import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistHelpdeskService } from "../therapist-helpdesk.service";

@Component({
  selector: "app-view-old-requests",
  templateUrl: "./view-old-requests.component.html",
  styleUrls: ["./view-old-requests.component.css"],
})
export class ViewOldRequestsComponent implements OnInit {
  isLoading = true;
  currentFilter = "OPEN";
  ticketdetails;
  isRequestLoading = true;
  filteredTickets = [];
  constructor(
    private helpdeskService: TherapistHelpdeskService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) { }

  ngOnInit() {
    this.helpdeskService.getOldRequests().subscribe((data) => {
      this.ticketdetails = data;
      this.onFilterRequests(this.currentFilter);
      this.isRequestLoading = false;
    }, (err) => {
      this.isRequestLoading = false;
    });
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  redirectTo(id) {
    this.router.navigateByUrl(
      "therapist-dashboard/viewsingleticket?ticketId=" + id
    );
  }
  onFilterRequests(status) {
    this.isLoading = true;
    this.filteredTickets = [];
    this.currentFilter = status;
    this.filteredTickets = this.ticketdetails.filter(
      (ticket) => ticket.status == status
    );
    this.isLoading = false;
    this.isRequestLoading = false;
  }
}
