<div class="formRound" (click)="closenavbar()">
  <div style="display: flex; align-items: center; margin-bottom: 20px">
    <span
      ><a
        routerLink="../profile"
        style="
          font-size: 16px;
          color: #07006e;
          cursor: pointer;
          text-decoration: underline;
        "
        >Profile</a
      ></span
    >
    <span style="font-size: 16px">&nbsp;>&nbsp;</span>
    <span style="font-size: 16px; color: #808080">Change Password</span>
  </div>
  <form [formGroup]="changePasswordForm" name="changepassForm">
    <div class="secPadding-top profileSection">
      <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
      <div class="sectionHeading">
        <h3>Change Password</h3>
        <hr />
      </div>
      <div class="eachGroupBox">
        <h3>Old Password</h3>
        <div class="input-group">
          <input
            [type]="showPasswordOldPass ? 'text' : 'password'"
            id="oldpassword"
            name="oldpassword"
            formControlName="password"
            class="form-control"
            placeholder=""
            [ngClass]="{
              'has-error':
                !changePasswordForm.controls.password.valid &&
                changePasswordForm.controls.password.touched,
              'has-success':
                changePasswordForm.controls.password.valid &&
                changePasswordForm.controls.password.touched
            }"
          />
          <span class="eyeIcon">
            <i
              class="fa"
              [ngClass]="{
                'fa-eye-slash': !showPasswordOldPass,
                'fa-eye': showPasswordOldPass
              }"
              (click)="showPasswordToggleOldPass()"
            ></i>
          </span>
        </div>
      </div>
      <div class="eachGroupBox">
        <h3>New Password</h3>
        <div class="input-group">
          <input
            [type]="showPasswordNewPass ? 'text' : 'password'"
            id="password"
            name="password"
            formControlName="npassword"
            class="form-control"
            placeholder=""
            [ngClass]="{
              'has-error':
                !changePasswordForm.controls.npassword.valid &&
                changePasswordForm.controls.npassword.touched,
              'has-success':
                changePasswordForm.controls.npassword.valid &&
                changePasswordForm.controls.npassword.touched
            }"
          />
          <span class="eyeIcon">
            <i
              class="fa"
              [ngClass]="{
                'fa-eye-slash': !showPasswordNewPass,
                'fa-eye': showPasswordNewPass
              }"
              (click)="showPasswordToggleNewPass()"
            ></i>
          </span>
        </div>
      </div>
      <div class="eachGroupBox">
        <h3>Confirm Password</h3>
        <div class="input-group">
          <input
            [type]="showPasswordConfPass ? 'text' : 'password'"
            id="cpassword"
            name="cpassword"
            formControlName="cpassword"
            class="form-control"
            placeholder=""
            [ngClass]="{
              'has-error':
                !changePasswordForm.controls.cpassword.valid &&
                changePasswordForm.controls.cpassword.touched,
              'has-success':
                changePasswordForm.controls.cpassword.valid &&
                changePasswordForm.controls.cpassword.touched
            }"
          />
          <span class="eyeIcon confirmpasswordEyeCss">
            <i
              class="fa"
              [ngClass]="{
                'fa-eye-slash': !showPasswordConfPass,
                'fa-eye': showPasswordConfPass
              }"
              (click)="showPasswordToggleConfPass()"
            ></i>
          </span>
        </div>
      </div>
    </div>
    <div class="text-center">
      <div
        *ngIf="isLoading"
        class=""
        style="display: flex; justify-content: center"
      >
        <!-- <div
          class="spinner-border"
          style="width: 2rem; height: 2rem; margin-top: 23px"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div> -->
        <ng-lottie
          height="150px"
          [options]="options"
          (animationCreated)="animationCreated($event)"
        ></ng-lottie>
      </div>
      <button class="thm-2" *ngIf="!isLoading" (click)="onSubmit()">
        Save
      </button>
    </div>
  </form>
</div>
