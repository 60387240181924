import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const bookingUrl = environment.apiUrl + '/user/booking';
@Injectable({
  providedIn: 'root'
})
export class AvailableSlotsService {

  constructor(private http: HttpClient) { }

  getSlots(id, startDate, endDate) {
    return this.http.get(bookingUrl + '/timeslots/' + id + '?startDate=' + startDate + '&endDate=' + endDate)
  }
  getSlotsV2(id, startDate, endDate) {
    return this.http.get(bookingUrl + '/v2/timeslots/' + id + '?startDate=' + startDate + '&endDate=' + endDate)
  }
}
