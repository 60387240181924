import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AuthService } from "./auth/auth.service";
import { filter, map, mergeMap } from "rxjs/operators";
import { TherapistAuthService } from "./therapist-auth/therapist-auth.service";
import { SideNavBarService } from "./side-nav-bar.service";
import { NotificationService } from "./user/notification.service";
import { SeoMetaService } from "./seo-meta.service";
import clevertap from "clevertap-web-sdk";
import { RegionSelectionService } from "./region-selection.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "Felicity";
  isVisible: Boolean = true;
  regionData;
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private therapistAuthService: TherapistAuthService,
    private sideNavService: SideNavBarService,
    private metaSeo: SeoMetaService,
    private regionSelectionService: RegionSelectionService
  ) { }
  async ngOnInit() {
    if (localStorage.getItem("regionData")) {
      this.regionData = JSON.parse(localStorage.getItem("regionData"))
    } else {
      this.regionData = await this.regionSelectionService.returnRegionData();
    }
    this.authService.autoAuthUser();
    this.therapistAuthService.autoAuthUser();
    this.regionSelectionService.setRegionData(() => { });
    this.metaSeo.updateTitle();
    window.onload = (event) => {
      this.loadGTMCid();
    };
    this.loadGTMCid();
    this.router.events
      .pipe(
        filter((events) => events instanceof NavigationEnd),
        map((evt) => this.activatedRoute),
        map((route) => {
          setTimeout(() => {
            this.activatedRoute.queryParams.subscribe((params) => {
              let dataObj = {};
              let eventName = "Page Visited";
              dataObj["title"] = document.title;
              dataObj["url"] = this.router.url;
              if (params["utm_source"]) {
                dataObj["utm_source"] = params["utm_source"];
              }
              if (params["utm_medium"]) {
                dataObj["utm_medium"] = params["utm_medium"];
              }
              if (params["utm_campaign"]) {
                dataObj["utm_campaign"] = params["utm_campaign"];
              }
              if (params["utm_id"]) {
                dataObj["utm_id"] = params["utm_id"];
              }
              if (params["utm_term"]) {
                dataObj["utm_term"] = params["utm_term"];
              }
              if (params["utm_content"]) {
                dataObj["utm_content"] = params["utm_content"];
              }
              if (
                params["utm_source"] ||
                params["utm_medium"] ||
                params["utm_campaign"] ||
                params["utm_id"] ||
                params["utm_term"] ||
                params["utm_content"]
              ) {
                eventName = "UTM Visited";
              }
              clevertap.event.push(eventName, dataObj);
            });
          }, 500);
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((x) =>
        x.header === true ? (this.isVisible = true) : (this.isVisible = false)
      );
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.shortlink) {
        switch (params.shortlink) {
          case "26bbb366":
            if (params.sessionId) {
              this.router.navigateByUrl(
                "/user-dashboard/join-session?sessionId=" + params.sessionId
              );
            }
            break;
          case "6b474c97":
            if (params.therapistId) {
              this.router.navigate([
                "/view-counsellor-calendar/" + params.therapistId,
              ]);
            }
            break;
          case "6640261b":
            if (params.therapistId) {
              this.router.navigate([
                "/view-counsellor-calendar/" + params.therapistId,
              ]);
            }
            break;
          case "c8672775":
            if (params.sessionId) {
              this.router.navigateByUrl(
                "/user-dashboard/give-rating/" + params.sessionId
              );
            }
            break;
          case "3c45358a":
            this.router.navigateByUrl("/user-dashboard/therapy-intake-form");
            break;
          case "57f550d6":
            this.router.navigateByUrl("/our-counselors");
            break;
          case "8xcq23dz":
            if (params.sessionId) {
              this.router.navigateByUrl('/therapist-dashboard/session-join?sessionId=' + params.sessionId)
            }
            break;
          case "3i4w5uxu":
            this.router.navigateByUrl('/therapist-dashboard/view-calendar')
            break;
          case "86wvvz3e":
            this.router.navigateByUrl('/therapist-dashboard/my-chats')
            break;
          case "y6v9egi8":
            this.router.navigateByUrl('/therapist-dashboard/profile')
            break;
          case "g81mernm":
            this.router.navigateByUrl('/therapist-dashboard/requestform')
            break;
          case "sx4epk46":
            if (params.noteId) {
              this.router.navigateByUrl('/therapist-dashboard/client-notes-edit/' + params.noteId + '?mode=edit')
            }
            break;
          case "xbol2q5p":
            if (params.userId) {
              this.router.navigateByUrl('/therapist-dashboard/client-notes-view/' + params.userId);
            }
            break;
          case "h3kwzti3":
            if (params.blogId) {
              this.router.navigateByUrl('/blog-view/' + params.blogId);
            }
            break;
          case "coo7gdwy":
            if (params.plan_name) {
              this.router.navigateByUrl('/order-summary/' + params.plan_name)
            }
            break;
          case "q1xix7id":
            this.router.navigateByUrl('/user-dashboard/requestform');
            break;
        }
      }
    });
  }

  loadGTMCid() {
    if (window["dataLayer"]) {
      let dataLayer = window["dataLayer"];
      dataLayer.forEach((data) => {
        if (data["cid"]) {
          localStorage.setItem("GTMcid", data["cid"]);
        }
      });
    }
  }

  closeSidenav() {
    this.sideNavService.closesidenav();
  }
}
