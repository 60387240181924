<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container mt-3">
    <p class="disclaimerTxt">
      <strong><i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for screening and
      monitoring symptom severity and cannot replace a clinical assessment and
      diagnosis. You are encouraged to share these results with a mental health
      professional for a better understanding.
    </p>
    <div class="report-user mt-3">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user">
      <h3>Congratulations on taking that first step!</h3>
      <p class="mt-3"><strong>Your Score:</strong></p>
      <p>Depression Assessment : {{ depressionScore }}</p>
      <p class="mt-2"><strong>Your Result:</strong></p>
      <p>
        Your responses indicate a <span>{{ depressionFinal }}. <span class="scrollTxt"
            (click)="scrollToElem('target')">Detailed Analysis</span></span>
      </p>
    </div>
  </div>

  <div class="container graph-section mt-3">
    <div>
      <p class="mb-3" *ngIf="depressionScore >= 0 && depressionScore <= 4">
        Your results may not be a sign of concern, but read on to find out a more detailed analysis of your score.  Felicity is here to assist you in improving your mental well-being.
      </p>
      <p class="mb-3" *ngIf="depressionScore >= 5 && depressionScore <= 14">
        Your results may be slightly concerning, but do not worry too much - Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.
      </p>
      <p class="mb-3" *ngIf="depressionScore >= 15 && depressionScore <= 27">
        Seeing the report can be overwhelming, but Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.
      </p>
    </div>
    <h5>Understanding Your Depression Assessment</h5>
    <div class="graph-right">
      <img src="../../../assets/images/free-report-graphs/phq9-depression.png" />
    </div>
  </div>

  <div class="container">
    <div class="about-scores report-user">
      <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
      <p class="mb-3">
        The possible range of scores is 0 to 27, 0 indicates ‘no symptoms of
        depression at all’ and 27 indicates ‘severely high depression’.
      </p>
      <p class="para_scores mb-3" class="para_scores mb-3" id="target" *ngIf="depressionScore >= 0 && depressionScore <= 4">
        A score of 0 to 4 indicates minimal symptoms of depression which is a
        decent state to maintain. This feels pleasant and would be the ideal
        state to be in, so keep monitoring your condition.
      </p>
      <p class="para_scores mb-3" class="para_scores mb-3" id="target" *ngIf="depressionScore >= 5 && depressionScore <= 9">
        A score of 5 to 9 indicates mild symptoms of depression. You may
        experience some mild symptoms of depression depending on your triggers.
        For this, we bring to you our mindfulness toolkit which you can use to
        feel better. You can always get in touch with our mental health
        professionals if you feel overwhelmed by all of this.
      </p>
      <p class="para_scores mb-3" class="para_scores mb-3" id="target" *ngIf="depressionScore >= 10 && depressionScore <= 14">
        A score of 10 to 14 indicates moderate levels of depression. This is the
        borderline range and you should focus on yourself a little more than
        usual. You may be experiencing symptoms of depression at a moderately
        high level. We recommend that you talk to one of our mental health
        professionals to help you through this. We assure to provide you with
        the best help in this time of need.
      </p>
      <p class="para_scores mb-3" id="target" class="para_scores mb-3" id="target" *ngIf="depressionScore > 15">
        Scores higher than 15 indicate moderately severe and severe levels of
        depression. It is advisable to get your report and condition checked by
        a mental health professional. Things can seem out of control to you with
        no sight of positivity in the future but this is where Felicity steps in
        to be there for you. A combination of medication and psychotherapy will
        serve best in this time to help you get back on track.
      </p>
    </div>
  </div>

  <!-- TODO: Add CSS -->
  <br />
  <div class="container about-scores">
    <div class="report-user">
      <h3 class="">Detailed Analysis</h3>
      <p class="mb-3">
        PHQ-9 assesses 9 components. Each one of them will be discussed here
        one-by-one along with your scores.
      </p>

      <p class="mb-3">
        <strong>Anhedonia</strong> is the feeling of not enjoying something you
        once enjoyed. Scores range from 0 to 3, with 0 being negligible and 3
        being high. Your score is {{ answersArray[0] }}.
      </p>
      <p class="mb-3">
        <strong>Depressed mood</strong> is a constant feeling of sadness and
        lack of interest. Scores range from 0 to 3, with 0 being negligible and
        3 being high. Your score is {{ answersArray[1] }}.
      </p>

      <p class="mb-3">
        <strong>Sleep difficulties</strong> indicate trouble falling asleep and
        poor quality of sleep. Scores range from 0 to 3, with 0 being negligible
        and 3 being high. Your score is {{ answersArray[2] }}.
      </p>
      <p class="mb-3">
        <strong>Fatigue</strong> occurs when one feels tired all day due to
        physical or mental exertion. Scores range from 0 to 3, with 0 being
        negligible and 3 being high. Your score is {{ answersArray[3] }}.
      </p>
      <p class="mb-3">
        <strong>Appetite changes</strong> indicate an alteration in your food
        intake - increase or decrease from your normal capacity. Scores range
        from 0 to 3, with 0 being negligible and 3 being high. Your score is
        {{ answersArray[4] }}.
      </p>
      <p class="mb-3">
        <strong>Feeling of worthlessness</strong> means experiencing feelings of
        hopelessness and feeling insignificant. Scores range from 0 to 3, with 0
        being negligible and 3 being high. Your score is {{ answersArray[5] }}.
      </p>
      <p class="mb-3">
        <strong>Concentration difficulties</strong> include the inability to pay
        attention to and focus on details of your current work. Scores range
        from 0 to 3, with 0 being negligible and 3 being high. Your score is
        {{ answersArray[6] }}.
      </p>
      <p class="mb-3">
        <strong>Psychomotor agitation</strong> is the feeling of restlessness
        and involuntary movements like tapping, pacing and more. Scores range
        from 0 to 3, with 0 being negligible and 3 being high. Your score is
        {{ answersArray[7] }}.
      </p>
      <p class="mb-3">
        <strong>Thoughts of death</strong> means thinking of death without
        acting on it immediately. Scores range from 0 to 3, with 0 being
        negligible and 3 being high. Your score is {{ answersArray[8] }}.
      </p>
    </div>

    <div class="mt-4 mb-4">
      <p>
        It sure can be overwhelming to see such a detailed analysis of your
        report. You can contact Felicity and take steps for yourself if you are
        seeking any type of mental health care.
      </p>
    </div>
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>

  <!-- <div class="disclaimer-section">
    <div class="head-disclaimer">
      <h3>Disclaimer</h3>
      <p>
        This tool should be used for screening and monitoring symptom severity
        and cannot replace a clinical assessment and diagnosis. You are
        encouraged to share these results with a mental health professional.
        Felicity disclaims any liability, loss or risk incurred as a consequence
        , directly or indirectly, from the use of this assessment & it's
        results.
      </p>
    </div>
  </div> -->
</div>