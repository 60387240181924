<div class="bodyPageWrap">
    <div *ngIf="isLoading">
      <ngx-skeleton-loader
        appearance="line"
        [theme]="{
          'border-radius': '12px',
          height: '200px',
          display: 'flex !important',
          'margin-top': '3px',
          'margin-right': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>

    <!-- <ng-template #displayNoneShimmer> -->
    <div class="secPadding joinBox" *ngIf="status == 'NOT_STARTED'">
      <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
      <div class="row align-items-center">
        <div class="col-sm-3">
          <div class="joinBoxImageHolder">
            <img
              src="../../../assets/images/blue-yellow-session-icon.svg"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-6 col-sm-9">
          <div class="joinBoxTxt">
            <h3>Your upcoming session starts in {{ remainingTimeText }}</h3>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="joinButton">
            <button (click)="joinCall()" class="buttonTheme1">
              Join Session
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- </ng-template> -->
    <div class="secPadding joinBox" *ngIf="status === 'NOT_FOUND'">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="joinBoxTxt">
            <h3>
              This session does not belong to the logged in user. Make sure you
              are using the correct credentials to login.
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="secPadding joinBox" *ngIf="status == 'ABOUT_TO_START'">
      <div class="row align-items-center">
        <div class="col-sm-3">
          <div class="joinBoxImageHolder">
            <img
              src="../../../assets/images/blue-yellow-session-icon.svg"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-6 col-sm-9">
          <div class="joinBoxTxt">
            <h3>Ready to start the session?</h3>
            <p>
              Please ensure that you have given the required permission to your
              camera & microphone.
            </p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="joinButton">
            <button (click)="joinCall()" class="buttonTheme1">START NOW</button>
          </div>
        </div>
      </div>
    </div>
    <div class="previewBox" *ngIf="status == 'ABOUT_TO_START'">
      <div class="videoChatBox">
        <video
          class="userPreviewVideo"
          id="inputPreview"
          autoplay
          [ngClass]="{ hiddenVideo: !isVideo }"
        ></video>
        <!-- <div class="audioIndCont">
        <div class="audioIndicator" [style.height.px]="audioLevel" [style.width.px]="audioLevel">
      </div>
      </div> -->
        <span class="helperSpan" [ngClass]="{ hiddenVideo: isVideo }"></span>
        <img
          src="{{ imageUrl }}/{{ sessionData?.userId?.imageUrl }}"
          draggable="false"
          alt="img"
          class="img-fluid userPreviewImage"
          [ngClass]="{ hiddenVideo: isVideo }"
        />
        <div class="videoControl localVideo">
          <a
            title="Un-Mute Audio"
            [hidden]="isAudio"
            *ngIf="isMicAvailable"
            style="cursor: pointer"
            (click)="toggleLocalAudio()"
            class="vOrng"
            ><img src="../../../assets/images/mic_off.svg"
          /></a>
          <a
            title="Mute Audio"
            [hidden]="!isAudio"
            *ngIf="isMicAvailable"
            style="cursor: pointer"
            (click)="toggleLocalAudio()"
            class="vOrng"
            ><img src="../../../assets/images/mic_on_small.svg"
          /></a>
          <a
            title="Error Audio"
            *ngIf="!isMicAvailable"
            style="cursor: pointer"
            (click)="retryMedia('audio')"
            class="vOrng"
            ><img src="../../../assets/images/mic_error.svg"
          /></a>
          <a
            title="Switch off Video"
            [hidden]="!isVideo"
            *ngIf="isCamAvailable"
            style="cursor: pointer"
            (click)="toggleLocalVideo()"
            class="vOrng"
            ><img src="../../../assets/images/camera_on_big.svg"
          /></a>
          <a
            title="Switch on Video"
            *ngIf="isCamAvailable"
            [hidden]="isVideo"
            style="cursor: pointer"
            (click)="toggleLocalVideo()"
            class="vOrng"
            ><img src="../../../assets/images/camera_off_darker.svg"
          /></a>
          <a
            title="Error Video"
            [hidden]="!isVideo"
            *ngIf="!isCamAvailable"
            style="cursor: pointer"
            (click)="retryMedia('video')"
            class="vOrng"
            ><img src="../../../assets/images/cam_error.svg"
          /></a>
        </div>
      </div>
    </div>
    <div class="secPadding" *ngIf="status == 'STARTED'">
      <!-- Remote audio : {{remoteAudioMuted}}
    Remote video : {{remoteVideoMuted}} -->
      <div class="reportBtnHolder">
        <button
          class="buttonTheme2"
          [routerLink]="['../requestform']"
          [queryParams]="{ report: 'session', sessionId: roomName }"
        >
          Report a problem
        </button>
      </div>
      <div class="videoChatBox" id="videCallBox">
        <video class="remoteVideo" *ngIf="isRemoteJoined" id="remote"></video>
        <div *ngIf="!isRemoteJoined" class="loadingAnimation">
          <img src="../../../assets/images/video-chat/wait_screen.gif" />
          <p>Still the mind. Inhale peace. Exhale worries.</p>
          <!-- <br> -->
          <p class="plsWaitText">Your therapist will join soon...</p>
        </div>
        <div
          *ngIf="isRemoteJoined && remoteVideoMuted"
          class="loadingAnimation"
        >
          <img src="{{ imageUrl }}/{{ sessionData?.therapistId?.imageUrl }}" />
        </div>
        <div class="inputPreview">
          <video *ngIf="isCamAvailable" class="inputVideo" id="inputPreview" autoplay></video>
        </div>
        <div class="fullScreen">
          <a
            title="Full Screen"
            [hidden]="isFullScreen"
            style="cursor: pointer"
            (click)="fullScreenToggle()"
            class=""
            ><img src="../../../assets/images/video-chat/full screen.svg"
          /></a>
          <a
            title="Exit Full Screen"
            style="cursor: pointer"
            [hidden]="!isFullScreen"
            (click)="fullScreenToggle()"
            class=""
            ><img src="../../../assets/images/video-chat/normal screen.svg"
          /></a>
        </div>
        <div class="videoControl">
          <a
            title="Switch off Video"
            [hidden]="!isVideo"
            *ngIf="isCamAvailable"
            style="cursor: pointer"
            (click)="videoToggle()"
            class="vOrng"
            ><img src="../../../assets/images/camera_on_big.svg"
          /></a>
          <a
            title="Switch on Video"
            [hidden]="isVideo"
            *ngIf="isCamAvailable"
            style="cursor: pointer"
            (click)="videoToggle()"
            class="vOrng"
            ><img src="../../../assets/images/camera_off_darker.svg"
          /></a>
          <a
            title="Error Video"
            [hidden]="!isVideo"
            *ngIf="!isCamAvailable"
            style="cursor: pointer"
            (click)="retryMedia('video')"
            class="vOrng"
            ><img src="../../../assets/images/cam_error.svg"
          /></a>
          <a
            title="End Call"
            (click)="endCall()"
            style="cursor: pointer"
            class="vred"
            ><img src="../../../assets/images/video-chat/end call.svg"
          /></a>
          <a
            title="Un-Mute Audio"
            [hidden]="isAudio"
            *ngIf="isMicAvailable"
            style="cursor: pointer"
            (click)="audioToggle()"
            class="vOrng"
            ><img src="../../../assets/images/mic_off.svg"
          /></a>
          <a
            title="Mute Audio"
            [hidden]="!isAudio"
            *ngIf="isMicAvailable"
            style="cursor: pointer"
            (click)="audioToggle()"
            class="vOrng"
            ><img src="../../../assets/images/mic_on_small.svg"
          /></a>
          <a
            title="Error Audio"
            *ngIf="!isMicAvailable"
            style="cursor: pointer"
            (click)="retryMedia('audio')"
            class="vOrng"
            ><img src="../../../assets/images/mic_error.svg"
          /></a>
        </div>
      </div>
    </div>
    <div class="secPadding joinBox" *ngIf="status == 'CALL_ENDED'">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="joinBoxTxt">
            <h3>You've left the session</h3>
          </div>
        </div>
      </div>
      <div class="row justify-content-around">
        <div class="col-md-3">
          <button class="buttonTheme1" (click)="rejoin()">Rejoin</button>
        </div>
        <div class="col-md-3">
          <button
            class="buttonTheme2"
            routerLink="../give-rating/{{ roomName }}"
          >
            Rate Session
          </button>
        </div>
      </div>
    </div>
</div>
