import { AvailableSlotsDialogComponent } from './available-slots-dialog/available-slots-dialog.component';
import { Injectable, NgModule } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
@Injectable({
  providedIn: 'root'
})

export class AvailableSlotsDialogService {

  constructor(private modalService: BsModalService) { }

  public open(title: string,
    selectedDate: string,
    selectedSlots: Array<any>,
    bookedSlots: Array<any>,
    startDate: string,
    endDate: string,
    prevDateSlots: Array<any>,
    nextDateSlots: Array<any>,
    weeklySlots,
    userTimezone
  ): Subject<boolean> {
    const initialState = {
      selectedSlots: selectedSlots,
      bookedSlots: bookedSlots,
      selectedDate: selectedDate,
      startDate: startDate,
      endDate: endDate,
      prevDateSlots: prevDateSlots,
      nextDateSlots: nextDateSlots,
      weeklySlots: weeklySlots,
      userTimezone: userTimezone
    };

    let modalRef = this.modalService.show(AvailableSlotsDialogComponent, {
      initialState,
      class: 'modal-dialog-centered modal-lg',
      ignoreBackdropClick : true,
    })
    modalRef.content.title = title;
    modalRef.content.selectedDate = selectedDate;
    modalRef.content.bookedSlots = bookedSlots;
    modalRef.content.selectedSlots = selectedSlots;
    return modalRef.content.onClose
  }
}
