<div class="bodyPageWrap" (click)="closenavbar()">
    <div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div class="sectionHeading">
              <h2>Account related FAQ's</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div id="1" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>
                What kind of tech set up do I need for an optimal session
                experience?
              </h4>
  
              <p class="ptagCss">
                You can access felicity anytime, anywhere, from any device. All
                you need is an internet connection. You can do a video/audio call
                via the felicity app or an audio call using your device.
              </p>
            </div>
          </div>
          <div id="2" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>
                Can I be sure of the privacy and anonymity in the felicity chat
                room?
              </h4>
  
              <p class="ptagCss">
                Your privacy is sacrosanct and not to be violated in any scenario
                if you interact via this platform. We use banking grade encryption
                to secure conversations between you and your talk therapist. And
                whatever personal information you share on this platform is not
                used for any other purpose than the one stated here.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div >
              <h3>
                Have More Questions?
                <span class="spanCss">(Access following heads)</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../subscription-faq">Subscription Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../talk-therapy-faq">Talk therapy Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../program-related-faq">Program Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../technology-related-faq">Technology Related</a></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>