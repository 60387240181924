import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const guestBlogUrl = environment.apiUrl + '/guest/blog/';
const userBlogUrl = environment.apiUrl + '/user/blog/';
const therapistBlogUrl = environment.apiUrl + '/therapist/blog/';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient) { }

  getFeaturedBlog(){
    return this.http.get(guestBlogUrl + 'get-featured-post')
  }

  getLatestBlogs(){
    return this.http.get<any[]>(guestBlogUrl + 'get-latest-posts')
  }

  getBlogBySlug(slug){
    return this.http.get(guestBlogUrl + 'get-blog-by-slug/' + slug)
  }

  toggleLikeByUser(id){
    return this.http.get(userBlogUrl + 'like/' + id)
  }

  toggleLikeByTherapist(id){
    return this.http.get(therapistBlogUrl + 'like/' + id)
  }

  getBlogs(paginObject){
    return this.http.post(guestBlogUrl + 'get-all', paginObject)
  }

  getBlogsForHomePage(){
    return this.http.get<any[]>(guestBlogUrl + 'get-blogs-home')
  }

  addClap(id){
    return this.http.get<any[]>(guestBlogUrl + 'add-clap/' + id)
  }

}
