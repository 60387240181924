<div (click)="closenavbar()" class="topMenuMargin">
  <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
  <div class="container headingCss">
    <div class="secondaryHeading">
      <h2>Complete your profile for best experience!</h2>
      <hr />
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="formRound">
          <form [formGroup]="profileForm" name="userDataForm">
            <div class="sectionPadding-top">
              <div class="insideFormDivTags">
                <h3>Name</h3>
                <div>
                  <div class="table">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="name"
                    />
                  </div>
                </div>
              </div>
              <div class="insideFormDivTags">
                <h3>Email</h3>
                <div class="table">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                  />
                </div>
              </div>
              <div class="insideFormDivTags">
                <h3>Phone</h3>
                <div class="table" *ngIf="userCountryCode">
                  <input
                  type="text"
                  id="phoneInput"
                  formControlName="phone"
                  class="phoneControl"
                  [ngClass]="{
                    'has-error':
                      profileForm.controls.phone.errors?.invalidPhone
                    }"
                  ng2TelInput
                  (hasError)="checkError($event)"
                  (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }"
                />
                </div>
                <div
                  *ngIf="
                    profileForm.controls.phone.errors?.required &&
                    profileForm.controls.phone.touched
                  "
                  class="errorContainer"
                >
                  <p>Phone is required</p>
                </div>
                <div class="errorContainer" *ngIf="profileForm.controls.phone.errors?.invalidPhone">
                  <p>Please enter a valid phone number.</p>
                </div>
              </div>
              <div class="submitButton text-center">
                <button (click)="onSubmit()" class="buttonTheme1">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
