import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TherapistAuthService } from './therapist-auth.service';
@Injectable({
  providedIn: 'root'
})
export class TherapistAuthGuard implements CanActivate {
  constructor( private therapistAuthService: TherapistAuthService, private router: Router ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.therapistAuthService.autoAuthUser();
    const isAuth =  this.therapistAuthService.getIsAuth();
    if(!isAuth){
      this.router.navigate(['/therapist-login'], { queryParams : { authRed : state.url } });
    }
    return isAuth;
  }
  
}
