import { Component, OnInit, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RegionSelectionService } from 'src/app/region-selection.service';
@Component({
  selector: 'app-tawk-widget',
  templateUrl: './tawk-widget.component.html',
  styleUrls: ['./tawk-widget.component.css']
})
export class TawkWidgetComponent implements OnInit {
  salesIq = this._renderer.createElement('script');
  interaktScript = this._renderer.createElement('script');
  tawkTo = this._renderer.createElement('script');

  regionData;
  constructor(private _renderer: Renderer2, @Inject(DOCUMENT) private _document, private regionSelectionService: RegionSelectionService) { }

  async ngOnInit() {
    if (localStorage.getItem("regionData")) {
      this.regionData = JSON.parse(localStorage.getItem("regionData"))
    } else {
      this.regionData = await this.regionSelectionService.returnRegionData();
    }
    this.salesIq.text = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "f1205aee03d81fa0700cc880a600fbdcebf2bc9e4da10c47d81932fd4d8be42b", mode : "async",values:{},ready:function(){$zoho.salesiq.visitor.getGeoDetails();}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.in/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);$zoho.salesiq.afterReady=function(visitorgeoinfo){if(visitorgeoinfo.Country!=="INDIA"){$zoho.salesiq.floatbutton.visible("hide");}}`
    this.interaktScript.text = `(function(w,d,s,c,r,a,m){w['KiwiObject']=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)};w[r].l=1*new Date();a=d.createElement(s);m=d.getElementsByTagName(s)[0];a.async=1;a.src=c;m.parentNode.insertBefore(a,m)})(window,document,'script','https://app.interakt.ai/kiwi-sdk/kiwi-sdk-17-prod-min.js?v='+ new Date().getTime(),'kiwi');window.onload=function(){kiwi.init('','hNdOoWoMrw68WSARC4BymQqFloLlfWkK',{});}`
    this._renderer.appendChild(this._document.body, this.salesIq);
    if (this.regionData?.tag === 'IN') {
      this._renderer.appendChild(this._document.body, this.interaktScript);
    } else {
      this.tawkTo.text = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();(function(){var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/604cf438067c2605c0b8070c/1f0m9jvt6';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
      })();`
      this._renderer.appendChild(this._document.body, this.tawkTo)
    }
  }
}
