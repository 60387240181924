import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { FreeReportService } from "../../free-report.service";
import clevertap from "clevertap-web-sdk";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-angry-test-results",
  templateUrl: "./angry-test-results.component.html",
  styleUrls: ["./angry-test-results.component.css"],
})
export class AngryTestResultsComponent implements OnInit {
  angryFinal = "";
  angryScore;
  itemId;
  subScore1 = 0;
  subScore2 = 0;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private actRoute: ActivatedRoute,
    private freeReportService: FreeReportService,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.angryScore = res["report"]["reportDetails"]["score"];
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "Clinical Anger",
            "Score" : this.angryScore
          })
        }
      });
    });
    this.angryfinalcal();
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'anger'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  bookConsultation() {
  }
  angryfinalcal() {
    if (this.angryScore >= 0 && this.angryScore <= 13) {
      this.angryFinal = "Minimal";
    } else if (this.angryScore >= 14 && this.angryScore <= 19) {
      this.angryFinal = "Mild";
    } else if (this.angryScore >= 20 && this.angryScore <= 28) {
      this.angryFinal = "Moderate";
    } else if (this.angryScore >= 29 && this.angryScore <= 63) {
      this.angryFinal = "Severe";
    }
  }
  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
