import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { FreeReportService } from "../../free-report.service";
import clevertap from "clevertap-web-sdk";
import { ViewportScroller } from "@angular/common";
@Component({
  selector: 'app-financial-stress-test-results',
  templateUrl: './financial-stress-test-results.component.html',
  styleUrls: ['./financial-stress-test-results.component.css']
})
export class FinancialStressTestResultsComponent implements OnInit {

  financial_stress_Result = "";
  report;
  financial_stress_score;
  itemId;
  answersArray = []
  financial_stress_sub_result;
  details_score
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.financial_stress_score = res["report"]["reportDetails"]["score"];
        this.answersArray = Object.values(res['report']["reportDetails"]["answers"]);
        if (clevertap) {
          clevertap.event.push("Free Report Ended", {
            "Report Name": "Financial Stress",
            "Score": this.financial_stress_score
          })
        }
        this.financial_stress_score = Math.floor(this.financial_stress_score/8);
        this.financialFinalcal();
        console.log(this.financial_stress_score)
      });
    });
  }
  reportData() {
    let reqObj = {
      id: this.itemId,
      reportType: 'financial-stress'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if (res) {
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }

  financialFinalcal() {
    if (this.financial_stress_score == 1) {
      this.financial_stress_Result = "Your score indicates overwhelming financial distress."
      this.details_score = " Your score is on the higher end of the financial distress scale, which means that your financial well-being is at the lowest point. You may not feel secure in your finances and may feel like you have no control over meeting your monthly or daily financial requirements. This can have an impact on other aspects of your life as well, such as your productivity and your personal relationships, so it is best to seek help from a mental health professional to understand how to deal with your financial anxiety and work towards a stable future. You can get in touch with Felicity with the assurance of getting the best help that you require.";
    }
    else if (this.financial_stress_score == 2) {
      this.financial_stress_Result = "Your score indicates extremely high financial distress."
      this.details_score = "Your score is on the higher end of the financial distress scale, which means that your financial well-being is extremely low. This entails issues with finances of any kind: overspending, the fear of spending, hoarding, or all in all, acting obsessively towards money. It is natural that any kind of financial hardship can cause anxiety, but knowing how to deal with it is essential for one’s well-being. Since this can have an impact on other aspects of your life as well, such as your productivity and your personal relationships, it is best to seek help from a mental health professional to understand how to deal with your financial anxiety and work towards a stable future. Felicity can help you with the same, with their psychologists and psychotherapists available 24*7.";
    }
    else if (this.financial_stress_score == 3) {
      this.financial_stress_Result = "Your score indicates very high financial distress."
      this.details_score = "Your score is on the higher end of the financial distress scale, which means that your financial well-being is very low. You may not feel secure in your finances and may feel like you have no control over meeting your monthly or daily financial requirements. This can have an impact on other aspects of your life as well, such as your productivity and your personal relationships. Alleviating financial stress and reaching stability is important so that continually worrying about your bills isn’t the only thing that’s on your mind, and you can ";
    }
    else if (this.financial_stress_score == 4) {
      this.financial_stress_Result = "Your score indicates high financial distress."
      this.details_score = " Your score indicates high financial distress, meaning that your financial wellbeing is on the lower end. Financial distress can be the cause of several other problems in your life, relating to relationships, work, motivation, and other domains. Alleviating financial stress and reaching stability is important so that continually worrying about your bills isn’t the only thing that’s on your mind. ";
    }
    else if (this.financial_stress_score == 5) {
      this.financial_stress_Result = "Your score indicates average financial distress."
      this.details_score = "Your score for financial stress falls on the average level. It is normal to feel stressed about finances when a big change has happened, as could be the case with you. This could be due to moving to a different place, a loss of a job, or other reasons that may have led to a spike in your financial distress. However, this is not a desirable level of financial well-being, and if this persists, you may experience more stress over time. You can take measures you manage your finances yourself, or if you feel that they have become out of your control, you can consult a therapist at Felicity to help you manage your stress so it doesn’t affect other domains of your life.";
    }
    else if (this.financial_stress_score == 6) {
      this.financial_stress_Result = "Your score indicates moderate financial distress."
      this.details_score = "You are experiencing moderate levels of financial distress, which might be caused due to a sudden change in your life that has led to a loss of financial stability in some way. This could be due to moving to a different place, a loss of a job, or other reasons that may have led to a spike in your financial distress. However, this is not a desirable level of financial well-being, and if this persists, you may experience more stress over time. You can take measures you manage your finances yourself, or if you feel that they have become out of your control, you can consult a therapist at Felicity to help you manage your stress so it doesn’t affect other domains of your life.";
    }
    else if (this.financial_stress_score == 7) {
      this.financial_stress_Result = "Your score indicates low financial distress."
      this.details_score = "Your score means that you have low financial stress, and are experiencing an adequate level of financial wellbeing. Though finances might not be your biggest concern at the moment — as you are in a comfortable position — they are still affecting your wellbeing in some way. To prevent the stress due to finances from becoming too overwhelming, so much so that it strains other domains in your life, it is important to take the necessary precautions and manage your finances as well as your worries about them. You can consult a therapist at Felicity to help you manage your stress so it doesn’t affect other domains of your life.";
    }
    else if (this.financial_stress_score == 8) {
      this.financial_stress_Result = "Your score indicates very low financial distress."

      this.details_score = " Very low financial distress means that your financial wellbeing is at a good level. You are capable of managing your finances, and they don’t affect your ability to live a good and fulfilling life. You should aim to keep this, streamlining your budget in an effective manner. If you feel that you might need assistance with managing any future stress related to your finances, you can always get in touch with the psychologists at Felicity for the same.";
    }
    else if (this.financial_stress_score == 9) {
      this.financial_stress_Result = "Your score indicates extremely low financial distress."
      this.details_score = " Extremely low financial distress indicates very good financial well-being, and you should aim to maintain this level of well-being. It indicates that you are capable of managing your finances, and they don’t affect your ability to live a good and fulfilling life. It is recommended to have a good support system in place as and when you need to talk about any stress you might be facing due to finances.";
    }
    else if (this.financial_stress_score == 10) {
      this.financial_stress_Result = "Your score indicates no financial distress."
      this.details_score = " No financial distress indicates very high financial well-being — you should aim to maintain this level of well-being. It indicates that you are capable of managing your finances, and they don’t affect your ability to live a good and fulfilling life. You are secure in your financial future and are able to enjoy life to the fullest. ";
    }

    if (this.financial_stress_score >= 1 && this.financial_stress_score <= 3) {
      this.financial_stress_sub_result = "Seeing the report can be overwhelming, but Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.";
    } else if (this.financial_stress_score >= 4 && this.financial_stress_score <= 7) {
      this.financial_stress_sub_result = "Your results may be slightly concerning, but do not worry too much - Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.";
    } else {
      this.financial_stress_sub_result = "Your results may not be a sign of concern, but read on to find out a more detailed analysis of your score. Felicity is here to assist you in improving your mental well-being";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
