<div class="secPadding" (click)="closenavbar()">
  <div class="container">
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="secondaryHeading">
      <h2>Login</h2>
      <hr />
      <br />
    </div>
    <div class="signUpLine">
      <h3>
        Help us make the world happier.
        <a
          routerLink="/counseling-therapy-clinical-psychology-jobs/get-onboard/questions"
        >
          Apply Now
        </a>
      </h3>
    </div>
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="loginForm formRound">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
            <div>
              <form
                [formGroup]="loginForm"
                (ngSubmit)="onSubmit()"
                name="loginForm"
              >
                <div class="form-group">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    formControlName="email"
                    placeholder="Email"
                    class="form-control"
                    (change)="trimInput()"
                    [ngClass]="{
                      'has-error':
                        !loginForm.controls.email.valid &&
                        loginForm.controls.email.touched,
                      'has-success':
                        loginForm.controls.email.valid &&
                        loginForm.controls.email.touched
                    }"
                  />
                  <div
                    *ngIf="
                      loginForm.controls.email.errors?.required &&
                      loginForm.controls.email.touched
                    "
                    class="errorContainer"
                  >
                    <p>Email is required</p>
                  </div>
                  <div
                    *ngIf="
                      loginForm.controls.email.errors?.pattern &&
                      loginForm.controls.email.touched
                    "
                    class="errorContainer"
                  >
                    <p>Email is invalid</p>
                  </div>
                </div>
                <div class="input-group">
                  <input
                    [type]="isShowPassword ? 'text' : 'password'"
                    name="password"
                    id="password"
                    formControlName="password"
                    placeholder="Password"
                    class="form-control"
                    [ngClass]="{
                      'has-error':
                        !loginForm.controls.password.valid &&
                        loginForm.controls.password.touched,
                      'has-success':
                        loginForm.controls.password.valid &&
                        loginForm.controls.password.touched
                    }"
                  />
                  <span class="eyeIcon">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-eye-slash': !isShowPassword,
                        'fa-eye': isShowPassword
                      }"
                      (click)="showPasswordToggle()"
                    ></i>
                  </span>
                </div>
                <div
                  *ngIf="
                    loginForm.controls.password.errors?.required &&
                    loginForm.controls.password.touched
                  "
                  class="errorContainer"
                >
                  <p>Password is required</p>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <div class="checkBox">
                        <input
                          type="checkbox"
                          name="rememberMe"
                          id="rememberMe"
                        />
                        <span>Remember Me</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="forgotPassWrap">
                        <a routerLink="../forgot-password-therapist"
                          >Forgot Password?</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div *ngIf="isLoading" class="d-flex justify-content-start">
                    <div
                      class="spinner-border"
                      style="
                        width: 2rem;
                        height: 2rem;
                        margin-left: 55px;
                        margin-top: 23px;
                      "
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                      
                    </div>
                    <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
                  </div>
                  <button
                    *ngIf="!isLoading"
                    class="buttonTheme1"
                    type="submit"
                    [disabled]="!loginForm.valid"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
