<div class="bodyPageWrap">
    <div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div class="sectionHeading">
              <h2>Subscription Related FAQ's</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div id="1" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>What if I am not happy with the talk therapist?</h4>
              <ul>
                <li>
                  We take your feedback after every session. At any point you can
                  ask for a change of therapist and we connect you to a new one
                  within 24 hours.
                </li>
              </ul>
            </div>
          </div>
          <div id="2" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>What if I want to reschedule a session?</h4>
              <ul>
                <li>
                  You can send a reschedule request directly to the counsellor
                  from the felicity platform (your felicity profile> reschedule).
                  If this doesn’t work, you get in touch with the help desk via
                  call or chat.
                </li>
              </ul>
            </div>
          </div>
          <div id="3" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>What if I miss a session?</h4>
              <ul>
                <li>
                  If you miss a session without a 12 hours prior notice, you will
                  be charged for that session. Please note that our therapist earn
                  by the hour and this fee is used to compensate them for the time
                  that they could have utilized for another session.
                </li>
              </ul>
            </div>
          </div>
          <div id="4" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>
                Can I connect with my therapist apart from the scheduled sessions?
              </h4>
              <ul>
                <li>
                  Yes, you can chat with your therapist on the felicity app. The
                  therapist will respond 2-3 times a day.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div >
              <h3>
                Have More Questions?
                <span class="spanCss">(Access following heads)</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../talk-therapy-faq">Talk therapy Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../program-related-faq">Program Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../account-related-faq">Account Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../technology-related-faq">Technology Related</a></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  