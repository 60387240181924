import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { BookingService } from "src/app/therapist/booking.service";
import { TherapistRaiseRequestService } from "../therapist-raise-request.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: "app-sessions-report",
  templateUrl: "./sessions-report.component.html",
  styleUrls: ["./sessions-report.component.css"],
})
export class SessionsReportComponent implements OnInit {
  sessions = [];
  isLoading = false;
  startDate = new Date();
  endDate = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  searchString = "";
  totalEarnings = 0;
  averageRating = 0;

  constructor(
    private sessionsService: BookingService,
    private raiseRequestService: TherapistRaiseRequestService,
    private confirmationService: ConfirmationDialogService,
    private alertService: BootstrapAlertService
  ) {}

  
  options: AnimationOptions = {
      path: '/assets/loader.json',
    };
  
    animationCreated(animationItem: AnimationItem): void {
      console.log(animationItem);
    }
  ngOnInit(): void {
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    this.startDate.setDate(this.startDate.getDate() - 30);
    this.getCompletedSessions();
  }

  getCompletedSessions() {
    this.isLoading = true;
    this.sessionsService
      .getCompletedSessions(
        this.startDate.toISOString(),
        this.endDate.toISOString(),
        this.searchString
      )
      .subscribe((res) => {
        // this.isLoading = true;
        this.sessions = res["completedSessions"];
        let ratingCount = 0
        let totalRating = 0
        this.sessions.forEach((session) => {
          this.totalEarnings+=session.earning
          if(session.ratingId){
            ratingCount++;
            totalRating+=session.ratingId.rating
          }
        })
        if(totalRating> 0 && ratingCount > 0){
          this.averageRating = +((totalRating/ratingCount).toFixed(2));
        }
        this.isLoading = false;
      });
      
  }

  raiseRequest(sessionId) {
    this.confirmationService
      .confirm("Are you sure?", "This will raise an invoice request")
      .subscribe((res) => {
        if (res) {
          this.raiseRequestService
            .raiseRequest({ sessionId: sessionId })
            .subscribe((res) => {
              if (res) {
                this.alertService.alert(
                  new BootstrapAlert('Invoice raised successfully', "alert-success")
                );
              }
            });
        }
      });
  }

  downloadReceipt(obj) {
    this.raiseRequestService.getDownloadUrl(obj.key).subscribe((res) => {
      this.getBlob(res["url"]).then((blob) => {
        this.saveAs(blob, obj.fileName);
      });
    });
  }

  getBlob(url) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };
      xhr.send();
    });
  }

  saveAs(blob, filename) {
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }
}
