import { regionData } from "./util/regionData.model";

export class COMMON_DATA {
  static states = [
  "Others",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry"]
  static therapyType = ["GENERAL", "COUPLES", "CHILD", "PSYCHIATRIC"];
  static ptsd_options = [
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "A little bit" },
      { answerText: "Moderately" },
      { answerText: "Quite a bit" },
      { answerText: "Extermely" },
    ],
  ];
  static ptsd_questions = [
    {
      label: "queNo1",
      questionText:
        "Repeated, disturbing, and unwanted memories of the stressful experience?",
    },
    {
      label: "queNo2",
      questionText: "Repeated, disturbing dreams of the stressful experience?",
    },
    {
      label: "queNo3",
      questionText:
        "Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?",
    },
    {
      label: "queNo4",
      questionText:
        "Feeling very upset when something reminded you of the stressful experience?",
    },
    {
      label: "queNo5",
      questionText:
        "Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)?",
    },
    {
      label: "queNo6",
      questionText:
        "Avoiding memories, thoughts, or feelings related to the stressful experience?",
    },
    {
      label: "queNo7",
      questionText:
        "Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)?",
    },
    {
      label: "queNo8",
      questionText:
        "Trouble remembering important parts of the stressful experience?",
    },
    {
      label: "queNo9",
      questionText: "Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)?",
    },
    {
      label: "queNo10",
      questionText: "Blaming yourself or someone else for the stressful experience or what happened after it?",
    },
    {
      label: "queNo11",
      questionText:
        "Having strong negative feelings such as fear, horror, anger, guilt, or shame? ",
    },
    {
      label: "queNo12",
      questionText:
        "Loss of interest in activities that you used to enjoy?",
    },
    {
      label: "queNo13",
      questionText:
        "Feeling distant or cut off from other people?",
    },
    {
      label: "queNo14",
      questionText: "Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)?",
    },
    {
      label: "queNo15",
      questionText: "Irritable behavior, angry outbursts, or acting aggressively?",
    },
    {
      label: "queNo16",
      questionText: "Taking too many risks or doing things that could cause you harm?",
    },
    {
      label: "queNo17",
      questionText: "Being “superalert” or watchful or on guard?",
    },
    {
      label: "queNo18",
      questionText: "Feeling jumpy or easily startled?",
    },
    {
      label: "queNo19",
      questionText: "Having difficulty concentrating?",
    },
    {
      label: "queNo20",
      questionText: "Trouble falling or staying asleep?",
    }

  ];

  static inRegionData: regionData = {
    tag: 'IN',
    therapyStr : "Counselling Therapy Sessions",
    viewAllCTA : "View All Counselors",
    langHome : true,
    homeSliderData : [
      {
        name : "Puja Roy",
        title : "M.Sc in Health Psychology (Gold Medalist)",
        fileName : "pura-roy-slide.webp",
        id : "603b643a37e19c36ed87dfa4"
      },
      {
        name : "Sadaf Mir",
        title : "Master\'s in Clinical Psychology",
        fileName : "sadaf-mir-slide.webp",
        id: "619378a71192ee11e85d1d28"
      },
      {
        name : "Ritam Dubey",
        title : "MA in Counselling Psychology",
        fileName : "ritam-slide-image.webp",
        id : "626a1d73069e10227bba193e"
      },
      {
        name : "Dr. V S Ananthakrishnan",
        title : "MBBS, MD (Psychiatry)",
        fileName : "anantha-krishnan-slide.webp",
        id : "607fcf80a14fe9519e363e4c"
      },
      {
        name : "Zoha Merchant",
        title : "M.Phil. in Psychology",
        fileName : "zoha-slide.webp",
        id : "61b837471ff9c716c12706e3"
      }
    ],
    specializedHelpList : [
      "Anxiety & Stress",
      "Depression",
      "Relationship Issues",
      "OCD",
      "Bipolar Disorder",
      "Psychosis",
      "Grief & Loss",
      "Trauma",
      "Couple Therapy",
      "LGBTQIA+",
      "ADHD & Teenage Issues",
      "Sexual Issues"
    ],
    freeReportHelpString : "quickest way to determine if you are suffering from symptoms of any mental health disorder.",
    howItWorksTherapists : [
      {
        name : "Aashita Khanna",
        title : "MPhil in Clinical Psychology (RCI Licensed)",
        id : "626a2057069e10227bba1971",
        imgSrc : "../../../../assets/images/counseling-therapy-psychologists/aashita-home-bubble.webp"
      },
      {
        name : "Dr. V S Ananthakrishnan",
        title : "MBBS, MD (Psychiatry)",
        id : "607fcf80a14fe9519e363e4c",
        imgSrc : "../../../../assets/images/counseling-therapy-psychologists/anathakrishna.jpeg"
      },
      {
        name : "Puja Roy",
        title : "Msc in Health Psychology (gold medalist)",
        id : "603b643a37e19c36ed87dfa4",
        imgSrc : "../../../../assets/images/counseling-therapy-psychologists/puja-roy.jpeg"
      },
      {
        name : "Guraksha Pabreja",
        title : "MA in Clinical Psychology (Counselling Practices)",
        id : "623824accaf201116c09f77d",
        imgSrc : "../../../../assets/images/counseling-therapy-psychologists/guraksha-home-bubble.webp"
      }
    ],
    meetTherapistStr : "Meet Our Counselling Therapists",
    therapistStr : "counselling therapist",
    therapistStrDup : "Counselling therapist",
    verifiedString : "licensed and verified",
    showLanguageFilter : true,
    communityStr: "India’s #1 Mental Health Support Community",
    homeBannerStr : "Counselling Therapy",
    counsellorTherapistStr : "counselor",
    counsellingTherapyStr : 'Counselling',
    counsellorTherapistStrCap : "Counselor",
    userReview: [
      {
        text: "Therapy with Felicity gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from Felicity.",
        name: "Sana Javed",
        work: "Software Engineer",
      },
      {
        name : "Naina Kashyap",
        work : "Mumbai",
        text : "When I finally held my baby in my arms after 9 months of waiting and expecting, I didn’t really feel flooded with love or attachment. To the contrary I felt overwhelmed and despondent. When I reached out to Felicity, I was full of guilt and shame for feeling this way. My Felicity therapist helped me understand that a biochemical shift in my brain was causing my despair. Within a few sessions she pulled me out of this really dark place & helped me find joy in my motherhood."
      },
      {
        text: "I was unsure about starting therapy when I reached out to Felicity. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.",
        name: "Puja Mittal",
        work: "Homemaker",
      },
      {
        text: "Felicity has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended Felicity to me, I gave it a shot. I feel so grateful to the psychologist & psychiatrist at Felicity who made helped me understand & treat my depression.",
        name: "Vikas Arora",
        work: "CFO",
      },
      {
        text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at Felicity helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
        name: "Aishwarya Hegde",
        work: "Student",
      },
    ]
  }

  static intlRegionData: regionData = {
    tag : "intl",
    therapyStr : "Therapy Sessions",
    viewAllCTA : "View All Therapists",
    langHome : false,
    homeSliderData : [
      {
        name : "Puja Roy",
        title : "M.Sc in Health Psychology (Gold Medalist)",
        fileName : "pura-roy-slide.webp",
        id : "603b643a37e19c36ed87dfa4"
      },
      {
        name : "Sadaf Mir",
        title : "Master\'s in Clinical Psychology",
        fileName : "sadaf-mir-slide.webp",
        id: "619378a71192ee11e85d1d28"
      },
      {
        name : "Ritam Dubey",
        title : "MA in Counselling Psychology",
        fileName : "ritam-slide-image.webp",
        id : "626a1d73069e10227bba193e"
      },
      {
        name : "Aniket Kulkarni",
        title : "MS Clinical Mental Health Counselling",
        fileName : "aniket-slide.webp",
        id : "62ea262a8fec777e808a2cd6"
      },
      {
        name : "Zoha Merchant",
        title : "M.Phil. in Psychology",
        fileName : "zoha-slide.webp",
        id : "61b837471ff9c716c12706e3"
      }
    ],
    specializedHelpList : [
      "Anxiety & Stress",
      "Relationship Issues",
      "Grief & Loss",
      "Trauma",
      "Couple Therapy",
      "LGBTQIA+",
      "Sexual Issues",
      "Low Mood",
      "Teenage Issues",
      "Depression"
    ],
    freeReportHelpString : "a quick stock take of your mental health.",
    howItWorksTherapists : [
      {
        name : "Aashita Khanna",
        title : "MPhil in Clinical Psychology (RCI Licensed)",
        id : "626a2057069e10227bba1971",
        imgSrc : "../../../../assets/images/counseling-therapy-psychologists/aashita-home-bubble.webp"
      },
      {
        name : "Aniket Kulkarni, LLPC",
        title : "MS Clinical Mental Health Counselling",
        id : "62ea262a8fec777e808a2cd6",
        imgSrc : "../../../../assets/images/counseling-therapy-psychologists/aniket-home.webp"
      },
      {
        name : "Puja Roy",
        title : "Msc in Health Psychology (gold medalist)",
        id : "603b643a37e19c36ed87dfa4",
        imgSrc : "../../../../assets/images/counseling-therapy-psychologists/puja-roy.jpeg"
      },
      {
        name : "Guraksha Pabreja",
        title : "MA in Clinical Psychology (Counselling Practices)",
        id : "623824accaf201116c09f77d",
        imgSrc : "../../../../assets/images/counseling-therapy-psychologists/guraksha-home-bubble.webp"
      }
    ],
    meetTherapistStr : "Meet Our Therapists",
    therapistStr : "therapist",
    therapistStrDup : "Therapist",
    verifiedString : "qualified and verified",
    showLanguageFilter : false,
    communityStr : "Global Mental Health Support Community - 150K Plus users from across the Globe",
    homeBannerStr : "Therapy",
    counsellorTherapistStr : "therapist",
    counsellingTherapyStr : "Therapy",
    counsellorTherapistStrCap : "Therapist",
    userReview : [
      {
        text: "Therapy with Felicity gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from Felicity.",
        name: "Sana",
        work: "Software Engineer",
      },
      {
        name : "Nancy",
        work : "Mumbai",
        text : "When I finally held my baby in my arms after 9 months of waiting and expecting, I didn’t really feel flooded with love or attachment. To the contrary I felt overwhelmed and despondent. When I reached out to Felicity, I was full of guilt and shame for feeling this way. My Felicity therapist helped me understand that a biochemical shift in my brain was causing my despair. Within a few sessions she pulled me out of this really dark place & helped me find joy in my motherhood."
      },
      {
        text: "I was unsure about starting therapy when I reached out to Felicity. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.",
        name: "Priyanka",
        work: "Homemaker",
      },
      {
        text: "Felicity has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended Felicity to me, I gave it a shot. I feel so grateful to the psychologist & psychiatrist at Felicity who made helped me understand & treat my depression.",
        name: "Vince",
        work: "CFO",
      },
      {
        text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at Felicity helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
        name: "Jennifer",
        work: "Student",
      },
    ]
  }

  static satisfaction_with_life_options = [
    [
      { answerText: "Strongly disagree" },
      { answerText: "Disagree" },
      { answerText: "Slightly disagree" },
      { answerText: "Neither agree nor disagree" },
      { answerText: "Slightly agree" },
      { answerText: "Agree" },
      { answerText: "Strongly agree" },
    ],
    [
      { answerText: "Strongly disagree" },
      { answerText: "Disagree" },
      { answerText: "Slightly disagree" },
      { answerText: "Neither agree nor disagree" },
      { answerText: "Slightly agree" },
      { answerText: "Agree" },
      { answerText: "Strongly agree" },
    ],
    [
      { answerText: "Strongly disagree" },
      { answerText: "Disagree" },
      { answerText: "Slightly disagree" },
      { answerText: "Neither agree nor disagree" },
      { answerText: "Slightly agree" },
      { answerText: "Agree" },
      { answerText: "Strongly agree" },
    ],
    [
      { answerText: "Strongly disagree" },
      { answerText: "Disagree" },
      { answerText: "Slightly disagree" },
      { answerText: "Neither agree nor disagree" },
      { answerText: "Slightly agree" },
      { answerText: "Agree" },
      { answerText: "Strongly agree" },
    ],
    [
      { answerText: "Strongly disagree" },
      { answerText: "Disagree" },
      { answerText: "Slightly disagree" },
      { answerText: "Neither agree nor disagree" },
      { answerText: "Slightly agree" },
      { answerText: "Agree" },
      { answerText: "Strongly agree" },
    ],

  ];
  static satisfaction_with_life_questions = [
    {
      label: "queNo1",
      questionText:
        "In most ways, my life is close to my ideal.",
    },
    {
      label: "queNo2",
      questionText: "The conditions of my life are excellent.",
    },
    {
      label: "queNo3",
      questionText:
        "I am satisfied with my life.",
    },
    {
      label: "queNo4",
      questionText:
        "So far, I have gotten the important things I want in life.",
    },
    {
      label: "queNo5",
      questionText:
        "If I could live my life over, I would change almost nothing.",
    }
  ];
  static depression_options = [
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
  ];
  static depression_questions = [
    {
      label: "queNo1",
      questionText:
        "Little interest or pleasure in doing things",
    },
    {
      label: "queNo2",
      questionText: "Feeling down, depressed or hopeless",
    },
    {
      label: "queNo3",
      questionText: "Trouble falling/staying asleep, sleeping too much",
    },
    {
      label: "queNo4",
      questionText: "Feeling tired or having little energy",
    },
    {
      label: "queNo5",
      questionText: "Poor appetite or overeating",
    },
    {
      label: "queNo6",
      questionText:
        "Feeling bad about yourself or that you are a failure or have let yourself or your family down",
    },
    {
      label: "queNo7",
      questionText:
        "Trouble concentrating on things, such as reading the newspaper or watching television.",
    },
    {
      label: "queNo8",
      questionText:
        "Moving or speaking so slowly that other people could have noticed. Or the opposite; being so fidgety or restless that you have been moving around a lot more than usual.",
    },
    {
      label: "queNo9",
      questionText:
        "Thoughts that you would be better off dead or of hurting yourself in some way.",
    },
  ];
  static caregiver_options = [
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Frequently" },
      { answerText: "Nearly always" },
    ],
  ];
  static caregiver_questions = [
    {
      label: "queNo1",
      questionText:
        "Do you feel that your loved one asks for more help than they need?",
    },
    {
      label: "queNo2",
      questionText: "Do you feel that, because of the time you spend with your loved one, you don’t have enough time for yourself? ",
    },
    {
      label: "queNo3",
      questionText: "Do you feel stressed between caring for your loved one and trying to meet other responsibilities for your family or work? ",
    },
    {
      label: "queNo4",
      questionText: "Do you feel embarrassed about your loved one’s behavior? ",
    },
    {
      label: "queNo5",
      questionText: "Do you feel angry when you are around your loved one? ",
    },
    {
      label: "queNo6",
      questionText:
        "Do you feel that your loved one affects your relationships with other family members or friends in a negative way? ",
    },
    {
      label: "queNo7",
      questionText:
        "Are you afraid of what the future holds for your loved one? ",
    },
    {
      label: "queNo8",
      questionText:
        "Do you feel your loved one is dependent on you? ",
    },
    {
      label: "queNo9",
      questionText:
        "Thoughts that you would be better off dead or of hDo you feel strained when you are around your loved one? ",
    },
    {
      label: "queNo10",
      questionText:
        "Do you feel your health has suffered because of your involvement with your loved one? ",
    },
    {
      label: "queNo11",
      questionText:
        "Do you feel that you don’t have as much privacy as you would like because of your loved one? ",
    },
    {
      label: "queNo12",
      questionText: "Do you feel that your social life has suffered because you are caring for your loved one?",
    },
    {
      label: "queNo13",
      questionText: "Do you feel uncomfortable about having friends at your home because of your loved one? ",
    },
    {
      label: "queNo14",
      questionText: "Do you feel that your loved one seems to expect you to take care of them as if you were the only one they could depend on? ",
    },
    {
      label: "queNo15",
      questionText: "Do you feel that you do not have enough money to take care of your loved one in addition to your other expenses?",
    },
    {
      label: "queNo16",
      questionText:
        "Do you feel that you will be unable to take care of your loved one much longer? ",
    },
    {
      label: "queNo17",
      questionText:
        "Do you feel you have lost control of your life since your relative became ill? ",
    },
    {
      label: "queNo18",
      questionText:
        "Do you wish you could leave the care of your loved one to someone else?",
    },
    {
      label: "queNo19",
      questionText:
        "Do you feel uncertain about what to do about your loved one? ",
    },
    {
      label: "queNo20",
      questionText:
        "Do you feel you should be doing more for your loved one?",
    },
    {
      label: "queNo21",
      questionText:
        "Do you feel that you could do a better job of caring for your loved one? ",
    },
    {
      label: "queNo22",
      questionText:
        "Overall, how burdened do you feel in caring for your loved one?",
    },
  ];
  static anixety_options = [
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
    [
      { answerText: "Not at all" },
      { answerText: "Several days" },
      { answerText: "More than half the days" },
      { answerText: "Nearly every day" },
    ],
  ];
  static social_anixety_fear_options = [
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
    [
      { answerText: "None" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
    ],
  ];
  static social_anixety_avoidance_options = [
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Occasionally" },
      { answerText: "Often" },
      { answerText: "Usually" },
    ],
  ];
  static angry_options = [
    [
      { answerText: "I do not feel angry" },
      { answerText: "I feel angry" },
      { answerText: "I am angry most of the time now. " },
      {
        answerText:
          "I am so angry and hostile all the time that I can't stand it.",
      },
    ],
    [
      { answerText: "I am not particularly angry about my future. " },
      { answerText: "When I think about my future, I feel angry." },
      { answerText: "I feel angry about what I have to look forward to." },
      {
        answerText:
          "I feel intensely angry about my future, since it cannot be improved.",
      },
    ],
    [
      { answerText: "It makes me angry that I feel like such a failure." },
      {
        answerText:
          "It makes me angry that I have failed more than the average person.",
      },
      {
        answerText:
          "As I look back on my life, I feel angry about my failures. ",
      },
      {
        answerText:
          "It makes me angry to feel like a complete failure as a person.",
      },
    ],
    [
      { answerText: "I am not all that angry about things. " },
      {
        answerText:
          "I am becoming more hostile about things than I used to be. ",
      },
      { answerText: "I am pretty angry about things these days. " },
      { answerText: "I am angry and hostile about everything. " },
    ],
    [
      { answerText: "I don't feel particularly hostile at others" },
      { answerText: "I feel hostile a good deal of the time" },
      { answerText: "I feel quite hostile most of the time" },
      { answerText: "I feel hostile all of the time" },
    ],
    [
      { answerText: "I don't feel that others are trying to annoy me" },
      { answerText: "At times I think people are trying to annoy me" },
      {
        answerText:
          "More people than usual are beginning to make me feel angry",
      },
      {
        answerText:
          "I feel that others are constantly and intentionally making me angry",
      },
    ],
    [
      { answerText: "I don't feel angry when I think about myself" },
      {
        answerText: "I feel more angry about myself these days than I used to",
      },
      { answerText: "I feel angry about myself a good deal of the time" },
      { answerText: "When I think about myself, I feel intense anger" },
    ],
    [
      {
        answerText:
          "I don't have angry feelings about others having screwed up my life",
      },
      {
        answerText:
          "It's beginning to make me angry that others are screwing up my life",
      },
      {
        answerText:
          "I feel angry that others prevent me from having a good life",
      },
      {
        answerText:
          "I am constantly angry because others have made my life totally miserable",
      },
    ],
    [
      { answerText: "I don't feel angry enough to hurt someone" },
      {
        answerText:
          "Sometimes I am so angry that I feel like hurting others, but I would not really do it",
      },
      {
        answerText:
          "My anger is so intense that I sometimes feel like hurting others",
      },
      { answerText: "I'm so angry that I would like to hurt someone" },
    ],
    [
      { answerText: "I don't shout at people any more than usual" },
      { answerText: "I shout at others more now than I used to" },
      { answerText: "I shout at people all the time now" },
      {
        answerText:
          "I shout at others so often that sometimes I just can't stop",
      },
    ],
    [
      { answerText: "Things are not more irritating to me now than usual" },
      { answerText: "I feel slightly more irritated now than usual." },
      { answerText: "I feel irritated a good deal of the time." },
      { answerText: "I'm irritated all the time now." },
    ],
    [
      {
        answerText:
          "My anger does not interfere with my interest in other people",
      },
      {
        answerText: "My anger sometimes interferes with my interest in others",
      },
      {
        answerText:
          "I am becoming so angry that I don't want to be around others",
      },
      { answerText: "I'm so angry that I can't stand being around people" },
    ],
    [
      {
        answerText:
          "I don't have any persistent angry feelings that influence my ability to make decisions",
      },
      {
        answerText:
          "My feelings of anger occasionally undermine my ability to make decisions",
      },
      {
        answerText:
          "I am angry to the extent that it interferes with my making good decisions",
      },
      { answerText: "I'm so angry that I can't make good decisions anymore" },
    ],
    [
      { answerText: "I'm not so angry and hostile that others dislike me" },
      {
        answerText:
          "People sometimes dislike being around me since I become angry",
      },
      {
        answerText:
          "More often than not, people stay away from me because I'm so hostile and angry",
      },
      {
        answerText:
          "People don't like me anymore because I'm constantly angry all the time",
      },
    ],
    [
      { answerText: "My feelings of anger do not interfere with my work" },
      {
        answerText:
          "From time to time my feelings of anger interfere with my work",
      },
      {
        answerText:
          "I feel so angry that it interferes with my capacity to work",
      },
      {
        answerText:
          "My feelings of anger prevent me from doing any work at all",
      },
    ],
    [
      { answerText: "My anger does not interfere with my sleep" },
      {
        answerText:
          "Sometimes I don't sleep very well because I'm feeling angry",
      },
      {
        answerText:
          "My anger is so great that I stay awake 1—2 hours later than usual",
      },
      {
        answerText:
          "I am so intensely angry that I can't get much sleep during the night",
      },
    ],
    [
      { answerText: "My anger does not make me feel anymore tired than usual" },
      { answerText: "My feelings of anger are beginning to tire me out" },
      {
        answerText:
          "My anger is intense enough that it makes me feel very tired",
      },
      { answerText: "My feelings of anger leave me too tired to do anything" },
    ],
    [
      {
        answerText:
          "My appetite does not suffer because of my feelings of anger",
      },
      {
        answerText: "My feelings of anger are beginning to affect my appetite",
      },
      {
        answerText: "My feelings of anger leave me without much of an appetite",
      },
      {
        answerText: "My anger is so intense that it has taken away my appetite",
      },
    ],
    [
      { answerText: "My feelings of anger don't interfere with my health" },
      {
        answerText:
          "My feelings of anger are beginning to interfere with my health",
      },
      {
        answerText:
          "My anger prevents me from devoting much time and attention to my health",
      },
      {
        answerText:
          "I'm so angry at everything these days that I pay no attention to my health and well-being",
      },
    ],
    [
      {
        answerText:
          "My ability to think clearly is unaffected by my feelings of anger",
      },
      {
        answerText:
          "Sometimes my feelings of anger prevent me from thinking in a clear-headed way",
      },
      { answerText: "My anger makes it hard for me to think of anything else" },
      {
        answerText:
          "I'm so intensely angry and hostile that it completely interferes with my thinking",
      },
    ],
    [
      {
        answerText:
          "I don't feel so angry that it interferes with my interest in sex",
      },
      {
        answerText:
          "My feelings of anger leave me less interested in sex than I used to be",
      },
      {
        answerText: "My current feelings of anger undermine my interest in sex",
      },
      {
        answerText:
          "I'm so angry about my life that I've completely lost interest in sex",
      },
    ],
  ];
  static angry_questions = [
    {
      label: "queNo1",
      questionText:
        "",
    },
    {
      label: "queNo2",
      questionText: "",
    },
    {
      label: "queNo3",
      questionText:
        "",
    },
    {
      label: "queNo4",
      questionText:
        "",
    },
    {
      label: "queNo5",
      questionText:
        "",
    },
    {
      label: "queNo6",
      questionText:
        "",
    },
    {
      label: "queNo7",
      questionText:
        "",
    },
    {
      label: "queNo8",
      questionText:
        "",
    },
    {
      label: "queNo9",
      questionText: "",
    },
    {
      label: "queNo10",
      questionText: "",
    },
    {
      label: "queNo11",
      questionText:
        "",
    },
    {
      label: "queNo12",
      questionText:
        "",
    },
    {
      label: "queNo13",
      questionText:
        "",
    },
    {
      label: "queNo14",
      questionText: "",
    },
    {
      label: "queNo15",
      questionText: "",
    },
    {
      label: "queNo16",
      questionText: "",
    },
    {
      label: "queNo17",
      questionText: "",
    },
    {
      label: "queNo18",
      questionText: "",
    },
    {
      label: "queNo19",
      questionText: "",
    },
    {
      label: "queNo20",
      questionText: "",
    },
    {
      label: "queNo21",
      questionText: "",
    },
  ];

  static social_anixety_questions = [
    {
      label: "queNo1",
      questionText: "Telephoning in public.",
    },
    {
      label: "queNo2",
      questionText: "Participating in small groups.",
    },
    {
      label: "queNo3",
      questionText: " Eating in public places.",
    },
    {
      label: "queNo4",
      questionText: "Drinking with others in public places.",
    },
    {
      label: "queNo5",
      questionText: "Talking to people in authority.",
    },
    {
      label: "queNo6",
      questionText: "Acting, performing or giving a talk in front of an audience.",
    },
    {
      label: "queNo7",
      questionText: "Going to a party.",
    },
    {
      label: "queNo8",
      questionText: "Working while being observed.",
    },
    {
      label: "queNo9",
      questionText: "Writing while being observed.",
    },
    {
      label: "queNo10",
      questionText: "Calling someone you don’t know very well.",
    },
    {
      label: "queNo11",
      questionText: "Talking with people you don’t know very well.",
    },
    {
      label: "queNo12",
      questionText: "Meeting strangers.",
    },
    {
      label: "queNo13",
      questionText: "Urinating in a public bathroom.",
    },
    {
      label: "queNo14",
      questionText: "Entering a room when others are already seated.",
    },
    {
      label: "queNo15",
      questionText: "Being the center of attention.",
    },
    {
      label: "queNo16",
      questionText: "Speaking up at a meeting.",
    },
    {
      label: "queNo17",
      questionText: "Taking a test.",
    },
    {
      label: "queNo18",
      questionText: "Expressing a disagreement or disapproval to people you don’t know very well.",
    },
    {
      label: "queN19",
      questionText: " Looking at people you don’t know very well in the eyes.",
    },
    {
      label: "queNo20",
      questionText: "Giving a report to a group.",
    },
    {
      label: "queNo21",
      questionText: "Trying to pick up someone.",
    },
    {
      label: "queNo22",
      questionText: "Returning goods to a store.",
    },
    {
      label: "queNo23",
      questionText: "Giving a party.",
    },
    {
      label: "queNo24",
      questionText: "Resisting a high pressure salesperson.",
    },
  ];
  static anixety_questions = [
    {
      label: "queNo1",
      questionText: "Feeling nervous, anxious or on edge",
    },
    {
      label: "queNo2",
      questionText: "Not being able to stop or control worrying",
    },
    {
      label: "queNo3",
      questionText: "Worrying too much about different things",
    },
    {
      label: "queNo4",
      questionText: "Trouble relaxing",
    },
    {
      label: "queNo5",
      questionText: "Being so restless that it's hard to sit still",
    },
    {
      label: "queNo6",
      questionText: "Feeling afraid as if something awful might happen",
    },
    {
      label: "queNo7",
      questionText: "Little interest or pleasure in doing things",
    },
  ];
  static anxiety_hamilton_questions = [
    {
      label: "queNo1",
      questionText: "Worries, anticipation of the worst, fearful anticipation, irritability.",
    },
    {
      label: "queNo2",
      questionText: "Feelings of tension, fatigability, startle response, moved to tears easily, trembling, feelings of restlessness, inability to relax",
    },
    {
      label: "queNo3",
      questionText: "Of dark, of strangers, of being left alone, of animals, of traffic, of crowds.",
    },
    {
      label: "queNo4",
      questionText: "Difficulty in falling asleep, broken sleep, unsatisfying sleep and fatigue on waking, dreams, nightmares, night terrors.",
    },
    {
      label: "queNo5",
      questionText: "Difficulty in concentration, poor memory.",
    },
    {
      label: "queNo6",
      questionText: "Loss of interest, lack of pleasure in hobbies, depression, early waking, diurnal swing.",
    },
    {
      label: "queNo7",
      questionText: "Pains and aches, twitching, stiffness, myoclonic jerks, grinding of teeth, unsteady voice, increased muscular tone.",
    },
    {
      label: "queNo8",
      questionText: "Tinnitus, blurring of vision, hot and cold flushes, feelings of weakness, pricking sensation.",
    },
    {
      label: "queNo9",
      questionText: "Tachycardia, palpitations, pain in chest, throbbing of vessels, fainting feelings, missing beat.",
    },
    {
      label: "queNo10",
      questionText: "Pressure or constriction in chest, choking feelings, sighing, dyspnea.",
    },
    {
      label: "queNo11",
      questionText: "Difficulty in swallowing, wind abdominal pain, burning sensations, abdominal fullness, nausea, vomiting, borborygmi, looseness of bowels, loss of weight, constipation.",
    },
    {
      label: "queNo12",
      questionText: "Frequency of micturition, urgency of micturition, amenorrhea, menorrhagia, development of frigidity, premature ejaculation, loss of libido, impotence.",
    },
    {
      label: "queNo13",
      questionText: "Dry mouth, flushing, pallor, tendency to sweat, giddiness, tension headache, raising of hair.",
    },
    {
      label: "queNo14",
      questionText: "Fidgeting, restlessness or pacing, tremor of hands, furrowed brow, strained face, sighing or rapid respiration, facial pallor, swallowing, etc.",
    },
  ];
  static anxiety_hamilton_options = [
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],
    [
      { answerText: "Not Present" },
      { answerText: "Mild" },
      { answerText: "Moderate" },
      { answerText: "Severe" },
      { answerText: "Very Severe" },
    ],

  ]
  static child_adhd_questions = [
    {
      label: "queNo1",
      questionText:
        "Fails to give close attention to details or makes careless mistakes in schoolwork. ",
    },
    {
      label: "queNo2",
      questionText:
        "Fidgets with hands or feet or squirms in seat.",
    },
    {
      label: "queNo3",
      questionText:
        "Has difficulty sustaining attention in tasks or play activities.",
    },
    {
      label: "queNo4",
      questionText:
        "Leaves seat in classroom or in other situations in which remaining seated is expected.",
    },
    {
      label: "queNo5",
      questionText:
        "Does not seem to listen when spoken to directly.",
    },
    {
      label: "queNo6",
      questionText:
        "Runs about or climbs excessively in situations in which it is inappropriate.",
    },
    {
      label: "queNo7",
      questionText:
        "Does not follow through on instructions and fails to finish work.",
    },
    {
      label: "queNo8",
      questionText:
        "Has difficulty playing or engaging in leisure activities quietly.",
    },
    {
      label: "queNo9",
      questionText:
        "Has difficulty organizing tasks and activities.",
    },
    {
      label: "queNo10",
      questionText:
        "Is “on the go” or acts as if “driven by a motor.”",
    },
    {
      label: "queNo11",
      questionText:
        "Avoids tasks (eg, schoolwork, homework) that require sustained mental effort. ",
    },
    {
      label: "queNo12",
      questionText:
        "Talks excessively. ",
    },
    {
      label: "queNo13",
      questionText:
        "Loses things necessary for tasks or activities.",
    },
    {
      label: "queNo14",
      questionText:
        "Blurts out answers before questions have been completed. ",
    },
    {
      label: "queNo15",
      questionText:
        "Is easily distracted.",
    },
    {
      label: "queNo16",
      questionText:
        "Has difficulty awaiting turn.",
    },
    {
      label: "queNo17",
      questionText:
        "Is forgetful in daily activities.",
    },
    {
      label: "queNo18",
      questionText:
        "Interrupts or intrudes on others.",
    },
  ];
  static child_adhd_options = [
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never/Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
  ];
  static adhd_questions = [
    {
      label: "queNo1",
      questionText:
        "How often do you have trouble wrapping up the final details of a project once the challenging parts have been done?",
    },
    {
      label: "queNo2",
      questionText:
        "How often do you have difficulty getting things in order when you have to do a task that requires organization?",
    },
    {
      label: "queNo3",
      questionText:
        "How often do you have problems remembering appointments or obligations?",
    },
    {
      label: "queNo4",
      questionText:
        "When you have a task that requires a lot of thought, how often do you avoid or delay getting started?",
    },
    {
      label: "queNo5",
      questionText:
        "How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?",
    },
    {
      label: "queNo6",
      questionText:
        "How often do you feel overly active and compelled to do things, like you were driven by a motor?",
    },
  ];
  static adhd_options = [
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
  ];
  static adhd_questions1 = [
    {
      label: "queNo7",
      questionText:
        "How often do you make careless mistakes when you have to work on a boring or difficult project?",
    },
    {
      label: "queNo8",
      questionText:
        "How often do you have difficulty keeping your attention when you are doing boring or repetitive work?",
    },
    {
      label: "queNo9",
      questionText:
        "How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?",
    },
    {
      label: "queNo10",
      questionText:
        "How often do you misplace or have difficulty finding things at home or at work?",
    },
    {
      label: "queNo11",
      questionText:
        "How often are you distracted by activity or noise around you?",
    },
    {
      label: "queNo12",
      questionText:
        "How often do you leave your seat in meetings or other situations in which you are expected to remain seated?",
    },
    {
      label: "queNo13",
      questionText:
        "How often do you feel restless or fidgety?",
    },
    {
      label: "queNo14",
      questionText:
        "How often do you have difficulty unwinding and relaxing when you have time to yourself?",
    },
    {
      label: "queNo15",
      questionText:
        "How often do you find yourself talking too much when you are in social situations?",
    },
    {
      label: "queNo16",
      questionText:
        "When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves?",
    },
    {
      label: "queNo17",
      questionText:
        "How often do you have difficulty waiting your turn in situations when turn taking is required?",
    },
    {
      label: "queNo18",
      questionText:
        "How often do you interrupt others when they are busy?",
    },
  ];
  static adhd_options1 = [
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
    [
      { answerText: "Never" },
      { answerText: "Rarely" },
      { answerText: "Sometimes" },
      { answerText: "Often" },
      { answerText: "Very Often" },
    ],
  ];
  static academic_stress_questions = [
    {
      label: "queNo1",
      questionText: "I am confident that I will be a successful student",
    },
    {
      label: "queNo2",
      questionText:
        "I am confident that I will be a successful in my future career",
    },
    {
      label: "queNo3",
      questionText:
        "I can make academic decisions easily ",
    },
    {
      label: "queNo4",
      questionText:
        "The time allocated to classes and academic work is enough",
    },
    {
      label: "queNo5",
      questionText: "I have enough time to relax after work",
    },
    {
      label: "queNo6",
      questionText:
        "My teachers are critical of my academic performance ",
    },
    {
      label: "queNo7",
      questionText:
        "I fear failing courses this year ",
    },
    {
      label: "queNo8",
      questionText: "I think that my worry about examinations is weakness of character",
    },
    {
      label: "queNo9",
      questionText: "Teachers have unrealistic expectations of me",
    },
    {
      label: "queNo10",
      questionText:
        "The size of the curriculum (workload) is excessive ",
    },
    {
      label: "queNo11",
      questionText: "I believe that the amount of work assignment is too much ",
    },
    {
      label: "queNo12",
      questionText:
        "I am unable to catch up if getting behind the work",
    },
    {
      label: "queNo13",
      questionText:
        "The unrealistic expectations of my parents stresses me out",
    },
    {
      label: "queNo14",
      questionText:
        "The competition with my peers for grades is quite intense",
    },
    {
      label: "queNo15",
      questionText: "The examination questions are usually difficult ",
    },
    {
      label: "queNo16",
      questionText: "Examination time is short to complete the answers",
    },
    {
      label: "queNo17",
      questionText: "Examination times are very stressful to me out ",
    },
    {
      label: "queNo18",
      questionText: "Even if I pass my exams, I am worried about getting a job",
    },
  ];
  static academic_stress_options = [
    [
      { answerText: "Strongly disagree", value: 5 },
      { answerText: "Disagree", value: 4 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly disagree", value: 5 },
      { answerText: "Disagree", value: 4 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly disagree", value: 5 },
      { answerText: "Disagree", value: 4 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly disagree", value: 5 },
      { answerText: "Disagree", value: 4 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly disagree", value: 5 },
      { answerText: "Disagree", value: 4 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
    [
      { answerText: "Strongly disagree", value: 1 },
      { answerText: "Disagree", value: 2 },
      { answerText: "Neither agree nor disagree", value: 3 },
      { answerText: "Agree", value: 4 },
      { answerText: "Strongly Agree", value: 5 },
    ],
  ];
  static grief_questions = [
    {
      label: "queNo1",
      questionText: "I am longing or yearning for the deceased.",
    },
    {
      label: "queNo2",
      questionText:
        "I am preoccupied with thoughts about the deceased or the circumstances of the death.",
    },
    {
      label: "queNo3",
      questionText:
        "I have intense feelings of sorrow, related to the deceased.",
    },
    {
      label: "queNo4",
      questionText:
        "I feel guilty about the death or circumstances surrounding the death.",
    },
    {
      label: "queNo5",
      questionText: "I am angry over the loss.",
    },
    {
      label: "queNo6",
      questionText:
        "I try to avoid reminders of the deceased or the death as much as possible (e.g., pictures, memories).",
    },
    {
      label: "queNo7",
      questionText:
        "I blame others or the circumstances for the death (e.g., a higher power).",
    },
    {
      label: "queNo8",
      questionText: "I have trouble or just don’t want to accept the loss.",
    },
    {
      label: "queNo9",
      questionText: "I feel that I lost a part of myself. ",
    },
    {
      label: "queNo10",
      questionText:
        "I have trouble or have no desire to experience joy or satisfaction.",
    },
    {
      label: "queNo11",
      questionText: "I feel emotionally numb. ",
    },
    {
      label: "queNo12",
      questionText:
        "I have difficulties engaging in activities I enjoyed prior to the death.",
    },
    {
      label: "queNo13",
      questionText:
        "Grief significantly interferes with my ability to work, socialize or function in everyday life.",
    },
    {
      label: "queNo14",
      questionText:
        "My grief would be considered worse (e.g., more intense, severe and/or of longer duration) than for others from my community or culture.",
    },
    {
      label: "queNo15",
      questionText: "When did the loss occur?",
    },
  ];
  static grief_options = [
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Often", value: 4 },
      { answerText: "Always", value: 5 },
    ],
    [
      { answerText: "Less than 6 months ago", value: 1 },
      { answerText: "6 to 12 months ago", value: 2 },
      { answerText: "1 to 5 years ago", value: 3 },
      { answerText: "10 to 20 years ago", value: 4 },
      { answerText: "More than 20 years ago", value: 5 },
    ]
  ];
  static psychological_distress_questions = [
    {
      label: "queNo1",
      questionText: "In the past 4 weeks, about how often did you feel tired out for no good reason?",
    },
    {
      label: "queNo2",
      questionText:
        "In the past 4 weeks, about how often did you feel nervous?",
    },
    {
      label: "queNo3",
      questionText:
        "In the past 4 weeks, about how often did you feel so nervous that nothing could calm you down?",
    },
    {
      label: "queNo4",
      questionText:
        "In the past 4 weeks, about how often did you feel hopeless?",
    },
    {
      label: "queNo5",
      questionText: "In the past 4 weeks, about how often did you feel restless or fidgety?",
    },
    {
      label: "queNo6",
      questionText:
        "In the past 4 weeks, about how often did you feel so restless you could not sit still?",
    },
    {
      label: "queNo7",
      questionText:
        "In the past 4 weeks, about how often did you feel depressed?",
    },
    {
      label: "queNo8",
      questionText: "In the past 4 weeks, about how often did you feel that everything was an effort?",
    },
    {
      label: "queNo9",
      questionText: "In the past 4 weeks, about how often did you feel so sad that nothing could cheer you up?",
    },
    {
      label: "queNo10",
      questionText:
        "In the past 4 weeks, about how often did you feel worthless?",
    }
  ];
  static psychological_distress_options = [
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
    [
      { answerText: "None of the time", value: 1 },
      { answerText: "A little of the time", value: 2 },
      { answerText: "Some of the time", value: 3 },
      { answerText: "Most of the time", value: 4 },
      { answerText: "All of the time", value: 5 },
    ],
  ];
  static work_life_questions = [
    {
      label: "queNo1",
      questionText: "My personal life suffers because of work",
    },
    {
      label: "queNo2",
      questionText:
        "My job makes my personal life difficult",
    },
    {
      label: "queNo3",
      questionText:
        "I neglect personal needs because of work",
    },
    {
      label: "queNo4",
      questionText:
        "I put personal life on hold for work",
    },
    {
      label: "queNo5",
      questionText: "I miss personal activities because of work",
    },
    {
      label: "queNo6",
      questionText:
        "I struggle to juggle work and non-work",
    },
    {
      label: "queNo7",
      questionText:
        "I am unhappy with the amount of time for non-work activities",
    },
    {
      label: "queNo8",
      questionText: "My personal life drains me of energy to work",
    },
    {
      label: "queNo9",
      questionText: "I am too tired to be effective at work",
    },
    {
      label: "queNo10",
      questionText:
        "My work suffers because of my personal life",
    },
    {
      label: "queNo11",
      questionText: "It is hard to work because of personal matters",
    },
    {
      label: "queNo12",
      questionText:
        "My personal life gives me energy for my job",
    },
    {
      label: "queNo13",
      questionText:
        "My job gives me energy to pursue personal activities",
    },
    {
      label: "queNo14",
      questionText:
        "I have a better mood at work because of personal life",
    },
    {
      label: "queNo15",
      questionText: "I have a better mood because of my job",
    },
  ];
  static work_life_options = [
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 7 },
      { answerText: "", value: 6 },
      { answerText: "", value: 5 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "All the time", value: 1 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "All the time", value: 7 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "All the time", value: 7 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "All the time", value: 7 },
    ],
    [
      { answerText: "Not at all", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "Neutral", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "All the time", value: 7 },
    ],
  ];
  static eating_disorders_questions = [
    {
      label: "queNo1",
      questionText: "Do you ever make yourself sick because you feel uncomfortably full?",
    },
    {
      label: "queNo2",
      questionText:
        "Do you worry you have lost control over how much you eat?",
    },
    {
      label: "queNo3",
      questionText:
        "Have you recently lost more than one stone (approximately 6kgs)  in a three month period?",
    },
    {
      label: "queNo4",
      questionText:
        "Do you believe yourself to be fat when others say you are too thin?",
    },
    {
      label: "queNo5",
      questionText: "Would you say that food dominates your life?",
    }
  ];
  static eating_disorders_options = [
    [
      { answerText: "Yes", value: 1 },
      { answerText: "No", value: 0 },
    ],
    [
      { answerText: "Yes", value: 1 },
      { answerText: "No", value: 0 },
    ],
    [
      { answerText: "Yes", value: 1 },
      { answerText: "No", value: 0 },
    ],
    [
      { answerText: "Yes", value: 1 },
      { answerText: "No", value: 0 },
    ],
    [
      { answerText: "Yes", value: 1 },
      { answerText: "No", value: 0 },
    ],
  ];

  static financial_stress_question = [
    {
      label: "queNo1",
      questionText: "What do you feel is the level of your financial stress today?",
    },
    {
      label: "queNo2",
      questionText:
        "On the stair steps below, mark (with a circle) how satisfied you are with your present financial situation. The “1” at the bottom of the steps represent complete dissatisfaction. The “10” at the top of the stair steps represents complete satisfaction. The more dissatisfied you are, the lower the number you should circle. The more satisfied you are, the higher the number you should circle.",
    },
    {
      label: "queNo3",
      questionText:
        "How do you feel about your current financial situation? ",
    },
    {
      label: "queNo4",
      questionText:
        "How often do you worry about being able to meet normal monthly living expenses?",
    },
    {
      label: "queNo5",
      questionText: "How confident are you that you could find the money to pay for a financial emergency that costs about $1,000 (~₹78,000?)?",
    },
    {
      label: "queNo6",
      questionText:
        "How often does this happen to you? You want to go out to eat, go to a movie or do something else and don’t go because you can’t afford to?",
    },
    {
      label: "queNo7",
      questionText:
        "How frequently do you find yourself just getting by financially and living paycheck to paycheck?",
    },
    {
      label: "queNo8",
      questionText:
        "How stressed do you feel about your personal finances in general?",
    }
  ];
  static financial_stress_option = [
    [
      { answerText: "Overwhelming stress", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "High stress ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Low stress", value: 7 },
      { answerText: "", value: 8 },
      { answerText: "", value: 9 },
      { answerText: "No stress at all", value: 10 },
    ],
    //2
    [
      { answerText: " 1", id: 2, value: 1 },
      { answerText: " 2", id: 2, value: 2 },
      { answerText: " 3", id: 2, value: 3 },
      { answerText: " 4", id: 2, value: 4 },
      { answerText: " 5", id: 2, value: 5 },
      { answerText: " 6", id: 2, value: 6 },
      { answerText: " 7", id: 2, value: 7 },
      { answerText: " 8", id: 2, value: 8 },
      { answerText: " 9", id: 2, value: 9 },
      { answerText: " 10", id: 2, value: 10 },
    ],
    //3
    [
      { answerText: "Feel overwhelmed", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "Sometimes feel worried ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Not worried", value: 7 },
      { answerText: "", value: 8 },
      { answerText: "", value: 9 },
      { answerText: " Feel comfortable", value: 10 },
    ],
    //4
    [
      {
        answerText: "Worry all the time", value: 1
      },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: " Sometimes worry ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      {
        answerText: "Rarely worry", value: 7
      },
      { answerText: "", value: 8 },
      { answerText: "", value: 9 },
      { answerText: "Never worry", value: 10 },
    ],
    //5
    [
      { answerText: "No confidence", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "Little confidence ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: " Some confidence", value: 7 },
      { answerText: "", value: 8 },
      { answerText: "", value: 9 },
      {
        answerText: "High confidence", value: 10
      },
    ],
    //6
    [
      { answerText: "All the time", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "Sometimes", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Rarely", value: 7 },
      { answerText: "", value: 8 },
      { answerText: "", value: 9 },
      { answerText: "Never", value: 10 },
    ],
    //7
    [
      { answerText: "All the time", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "Sometimes", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Rarely", value: 7 },
      { answerText: "", value: 8 },
      { answerText: "", value: 9 },
      { answerText: "Never", value: 10 },
    ],
    //8
    [
      { answerText: "Overwhelming stress", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "High stress ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Low stress", value: 7 },
      { answerText: "", value: 8 },
      { answerText: "", value: 9 },
      { answerText: "No stress at all", value: 10 },
    ],
  ];

  static self_esteem_stress_question = [
    {
      label: "queNo1",
      questionText:
        "On the whole, I am satisfied with myself.",
    },
    {
      label: "queNo2",
      questionText: "At times I think I am no good at all.",
    },
    {
      label: "queNo3",
      questionText:
        "I feel that I have a number of good qualities. ",
    },
    {
      label: "queNo4",
      questionText:
        "I am able to do things as well as most other people. ",
    },
    {
      label: "queNo5",
      questionText:
        "I feel I do not have much to be proud of.",
    },
    {
      label: "queNo6",
      questionText:
        "I certainly feel useless at times.",
    },
    {
      label: "queNo7",
      questionText:
        "I feel that I'm a person of worth, at least on an equal plane with others.",
    },
    {
      label: "queNo8",
      questionText:
        "I wish I could have more respect for myself. ",
    },
    {
      label: "queNo9",
      questionText: "All in all, I am inclined to feel that I am a failure. ",
    },
    {
      label: "queNo10",
      questionText: "I take a positive attitude toward myself.",
    },
  ];

  static self_esteem_stress_options = [
    [
      { answerText: "Strongly agree", value: 4 },
      { answerText: "Agree",value: 3 },
      { answerText: "Disagree",value: 2 },
      { answerText: "Strongly disagree",value: 1 },
    ],
    [
      { answerText: "Strongly agree", value: 1 },
      { answerText: "Agree",value: 2 },
      { answerText: "Disagree",value: 3 },
      { answerText: "Strongly disagree",value: 4 },
    ],
    [
      { answerText: "Strongly agree", value: 4 },
      { answerText: "Agree",value: 3 },
      { answerText: "Disagree",value: 2 },
      { answerText: "Strongly disagree",value: 1 },
    ],
    [
      { answerText: "Strongly agree", value: 4 },
      { answerText: "Agree",value: 3 },
      { answerText: "Disagree",value: 2 },
      { answerText: "Strongly disagree",value: 1 },
    ],
    [
      { answerText: "Strongly agree", value: 1 },
      { answerText: "Agree",value: 2},
      { answerText: "Disagree",value: 3 },
      { answerText: "Strongly disagree",value: 4 },
    ],
    [
      { answerText: "Strongly agree", value: 1 },
      { answerText: "Agree",value: 2 },
      { answerText: "Disagree",value: 3 },
      { answerText: "Strongly disagree",value: 4 },
    ],
    [
      { answerText: "Strongly agree", value: 4 },
      { answerText: "Agree",value: 3 },
      { answerText: "Disagree",value: 1 },
      { answerText: "Strongly disagree",value: 1 }, 
    ],
    [
      { answerText: "Strongly agree", value: 1 },
      { answerText: "Agree",value: 2 },
      { answerText: "Disagree",value: 3 },
      { answerText: "Strongly disagree",value: 4 },
    ],
    [
      { answerText: "Strongly agree", value: 1 },
      { answerText: "Agree",value: 2 },
      { answerText: "Disagree",value: 3 },
      { answerText: "Strongly disagree",value: 4 },
    ],
    [
      { answerText: "Strongly agree", value: 4 },
      { answerText: "Agree",value: 3 },
      { answerText: "Disagree",value: 2 }, 
      { answerText: "Strongly disagree",value: 1 },
    ],
  ];
  static relationship_questions = [
    {
      label: "queNo1",
      questionText: "How well does your partner meet your needs?",
    },
    {
      label: "queNo2",
      questionText:
        "In general, how satisfied are you with your relationship? ",
    },
    {
      label: "queNo3",
      questionText:
        "How good is your relationship compared to most? ",
    },
    {
      label: "queNo4",
      questionText:
        "How often do you wish you hadn’t gotten into this relationship? ",
    },
    {
      label: "queNo5",
      questionText: "To what extent has your relationship met your original expectations?",
    },
    {
      label: "queNo6",
      questionText:
        "How much do you love your partner? ",
    },
    {
      label: "queNo7",
      questionText:
        "How many problems are there in your relationship?",
    }
  ];
  static relationship_options = [
    [
      { answerText: "Low", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "High", value: 5 },
    ],
    [
      { answerText: "Low", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "High", value: 5 },
    ],
    [
      { answerText: "Low", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "High", value: 5 },
    ],
    [
      { answerText: "Low", value: 5 },
      { answerText: " ", value: 4 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 2 },
      { answerText: "High", value: 1 },
    ],
    [
      { answerText: "Low", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "High", value: 5 },
    ],
    [
      { answerText: "Low", value: 5 },
      { answerText: " ", value: 4 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 2 },
      { answerText: "High", value: 1 },
    ],
    [
      { answerText: "Low", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "High", value: 5 },
    ],
  ];
  static ace_options = [
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
    [
      { value: 1, answerText: "Yes" },
      { value: 0, answerText: "No" },
    ],
  ];
  static ace_questions = [
    {
      label: "queNo1",
      questionText: "Did a parent or other adult in the house often:",
      subquestion: [
        "•	Swear at you, insult you, put you down, or humiliate you?",
        "•	Act in a way that made you afraid that you might be physically hurt?",
      ],
    },
    {
      label: "queNo2",
      questionText: "Did a parent or other adult in the household often:",
      subquestion: [
        "•	Push, grab, slap, or throw something at you?",
        "•	Ever hit you so hard that you had marks or were injured?",
      ],
    },
    {
      label: "queNo3",
      questionText:
        "Did an adult or person at least 5 years older than you ever:",
      subquestion: [
        "•	Touch or fondle you or have you touch their body in a sexual way?",
        "•	Attempt or actually have oral, anal, or vaginal intercourse with you?",
      ],
    },
    {
      label: "queNo4",
      questionText: "Did you often feel that:",
      subquestion: [
        "•	No one in your family loved you or thought you were important or special?",
        "•	Your family didn’t look out for each other, feel close to each other, or support each other?",
      ],
    },
    {
      label: "queNo5",
      questionText: "Did you often feel that:",
      subquestion: [
        "•	You didn’t have enough to eat, had to wear dirty clothes, and had no one to protect you?",
        "•	Your parents were too drunk or high to take care of you or take you to the doctor if you needed it?",
      ],
    },
    {
      label: "queNo6",
      questionText: "Were your parents ever separated or divorced?",
    },
    {
      label: "queNo7",
      questionText: "Were any of your parents or other adult caregivers:",
      subquestion: [
        "•	Often pushed, grabbed, slapped, or had something thrown at them?",
        "•	Sometimes or often kicked, bitten, hit with a fist, or hit with something hard? ",
        "•	Ever repeatedly hit over at least a few minutes or threatened with a gun or knife?",
      ],
    },
    {
      label: "queNo8",
      questionText:
        "Did you live with anyone who was a problem drinker or alcoholic, or who used street drugs?",
    },
    {
      label: "queNo9",
      questionText:
        "Was a household member depressed or mentally ill, or did a household member attempt suicide?",
    },
    {
      label: "queNo10",
      questionText: "Did a household member go to prison?",
    },
  ];
  static ocdt_options = [
    [
      { answerText: "None" },
      { answerText: "Less than 1 hr/day or occasional occurrence " },
      { answerText: "1 to 3 hrs/day or frequent" },
      {
        answerText:
          "Greater than 3 and up to 8 hrs/day or very frequent occurrence",
      },
      { answerText: "Greater than 8 hrs/day or nearly constant occurrence" },
    ],
    [
      { answerText: "None" },
      {
        answerText:
          "Slight interference with social or other activities, but overall performance not impaired",
      },
      {
        answerText:
          "Definite interference with social or occupational performance, but still manageable ",
      },
      {
        answerText:
          "Causes substantial impairment in social or occupational performance ",
      },
      { answerText: "Incapacitating" },
    ],
    [
      { answerText: "None" },
      { answerText: "Not too disturbing" },
      { answerText: "Disturbing, but still manageable" },
      { answerText: "Very disturbing" },
      { answerText: "Near constant and disabling distress" },
    ],
    [
      { answerText: "Try to resist all the time" },
      { answerText: "Try to resist most of the time" },
      { answerText: "Make some effort to resist" },
      {
        answerText:
          "Yield to all obsessions without attempting to control them, but with some reluctance",
      },
      { answerText: "Completely and willingly yield to all obsessions" },
    ],
    [
      { answerText: "Complete control" },
      {
        answerText:
          "Usually able to stop or divert obsessions with some effort and concentration",
      },
      { answerText: "Sometimes able to stop or divert obsessions" },
      {
        answerText:
          "Rarely successful in stopping or dismissing obsessions, can only divert attention with difficulty",
      },
      {
        answerText:
          "Obsessions are completely involuntary, rarely able to even momentarily alter obsessive thinking",
      },
    ],
  ];
  static ocdt_options1 = [
    [
      { answerText: "None" },
      {
        answerText:
          "Less than 1 hr/day or occasional occurrence of compulsive behaviors",
      },
      {
        answerText:
          "1 to 3 hrs/day, or frequent performance of compulsive behaviors ",
      },
      {
        answerText:
          "Greater than 3 and up to 8 hrs/day or very frequent occurrence of compulsive behaviors",
      },
      {
        answerText:
          "More than 8 hrs/day, or near constant performance of compulsive behaviors (too numerous to count) ",
      },
    ],
    [
      { answerText: "None" },
      {
        answerText:
          "Slight interference with social or other activities, but overall performance not impaired ",
      },
      {
        answerText:
          "Definite interference with social or occupational performance, but still manageable ",
      },
      {
        answerText:
          "Causes substantial impairment in social or occupational performance",
      },
      { answerText: "Incapacitating" },
    ],
    [
      { answerText: "None" },
      { answerText: "Only slightly anxious if compulsions prevented " },
      {
        answerText:
          "Anxiety would mount but remain manageable if compulsions prevented",
      },
      {
        answerText:
          "Prominent and very disturbing increase in anxiety if compulsions interrupted",
      },
      {
        answerText:
          "Incapacitating anxiety from any intervention aimed at modifying activity",
      },
    ],
    [
      { answerText: "Always try to resist" },
      { answerText: "Try to resist most of the time" },
      { answerText: "Make some effort to resist" },
      {
        answerText:
          "Yield to almost all compulsions without attempting to control them, but with some reluctance",
      },
      { answerText: "Completely and willingly yield to all compulsions" },
    ],
    [
      { answerText: "Complete control" },
      {
        answerText:
          "Pressure to perform the behavior but usually able to exercise voluntary control over it ",
      },
      {
        answerText:
          "Strong pressure to perform behavior, can control it only with difficulty ",
      },
      {
        answerText:
          "Very strong drive to perform behavior, must be carried to completion, can only delay with difficulty ",
      },
      {
        answerText:
          "Drive to perform behavior experienced as completely involuntary and over-powering, rarely able to even momentarily delay activity",
      },
    ],
  ];
  static ocdt_questions = [
    {
      label: "queNo1",
      questionHeading: "Time Occupied By Obsessive Thoughts ",
      questionText: "How much of your time is occupied by obsessive thoughts?",
    },
    {
      label: "queNo2",
      questionHeading: "Interference Due To Obsessive Thoughts ",
      questionText:
        "How much do your obsessive thoughts interfere with your work, school, social, or other important role functioning? Is there anything that you don’t do because of them? ",
    },
    {
      label: "queNo3",
      questionHeading: "Distress Associated With Obsessive Thoughts ",
      questionText: "How much distress do your obsessive thoughts cause you?",
    },
    {
      label: "queNo4",
      questionHeading: "Resistance Against Obsessions ",
      questionText:
        "How much of an effort do you make to resist the obsessive thoughts? How often do you try to disregard or turn your attention away from these thoughts as they enter your mind?",
    },
    {
      label: "queNo5",
      questionHeading: "Degree Of Control Over Obsessive Thoughts",
      questionText:
        "How much control do you have over your obsessive thoughts? How successful are you in stopping or diverting your obsessive thinking? Can you dismiss them? ",
    },
  ];
  static ocdt_questions1 = [
    {
      label: "queNo6",
      questionHeading: "Time Spent Performing Compulsive Behaviors ",
      questionText:
        "How much time do you spend performing compulsive behaviors? How much longer than most people does it take to complete routine activities because of your rituals? How frequently do you do rituals? ",
    },
    {
      label: "queNo7",
      questionHeading: "Interference Due To Compulsive Behaviors ",
      questionText:
        "How much do your compulsive behaviors interfere with your work, school, social, or other important role functioning? Is there anything that you don’t do because of the compulsions?",
    },
    {
      label: "queNo8",
      questionHeading: " Distress Associated With Compulsive Behavior ",
      questionText:
        "How would you feel if prevented from performing your compulsion(s)? How anxious would you become? ",
    },
    {
      label: "queNo9",
      questionHeading: "Resistance Against Compulsions ",
      questionText:
        "How much of an effort do you make to resist the compulsions? ",
    },
    {
      label: "queNo10",
      questionHeading: "Degree Of Control Over Compulsive Behavior  ",
      questionText:
        "How strong is the drive to perform the compulsive behavior? How much control do you have over the compulsions?",
    },
  ];
  static personality_questions = [
    {
      label: "queNo1",
      questionText: "Worries a lot.",
    },
    {
      label: "queNo2",
      questionText: "Gets nervous easily.",
    },
    {
      label: "queNo3",
      questionText: "Remains calm in tense situations.",
    },
    {
      label: "queNo4",
      questionText: "Is talkative.",
    },
    {
      label: "queNo5",
      questionText: "Is outgoing, sociable.",
    },
    {
      label: "queNo6",
      questionText: "Is reserved.",
    },
    {
      label: "queNo7",
      questionText: "Is original, comes up with new ideas.",
    },
    {
      label: "queNo8",
      questionText: "Values artistic, aesthetic experiences.",
    },
    {
      label: "queNo9",
      questionText: "Has an active imagination.",
    },
    {
      label: "queNo10",
      questionText: "Is sometimes rude to others.",
    },
    {
      label: "queNo11",
      questionText: "Has a forgiving nature.",
    },
    {
      label: "queNo12",
      questionText: "Is considerate and kind to almost anyone.",
    },
    {
      label: "queNo13",
      questionText: "Does a thorough job.",
    },
    {
      label: "queNo14",
      questionText: "Tends to be lazy.",
    },
    {
      label: "queNo15",
      questionText: "Does things efficiently.",
    },
  ];

  static personality_options = [
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: "", value: 2 },
      { answerText: "", value: 3 },
      { answerText: "", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 7 },
      { answerText: " ", value: 6 },
      { answerText: " ", value: 5 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 7 },
      { answerText: " ", value: 6 },
      { answerText: " ", value: 5 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 7 },
      { answerText: " ", value: 6 },
      { answerText: " ", value: 5 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
    [
      { answerText: "Strongly Disagree", value: 7 },
      { answerText: " ", value: 6 },
      { answerText: " ", value: 5 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 3 },
      { answerText: "", value: 2 },
      { answerText: "Strongly Agree", value: 1 },
    ],
    [
      { answerText: "Strongly Disagree", value: 1 },
      { answerText: " ", value: 2 },
      { answerText: " ", value: 3 },
      { answerText: " ", value: 4 },
      { answerText: "", value: 5 },
      { answerText: "", value: 6 },
      { answerText: "Strongly Agree", value: 7 },
    ],
  ];

  static loneliness_options = [
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 1 },
      { answerText: "Rarely", value: 2 },
      { answerText: "Sometimes", value: 3 },
      { answerText: "Always", value: 4 }
    ],
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
    [
      { answerText: "Never", value: 4 },
      { answerText: "Rarely", value: 3 },
      { answerText: "Sometimes", value: 2 },
      { answerText: "Always", value: 1 }
    ],
  ];
  static loneliness_questions = [
    {
      label: "queNo1",
      questionText: "How often do you feel that you are “in tune” with the people around you?",
    },
    {
      label: "queNo2",
      questionText:
        "How often do you feel that you lack companionship?",
    },
    {
      label: "queNo3",
      questionText:
        "How often do you feel that there is no one you can turn to?",
    },
    {
      label: "queNo4",
      questionText:
        "How often do you feel alone?",
    },
    {
      label: "queNo5",
      questionText: "How often do you feel part of a group of friends?",
    },
    {
      label: "queNo6",
      questionText:
        "How often do you feel that you have a lot in common with the people around you?",
    },
    {
      label: "queNo7",
      questionText:
        "How often do you feel like you are no longer close to anyone?",
    },
    {
      label: "queNo8",
      questionText: "How often do you feel that your interests and ideas are not shared by those around you?",
    },
    {
      label: "queNo9",
      questionText: "How often do you feel outgoing and friendly? ",
    },
    {
      label: "queNo10",
      questionText:
        "How often do you feel close to people?",
    },
    {
      label: "queNo11",
      questionText: "How often do you feel left out?",
    },
    {
      label: "queNo12",
      questionText:
        "How often do you feel that your relationships with others are not meaningful?",
    },
    {
      label: "queNo13",
      questionText:
        "How often do you feel that no one really knows you we11?",
    },
    {
      label: "queNo14",
      questionText:
        "How often do you feel isolated from others?",
    },
    {
      label: "queNo15",
      questionText: "How often do you fee1 you can find companionship when you want it?",
    },
    {
      label: "queNo16",
      questionText: "How often do you feel that there are people who really understand you? ",
    },
    {
      label: "queNo17",
      questionText:
        "How often do you feel shy?",
    },
    {
      label: "queNo18",
      questionText:
        "How often do you feel that people are around you but not with you?",
    },
    {
      label: "queNo19",
      questionText:
        "How often do you feel that there are people you can talk to?",
    },
    {
      label: "queNo20",
      questionText: "How often do you feel that there are people you can turn to?",
    },
  ];
  static narcissism_questions = [
    {
      label: "queNo1",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo2",
      questionText: "Choose one of the following options:",
    },
    {
      label: "queNo3",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo4",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo5",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo6",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo7",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo8",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo9",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo10",
      questionText: "Choose one of the following options:",
    },
    {
      label: "queNo11",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo12",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo13",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo14",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo15",
      questionText: "Choose one of the following options:"
      ,
    },
    {
      label: "queNo16",
      questionText: "Choose one of the following options:"
      ,
    }
  ];
  static narcissism_options = [
    [
      { answerText: "I really like to be the center of attention" },
      { answerText: "It makes me uncomfortable to be the center of attention " },
    ],
    [
      { answerText: "I am no better or no worse than most people" },
      { answerText: "I think I am a special person " },
    ],
    [
      { answerText: "Everybody likes to hear my stories" },
      { answerText: "Sometimes I tell good stories" },
    ],
    [
      { answerText: "I usually get the respect that I deserve" },
      { answerText: "I insist upon getting the respect that is due me" },
    ],
    [
      { answerText: "I don't mind following orders" },
      { answerText: "I like having authority over people" },
    ],
    [
      { answerText: "I am going to be a great person" },
      { answerText: "I hope I am going to be successful" },
    ],
    [
      { answerText: "People sometimes believe what I tell them" },
      { answerText: "I can make anybody believe anything I want them to" },
    ],
    [
      { answerText: "I expect a great deal from other people" },
      { answerText: "I like to do things for other people" },
    ],
    [
      { answerText: "I like to be the center of attention" },
      { answerText: "I prefer to blend in with the crowd" },
    ],
    [
      { answerText: "I am much like everybody else" },
      { answerText: "I am an extraordinary person" },
    ],
    [
      { answerText: "I always know what I am doing" },
      { answerText: "Sometimes I am not sure of what I am doing" },
    ],
    [
      { answerText: "I don't like it when I find myself manipulating people" },
      { answerText: "I find it easy to manipulate people" },
    ],
    [
      { answerText: "Being an authority doesn't mean that much to me" },
      { answerText: "People always seem to recognize my authority" },
    ],
    [
      { answerText: "I know that I am good because everybody keeps telling me so" },
      { answerText: "When people compliment me I sometimes get embarrassed" },
    ],
    [
      { answerText: "I try not to be a show off" },
      { answerText: "I am apt to show off if I get the chance" },
    ],
    [
      { answerText: "I am more capable than other people" },
      { answerText: "There is a lot that I can learn from other people" },
    ],
  ];
  static ages = [
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
  ];
  static gender = [
    "Male",
    "Female",
    "Non-Binary",
    "Transgender",
    "Intersex",
    "Neutral",
    "I prefer not to say",
    "Others",
    "Let me type...",
  ];
  static jobqualification = [
    "Bachelors in psychology ",
    "Masters in psychology",
    "PHD in psychology",
    "Other (Please Mention)",
  ];
  static counsellingexperience = [
    "0 years",
    "Less than a year",
    "1-3 years",
    "3-6 years ",
    "6-10 years ",
    "10-15 years",
    "More than 15 years",
  ];
  static counsellingFelicityPerWeek = [
    "Less than 9 hours",
    "9-18 hours",
    "18-27 hours",
    "27 to 36 hours",
    "36 to 45 hours",
  ];
  static WorkingElseWhereApartFelicity = [
    "Yes, I will continue with my private practice",
    "Yes, I will continue with my employment as a counsellor (please mention employer)",
    "Yes, I will continue my employment, but it is a non- counselling job",
    "No, am not employed or practicing not now but I might in the future",
    "No, I will not.",
  ];
  static specializeareas1 = [
    "Others",
    "Anxiety",
    "Stress",
    "Depression",
    "Adolescent Issues",
    "Relationship Issues",
    "Career Related Concerns",
    "Grief",
    "Geriatric Issues",
    "Family Therapy",
    "Trauma",
    "OCD",
    "Self- Image Issues",
    "Depression, Self-Improvement",
    "Self- Image Issues, Self-Improvement",
    "Mood Disorder",
    "Depression",
    "Neurodevelopmental Disorders",
    "Psychotic Disorder, Schizophrenia",
    "Neurocognitive Disorder",
    "Pain Management",
    "Personality Disorder",
    "LGBT",
    "Sexual Dysfunction",
    "Sleep Disorder",
    "Psychosomatic disorders",
    "Dissociative disorder",
    "Attachment Disorder",
    "OCD",
    "Eating Disorder",
  ];

  static specializeareasOptions = [
    "Not Sure",
    "Anxiety",
    "Depression",
    "Relationship Issues",
    "Couple Counseling",
    "Sexual Issues",
    "Suicidal Ideation",
    "Grief & Loss",
    "OCD",
    "Bipolar Disorder",
    "Psychosis",
    "Sleep Issues",
    "Cognitive Rehabilitation",
    "Trauma",
    "Narcissistic Abuse",
    "ADHD (Adult)",
    "ADHD (Child)",
    "Autism Spectrum Disorders",
    "Family Therapy",
    "Self-harm",
    "Body Image",
    "Self Confidence",
    "Anger",
    "Psycho-Somatic Disorders",
    "Pain Management",
    "LGBTQI",
    "Psycho-Oncology",
    "Phobia",
    "Child Counseling",
    "Geriatric Issues",
    "Family Therapy",
    "Career Counselling",
    "Marital Discord",
    "Eating Disorders",
    "Stress",
  ];
  static therapeuticExpertiesOptions = [
    "Cognitive Behavioural Therapy (CBT)",
    "Dialectical Behavioural Therapy (DBT)",
    "Client Centred",
    "Psychodynamic",
    "Psychoanalytic",
    "Gestalt",
    "Existential",
    "Rational Emotive Behavioural Therapy (REBT)",
    "Transactional Analysis (TA)",
    "Hypnotherapy/Regression",
    "Art Therapy",
    "Music Therapy",
    "Eye Movement Desensitization and Reprocessing (EMDR)",
    "Mindfulness Based Therapies",
    "Play and Art therapy for kids",
    "Behavioural modification for kids",
    "Trauma Informed Therapy",
    "Neuro-linguistic programming (NLP)",
    "Habit Coaching",
    "Life Coaching",
  ];
  static language = [
    "Not Applicable",
    "Abkhaz",
    "Afar",
    "Afrikaans",
    "Akan",
    "Albanian",
    "Amharic",
    "Arabic",
    "Aragonese",
    "Armenian",
    "Assamese",
    "Avaric",
    "Avestan",
    "Aymara",
    "Azerbaijani",
    "Bambara",
    "Bashkir",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bihari",
    "Bislama",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Burmese",
    "Catalan",
    "Valencian",
    "Chamorro",
    "Chechen",
    "Chichewa",
    "Chewa",
    "Nyanja",
    "Chinese",
    "Chuvash",
    "Cornish",
    "Corsican",
    "Cree",
    "Croatian",
    "Czech",
    "Danish",
    "Divehi",
    "Dogri",
    "Maldivian",
    "Dutch",
    "English",
    "Esperanto",
    "Estonian",
    "Ewe",
    "Faroese",
    "Fijian",
    "Finnish",
    "French",
    "Fula",
    "Galician",
    "Georgian",
    "German",
    "Greek",
    "Guaraní",
    "Gujarati",
    "Haitian",
    "Hausa",
    "Hebrew",
    "Herero",
    "Hindi",
    "Hiri Motu",
    "Hungarian",
    "Interlingua",
    "Indonesian",
    "Interlingue",
    "Irish",
    "Igbo",
    "Inupiaq",
    "Ido",
    "Icelandic",
    "Italian",
    "Inuktitut",
    "Japanese",
    "Javanese",
    "Kalaallisut",
    "Kannada",
    "Kanuri",
    "Kashmiri",
    "Kazakh",
    "Khmer",
    "Kikuyu",
    "Kinyarwanda",
    "Kirghiz",
    "Komi",
    "Kongo",
    "Konkani",
    "Korean",
    "Kurdish",
    "Kwanyama",
    "Latin",
    "Luxembourgish",
    "Luganda",
    "Limburgish",
    "Lingala",
    "Lao",
    "Lithuanian",
    "Luba-Katanga",
    "Latvian",
    "Manx",
    "Macedonian",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Maltese",
    "Maori",
    "Marathi",
    "Marshallese",
    "Mongolian",
    "Nauru",
    "Navajo",
    "Norwegian Bokmal",
    "North Ndebele",
    "Nepali",
    "Ndonga",
    "Norwegian Nynorsk",
    "Norwegian",
    "Nuosu",
    "South Ndebele",
    "Occitan",
    "Ojibwe",
    "Old Church Slavonic",
    "Oromo",
    "Oriya",
    "Ossetian",
    "Pahadi",
    "Punjabi",
    "Pāli",
    "Persian",
    "Polish",
    "Pashto",
    "Portuguese",
    "Quechua",
    "Romansh",
    "Kirundi",
    "Romanian",
    "Russian",
    "Sanskrit",
    "Sardinian",
    "Sindhi",
    "Northern Sami",
    "Samoan",
    "Sango",
    "Serbian",
    "Scottish",
    "Shona",
    "Sinhalese",
    "Slovak",
    "Slovene",
    "Somali",
    "Southern Sotho",
    "Spanish",
    "Sundanese",
    "Swahili",
    "Swati",
    "Swedish",
    "Tamil",
    "Telugu",
    "Tajik",
    "Thai",
    "Tigrinya",
    "Tibetan Standard",
    "Turkmen",
    "Tagalog",
    "Tswana",
    "Tonga",
    "Turkish",
    "Tsonga",
    "Tatar",
    "Twi",
    "Tahitian",
    "Uighur",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Venda",
    "Vietnamese",
    "Volapük",
    "Walloon",
    "Welsh",
    "Wolof",
    "Western",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zhuang",
  ];
  static ChatMessage = ["Yes", "No"];
  static TimeSlott = [
    "7 AM - 11 AM",
    "11 AM - 3 PM",
    "3 PM - 7 PM",
    "7 PM - 11 PM",
    "11 PM - 3 AM",
    "3 AM - 7 AM",
  ];
  static AMORPM = ["AM", "PM"];
  static TimeZone = [
    { offset: "-11:00", label: "(GMT-11:00) Niue", tzCode: "Pacific/Niue" },
    {
      offset: "-11:00",
      label: "(GMT-11:00) Pago Pago",
      tzCode: "Pacific/Pago_Pago",
    },
    {
      offset: "-10:00",
      label: "(GMT-10:00) Hawaii Time",
      tzCode: "Pacific/Honolulu",
    },
    {
      offset: "-10:00",
      label: "(GMT-10:00) Rarotonga",
      tzCode: "Pacific/Rarotonga",
    },
    { offset: "-10:00", label: "(GMT-10:00) Tahiti", tzCode: "Pacific/Tahiti" },
    {
      offset: "-09:30",
      label: "(GMT-09:30) Marquesas",
      tzCode: "Pacific/Marquesas",
    },
    {
      offset: "-09:00",
      label: "(GMT-09:00) Alaska Time",
      tzCode: "America/Anchorage",
    },
    {
      offset: "-09:00",
      label: "(GMT-09:00) Gambier",
      tzCode: "Pacific/Gambier",
    },
    {
      offset: "-08:00",
      label: "(GMT-08:00) Pacific Time",
      tzCode: "America/Los_Angeles",
    },
    {
      offset: "-08:00",
      label: "(GMT-08:00) Pacific Time - Tijuana",
      tzCode: "America/Tijuana",
    },
    {
      offset: "-08:00",
      label: "(GMT-08:00) Pacific Time - Vancouver",
      tzCode: "America/Vancouver",
    },
    {
      offset: "-08:00",
      label: "(GMT-08:00) Pacific Time - Whitehorse",
      tzCode: "America/Whitehorse",
    },
    {
      offset: "-08:00",
      label: "(GMT-08:00) Pitcairn",
      tzCode: "Pacific/Pitcairn",
    },
    {
      offset: "-07:00",
      label: "(GMT-07:00) Mountain Time",
      tzCode: "America/Denver",
    },
    {
      offset: "-07:00",
      label: "(GMT-07:00) Mountain Time - Arizona",
      tzCode: "America/Phoenix",
    },
    {
      offset: "-07:00",
      label: "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan",
      tzCode: "America/Mazatlan",
    },
    {
      offset: "-07:00",
      label: "(GMT-07:00) Mountain Time - Dawson Creek",
      tzCode: "America/Dawson_Creek",
    },
    {
      offset: "-07:00",
      label: "(GMT-07:00) Mountain Time - Edmonton",
      tzCode: "America/Edmonton",
    },
    {
      offset: "-07:00",
      label: "(GMT-07:00) Mountain Time - Hermosillo",
      tzCode: "America/Hermosillo",
    },
    {
      offset: "-07:00",
      label: "(GMT-07:00) Mountain Time - Yellowknife",
      tzCode: "America/Yellowknife",
    },
    { offset: "-06:00", label: "(GMT-06:00) Belize", tzCode: "America/Belize" },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Central Time",
      tzCode: "America/Chicago",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Central Time - Mexico City",
      tzCode: "America/Mexico_City",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Central Time - Regina",
      tzCode: "America/Regina",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Central Time - Tegucigalpa",
      tzCode: "America/Tegucigalpa",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Central Time - Winnipeg",
      tzCode: "America/Winnipeg",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Costa Rica",
      tzCode: "America/Costa_Rica",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) El Salvador",
      tzCode: "America/El_Salvador",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Galapagos",
      tzCode: "Pacific/Galapagos",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Guatemala",
      tzCode: "America/Guatemala",
    },
    {
      offset: "-06:00",
      label: "(GMT-06:00) Managua",
      tzCode: "America/Managua",
    },
    {
      offset: "-05:00",
      label: "(GMT-05:00) America Cancun",
      tzCode: "America/Cancun",
    },
    { offset: "-05:00", label: "(GMT-05:00) Bogota", tzCode: "America/Bogota" },
    {
      offset: "-05:00",
      label: "(GMT-05:00) Easter Island",
      tzCode: "Pacific/Easter",
    },
    {
      offset: "-05:00",
      label: "(GMT-05:00) Eastern Time",
      tzCode: "America/New_York",
    },
    {
      offset: "-05:00",
      label: "(GMT-05:00) Eastern Time - Iqaluit",
      tzCode: "America/Iqaluit",
    },
    {
      offset: "-05:00",
      label: "(GMT-05:00) Eastern Time - Toronto",
      tzCode: "America/Toronto",
    },
    {
      offset: "-05:00",
      label: "(GMT-05:00) Guayaquil",
      tzCode: "America/Guayaquil",
    },
    { offset: "-05:00", label: "(GMT-05:00) Havana", tzCode: "America/Havana" },
    {
      offset: "-05:00",
      label: "(GMT-05:00) Jamaica",
      tzCode: "America/Jamaica",
    },
    { offset: "-05:00", label: "(GMT-05:00) Lima", tzCode: "America/Lima" },
    { offset: "-05:00", label: "(GMT-05:00) Nassau", tzCode: "America/Nassau" },
    { offset: "-05:00", label: "(GMT-05:00) Panama", tzCode: "America/Panama" },
    {
      offset: "-05:00",
      label: "(GMT-05:00) Port-au-Prince",
      tzCode: "America/Port-au-Prince",
    },
    {
      offset: "-05:00",
      label: "(GMT-05:00) Rio Branco",
      tzCode: "America/Rio_Branco",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Atlantic Time - Halifax",
      tzCode: "America/Halifax",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Barbados",
      tzCode: "America/Barbados",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Bermuda",
      tzCode: "Atlantic/Bermuda",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Boa Vista",
      tzCode: "America/Boa_Vista",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Caracas",
      tzCode: "America/Caracas",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Curacao",
      tzCode: "America/Curacao",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Grand Turk",
      tzCode: "America/Grand_Turk",
    },
    { offset: "-04:00", label: "(GMT-04:00) Guyana", tzCode: "America/Guyana" },
    { offset: "-04:00", label: "(GMT-04:00) La Paz", tzCode: "America/La_Paz" },
    { offset: "-04:00", label: "(GMT-04:00) Manaus", tzCode: "America/Manaus" },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Martinique",
      tzCode: "America/Martinique",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Port of Spain",
      tzCode: "America/Port_of_Spain",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Porto Velho",
      tzCode: "America/Porto_Velho",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Puerto Rico",
      tzCode: "America/Puerto_Rico",
    },
    {
      offset: "-04:00",
      label: "(GMT-04:00) Santo Domingo",
      tzCode: "America/Santo_Domingo",
    },
    { offset: "-04:00", label: "(GMT-04:00) Thule", tzCode: "America/Thule" },
    {
      offset: "-03:30",
      label: "(GMT-03:30) Newfoundland Time - St. Johns",
      tzCode: "America/St_Johns",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Araguaina",
      tzCode: "America/Araguaina",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Asuncion",
      tzCode: "America/Asuncion",
    },
    { offset: "-03:00", label: "(GMT-03:00) Belem", tzCode: "America/Belem" },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Buenos Aires",
      tzCode: "America/Argentina/Buenos_Aires",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Campo Grande",
      tzCode: "America/Campo_Grande",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Cayenne",
      tzCode: "America/Cayenne",
    },
    { offset: "-03:00", label: "(GMT-03:00) Cuiaba", tzCode: "America/Cuiaba" },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Fortaleza",
      tzCode: "America/Fortaleza",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Godthab",
      tzCode: "America/Godthab",
    },
    { offset: "-03:00", label: "(GMT-03:00) Maceio", tzCode: "America/Maceio" },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Miquelon",
      tzCode: "America/Miquelon",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Montevideo",
      tzCode: "America/Montevideo",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Palmer",
      tzCode: "Antarctica/Palmer",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Paramaribo",
      tzCode: "America/Paramaribo",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Punta Arenas",
      tzCode: "America/Punta_Arenas",
    },
    { offset: "-03:00", label: "(GMT-03:00) Recife", tzCode: "America/Recife" },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Rothera",
      tzCode: "Antarctica/Rothera",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Salvador",
      tzCode: "America/Bahia",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Santiago",
      tzCode: "America/Santiago",
    },
    {
      offset: "-03:00",
      label: "(GMT-03:00) Stanley",
      tzCode: "Atlantic/Stanley",
    },
    {
      offset: "-02:00",
      label: "(GMT-02:00) Noronha",
      tzCode: "America/Noronha",
    },
    {
      offset: "-02:00",
      label: "(GMT-02:00) Sao Paulo",
      tzCode: "America/Sao_Paulo",
    },
    {
      offset: "-02:00",
      label: "(GMT-02:00) South Georgia",
      tzCode: "Atlantic/South_Georgia",
    },
    {
      offset: "-01:00",
      label: "(GMT-01:00) Azores",
      tzCode: "Atlantic/Azores",
    },
    {
      offset: "-01:00",
      label: "(GMT-01:00) Cape Verde",
      tzCode: "Atlantic/Cape_Verde",
    },
    {
      offset: "-01:00",
      label: "(GMT-01:00) Scoresbysund",
      tzCode: "America/Scoresbysund",
    },
    {
      offset: "+00:00",
      label: "(GMT+00:00) Abidjan",
      tzCode: "Africa/Abidjan",
    },
    { offset: "+00:00", label: "(GMT+00:00) Accra", tzCode: "Africa/Accra" },
    { offset: "+00:00", label: "(GMT+00:00) Bissau", tzCode: "Africa/Bissau" },
    {
      offset: "+00:00",
      label: "(GMT+00:00) Canary Islands",
      tzCode: "Atlantic/Canary",
    },
    {
      offset: "+00:00",
      label: "(GMT+00:00) Casablanca",
      tzCode: "Africa/Casablanca",
    },
    {
      offset: "+00:00",
      label: "(GMT+00:00) Danmarkshavn",
      tzCode: "America/Danmarkshavn",
    },
    { offset: "+00:00", label: "(GMT+00:00) Dublin", tzCode: "Europe/Dublin" },
    {
      offset: "+00:00",
      label: "(GMT+00:00) El Aaiun",
      tzCode: "Africa/El_Aaiun",
    },
    { offset: "+00:00", label: "(GMT+00:00) Faeroe", tzCode: "Atlantic/Faroe" },
    {
      offset: "+00:00",
      label: "(GMT+00:00) GMT (no daylight saving)",
      tzCode: "Etc/GMT",
    },
    { offset: "+00:00", label: "(GMT+00:00) Lisbon", tzCode: "Europe/Lisbon" },
    { offset: "+00:00", label: "(GMT+00:00) London", tzCode: "Europe/London" },
    {
      offset: "+00:00",
      label: "(GMT+00:00) Monrovia",
      tzCode: "Africa/Monrovia",
    },
    {
      offset: "+00:00",
      label: "(GMT+00:00) Reykjavik",
      tzCode: "Atlantic/Reykjavik",
    },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Algiers",
      tzCode: "Africa/Algiers",
    },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Amsterdam",
      tzCode: "Europe/Amsterdam",
    },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Andorra",
      tzCode: "Europe/Andorra",
    },
    { offset: "+01:00", label: "(GMT+01:00) Berlin", tzCode: "Europe/Berlin" },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Brussels",
      tzCode: "Europe/Brussels",
    },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Budapest",
      tzCode: "Europe/Budapest",
    },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Central European Time - Belgrade",
      tzCode: "Europe/Belgrade",
    },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Central European Time - Prague",
      tzCode: "Europe/Prague",
    },
    { offset: "+01:00", label: "(GMT+01:00) Ceuta", tzCode: "Africa/Ceuta" },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Copenhagen",
      tzCode: "Europe/Copenhagen",
    },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Gibraltar",
      tzCode: "Europe/Gibraltar",
    },
    { offset: "+01:00", label: "(GMT+01:00) Lagos", tzCode: "Africa/Lagos" },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Luxembourg",
      tzCode: "Europe/Luxembourg",
    },
    { offset: "+01:00", label: "(GMT+01:00) Madrid", tzCode: "Europe/Madrid" },
    { offset: "+01:00", label: "(GMT+01:00) Malta", tzCode: "Europe/Malta" },
    { offset: "+01:00", label: "(GMT+01:00) Monaco", tzCode: "Europe/Monaco" },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Ndjamena",
      tzCode: "Africa/Ndjamena",
    },
    { offset: "+01:00", label: "(GMT+01:00) Oslo", tzCode: "Europe/Oslo" },
    { offset: "+01:00", label: "(GMT+01:00) Paris", tzCode: "Europe/Paris" },
    { offset: "+01:00", label: "(GMT+01:00) Rome", tzCode: "Europe/Rome" },
    {
      offset: "+01:00",
      label: "(GMT+01:00) Stockholm",
      tzCode: "Europe/Stockholm",
    },
    { offset: "+01:00", label: "(GMT+01:00) Tirane", tzCode: "Europe/Tirane" },
    { offset: "+01:00", label: "(GMT+01:00) Tunis", tzCode: "Africa/Tunis" },
    { offset: "+01:00", label: "(GMT+01:00) Vienna", tzCode: "Europe/Vienna" },
    { offset: "+01:00", label: "(GMT+01:00) Warsaw", tzCode: "Europe/Warsaw" },
    { offset: "+01:00", label: "(GMT+01:00) Zurich", tzCode: "Europe/Zurich" },
    { offset: "+02:00", label: "(GMT+02:00) Amman", tzCode: "Asia/Amman" },
    { offset: "+02:00", label: "(GMT+02:00) Athens", tzCode: "Europe/Athens" },
    { offset: "+02:00", label: "(GMT+02:00) Beirut", tzCode: "Asia/Beirut" },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Bucharest",
      tzCode: "Europe/Bucharest",
    },
    { offset: "+02:00", label: "(GMT+02:00) Cairo", tzCode: "Africa/Cairo" },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Chisinau",
      tzCode: "Europe/Chisinau",
    },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Damascus",
      tzCode: "Asia/Damascus",
    },
    { offset: "+02:00", label: "(GMT+02:00) Gaza", tzCode: "Asia/Gaza" },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Helsinki",
      tzCode: "Europe/Helsinki",
    },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Jerusalem",
      tzCode: "Asia/Jerusalem",
    },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Johannesburg",
      tzCode: "Africa/Johannesburg",
    },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Khartoum",
      tzCode: "Africa/Khartoum",
    },
    { offset: "+02:00", label: "(GMT+02:00) Kiev", tzCode: "Europe/Kiev" },
    { offset: "+02:00", label: "(GMT+02:00) Maputo", tzCode: "Africa/Maputo" },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Moscow-01 - Kaliningrad",
      tzCode: "Europe/Kaliningrad",
    },
    { offset: "+02:00", label: "(GMT+02:00) Nicosia", tzCode: "Asia/Nicosia" },
    { offset: "+02:00", label: "(GMT+02:00) Riga", tzCode: "Europe/Riga" },
    { offset: "+02:00", label: "(GMT+02:00) Sofia", tzCode: "Europe/Sofia" },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Tallinn",
      tzCode: "Europe/Tallinn",
    },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Tripoli",
      tzCode: "Africa/Tripoli",
    },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Vilnius",
      tzCode: "Europe/Vilnius",
    },
    {
      offset: "+02:00",
      label: "(GMT+02:00) Windhoek",
      tzCode: "Africa/Windhoek",
    },
    { offset: "+03:00", label: "(GMT+03:00) Baghdad", tzCode: "Asia/Baghdad" },
    {
      offset: "+03:00",
      label: "(GMT+03:00) Istanbul",
      tzCode: "Europe/Istanbul",
    },
    { offset: "+03:00", label: "(GMT+03:00) Minsk", tzCode: "Europe/Minsk" },
    {
      offset: "+03:00",
      label: "(GMT+03:00) Moscow+00 - Moscow",
      tzCode: "Europe/Moscow",
    },
    {
      offset: "+03:00",
      label: "(GMT+03:00) Nairobi",
      tzCode: "Africa/Nairobi",
    },
    { offset: "+03:00", label: "(GMT+03:00) Qatar", tzCode: "Asia/Qatar" },
    { offset: "+03:00", label: "(GMT+03:00) Riyadh", tzCode: "Asia/Riyadh" },
    {
      offset: "+03:00",
      label: "(GMT+03:00) Syowa",
      tzCode: "Antarctica/Syowa",
    },
    { offset: "+03:30", label: "(GMT+03:30) Tehran", tzCode: "Asia/Tehran" },
    { offset: "+04:00", label: "(GMT+04:00) Baku", tzCode: "Asia/Baku" },
    { offset: "+04:00", label: "(GMT+04:00) Dubai", tzCode: "Asia/Dubai" },
    { offset: "+04:00", label: "(GMT+04:00) Mahe", tzCode: "Indian/Mahe" },
    {
      offset: "+04:00",
      label: "(GMT+04:00) Mauritius",
      tzCode: "Indian/Mauritius",
    },
    {
      offset: "+04:00",
      label: "(GMT+04:00) Moscow+01 - Samara",
      tzCode: "Europe/Samara",
    },
    {
      offset: "+04:00",
      label: "(GMT+04:00) Reunion",
      tzCode: "Indian/Reunion",
    },
    { offset: "+04:00", label: "(GMT+04:00) Tbilisi", tzCode: "Asia/Tbilisi" },
    { offset: "+04:00", label: "(GMT+04:00) Yerevan", tzCode: "Asia/Yerevan" },
    { offset: "+04:30", label: "(GMT+04:30) Kabul", tzCode: "Asia/Kabul" },
    { offset: "+05:00", label: "(GMT+05:00) Aqtau", tzCode: "Asia/Aqtau" },
    { offset: "+05:00", label: "(GMT+05:00) Aqtobe", tzCode: "Asia/Aqtobe" },
    {
      offset: "+05:00",
      label: "(GMT+05:00) Ashgabat",
      tzCode: "Asia/Ashgabat",
    },
    {
      offset: "+05:00",
      label: "(GMT+05:00) Dushanbe",
      tzCode: "Asia/Dushanbe",
    },
    { offset: "+05:00", label: "(GMT+05:00) Karachi", tzCode: "Asia/Karachi" },
    {
      offset: "+05:00",
      label: "(GMT+05:00) Kerguelen",
      tzCode: "Indian/Kerguelen",
    },
    {
      offset: "+05:00",
      label: "(GMT+05:00) Maldives",
      tzCode: "Indian/Maldives",
    },
    {
      offset: "+05:00",
      label: "(GMT+05:00) Mawson",
      tzCode: "Antarctica/Mawson",
    },
    {
      offset: "+05:00",
      label: "(GMT+05:00) Moscow+02 - Yekaterinburg",
      tzCode: "Asia/Yekaterinburg",
    },
    {
      offset: "+05:00",
      label: "(GMT+05:00) Tashkent",
      tzCode: "Asia/Tashkent",
    },
    { offset: "+05:30", label: "(GMT+05:30) Colombo", tzCode: "Asia/Colombo" },
    {
      offset: "+05:30",
      label: "(GMT+05:30) India Standard Time",
      tzCode: "Asia/Calcutta",
    },
    {
      offset: "+05:45",
      label: "(GMT+05:45) Kathmandu",
      tzCode: "Asia/Kathmandu",
    },
    { offset: "+06:00", label: "(GMT+06:00) Almaty", tzCode: "Asia/Almaty" },
    { offset: "+06:00", label: "(GMT+06:00) Bishkek", tzCode: "Asia/Bishkek" },
    { offset: "+06:00", label: "(GMT+06:00) Chagos", tzCode: "Indian/Chagos" },
    { offset: "+06:00", label: "(GMT+06:00) Dhaka", tzCode: "Asia/Dhaka" },
    {
      offset: "+06:00",
      label: "(GMT+06:00) Moscow+03 - Omsk",
      tzCode: "Asia/Omsk",
    },
    { offset: "+06:00", label: "(GMT+06:00) Thimphu", tzCode: "Asia/Thimphu" },
    {
      offset: "+06:00",
      label: "(GMT+06:00) Vostok",
      tzCode: "Antarctica/Vostok",
    },
    { offset: "+06:30", label: "(GMT+06:30) Cocos", tzCode: "Indian/Cocos" },
    { offset: "+06:30", label: "(GMT+06:30) Rangoon", tzCode: "Asia/Yangon" },
    { offset: "+07:00", label: "(GMT+07:00) Bangkok", tzCode: "Asia/Bangkok" },
    {
      offset: "+07:00",
      label: "(GMT+07:00) Christmas",
      tzCode: "Indian/Christmas",
    },
    {
      offset: "+07:00",
      label: "(GMT+07:00) Davis",
      tzCode: "Antarctica/Davis",
    },
    { offset: "+07:00", label: "(GMT+07:00) Hanoi", tzCode: "Asia/Saigon" },
    { offset: "+07:00", label: "(GMT+07:00) Hovd", tzCode: "Asia/Hovd" },
    { offset: "+07:00", label: "(GMT+07:00) Jakarta", tzCode: "Asia/Jakarta" },
    {
      offset: "+07:00",
      label: "(GMT+07:00) Moscow+04 - Krasnoyarsk",
      tzCode: "Asia/Krasnoyarsk",
    },
    { offset: "+08:00", label: "(GMT+08:00) Brunei", tzCode: "Asia/Brunei" },
    {
      offset: "+08:00",
      label: "(GMT+08:00) China Time - Beijing",
      tzCode: "Asia/Shanghai",
    },
    {
      offset: "+08:00",
      label: "(GMT+08:00) Choibalsan",
      tzCode: "Asia/Choibalsan",
    },
    {
      offset: "+08:00",
      label: "(GMT+08:00) Hong Kong",
      tzCode: "Asia/Hong_Kong",
    },
    {
      offset: "+08:00",
      label: "(GMT+08:00) Kuala Lumpur",
      tzCode: "Asia/Kuala_Lumpur",
    },
    { offset: "+08:00", label: "(GMT+08:00) Macau", tzCode: "Asia/Macau" },
    {
      offset: "+08:00",
      label: "(GMT+08:00) Makassar",
      tzCode: "Asia/Makassar",
    },
    { offset: "+08:00", label: "(GMT+08:00) Manila", tzCode: "Asia/Manila" },
    {
      offset: "+08:00",
      label: "(GMT+08:00) Moscow+05 - Irkutsk",
      tzCode: "Asia/Irkutsk",
    },
    {
      offset: "+08:00",
      label: "(GMT+08:00) Singapore",
      tzCode: "Asia/Singapore",
    },
    { offset: "+08:00", label: "(GMT+08:00) Taipei", tzCode: "Asia/Taipei" },
    {
      offset: "+08:00",
      label: "(GMT+08:00) Ulaanbaatar",
      tzCode: "Asia/Ulaanbaatar",
    },
    {
      offset: "+08:00",
      label: "(GMT+08:00) Western Time - Perth",
      tzCode: "Australia/Perth",
    },
    {
      offset: "+08:30",
      label: "(GMT+08:30) Pyongyang",
      tzCode: "Asia/Pyongyang",
    },
    { offset: "+09:00", label: "(GMT+09:00) Dili", tzCode: "Asia/Dili" },
    {
      offset: "+09:00",
      label: "(GMT+09:00) Jayapura",
      tzCode: "Asia/Jayapura",
    },
    {
      offset: "+09:00",
      label: "(GMT+09:00) Moscow+06 - Yakutsk",
      tzCode: "Asia/Yakutsk",
    },
    { offset: "+09:00", label: "(GMT+09:00) Palau", tzCode: "Pacific/Palau" },
    { offset: "+09:00", label: "(GMT+09:00) Seoul", tzCode: "Asia/Seoul" },
    { offset: "+09:00", label: "(GMT+09:00) Tokyo", tzCode: "Asia/Tokyo" },
    {
      offset: "+09:30",
      label: "(GMT+09:30) Central Time - Darwin",
      tzCode: "Australia/Darwin",
    },
    {
      offset: "+10:00",
      label: "(GMT+10:00) Dumont D'Urville",
      tzCode: "Antarctica/DumontDUrville",
    },
    {
      offset: "+10:00",
      label: "(GMT+10:00) Eastern Time - Brisbane",
      tzCode: "Australia/Brisbane",
    },
    { offset: "+10:00", label: "(GMT+10:00) Guam", tzCode: "Pacific/Guam" },
    {
      offset: "+10:00",
      label: "(GMT+10:00) Moscow+07 - Vladivostok",
      tzCode: "Asia/Vladivostok",
    },
    {
      offset: "+10:00",
      label: "(GMT+10:00) Port Moresby",
      tzCode: "Pacific/Port_Moresby",
    },
    { offset: "+10:00", label: "(GMT+10:00) Truk", tzCode: "Pacific/Chuuk" },
    {
      offset: "+10:30",
      label: "(GMT+10:30) Central Time - Adelaide",
      tzCode: "Australia/Adelaide",
    },
    {
      offset: "+11:00",
      label: "(GMT+11:00) Casey",
      tzCode: "Antarctica/Casey",
    },
    {
      offset: "+11:00",
      label: "(GMT+11:00) Eastern Time - Hobart",
      tzCode: "Australia/Hobart",
    },
    {
      offset: "+11:00",
      label: "(GMT+11:00) Eastern Time - Melbourne, Sydney",
      tzCode: "Australia/Sydney",
    },
    { offset: "+11:00", label: "(GMT+11:00) Efate", tzCode: "Pacific/Efate" },
    {
      offset: "+11:00",
      label: "(GMT+11:00) Guadalcanal",
      tzCode: "Pacific/Guadalcanal",
    },
    { offset: "+11:00", label: "(GMT+11:00) Kosrae", tzCode: "Pacific/Kosrae" },
    {
      offset: "+11:00",
      label: "(GMT+11:00) Moscow+08 - Magadan",
      tzCode: "Asia/Magadan",
    },
    {
      offset: "+11:00",
      label: "(GMT+11:00) Norfolk",
      tzCode: "Pacific/Norfolk",
    },
    { offset: "+11:00", label: "(GMT+11:00) Noumea", tzCode: "Pacific/Noumea" },
    {
      offset: "+11:00",
      label: "(GMT+11:00) Ponape",
      tzCode: "Pacific/Pohnpei",
    },
    {
      offset: "+12:00",
      label: "(GMT+12:00) Funafuti",
      tzCode: "Pacific/Funafuti",
    },
    {
      offset: "+12:00",
      label: "(GMT+12:00) Kwajalein",
      tzCode: "Pacific/Kwajalein",
    },
    { offset: "+12:00", label: "(GMT+12:00) Majuro", tzCode: "Pacific/Majuro" },
    {
      offset: "+12:00",
      label: "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
      tzCode: "Asia/Kamchatka",
    },
    { offset: "+12:00", label: "(GMT+12:00) Nauru", tzCode: "Pacific/Nauru" },
    { offset: "+12:00", label: "(GMT+12:00) Tarawa", tzCode: "Pacific/Tarawa" },
    { offset: "+12:00", label: "(GMT+12:00) Wake", tzCode: "Pacific/Wake" },
    { offset: "+12:00", label: "(GMT+12:00) Wallis", tzCode: "Pacific/Wallis" },
    {
      offset: "+13:00",
      label: "(GMT+13:00) Auckland",
      tzCode: "Pacific/Auckland",
    },
    {
      offset: "+13:00",
      label: "(GMT+13:00) Enderbury",
      tzCode: "Pacific/Enderbury",
    },
    {
      offset: "+13:00",
      label: "(GMT+13:00) Fakaofo",
      tzCode: "Pacific/Fakaofo",
    },
    { offset: "+13:00", label: "(GMT+13:00) Fiji", tzCode: "Pacific/Fiji" },
    {
      offset: "+13:00",
      label: "(GMT+13:00) Tongatapu",
      tzCode: "Pacific/Tongatapu",
    },
    { offset: "+14:00", label: "(GMT+14:00) Apia", tzCode: "Pacific/Apia" },
    {
      offset: "+14:00",
      label: "(GMT+14:00) Kiritimati",
      tzCode: "Pacific/Kiritimati",
    },
  ];
  static timeSlotsInSignUp = [
    "12:00 AM",
    "1:00 AM",
    "2:00 AM",
    "3:00 AM",
    "4:00 AM",
    "5:00 AM",
    "6:00 AM",
    "7:00 AM",
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  static noOfEmployeeOptions = [
    "0-100",
    "100-250",
    "250-500",
    "500-1000",
    "More than 1000",
  ];
  static mentalHealthTests = [
    "Depression Test",
    "Anxiety Test",
    "Social Anxiety Test",
    "Bipolar Disorder Test",
    "OCD Test",
    "PTSD Test",
  ];
  static frequentSearches = [
    "therapy",
    "online therapy",
    "therapist",
    "online counselling",
    "couples therapy",
    "am I depressed?",
    "free online therapy",
  ];
  static grievance = [
    "Subscription Related",
    "Talk Therapy Related",
    "Program Related",
    "Account Related",
    "Technology Related",
  ];
  static userHelpDesk = [
    "Schedule / Reschedule / Cancel a session",
    "Help With Therapist Availibilty",
    "Chat Issues",
    "Change Therapist",
    "Technical Issues / Mobile App Issues",
    "Help With Prescription",
    "Account Related Issues",
    "Payment Issues",
    "Feedback / Escalation",
    "Request A Refund",
    "Others"
  ];
  static therapistHelpDesk = [
    "Rescheduling",
    "Cancellation",
    "Payout Related",
    "Psychiatrist Consultation"
  ]
  static moment = [
    "In school",
    "Graduation",
    "Post graduation",
    "Employed",
    "Professional",
    "Business",
    "Others",
  ];
  static seekingHelp = [
    "I am looking for general counselling to vent out, manage stress & feel positive.",
    "I want counselling treatment for a specific mental health condition.",
    "I am not sure.",
  ];
  static preferredMediumOptions = ["Video", "Audio", "Chat"];
  static therapyBefore = ["No, it is my first time", "Yes"];
  static therapistgenderpreference = ["Male", "Female", "No preference"];
  static firstsessionAt = ["Right Away", "This Week", "Next Week", "Not Sure"];
  static relatesToChild = ["Mother", "Father", "Guardian"];
}
