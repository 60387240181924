import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from "../../free-report.service";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-work-life-balance-test-results',
  templateUrl: './work-life-balance-test-results.component.html',
  styleUrls: ['./work-life-balance-test-results.component.css']
})
export class WorkLifeBalanceTestResultsComponent implements OnInit {
  report;
  workLifeScore;
  score1;
  score2;
  score3;
  itemId;
  answersArray = []
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res["report"]
        this.workLifeScore = res["report"]["reportDetails"]["score"];
        this.answersArray = Object.values(this.report.reportDetails.answers);
        this.score1 = Number(this.answersArray[0]) + Number(this.answersArray[1]) + Number(this.answersArray[2]) + Number(this.answersArray[3]) + Number(this.answersArray[4]) + Number(this.answersArray[5]) + Number(this.answersArray[6]);

        this.score2 = Number(this.answersArray[7]) + Number(this.answersArray[8]) + Number(this.answersArray[9]) + Number(this.answersArray[10]);

        this.score3 = Number(this.answersArray[11]) + Number(this.answersArray[12]) + Number(this.answersArray[13]) + Number(this.answersArray[14]);
        if (clevertap) {
          clevertap.event.push("Free Report Ended", {
            "Report Name": "Work Life Balance",
            "Score": this.workLifeScore
          })
        }
      });
    });
  }
  reportData() {
    let reqObj = {
      id: this.itemId,
      reportType: 'work-life-balance'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if (res) {
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
