<div class="topMenuMargin" (click)="closenavbar()">
    <div class="container mt-3">
      <p class="disclaimerTxt">
        <strong> <i class="fa fa-exclamation-triangle"></i>  Disclamer: </strong>This tool should be used for screening and
        monitoring symptom severity and cannot replace a clinical assessment and
        diagnosis. You are encouraged to share these results with a mental health
        professional for a better understanding.
      </p>
      <br>
    <div class="report-user mt-2">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
    </div>
    <div class="report-section">
      <div class="img-section">
        <img src="../../../assets/images/meet.svg" />
      </div>
      <div class="report-user">
        <h3>Congratulations on taking that first step!</h3>
        <p class="mt-3"><strong>Your Score:</strong></p>
        <p>Satisfaction with Life Scale: {{ satisfactionScore }}</p>
        <p class="mt-2"><strong>Your Result:</strong></p>
        <p>
            Your responses indicate that you are <span>{{ satisfactionPhrase }} <span class="scrollTxt" (click)="scrollToElem('target')">Detailed Analysis</span> </span>
        </p>
        <br>
      </div>
    </div>
  
    <div class="container graph-section mt-3">
      <div>
        <div>
          <p class="mb-3" *ngIf="satisfactionScore >= 5 && satisfactionScore <= 19">
            Seeing the report can be overwhelming, but Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere, to bring you on the path to having a more fulfilling life.
          </p>
          <p class="mb-3" *ngIf="satisfactionScore == 20">
            Your results lie within the normal range. Read the report to find out more suggestions on how you can improve your lifestyle, and, concurrently, your satisfaction with life.
          </p>
          <p class="mb-3" *ngIf="satisfactionScore >= 21 && satisfactionScore <= 35">
            Your results are not a sign of concern, but read on to find out a more detailed analysis of your score as well as a few suggestions from Felicity that you can always use to improve your lifestyle.

          </p>
        </div>
  
        <h5>Let’s try to explore your satisfaction with life.</h5>
        <div class="graph-right">
          <img src="../../../assets/images/free-report-graphs/satisfied-with-life.png">
        </div>
      </div>
    </div>
  
    <div class="container scores-section mb-4">
      <h5>Your Score Interpretation </h5>
      <div class="about-scores report-user" id="target">
        <p class="mb-3">
            The possible range of scores is 5 to 35, where 5 indicates that the respondent is 'extremely dissatisfied’ and 35 indicates that the respondent is ‘extremely satisfied with life.' 
        </p>
        <p class="para_scores mb-3" *ngIf="satisfactionScore >= 5 && satisfactionScore <= 19">
            From the above graph, it is clear that a score of 5 to 9 means extreme dissatisfaction with life. A score this low tends to be an indication of dissatisfaction across multiple areas of life, and it is recommended that you begin reflecting on which areas of life you might want to improve, to alleviate your overall wellbeing. This score could also be because of some drastic changes in your life recently, such as due to bereavement. You can always get in touch with the mental health professionals at Felicity if you need help or guidance to get you through this period. We assure to provide you with the best help for as long as you require.
        </p>
        <p class="para_scores mb-3" *ngIf="satisfactionScore >= 10 && satisfactionScore <= 14">
            A score of 10 to 14 indicates that you are substantially dissatisfied with your current circumstances. Since this score is on the lower end, it's worth reflecting on your recent events or any situations - which may be temporary - that may have led you to feeling this way. It is best to figure this out sooner than later, so you can learn different strategies to increase your quality of life. You can always get in touch with the mental health professionals at Felicity to help guide you through the process of developing your sense of satisfaction by improving certain areas of your life.
        </p>
        <p class="para_scores mb-3" *ngIf="satisfactionScore >= 15 && satisfactionScore <= 19">
            A score of 15 to 19 indicates that you feel dissatisfied more than satisfied with your current circumstances on a day-to-day basis. There are several significant areas for improvement that may be implied here; or, it could just mean that you are generally content with your life, but there is one domain which you are deeply unsatisfied with, which might explain the low score. If you seek betterment in any area and think you need help for the same, the mental health professionals at Felicity can help you identify the same, with the assurance to provide you with the best help.
        </p>
        <p class="para_scores mb-3" *ngIf="satisfactionScore == 20">
            A score of 20 falls between satisfaction and dissatisfaction - it means that you are likely pretty neutral about your sense of satisfaction with your life. This score could be explained by the assumption that you may not put too much thought into what does or doesn't need improvement in your life, or you may be fairly content with where you are. This could be your sign to reflect on areas that can be improved slightly, if you haven't done that before. 
        </p>
        <p class="para_scores mb-3"  id="target" *ngIf="satisfactionScore >= 21 && satisfactionScore <= 25">
            A score of 21 to 25 indicates that you are slightly satisfied with where you are in life, your current circumstances, relationships, work, finances, or any other domain that you might have considered for your answers. This score suggests that on average, you are satisfied with your day to day routine, but there might be some areas that you may consider improving in.
        </p>
        <p class="para_scores mb-3"  id="target" *ngIf="satisfactionScore >= 26 && satisfactionScore <= 30">
          A score of 26 to 30 lies on the higher side, suggesting that you are substantially satisfied with your life. You understand that your life is not perfect, and respect any challenges and opportunities for change thrown your way - you realise that these are only areas for further growth and exploration.
        </p>
        <p class="para_scores mb-3"  id="target" *ngIf="satisfactionScore >= 31 && satisfactionScore <= 35">
            A score of 31-35 is the highest range that you can achieve on the scale. This means that you have a strong love for your life and feel that things are going really well for you in different aspects of your life. The high score does not suggest that your life is perfect, just that your perception of your current circumstances is one of contentment and satisfaction. It also does not mean that you are complacent about your sense of satisfaction, just that you realise any challenges thrown your way are temporary and can be managed.
        </p>
      </div>
    </div>
  
    <!-- TODO: Add CSS -->
    <div class="container about-scores">
      <div class="">
        <h5 class="mb-3">Detailed Analysis</h5>
        <p class="mb-3">
            The Satisfaction With Life Scale was developed as a measure of the judgmental component of subjective well-being (SWB). SWB consists of two distinct elements: the emotional or affective component, and the judgmental or cognitive component. The SWLS evaluates a person's conscious judgement of their life based on the individual's own criteria, and that is the reason the questions in the assessment cover general life satisfaction, and not particular domains.
        </p>
      </div>
    </div>
  <div class="container">
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
    <!-- <div class="disclaimer-section">
        <div class="head-disclaimer">
          <h3>Disclaimer</h3>
          <p>
            This tool should be used for screening and monitoring symptom severity
            and cannot replace a clinical assessment and diagnosis. You are
            encouraged to share these results with a mental health professional.
            Felicity disclaims any liability, loss or risk incurred as a consequence
            , directly or indirectly, from the use of this assessment & it's
            results.
          </p>
        </div>
      </div> -->
  </div>
  