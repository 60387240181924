import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.css']
})
export class DeleteAccountDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  public onClose: Subject<boolean>;
  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  public decline() {
    this.bsModalRef.hide()
    this.onClose.next(false);
  }

  public accept() {
    this.bsModalRef.hide()
    this.onClose.next(true);
  }

  public close() {
    this.bsModalRef.hide();
    this.onClose.next(null);
  }
}
