<div (click)="closenavbar()">
  <div class="footerWrap">
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="footerCont">
            <img
              src="../../assets/images/logo-white.svg"
              alt="Felicity"
              draggable="false"
              class="img-fluid"
            />
            <ul class="contactInfo">
              <li *ngIf="regionData?.tag === 'IN'">
                <i class="fa fa-map-marker" ></i>
                Sapphire Space, Bani Park, <br />
                Jaipur 302016, India
              </li>
              <li class="phoneCssFix">
                <a href="mailto:hola@felicity.care"
                  ><i class="fa fa-envelope"></i> hola@felicity.care</a
                >
              </li>
              <li class="phoneCssFix" *ngIf="regionData?.tag === 'IN'">
                <a href="tel:+916377327550"
                  ><i class="fa fa-phone"></i> +916377327550</a
                >
              </li>
            </ul>
            <ul class="socialIconCont">
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/felicitycareofficial/"
                  ><i class="fa fa-facebook"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="https://twitter.com/CareFelicity"
                  ><i class="fa fa-twitter-square"></i
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/felicity_care/"
                  ><i class="fa fa-instagram"></i
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC5JO-2aftdLYSUupZm2Ca1A"
                  ><i class="fa fa-youtube"></i
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://in.pinterest.com/felicity_care/"
                  ><i class="fa fa-pinterest"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-6">
          <div class="quickLinkBox">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a routerLink="/corporates"
                  >For Corporates</a
                >
              </li>
              <li>
                <a
                  routerLink="/counseling-therapy-clinical-psychology-jobs/get-onboard"
                  >For Therapists</a
                >
              </li>
              <li>
                <a routerLink="online-counseling-psychologists/contact-us"
                  >Contact</a
                >
              </li>
              <li>
                <a routerLink="mental-health-community/about-the-company"
                  >About us</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-6">
          <div class="quickLinkBox">
            <h3>Legal Stuff</h3>
            <ul>
              <li>
                <a routerLink="/online-counseling-psychologists/disclaimer"
                  >Disclaimer</a
                >
              </li>
              <li>
                <a routerLink="/online-counseling-psychologists/privacy-policy"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a
                  routerLink="/online-counseling-psychologists/terms-of-service"
                  >Terms Of Service</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-8 col-12">
          <div class="callbackBox">
            <form [formGroup]="callbackForm" (ngSubmit)="onSubmit()">
              <h3>We are here to make sure that you are always happy</h3>
              <div class="callbackInput">
                <input
                  type="text"
                  placeholder="Enter your phone number"
                  class="form-control one"
                  formControlName="phone"
                  ng2TelInput
                  (hasError)="checkError($event)"
                  (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }"
                  [ngClass]="{
                    'has-error':
                      !callbackForm.controls.phone.valid &&
                      callbackForm.controls.phone.touched,
                    'has-success':
                      callbackForm.controls.phone.valid &&
                      callbackForm.controls.phone.touched
                  }"
                />
                <a type="submit" class="primaryBtn" (click)="onSubmit()"> Request Callback </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footerWarningBox">
    <div class="container">
      If you are in a life-threatening situation – DO NOT use this site. Use
      these
      <a target="_blank"
      href="https://www.helpguide.org/articles/therapy-medication/directory-of-international-mental-health-helplines.htm"
      >resources</a>
      to get immediate help. <br />
      ©Felicity. All rights reserved.
    </div>
  </div>
</div>
