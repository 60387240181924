import { Component, OnInit } from "@angular/core";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { BootstrapAlertService } from "../../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TherapistHolidaysService } from "../therapist-holidays.service";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import * as moment from "moment";

@Component({
  selector: "app-therapist-holidays",
  templateUrl: "./therapist-holidays.component.html",
  styleUrls: ["./therapist-holidays.component.css"],
})
export class TherapistHolidaysComponent implements OnInit {
  holidaysData;
  bsConfig: Partial<BsDatepickerConfig>;
  holidaysForm: FormGroup;
  holidaysArray = [];
  therapistID;
  isValidDate: any;
  constructor(
    private alertService: BootstrapAlertService,
    private therapistHolidaysService: TherapistHolidaysService,
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService
  ) {}
  ngOnInit(): void {
    this.initForms();
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    this.therapistID = localStorage.getItem("therapistId");
    this.therapistHolidaysService.getHolidays().subscribe((data) => {
      this.holidaysArray = data["holidays"];
    });
  }

  closenavbar(){
    this.sidenavService.closesidenav();
  }

  initForms() {
    this.holidaysForm = new FormGroup({
      startDate: new FormControl("", Validators.required),
      endDate: new FormControl("", Validators.required),
      reason: new FormControl("", Validators.required),
    });
  }
  validateDates() {
    this.isValidDate = true;
    if (
      (
        new Date(this.holidaysForm.controls["startDate"].value) <=
        new Date(this.holidaysForm.controls["endDate"].value)
      )
    ) {
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  formSubmission() {
    if (this.holidaysForm.invalid) {
      return;
    }
    let startDay = moment(this.holidaysForm.value.startDate).startOf("day")["_d"]
    let endDay = moment(this.holidaysForm.value.endDate).endOf("day")["_d"]
    this.holidaysData = {
      therapistId: this.therapistID,
      startDate : startDay,
      endDate : endDay,
      reason : this.holidaysForm.value.reason
    };
    this.isValidDate = this.validateDates();
    if (this.isValidDate) {
      this.alertService.alert(
        new BootstrapAlert(
          "End date should be greater then start date.",
          "alert-danger"
        )
      );
    } else {
      this.confirmationService
        .confirm(
          "Are you sure?",
          "Are you sure you want to Add this holiday?",
          "Yes, Add",
          "No"
        )
        .subscribe((res) => {
          if (res) {
            this.therapistHolidaysService
              .addHolidays(this.holidaysData)
              .subscribe((data) => {
                this.holidaysArray.unshift(data["holiday"]);
                if (data) {
                  this.alertService.alert(
                    new BootstrapAlert(
                      "Holiday is Added Successfully.",
                      "alert-success"
                    )
                  );
                }
              });
          }
        });
    }
  }
  removeObjFromArray(item) {
    this.confirmationService
      .confirm(
        "Are you sure?",
        "Are you sure you want to delete this holiday?",
        "Yes, Delete",
        "No"
      )
      .subscribe((res) => {
        if (res) {
          this.therapistHolidaysService
            .deleteHoliday(item._id)
            .subscribe((data) => {
              if (data) {
                let index = this.holidaysArray.indexOf(item);
                this.holidaysArray.splice(index, 1);
                this.alertService.alert(
                  new BootstrapAlert(
                    "Holiday is deleted Successfully.",
                    "alert-success"
                  )
                );
              }
            });
        }
      });
  }
}
