<div [ngClass]="alertPosition" class="bootstrap-alert col-lg-4">
  <div
    *ngFor="let bootstrapAlert of bootstrapAlerts"
    id="{{ bootstrapAlert.uuid }}"
    class="alert alert-dismissable mb-2"
    [ngClass]="bootstrapAlert.type"
    [@alertVisible]="bootstrapAlert.state"
  >
    {{ bootstrapAlert.message }}
  </div>
</div>
