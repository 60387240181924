<div (click)="closenavbar()">
  <div class="container-fluid" *ngIf="firstPart">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <br />
            <div class="sectionHeading">
              <h1><b>Prolonged Grief Assessment</b></h1>
              <hr />
            </div>
          </div>
          <div class="col-md-3 paddingCss">
            <div class="divTagCss">
              <img class="img-fluid imageCss" src="../../../assets/images/prolonged-grief-report.webp" />
            </div>
          </div>
          <div class="col-md-8 paddingCss">
            <div class="textInside">
              <p>
                Welcome to Felicity's online mental health test. It's free,
                quick, anonymous & 100% confidential.
                <br />
              </p>
              <br>
              <h2 class="htag"><b>Introduction</b></h2>
              <p>
                The International Prolonged Grief Disorder Scale (IPGDS) is a self-report test created by the WHO ICD-11
                Working Group, to diagnose Prolonged Grief Disorder.
                Prolonged Grief Disorder, also known as Complicated grief, was recently added to the list of disorders
                listed in ICD-11. It is characterized by a feeling of longing and excessive preoccupation with the
                deceased and has a very particular set of symptoms.
              </p>
            </div>
            <br />
            <div class="textInside">
              <h2 class="htag"><b>Instructions</b></h2>
              <p>
                In this self-report questionnaire, there are no right or wrong answers. You have to read each question carefully and answer them accurately according to how you feel. Answer the questions keeping in mind to what extent does the statement describe you best. This scale is to be completed by individuals who have experienced the death of a loved one or close person. Using the scale below, please choose the answer that best describes how you have been feeling over the  <strong>past week</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="submitButtonClass text-center">
      <button class="thm-2" (click)="ShowHide()">Next</button>
    </div>
    <br />
  </div>
  <div class="container-fluid" *ngIf="secondPart">
    <form [formGroup]="freeReportForm" name="freeReportForm">
      <ul class="unstyled main-body">
        <div class="question-section">
          <h5>{{ questions[currentIndex].questionText }}</h5>
          <ul class="unstyled options_list" id="question_{{ questions[currentIndex].qno }}">
            <div *ngFor="let answer of answers[currentIndex]; let idx1 = index" class="align-options">
              <li>
                <label class="labelCss">
                  <input type="radio" class="align-input" value="{{ answer.value }}"
                    name="{{ questions[currentIndex].label }}" formControlName="{{ questions[currentIndex].label }}" />
                  &nbsp;<span class="options_listing">{{
                    answer.answerText
                    }}</span>
                </label>
              </li>
            </div>
          </ul>
        </div>
        <div class="form-btn">
          <button *ngIf="currentIndex == 0" type="button" class="btn-back" (click)="instruction1()">
            BACK
          </button>
          <button *ngIf="currentIndex > 0" type="button" class="btn-back" (click)="previousPage()">
            BACK
          </button>
          <button *ngIf="questions.length - 1 != currentIndex" class="btn btn-submit thm-2" (click)="currentIndexInc()">
            Next
          </button>
          <button *ngIf="questions.length - 1 == currentIndex" class="btn btn-submit" type="submit" data-toggle="modal"
            data-target="#myModal" (click)="showModal()" [disabled]="!freeReportForm?.valid">
            Submit
          </button>
          <div class="container-progress">
            <div [hidden]="!(progressPercentage > 0)" class="progressBarCls"
              [ngStyle]="{ width: progressPercentage + '%' }"></div>
          </div>
        </div>
      </ul>
    </form>
  </div>
  <div class="modal fade" id="myModal" *ngIf="!hideModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Free Report</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <p>
            Great! You're almost done. Please enter these details to generate
            report.
          </p>
          <form [formGroup]="userForm2" name="userForm2">
            <input id="name" formControlName="fullname" name="fullname" placeholder="Name" [ngClass]="{
                  'has-error':
                    !userForm2.controls.fullname.valid &&
                    userForm2.controls.fullname.touched,
                  'has-success':
                    userForm2.controls.fullname.valid &&
                    userForm2.controls.fullname.touched
                }" />
            <input type="email" id="email" formControlName="email" name="email" placeholder="xyz@gmail.com" [ngClass]="{
                  'has-error':
                    !userForm2.controls.email.valid &&
                    userForm2.controls.email.touched,
                  'has-success':
                    userForm2.controls.email.valid &&
                    userForm2.controls.email.touched
                }" />
            <div class="phoneHolder">
              <input type="tel" id="phone" formControlName="phone" name="phone" class="form-control phoneControl"
                [ngClass]="{
                    'has-error':
                      userForm2.controls.phone.errors?.invalidPhone ||
                      (userForm2.controls.phone.errors?.required &&
                        userForm2.controls.phone.touched)
                  }" ng2TelInput (hasError)="checkError($event)" (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }" />
            </div>
            <div class="errorContainer" *ngIf="userForm2.controls.phone.errors?.invalidPhone">
              <p>Please enter a valid phone number.</p>
            </div>
          </form>
          <div class="modal-footer">
            <button type="submit" class="btn-submit" value="Submit" (click)="onSubmit()" data-dismiss="modal"
              [disabled]="!userForm2?.valid">
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>