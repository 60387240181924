<div class="bodyPageWrap">
  <div style="display: flex;align-items: center;margin-bottom: 20px;">
    <span ><a routerLink="../helpdesk" style="font-size:16px; color: #07006e; cursor: pointer;text-decoration: underline;">Helpdesk</a></span>
    <span style="font-size:16px">&nbsp;>&nbsp;</span>
    <span style="font-size:16px; color: #808080;">Account Related FAQ's</span> 
  </div>
  <div>
    <div class="frequentHeadWrap">
      <div class="row">
        <div class="col-md-12">
          <div class="sectionHeading">
            <h2>Account Related FAQ's</h2>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="frequentQuestionBox">
      <div class="row">
        <div id="1" class="col-lg-12 col-md-6 col-sm-6">
          <div class="questionWrap">
            <h4>How can I create and manage my profile?</h4>
            <p class="ptagCss">
              A degree in counselling psychology or a related discipline is a
              must. You should have some practice experience even if it’s under
              training. At least 2 references are mandatory. In the interview
              round you are assessed on empathy, communication skills,
              relationship building and other relevant competencies. You are
              required to get a minimum rating of 4 in the demo round.
            </p>
          </div>
        </div>
        <div id="2" class="col-lg-12 col-md-6 col-sm-6">
          <div class="questionWrap">
            <h4>How can I delete my felicity account?</h4>
            <p class="ptagCss">
                You have the option to delete the account on your dashboard.
                Please note that deletion will permanently remove your profile
                and in case you want to re-join in future you will have design
                it from scratch.
            </p>
          </div>
        </div>
        <div id="3" class="col-lg-12 col-md-6 col-sm-6">
          <div class="questionWrap">
            <h4>How can I deactivate my felicity account?</h4>
            <p class="ptagCss">
                In case you want to temporarily get off the platform due to some
                reason, deactivation should be your choice. You have the option
                to deactivate the account on your dashboard. Please note that
                once deactivated, you will not be assigned clients unless you
                activate your profile again.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="frequentHeadWrap">
      <div class="row">
        <div class="col-md-12">
          <div>
            <h3>
              Have More Questions?
              <span class="spanCss">(Access following heads)</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="frequentQuestionBox">
      <div class="row">
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../contract-related-faq">Contract Related</a></b>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../talk-therapy-faq">Talk therapy Related</a></b>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../payment-related-faq">Payment Related</a>
            </b>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../platform-related-faq">Platform Related</a></b>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../benefits-related-faq">Benefits Related</a></b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
