<div style="display: flex; align-items: center; margin-bottom: 20px">
  <span
    ><a
      routerLink="../discussion"
      style="
        font-size: 16px;
        color: #07006e;
        cursor: pointer;
        text-decoration: underline;
      "
      >Discussion</a
    ></span
  >
  <span style="font-size: 16px">&nbsp;>&nbsp;</span>
  <span
    style="font-size: 16px; color: #808080"
    *ngIf="discussion?.therapistId?.firstname"
    >{{ discussion?.therapistId?.firstname }} Discussion Forum</span
  >
  <span
    style="font-size: 16px; color: #808080"
    *ngIf="!discussion?.therapistId?.firstname"
    >{{ discussion?.userId?.name }} Discussions Forum</span
  >
</div>
<div *ngIf="isLoaded">
  <div class="discussionGroup d-flex align-items-center flex-wrap">
    <div class="discussionImage" *ngIf="discussion?.userId">
      <img
        *ngIf="discussion?.userId?.imageUrl"
        src="{{ discussion?.userId?.imageUrl }}"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="discussionImage" *ngIf="discussion?.therapistId">
      <img
        src="{{ discussion?.therapistId?.imageUrl }}"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="disscussionContentWrap">
      <h3>{{ discussion.topic }}</h3>
      <p [innerHTML]="discussion?.description"></p>
      <div class="disscussionMessage d-flex flex-wrap justify-content-between">
        <div class="info" *ngIf="discussion?.userId">
          Posted By
          <span class="forumtext">{{ discussion?.userId?.name }}</span>
        </div>
        <div class="info" *ngIf="discussion?.therapistId">
          Posted By
          <span class="forumtext">{{
            discussion?.therapistId?.firstname
          }}</span>
        </div>
        <div class="extraCsscolmd">
          <span
            (click)="toggleLike(discussion._id)"
            class="like-btn"
            [ngClass]="{ 'like-active': discussion?.isLiked }"
          ></span>
          <span class="heartText"
            >{{ discussion?.likes }} Like<span *ngIf="discussion?.likes > 1"
              >s</span
            ></span
          >
        </div>
        <div>
          {{ discussion.replies }}
          Replies
        </div>
        <div>
          {{ discussion.createdAt | dateAgo }}
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="discussionGroup d-flex align-items-center flex-wrap"
  *ngFor="let i of comments"
>
  <div class="discussionImage" *ngIf="i['userId']">
    <!-- <img src="/images/{{i.userId.image}}" class="img-fluid" alt=""/> -->
    <img src="../../../assets/images/user.png" class="img-fluid" alt="" />
  </div>
  <div class="discussionImage" *ngIf="i['therapistId']">
    <!-- <img src="/images/{{i.therapistId.image}}" class="img-fluid" alt=""/> -->
    <img src="../../../assets/images/user.png" class="img-fluid" alt="" />
  </div>
  <div class="discussionImage" *ngIf="i['adminId']">
    <img src="/images/user.png" class="img-fluid" alt="" />
  </div>
  <div class="disscussionContentWrap">
    <p>{{ i.commentDescription }}</p>
    <div class="disscussionMessage d-flex flex-wrap justify-content-between">
      <div class="info" *ngIf="i.userId">
        Replied By
        <span class="forumtext">{{ i.userId.name }}</span>
      </div>
      <div class="info" *ngIf="i['therapistId']">
        Replied By
        <span class="forumtext">{{ i.therapistId.firstname }}</span>
      </div>
      <div class="extraCsscolmd">
        <span
          (click)="toggleLikeOnComment(i?._id)"
          class="like-btn"
          [ngClass]="{ 'like-active': i?.isLiked }"
        ></span>
        <span class="heartText"
          >{{ i?.likes }} Like<span *ngIf="i?.likes">s</span></span
        >
      </div>
      <div>
        {{ i.createdAt | dateAgo }}
      </div>
    </div>
  </div>
</div>
<div class="formRound divTagExtra">
  <div>
    <form>
      <textarea
        class="form-control discussionGroup textareaCss"
        [formControl]="comment"
        rows="5"
        #TextValue
        (keyup)="activeButton(TextValue.value)"
        placeholder="Participate in the discussion. Write Comments Here"
      ></textarea>
    </form>
  </div>
  <div *ngIf="isLoading" class="d-flex justify-content-center">
    <div
      class="spinner-border"
      style="width: 2rem; height: 2rem; margin-top: 23px"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
  </div>
  <div [style.display]="activeBtn ? 'initial' : 'none'">
    <button class="thm-2" (click)="addComment()" *ngIf="!isLoading">
      Post Anonymously
    </button>
  </div>
  <div [style.display]="activeBtn ? 'none' : 'initial'">
    <button
      class="thm-2"
      style="filter: grayscale(100%); cursor: not-allowed !important"
    >
      Post Anonymously
    </button>
  </div>
</div>
