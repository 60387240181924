<div class="container">
    <div class="row">
        <div class="infoBox">
            <div class="notFoundBox" *ngIf="!isLoading && !isFound">
                <h2>Ooopss, We could not find the url that you are looking for!</h2>
            </div>
            <div *ngIf="isLoading" class="notFoundBox d-flex justify-content-center">
                <!-- <div class="spinner-border" style="width: 2rem; height: 2rem" role="status">
                  <span class="sr-only">Loading...</span>
                </div> -->
                <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
                
              </div>
        </div>
    </div>
</div>