import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { FreeReportService } from "../../free-report.service";
import clevertap from "clevertap-web-sdk";
import { ViewportScroller } from "@angular/common";


@Component({
  selector: 'app-eating-disorders-test-results',
  templateUrl: './eating-disorders-test-results.component.html',
  styleUrls: ['./eating-disorders-test-results.component.css']
})
export class EatingDisordersTestResultsComponent implements OnInit {
  eatingDisordersPhrase = "";
  eatingDisordersScore;
  itemId;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private actRoute: ActivatedRoute,
    private freeReportService: FreeReportService,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.eatingDisordersScore = res["report"]["reportDetails"]["score"];
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "Eating Disorders",
            "Score" : this.eatingDisordersScore
          })
        }
      });
    });
    this.eatingDisordersPhraseCal();
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'eating-disorders'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  bookConsultation() {
  }
  eatingDisordersPhraseCal() {
    if (this.eatingDisordersScore >= 0 && this.eatingDisordersScore <= 1) {
      this.eatingDisordersPhrase = "Your score indicates a healthy relationship with food.";
    } else {
      this.eatingDisordersPhrase = "Your score could be a sign of concern. We suggest you contact a mental health professional.";
    } 
  }
  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
