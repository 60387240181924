import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const discussionUrl = environment.apiUrl + '/guest/discussion';
const discussionCommentUrl = environment.apiUrl + '/guest/discussion-comment';
@Injectable({
  providedIn: 'root'
})
export class DiscussionForumService {

  constructor(private http : HttpClient) { }

  getCategories(){
    return this.http.get(discussionUrl + '/categories')
  }

  getForums(paginObject){
    return this.http.post(discussionUrl + '/get-all', paginObject)
  }

  getForumById(id){
    return this.http.get(discussionUrl + '/get/' + id)
  }

  getDiscussionComments(id){
    return this.http.get(discussionCommentUrl + '/user-discussion-comment/get-comments/' + id + '?limit=10&skip=0')
  }
}
