import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from '../../free-report.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-caregiver-stress-test-results',
  templateUrl: './caregiver-stress-test-results.component.html',
  styleUrls: ['./caregiver-stress-test-results.component.css']
})
export class CaregiverStressTestResultsComponent implements OnInit {
  burnoutScore = 0;
  burnoutPhrase = "";
  itemId;
  
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  answersArray: any[]
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.burnoutScore = res["report"]["reportDetails"]["score"];
        let report = res['report'];
        this.answersArray = Object.values(report.reportDetails.answers)
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "Caregiver Stress",
            "Score" : this.burnoutScore
          })
        }
        this.burnoutPhrasecal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'caregiver-stress'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }

  burnoutPhrasecal() {
    if (this.burnoutScore >= 0 && this.burnoutScore <= 20) {
      this.burnoutPhrase = "little to no burnout experience.";
    } else if (this.burnoutScore >= 21 && this.burnoutScore <= 40) {
      this.burnoutPhrase = "a mild burnout experience.";
    } else if (this.burnoutScore >= 41 && this.burnoutScore <= 60) {
      this.burnoutPhrase = "a moderately high level of burnout.";
    }
    else if (this.burnoutScore >= 61) {
      this.burnoutPhrase = "a high level of burnout.";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
