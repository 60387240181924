import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Editor } from "ngx-editor";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistDiscussionFourmService } from "../therapist-discussion-fourm.service";

@Component({
  selector: "app-therapist-start-discussion-forum",
  templateUrl: "./therapist-start-discussion-forum.component.html",
  styleUrls: ["./therapist-start-discussion-forum.component.css"],
})
export class TherapistStartDiscussionForumComponent
  implements OnInit, OnDestroy
{
  editor: Editor;
  discussionId;
  mode: string;
  constructor(
    private therapistDiscussionService: TherapistDiscussionFourmService,
    private confirmationService: ConfirmationDialogService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) {}
  discussionForm = new FormGroup({
    topic: new FormControl("", Validators.required),
    tags: new FormControl("", Validators.required),
    description: new FormControl("", Validators.required),
    isAnonymous: new FormControl(false),
  });
  categoryitems = [];
  ngOnInit(): void {
    this.editor = new Editor();
    this.therapistDiscussionService.getCategories().subscribe((data) => {
      this.categoryitems = data["category"];
    });
    this.actRoute.queryParamMap.subscribe((params) => {
      this.discussionId = params.get("id");
      if (this.discussionId != null) {
        this.therapistDiscussionService
          .getForumById(this.discussionId)
          .subscribe((data) => {
            this.discussionForm.setValue({
              topic: data["topic"],
              tags: data["tags"],
              description: data["description"],
              isAnonymous: data["isAnonymous"],
            });
          });
      }
    });
    this.actRoute.queryParams.subscribe((params) => {
      this.mode = params["mode"];
    });
  }

  closenavbar() {
    this.sidenavService.closesidenav()
  }

  getFormControls() {
    return this.discussionForm.controls;
  }

  onSubmit() {
    if (this.discussionForm.invalid) {
      return;
    }
    if (this.mode == "edit") {
      this.confirmationService
        .confirm(
          "Are you sure?",
          "This will update forum post",
          "Continue",
          "Cancel"
        )
        .subscribe((res) => {
          if (res) {
            this.therapistDiscussionService
              .updateDiscussionForumById(
                this.discussionId,
                this.discussionForm.value
              )
              .subscribe((data) => {
                if (data) {
                  this.alertService.alert(
                    new BootstrapAlert(
                      "Discussion was updated successfully",
                      "alert-success"
                    )
                  );
                }
              });
            this.router.navigateByUrl("therapist-dashboard/discussion");
          }
        });
    } else {
      this.confirmationService
        .confirm(
          "Are you sure?",
          "This will create forum post",
          "Continue",
          "Cancel"
        )
        .subscribe((res) => {
          if (res) {
            this.therapistDiscussionService
              .createDiscussion(this.discussionForm.value)
              .subscribe((res) => {
                if (res) {
                  this.alertService.alert(
                    new BootstrapAlert(
                      "Discussion was updated successfully",
                      "alert-success"
                    )
                  );
                }
              });
            this.router.navigateByUrl("therapist-dashboard/discussion");
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
