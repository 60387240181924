import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const therapistHolidaysUrl = environment.apiUrl + "/therapist/holiday";

@Injectable({
  providedIn: "root",
})
export class TherapistHolidaysService {
  constructor(private http: HttpClient) {}

  addHolidays(holiday) {
    return this.http.post(therapistHolidaysUrl, holiday);
  }
  getHolidays() {
    return this.http.get(therapistHolidaysUrl);
  }
  getHolidaysByID(id) {
    return this.http.get(therapistHolidaysUrl + id);
  }
  deleteHoliday(id) {
    return this.http.delete(therapistHolidaysUrl + "/" + id);
  }
  updateHoliday(id, data) {
    return this.http.put(therapistHolidaysUrl + id, data);
  }
}
