import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { QuoteService } from "src/app/shared/quote.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { environment } from "src/environments/environment";
import { BookingService } from "../booking.service";
import { ProfileService } from "../profile.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  userData;
  variable = 0;
  quote = "";
  Tawk_API: any;
  therapistId = null;
  upcomingSession = null;
  upcomingSessionTimeString = "";
  scheduledSessions = 0;
  imageUrl = environment.apiUrl;
  activePlan = null;
  userTimezone = "Asia/Calcutta";
  showFillIntake = false;
  isLoading = true;
  isShimmerLoading = true;
  profileShimmer = true;
  getQuotes = true;
  upcomingSessionShimmer = true;
  scheduleShimmer = true;
  inTakeShimmer = true;
  constructor(
    private profileService: ProfileService,
    private quoteService: QuoteService,
    private router: Router,
    private confirmationService: ConfirmationDialogService,
    private bookingService: BookingService,
    private sidenavService: SideNavBarService
  ) { }
  redirectTo(id) {
    this.router.navigateByUrl("user-dashboard/join-session?sessionId=" + id);
  } redirectToV2(id) {
    this.router.navigateByUrl("user-dashboard/session-join-user?sessionId=" + id);
  }
  redirectToV3(id) {
    this.router.navigateByUrl("user-dashboard/session-join-call-user?sessionId=" + id);
  }
  plansPricing(therapist_id) {
    // this.router.navigateByUrl("plans-and-pricing?counselor=" + therapist_id);
    this.confirmationService
      .confirm(
        "",
        "You do not have available sessions in your account. If you want to reschedule, please cancel the upcoming session & try again. If you want to schedule another session, please renew your plan.",
        "Buy Now",
        "Close"
      )
      .subscribe((res) => {
        if (res) {
          this.router.navigateByUrl(
            "plans-and-pricing?counselor=" + therapist_id
          );
        }
      }, (err) => {
        this.isLoading = false;
        this.isShimmerLoading = false;
        this.profileShimmer = false;
        this.getQuotes = false;
        this.upcomingSessionShimmer = false;
        this.scheduleShimmer = false;
        this.inTakeShimmer = false;
      });
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.isShimmerLoading = true;
    this.profileShimmer = true;
    this.getQuotes = true;
    this.upcomingSessionShimmer = true;
    this.scheduleShimmer = true;
    this.inTakeShimmer = true;
    this.sidenavService.hideWhatsappChat();
    this.profileService.getProfileWithTherapist().subscribe((data) => {
      this.userData = data;
      this.getActivePlan(this.userData);
      if (this.userData.therapistId) {
        this.therapistId = this.userData.therapistId;
        if (
          this.userData.therapistId.jobqualification == "Other (Please Mention)"
        ) {
          this.userData.therapistId.jobqualification =
            this.userData.therapistId.jobqualificationOtherOptionAnswer;
        }
      }
      this.profileShimmer = false;
      this.isShimmerLoading = false;

    }, (error) => {
      this.profileShimmer = false;
      this.isShimmerLoading = false;
    });
    this.quoteService.getQuote("user").subscribe((data) => {
      this.quote = data["quote"];
      this.getQuotes = false;
    }, (err) => {
      this.isLoading = false;
      this.isShimmerLoading = false;
      this.profileShimmer = false;
      this.getQuotes = false;
      this.upcomingSessionShimmer = false;
      this.scheduleShimmer = false;
      this.inTakeShimmer = false;
    });
    this.profileService.getUpcomingSession().subscribe((data) => {
      if (data) {
        this.upcomingSession = data;
        this.formateDate(data["startDate"]);
      }
      this.upcomingSessionShimmer = false;
    }, (err) => {
      this.isLoading = false;
      this.isShimmerLoading = false;
      this.profileShimmer = false;
      this.getQuotes = false;
      this.upcomingSessionShimmer = false;
      this.scheduleShimmer = false;
      this.inTakeShimmer = false;
    });
    this.profileService.getScheduledSessions().subscribe((data) => {
      this.scheduledSessions = data["count"];
      this.scheduleShimmer = false;
    }, (err) => {
      this.isLoading = false;
      this.isShimmerLoading = false;
      this.profileShimmer = false;
      this.getQuotes = false;
      this.upcomingSessionShimmer = false;
      this.scheduleShimmer = false;
      this.inTakeShimmer = false;
    });
    this.profileService.getIntakeForm().subscribe((data) => {
      this.showFillIntake = !data["intakeFilled"]
      this.inTakeShimmer = false;
    }, (err) => {
      this.isLoading = false;
      this.isShimmerLoading = false;
      this.profileShimmer = false;
      this.getQuotes = false;
      this.upcomingSessionShimmer = false;
      this.scheduleShimmer = false;
      this.inTakeShimmer = false;
    })
    if (localStorage.getItem("userTzcode")) {
      this.userTimezone = localStorage.getItem("userTzcode");

    }
    // this.isShimmerLoading = false;
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  getActivePlan(userData) {
    let activePlansArray = [];
    if (userData.plans) {
      activePlansArray = userData.plans.filter(
        (plan) => plan.sessions > 0 || plan.chatDaysAvailable > 0
      );
    }
    if (activePlansArray.length > 0) {
      this.activePlan = activePlansArray[activePlansArray.length - 1];
    }
  }

  openChat() {
    window["$zoho"].salesiq.floatbutton.visible("show");
    let chatElem = document.getElementById("zsiq_agtpic");
    chatElem.click();
  }
  scheduleSession() {
    if (this.userData.therapistId) {
      this.router.navigateByUrl(
        "view-counsellor-calendar/" + this.userData.therapistId._id
      );
    } else {
      this.router.navigateByUrl("our-counselors");
    }
  }
  formateDate(startDate) {
    let startDateObj = momentTz.tz(startDate, this.userTimezone);
    let startString = moment(startDateObj).format("hh A");
    startDateObj.add(45, "minutes");
    let endString = moment(startDateObj).format("hh:mm A");
    this.upcomingSessionTimeString = startString + " to " + endString;
  }

  cancelSession() {
    let currentTime = moment.utc()
    let startDateTime = moment(this.upcomingSession.startDate)
    var duration = moment.duration(startDateTime.diff(currentTime));
    var hours = duration.asHours();
    if (hours < 6) {
      this.confirmationService.confirm("", "As less than 6 hours remain for your session to start, you are not allowed to cancel the session. Please contact the helpdesk for more information.", "Helpdesk", "Close").subscribe((res) => {
        if (res) {
          this.router.navigateByUrl("user-dashboard/helpdesk")
        }
      })

    } else {
      this.confirmationService
        .confirm(
          "Are you sure you want to cancel?",
          "This sesssion will be cancelled!",
          "Yes",
          "No"
        )
        .subscribe((res) => {
          if (res) {
            this.bookingService
              .cancelSession(this.upcomingSession._id)
              .subscribe((res) => {
                window.location.reload();
              });

          } else {
            this.isLoading = false;
            this.isShimmerLoading = false;
            this.profileShimmer = false;
            this.getQuotes = false;
            this.upcomingSessionShimmer = false;
            this.scheduleShimmer = false;
            this.inTakeShimmer = false;
          }
        }, (err) => {
          this.isLoading = false;
          this.isShimmerLoading = false;
          this.profileShimmer = false;
          this.getQuotes = false;
          this.upcomingSessionShimmer = false;
          this.scheduleShimmer = false;
          this.inTakeShimmer = false;
        });
    }
  }
}
