import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const therapistUrl = environment.apiUrl + "/guest/new-therapist/";

@Injectable({
  providedIn: 'root'
})
export class TherapistSignupService {

  constructor(private http : HttpClient) { }

  signUp(data){
    return this.http.post(therapistUrl + "sign-up", data)
  }

  uploadResume(data){
    return this.http.post(therapistUrl + "resume-upload", data)
  }
}
