<div (click)="closenavbar()">
  <div class="container-fluid">
    <div class="row">
      <div class="contentBoxBlock">
        <div *ngIf="profileShimmer; else headingLoader">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '50px',
              width: '50%',
              'margin-top': '3px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>

        <ng-template #headingLoader>
          <h3>
            Welcome to Felicity,
            {{ userData?.name }}
          </h3></ng-template
        >
        <div *ngIf="getQuotes; else subHeadingLoader">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '30px',
              width: '75%',
              'margin-top': '3px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>

        <ng-template #subHeadingLoader>
          <p>
            {{ quote }}
          </p></ng-template
        >
      </div>
    </div>
  </div>
  <br />
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div *ngIf="isShimmerLoading; else imageLoader">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '350px',
              'margin-top': '3px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>

        <ng-template #imageLoader>
          <img
            src="../../../assets/images/dashboard/Main Illustration.svg"
            draggable="false"
            class="img-fluid imageCss extraimage"
          />
        </ng-template>
      </div>
      <div class="col-lg-6">
        <div *ngIf="upcomingSessionShimmer; else sessionLoader">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '200px',
              'margin-top': '75px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <ng-template #sessionLoader>
          <div *ngIf="!upcomingSession" class="exploreBoxWrap boxCss">
            <div class="exploreRightContent contentCss">
              <p>
                You have
                {{ variable }}
                upcoming sessions
              </p>
            </div>
            <div class="exploreImageBox">
              <a
                *ngIf="!userData?.plans"
                routerLink="../../online-counseling-psychologists/plans-pricing"
                class="thm-2 ScheduleCss"
                >Schedule Now</a
              >
              <a
                *ngIf="
                  userData?.plans &&
                  userData?.bookingAvailable > 0 &&
                  therapistId
                "
                routerLink="../../view-counsellor-calendar/{{
                  therapistId._id
                }}"
                class="thm-2 ScheduleCss"
                >Schedule Now</a
              >
              <a
                *ngIf="
                  userData?.plans &&
                  userData?.bookingAvailable > 0 &&
                  !therapistId
                "
                routerLink="../../our-counselors"
                class="thm-2 ScheduleCss"
                >Schedule Now</a
              >
            </div>
          </div>

          <div *ngIf="upcomingSession" class="exploreBoxWrap boxCss">
            <div class="planTextHolder myCounselor upComingText">
              <h5>Upcoming Session</h5>
              <p>
                {{ upcomingSession?.therapistId?.firstname }}
                {{ upcomingSession?.therapistId?.lastname }}
              </p>
              <p>
                Time : {{ upcomingSessionTimeString }}
                {{ upcomingSession?.startDate | date: "longDate" }}
              </p>
            </div>
            <div>
              <div class="planImageHolder">
                <img
                  src="{{ imageUrl }}/{{
                    upcomingSession?.therapistId?.imageUrl
                  }}"
                  onerror="this.src='../../../../assets/images/user.png';"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <div class="planButtonHolder upComingCss">
                <button
                  (click)="redirectToV3(upcomingSession._id)"
                  class="thm-2 extraThm"
                >
                  Join Session
                </button>
              </div>
              <div class="cancelSessionTag">
                <a (click)="cancelSession()"><p>Cancel Session</p></a>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="missingIntake" *ngIf="showFillIntake">
    <div *ngIf="inTakeShimmer; else missingIntakeShimmer">
      <ngx-skeleton-loader
        appearance="line"
        [theme]="{
          'border-radius': '5px',
          height: '40px',
          'margin-top': '75px',
          'margin-right': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <ng-template #missingIntakeShimmer>
      <h3>
        Hey!🤗 Fill this therapy intake form 📝 for an effective session.
        <a routerLink="../therapy-intake-form">Click here</a>
      </h3>
    </ng-template>
  </div>

  <div class="sectionPadding">
    <div class="row justify-content-between">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="isShimmerLoading; else myPlansLoader">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '20px',
              width: '18%',
              'margin-top': '15px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <ng-template #myPlansLoader>
          <h3 class="h3Tag">
            <b>MY PLANS</b>
          </h3>
        </ng-template>
        <!-- <div *ngIf="!userData?.plan" class="exploreBoxWrap1">
          <div class="exploreRightContent">
            <h3 class="h3Tag Colour">
              <b>NO ACTIVE PLAN</b>
            </h3>
            <br />
            <div class="sectionHeading1">
              <a routerLink="../../plans-and-pricing" class="thm-2 classThm"
                >Buy Now</a
              >
            </div>
          </div>
        </div> -->
        <div *ngIf="profileShimmer; else PlansLoader">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '200px',
              'margin-top': '15px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <ng-template #PlansLoader>
          <div *ngIf="activePlan === null" class="exploreBoxWrap1">
            <div class="exploreRightContent">
              <h3 class="h3Tag Colour">
                <b>NO ACTIVE PLAN</b>
              </h3>
              <br />
              <div class="noActivePlanButtonsHolder">
                <button
                  routerLink="../../online-counseling-psychologists/plans-pricing"
                  class="thm-2 extraThm"
                >
                  Buy Now
                </button>
                <button routerLink="../view-old-plans" class="thm-2 classThm">
                  View Old Plans
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="activePlan !== null" class="planBox">
            <div class="planImageHolder">
              <img
                src="../../../assets/images/plans-pricing/explore-plan-1.svg"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="planTextHolder">
              <h5>{{ activePlan?.name }}</h5>
              <p>
                {{ activePlan?.sessions }} Session<span
                  *ngIf="activePlan?.sessions > 1"
                  >s</span
                >
                Available
              </p>
              <p>
                {{ scheduledSessions }} Session<span
                  *ngIf="scheduledSessions > 1"
                  >s</span
                >
                Scheduled
              </p>
              <p>
                {{ activePlan?.chatDaysAvailable }} Day<span
                  *ngIf="scheduledSessions > 1"
                  >s</span
                >
                of chat access left
              </p>
            </div>
            <div class="planButtonHolder">
              <button
                routerLink="../../online-counseling-psychologists/plans-pricing"
                class="thm-2 classThm"
              >
                Buy More
              </button>
              <button routerLink="../view-old-plans" class="thm-2 extraThm">
                View Old Plans
              </button>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="col-md-6 col-sm-6">
        <div *ngIf="isShimmerLoading; else councelorShimmer">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '20px',
              width: '18%',
              'margin-top': '15px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <ng-template #councelorShimmer>
          <h3 class="h3Tag">
            <b>MY COUNSELOR</b>
          </h3>
        </ng-template>
        <div *ngIf="profileShimmer; else boxTwo">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '200px',
              'margin-top': '15px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <ng-template #boxTwo>
          <div *ngIf="!therapistId" class="exploreBoxWrap1">
            <div class="exploreRightContent">
              <h3 class="h3Tag Colour">
                <b>NO ACTIVE COUNSELOR</b>
              </h3>
              <br />
              <div class="sectionHeading1">
                <a routerLink="../../our-counselors" class="thm-2 classThm"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
          <div *ngIf="therapistId" class="planBox" style="width: 100%">
            <div class="planImageHolder">
              <img
                src="{{ imageUrl }}/{{ therapistId?.imageUrl }}"
                alt=""
                onerror="this.src='../../../../assets/images/user.png';"
                class="img-fluid"
              />
            </div>
            <div class="planTextHolder myCounselor">
              <h5>{{ therapistId.firstname }} {{ therapistId.lastname }}</h5>
              <p>{{ therapistId.jobqualification }}</p>
              <a routerLink="../../our-counselors"><p>Change Therapist</p></a>
            </div>
            <div class="planButtonHolder">
              <button
                routerLink="../../view-counsellor-calendar/{{
                  therapistId._id
                }}"
                *ngIf="activePlan?.sessions > 0"
                class="thm-2 extraThm"
              >
                View Calendar
              </button>
              <button
                (click)="plansPricing(therapistId._id)"
                *ngIf="activePlan?.sessions == 0"
                class="thm-2 extraThm"
              >
                Book Again
              </button>
              <button
                routerLink="../../community-talk-therapists-detail/{{
                  therapistId._id
                }}"
                class="thm-2 classThm"
              >
                Go To Profile
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div>
    <div class="sectionHeading">
      <div *ngIf="isShimmerLoading; else needHelp">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '5px',
            height: '50px',
            width: '18%',
            'margin-top': '15px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <ng-template #needHelp>
        <h4 class="h4Tag">
          <b>Need Help?</b>
        </h4>
      </ng-template>
      <div *ngIf="isShimmerLoading; else needHelpBx">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '50px',
            height: '50px',
            width: '18%',
            'margin-top': '15px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <ng-template #needHelpBx>
        <a (click)="openChat()" class="thm-2 extraThm"
          >Talk to a matching expert</a
        >
      </ng-template>
    </div>
    <br />
    <div class="sectionHeading">
      <div *ngIf="isShimmerLoading; else exploreShimmer">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'border-radius': '5px',
            height: '25px',
            width: '18%',
            'margin-top': '15px',
            'margin-right': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <ng-template #exploreShimmer>
        <h3 class="h3Tag">
          <b>EXPLORE</b>
        </h3>
      </ng-template>
      <hr />
    </div>
    <div>
      <div class="row justify-content-between">
        <div class="col-md-6 col-sm-6 extraPadding">
          <div *ngIf="isShimmerLoading; else BxOne">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '5px',
                height: '138px',
                width: '100%',
                'margin-top': '15px',
                'margin-right': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <ng-template #BxOne>
            <div class="exploreBoxWrap wrapcss">
              <div class="exploreRightContent styleCss">
                Do you have follow up questions after your session? Don't worry,
                chat with your Therapist.
              </div>
              <div class="exploreImageBox">
                <img
                  src="../../../assets/images/dashboard/chat icon.svg"
                  draggable="false"
                  class="img-fluid imageCss"
                />
                <a routerLink="../my-chats" class="thm-2 heightCss"
                  >Start Chatting</a
                >
              </div>
            </div></ng-template
          >
        </div>
        <div class="col-md-6 col-sm-6 extraPadding">
          <div *ngIf="isShimmerLoading; else BxTwo">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '5px',
                height: '138px',
                width: '100%',
                'margin-top': '15px',
                'margin-right': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <ng-template #BxTwo>
            <div class="exploreBoxWrap wrapcss">
              <div class="exploreRightContent styleCss">
                Feeling Overwhelmed? Talk to our Therapists. they are here to
                help you.
              </div>
              <div class="exploreImageBox">
                <img
                  src="../../../assets/images/dashboard/session icon.svg"
                  draggable="false"
                  class="img-fluid imageCss"
                />
                <button (click)="scheduleSession()" class="thm-2 heightCss">
                  Schedule a Session
                </button>
              </div>
            </div></ng-template
          >
        </div>
        <div class="col-md-6 col-sm-6 extraPadding">
          <div *ngIf="isShimmerLoading; else BxThree">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '5px',
                height: '138px',
                width: '100%',
                'margin-top': '15px',
                'margin-right': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <ng-template #BxThree>
            <div class="exploreBoxWrap wrapcss">
              <div class="exploreRightContent styleCss">
                Your Creative Release-Capture Your thoughts, & let your emotions
                go free.
              </div>
              <div class="exploreImageBox">
                <img
                  src="../../../assets/images/dashboard/moodjournalicon.svg"
                  draggable="false"
                  class="img-fluid imageCss"
                />
                <a routerLink="../moodboard" class="thm-2 heightCss"
                  >Visit Mood Journal</a
                >
              </div>
            </div>
          </ng-template>
        </div>
        <div class="col-md-6 col-sm-6 extraPadding">
          <div *ngIf="isShimmerLoading; else BxFour">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '5px',
                height: '138px',
                width: '100%',
                'margin-top': '15px',
                'margin-right': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <ng-template #BxFour>
            <div class="exploreBoxWrap wrapcss">
              <div class="exploreRightContent styleCss">
                Something on your mind. ask questions, comment or share
                anonymously.
              </div>
              <div class="exploreImageBox">
                <img
                  src="../../../assets/images/dashboard/discussionforumicon.svg"
                  draggable="false"
                  class="img-fluid imageCss"
                />
                <br />
                <a routerLink="../discussion" class="thm-2 heightCss"
                  >Start A Discussion</a
                >
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
