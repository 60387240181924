
<div class="container">
    <div class="row">
        <div class="infoBox">
            <div class="notFoundBox d-flex justify-content-center">
                <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
              </div>
        </div>
    </div>
</div>