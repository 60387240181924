<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container mt-3">
    <p class="disclaimerTxt"><i class="fa fa-exclamation-triangle"></i><strong> Disclamer: </strong>This tool should be
      used for screening and monitoring symptom
      severity
      and cannot replace a clinical assessment and diagnosis. You are encouraged to share these results with a mental
      health professional for a better understanding.</p>
    <br>
    <div class="report-user">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user">
      <h3>Congratulations on taking that first step!</h3>
      <p class="mt-3"><strong>Your Score:</strong></p>
      <p>Anxiety Assessment: {{ anxietyScore }}</p>
      <p class="mt-2"><strong>Your Result:</strong></p>
      <p>
        Your responses indicate a <span>{{ anxietyFinal }} <span class="scrollTxt"
            (click)="scrollToElem('target')">Detailed Analysis</span></span>
      </p>
      <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
    </div>
  </div>

  <div class="container graph-section mt-3">
    <div>
      <div>
        <p class="mb-3">Seeing the report can be overwhelming, but Felicity has got you covered. We offer a variety of
          services that you can access at any time, anywhere.</p>
      </div>
      <div class="report-user">
        <h3>Understanding Your Anxiety Assessment</h3>
      </div>
      <div class="graph-right">
        <img src="../../../assets/images/anxiety-hamilton-graph.webp" />
      </div>
    </div>
  </div>

  <div class="container scores-section mb-4">
    <div class="about-scores report-user" id="target">
      <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
      <p class="para_scores mb-3" *ngIf="anxietyScore <= 7">
        From the above graph, it is clear that a score of 0 to 7 means minimal or negligible symptoms. You should
        attempt to maintain this score, because it is a pleasant state to be in.
      </p>
      <p class="para_scores mb-3" *ngIf="anxietyScore >= 8 && anxietyScore <= 14">
        A score of 8 to 14 indicates mild anxiety. You may experience some mild symptoms of anxiety depending on your
        triggers. You can perform activities like journaling, meditation, and mindfulness, to keep your anxiety at bay.
        You can always get in touch with our mental health professionals if you feel overwhelmed by the situation.
      </p>
      <p class="para_scores mb-3" *ngIf="anxietyScore >= 15 && anxietyScore <= 23">
        A score of 15 to 23 indicates moderate anxiety. You may be experiencing symptoms of anxiety at a moderately high
        level. We recommend that you talk to one of our mental health professionals to help you through this. We assure
        to provide you with the best help in this time of need.
      </p>
      <p class="para_scores mb-3" *ngIf="anxietyScore >= 24">
        A score of 24 and above indicates severe anxiety. You’ve come this far, managing all your symptoms on your own.
        It would be best for you to get in touch with some of the best mental health professions including
        psychiatrists, clinical psychologists, therapists and more. In this alarming state of life, Felicity would like
        to extend support and help you get through this with proper treatment.
      </p>
    </div>
  </div>

  <div class="container about-scores">
    <div class="report-user">
      <h3 class="">Detailed Analysis</h3>
      <p class="mb-3">The Hamilton Anxiety Rating Scale (HAM-A) has been designed to measure the severity of Anxiety
        symptoms for several decades now.
      </p>
      <p class="mb-3"><strong>Psychiatric anxiety (Psychological distress): </strong>This includes factors like anxious
        mood, tension. fear, concentration and focus difficulties, insomnia, and depressed mood. In simple words, the
        effect of anxiety on your mental wellbeing. Your score on this subscale is {{subscore1}}, from a range of 0 to
        24. Let’s work towards a healthier life.</p>
      <p class="mb-3"><strong>Somatic anxiety (Physical symptoms of distress): </strong>This includes bodily pain, aches
        and twitching; sensory weakness; changes in heartbeat functioning; respiratory symptoms; issues in stomach and
        intestines; genitourinary symptoms. Apart from this, problems like dry mouth, giddiness, tension headache and
        symptoms surrounding restlessness in the entire body are experienced. Your score on this subscale is
        {{subscore2}}, from a
        range of 0 to 32. Let’s work towards a healthier life.</p>
    </div>
  </div>

  <div class="container">
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
</div>