import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../notification.service";
import { Notification } from "../notification";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  notifications = [];
  pageNo = 1;
  pages = [1];
  showPrev = false;
  showNext = false;
  totalCount = 0;
  isLoading = true;
  skip = 0;
  perPage = 10;
  markAsRead = [];
  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    // this.notificationService.getNotifications().subscribe((res) => {
    //   this.notifications = res;
    // })
    this.getNotifications();
    // this.notificationService
    //   .listen(localStorage.getItem("userId"))
    //   .subscribe((data) => {
    //     this.notifications.unshift(data);
    //   });
  }

  checkPage() {
    if (this.pageNo === this.pages[this.pages.length - 1]) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }
  }

  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;
    this.pageNo = this.pageNo + 1;
    this.getNotifications();
    this.checkPage();
  }

  setPage(pageNo) {
    this.pageNo = pageNo;
    this.getNotifications();
  }

  getNotifications() {
    this.skip = (this.pageNo - 1) * this.perPage;
    this.notificationService
      .notificationPagin( this.skip, this.perPage )
      .subscribe((data) => {
        this.notifications = this.notifications.concat(data["result"])
        this.notifications.forEach((notification) => {
          if(!notification.isRead){
            this.markAsRead.push(notification._id)
          }
        })
        this.markReadFunc()
        this.totalCount = data["totalCount"];
        let pageCount = Math.ceil(this.totalCount / 10);
        if (pageCount != this.pages.length) {
          for (let i = 0; i < pageCount - 1; i++) {
            if (this.pages.indexOf(i + 2)) {
              this.pages.push(i + 2);
            }
          }
        }
        this.checkPage();
        this.isLoading = false;
      });
  }

  markReadFunc(){
    this.notificationService.markAsRead(this.markAsRead).subscribe((res) => {
    })
  }
}
