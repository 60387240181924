import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { COMMON_DATA } from "src/app/shared/common";
import { BookingService } from "../booking.service";
import { ProfileService } from "../profile.service";
import * as momentTz from "moment-timezone";
import * as moment from "moment";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-session-booked",
  templateUrl: "./session-booked.component.html",
  styleUrls: ["./session-booked.component.css"],
})
export class SessionBookedComponent implements OnInit {
  userBookingAvailable;
  sessionId;
  sessionData = null;
  TimeZone = COMMON_DATA.TimeZone;
  userTimezone = null;
  imageUrl = environment.apiUrl;

  buttonShimmer = true
  userDetailShimmer = true

  constructor(
    private bookingService: BookingService,
    private actRoute: ActivatedRoute,
    private profileService: ProfileService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.profileService.getBookingAvailable().subscribe((res) => {
      this.userBookingAvailable = res["bookingAvailable"];
    });
    this.actRoute.paramMap.subscribe((params) => {
      this.sessionId = params.get("id");
      this.profileService.getProfile().subscribe((res) => {

        if (res["TimeZone"]) {
          this.userTimezone = res["TimeZone"];
        } else {
          let timeZone = momentTz.tz.guess();
          let zoneIndex = this.TimeZone.findIndex((tz) => tz.tzCode === timeZone);
          if (zoneIndex !== -1) {
            this.userTimezone = this.TimeZone[zoneIndex];
          }
        }
        this.bookingService.getSession(this.sessionId).subscribe((res) => {
          this.sessionData = res["booking"];
          this.sessionData['startDate'] = moment(new Date(this.sessionData['startDate']))
          this.sessionData['startDate'] = momentTz.tz(this.sessionData['startDate'], this.userTimezone.tzCode)
          this.sessionData['startDateUtc'] = momentTz.tz(this.sessionData['startDate'], this.userTimezone.tzCode).format('MMMM DD, YYYY')
          this.sessionData['startDateDay'] = momentTz.tz(this.sessionData['startDate'], this.userTimezone.tzCode).format('dddd')
          this.sessionData['timeZoneDate'] = this.userTimezone.tzCode + ' (' + this.sessionData['startDate'].format('Z') + ')'
          this.sessionData['endDate'] = moment(new Date(this.sessionData['endDate']))
          this.sessionData['endDate'] = momentTz.tz(this.sessionData['endDate'], this.userTimezone.tzCode)
          this.sessionData['endDateTime'] = this.sessionData['endDate'].format('hh:mm a')
          this.sessionData['startDateTime'] = this.sessionData['startDate'].format('hh:mm a')
          this.userDetailShimmer = false
        });
        this.buttonShimmer = false
      });
    });
  }

  secheduleanother() {
    if (this.userBookingAvailable > 0) {
      this.router.navigate([
        "view-counsellor-calendar/" + this.sessionData.therapistId._id,
      ]);
    } else {
      this.router.navigateByUrl('online-counseling-psychologists/plans-pricing?counselor=' + this.sessionData.therapistId._id)
    }
  }
}
