import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { TherapistProfileService } from "../therapist-profile.service";
import { BootstrapAlertService } from "../../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { environment } from "src/environments/environment";
import { FileValidatorService } from "src/app/shared/file-validator.service";
import { COMMON_DATA } from "src/app/shared/common";
import { TherapistChatService } from "../therapist-chat.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';



@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  userData;
  userName = "";
  therapistForm: FormGroup;
  showSelectedLanguages = false;
  isShimmerLoading = true;
  gender = COMMON_DATA.gender;
  jobqualification = COMMON_DATA.jobqualification;
  counsellingexperience = COMMON_DATA.counsellingexperience;
  counsellingFelicityPerWeek = COMMON_DATA.counsellingFelicityPerWeek;
  WorkingElseWhereApartFelicity = COMMON_DATA.WorkingElseWhereApartFelicity;
  specializeareas1 = COMMON_DATA.specializeareasOptions;
  therapeuticExperties = COMMON_DATA.therapeuticExpertiesOptions;
  language = COMMON_DATA.language;
  ChatMessage = COMMON_DATA.ChatMessage;
  TimeSlott = COMMON_DATA.TimeSlott;
  AMORPM = COMMON_DATA.AMORPM;
  TimeZone = COMMON_DATA.TimeZone;
  selectedLanguageArray;
  selectedtherapeuticExperties;
  onLoadSelectedLanguageArray = [];
  selectedSpecializeAreas;
  onLoadSelectedSpecializeAreas = [];
  selectedLastDegree: File = null;
  imageUrl = environment.apiUrl;
  selectedProfileImage: File = null;
  showDegreeUpload = false;
  showExpUpload = false;
  showResumeUpload = false;
  userCountryCode;
  showBtn: boolean = true;
  isLoading = false;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private profileService: TherapistProfileService,
    private alertService: BootstrapAlertService,
    private uploadValidator: FileValidatorService,
    private therapistChatService: TherapistChatService,
  ) { }

  doShowButton() {
    this.showBtn = true;
  }


  doHideButton() {
    this.showBtn = false;
  }

  bsConfig: Partial<BsDatepickerConfig>;
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit() {
    this.initForms();
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    this.isShimmerLoading = true;
    this.profileService.getProfile().subscribe((data) => {
      this.userData = data;
      this.userName = this.userData.firstname;
      this.selectedLanguageArray = data["selectedLanguageArray"];
      this.selectedtherapeuticExperties = data["therapeuticExperties"]
      this.onLoadSelectedLanguageArray = data["selectedLanguageArray"];
      if (this.selectedLanguageArray) {
        if (this.selectedLanguageArray.length != 0) {
          this.showSelectedLanguages = true;
        }

      }
      this.selectedSpecializeAreas = [...data["specializeareas"]];
      this.onLoadSelectedSpecializeAreas = [...data["specializeareas"]];
      this.therapistForm.setValue({
        firstname: this.userData.firstname ? this.userData.firstname : null,
        lastname: this.userData.lastname ? this.userData.lastname : null,
        email: this.userData.email ? this.userData.email : null,
        phone: this.userData.phone ? this.userData.phone : null,
        dob: this.userData.dob ? new Date(this.userData.dob) : null,
        age: this.userData.age ? this.userData.age : null,
        gender: this.userData.gender ? this.userData.gender : null,
        jobqualification: this.userData.jobqualification
          ? this.userData.jobqualification
          : null,
          jobqualificationOtherOptionAnswer : this.userData.jobqualificationOtherOptionAnswer ? this.userData.jobqualificationOtherOptionAnswer : null,
        counsellingexperience: this.userData.counsellingexperience
          ? this.userData.counsellingexperience
          : null,
        counsellingFelicityPerWeek: this.userData.counsellingFelicityPerWeek
          ? this.userData.counsellingFelicityPerWeek
          : null,
        WorkingElseWhereApartFelicity: this.userData
          .WorkingElseWhereApartFelicity
          ? this.userData.WorkingElseWhereApartFelicity
          : null,
        specializeareas: null,
        therapeuticExperties: null,
        AboutPerson: this.userData.AboutPerson
          ? this.userData.AboutPerson
          : null,
        language: null,
        ChatMessage: this.userData.ChatMessage
          ? this.userData.ChatMessage
          : null,
        TimeSlott: this.userData.TimeSlott ? this.userData.TimeSlott : null,
        AMORPM: this.userData.AMORPM ? this.userData.AMORPM : null,
        TimeZone: this.userData.TimeZone ? this.userData.TimeZone : null,
        pan_no: this.userData.pan_no ? this.userData.pan_no : null,
        bankAccount_no: this.userData.bankAccount_no
          ? this.userData.bankAccount_no
          : null,
        aadhar_no: this.userData.aadhar_no ? this.userData.aadhar_no : null,
        RequiredInfrastructureForVideoCallDevice: this.userData
          .RequiredInfrastructureForVideoCallDevice
          ? this.userData.RequiredInfrastructureForVideoCallDevice
          : false,
        RequiredInfrastructureForVideoCallInternet: this.userData
          .RequiredInfrastructureForVideoCallInternet
          ? this.userData.RequiredInfrastructureForVideoCallInternet
          : false,
        RequiredInfrastructureForVideoCallEnvironment: this.userData
          .RequiredInfrastructureForVideoCallEnvironment
          ? this.userData.RequiredInfrastructureForVideoCallEnvironment
          : false,
        audioTherapy: this.userData.audioTherapy ? this.userData.audioTherapy : false,
        videoTherapy: this.userData.videoTherapy ? this.userData.videoTherapy : false,
        chatTherapy: this.userData.chatTherapy ? this.userData.chatTherapy : false,
      });
      if (this.userData["countryCode"]) {
        this.userCountryCode = this.userData["countryCode"];
      }
      this.isShimmerLoading = false;
    });
  }
  initForms() {
    this.therapistForm = new FormGroup({
      firstname: new FormControl({ value: "", disabled: true }, Validators.required),
      lastname: new FormControl({ value: "", disabled: true }, Validators.required),
      email: new FormControl({ value: "", disabled: true }, [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]),
      phone: new FormControl({ value: "", disabled: true }, [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      dob: new FormControl("", Validators.required),
      age: new FormControl(),
      gender: new FormControl("", Validators.required),
      jobqualification: new FormControl("", Validators.required),
      jobqualificationOtherOptionAnswer : new FormControl(),
      counsellingexperience: new FormControl("", Validators.required),
      counsellingFelicityPerWeek: new FormControl("", Validators.required),
      WorkingElseWhereApartFelicity: new FormControl("", Validators.required),
      AboutPerson: new FormControl(),
      specializeareas: new FormControl(""),
      therapeuticExperties: new FormControl("", Validators.required),
      language: new FormControl(),
      ChatMessage: new FormControl("", Validators.required),
      TimeSlott: new FormControl("", Validators.required),
      AMORPM: new FormControl(),
      TimeZone: new FormControl("", Validators.required),
      pan_no: new FormControl("", [
        Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]{1}$"),
      ]),
      bankAccount_no: new FormControl("", [
        Validators.pattern("^[0-9]{9,18}$"),
      ]),
      aadhar_no: new FormControl("", [
        Validators.pattern("^[2-9]{1}[0-9]{11}$"),
      ]),
      RequiredInfrastructureForVideoCallDevice: new FormControl(),
      RequiredInfrastructureForVideoCallInternet: new FormControl(),
      RequiredInfrastructureForVideoCallEnvironment: new FormControl(),
      audioTherapy: new FormControl(false),
      videoTherapy: new FormControl(false),
      chatTherapy: new FormControl(false)
    });
  }

  checkError(valid) {
    if (!valid) {
      this.therapistForm.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.therapistForm.patchValue({
      phone: phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }

  uploadProfile(event) {
    this.selectedProfileImage = <File>event.target.files[0];
    const data = new FormData();
    data.append("upload", this.selectedProfileImage);
    if (
      this.uploadValidator.validateImage(this.selectedProfileImage.name) &&
      this.uploadValidator.validateSize(this.selectedProfileImage.size)
    ) {
      this.profileService.uploadProfileImage(data).subscribe((res) => {
        if (res) {
          this.alertService.alert(
            new BootstrapAlert(
              "Profile image updated successfully",
              "alert-success"
            )
          );
        }
      });
    } else {
      if (!this.uploadValidator.validateImage(this.selectedProfileImage.name)) {
        this.alertService.alert(
          new BootstrapAlert("Please select a valid image", "alert-danger")
        );
      }
      if (!this.uploadValidator.validateSize(this.selectedProfileImage.size)) {
        this.alertService.alert(
          new BootstrapAlert("File size is too large!", "alert-danger")
        );
      }
    }
  }
  uploadLastDegree(event) {
    this.selectedLastDegree = <File>event.target.files[0];
    const data = new FormData();
    data.append("upload", this.selectedLastDegree);
    this.profileService.uploadLastDegree(data).subscribe((res) => {
      if (res) {
        this.userData.LastDegreeUpload = res;
        this.showDegreeUpload = false;
        this.alertService.alert(
          new BootstrapAlert(
            "Last degree updated successfully",
            "alert-success"
          )
        );
      }
    });
  }

  uploadExperienceLetter(event) {
    this.selectedLastDegree = <File>event.target.files[0];
    const data = new FormData();
    data.append("upload", this.selectedLastDegree);
    this.profileService.uploadExperienceLetter(data).subscribe((res) => {
      if (res) {
        this.userData.experienceletter = res;
        this.showExpUpload = false;
        this.alertService.alert(
          new BootstrapAlert(
            "Exprience letter updated successfully",
            "alert-success"
          )
        );
      }
    });
  }

  uploadResume(event) {
    this.selectedLastDegree = <File>event.target.files[0];
    const data = new FormData();
    data.append("upload", this.selectedLastDegree);
    this.profileService.uploadResume(data).subscribe((res) => {
      if (res) {
        this.userData.resume = res;
        this.showResumeUpload = false;
        this.alertService.alert(
          new BootstrapAlert(
            "Resume updated successfully",
            "alert-success"
          )
        );
      }
    });
  }

  onSubmit() {
    this.confirmationService
      .confirm(
        "Are you sure?",
        "This will update your profile",
        "Yes",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          if (this.therapistForm.controls.phone.status === "INVALID") {
            this.alertService.alert(
              new BootstrapAlert("Please enter a valid phone number", "alert-danger")
            )
            return;
          }
          let userData = this.getDirtyValues(this.therapistForm)
          if(this.therapistForm.value.TimeZone === null){
            delete userData['TimeZone'];
          }
            delete userData["language"];
          userData['therapeuticExperties'] = this.selectedtherapeuticExperties;
          if (JSON.stringify(this.selectedLanguageArray) !== JSON.stringify(this.onLoadSelectedLanguageArray)) {
            userData["selectedLanguageArray"] = this.selectedLanguageArray;
          }
          if (JSON.stringify(this.selectedSpecializeAreas) !== JSON.stringify(this.onLoadSelectedSpecializeAreas)) {
            userData["specializeareas"] = this.selectedSpecializeAreas;
          }
          this.isLoading = true
          this.profileService.updateProfile(userData).subscribe((res) => {
            this.alertService.alert(
              new BootstrapAlert(
                "Profile Updated successfully",
                "alert-success"
              )
            );
            this.isLoading = false;
            this.doShowButton()
          }, (err) => {
            this.doShowButton()
            this.isLoading = false;
          });
        } else {
          this.isLoading = false
          this.doShowButton()
        }
      });
  }
  getDirtyValues(form: any) {
    let dirtyValues = {};
    Object.keys(form.controls)
      .forEach(key => {
        let currentControl = form.controls[key];
        if (currentControl.dirty) {
          if (currentControl.controls)
            dirtyValues[key] = this.getDirtyValues(currentControl);
          else
            dirtyValues[key] = currentControl.value;
        }
      });

    return dirtyValues;
  }
  addLanguageToArray() {
    let indexInArray = null;
    let languageToAdd = this.therapistForm.value.language;
    if (this.therapistForm.value.language) {
      this.selectedLanguageArray.find(function (language, index) {
        if (languageToAdd == language.language) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedLanguageArray.push({
          language: this.therapistForm.value.language,
          fluency: "",
        });
      }
    }
  }
  removeLanguageFromArray(languageToRemove) {
    let indexInArray = null;
    this.selectedLanguageArray.find(function (language, index) {
      if (languageToRemove == language.language) {
        indexInArray = index;
      }
    });
    if (indexInArray != null) {
      this.selectedLanguageArray.splice(indexInArray, 1);
      if (this.selectedLanguageArray.length == 0) {
        this.showSelectedLanguages = false;
      }
    }
  }
  addSpecializeAreaToArray() {
    let indexInArray = null;
    let areaToAdd = { name: this.therapistForm.value.specializeareas };
    if (this.therapistForm.value.specializeareas) {
      this.selectedSpecializeAreas.find(function (area, index) {
        if (areaToAdd == area) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedSpecializeAreas.push(areaToAdd);
      }
    }
  }
  removeSpecializeAreaFromArray(areaToRemove) {
    let indexInArray = null;
    this.selectedSpecializeAreas.find(function (area, index) {
      if (areaToRemove == area) {
        indexInArray = index;
      }
    });
    if (indexInArray != null) {
      this.selectedSpecializeAreas.splice(indexInArray, 1);
    }
  }
  addtherapeuticExperties() {
    let indexInArray = null;
    let areaToAdd = this.therapistForm.value.therapeuticExperties;
    if (this.therapistForm.value.therapeuticExperties) {
      this.selectedtherapeuticExperties.find(function (area, index) {
        if (areaToAdd == area) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedtherapeuticExperties.push(areaToAdd);
      }
    }
  }
  removetherapeuticExpertiesFromArray(areaToRemove) {
    let indexInArray = null;
    this.selectedtherapeuticExperties.find(function (area, index) {
      if (areaToRemove == area) {
        indexInArray = index;
      }
    });
    if (indexInArray != null) {
      this.selectedtherapeuticExperties.splice(indexInArray, 1);
    }
  }
  indexTracker(index: number, value: any) {
    return index;
  }

  showUploadBox(type) {
    if (type == 'EXP') {
      this.showExpUpload = true;
    }
    if (type == 'DEG') {
      this.showDegreeUpload = true;
    }
    if (type == 'RES') {
      this.showResumeUpload = true;
    }
  }

  cancelUploadBox(type) {
    if (type == 'EXP') {
      this.showExpUpload = false;
    }
    if (type == 'DEG') {
      this.showDegreeUpload = false;
    }
    if (type == 'RES') {
      this.showResumeUpload = false;
    }
  }

  downloadAttachment(key, fileName) {
    this.profileService.getDocuments(key).subscribe((data) => {
      let url = data["url"];
      this.getBlob(url).then((blob) => {
        this.saveAs(blob, fileName);
      });
    });
  }

  getBlob(url) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };
      xhr.send();
    });
  }

  saveAs(blob, filename) {
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }
}