<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container mt-3">
    <p class="disclaimerTxt">
      <strong> <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for screening and
      monitoring symptom severity
      and cannot replace a clinical assessment and diagnosis. You are encouraged
      to share these results with a mental health professional for a better
      understanding.
    </p>
    <div class="report-user">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user mt-3">
      <h3>Congratulations on taking that first step!</h3>
      <p class="mt-3"><strong>Your Result:</strong></p>
      <p>

        <span> {{financial_stress_Result}}
          <span class="scrollTxt" (click)="scrollToElem('target')">Detailed Analysis</span></span>
      </p>
      <p class="mt-3"><strong>Your Score:</strong></p>
      <p>Financial Anxiety : {{financial_stress_score}} </p>
      <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
    </div>
  </div>

  <div class="container graph-section mt-3">
    <p class="mb-3">
      {{financial_stress_sub_result}}
    </p>
    <div class="report-user">
      <h3>Understanding Your Financial Anxiety</h3>
    </div>
    <div class="graph-right">
      <img src="../../../assets/images/free-report-graphs/financial-distress.png" />
    </div>
  </div>

  <div class="container scores-section mb-4">
    <div class="about-scores report-user">
      <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
      <p class="para_scores mb-3" id="target">
        {{details_score}}
      </p>

    </div>
    <div class="container about-scores mb-4">
      <div class="">
        <h5 class="mb-3">Detailed Analysis</h5>
        <p class="mb-3">
          The InCharge Financial Distress/Financial Well-Being Scale measures aspects of money management, family life,
          bill paying behaviors, work, and retirement, all of which contribute to financial stress and wellbeing. It is
          thus very important for assessing the level of distress that an individual might be experiencing in their life
          at a particular stage. Identifying the source of stress is essential for managing it, and learning to manage
          one’s finances prevents it from occurring in the future.
        </p>

      </div>
    </div>
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
</div>