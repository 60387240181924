import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

const userBackendUrl = environment.apiUrl + "/user/user/";
const sessionUrl = environment.apiUrl + "/user/booking";
const imageUploadUrl = environment.apiUrl + "/user/user/uploadProfileImage";
const intakeFormUrl = environment.apiUrl + "/user/therapyIntakeForm/";
@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  getProfile() {
    return this.http.get<any>(userBackendUrl + "get-details");
  }

  getFreeReportData(){
    return this.http.get<any>(userBackendUrl + "get-free-report")
  }

  getProfileWithTherapist() {
    return this.http.get<any>(userBackendUrl + "getWithTherapist");
  }

  updateProfile(userData: object) {
    return this.http.put<any>(userBackendUrl + "update-details", userData);
  }

  getUpcomingSession() {
    return this.http.get<any>(sessionUrl + "/upcomingSession");
  }

  getBookingAvailable(){
    return this.http.get(userBackendUrl + "getBookingAvailable");
  }

  uploadProfileImage(imgData){
    return this.http.post(imageUploadUrl, imgData)
  }

  getScheduledSessions(){
    return this.http.get(sessionUrl + "/getScheduledSessions")
  }

  getPurchasedPlans(){
    return this.http.get(userBackendUrl + "/purchasedPlans")
  }

  getUserTherapist(){
    return this.http.get(userBackendUrl + "getUserTherapist")
  }

  getIntakeForm(){
    return this.http.get(intakeFormUrl + "checkIntakeForm")
  }

  getIntakeFormData(){
    return this.http.get(intakeFormUrl + "getIntakeFormData")
  }

  updateIntakeForm(data){
    return this.http.put(intakeFormUrl + "updateIntakeForm", data)
  }

}
