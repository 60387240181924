<div class="topMenuMargin">
    <div class="container">
        <div class="row">
            <div class="secondaryHeading">
                <h2>Email Verification Required</h2>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="message-section">
                You need to verify your email first to continue. We have sent an email to you with a link to verify your account. Please check your email.
            </div>
        </div>
    </div>
</div>