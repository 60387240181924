<div class="topMenuMargin" (click)="closenavbar()">
  <div class="d-flex justify-content-center">
    <div class="blog-container d-flex justify-content-between">
      <div class="blog-left-container">
        <div class="sub-text text-orange">
          <b>{{ blogData?.category?.name }}</b>
        </div>
        <div class="heading-blog-detail" style="margin: 10px 0px">
          <b>{{ blogData?.title }}</b>
        </div>
        <div class="d-flex justify-content-between">
          <div class="sub-text">
            <b>By {{ blogData?.owner }}</b>
          </div>
          <div class="sub-text">
            <b>{{ blogData?.createdAt | date: "MMMM dd, yyyy" }}</b>
          </div>
        </div>

        <div
          *ngIf="blogData?.image; else noBlogDataImage"
          class="blog-image"
          [ngStyle]="{
            background: 'url(' + blogData.imageURL + ')',
            'background-size': 'cover',
            'backgroud-repeat': 'no-repeat'
          }"
        ></div>
        <ng-template #noBlogDataImage>
          <div
            class="blog-image"
            #test
            [ngStyle]="{
              background: 'url( assets/images/blog/download.jpeg)',
              'background-size': 'cover',
              'backgroud-repeat': 'no-repeat'
            }"
          ></div>
        </ng-template>

        <div class="sub-head-text">
          <span [innerHtml]="blogData?.blogDescription | safe: 'html'"></span>
        </div>
        <div class="d-flex justify-content-between action-group">
          <div class="d-flex align-items-center">
            <div
              (click)="onClap()"
              class="clap-btn"
              [ngClass]="{ 'clap-active': isClapped }"
            ></div>
            <div style="margin-left: 12px">{{ claps }} Claps</div>
          </div>
          <div class="mainShareHolder">
            <div class="btn_wrap">
              <span>Share</span>
              <div class="container-share">
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u={{
                    currentUrl
                  }}"
                  target="_blank"
                  ><i class="fa fa-facebook-f"></i
                ></a>
                <a
                  href="https://twitter.com/intent/tweet?url={{ currentUrl }}="
                  target="_blank"
                  ><i class="fa fa-twitter"></i
                ></a>
                <a
                  href="https://www.linkedin.com/shareArticle?mini=true&url={{
                    currentUrl
                  }}"
                  target="_blank"
                  ><i class="fa fa-linkedin"></i
                ></a>
                <a
                  href="https://wa.me/?text=Hey!%20I%20just%20found%20this%20great%20blog%20at%20Felicity.%20Check%20it%20out%20%3A%0A{{
                    currentUrl
                  }}&url={{ blogData.imageURL }}"
                  ><i class="fa fa-whatsapp"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex" style="margin-top: 30px">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u={{ currentUrl }}"
            target="_blank"
            ><div class="facebook-detail" style="margin-right: 24px"></div
          ></a>
          <a href="https://twitter.com/intent/tweet?url={{ currentUrl }}="
            ><div class="twitter-detail" style="margin-right: 24px"></div
          ></a>
          <a
            href="https://www.linkedin.com/shareArticle?mini=true&url={{
              currentUrl
            }}"
            ><div class="linkedin-detail" style="margin-right: 24px"></div
          ></a>
          <div
            (click)="copyUrl()"
            class="link-detail"
            style="margin-right: 24px"
          ></div>
          <a href="whatsapp://send?text={{ currentUrl }}"
            ><div class="whatsapp-detail" style="margin-right: 24px"></div
          ></a>
        </div> -->
      </div>
      <div class="blog-right-container">
        <div class="gray-box">
          <div style="position: relative">
            <input
              class="search-input-text"
              [formControl]="query" (keyup.enter)="onSearch()" (keyup)="onSearch()"
              type="text"
              placeholder="Search..."
            />
            <div class="search-icon"></div>
          </div>
          <div class="d-flex flex-wrap">
            <div
              *ngFor="let category of categories"
              (click)="onSearch(category?.name)"
              class="search-tabs"
              style="margin: 16px 16px 0px 0px"
            >
              {{ category?.name }}
            </div>
          </div>
        </div>
        <div class="gray-box" style="margin-top: 24px">
          <div class="sub-head-text text-center">
            <b>Free Mental Health Test</b>
          </div>
          <div class="mental-health-grid" style="margin-top: 20px">
            <div *ngFor="let item of freeReports">
              <div
                class="grid-image-1"
                [ngStyle]="{
                  background: 'url(' + item.image + ')',
                  'background-size': 'cover',
                  'backgroud-repeat': 'no-repeat',
                  'background-position': 'center'
                }"
                routerLink="../../{{ item.route }}"
              ></div>
              <div
                class="d-flex justify-content-center"
                style="margin-top: 16px"
              >
                <button
                  class="mental-health-button"
                  routerLink="../../{{ item.route }}"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="gray-box" style="margin-top: 24px">
          <div class="sub-head-text text-center"><b>Find Counselors</b></div>
          <div class="counselor-grid" style="margin-top: 20px">
            <div *ngFor="let item of freeReports">
              <div
                class="profile-image"
                [ngStyle]="{
                  background: 'url(' + item.featuredImage + ')',
                  'background-size': 'cover',
                  'backgroud-repeat': 'no-repeat',
                  'background-position': 'center'
                }"
              ></div>
              <div class="text-center" style="margin-top: 16px">
                <div class="sub-text"><b>Angelina David</b></div>
                <div class="xs-text">MSC in Health Psychology</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center" style="margin-top: 24px">
            <button class="mental-health-button">View All Counsellor</button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div>
    <div class="container">
      <div class="boxRound subsBox">
        <div class="secondaryHeading">
          <h2>
            The latest mental health news and tips, delivered to your inbox
            weekly.
          </h2>
          <hr />
        </div>
        <div class="subsEmail formRound1">
          <form [formGroup]="subsForm" (ngSubmit)="onSubmit()">
            <div class="d-flex">
              <input
                type="text"
                placeholder="Enter your mail address here"
                formControlName="email"
                class="form-control"
              />
              <button type="submit" class="buttonTheme1 subsButton">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
