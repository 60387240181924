import { Component, OnInit } from "@angular/core";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-for-corporates",
  templateUrl: "./for-corporates.component.html",
  styleUrls: ["./for-corporates.component.css"],
})
export class ForCorporatesComponent implements OnInit {
  constructor(private sidenavService: SideNavBarService) {}

  ngOnInit(): void {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
}
