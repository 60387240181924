import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { TherapistAuthService } from "src/app/therapist-auth/therapist-auth.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private therapistAuthService: TherapistAuthService,
    private confirmationDialogueService: ConfirmationDialogService,
    private alertService: BootstrapAlertService
  ) { }
  itemid: string;
  changePasswordForm: FormGroup;
  showPasswordOldPass: Boolean = false;
  showPasswordNewPass: Boolean = false;
  showPasswordConfPass: Boolean = false;
  isLoading = false;
  
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit() {
    this.initForms();
  }
  initForms() {
    this.changePasswordForm = new FormGroup({
      password: new FormControl("", Validators.required),
      npassword: new FormControl("", Validators.required),
      cpassword: new FormControl("", Validators.required),
    });
  }
  showPasswordToggleOldPass() {
    this.showPasswordOldPass = !this.showPasswordOldPass;
  }
  showPasswordToggleNewPass() {
    this.showPasswordNewPass = !this.showPasswordNewPass;
  }
  showPasswordToggleConfPass() {
    this.showPasswordConfPass = !this.showPasswordConfPass;
  }
  onSubmit() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.confirmationDialogueService
      .confirm(
        "Are you sure?",
        "This will update your password",
        "Yes",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          this.isLoading = true;
          this.therapistAuthService
            .updatePassword(this.changePasswordForm.value)
            .subscribe((res) => {
              if (res) {
                this.alertService.alert(
                  new BootstrapAlert("Password updated successfully!", "alert-success")
                );
                this.isLoading = false;
                this.changePasswordForm.reset()
              }
            });
        } else {
          this.isLoading = false;
        }
      });
  }
}
