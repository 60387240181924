import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { AuthService } from '../auth.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  isLoading = false;
  constructor(private authService: AuthService,
    private alertService: BootstrapAlertService) { }

    options: AnimationOptions = {
      path: '/assets/loader.json',
    };
  
    animationCreated(animationItem: AnimationItem): void {
      console.log(animationItem);
    }

  ngOnInit(): void {
    this.initForms();
  }

  initForms() {
    this.forgotPassForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]")])
    })
  }

  onSubmit() {
    if (this.forgotPassForm.invalid) {
      return;
    } else {
      this.isLoading = true;
      this.authService.forgotPassword(this.forgotPassForm.value).subscribe((res) => {
        if (res) {
          this.alertService.alert(
            new BootstrapAlert("Email has been Sent With Reset Password Link", "alert-success")
          );
          this.isLoading = false;
          this.forgotPassForm.reset()
        } else {
          this.isLoading = false;
        }
      }, (err) => {
        this.isLoading = false;
      })
    }
  }

}
