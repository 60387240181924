<div class="bodyPageWrap">
  <div class="welcomeBoxWrap">
    <div class="contentBoxWrap">
      <h3>We Know Your Notes Are Important</h3>
      <p>That is why have them organised for you.</p>
    </div>
    <div class="welcomeImage">
      <img
        src="../../../assets/images/notes.svg"
        class="img-fluid"
        draggable="false"
        alt=""
      />
    </div>
  </div>

  <div *ngIf="isShimmerLoading">
    <ngx-skeleton-loader
      appearance="line"
      count = "9"
      [theme]="{
        'border-radius': '12px',
        height: '13rem',
        width: '13rem',
        display: 'flex !important',
        'margin-top': '50px',
        'margin-right': '30px'
      }"
    ></ngx-skeleton-loader>
  </div>

  <div class="clientNotesWrap">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6 col-6" *ngFor="let i of items">
        <div class="thoughtboxWrapap">
          <div class="thoughtFolder">
            <a routerLink="../client-notes-view/{{ i._id }}">
              <img
                src="../../../assets/images/folder.svg"
                class="img-fluid"
                alt=""
                draggable="false"
              />
            </a>
          </div>
          <div class="thoughtButtons">
            <span>{{ i.name }}</span>
            <ul>
              <li>
                <a routerLink="../client-notes-view/{{ i._id }}"
                  ><i class="fa fa-eye"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
