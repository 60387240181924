<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ title }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ message }}
    <div class="btnHolder">
      <button class="btn btn-cancel" (click)="decline()">
        {{ btnCancelText }}
      </button>
      <button class="btn btn-accept" (click)="accept()">{{ btnOkText }}</button>
    </div>
  </div>
</div>
