<div class="topMenuMargin" (click)="closenavbar()">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <strong> <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for
            screening and
            monitoring symptom severity and cannot replace a clinical assessment and
            diagnosis. You are encouraged to share these results with a mental health
            professional for a better understanding.
        </p>
        <br>
        <div class="report-user">
            <h3>
                Getting to know yourself is the first step towards a mentally healthier
                life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <h3>Congratulations on taking that first step!</h3>
            <p class="mt-3"><strong>Your Score:</strong> {{ workLifeScore }}</p>
            <br>
            <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
        </div>
    </div>

    <div class="container graph-section mt-3">
        <div>
            <div>
                <p class="mb-3">
                    Seeing the report can be overwhelming, but Felicity has got you
                    covered. We offer a variety of services that you can access at any
                    time, anywhere.
                </p>
            </div>

            <!-- <h5>Understanding your Loneliness Assessment</h5>
        <div class="graph-right">
          <img src="../../../assets/images/anxiety-gad-graph.webp" />
        </div> -->
        </div>
    </div>

    <div class="container scores-section mb-4">
        <div class="about-scores report-user">
            <h5>Your Score Interpretation</h5>
            <p class="para_scores mb-3">
                If your score lies on the lower end of the spectrum, this means that your work-life prioritisation is
                poor. If you’re spending too long at work, or worrying about your personal life which reduces your
                capability to work, both are interdependent and can worsen your overall quality of life in the long run.
                Fatigue and poor health are the most common features of having a poor work life balance, and having
                lesser ‘alone time’ can also lead to feelings of dissatisfaction, as you are not able to indulge in your
                own interests. There are several ways you can improve your work-life balance and learn to prioritise
                your tasks in a way that you have time for enjoying life as well. Having a healthy support system is one
                way that work-life conflict can be eased, but in order to develop a healthy balance you may need help
                and consultation with a mental health professional who can aid you through this journey of betterment
                together. You can get in touch with the professionals at Felicity, who can help you examine your
                priorities and create a life that is not just constrained by responsibilities.
            </p>
            <p class="para_scores mb-3">
                If your score lies around the 50 score mark, your work life balance is neither too poor nor is it at its optimum. Though you may be able to manage your work and personal responsibilities separately, there is still much you can do in order to live your life to your fullest potential. A healthy lifestyle is important when balancing different roles in our lives, which includes having time to oneself, like being involved in activities outside of work - any sport, a club of any activity, or even volunteering - and if you think that aspect of your life is lacking, then you should get in touch with the mental health professionals at Felicity, who can help you divide your time in such a way that you are capable of having time for other interests as well.
            </p>
            <p class="para_scores mb-3">
                If your score lies on the higher end of the spectrum, this means that you have a good work-life balance. You are able to manage your time effectively between your work responsibilities and your personal relationships. A good work life balance enhances your productivity, and can lead to improved physical and mental health. Ensuring your own wellbeing while maintaining work efficiency is at the core of the healthy lifestyle of a working individual. This is a good level to maintain, but if you still think that there are some aspects of your life you can improve in, or if you think you need help managing it for the long term, there are always small changes that you can do in order to ensure that. Felicity can help you with the same, and you can get in touch with the mental health professionals on the platform any time you require. Extra help is only going to be beneficial!
            </p>
        </div>
    </div>

    <!-- TODO: Add CSS -->
    <div class="container about-scores">
        <div class="">
            <h5 class="mb-3">Detailed Analysis</h5>
            <p class="mb-3">
                Work and personal life are two sides of the same coin, and having an appropriate balance between them is essential for living a healthy and functional life. But sometimes, to meet the requirements of both, one of the aspects of our life has to be compromised in one way or another. This is why, in measuring work life balance, the Work/Life Balance Self-Assessment scale addresses 3 factors, namely: work interference with personal life (WIPL); personal life interference with work (PLIW); and work/personal life enhancement (WPLE).
            </p>
            <p class="mb-3">
                <strong>Work Interference with Personal Life: </strong>This scale includes seven items that measure the extent to which a person’s working life has prevented them from maintaining a proper work-life balance. When a person is incapable of setting boundaries between their work and personal life, such that work responsibilities seep into their daily personal time to the extent that they might even miss certain events on hold to complete their work instead - this is a sign of an unhealthy work to personal life balance. Your score on this scale is: {{score1}}
            </p>
            <p class="mb-3">
                <strong>Personal Life Interference with Work: </strong>This scale includes four items that measure the extent to which a person’s personal life impacts their work efficiency. This factor not only includes family roles - such as that of a child, a spouse, a parent - but also other roles that one may have in their life such as that of a friend, a learner, a member of a community, while also having time to themselves. If any personal issues in these different roles are impacting the ability to remain present and focused at work, then it is a sign that there need to be better boundaries set between your private and professional lives. Your score on this scale is: {{score2}}
            </p>
            <p class="mb-3">
                <strong>Work/Personal Life Enhancement: </strong>This construct includes items that indicate how work and life enhance each other. For instance, family support can enhance energy and efficiency at work, and vice versa - support from colleagues at work can enhance one’s personal life. If something good happens to you in your daily life, you are bound to carry that energy into your working hours as well - this is how work and personal life can enhance each other, and how one may not always be a hindrance to the other. Your score on this scale is: {{score3}}
            </p>
            <p class="mb-3">
                It’s not always possible to divide our lives into 50/50 sections, one set for work and the other for leisure and private affairs. Many jobs have erratic work hours, where maintaining a proper schedule is not always feasible, and this era of technology has made it all the more easier for employees to be almost ‘permanently at work’. In such cases, it is all the more important to maintain a work-life balance, so that it doesn’t lead to mental health issues when the stress gets too overwhelming to manage. If you feel that you are falling into this trap, consider seeking professional help, where you can learn how to deal with your issues more effectively. 
            </p>
        </div>
    </div>
    <div class="container">
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
    <!-- <div class="disclaimer-section">
        <div class="head-disclaimer">
          <h3>Disclaimer</h3>
          <p>
            This tool should be used for screening and monitoring symptom severity
            and cannot replace a clinical assessment and diagnosis. You are
            encouraged to share these results with a mental health professional.
            Felicity disclaims any liability, loss or risk incurred as a consequence
            , directly or indirectly, from the use of this assessment & it's
            results.
          </p>
        </div>
      </div> -->
</div>