import { Component, ElementRef, OnInit } from '@angular/core';
import { Zoom } from 'swiper';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';
import { UserVideoService } from '../user-video.service';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../booking.service';
import * as moment from 'moment';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';

import {
  BootstrapAlert,
  BootstrapAlertService,
} from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
interface IMeetingDetailsResponse {
  id: string | number;
  password: string;
  signatureToken: string;
}
interface IUserDetails {
  id: string | number;
  name: string;
  imageUrl: string;
}

@Component({
  selector: 'app-session-join-user',
  templateUrl: './session-join-user.component.html',
  styleUrls: ['./session-join-user.component.css']
})
export class SessionJoinUserComponent implements OnInit {
  ZoomMtg
  isJoiningRoom = false;
  isMeetingFetchLoader = false
  meetingDetails: IMeetingDetailsResponse = {
    id: '',
    password: '',
    signatureToken: '',
  };
  mobileScreen = false
  leaveUrl = environment.base_url + '/user-dashboard/sessions'
  roomName = "";
  responsiveRoute = ""
  isLoading = true;
  room = null;
  userDetails: IUserDetails = {
    id: '',
    name: '',
    imageUrl: ''
  }
  isAudio: Boolean = true;
  isVideo: Boolean = true;
  isLocalAudio: Boolean = true;
  isLocalVideo: Boolean;
  isFullScreen: Boolean = false;
  isRemoteJoined = false;
  mediaDevices = [];
  localVideoTrack;
  localAudioTrack;
  localTracks = null;
  mics = [];
  MobileText: String;
  cams = [];
  remoteAudioMuted = false;
  remoteVideoMuted = false;
  status = "";
  isConnecting = false
  remainingMinutes = null;
  sessionData = null;
  joinDate = null;
  joinSessionInterval = null;
  remainingTimeText = "";
  imageUrl = environment.apiUrl;
  audioLevel = 0;
  isMobile = false;
  isMicAvailable = true;
  isCamAvailable = true;
  hasTherapistJoined = { status: false, message: '' };
  isFirstTime = true;
  deviceInfo = null;
  constructor(
    private userVideoService: UserVideoService,
    private videoService: UserVideoService,
    private actRoute: ActivatedRoute,
    private bookingService: BookingService,
    private sideNavService: SideNavBarService,
    private deviceService: DeviceDetectorService,
    private confirmationService: ConfirmationDialogService,
    private router: Router,
    private alertService: BootstrapAlertService,
    private el: ElementRef, private meta: Meta
  ) { }

  initializeLibrary() {
    import('@zoomus/websdk').then(({ ZoomMtg }) => {
      this.isJoiningRoom = true
      this.ZoomMtg = ZoomMtg
      ZoomMtg.setZoomJSLib('https://source.zoom.us/2.4.0/lib', '/av');
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();

      // loads language files, also passes any error messages to the ui
      this.isJoiningRoom = true
      this.ZoomMtg.i18n.load('en-US');
      this.ZoomMtg.i18n.reload('en-US');

      document.getElementById('zmmtg-root').style.display = 'none'
      document.getElementById('zmmtg-root').style.backgroundColor = 'transparent'
      document.getElementById('zmmtg-root').style.overflow = 'scroll'
      this.initMeeting()
      this.isLoading = false;
    });
  }
  ngOnInit(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.actRoute.queryParamMap.subscribe((params) => {
      let leftSession = params.get("leftSession");
      if (leftSession == "true") {
        this.status = "CALL_ENDED"
      }
      this.roomName = params.get("sessionId");
      this.responsiveRoute = environment.deepLinkUser + params.get("sessionId");
      this.leaveUrl = environment.base_url + '/user-dashboard/session-join-user?sessionId=' + this.roomName + "&leftSession=true"
      this.videoService.statsLogger({ deviceInfo: this.deviceInfo, sessionId: this.roomName, eventName: 'Page Loaded - User' }).subscribe((res) => {
      });
      this.isLoading = true;
      this.bookingService.getSession(this.roomName).subscribe(
        async (data) => {
          this.sessionData = data["booking"];
          if (data["booking"].userId) {
            this.userDetails.id = data["booking"].userId._id
            this.userDetails.imageUrl = data["booking"].userId.imageUrl
            this.userDetails.name = data["booking"].userId.name
          }
          if (data["booking"].meeting) {
            this.meetingDetails.id = data["booking"].meeting.id
            this.meetingDetails.password = data["booking"].meeting?.pwd || ""
            this.meetingDetails.signatureToken = data["booking"].meeting.signatureToken
          }
          let startDate = moment(this.sessionData.startDate);
          let timeDiff = this.bookingService.calcTimeDiff(
            startDate["_d"],
            new Date()
          );
          let timeDiffKey = Object.keys(timeDiff);

          timeDiffKey.forEach((key) => {
            if (timeDiff[key] > 0) {
              if (timeDiff[key] > 1) {
                this.remainingTimeText += timeDiff[key] + " " + key + " ";
              } else {
                this.remainingTimeText +=
                  timeDiff[key] + " " + key.slice(0, -1) + " ";
              }
            }
          });
          let now = moment(new Date());
          this.remainingMinutes = moment.duration(startDate.diff(now));
          this.remainingMinutes = this.remainingMinutes.asMinutes();
          this.joinDate = new Date(this.sessionData.startDate);
          this.joinDate.setMinutes(this.joinDate.getMinutes() - 10);
          if (this.status == "CALL_ENDED") {
            this.isLoading = false;
          }
          else if (this.remainingMinutes <= -90) {
            this.isLoading = false;
            this.status = "PAST_MEETING";
            setTimeout(() => {
              this.router.navigateByUrl("user-dashboard/sessions");
            }, 3000)
          }
          else if (this.remainingMinutes <= 30) {
            this.status = "ABOUT_TO_START";
            this.doHandleMeetingInit()

          } else {
            this.isLoading = false;
            this.status = "NOT_STARTED";
          }
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 404) {
            this.status = 'NOT_FOUND'
          }
        }
      );
    });

  }


  isMeetingAccessible(endDate, startDate, isBefore = false, defaultMargin = 30) {
    try {
      let endDateMoment = moment(endDate)
      let addedMargin = endDateMoment.add(defaultMargin, 'minutes')
      let startDateMoment = moment(startDate)
      let now = moment(new Date());
      return now.isBetween(startDateMoment, addedMargin)

    }
    catch (e) {
      return false
    }


  }

  doHandleMeetingInit() {
    this.isLoading = false;
    if (this.deviceService.os.toLocaleLowerCase().includes('ios')) {
      this.MobileText = "App Store";
      this.mobileScreen = true;
    }
    else if (this.deviceService.os.includes('Android') || this.deviceService.os.includes('android')) {

      this.MobileText = "Play Store";
      this.mobileScreen = true;
    } else {
      if (this.meetingDetails.id &&
        this.meetingDetails.password &&
        this.meetingDetails.signatureToken) {
        this.mobileScreen = false;
      }
    }
  }
  doGetMeetingDetails() {
    // get meeting details service 
    this.isMeetingFetchLoader = true
    this.bookingService
      .joinSession(this.roomName)
      .subscribe(async (data) => {
        this.isLoading = false
        this.isMeetingFetchLoader = false
        if (data["booking"].meeting && data["booking"].meeting.id && data["booking"].meeting.pwd && data["booking"].meeting.signatureToken) {
          this.hasTherapistJoined.status = false
          this.hasTherapistJoined.message = ""
          this.status = "STARTED";
          this.meetingDetails.id = data["booking"].meeting.id
          this.meetingDetails.password = data["booking"].meeting?.pwd || ""
          this.meetingDetails.signatureToken = data["booking"].meeting?.signatureToken
          clearTimeout(this.joinSessionInterval);
          this.initializeLibrary()
        }
      }, err => {
        this.isLoading = false
        this.isMeetingFetchLoader = false
        // two scenario :1 meeting slots full 2. therapist havent joined yet
        if (err.status == 403) {
          this.status = 'WAITING'
          this.hasTherapistJoined.status = true;
          this.hasTherapistJoined.message = err.error.message
          this.doGetMeetingDetailsInterval()
        }
        if (err.status == 422) {
          this.isLoading = false;
          this.status = "PAST_MEETING";
          this.isMeetingFetchLoader = false
          setTimeout(() => {
            this.router.navigateByUrl("user-dashboard/sessions");
          }, 3000)
        }
        else {

        }
      })


    // if no meeting detals show popup
  }
  doGetMeetingDetailsInterval() {
    // get meeting details service 
    this.joinSessionInterval = setInterval(() => {
      this.isMeetingFetchLoader = true
      this.bookingService
        .joinSession(this.roomName)
        .subscribe(async (data) => {
          this.isLoading = false
          this.isMeetingFetchLoader = false
          if (data["booking"].meeting && data["booking"].meeting.id && data["booking"].meeting.pwd && data["booking"].meeting.signatureToken) {
            this.hasTherapistJoined.status = false
            this.hasTherapistJoined.message = ""
            this.status = "STARTED";
            this.meetingDetails.id = data["booking"].meeting.id
            this.meetingDetails.password = data["booking"].meeting?.pwd || ""
            this.meetingDetails.signatureToken = data["booking"].meeting?.signatureToken
            clearTimeout(this.joinSessionInterval);
            this.initializeLibrary()
          }
        }, err => {
          this.isLoading = false
          this.isMeetingFetchLoader = false
          // two scenario :1 meeting slots full 2. therapist havent joined yet
          if (err.status == 403) {
            this.status = 'WAITING'
            this.hasTherapistJoined.status = true;
            this.hasTherapistJoined.message = err.error.message
          }
          else if (err.status == 422) {
            this.status = "PAST_MEETING";
            setTimeout(() => {
              this.router.navigateByUrl("user-dashboard/sessions");
            }, 3000)
          }
          else {
            this.confirmationService
              .confirm(
                "Something Went Wrong!",
                err.error.message,
                "ok",
                "Retry"
              )
              .subscribe((res) => {
                if (res) {

                }
                else {
                  this.doGetMeetingDetails()

                }
              });
          }
        })

    }, 5000)

    // if no meeting detals show popup
  }
  joinCall() {
    if (this.status === "ABOUT_TO_START") {
      if (this.mics.length === 0 || this.cams.length === 0) {
        if (this.mics.length === 0 && this.cams.length === 0) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like you don't have mic and camera connected. Therapist won't be see and hear you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
        if (this.mics.length === 0 && this.cams.length > 0) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like you don't have mic connected. Therapist won't be able to hear you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
        if (this.mics.length > 0 && this.cams.length === 0) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like you don't have camera connected. Therapist won't be able to see you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
      } else {
        this.checkJoin();
      }
    } else if (this.status === "NOT_STARTED") {
      this.checkJoin();
    }
  }
  checkJoin() {
    if (this.status == "CALL_ENDED") {
      this.status = ""
      this.router.navigateByUrl("user-dashboard/session-join-user?sessionId=" + this.roomName);
    }
    else if (this.status == "ABOUT_TO_START") {
      if(window["$zoho"]){
        if(window["$zoho"].salesiq){
          window["$zoho"].salesiq.floatbutton.visible("hide");
        }
      }
      if(window["kiwi"]){
        window["kiwi"]["hide"]();
      }
      this.isMeetingFetchLoader = true;
      this.videoService.statsLogger({ deviceInfo: this.deviceInfo, sessionId: this.roomName, eventName: 'Waiting - User' }).subscribe((res) => { })
      this.doGetMeetingDetails()
    } else if (this.status == "NOT_STARTED") {
      let startDate = moment(this.sessionData.startDate);
      let now = moment(new Date());
      this.remainingMinutes = moment.duration(startDate.diff(now));
      this.remainingMinutes = this.remainingMinutes.asMinutes();
      if (this.remainingMinutes < 10) {
        this.status = "ABOUT_TO_START";
      } else {
        this.alertService.alert(
          new BootstrapAlert(
            "You can join session when 30 minutes are remaining",
            "alert-warning"
          )
        );
      }
    }
  }

  joinMeeting() {
    this.ZoomMtg.join({
      // user signature from api
      signature: this.meetingDetails.signatureToken,
      meetingNumber: this.meetingDetails.id,
      sdkKey: environment.zoomSdkKey,
      userEmail: '',
      userName: this.userDetails.name,
      passWord: this.meetingDetails.password,
      success: (success) => {
        console.log(success)
        this.ZoomMtg.showJoinAudioFunction({
          show: false
        });
        this.videoService
          .setUserStartedAt(this.roomName)
          .subscribe((res) => { });
        if (this.deviceService.os.includes('Android') || this.deviceService.os.includes('android')) {
          this.meta.updateTag(
            { name: 'viewport', content: "width=device-width, initial-scale=0.1" },
            'name=viewport'
          )
        }
        this.sideNavService.hideHeaderActions()
      },
      error: (error) => {
        this.sideNavService.showHeaderActions()
        console.log(error)
      }
    })
  }
  initMeeting() {
    try {

      this.ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        screenShare: true,
        showMeetingHeader: false,
        disableInvite: true,
        disableRecord: true,
        isSupportChat: true,
        audioPanelAlwaysOpen: false,
        meetingInfo: [],
        // customize: {
        // },
        success: (success) => {
          this.videoService.statsLogger({ deviceInfo: this.deviceInfo, sessionId: this.roomName, eventName: 'Zoom init - User' }).subscribe((res) => { })
          let zoomRoot = document.getElementById('zmmtg-root')
          zoomRoot.style.display = 'block'
          this.isJoiningRoom = true
          if (this.deviceService.isMobile()) {
            if (this.deviceService.os.includes('ios') || this.deviceService.os.includes('iOS')) {
              document.getElementById('media-preview-tooltip-container').style.position = 'absolute'
              document.getElementById('media-preview-tooltip-container').style.left = '50px'
            }
            if (this.deviceService.os.includes('Android') || this.deviceService.os.includes('android')) {
              zoomRoot.className += ' android'
              this.meta.updateTag(
                { name: 'viewport', content: "width=device-width, initial-scale=1" },
                'name=viewport'
              )
              document.getElementById('content_container').style.height = '80vh'
              document.getElementById('content_container').style.width = '50vh'
              document.getElementById('media-preview-tooltip-container').style.position = 'absolute'
              document.getElementById('media-preview-tooltip-container').style.left = '50px'
            }
          }


          if (this.deviceService.isDesktop()) {
            document.getElementById('zmmtg-root').style.paddingLeft = '8rem'
            document.getElementById('zmmtg-root').style.overflow = 'hidden'
          }
          if (success.method !== "init") {
            this.sideNavService.hideHeaderActions()
          }
          this.ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
            // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
            if (data == 1) {
            }
            console.log(data, 'onMeetingStatus');
          });
          this.ZoomMtg.inMeetingServiceListener('onUserJoin', function (data) {
            console.log(data, 'onUserJoin');
          });

          // only support meeting
          this.ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
            console.log(data, 'onUserLeave');
          });

          this.ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', function (data) {
            console.log(data, 'onUserIsInWaitingRoom');
          });
          // console.log(ZoomMtg)
          // @ts-ignore
          // this.getSignatureToken(this.meetingDetails.id)
          this.joinMeeting()
        },
        error: (error) => {
          this.sideNavService.showHeaderActions()
          console.log(error)
        }
      })
    }
    catch (e) {
      this.sideNavService.showHeaderActions()
    }
  }
  openLink() {
    window.open(this.responsiveRoute, '_blank');
  }
  ngOnDestroy() {
    clearInterval(this.joinSessionInterval)
    window.location.reload();
    this.sideNavService.showHeaderActions()
    this.meta.updateTag(
      { name: 'viewport', content: "width=device-width, initial-scale=1" },
      'name=viewport'
    )
  }

}
