import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { AuthService } from "src/app/auth/auth.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { environment } from "src/environments/environment";
import { OurCounsellorsService } from "../../our-counsellors.service";
import { faCommentAlt, faVideo } from '@fortawesome/free-solid-svg-icons';
import clevertap from "clevertap-web-sdk";
import { RegionSelectionService } from "src/app/region-selection.service";


@Component({
  selector: "app-schedule-therapists-detail",
  templateUrl: "./schedule-therapists-detail.component.html",
  styleUrls: ["./schedule-therapists-detail.component.css"],
})
export class ScheduleTherapistsDetailComponent implements OnInit {
  // let newString = languageArray.join();
  faCommentAlt = faCommentAlt;
  faVideo = faVideo;
  languageString = "";
  therapistId = "";
  therapist = null;
  therapistUser = 1;
  upcomingSessionDetail = [];
  imageUrl = environment.apiUrl;
  reviewsData = [];
  nextAvailableAt = null;
  userTimezone = "Asia/Calcutta";
  isProfileActive: boolean = true;

  totalPages;
  currentPage;
  pageNo = 1;
  totalCount = 0;
  showNext = true;
  isLoading = false;
  pages = [1];
  regionData;

  constructor(
    private counsellorService: OurCounsellorsService,
    private actRoute: ActivatedRoute,
    private userAuthService: AuthService,
    private router: Router,
    private sidenavService: SideNavBarService,
    private regionSelectionService : RegionSelectionService
  ) { }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  async ngOnInit() {
    if(localStorage.getItem("regionData")){
      this.regionData = JSON.parse(localStorage.getItem("regionData"))
    } else {
      this.regionData = await this.regionSelectionService.returnRegionData();
    }
    this.actRoute.paramMap.subscribe((params) => {
      if (localStorage.getItem("userTzcode")) {
        this.userTimezone = localStorage.getItem("userTzcode");
      } else {
        this.userTimezone = momentTz.tz.guess();
      }
      this.therapistId = params.get("id");
      this.counsellorService
        .getCounsellorById(this.therapistId)
        .subscribe((data) => {
          this.therapist = data;
          this.isProfileActive = true;
          clevertap.event.push("Counsellor Profile Viewed", {
            "Therapist Name": this.therapist.firstname + " " + this.therapist.lastname
          })
          let specializeAreasArray = [];
          if (this.therapist.specializeareas) {
            this.therapist.specializeareas.forEach((element) => {
              specializeAreasArray.push(element.name);
            });
            this.therapist.specializeareas = specializeAreasArray;
          }
          let languageArray = [];
          if (this.therapist.selectedLanguageArray) {
            this.therapist.selectedLanguageArray.forEach((element) => {
              languageArray.push(element.language);
            });
            this.therapist.selectedLanguageArray = languageArray;
          }
        }, (err) => {
          if (err.status === 410) {
            this.isProfileActive = false;
          }
        });
      // this.counsellorService.getReviews(this.therapistId, this.pageNo).subscribe((res) => {
      //   if (res) {
      //     this.reviewsData = res
      //     this.reviewsData = this.reviewsData.map((review) => {
      //       let nameSplit = review?.username?.split(" ")
      //       let initials = '';
      //       nameSplit.forEach((initial) => {
      //         initials += ' ' + initial[0]
      //       })
      //       review.initials = initials
      //       return review;
      //     })
      //   }
      // })
      this.getReviews()
      this.counsellorService.getNextAvailableAtById(this.therapistId).subscribe((res) => {
        this.nextAvailableAt = res["nextSlot"]
        if (this.nextAvailableAt.length > 0) {
          this.nextAvailableAt = this.nextAvailableAt[0]
          let utcDate = moment.utc(
            this.nextAvailableAt.date + " " + this.nextAvailableAt.slot,
            "DD_MM_YYYY HH:mm"
          );
          this.nextAvailableAt = momentTz
            .tz(utcDate, this.userTimezone)
            .format("MMM D, YYYY h:mm A");
        }
      })
    });
  }
  indexTracker(index: number, value: any) {
    return index;
  }
  getReviews() {
    this.counsellorService.getReviews(this.therapistId, this.pageNo).subscribe((res) => {
      if (res) {
        this.reviewsData = [...this.reviewsData, ...res.reviews]
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.showNext = this.currentPage !== this.totalPages
        this.reviewsData = this.reviewsData.map((review) => {
          let nameSplit = review?.username?.split(" ")
          let initials = '';
          nameSplit.forEach((initial) => {
            initials += ' ' + initial[0]
          })
          review.initials = initials
          return review;
        })
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      this.showNext = false;
    })
  }

  nextPage() {
    this.pageNo = this.pageNo + 1;
    this.isLoading = true;
    this.getReviews()
  }

  viewCalendar() {
    clevertap.event.push("Counsellor Book Now Clicked", {
      "Therapist Name": this.therapist.firstname + " " + this.therapist.lastname
    })
    if (this.userAuthService.getIsAuth()) {
      this.router.navigate([
        "../view-counsellor-calendar/" + this.therapist._id,
      ]);
    } else {
      this.router.navigateByUrl("sign-up?therapist=" + this.therapist._id);
    }
  }
}
