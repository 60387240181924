import { Component, OnInit, NgZone } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AdCampaignService } from "../ad-campaign.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { ScriptService } from "src/app/script.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { ProfileService } from "src/app/user/profile.service";
import { COMMON_DATA } from "src/app/shared/common";
import { OrderService } from "src/app/user/order.service";

@Component({
  selector: "app-ad-campaign-signup",
  templateUrl: "./ad-campaign-signup.component.html",
  styleUrls: ["./ad-campaign-signup.component.css"],
})
export class AdCampaignSignupComponent implements OnInit {
  campaignForm: FormGroup;
  plansSelected = [false, true, false];
  initialPrice;
  finalPrice;
  finalCoupon = 0;
  appliedCoupon = null;
  coupon = null;
  RAZORPAY_OPTIONS = {
    key: environment.RAZORPAY_KEY,
    amount: "",
    name: "",
    order_id: "",
    description: "",
    prefill: {
      name: "",
      email: "",
      contact: "",
      method: "",
    },
    modal: {},
  };
  isPaymentProcessing = false;
  paymentDetails = null;
  isNewUser = true;
  userData = null;
  paramPlan;
  paramCoupon;
  isCouponShow = false;
  userCountryCode = {
    countryCode : "in",
    dialCode : "91"
  };
  states = COMMON_DATA.states;
  selectedState = 'Rajasthan';
  fetchedState = '';  
  constructor(
    private adCampaignService: AdCampaignService,
    private alertService: BootstrapAlertService,
    private loadScript: ScriptService,
    private router: Router,
    private authService: AuthService,
    private profileService: ProfileService,
    private NgZone: NgZone,
    private actRoute: ActivatedRoute,
    private orderService: OrderService
  ) {}
  plans;
  selectedPlan = null;

  ngOnInit(): void {
    this.initForms();
    this.loadGTMCid();
    this.orderService.getRegion().subscribe((data) => {
      if(data['status']){
        this.fetchedState = data['region']
        let inx = this.states.findIndex(state => state === data['region'])
        if(inx > -1){
          this.selectedState = this.states[inx]
        } else {
          this.selectedState = 'Others'
        }
      }
    })
    window.onload = (event) => {
      this.loadGTMCid();
      let planHolder = document.getElementById("scroll");
      planHolder.scrollBy((planHolder.scrollWidth * 20) / 100, 0);
    };
    if (this.authService.getIsAuth()) {
      this.profileService.getProfile().subscribe((res) => {
        this.userData = res;
        if (res["name"]) {
          this.campaignForm.controls["name"].setValue(res["name"]);
        }
        if (res["email"]) {
          this.campaignForm.controls["email"].setValue(res["email"]);
        }
        if (res["phone"]) {
          this.campaignForm.controls["phone"].setValue(res["phone"]);
        }
      });
    }
    setTimeout(() => {
      window["$zoho"].salesiq.floatbutton.visible("hide");
      window["kiwi"]["hide"]();
    }, 4000);
    this.loadScript.load("razor-pay").then((data) => {});

    this.adCampaignService.getPlans().subscribe((res) => {
      this.plans = res;
      this.actRoute.queryParamMap.subscribe((params) => {
        if(params.get("plan")){
          this.paramPlan = params.get("plan");
          if(this.paramPlan === "plan_p"){
            this.adCampaignService.getElevatePlan().subscribe((res) => {
              this.plans.push(res)
              this.plans.forEach((plan) => {
                if (plan.key === this.paramPlan) {
                  this.selectedPlan = plan;
                  this.initialPrice = this.selectedPlan.price;
                  this.finalPrice = this.selectedPlan.price;
                }
              });
            })
          } else {
            this.plans.forEach((plan) => {
              if (plan.key === this.paramPlan) {
                this.selectedPlan = plan;
                this.initialPrice = this.selectedPlan.price;
                this.finalPrice = this.selectedPlan.price;
              }
            });
          }
        } else {
          this.plans.forEach((plan) => {
            if (plan.key === "plan_b") {
              plan["isSelected"] = true;
              this.selectedPlan = plan;
              this.initialPrice = this.selectedPlan.price;
              this.finalPrice = this.selectedPlan.price;
            } else {
              plan["isSelected"] = false;
            }
          });
        }
        if(params.get("coupon")){
          this.paramCoupon = params.get("coupon");
          this.campaignForm.controls["coupon"].setValue(this.paramCoupon);
          this.checkCoupon();
        }
        if(params.get("prime") === "true"){
          this.isCouponShow = false;
        } else {
          this.isCouponShow = true;
        }
      })
      this.plans.sort((a, b) => {
        return a.price - b.price;
      });
    });
  }

  loadGTMCid(){
    if (window["dataLayer"]) {
      let dataLayer = window["dataLayer"];
      dataLayer.forEach((data) => {
        if (data["cid"]) {
          localStorage.setItem("GTMcid", data["cid"]);
        }
      });
    }
  }

  initForms() {
    this.campaignForm = new FormGroup({
      name: new FormControl("", Validators.required),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
      ]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      coupon: new FormControl(""),
    });
  }

  selectPlan(index) {
    this.plans.forEach((plan, inx) => {
      if (index === inx) {
        plan["isSelected"] = true;
        this.selectedPlan = plan;
        this.initialPrice = this.selectedPlan.price;
        if (this.appliedCoupon) {
          this.applyCoupon(this.appliedCoupon);
        } else {
          this.finalPrice = this.selectedPlan.price;
        }
      } else {
        plan["isSelected"] = false;
      }
    });
  }

  checkCoupon() {
    if(!this.campaignForm.controls.email.valid){
      this.alertService.alert(
        new BootstrapAlert("Please enter valid email to apply coupon", 'alert-danger')
      )
      return;
    }
    this.finalPrice = this.initialPrice;
    this.finalCoupon = 0;
    if (this.campaignForm.value.coupon) {
      let code = this.campaignForm.value.coupon;
      this.adCampaignService.getCoupon(code, this.campaignForm.controls.email.value).subscribe(
        (data) => {
          if (data) {
            this.appliedCoupon = data;
            this.applyCoupon(data);
          }
        },
        (error) => {
          this.alertService.alert(
            new BootstrapAlert(error.error.message, "alert-danger")
          );
        }
      );
    }
  }

  applyCoupon(data) {
    this.plans.forEach((plan) => {
      if(plan.price > data.minAmount){
        if(plan.discountedPrice){
          delete plan.discountedPrice;
          delete plan.discount
        }
        let appliedCouponData = this.adCampaignService.applyCoupon(data, plan.price)
        plan.discount = appliedCouponData.discount;
        plan.discountedPrice = appliedCouponData.finalPrice;
      }
    })
    let appliedCouponData = this.adCampaignService.applyCoupon(
      data,
      this.finalPrice
    );
    this.finalPrice = appliedCouponData.finalPrice;
    this.finalCoupon = appliedCouponData.discount;
  }

  initPayment() {
    if(this.campaignForm.invalid){
      this.alertService.alert(
        new BootstrapAlert("Please fill the required fields to proceed!", "alert-danger")
      );
      return;
    }
    let paymentObj = {
      planKey: this.selectedPlan.key,
      email: this.campaignForm.value.email,
      name: this.campaignForm.value.name,
      phone: this.campaignForm.value.phone,
      countryCode : this.userCountryCode.dialCode
    };
    if(this.selectedPlan.discount){
      paymentObj["coupon"] = this.appliedCoupon;
    }
    this.RAZORPAY_OPTIONS["handler"] = this.razorPaySuccessHandler.bind(this);
    this.RAZORPAY_OPTIONS["modal.ondismiss"] = this.failedPayment.bind(this);
    this.adCampaignService.initPayment(paymentObj).subscribe((data) => {
      this.isNewUser = data["userStatus"] === "new" ? true : false;
      if (this.isNewUser) {
        this.authService.campaignLogin(data["user"], data["token"]);
      }
      this.RAZORPAY_OPTIONS.amount = data["order"]["amount"];
      this.RAZORPAY_OPTIONS.name = this.campaignForm.value.name;
      this.RAZORPAY_OPTIONS.order_id = data["order"]["id"];
      (this.RAZORPAY_OPTIONS.description = this.selectedPlan.name),
        (this.RAZORPAY_OPTIONS.prefill.name = this.campaignForm.value.name);
      this.RAZORPAY_OPTIONS.prefill.email = this.campaignForm.value.email;
      this.RAZORPAY_OPTIONS.prefill.contact = this.campaignForm.value.phone;
      let razorpay = new window.Razorpay(this.RAZORPAY_OPTIONS);
      this.isPaymentProcessing = true;
      razorpay.open();
    });
  }

  checkError(valid) {
    if(!valid){
      this.campaignForm.controls["phone"].setErrors({ 'invalidPhone' : true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.campaignForm.patchValue({
      phone: phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }

  razorPaySuccessHandler(response) {
    this.isPaymentProcessing = true;
    this.paymentDetails = response;
    let paymentObj = {
      requestData: {
        plan_key: this.selectedPlan.key,
        orderId: this.RAZORPAY_OPTIONS.order_id,
        amount: this.finalPrice,
        email: this.campaignForm.value.email,
      },
      couponData: this.appliedCoupon,
      region : this.selectedState,
      fetchedRegion: this.fetchedState
    };
    if(this.selectedPlan.discount){
      paymentObj["coupon"] = this.appliedCoupon;
    }
    if(localStorage.getItem('GTMcid')){
      paymentObj['GTMcid'] = localStorage.getItem('GTMcid')
    }
    this.adCampaignService.confirmPayment(paymentObj).subscribe((res) => {
      if(window["dataLayer"]){
        window["dataLayer"].push({ ecommerce : null });
        window["dataLayer"].push({
          event: "new_ad_campaign",
          plan_name: this.selectedPlan.name,
          plan_value: this.finalPrice,
        });
        window["dataLayer"].push({
          'event': 'transaction',
          'ecommerce': {
            'purchase': {
              'actionField': {
                'id': response.razorpay_payment_id,
                'revenue': this.finalPrice,
                'couponDiscount': this.finalCoupon,
              },
              'products': [{
                'name': this.selectedPlan.name,
                'id': this.selectedPlan.key,
                'price': this.selectedPlan.price,
                'sessions': this.selectedPlan.no_of_sessions,
                'couponDiscount': this.finalCoupon
               }]
            }
          }
        })
      }
      this.NgZone.run(() => {
        this.router.navigate(["/thank-you"], {
          queryParams: { act: this.isNewUser, plan: this.selectedPlan.key },
        });
      });
    });
  }

  failedPayment(error) {
    this.NgZone.run(() => {
      this.isPaymentProcessing = false;
      this.alertService.alert(
        new BootstrapAlert("Payment window was closed!", "alert-danger")
      );
    });
  }
  chatWithUs() {
    window["$zoho"].salesiq.floatbutton.visible("show");
    let chatElem = document.getElementById("zsiq_agtpic");
    chatElem.click();
  }
}
