<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container">
    <div class="d-flex align-items-center flex-column">
      <div class="Top">
        <div class="heading-text">
          <div class="heading-text-main-div">
            Do You Control Anxiety Or does it <br />
            Control You
          </div>
        </div>
        <div class="sub-head">
          <p>
            CALM - an anxiety management program personally curated by licensed
            psychologists.
          </p>
        </div>
        <div style="display: flex; justify-content: center; align-items: center">
          <button class="btn btn-success main-div" *ngIf="isLoggedIn" style="
              padding: 1.2vh 3.2vh;
              border-radius: 10px;
              font-size: 18px;
              background: #f47d31;
              outline: none !important;
              border: none;
              box-shadow: 0px 3px 16px #00000029;
            " routerLink="/order-summary/plan_p">
            Start
          </button>
          <button class="btn btn-success main-div" *ngIf="!isLoggedIn" style="
          padding: 1.2vh 3.2vh;
          border-radius: 10px;
          font-size: 18px;
          background: #f47d31;
          outline: none !important;
          border: none;
          box-shadow: 0px 3px 16px #00000029;
        " [routerLink]="['/sign-up']" [queryParams]="{plan : 'plan_p'}">
        Start
      </button>
        </div>
      </div>

      <img src="./assets/images/yoga.png" class="yoga" style="margin-top: 75px" />

      <div class="container">
        <div class="row">
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-8">
            <div class="sub-heading2-Freedom" style="width: 100%">
              Freedom From Anxiety
            </div>
            <p class="freedom" style="display: flex">
              <span style="
                  flex-direction: column;
                  background: #f6f6f6 0% 0% no-repeat padding-box;
                  box-shadow: 0px 2px 11px #0000002e;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                "><i class="fa fa-check circle-freedom"></i></span>
              <span style="margin-left: 12px; font-size: 16px">6 sessions (video/audio/chat) with an expert psychologist
                (45
                minutes each)</span>
            </p>

            <p class="freedom" style="display: flex">
              <span style="
                  flex-direction: column;
                  background: #f6f6f6 0% 0% no-repeat padding-box;
                  box-shadow: 0px 2px 11px #0000002e;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                "><i class="fa fa-check circle-freedom"></i></span>
              <span style="margin-left: 12px; font-size: 16px">Pre and post program anxiety assessment</span>
            </p>

            <p class="freedom" style="display: flex">
              <span style="
                  flex-direction: column;
                  background: #f6f6f6 0% 0% no-repeat padding-box;
                  box-shadow: 0px 2px 11px #0000002e;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                "><i class="fa fa-check circle-freedom"></i></span>
              <span style="margin-left: 12px; font-size: 16px">Access to chat with psychologist in-between
                sessions</span>
            </p>

            <p class="freedom" style="display: flex">
              <span style="
                  flex-direction: column;
                  background: #f6f6f6 0% 0% no-repeat padding-box;
                  box-shadow: 0px 2px 11px #0000002e;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                "><i class="fa fa-check circle-freedom"></i></span>
              <span style="margin-left: 12px; font-size: 16px">Daily Self-help tool-kit personally curated by
                psychologists</span>
            </p>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 happy-img" style="text-align: center">
            <img src="./assets/images/happy.png" class="happy img-fluid" />
          </div>
        </div>
      </div>

      <div class="heading-text-plan-details">PLAN DETAILS</div>
      <div class="col-md-12 col-lg-12 col-xs-12 col-12 col-sm-12 d-flex justify-content-center">
        <div class="orange-seperator"></div>
      </div>

      <div class="pricing-plan">
        <div class="wrapper" id="accordion">
          <ol class="">
            <li class="c-timeline__item">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div class="c-timeline">
                  <div class="c-timeline_content" style="font-size: 24px">
                    Pre Program Assignment
                  </div>
                </div>
              </div>
              <time></time>
            </li>
            <li class="c-timeline__item" data-toggle="collapse" href="#collapseOne" aria-expanded="true">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div class="c-timeline__desc" id="collapseOne" class="collapse show" data-parent="#accordion">
                  <div class="card-body cardTxt">
                    Safe space creation, <br>
                    nutritional tidbits, <br>
                    affirmations, customized journaling.
                  </div>
                </div>
              </div>
              <time>Session 1</time>
            </li>
            <li class="c-timeline__item" data-toggle="collapse" href="#collapseTwo">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div class="c-timeline__desc" id="collapseTwo" class="collapse" data-parent="#accordion">
                  <div class="card-body cardTxt">
                    Goal-setting, <br> rapport building, <br> spin-the-wheel, <br> small victories, <br> nutribits, <br>
                    psychoeducation modules.
                  </div>
                </div>
              </div>
              <time>Session 2</time>
            </li>
            <li class="c-timeline__item" data-toggle="collapse" href="#collapseThree">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div class="c-timeline__desc" id="collapseThree" class="collapse" data-parent="#accordion">
                  <div class="card-body cardTxt">
                    CBT basics, <br> guided relaxation, <br> nutritional tidbits, <br> journaling, <br> psychoeducation
                    modules.
                  </div>
                </div>
              </div>
              <time>Session 3</time>
            </li>

            <li class="c-timeline__item" data-toggle="collapse" href="#collapseFour">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div class="c-timeline__desc" id="collapseFour" class="collapse" data-parent="#accordion">
                  <div class="card-body cardTxt">
                    Thought v/s Reality, <br> Doodle it out, <br> Trust building, <br> Nutribits, <br> psychoeducation
                    modules.
                  </div>
                </div>
              </div>
              <time>Session 4</time>
            </li>

            <li class="c-timeline__item" data-toggle="collapse" href="#collapseFive">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div class="c-timeline__desc" id="collapseFive" class="collapse" data-parent="#accordion">
                  <div class="card-body cardTxt">
                    Thought records, <br> power posing, <br> gratitude jar, <br> nutribits, <br> affirmations, <br>
                    psychoeducation modules.
                  </div>
                </div>
              </div>
              <time>Session 5</time>
            </li>

            <li class="c-timeline__item" data-toggle="collapse" href="#collapseSix">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div class="c-timeline__desc" id="collapseSix" class="collapse" data-parent="#accordion">
                  <div class="card-body cardTxt">
                    Control/No Control, <br> Mindfulness meditation, <br> nutribits, <br> psychoeducation modules.
                  </div>
                </div>
              </div>
              <time>Session 6</time>
            </li>
            <li class="c-timeline__item">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div>
                  <div class="c-timeline_content"></div>
                </div>
              </div>
              <time></time>
            </li>
            <li class="c-timeline__item">
              <img src="../../../../assets/images/user_icon.svg" alt="" />
              <div class="c-timeline__content">
                <div class="c-timeline">
                  <div class="c-timeline_content" style="font-size: 24px">
                    Progress check
                  </div>
                </div>
              </div>
              <time></time>
            </li>
          </ol>
          <div style="display: flex; justify-content: center; align-items: center">
            <button class="btn btn-success main-div" style="
              padding: 1.2vh 3.2vh;
              border-radius: 10px;
              font-size: 18px;
              background: #f47d31;
              outline: none !important;
              border: none;
              box-shadow: 0px 3px 16px #00000029;
            " [routerLink]="['/our-counselors']" [queryParams]="{ specialization : 'Anxiety' }">
              Get Started
            </button>
          </div>
        </div>
      </div>

      <div class="heading-text-human-mind">
        EXPERTS WHO UNDERSTAND ANXIETY <br />
        AND HUMAN MIND
      </div>

      <section class="counselling-session-section">
        <div class="container">
          <!-- <h2 class="section-title title-with-line">Choose Help. Not Suffering.</h2>
      <hr class="hrDivider" /> -->
          <div class="row images" style="display: flex; align-items: center; justify-content: center">
            <!-- {{therapistsData[0].name}} -->
            <div class="col-md-12 col-lg-8 text-center slider-col">
              <div class="counselorHolder fade-in"></div>
              <ngx-slick-carousel class="carousel" #slickModal3="slick-carousel" [config]="slideConfig"
                (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                (beforeChange)="beforeChange($event)">
                <div class="item sldie" ngxSlickItem *ngFor="let therapist of therapistsData" routerLink="../community-talk-therapists-detail/{{
                    therapist.id
                  }}">
                  <img src="../../../../assets/images/homepage-slides/{{
                      therapist.fileName
                    }}" alt="{{ therapist.name }}" class="slider-img w-100 borderRadius" />
                  <div class="slider-info">
                    <h3 class="counsellor-name">{{ therapist.name }}</h3>
                    <p class="counsellor-title">{{ therapist.title }}</p>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
      </section>

      <div class="heading-text-anxiety">SELF HELP FOR ANXIETY</div>
      <div class="col-md-12 col-lg-12 col-xs-12 col-12 col-sm-12 d-flex justify-content-center">
        <div class="orange-seperator"></div>
      </div>

      <!-- <div class="row">

        <div class="col-lg-4 col-md-4 col-xs-12 col-12 col-sm-12">         
          <div class="d-flex justify-content-center flex-column align-items-center">
            <img src="./assets/images/grounding technique icon.svg" height="132px" width="132px" />
              <p style="color:#262626; text-align: center; font-weight: 500; font-size: 26px;">Grounding tehnique</p>        
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-xs-12 col-12 col-sm-12">         
          <div class="d-flex justify-content-center flex-column align-items-center">
            <img src="./assets/images/breathe work icon.svg" height="132px" width="132px" />
              <p style="color:#262626; text-align: center; font-weight: 500; font-size: 26px;">Breathe Work</p>        
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-xs-12 col-12 col-sm-12">         
          <div class="d-flex justify-content-center flex-column align-items-center">
            <img src="./assets/images/mindful minutes icon.svg" height="132px" width="132px" />
              <p style="color:#262626; text-align: center; font-weight: 500; font-size: 26px;">Mindful minutes</p>        
          </div>
        </div>
      </div> -->

      <div class="container-fluid">
        <div class="row justify-content-center" style="position: relative; top: -80px">
          <div class="col-md-10">
            <ngx-slick-carousel class="carousel" #slickModal3="slick-carousel" [config]="youMayAlsoLikeConfig"
              class="mySwiper">
              <div ngxSlickItem *ngFor="let item of safeVideoAlsoLike">
                <div class="likeVideoHolder">
                  <iframe [src]="item" class="you-may-like-videos" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>

      <div class="slogan">
        <!-- <p></p> -->

        <div class="heading-text—Confucius">
          “The man who moves a mountain begins by carrying away small stones.”
          —Confucius
        </div>
      </div>

      <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
        ">
        <button class="btn btn-success main-div" *ngIf="isLoggedIn" style="
            padding: 1.2vh 3.2vh;
            border-radius: 10px;
            font-size: 18px;
            background: #f47d31;
            outline: none !important;
            border: none;
            box-shadow: 0px 3px 16px #00000029;
          " routerLink="/order-summary/plan_p">
          Start now
        </button>
        <button class="btn btn-success main-div" *ngIf="!isLoggedIn" style="
        padding: 1.2vh 3.2vh;
        border-radius: 10px;
        font-size: 18px;
        background: #f47d31;
        outline: none !important;
        border: none;
        box-shadow: 0px 3px 16px #00000029;
      " [routerLink]="['/sign-up']" [queryParams]="{plan : 'plan_p'}">
      Start now
    </button>
      </div>

      <div class="heading-text-frequent">FREQUENTLY ASKED QUESTIONS</div>
      <div class="orange-seperator"></div>
      <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible1')"> <input id="collapsible1" class="toggle" type="checkbox"> <label for="collapsible1"
          class="lbl-toggle">
          <h4>What triggers anxiety?</h4>
        </label>
        <div class="collapsible-content">
          <div class="content-inner">
            <p>Anxiety is a mental health condition that stems from fear. Recent life stressors or impending stress, it can also be caused due to smaller stresses snowballing into one large consequence. Other factors like lifestyle, family environment, and coping mechanisms also add to it. </p>
          </div>
        </div>
      </div>

      <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible2')"> <input id="collapsible2" class="toggle" type="checkbox"> <label for="collapsible2"
          class="lbl-toggle">
          <h4>How can I know if I have anxiety?</h4>
        </label>
        <div class="collapsible-content">
          <div class="content-inner">
            <p>Anxiety becomes detrimental once it starts hampering your daily life routine, preventing you from doing the smallest of tasks.</p>
          </div>
        </div>
      </div>
      <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible3')"> <input id="collapsible3" class="toggle" type="checkbox"> <label for="collapsible3"
          class="lbl-toggle">
          <h4>Do I really need help?</h4>
        </label>
        <div class="collapsible-content">
          <div class="content-inner">
            <p>If you feel like your anxiety is affecting you negatively - not allowing you to function in your daily routine like you used to, in the past; sudden appetite changes and sleep cycle fluctuations; lack of socialization with people close to you, and more, you could consider seeking help.</p>
          </div>
        </div>
      </div>
      <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible4')"> <input id="collapsible4" class="toggle" type="checkbox"> <label for="collapsible4"
          class="lbl-toggle">
          <h4>What does treatment look like?</h4>
        </label>
        <div class="collapsible-content">
          <div class="content-inner">
            <p>Treatment for anxiety differs from case to case, depending on factors such as severity, willingness to work, family environment, social circle, perceptions about self and the world, and more. It focuses on reducing the symptoms of anxiety using various therapy techniques like journaling, mindfulness, the ABCDE model, etc. </p>
          </div>
        </div>
      </div>

      <div class="tab">
        <input type="checkbox" id="chck1" />
        <label class="tab-label" for="chck1" style="color: #262626; margin-top: 30px; margin-bottom: 0px">More on
          Anxiety
          <span class="collapse-icon"></span>
        </label>
        <div class="tab-content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <h3 class="more-anxiety">What is anxiety?</h3>
                <p class="anxiety-details" style="color: #707070">
                  Anxiety is a word we often use to describe excessive worry,
                  fear, or apprehension about the future. It is our body's
                  natural response to stress. There is some level of anxiety in
                  every human that is normal. This is not medically significant
                  and does not impair our day-to-day functioning. It could arise
                  out of everyday situations. First day of college, job
                  interview, a confrontation, or an important presentation ?
                  anything like that could trigger it. It goes as quickly as it
                  comes. Then there are conditions like are anxiety disorders.
                  They arise in a number of forms including phobic,
                  social,obsessive-compulsive (OCD),post-traumatic disorder
                  (PTSD), or generalized anxiety disorders. The symptoms and
                  diagnostic criteria for each subset of anxiety disorders are
                  unique. However, collectively the WHO?s International
                  Classification of Diseases (ICD-10) note frequent symptoms of:
                  <span style="margin-top: 20px">
                    <br />
                    (a) apprehension (worries about future misfortunes, feeling
                    ?on edge?, difficulty in concentrating, etc.);
                    <br />
                    (b) motor tension (restless fidgeting, tension headaches,
                    trembling, inability to relax); <br />(c) autonomic
                    overactivity (lightheadedness, sweating, tachycardia or
                    tachypnoea, epigastric discomfort, dizziness, dry mouth,
                    etc.).? <br />
                    When these feelings are extreme, last for longer than 6
                    months & interfere with your life ? you might have an
                    anxiety disorder. In extreme cases, even leaving the house
                    could make you anxious.
                  </span>
                </p>
              </div>
              <div class="col-md-6 col-sm-12">
                <h3 class="more-anxiety-self-care">
                  Self-care regime for dealing with anxiety
                </h3>
                <p class="anxiety-details" style="color: #707070">
                  Sleep well ?While it might sound too generic a tip, sound
                  sleep and anxiety have a strong inverse corelation. A good
                  sleep routine can flush out excess anxiety from your system.
                  -Meditate & exercise ?This oft repeated recommendation is a
                  powerful anxiety killer. Exercise is a great way to let off
                  steam from your emotional pressure cooker and meditation
                  brings oodles of calm and hence works as a palliative when it
                  comes to anxiety disorders. Eat healthy ?As they say mind is
                  not restricted to your head but is present in your entire
                  body. What you put in your body affects your mind. Do not
                  believe it? Just notice how your feel after a double shot
                  cappuccino versus how you feel after chamomile tea. Anxiety
                  does not coexist well with a balanced diet and good digestion.
                  -Avoid alcohol, caffeine & smoke ?Apart from the physiological
                  damage these things cause, they also interfere with body?s
                  natural state of rest and hence aggravate anxiety.
                </p>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12" style="margin-top: 60px">
                <h3 class="more-anxiety">Types of Anxiety Disorders</h3>
                <div class="anxiety-details" style="color: #707070">
                  Anxiety comes in many forms. Anxiety disorders are typically
                  classified based on the source or trigger of anxiety. Some
                  common ones are:
                  <p class="mt-3" style="color: #707070">
                    <span class="font-weight-bold">Panic:</span> A person with a
                    panic disorder experiences recurring panic attacks at
                    unexpected times. Panic could strike out of the blue and
                    make the person feel helpless. It is so overwhelming that a
                    person does not know what to do or how to a control it. One
                    cannot stop hyperventilating.
                  </p>
                  <p class="mt-3" style="color: #707070">
                    <span class="font-weight-bold">Phobia:</span> A phobic
                    person has an excessive fear of a specific object,
                    situation, or activity. It could be closed spaces, dolls, or
                    flights or just about anything else. These things do not
                    freak out normal people but generate extremely high levels
                    of anxiety in a person with the phobia.
                  </p>
                  <p class="mt-3" style="color: #707070">
                    <span class="font-weight-bold">Social Anxiety:</span> A
                    socially anxious person has an extreme fear of being judged
                    by others in social situations. Just the invitations to
                    social dos can cause them immense anxiety. They avoid social
                    situations like plague and when are compelled to socialize,
                    they are highly uncomfortable and edgy.
                  </p>
                  <p class="mt-3" style="color: #707070">
                    <span class="font-weight-bold">Obsessive-Compulsive Disorder (OCD):</span>OCD causes recurring
                    irrational thoughts that lead you to
                    perform specific, repeated behaviors. There is an
                    irrepressible urge to do the same thing over and over again.
                    It could be something as simple as washing hands. A person
                    can go on obsessing over it, doing it again and again and
                    even when not doing it, just thinking about it. They
                    struggle to break out of this pattern.
                  </p>
                  <p class="mt-3" style="color: #707070">
                    <span class="font-weight-bold">Separation Anxiety:</span>
                    Some people have a fear of being away from home or loved
                    ones. Even a sojourn away from the comfort of home and
                    family can make them anxious. Move to a new city or another
                    home can really drive up their anxiety.
                  </p>
                  <p class="mt-3" style="color: #707070">
                    <span class="font-weight-bold">Illness Anxiety:</span> Also
                    called hypochondria, this is anxiety about one's health.
                    Where a person reads too much into body behavior and
                    imagines the worst. Example ? simple stomach-ache becomes
                    cancer and headache a tumor. One always thinks that
                    something bad is about to happen to them.
                  </p>
                  <p class="mt-3" style="color: #707070">
                    <span class="font-weight-bold">Post-Traumatic Stress Disorder(PTSD):</span>
                    This is the anxiety following a traumatic event. When a
                    human mind experiences something highly traumatic or
                    abusive, the incident gets imprinted on it. And the memory
                    of the incident continues to haunt for a long time. War
                    veterans, childhood abuse victims or people who have been
                    through any other trauma are afflicted with this condition.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <h3 class="more-anxiety" style="margin-top: 60px">
                  First Aid for Anxiety Relief
                </h3>
                <p class="anxiety-details" style="color: #707070">
                  The above-mentioned self-care tips will hold you in good stead
                  when it comes to long term anxiety management. However, what
                  when you are in the middle of an anxiety attack with no
                  immediate help available. Here are somethings you can do that
                  work as an emotional band-aid to control your mind. -Stop and
                  Breathe ?Just do what keeps you alive. Conscious deep
                  breathing is a natural pacifier and instant calm down fix.
                  -Write Down What's Bothering You ?When you write about what
                  you are thinking, it takes away energy from that thought and
                  it stop bothering you with the same intensity. -Focus on
                  something less anxiety-provoking? Go back to a happy place in
                  your mind, a fond memory or occupy yourself with something
                  that distracts you from your anxiety ? cooking or gardening,
                  does not matter.
                </p>
              </div>
              <div class="col-md-6 col-sm-12" style="margin-top: 60px">
                <h3 class="more-anxiety">Long-Term Anxiety Treatment</h3>
                <p class="anxiety-details" style="color: #707070">
                  <span class="font-weight-bold"> Therapy? </span>
                  Counselling is the first line of treatment when it comes to
                  anxiety disorders. By working with psychologists, you can
                  learn tools & strategies to cope with anxiety and build
                  emotional muscularity. Sign up with Felicity and get an online
                  therapy session today.
                  <br />
                  <br />
                  <br />
                  <span class="font-weight-bold" style="margin-top: 120px">
                    Medication -
                  </span>
                  While many cases can be managed via counselling alone,
                  medication might be essential in some cases to balance brain
                  chemistry. Pill shaming deters people from taking help and
                  causes them to suffer. How you are not embarrassed about
                  medicating yourself for diabetes, you shouldn't be embarrassed
                  if you are required to take pills. While it is troubling to
                  note that anxiety is one of the most common mental health
                  disorders (1 in every 3 people have experienced it at some
                  point in their lives), the good news it that anxiety disorders
                  can be treated, even in severe cases with counselling &
                  medication. You can learn to manage anxiety & live a happy,
                  healthy life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>