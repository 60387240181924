<!--Zoho Marketing Automation Web-Optin Form Starts Here-->
<script type="text/javascript" src="https://ajwijk.maillist-manage.in/js/optin.min.js" onload="setupSF('sf3zffab551bbf3b3f1ac98edfd716a37a7e7aa5830ad993c76278bc0268562a61a6','ZCFORMVIEW',false,'light')"></script>
<script type="text/javascript">
	function runOnFormSubmit_sf3zffab551bbf3b3f1ac98edfd716a37a7e7aa5830ad993c76278bc0268562a61a6(th){
		/*Before submit, if you want to trigger your event, "include your code here"*/
	};
</script>

<meta content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
<div id="sf3zffab551bbf3b3f1ac98edfd716a37a7e7aa5830ad993c76278bc0268562a61a6" data-type="signupform">
	<div id="customForm">
		<input type="hidden" id="signupFormType" value="QuickForm_Vertical">
		<div>
			<table width="250" class="quick_form_3_css" border="0" cellspacing="0" cellpadding="0" align="center" name="SIGNUP_BODY" id="SIGNUP_BODY">
				<tbody>
					<tr>
						<td align="center" valign="top">
							<div id="SIGNUP_HEADING" name="SIGNUP_HEADING" changeid="SIGNUP_MSG" changetype="SIGNUP_HEADER">Join our newsletter</div>
							<div>
								<div style="position:relative;">
									<div id="Zc_SignupSuccess" style="display:none;position:absolute;margin-left:4%;width:90%;background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);  margin-top: 10px;margin-bottom:10px;word-break:break-all ">
										<table width="100%" cellpadding="0" cellspacing="0" border="0">
											<tbody>
												<tr>
													<td width="10%">
														<img class="successicon" src="https://ajwijk.maillist-manage.in/images/challangeiconenable.jpg" align="absmiddle">
													</td>
													<td>
														<span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px;word-break:break-word">&nbsp;&nbsp;Thank you for Signing Up</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<form method="POST" id="zcampaignOptinForm" action="https://ajwijk.maillist-manage.in/weboptin.zc" target="_zcSignup"><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
									<div>
										<input name="CONTACT_EMAIL" changetype="CONTACT_EMAIL" changeitem="SIGNUP_FORM_FIELD" type="email" required="true" id="CONTACT_EMAIL" placeholder="Email">
										<span style="display:none" id="dt_CONTACT_EMAIL">1,true,6,Lead Email,2</span>
									</div><!-- check to mark emailid field as type email, and other mandatory fields as type required --><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
									<div>
										<input name="LASTNAME" changetype="LASTNAME" changeitem="SIGNUP_FORM_FIELD" type="text" id="LASTNAME" placeholder="Name">
										<span style="display:none" id="dt_LASTNAME">1,false,1,Last Name,2</span>
									</div>
									<div>
										<input name="SIGNUP_SUBMIT_BUTTON" id="zcWebOptin" type="button" value="Join now">
									</div><!-- Do not edit the below Zoho Campaigns hidden tags -->
									<input type="hidden" id="fieldBorder" value="rgb(235, 235, 235)">
									<input type="hidden" id="submitType" name="submitType" value="optinCustomView">
									<input type="hidden" id="lD" name="lD" value="1f433071fafb6e1">
									<input type="hidden" name="emailReportId" id="emailReportId" value="">
									<input type="hidden" id="formType" name="formType" value="QuickForm">
									<input type="hidden" name="zx" id="cmpZuid" value="1df8d8a88e">
									<input type="hidden" name="zcvers" value="2.0">
									<input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
									<input type="hidden" id="mode" name="mode" value="OptinCreateView">
									<input type="hidden" id="zcld" name="zcld" value="1f433071fafb6e1">
									<input type="hidden" id="document_domain" value="zoho.in">
									<input type="hidden" id="zc_Url" value="ajwijk.maillist-manage.in">
									<input type="hidden" id="new_optin_response_in" value="0">
									<input type="hidden" id="duplicate_optin_response_in" value="0">
									<input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW" onload="">
									<input type="hidden" id="zc_formIx" name="zc_formIx" value="3zffab551bbf3b3f1ac98edfd716a37a7e7aa5830ad993c76278bc0268562a61a6"><!-- End of the campaigns hidden tags --></form>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<img src="https://ajwijk.maillist-manage.in/images/spacer.gif" id="refImage" onload="referenceSetter(this)" style="display:none;">
	</div>
</div>
<div id="zcOptinOverLay" oncontextmenu="return false" style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;"></div>
<div id="zcOptinSuccessPopup" style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;">
	<span style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;" id="closeSuccess">
		<img src="https://ajwijk.maillist-manage.in/images/videoclose.png">
	</span>
	<div id="zcOptinSuccessPanel"></div>
</div>

<!--Zoho Marketing Automation Web-Optin Form Ends Here-->