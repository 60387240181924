import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from "../../free-report.service";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-relationship-test-results',
  templateUrl: './relationship-test-results.component.html',
  styleUrls: ['./relationship-test-results.component.css']
})
export class RelationshipTestResultsComponent implements OnInit {

  relationshipFinal = "";
  report;
  relationshipScore;
  itemId;
  answersArray = []
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) { 
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res["report"]
        this.relationshipScore = res["report"]["reportDetails"]["score"];
        this.answersArray = Object.values(this.report.reportDetails.answers);
        if (clevertap) {
          clevertap.event.push("Free Report Ended", {
            "Report Name": "Relationship Assessment",
            "Score": this.relationshipScore
          })
        }
        this.relationshipFinalcal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id: this.itemId,
      reportType: 'relationship'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if (res) {
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  relationshipFinalcal() {
    if (this.relationshipScore >= 0 && this.relationshipScore <= 6) {
      this.relationshipFinal = "not satisfied with your relationship";
    } else if (this.relationshipScore >= 7 && this.relationshipScore <= 14) {
      this.relationshipFinal = "lowly satisfied with your relationship";
    } else if (this.relationshipScore >= 15 && this.relationshipScore <= 21) {
      this.relationshipFinal = "moderately satisfied with your relationship";
    } else {
      this.relationshipFinal = "highly satisfied with your relationship";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }

}
