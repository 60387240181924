import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const planBackendUrl = environment.apiUrl + "/guest/plan";
@Injectable({
  providedIn: "root",
})
export class PlansService {
  constructor(private http: HttpClient) {}

  getAllPlans() {
    return this.http.get(planBackendUrl + "/get-all");
  }

  getPlanByKey(key) {
    return this.http.get(planBackendUrl + "/" + key);
  }

  getvisiblePlans(region){
    return this.http.get<any[]>(planBackendUrl + "/visible-plans?region=" + region);
  }
}
