<div>
  <div class="mood-sectionPadding-bottom">
    <div style="display: flex; align-items: center; margin-bottom: 20px">
      <span><a routerLink="../moodboard" style="
            font-size: 16px;
            color: #07006e;
            cursor: pointer;
            text-decoration: underline;
          ">Mood Journal</a></span>
      <span style="font-size: 16px">&nbsp;>&nbsp;</span>
      <span style="font-size: 16px; color: #808080">Mood Check In</span>
    </div>
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div>
          <img src="../../../assets/images/moodboard.png" class="img-fluid" draggable="false" />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="mood-moodBoardContent">
          <h3>How do you feel today?</h3>
          <div class="d-flex flex-wrap">
            <div class="mood-feelbox happy" (click)="formSubmit2('#FFCEAD', 'Elated')">
              Elated
            </div>
            <div class="mood-feelbox happy" (click)="formSubmit2('#FFC971', 'Happy')">
              Happy
            </div>
            <div class="mood-feelbox angry" (click)="formSubmit2('#FF6967', 'Angry')">
              Angry
            </div>
            <div class="mood-feelbox sad" (click)="formSubmit2('#99AEFF', 'Sad')">
              Sad
            </div>
            <div class="mood-feelbox confused" (click)="formSubmit2('#AAC9A8', 'Confused')">
              Confused
            </div>
            <div class="mood-feelbox manic" (click)="formSubmit2('#B7A1E4', 'Manic')">
              Manic
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <full-calendar class="mood-myCalendar" #calendar [options]="calendarOptions"></full-calendar>
  </div>
</div>