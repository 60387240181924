import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';

const moodUrl = environment.apiUrl + '/user/mood-journal/'
const moodcheckinUrl = environment.apiUrl + '/user/mood-checkin/'
@Injectable({
  providedIn: 'root'
})
export class MoodJournalService {

  constructor(private http: HttpClient) { }

  createJournal(postObj){
    return this.http.post(moodUrl + 'create', postObj)
  }

  journalPagin( skip, limit) {
    return this.http.get<any>(
      moodUrl +
        "pagin?skip=" +
        skip +
        "&limit=" +
        limit
    );
  }

  deleteJournal(id){
    return this.http.delete(moodUrl+ "delete/" + id)
  }

  getJournalById(id){
    return this.http.get(moodUrl + "read/" + id)
  }

  updateJournal(id, data){
    return this.http.put(moodUrl + "update/" + id, data)
  }

  moodcheckin(moodObj, dateStr){
    return this.http.put(moodcheckinUrl + dateStr, moodObj)
  }
  getMoodCheckins(dates){
    return this.http.post(moodcheckinUrl + 'getMoodCheckins', dates)
  }

  shareMoodJournal(id){
    return this.http.put(moodUrl + 'share-journal/' +id, { isShared : true})
  }

  unShareMoodJournal(id){
    return this.http.put(moodUrl + 'unshare-journal/' +id, { isShared : true})
  }
}
