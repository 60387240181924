<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container mt-3">
    <p class="disclaimerTxt">
      <strong> <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for screening and
      monitoring symptom severity and cannot replace a clinical assessment and
      diagnosis. You are encouraged to share these results with a mental health
      professional for a better understanding.
    </p>
    <br>
    <div class="report-user mt-3">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user">
      <h3>
        Congratulations on taking that first step!
      </h3>
      <p class="mt-2"><strong>Your Result:</strong></p>
      <p>
        Your responses indicate you are <span>{{ relationshipFinal }}. <span class="scrollTxt"
            (click)="scrollToElem('target')">Detailed Analysis</span></span>
      </p>
      <br>
      <p class="mt-3"><strong>Your Score:</strong></p>
      <p>Relationship Assessment: {{ relationshipScore }}</p>
      <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
    </div>
  </div>

  <div class="container graph-section mt-3">
    <div>
      <div>
        <p class="mb-3" *ngIf="relationshipScore >= 0 && relationshipScore <= 6">
          Seeing the report can be stressful, but Felicity has got you covered. We offer a variety of services that you
          can access at any time, anywhere.
        </p>
        <p class="mb-3" *ngIf="relationshipScore >= 7 && relationshipScore <= 14">
          Your results may be slightly concerning for your relationship, but do not worry too much - Felicity has got
          you covered. We offer a variety of services that you can access at any time, anywhere.
        </p>
        <p class="mb-3" *ngIf="relationshipScore >= 15 && relationshipScore <= 21">
          Your results may be slightly concerning for your relationship, but do not worry too much - Felicity has got
          you covered. We offer a variety of services that you can access at any time, anywhere.
        </p>
        <p class="mb-3" *ngIf="relationshipScore >= 22 && relationshipScore <= 35">
          Your results may not be a sign of concern, but read on to find out a more detailed analysis of your score as
          well as a few suggestions from Felicity that you can always use to improve your interpersonal relationships.
        </p>
      </div>
      <h5>Understanding Your Relationship Assessment</h5>
      <div class="graph-right">
        <img src="../../../assets/images/relationship-report-graph.webp" />
      </div>
    </div>
  </div>

  <div class="container scores-section mb-4">
    <div class="about-scores report-user">
      <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
      <p class="mb-3">
        The possible range of scores is 0 to 35, 0 indicates ‘negligible
        satisfaction’ and, 35 indicates ‘high satisfaction’.
      </p>
      <p class="para_scores mb-3" id="target" *ngIf="relationshipScore >= 0 && relationshipScore <= 6">
        A score of 0 to 6 implies no satisfaction in your relationship. You
        could rethink this relationship that you are a part of and mutually work
        on it, if both agree. It would be best for you to get in touch with a
        mental health professional to get the best out of this relationship in
        order to retain yourself. Felicity has got you covered. We provide both
        individual and couple sessions which are custom-tailored to your
        requirements.
      </p>
      <p class="para_scores mb-3" *ngIf="relationshipScore >= 7 && relationshipScore <= 14">
        A score of 7 to 14 indicates low satisfaction in your relationship. This
        does sound like concern and it's good that you understood it sooner. If
        your partner feels the same way, and if both of you are comfortable, you
        could access some of the best help from the mental health professionals
        for couple therapy sessions or individual one-on-one sessions. You might
        feel overwhelmed by this, but it's worth a shot if you think you can
        improve it!
      </p>
      <p class="para_scores mb-3" *ngIf="relationshipScore >= 15 && relationshipScore <= 21">
        The range of scores from 15 to 21 means moderate satisfaction in your
        relationship. To work on it yourself, you could both list down your
        issues with the relationship and have an honest discussion about the
        same. If this feels unmanageable, you also have an option of getting in
        touch with the mental health professionals from Felicity who conduct
        excellent couple therapy sessions.
      </p>
      <p class="para_scores mb-3" *ngIf="relationshipScore >= 22 && relationshipScore <= 35">
        From the above graph, it is clear that a score of 22 to 35 means high
        satisfaction in your relationship. You should attempt to maintain this
        score, because it is a pleasant state to be in, for both you and your
        partner. If it so happens that you are still experiencing trouble, you
        could always try being more honest with yourself and your partner about
        how you are feeling and work on the issues together.
      </p>
    </div>
    <div class="about-scores mb-4">
      <h5 class="mb-3">Detailed Analysis</h5>
      <p class="mb-3">
        Relationship Assessment Scale (RAS) is a measure of general relationship
        satisfaction that you experience. Relationship satisfaction is a
        subjective evaluation and correlates with factors like love, well being,
        honesty and more. The consequence of high relationship satisfaction is
        healthier bond between couples, high couple coping strength in stress
        and difficult times. Your score on this is {{ relationshipScore }}.
      </p>
    </div>
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
  <!-- <div class="disclaimer-section">
        <div class="head-disclaimer">
          <h3>Disclaimer</h3>
          <p>
            This tool should be used for screening and monitoring symptom severity
            and cannot replace a clinical assessment and diagnosis. You are
            encouraged to share these results with a mental health professional.
            Felicity disclaims any liability, loss or risk incurred as a consequence
            , directly or indirectly, from the use of this assessment & it's
            results.
          </p>
        </div>
      </div> -->
</div>