<div class="secPadding" (click)="closenavbar()">
  <div class="container">
    <div class="secondaryHeading">
      <h2>Find A Plan That's Right For You</h2>
      <hr />
      <p>
        Cancel anytime. Same day full refund. Change your therapist anytime. No
        questions asked
      </p>
    </div>
    <div class="planBoxWrap">
      <div class="row planContWrap">
        <div class="col-sm-4 col-md-4">
          <div>
            <img
              src="../../../../assets/images/plans-pricing/psychiatricconsultation.png"
              class="img-fluid"
              draggable="false"
              alt=""
            />
          </div>
          <div class="iconsHolder">
            <img
              src="../../../../assets/images/video.png"
              alt=""
              draggable="false"
              class="img-fluid"
            />
            <img
              src="../../../../assets/images/mic.png"
              alt=""
              draggable="false"
              class="img-fluid"
            />
            <img
              src="../../../../assets/images/chat.png"
              alt=""
              draggable="false"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-sm-8 col-md-8">
          <div class="planConts">
            <h2>What to expect?</h2>
            <h5>25 minutes doctor consultation & medicine prescription</h5>
          </div>
          <div class="planPrice">
            <h2>INR 799</h2>
            <p>( Price per session INR 799 )</p>
          </div>
          <div class="planBuy">
            <a (click)="buyNow('plan_k')" class="buttonTheme1">Buy Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="faqHolder">
    <div class="faqHeading">
      <h2>Frequently Asked Questions</h2>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div class="faqQue">
                <h4>How do I need if I need a psychiatrist consultation?</h4>
              </div>
            </div>
            <div class="flip-card-back">
              <p>
                We suggest a session with a counselor/psychologist first, who
                will assess the need. Lot of cases can be handled without
                medication as well. It is best to follow the suggestion of your
                licensed counsellor.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div class="faqQue">
                <h4>How will I receive the prescription?</h4>
              </div>
            </div>
            <div class="flip-card-back">
              <p>You will get a soft copy on email or WhatsApp.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>
                Will the psychiatrist have my case background in case I have
                already spoken to the counselor?
              </h4>
            </div>
            <div class="flip-card-back">
              <p>
                Yes, if you have been referred for a psychiatric consultation by
                one of our counselors, the psychiatrist will have all the case
                details.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>Will I need to see the psychiatrist again?</h4>
            </div>
            <div class="flip-card-back">
              <p>
                A second follow up consultation after the course of medication
                is strongly recommended.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
