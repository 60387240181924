import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { PlansService } from "src/app/plans.service";
import { ScriptService } from "src/app/script.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { environment } from "src/environments/environment";
import { OrderService } from "../order.service";
import { ProfileService } from "../profile.service";
import clevertap from "clevertap-web-sdk";
import { COMMON_DATA } from "src/app/shared/common";
import { RegionSelectionService } from "src/app/region-selection.service";
declare global {
  interface Window {
    Razorpay: any;
  }
}
@Component({
  selector: "app-order-summary",
  templateUrl: "./order-summary.component.html",
  styleUrls: ["./order-summary.component.css"],
})
export class OrderSummaryComponent implements OnInit {
  @ViewChild("coupon_code") coupon_code: ElementRef;
  plan;
  planKey;
  initialPrice;
  finalPrice;
  finalCoupon = 0;
  appliedCoupon = null;
  coupon = new FormControl("");
  RAZORPAY_OPTIONS = {
    key: environment.RAZORPAY_KEY,
    amount: "",
    name: "",
    order_id: "",
    description: "",
    prefill: {
      name: "",
      email: "",
      contact: "",
      method: "",
    },
    modal: {},
  };
  paymentDetails;
  cousellorRedirection = null;
  userTherapist = null;
  isPaymentProcessing = false;
  promotionalDiscount = 0;
  togglePromo = false;
  states = COMMON_DATA.states;
  selectedState = 'Rajasthan';
  fetchedState = '';
  name: String = localStorage.getItem('name');
  email: String = localStorage.getItem('email');
  dialCode: String = localStorage.getItem('dialCode');
  phone: String = localStorage.getItem('phone');
  expectStatementsObj = {
    explore: "Vent out, identify & understand your issues. Plan your wellness journey with the therapist.",
    firstSteps: "Identify & break negative thought loops, develop coping strategies & initiate positive changes in your life.",
    mindYourMind: "Initiate behavioural changes, learn how to manage your thoughts & emotions."
  }
  expectStatement = this.expectStatementsObj.explore;
  stripe;
  elements;
  paymentElement;
  clientSecretStr;
  regionData;
  loadingCoupon = false;
  partnerSource = "";
  // bolt: any;  payumoney integration
  constructor(
    private confirmationService: ConfirmationDialogService,
    private loadScript: ScriptService,
    private actRoute: ActivatedRoute,
    private planService: PlansService,
    private orderService: OrderService,
    private router: Router,
    private NgZone: NgZone,
    private alertService: BootstrapAlertService,
    private userService: ProfileService,
    private metaSeo: SeoMetaService,
    private regionSelectionService: RegionSelectionService
  ) { }
  backClicked() {
    this.router.navigateByUrl("online-counseling-psychologists/plans-pricing");
  }
  async ngOnInit() {
    this.metaSeo.updateTitle("Order Summary | Felicity")
    // this.invokeStripe();
    // this.stripeCheckout();
    if(localStorage.getItem("regionData")){
      this.regionData = JSON.parse(localStorage.getItem("regionData"))
    } else {
      this.regionData = await this.regionSelectionService.returnRegionData();
    }
    if(localStorage.getItem("partner_source") === "amber"){
      this.regionData = {
        ...this.regionData,
        tag : "IN"
      }
    }
    
    // this.regionData = await this.regionSelectionService.returnRegionData();
    this.actRoute.paramMap.subscribe((params) => {
      this.planKey = params.get("id");
      switch (this.planKey) {
        case "plan_a":
          this.expectStatement = this.expectStatementsObj.explore
          break;
        case "plan_b":
          this.expectStatement = this.expectStatementsObj.firstSteps
          break;
        case "plan_c":
          this.expectStatement = this.expectStatementsObj.mindYourMind
          break;
        default:
          this.expectStatement = this.expectStatementsObj.explore
      }
      this.planService.getPlanByKey(this.planKey).subscribe((data) => {
        this.plan = data["plan"];
        this.promotionalDiscount = data["promoDiscount"];
        this.finalCoupon = this.promotionalDiscount;
        if (this.regionData.tag === "IN") {
          this.plan['avgPrice'] = Math.floor(Number(this.plan["price"]) / Number(this.plan["no_of_sessions"]));
          this.finalPrice = this.plan.price - this.promotionalDiscount;
          this.initialPrice = this.plan.price;
        } else if(this.regionData.tag === "GB"){
          this.plan['avgPrice'] = Math.floor(Number(this.plan["gbPrice"]) / Number(this.plan["no_of_sessions"]));
          this.finalPrice = this.plan.gbPrice - this.promotionalDiscount;
          this.initialPrice = this.plan.gbPrice;
        } else {
          this.plan['avgPrice'] = Math.floor(Number(this.plan["usdPrice"]) / Number(this.plan["no_of_sessions"]));
          this.finalPrice = this.plan.usdPrice - this.promotionalDiscount;
          this.initialPrice = this.plan.usdPrice;
        }
        // this.finalPrice = this.plan.price;
        if (clevertap) {
          let planName = this.plan.name
          clevertap.event.push("Plan Selected", {
            "Plan Name": planName
          })
        }
      });
    });
    this.orderService.getRegion().subscribe((data) => {
      if (data['status']) {
        this.fetchedState = data['region']
        let inx = this.states.findIndex(state => state === data['region'])
        if (inx > -1) {
          this.selectedState = this.states[inx]
        } else {
          this.selectedState = 'Others'
        }
      }
    })
    this.loadScript.load("razor-pay").then((data) => { });
    this.actRoute.queryParamMap.subscribe((params) => {
      this.cousellorRedirection = params.get("therapistId");
    })
    this.userService.getUserTherapist().subscribe((data) => {
      this.userTherapist = data["therapistId"];
    });
    this.cousellorRedirection = localStorage.getItem("counselor");
    setTimeout(() => {
      window["$zoho"].salesiq.floatbutton.visible("hide");
      window["kiwi"]["hide"]();
    }, 2000)

  }

  async stripeCheckout() {
    let paymentObj = {
      planKey: this.planKey,
      coupon: this.appliedCoupon,
      countryCode: this.regionData.regionCode
    }
    this.orderService.getCheckoutUrl(paymentObj).subscribe((data) => {
      window.location.href = data["redirectURL"]
    })
  }

  async invokeStripe() {
    // this.stripe = await loadStripe('pk_test_51M1Wq0SCBgTDSsbndK7vPDBee8FT4KS3euzpb4idDKA2dWH6snknRQVX849zZCKsLYlxihb0f5qB53F4JcOfGdKQ00p6iTei1I');
    // let paymentObj = {
    //   planKey: this.planKey,
    //   coupon: this.appliedCoupon,
    //   countryCode: this.regionData.regionCode
    // }
    // this.orderService.getPaymentIntent(paymentObj).subscribe((data) => {
    //   const clientSecret = data["clientSecret"];
    //   const appearance = {
    //     theme: 'stripe',
    //     loader : 'always',
    //     wallet : {
    //       applePay: "auto",
    //       googlePay: "auto",
    //     }
    //   };
    //   this.elements =  this.stripe.elements({ appearance, clientSecret });

    //   const paymentElementOptions = {
    //     layout: "tabs",
    //   };

    //   this.paymentElement = this.elements.create("payment", paymentElementOptions);
    //   this.paymentElement.mount("#payment-element");
    //   const addressElement = this.elements.create("address", {
    //     mode: "billing",
    //   });
    //   addressElement.mount("#address-element");
    //   this.clientSecretStr = clientSecret;
    // })
  }

  // async onSubmitEvent(event){
  //   console.log(event)
  //   const {error} = await this.stripe.confirmPayment({
  //     //`Elements` instance that was used to create the Payment Element
  //     elements : this.elements,
  //     redirect : 'if_required'
  //     // confirmParams : {
  //     //   return_url : "http://localhost:4200"
  //     // }
  //   });

  //   if (error) {
  //     // This point will only be reached if there is an immediate error when
  //     // confirming the payment. Show error to your customer (for example, payment
  //     // details incomplete)
  //     console.log(error)
  //     // const messageContainer = document.querySelector('#error-message');
  //     // messageContainer.textContent = error.message;
  //   } else {
  //     // Your customer will be redirected to your `return_url`. For some payment
  //     // methods like iDEAL, your customer will be redirected to an intermediate
  //     // site first to authorize the payment, then redirected to the `return_url`.
  //     console.log('payment done')
  //   }
  // }

  checkCoupon() {
    if(this.loadingCoupon) return;
    this.loadingCoupon = true;
    this.finalPrice = this.initialPrice;
    this.finalCoupon = 0;
    this.appliedCoupon = null;
    if (this.coupon.value) {
      let code = this.coupon.value;
      this.orderService.getCoupon(code).subscribe(
        (data) => {
          if (data) {
            this.appliedCoupon = data;
            if (this.appliedCoupon.minAmount > this.finalPrice) {
              this.alertService.alert(
                new BootstrapAlert(
                  "Coupon not applicable for this plan!",
                  "alert-danger"
                )
              );
              return;
            }
            let appliedCouponData = this.orderService.applyCoupon(
              data,
              this.finalPrice
            );
            this.finalPrice = appliedCouponData.finalPrice;
            this.finalCoupon = appliedCouponData.discount;
            this.loadingCoupon = false;
          }
        },
        (error) => {
          this.alertService.alert(
            new BootstrapAlert(error.error.message, "alert-danger")
          );
          this.loadingCoupon = false;
        }
      );
    }
  }

  initPayment() {
    this.isPaymentProcessing = true;
    if (this.finalPrice === 0) {
      let zeroPaymentObj = {
        requestData: {
          plan_key: this.planKey,
          amount: this.finalPrice,
        },
        couponData: this.appliedCoupon,
        isZeroPayment: true
      }
      this.orderService.confirmPayment(zeroPaymentObj).subscribe((res) => {
        if (clevertap) {
          clevertap.event.push("Plan Purchased", {
            "Plan Name": this.plan.name,
            "Discount": this.finalCoupon,
            "Amount": this.finalPrice,
            "Plan Key": this.plan.key
          })
        }
        this.isPaymentProcessing = false;
        if (this.cousellorRedirection) {
          this.NgZone.run(() => {
            this.router.navigateByUrl(
              "view-counsellor-calendar/" + this.cousellorRedirection
            );
          });
          return;
        }
        if (this.userTherapist) {
          this.NgZone.run(() => {
            this.router.navigateByUrl(
              "view-counsellor-calendar/" + this.userTherapist
            );
          });
          return;
        }
        this.NgZone.run(() => {
          this.router.navigateByUrl("questions?type=general");
        });
      })
      return;
    }
    let paymentObj = {
      planKey: this.planKey,
      coupon: this.appliedCoupon,
      regionCode : this.regionData["regionCode"],
    };
    this.RAZORPAY_OPTIONS["handler"] = this.razorPaySuccessHandler.bind(this);
    this.RAZORPAY_OPTIONS["modal.ondismiss"] = this.failedPayment.bind(this);
    this.orderService.initPayment(paymentObj).subscribe((data) => {
      this.RAZORPAY_OPTIONS.amount = data["order"]["amount"];
      this.RAZORPAY_OPTIONS.name = data["user"]["name"];
      this.RAZORPAY_OPTIONS.order_id = data["order"]["id"];
      (this.RAZORPAY_OPTIONS.description = this.plan.name),
        (this.RAZORPAY_OPTIONS.prefill.name = data["user"]["name"]);
      this.RAZORPAY_OPTIONS.prefill.email = data["user"]["email"];
      this.RAZORPAY_OPTIONS.prefill.contact = data["user"]["phone"];
      let razorpay = new window.Razorpay(this.RAZORPAY_OPTIONS);
      this.isPaymentProcessing = true;
      console.log(this.RAZORPAY_OPTIONS)
      razorpay.open();
    });
    // this.orderService.initPayment(paymentObj).subscribe((data) => {
    //   let hash = String(data["hash"]);
    //   let key = data["key"];
    //   hash = hash.replace(/^"(.*)"$/, "$1");
    //   console.log(hash);
    //   let requestData = {
    //     key: key,
    //     txnid: this.planKey,
    //     hash: hash,
    //     amount: this.finalPrice,
    //     discount: this.finalCoupon,
    //     firstname: "potter",
    //     udf5: this.planKey,
    //     email: "potter@gmail.com",
    //     phone: 7894561230,
    //     productinfo: "Felicity",
    //     surl: "https://sucess-url.in",
    //     furl: "https://fail-url.in",
    //   };
    //   console.log(requestData);

    //   let handler = {
    //     responseHandler: function (BOLT) {
    //       console.log(BOLT.response);
    //       if (BOLT.response.txnStatus == "SUCCESS") {
    //         window.alert("SUCCESS!");
    //       }
    //     },
    //     catchException: function (BOLT) {
    //       window.alert("failed!");
    //       console.log(BOLT);
    //     },
    //   };
    //   window.bolt.launch(requestData, handler);
    // });
  }
  public razorPaySuccessHandler(response) {
    this.isPaymentProcessing = true;
    this.paymentDetails = response;
    let paymentObj = {
      requestData: {
        plan_key: this.planKey,
        orderId: this.RAZORPAY_OPTIONS.order_id,
        amount: this.finalPrice,
      },
      couponData: this.appliedCoupon,
      region: this.selectedState,
      fetchedRegion: this.fetchedState,
      regionCode : this.regionData.regionCode
    };
    this.orderService.confirmPayment(paymentObj).subscribe((data) => {
      if (data) {
        if (window["dataLayer"]) {
          window["dataLayer"].push({ ecommerce: null });
          window["dataLayer"].push({
            'event': 'transaction',
            'ecommerce': {
              'purchase': {
                'actionField': {
                  'id': response.razorpay_payment_id,
                  'revenue': this.finalPrice,
                  'couponDiscount': this.finalCoupon,
                },
                'products': [{
                  'name': this.plan.name,
                  'id': this.plan.key,
                  'price': this.plan.price,
                  'sessions': this.plan.no_of_sessions,
                  'couponDiscount': this.finalCoupon
                }]
              }
            }
          })
        }
        if (clevertap) {
          clevertap.event.push("Plan Purchased", {
            "Plan Name": this.plan.name,
            "Discount": this.finalCoupon,
            "Amount": this.finalPrice,
            "Plan Key": this.plan.key
          })
        }
        this.isPaymentProcessing = false;
        if (this.cousellorRedirection) {
          this.NgZone.run(() => {
            this.router.navigateByUrl(
              "view-counsellor-calendar/" + this.cousellorRedirection
            );
          });
          return;
        }
        if (this.userTherapist) {
          this.NgZone.run(() => {
            this.router.navigateByUrl(
              "view-counsellor-calendar/" + this.userTherapist
            );
          });
          return;
        }
        this.NgZone.run(() => {
          this.router.navigateByUrl("questions?type=general");
        });
      }
    });
  }

  public failedPayment(error) {
    this.NgZone.run(() => {
      this.isPaymentProcessing = false;
      this.alertService.alert(
        new BootstrapAlert("Payment window was closed!", "alert-danger")
      );
    });
  }

  public errorPayment(error) {
    this.NgZone.run(() => {
      console.log(error)
    })

  }

  hideSalesIQ() {
    window['$zoho'].salesiq.ready = function () {
      window['$zoho'].salesiq.floatbutton.visible("hide");
    };
    window.onload = (event) => {
      window['kiwi']['hide']();
    }
  }

  togglePromoSection() {
    this.togglePromo = !this.togglePromo
  }
}
