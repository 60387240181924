<div class="bodyPageWrap">
  <!-- Main box Shimmer -->
  <div *ngIf="isShimmerLoading; else MainLoaderComplete">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        'border-radius': '12px',
        height: '225px',
        width: '100%',
        display: 'flex !important',
        'margin-top': '3px',
        'margin-right': '10px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <ng-template #MainLoaderComplete>
    <div class="roundCircleBox">
      <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
      <div class="afterRoundBox">
        <div class="sectionIcon">
          <label class="chooseImage">
            <input type="file" (change)="uploadProfile($event)" name="files" />
            <span class="choosingImageScript" *ngIf="userData?.imageUrl">
              <img
                src="{{ imageUrl }}/{{ userData?.imageUrl }}"
                draggable="false"
                alt="img"
                class="img-fluid"
              />
            </span>
            <span class="choosingImageScript" *ngIf="!userData?.imageUrl">
              <img
                src="../../../assets/images/user.png"
                draggable="false"
                alt="img"
                class="img-fluid"
              />
            </span>
            <div class="col-lg-12"></div>
          </label>
        </div>
        <div class="sectionHeading">
          <h2>Hello {{ userName }}</h2>
        </div>
      </div>
    </div>
  </ng-template>

  <div>
    <div class="formRound">
      <form [formGroup]="userDataForm" name="userDataForm">
        <!-- change Password Shimmer Button -->
        <div *ngIf="isShimmerLoading; else changePasswordLoadingComplete">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '50px',
              height: '52px',
              width: '25%',
              display: 'flex !important',
              'margin-top': '3px',
              'margin-left': '75%',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <ng-template #changePasswordLoadingComplete>
          <div class="changePasswordCss">
            <a routerLink="../changepassword" class="thm-2">Change Password</a>
          </div>
        </ng-template>

        <div
          *ngIf="isShimmerLoading; else mainFormLoader"
          style="text-align: center"
        >
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '12px',
              height: '50px',
              width: '50%',
              display: 'flex !important',
              'margin-top': '3px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            appearance="line"
            count="10"
            [theme]="{
              'border-radius': '12px',
              height: '40px',
              width: '80%',
              display: 'flex !important',
              'margin-top': '3px',
              'margin-right': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>

        <ng-template #mainFormLoader>
          <div class="sectionPadding-top profileFileSelect">
            <div class="sectionHeading">
              <h3>Personal Information</h3>
              <hr />
            </div>
            <div class="insideFormDivTags">
              <h3>Name</h3>
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  formControlName="name"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="insideFormDivTags">
              <h3>Email</h3>
              <div>
                <input
                  type="text"
                  id="email"
                  name="email"
                  formControlName="email"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="insideFormDivTags">
              <h3>Contact Number</h3>
              <div *ngIf="userCountryCode">
                <input
                  type="text"
                  id="phoneInput"
                  formControlName="phone"
                  class="phoneControl"
                  [ngClass]="{
                    'has-error':
                      userDataForm.controls.phone.errors?.invalidPhone
                  }"
                  ng2TelInput
                  (hasError)="checkError($event)"
                  (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }"
                />
              </div>
              <div
                class="invalidMessage"
                *ngIf="userDataForm.controls.phone.errors?.invalidPhone"
              >
                <p>Please enter a valid phone number.</p>
              </div>
            </div>
            <div class="insideFormDivTags">
              <h3>DOB</h3>
              <div>
                <input
                  type="text"
                  placeholder="Datepicker"
                  class="form-control"
                  bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'DD MMM Y' }"
                  formControlName="dob"
                />
              </div>
            </div>
          </div>
          <div class="sectionPadding-top">
            <div class="sectionHeading">
              <h3>Preferences</h3>
              <hr />
            </div>
            <div class="insideFormDivTags">
              <h3>What are you seeking help for?</h3>
              <div *ngIf="!showSpecificArea">
                <ng-select
                  class="form-control table"
                  [items]="specializeareas1"
                  formControlName="specializeArea"
                  [(ngModel)]="selectedOptionspecializeareas"
                  [multiple]="true"
                  [selectableGroup]="true"
                  [closeOnSelect]="false"
                  [selectableGroup]="true"
                  (change)="onSpecializeAreaChanged($event)"
                ></ng-select>
              </div>
              <p
              class="error-validation"
                *ngIf="userDataForm.controls.specializeArea.errors?.required"
              >
                This field is required
              </p>
            </div>
            <!-- <div
            class="insideFormDivTags"
            *ngIf="
              seekingHelpOption ==
              'I want counselling treatment for a specific mental health condition.'
            "
          >
            <h3>Please select the issue :</h3>
            <ng-select
              class="form-control table"
              [items]="specializeareas1"
              formControlName="specializeArea"
              [(ngModel)]="selectedOptionspecializeareas"
              [multiple]="true"
              [selectableGroup]="true"
              [closeOnSelect]="false"
              [selectableGroup]="true"
            ></ng-select>
          </div> -->
            <div class="insideFormDivTags" *ngIf="showSpecificArea">
              <input
                class="form-control col-xl-9"
                type="text"
                formControlName="specifyNeed"
                placeholder="Type your issue here..."
              />
              <button
                class="buttonTheme2 selectFromOptions"
                *ngIf="showSpecificArea"
                (click)="showOptions()"
                style="margin-left:20px; margin-top:20px"
              >
                Select from options
              </button>
            </div>
            <div class="insideFormDivTags">
              <h3 class="error-validation">What is your preferred medium for the session?</h3>
              <div>
                <ng-select
                  class="form-control table"
                  [items]="preferredMediumOptions"
                  formControlName="preferredMedium"
                ></ng-select>
              </div>
              <p
              class="error-validation"
                *ngIf="userDataForm.controls.preferredMedium.errors?.required"
              >
                Preferred medium is required
              </p>
            </div>
            <div class="insideFormDivTags">
              <h3>Do you have a gender preference for your therapist?</h3>
              <div>
                <ng-select
                  class="form-control table"
                  [items]="therapistgenderpreference"
                  formControlName="therapistgenderpreference"
                ></ng-select>
              </div>
              <p
              class="error-validation"
                *ngIf="
                  userDataForm.controls.therapistgenderpreference.errors
                    ?.required
                "
              >
                Therapist gender preference is required
              </p>
            </div>

            <div class="insideFormDivTags">
              <h3>
                Do you speak any languages, other than English? If yes, please
                select the language and your proficiency level?
              </h3>
              <div>
                <ng-select
                  class="form-control table"
                  [items]="language"
                  (change)="addLanguageToArray()"
                  formControlName="language"
                ></ng-select>
                <div class="divTagClassCss"></div>
                <table>
                  <tr>
                    <th>Language</th>
                    <th>Native</th>
                    <th>Fluent</th>
                    <th>Average</th>
                  </tr>

                  <tr
                    *ngFor="
                      let language of selectedLanguageArray;
                      let idx = index;
                      trackBy: indexTracker
                    "
                  >
                    <td>
                      {{ language.language }}
                    </td>
                    <td>
                      <input
                        name="{{ language.language }}"
                        [checked]="language.fluency == 'Native'"
                        [(ngModel)]="selectedLanguageArray[idx].fluency"
                        [ngModelOptions]="{ standalone: true }"
                        type="radio"
                        value="Native"
                      />
                    </td>
                    <td>
                      <input
                        name="{{ language.language }}"
                        [checked]="language.fluency == 'Fluent'"
                        [(ngModel)]="selectedLanguageArray[idx].fluency"
                        [ngModelOptions]="{ standalone: true }"
                        type="radio"
                        value="Fluent"
                      />
                    </td>
                    <td>
                      <input
                        name="{{ language.language }}"
                        [checked]="language.fluency == 'Average'"
                        [(ngModel)]="selectedLanguageArray[idx].fluency"
                        [ngModelOptions]="{ standalone: true }"
                        type="radio"
                        value="Average"
                      />
                    </td>
                    <td>
                      <i
                        class="fa fa-times"
                        (click)="removeLanguageFromArray(language.language)"
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                  <tr></tr>
                </table>
              </div>
            </div>
            <div class="insideFormDivTags">
              <h3>When do you want to schedule your first session?</h3>
              <div>
                <ng-select
                  class="form-control table"
                  [items]="firstsessionAt"
                  formControlName="firstsessionAt"
                ></ng-select>
              </div>
              <p
              class="error-validation"
                *ngIf="userDataForm.controls.firstsessionAt.errors?.required"
              >
                This Field is required
              </p>
            </div>
            <div class="insideFormDivTags">
              <h3>What is your preferred time for a session?</h3>
              <div>
                <ng-select
                  class="form-control table"
                  [items]="TimeSlott"
                  formControlName="TimeSlott"
                ></ng-select>
              </div>
              <p
                *ngIf="userDataForm.controls.TimeSlott.errors?.required"
                class="error-validation"
              >
                Time slot is required
              </p>
            </div>
            <div class="submitButton text-center">
              <div
                *ngIf="isLoading"
                class=""
                style="
                  display: flex;
                  justify-content: center;
                  margin-left: -55px;
                "
              >
                <div
                  class="spinner-border"
                  style="
                    width: 2rem;
                    height: 2rem;
                    margin-left: 55px;
                    margin-top: 23px;
                  "
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
              </div>
              <button
                [style.opacity]="
                  !userDataForm.controls.TimeSlott.errors?.required &&
                  !userDataForm.controls.firstsessionAt.errors?.required &&
                  !userDataForm.controls.therapistgenderpreference.errors
                    ?.required &&
                  !userDataForm.controls.preferredMedium.errors?.required &&
                  userDataForm.controls.specializeArea.value != ''
                    ? '1'
                    : '0.5'
                "
                [style.cursor]="
                  !userDataForm.controls.TimeSlott.errors?.required &&
                  !userDataForm.controls.firstsessionAt.errors?.required &&
                  !userDataForm.controls.therapistgenderpreference.errors
                    ?.required &&
                  !userDataForm.controls.preferredMedium.errors?.required &&
                  userDataForm.controls.specializeArea.value != ''
                    ? 'pointer'
                    : 'not-allowed'
                "
                (click)="onSubmit()"
                *ngIf="!isLoading"
                class="thm-2"
              >
                Save
              </button>
            </div>
          </div>
          <div class="row delete-container" >
            <div class="col-12 delete-title">
              Deleting account will remove all your personal information. This action cannot be undone.
            </div>
            <div class="col-12 delete-action">
              <button type="button" (click)="onDeleteAccount()" class="thm-2 danger-button">Delete my account</button>
            </div>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</div>
