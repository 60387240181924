import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { FreeReportService } from "../../free-report.service";
import clevertap from "clevertap-web-sdk";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-adverse-childhood-experiences-test-results",
  templateUrl: "./adverse-childhood-experiences-test-results.component.html",
  styleUrls: ["./adverse-childhood-experiences-test-results.component.css"],
})
export class AdverseChildhoodExperiencesTestResultsComponent implements OnInit {
  aceFinal = "";
  aceScore;
  itemId;
  subScore1 = 0;
  subScore2 = 0;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private viewPortScroller: ViewportScroller
  ) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.aceScore = res["report"]["reportDetails"]["score"];
        let answersArray = Object.values(res["report"]["reportDetails"]["answers"]);
        this.subScore1 = Number(answersArray[0]) + Number(answersArray[1]) + Number(answersArray[2]) + Number(answersArray[3]) + Number(answersArray[4])
        this.subScore2 = Number(answersArray[5]) + Number(answersArray[6]) + Number(answersArray[7]) + Number(answersArray[8]) + Number(answersArray[9])
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "ACE",
            "Score" : this.aceScore
          })
        }
      });
    });
  }
  reportData() {
    this.confirmationService
      .confirm(
        "Congratulations! Your free report will be sent to your mail address.",
        "",
        "Ok",
        "close"
      )
      .subscribe((res) => {
        if (res) {
        }
      });
  }

  
  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
