<div class="modal-content modal-lg">
  <div class="modal-header" style="padding: 16px 27px">
    <div class="modal-title pull-left">
      <div class="modal-title-main">{{ title }}</div>
      <div class="selected-date">
        {{ selectedDate | date: "EEEE, MMMM d, y" }}
      </div>
    </div>

    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="onSuccessCloseModal('yes')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="formRound">
      <form [formGroup]="slotsBookingForm" name="slotsBooking">
        <div class="container">
          <!-- <div *ngFor="let slot of slotsArray ">
            {{slot.type}}
            
          </div> -->
          <div class="row" style="padding: 0px 0px">
            <h6 style="margin-left: 12px; margin-bottom: 0px; margin-top: 4px">
              Morning
            </h6>
            <div
              class="col-12"
              style="
                display: flex;
                flex-direction: row;
                display: flex;
                padding: 0px;
                justify-content: center;
                align-items: space-between;
              "
            >
              <div
                [ngClass]="getChipClass('00:00')"
                title="{{ getChipTitle('00:00') }}"
                (click)="onChipClick('00:00')"
              >
                12:00 - 01:00
              </div>
              <div
                [ngClass]="getChipClass('01:00')"
                title="{{ getChipTitle('01:00') }}"
                (click)="onChipClick('01:00')"
              >
                01:00 - 02:00
              </div>
              <div
                [ngClass]="getChipClass('02:00')"
                title="{{ getChipTitle('02:00') }}"
                (click)="onChipClick('02:00')"
              >
                02:00 - 03:00
              </div>
              <div
                [ngClass]="getChipClass('03:00')"
                title="{{ getChipTitle('03:00') }}"
                (click)="onChipClick('03:00')"
              >
                03:00 - 04:00
              </div>
            </div>
            <div
              class="col-12"
              style="
                display: flex;
                flex-direction: row;
                display: flex;
                padding: 0px;
                justify-content: center;
                align-items: space-between;
              "
            >
              <div
                [ngClass]="getChipClass('04:00')"
                title="{{ getChipTitle('04:00') }}"
                (click)="onChipClick('04:00')"
              >
                04:00 - 05:00
              </div>
              <div
                [ngClass]="getChipClass('05:00')"
                title="{{ getChipTitle('05:00') }}"
                (click)="onChipClick('05:00')"
              >
                05:00 - 06:00
              </div>
              <div
                [ngClass]="getChipClass('06:00')"
                title="{{ getChipTitle('06:00') }}"
                (click)="onChipClick('06:00')"
              >
                06:00 - 07:00
              </div>
              <div
                [ngClass]="getChipClass('07:00')"
                title="{{ getChipTitle('07:00') }}"
                (click)="onChipClick('07:00')"
              >
                07:00 - 08:00
              </div>
            </div>
          </div>

          <div class="row" style="padding: 0px 0px">
            <h6 style="margin-left: 12px; margin-bottom: 0px; margin-top: 4px">
              Day
            </h6>
            <div
              class="col-12"
              style="
                display: flex;
                flex-direction: row;
                display: flex;
                padding: 0px;
                justify-content: center;
                align-items: space-between;
              "
            >
              <div
                [ngClass]="getChipClass('08:00')"
                title="{{ getChipTitle('08:00') }}"
                (click)="onChipClick('08:00')"
              >
                08:00 - 09:00
              </div>
              <div
                [ngClass]="getChipClass('09:00')"
                title="{{ getChipTitle('09:00') }}"
                (click)="onChipClick('09:00')"
              >
                09:00 - 10:00
              </div>
              <div
                [ngClass]="getChipClass('10:00')"
                title="{{ getChipTitle('10:00') }}"
                (click)="onChipClick('10:00')"
              >
                10:00 - 11:00
              </div>
              <div
                [ngClass]="getChipClass('11:00')"
                title="{{ getChipTitle('11:00') }}"
                (click)="onChipClick('11:00')"
              >
                11:00 - 12:00
              </div>
            </div>
            <div
              class="col-12"
              style="
                display: flex;
                flex-direction: row;
                display: flex;
                padding: 0px;
                justify-content: center;
                align-items: space-between;
              "
            >
              <div
                [ngClass]="getChipClass('12:00')"
                title="{{ getChipTitle('12:00') }}"
                (click)="onChipClick('12:00')"
              >
                12:00 - 13:00
              </div>
              <div
                [ngClass]="getChipClass('13:00')"
                title="{{ getChipTitle('13:00') }}"
                (click)="onChipClick('13:00')"
              >
                13:00 - 14:00
              </div>
              <div
                [ngClass]="getChipClass('14:00')"
                title="{{ getChipTitle('14:00') }}"
                (click)="onChipClick('14:00')"
              >
                14:00 - 15:00
              </div>
              <div
                [ngClass]="getChipClass('15:00')"
                title="{{ getChipTitle('15:00') }}"
                (click)="onChipClick('15:00')"
              >
                15:00 - 16:00
              </div>
            </div>
          </div>

          <div class="row" style="padding: 0px 0px">
            <h6 style="margin-left: 12px; margin-bottom: 0px; margin-top: 4px">
              Evening
            </h6>
            <div
              class="col-12"
              style="
                display: flex;
                flex-direction: row;
                display: flex;
                padding: 0px;
                justify-content: center;
                align-items: space-between;
              "
            >
              <div
                [ngClass]="getChipClass('16:00')"
                title="{{ getChipTitle('16:00') }}"
                (click)="onChipClick('16:00')"
              >
                16:00 - 17:00
              </div>
              <div
                [ngClass]="getChipClass('17:00')"
                title="{{ getChipTitle('17:00') }}"
                (click)="onChipClick('17:00')"
              >
                17:00 - 18:00
              </div>
              <div
                [ngClass]="getChipClass('18:00')"
                title="{{ getChipTitle('18:00') }}"
                (click)="onChipClick('18:00')"
              >
                18:00 - 19:00
              </div>
              <div
                [ngClass]="getChipClass('19:00')"
                title="{{ getChipTitle('19:00') }}"
                (click)="onChipClick('19:00')"
              >
                19:00 - 20:00
              </div>
            </div>
            <div
              class="col-12"
              style="
                display: flex;
                flex-direction: row;
                display: flex;
                padding: 0px;
                justify-content: center;
                align-items: space-between;
              "
            >
              <div
                [ngClass]="getChipClass('20:00')"
                (click)="onChipClick('20:00')"
              >
                20:00 - 21:00
              </div>
              <div
                [ngClass]="getChipClass('21:00')"
                title="{{ getChipTitle('21:00') }}"
                (click)="onChipClick('21:00')"
              >
                21:00 - 22:00
              </div>
              <div
                [ngClass]="getChipClass('22:00')"
                (click)="onChipClick('22:00')"
              >
                22:00 - 23:00
              </div>
              <div
                [ngClass]="getChipClass('23:00')"
                (click)="onChipClick('23:00')"
              >
                23:00 - 24:00
              </div>
            </div>
          </div>
          <div
            class="eachOptionsDivTag extraCssforcategory"
            style="margin-top: 20px"
          >
            <div class="flex Slot-booking-data">
              <div class="col-md-3 col-sm-12 col-xs-12 col-lg-3">
                <div class="chip-indication-inner-container">
                  <div class="chip-indication-color">
                    <div class="chip-booked"></div>
                  </div>
                  <div class="chip-indication-title">Booked </div>
                </div>
              </div>
              <div
                class="col-md-3 col-sm-12 col-xs-12 col-lg-3"
              >
                <div class="chip-indication-inner-container">
                  <div class="chip-indication-color">
                    <div class="chip-active"></div>
                  </div>
                  <div class="chip-indication-title">Available </div>
                </div>
              </div>
              <div
                class="col-md-3 col-sm-12 col-xs-12 col-lg-3"
              >
                <div class="chip-indication-inner-container">
                  <div class="chip-indication-color">
                    <div class="chip-not-active"></div>
                  </div>
                  <div class="chip-indication-title" >Unused </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="eachOptionsDivTag extraCssforcategory"
            style="margin-top: 20px"
          >
            <div>
              <div
                style="
                  display: flex;
                  align-items: baseline;
                  padding-bottom: 20px;
                "
              >
                <label
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  "
                >
                  <input
                    type="checkbox"
                    id="isRepeatOptions"
                    formControlName="isRepeatOptions"
                  />
                  <span style="padding-left: 10px">
                    Set as Recurring Slots
                  </span>
                </label>
              </div>
            </div>
            <section *ngIf="slotsBookingForm.value.isRepeatOptions">
              <div class="row" style="padding: 0px 0px">
                <div class="col-md-12 form-group">
                  <h6>Repeat type</h6>
                  <ng-select
                    class="form-control"
                    formControlName="repeatType"
                    placeholder="Repeat type"
                  >
                    <ng-option
                      *ngFor="let repeatItem of repeatTillItems"
                      value="{{ repeatItem.type }}"
                      >{{ repeatItem.name }}</ng-option
                    >
                  </ng-select>
                  <div
                    *ngIf="
                      slotsBookingForm.controls.repeatType.errors?.required &&
                      slotsBookingForm.controls.repeatType.touched
                    "
                    class="errorContainer"
                  >
                    <p>Please select repeat type</p>
                  </div>
                </div>
              </div>
              <div class="row" style="padding: 0px 0px">
                <div class="col-md-12 form-group">
                  <h6>Recurrence slot till date</h6>
                  <div>
                    <input
                      type="text"
                      placeholder="till date"
                      class="form-control"
                      bsDatepicker
                      [bsConfig]="{
                        dateInputFormat:'DD MMM Y',
                        isAnimated: true,
                        adaptivePosition: true,
                        containerClass: 'calender-slot-theme'
                      }"
                      formControlName="repeatUntilDate"
                      placeholder="Repeat until date"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                    />
                  </div>
                  <div
                    *ngIf="
                      slotsBookingForm.controls.repeatUntilDate.errors
                        ?.required &&
                      slotsBookingForm.controls.repeatUntilDate.touched
                    "
                    class="errorContainer"
                  >
                    <p>Please select Date</p>
                  </div>
                </div>
              </div>
            </section>
            <!-- [disabled]="(slotsBookingForm.valid && slotsBookingForm.value.repeatType=='NO_RECURRENCE')||(slotsBookingForm.valid && slotsBookingForm.value.repeatType!=='NO_RECURRENCE' &&
           slotsBookingForm.controls.repeatUntilDate.valid)
           "  -->
          </div>
        </div>

        <div class="btnHolder" style="padding-bottom: 20px">
          <button
            *ngIf="!isLoading"
            class="btn btn-accept"
            [disabled]="
              slotsBookingForm.valid &&
              slotsBookingForm.value.repeatType !== 'NO_RECURRENCE' &&
              slotsBookingForm.value.repeatUntilDate
                ? false
                : slotsBookingForm.valid &&
                  slotsBookingForm.value.repeatType == 'NO_RECURRENCE'
                ? false
                : true
            "
            [ngStyle]="{
              'background-color': slotsBookingForm.invalid ? '#b9b9b9' : ''
            }"
            (click)="onSubmit()"
          >
            Save
          </button>
          <div
            *ngIf="isLoading"
            class="float-right spinner-border text-secondary"
            style="margin: 5px 10px"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <!-- <ng-lottie
              *ngIf="isLoading"
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
          <button class="btn btn-cancel-transparent" (click)="closeModal()">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
