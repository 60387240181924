import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { COMMON_DATA } from "src/app/shared/common";
import { AvailableSlotsService } from "../available-slots.service";
import { TherapistProfileService } from "../therapist-profile.service";
import * as momentTz from "moment-timezone";
import * as moment from "moment";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";

@Component({
  selector: "app-recurring-schedule",
  templateUrl: "./recurring-schedule.component.html",
  styleUrls: ["./recurring-schedule.component.css"],
})
export class RecurringScheduleComponent implements OnInit {
  TimeZone = COMMON_DATA.TimeZone;
  timeZoneForm: FormGroup;
  timeSlots = [];
  weekDays = [];
  recurringData = null;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private slotsService: AvailableSlotsService,
    private profileService: TherapistProfileService,
    private alertService: BootstrapAlertService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.profileService.getProfile().subscribe((res) => {
      if (res["TimeZone"]) {
        this.timeZoneForm.setValue({
          TimeZone: res["TimeZone"],
        });
      } else {
        let timeZone = momentTz.tz.guess();
        let zoneIndex = this.TimeZone.findIndex((tz) => tz.tzCode === timeZone);
        if (zoneIndex !== -1) {
          this.timeZoneForm.setValue({
            TimeZone: this.TimeZone[zoneIndex],
          });
        }
      }
    });
    this.slotsService.getRecurringSchedule().subscribe((res) => {
      this.recurringData = res["recurringSchedules"];
      let updatedRecurringData = [
        { day: "Sunday", slots: [] },
        { day: "Monday", slots: [] },
        { day: "Tuesday", slots: [] },
        { day: "Wednesday", slots: [] },
        { day: "Thursday", slots: [] },
        { day: "Friday", slots: [] },
        { day: "Saturday", slots: [] },
      ];
      let currentDate = moment().utc();
      let weekStart = currentDate.clone().startOf("week");
      this.recurringData.forEach((week, index) => {
        let dayObj = weekStart.clone().add(index, "days");
        week.slots.forEach((slot) => {
          let slotObj = dayObj.clone().set({
            hour: slot.split(":")[0],
            minute: slot.split(":")[1],
          });
          let tzStr = momentTz
            .tz(slotObj, this.timeZoneForm.value.TimeZone.tzCode)
            .format("dddd HH:mm");
          let slotNewIndex = updatedRecurringData.findIndex(
            (urd) => urd.day === tzStr.split(" ")[0]
          );
          updatedRecurringData[slotNewIndex].slots.push(tzStr.split(" ")[1]);
        });
      });
      this.recurringData = updatedRecurringData;
      this.recurringData.forEach((recurring) => {
        recurring.slots.forEach((slot) => {
          let dayIndex = this.weekDays.findIndex(
            (day) => day.day === recurring.day
          );
          let slotIndex = this.weekDays[dayIndex]["timeSlots"].findIndex(
            (nSlot) => nSlot.slot === slot
          );
          if (slotIndex > -1) {
            this.weekDays[dayIndex]["timeSlots"][slotIndex]["selected"] = true;
          }
        });
      });
    });
  }

  initForm() {
    this.timeZoneForm = new FormGroup({
      TimeZone: new FormControl(""),
    });
    for (let i = 0; i < 24; i++) {
      this.timeSlots.push({
        startTime: (i > 12 ? i - 12 : i) + (i > 11 ? " PM" : " AM"),
        endTime:
          (i + 1 > 12 ? i + 1 - 12 : i + 1) + (i + 1 > 11 ? " PM" : " AM"),
        hours: i * 100,
        selected: false,
        slot: i < 9 ? "0" + String(i) + ":00" : i + ":00",
      });
    }
    this.weekDays = [
      {
        day: "Sunday",
        index: 0,
        timeSlots: JSON.parse(JSON.stringify(this.timeSlots)),
      },
      {
        day: "Monday",
        index: 1,
        timeSlots: JSON.parse(JSON.stringify(this.timeSlots)),
      },
      {
        day: "Tuesday",
        index: 2,
        timeSlots: JSON.parse(JSON.stringify(this.timeSlots)),
      },
      {
        day: "Wednesday",
        index: 3,
        timeSlots: JSON.parse(JSON.stringify(this.timeSlots)),
      },
      {
        day: "Thursday",
        index: 4,
        timeSlots: JSON.parse(JSON.stringify(this.timeSlots)),
      },
      {
        day: "Friday",
        index: 5,
        timeSlots: JSON.parse(JSON.stringify(this.timeSlots)),
      },
      {
        day: "Saturday",
        index: 6,
        timeSlots: JSON.parse(JSON.stringify(this.timeSlots)),
      },
    ];
  }

  updateSchedule(dayIndex, slotIndex) {
    this.weekDays[dayIndex].timeSlots[slotIndex].selected =
      !this.weekDays[dayIndex].timeSlots[slotIndex].selected;
  }

  saveSchedule() {
    this.confirmationService
      .confirm(
        "Are you sure?",
        "This will update your schedule.",
        "Proceed",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          let recObj = JSON.parse(JSON.stringify(this.weekDays));
          recObj.forEach((day) => {
            // day.slots = day.timeSlots.filter((timeSlot) => timeSlot.selected === true);
            day.slots = day.timeSlots.reduce((filtered, data) => {
              if (data.selected) {
                filtered.push(data.slot);
              }
              return filtered;
            }, []);
            delete day.timeSlots;
          });
          let slotDates = [];
          let currentDate = moment();
          let weekStart = currentDate.clone().startOf("week");
          recObj.forEach((week) => {
            slotDates.push({
              day: week.day,
              slots: [],
            });
            let dayIndex = this.weekDays.findIndex(
              (weeks) => weeks.day === week.day
            );
            let dayObj = weekStart.clone().add(dayIndex, "days");
            week.slots.forEach((slot) => {
              let slotStr = slot.split(":");
              let slotDateObj = dayObj.clone();
              slotDateObj.set({
                hour: slotStr[0],
              });
              let slotUtcDate = moment.utc(slotDateObj);
              let slotUtcStr = slotUtcDate.format("dddd HH:mm");
              let slotIndex = slotDates.findIndex(
                (slotDate) => slotDate.day === slotUtcStr.split(" ")[0]
              );
              if (slotIndex === -1) {
                slotDates.push({
                  day: slotUtcStr.split(" ")[0],
                  slots: [slotUtcStr.split(" ")[1]],
                });
              } else {
                slotDates[slotIndex].slots.push(slotUtcStr.split(" ")[1]);
              }
            });
          });
          let requestObj = {
            startDate: moment.utc().format("DD_MM_YYYY"),
            slots: slotDates,
          };
          this.slotsService
            .createRecurringSchedule(requestObj)
            .subscribe((res) => {
              new BootstrapAlert(
                "Recurring Schedule created successfully!",
                "alert-success"
              );
            });
        }
      });
  }
}
