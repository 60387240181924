import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistAuthService } from "src/app/therapist-auth/therapist-auth.service";
import {NotificationService } from "src/app/therapist/notification.service"

@Component({
  selector: "app-therapist-header",
  templateUrl: "./therapist-header.component.html",
  styleUrls: ["./therapist-header.component.css"],
})
export class TherapistHeaderComponent implements OnInit {
  newNotifications = 0;
  constructor(
    private authService: TherapistAuthService,
    private sideNavService: SideNavBarService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.notificationService.getUnreadNotifications().subscribe((data) => {
      this.newNotifications = data["count"];
    })
  }
  logOut() {
    this.authService.logout();
  }
  openSideNav() {
    this.sideNavService.opensidenav();
  }
}
