<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container mt-3">
    <p class="disclaimerTxt">
      <strong> <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for screening and
      monitoring symptom severity and cannot replace a clinical assessment and
      diagnosis. You are encouraged to share these results with a mental health
      professional for a better understanding.
    </p>
    <div class="report-user mt-3">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user">
      <h3>Congratulations on taking that first step!</h3>
      <p class="mt-2"><strong>Your Result:</strong></p>
      <p>
        Your responses indicate a <span>{{ anxietyFinal }} <span class="scrollTxt"
            (click)="scrollToElem('target')">Detailed Analysis</span> </span>
      </p>
      <p class="mt-3"><strong>Your Score:</strong></p>
      <p>Anxiety Assessment : {{ anxietyScore }}</p>
      <!-- <button type="button" class="btn-report" (click)="reportData()">
        Email Report
      </button> -->
    </div>
  </div>

  <div class="container graph-section mt-3">
    <div>
      <div style="line-height: 21px;">
        <p class="mb-3" *ngIf="anxietyScore >= 0 && anxietyScore <= 4">
          Your results may not be a sign of concern, but read on to find out a more detailed analysis of your score. Felicity is here to assist you in improving your mental well-being. 
        </p>
        <p class="mb-3" *ngIf="anxietyScore >= 5 && anxietyScore <= 15">
          Your results may be slightly concerning, but do not worry too much - Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.
        </p>
        <p class="mb-3" *ngIf="anxietyScore >= 16 && anxietyScore <= 21">
          Seeing the report can be overwhelming, but Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.
        </p>
      </div>

      <div class="report-user">
        <h3>Understanding Your Anxiety Assessment</h3>
      </div>
      <div class="graph-right">
        <img src="../../../assets/images/anxiety-gad-graph.webp" />
      </div>
    </div>
  </div>

  <div class="container mb-2">
    <div class="about-scores report-user">
      <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
      <p class="mb-3">
        The possible range of scores is 0 to 21, 0 indicates ‘no anxiety at all’
        and 21 indicates ‘severely high anxiety’.
      </p>
      <p class="para_scores mb-3" *ngIf="anxietyScore >= 0 && anxietyScore <= 4">
        From the above graph, it is clear that a score of 0 to 4 means minimal
        or negligible symptoms. You should attempt to maintain this score,
        because it is a pleasant state to be in
      </p>
      <p class="para_scores mb-3" *ngIf="anxietyScore >= 5 && anxietyScore <= 9">
        A score of 5 to 9 indicates mild anxiety. You may experience some mild
        symptoms of anxiety depending on your triggers. You can perform
        activities like journaling, meditation, and mindfulness, to keep your
        anxiety at bay. You can always get in touch with our mental health
        professionals if you feel overwhelmed by the situation.
      </p>
      <p class="para_scores mb-3" *ngIf="anxietyScore >= 10 && anxietyScore <= 15">
        A score of 10 to 15 indicates moderate anxiety. You may be experiencing
        symptoms of anxiety at a moderately high level. We recommend that you
        talk to one of our mental health professionals to help you through this.
        We assure to provide you with the best help in this time of need.
      </p>
      <p class="para_scores mb-3" id="target" *ngIf="anxietyScore > 15">
        A score of 16 to 21 indicates severe anxiety. You’ve come this far,
        managing all your symptoms on your own. It would be best for you to get
        in touch with some of the best mental health professions including
        psychiatrists, clinical psychologists, therapists and more. In this
        alarming state of life, Felicity would like to extend support and help
        you get through this with proper treatment.
      </p>
    </div>
  </div>

  <!-- TODO: Add CSS -->
  <div class="container about-scores">
    <div class="report-user">
      <h3 class="">Detailed Analysis</h3>
      <p class="mb-3">
        GAD-7 assesses 2 components - the effect your anxiety has on your
        cognition and emotions; bodily symptoms.
      </p>
      <p class="mb-3">
        <strong>Anxiety can affect your cognition and emotions: </strong>You may
        feel nervous, scared, worried and panicky as a result of. You may also
        experience negative thoughts and feelings, and have a hard time
        concentrating on things that are of importance to you. Your score on
        this factor is {{ bodily }}, where the minimum score is 0 and maximum
        score is 12. Let’s work on getting better and healthier.
      </p>
      <p class="mb-3">
        <strong>Bodily symptoms: </strong>Trembling of hands, sweating, cold
        hands and feet, shaking, increased heartbeats, and chest pain in severe
        cases. Your score on this factor is {{ cognitive }}, where the minimum
        score is 0 and maximum score is 9. Let’s work on getting better and
        healthier.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
  <!-- <div class="disclaimer-section">
      <div class="head-disclaimer">
        <h3>Disclaimer</h3>
        <p>
          This tool should be used for screening and monitoring symptom severity
          and cannot replace a clinical assessment and diagnosis. You are
          encouraged to share these results with a mental health professional.
          Felicity disclaims any liability, loss or risk incurred as a consequence
          , directly or indirectly, from the use of this assessment & it's
          results.
        </p>
      </div>
    </div> -->
</div>