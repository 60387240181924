import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { COMMON_DATA } from "src/app/shared/common";
import { FreeReportService } from "../../free-report.service";
import clevertap from "clevertap-web-sdk";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-adhd-test-results",
  templateUrl: "./adhd-test-results.component.html",
  styleUrls: ["./adhd-test-results.component.css"],
})
export class AdhdTestResultsComponent implements OnInit {
  itemId;
  report;
  highScoreCount = 0;
  inattentiveness = 0;
  hyperactivity = 0;
  adhdScore = 0;
  adhdFinal = "";
  title = "ADHD Analysis";
  type = "PieChart";
  data = [
    ["Inattentive Presentation", this.inattentiveness],
    ["Hyperactive/Impulsive Presentation", this.hyperactivity],
  ];
  options = {};
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private actRoute: ActivatedRoute,
    private freeReportService: FreeReportService,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  answersArray: any[];
  questions = [...COMMON_DATA.adhd_questions, ...COMMON_DATA.adhd_questions1];
  questions1 = COMMON_DATA.adhd_questions1;

  ngOnInit(): void {
    // console.log(this.questions);
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res["report"];
        this.answersArray = Object.values(this.report.reportDetails.answers);
        this.answersArray.forEach((ans) => {
          if (Number(ans) >= 2) {
            this.highScoreCount++;
          }
        });
        this.answersArray.forEach((ans) => {
          this.adhdScore += Number(ans);
        });
        if (this.report["reportType"] == "adhd-adult") {
          this.inattentiveness =
            parseInt(this.answersArray[0], 10) +
            parseInt(this.answersArray[1], 10) +
            parseInt(this.answersArray[2], 10) +
            parseInt(this.answersArray[3], 10) +
            parseInt(this.answersArray[4], 10) +
            parseInt(this.answersArray[5], 10);
          this.hyperactivity =
            parseInt(this.answersArray[6], 10) +
            parseInt(this.answersArray[7], 10) +
            parseInt(this.answersArray[8], 10) +
            parseInt(this.answersArray[9], 10) +
            parseInt(this.answersArray[10], 10) +
            parseInt(this.answersArray[11], 10) +
            parseInt(this.answersArray[12], 10) +
            parseInt(this.answersArray[13], 10) +
            parseInt(this.answersArray[14], 10) +
            parseInt(this.answersArray[15], 10) +
            parseInt(this.answersArray[16], 10) +
            parseInt(this.answersArray[17], 10);
          this.adhdfinalcal();
          this.data = [
            ["Inattentive Presentation", this.inattentiveness],
            ["Hyperactive/Impulsive Presentation", this.hyperactivity],
          ];
        }
        if (clevertap) {
          if (this.report["reportType"] == "adhd") {
            clevertap.event.push("Free Report Ended", {
              "Report Name": "ADHD",
              Score: this.report.reportDetails.score,
            });
          }
          if (this.report["reportType"] == "adhd-adult") {
            clevertap.event.push("Free Report Ended", {
              "Report Name": "Adult ADHD",
              Score: this.report.reportDetails.score,
            });
          }
        }
      });
    });
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  bookConsultation() {}

  reportData() {
    if (this.report["reportType"] == "adhd") {
      let reqObj = {
        id: this.itemId,
        reportType: "adhd",
      };
      this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
        if (res) {
          this.alertService.alert(
            new BootstrapAlert(
              "You will shorly recieve free report in your email",
              "alert-success"
            )
          );
        }
      });
    } else {
      let reqObj = {
        id: this.itemId,
        reportType: "adhd-adult",
      };
      this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
        if (res) {
          this.alertService.alert(
            new BootstrapAlert(
              "You will shorly recieve free report in your email",
              "alert-success"
            )
          );
        }
      });
    }
  }

  adhdfinalcal() {
    if (this.adhdScore >= 0 && this.adhdScore <= 16) {
      this.adhdFinal = "unlikely to have ADHD.";
    } else if (this.adhdScore >= 17 && this.adhdScore <= 23) {
      this.adhdFinal = "likely to have ADHD.";
    } else if (this.adhdScore >= 24) {
      this.adhdFinal = "highly likely to have ADHD .";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
