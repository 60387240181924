import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  intervalObj: NodeJS.Timeout;

  constructor(private router: Router, private actRoute: ActivatedRoute, private authService: AuthService) { }
  isNew = false;
  isLoading = true;
  isLoggedIn = true;
  countDown = 30;

  ngOnInit(): void {
    this.actRoute.queryParamMap.subscribe((param) => {
      this.isNew = param.get("act") === 'true' ? true : false;
      this.isLoading = false;
    })
    this.isLoggedIn = this.authService.getIsAuth()
    this.intervalObj = setInterval(() => {
      this.updateCountDown();
    },1000)
  }

  updateCountDown() {
    this.countDown--;
    if(this.countDown === 1) {

      clearInterval(this.intervalObj)
      if(!this.isNew && !this.isLoggedIn){
        this.router.navigate(["login"])
      } else if(this.isLoggedIn){
        this.router.navigate(["user-dashboard"])
      }
    }
  }

}
