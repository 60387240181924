import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Client as ConversationsClient } from "@twilio/conversations";
import { FormControl, Validators } from "@angular/forms";
import { TherapistProfileService } from "../therapist-profile.service";
import { TherapistChatService } from "../therapist-chat.service";
import { Conversation } from "@twilio/conversations/lib/conversation";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import * as moment from "moment";
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { CustomValidator } from "./CustomValidator";
import { data } from "jquery";

@Component({
  selector: "app-my-chats",
  templateUrl: "./my-chats.component.html",
  styleUrls: ["./my-chats.component.css"],
  // encapsulation: ViewEncapsulation.None,
})
export class MyChatsComponent implements OnInit, OnDestroy {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  faPlay = faPlay;
  userData;
  token;
  onUploadAttachmentAPISERVICE
  isLoading = true;
  viewFullSizeCircle = true;
  isChatMessageLoading = false;
  chatShimmer = true;
  attachmentShimmer = true;
  RightProfileView = true;
  isConversationEmpty = false;
  isShowMoreMessageLoader = false
  twilioChatToken = "";
  // isShowMoreMessageLoader = false;
  conversationsClient: ConversationsClient;
  conversation: Conversation;
  conversationsList = [];
  chatThreads = [];
  selectedChatIndex: number = 0;
  messageToSend = new FormControl("", [Validators.required, CustomValidator.cannotContainSpace]);
  messages = [];
  isDownloading = {};
  searchArray = [];
  firstRunFlag: Boolean = false;
  selectedUserData = null;
  selectedAttachment: File = null;
  imageUrl = environment.apiUrl;
  userName = "";
  isThreadsEmpty: boolean = true;
  isClientTyping = false;
  isChatOpen = false;
  isProfileOpen = false;
  isChatListOpen = true;
  seeAllAttachments = false;
  progress = 0;
  isUploadingAttachment = false
  isSearch: boolean = false;
  currentConMessagePaginator;
  mydate: String
  showModal: boolean;
  showModalImg: String;
  skimmerLoader: boolean = true;
  imgUrl = environment.apiUrl;
  isSendingMessage = false;
  isShowHighQualityImageLoader: boolean = false;
  newMessages = [];
  isShowMoreMessageButtonEnabled = true
  selectedThreadData = null;
  newMessage = new FormControl("", Validators.required);
  skip = 1;
  perPage = 20;
  isPrev = false;
  downloadFilePath: String;
  downloadFileName: String;
  // name array
  SearchUserName = [];
  remoteTypingTimer = null;
  isRemoteTyping = false;
  toggleClass(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      event.srcElement.classList.remove('show');
    } else {
      event.srcElement.classList.add('show');
    }
  }

  showHighQualityImage(key, filePath, fileName) {
    this.isShowHighQualityImageLoader = true
    this.showModal = true; // Show-Hide Modal Check
    this.downloadFilePath = filePath;
    this.downloadFileName = fileName;
    this.isProfileOpen = false
    this.therapistChatService.getDownloadUrl(key).subscribe((data) => {
      this.showModalImg = data["url"];
      this.isShowHighQualityImageLoader = false
    }, (err) => {
      this.isShowHighQualityImageLoader = false
      this.showModalImg = `${this.imgUrl}/thumbnails/${key.split('/')[1]}`;
    })

  }
  show(imgUrl, filePath, fileName) {
    this.showModal = true; // Show-Hide Modal Check
    this.showModalImg = imgUrl;
    this.downloadFilePath = filePath;
    this.downloadFileName = fileName;
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }
  constructor(
    private therapistProfileService: TherapistProfileService,
    private therapistChatService: TherapistChatService,
    private http: HttpClient,
    private alertService: BootstrapAlertService
  ) { }


  UserLoader(length: number): Array<any> {
    if (length >= 0) {
      return new Array(length);
    }
  }


  ngOnInit(): void {
    this.therapistChatService.initializeSocket();
    // this.therapistChatService.joinRoom()
    this.mydate = moment().locale('en-in').format("DD_MMM_YYYY HH: mm");
    this.token = localStorage.getItem("token");
    this.therapistProfileService.getProfile().subscribe((data) => {
      this.userData = data;
    });
    this.therapistChatService.getChats("").subscribe((result) => {
      let sortedChannels = this.doSortChatChannels(result.channels)
      this.chatThreads = sortedChannels;
      // this.therapistChatService.subsribeRooms(this.chatThreads)
      let array = this.chatThreads.map(room => room.channelName)
      this.therapistChatService.joinMultipleRoom(array)
      // this.chatThreads.map(room => {
      //   //   console.log(room.channelName)
      //   this.therapistChatService.joinRoom(room.channelName)
      // })
      this.therapistChatService.getEventsObject().subscribe((cb) => {

        // @ts-ignore
        let { event, data } = cb
        if (data.eventType === 'typing') {
          if (this.selectedThreadData.channelName === data.channelName && data.sentBy !== 'therapist') {
            this.setRemoteTyping(data.channelName);
          }
        }
        else if (data.eventType === 'message') {

          this.doHandlePushMessageToChat(data)
        } else if (data.eventType === 'status' && data.sentBy !== 'therapist') {
          this.onStatusUpdate(data)
        }
      })
      this.chatThreads.forEach((thread) => {
        if (thread?.lastMessageAt) {
          thread.lastMessageAt = moment.utc(
            thread?.lastMessageAt,
            "DD_MMM_YYYY HH:mm"
          );
        }
        if (thread.attachments) {
          thread.attachments = thread.attachments.map((attach, index) => {
            let isShow = true;
            if (index > 5) {
              isShow = false;
            }
            let newObj = { ...attach, isShown: isShow };
            return newObj;
          });
        }
      });
      if (this.chatThreads.length > 0) {
        this.isThreadsEmpty = false;
        this.chatShimmer = false;
        this.isLoading = false;
        this.searchArray = this.chatThreads
        this.scrollToBottom();
        if (this.chatThreads[0].channelName) {
          this.selectedThreadData = this.chatThreads[0];
          this.getMessages(this.chatThreads[0].channelName)
          this.scrollToBottom();
        }
      } else {
        this.isThreadsEmpty = true;
        this.isLoading = false;
        this.RightProfileView = false;
        this.attachmentShimmer = false;
        this.chatShimmer = false;
      }
      this.therapistChatService.getChatToken().subscribe((result) => {
        this.twilioChatToken = result.token;
        // this.initChatClient(this.twilioChatToken);
      });
    });
  }
  doSortChatChannels(chanels = this.chatThreads) {
    if (chanels) {
      let sortedChannels = chanels.sort((a, b) => {
        if (!a?.lastMessageAt) {
          return 1;
        }
        if (!b?.lastMessageAt) {
          return -1;
        }
        return (
          (moment(b?.lastMessageAt, "DD_MMM_YYYY HH:mm")["_d"]) -
          moment(a?.lastMessageAt, "DD_MMM_YYYY HH:mm")["_d"]
        );
      });

      return sortedChannels
    }
  }
  doGetChannelIndex(channelName) {
    if (this.chatThreads?.length > 0) {
      let index = this.chatThreads && this.chatThreads.findIndex((data) => data.channelName == channelName);

      if (index !== -1) {
        return index;
      }
      return false
    }
    return false
  }
  async doUpdateLastMessage(channelName, message) {
    if (this.chatThreads[this.selectedChatIndex].channelName == channelName) {
      let lastTimeStr = moment().format("DD_MMM_YYYY HH:mm");
      this.chatThreads[this.selectedChatIndex]["lastMessageAt"] =
        moment(lastTimeStr, "DD_MMM_YYYY HH:mm");
      if (message.message) {
        this.chatThreads[this.selectedChatIndex]["lastMessage"]["message"] = message.message;
        this.chatThreads[this.selectedChatIndex]["lastMessage"]["attachment"] = null;
      } if (message.attachment) {
        this.chatThreads[this.selectedChatIndex]["lastMessage"]["attachment"] = message.attachment;
        this.chatThreads[this.selectedChatIndex]["lastMessage"]["message"] = null;
      }
      let selectedChatIndexData = this.chatThreads[this.selectedChatIndex]
      this.chatThreads = await this.doSortChatChannels()
      // current time format doesnt have seconds so after sorting swapping current selected channel to be the latest one
      let newSelectedChatIndex = await this.doGetChannelIndex(selectedChatIndexData.channelName)
      if (newSelectedChatIndex !== false) {
        if (newSelectedChatIndex > 0) {
          let element = this.chatThreads.splice(newSelectedChatIndex, 1)
          this.chatThreads.unshift(...element)
        }
        this.selectedChatIndex = 0
      }
      this.scrollToBottom();
    }
    else {
      let index = await this.doGetChannelIndex(channelName)
      if (index !== false && index !== -1) {
        let lastTimeStr = moment().format("DD_MMM_YYYY HH:mm");
        this.chatThreads[index]["lastMessageAt"] =
          moment(lastTimeStr, "DD_MMM_YYYY HH:mm")
        if (message.message) {
          this.chatThreads[index]["lastMessage"] = {
            ...this.chatThreads[index]["lastMessage"],
            isUnread: true
          }
          this.chatThreads[index]["lastMessage"]["message"] = message.message;
          this.chatThreads[index]["lastMessage"]["attachment"] = null;
        } if (message.attachment) {

          this.chatThreads[index]["lastMessage"] = {
            ...this.chatThreads[index]["lastMessage"],
            isUnread: true
          }
          this.chatThreads[index]["lastMessage"]["attachment"] = message.attachment;
          this.chatThreads[index]["lastMessage"]["message"] = null;
        }

        let currentChatIndexData = this.chatThreads[index]
        this.chatThreads = await this.doSortChatChannels()
        let newSelectedChatIndex = await this.doGetChannelIndex(currentChatIndexData.channelName)
        if (newSelectedChatIndex && newSelectedChatIndex > 0) {
          let temp = this.chatThreads[newSelectedChatIndex]
          this.chatThreads[newSelectedChatIndex] = this.chatThreads[0]
          this.chatThreads[0] = temp
        }
      }
    }
  }

  doHandlePushMessageToChat(data) {

    if (this.selectedThreadData.channelName == data.channelName) {
      // current active chat
      this.newMessages.push({
        sentBy: data.sentBy,
        message: data.message ? data.message : null,
        _id: data.messageId,
        time: moment().format('HH:mm'),
        attachment: data.attachment ? data.attachment : null
      })
      let newLastMessage = {
        attachment: data.attachment ? data.attachment : null,
        message: data.message ? data.message : null,
      }
      this.doUpdateLastMessage(data.channelName, newLastMessage)

      setTimeout(() => {
        this.scrollToBottom();
      }, 500)
    }
    else {
      // unread other chats message
      let newLastMessage = {
        attachment: data.attachment ? data.attachment : null,
        message: data.message ? data.message : null,
      }
      this.doUpdateLastMessage(data.channelName, newLastMessage)
    }


  }
  ngOnDestroy() {
    this.therapistChatService.disconnectSocket();
  }

  getMessages(channelId, isLoadMore = false) {
    // console.log('channel id', channelId)
    if (isLoadMore) {
      this.isShowMoreMessageLoader = true
    }
    else {
      this.isChatMessageLoading = true
    }

    this.therapistChatService.getMessages(channelId, this.skip, this.perPage).subscribe((res) => {
      if (res['messages'] && res['messages'].length > 0) {
        let newMessageArray = [];
        res['messages'].map((message) => {
          let currUtc = moment.utc()
          let messageDate = moment.utc(message.createdAt)
          let time = ''
          if (currUtc.isSame(messageDate, 'day')) {
            time = moment(message.createdAt).format('HH:mm')
          } else {
            time = messageDate.fromNow();
          }
          newMessageArray.push({
            ...message,
            time: time,
          })

        })
        if (isLoadMore) {
          this.isShowMoreMessageLoader = false
          if (this.selectedThreadData.channelName == res['channelName']) {
            this.newMessages = [...newMessageArray.reverse(), ...this.newMessages]
          }

        }
        else {

          if (this.selectedThreadData.channelName == res['channelName']) {
            this.newMessages = []
            this.newMessages.unshift(...newMessageArray.reverse())
            this.scrollToBottom();
          }
          this.isChatMessageLoading = false
          setTimeout(() => {
            this.scrollToBottom();
          }, 20)
        }
        if (this.newMessages?.length >= res['totalCount']) {
          this.isShowMoreMessageButtonEnabled = false
        }
        // this.scrollToBottom();
      }
    })
  }
  doShowMoreMessage() {
    this.skip += 1
    this.getMessages(this.selectedThreadData.channelName, true)
  }


  scrollOnImageLoad(index) {
    this.scrollToBottom();
  }

  sendNewMessage() {
    if (this.selectedAttachment === null) {
      this.therapistChatService.sendMessage({
        channelName: this.selectedThreadData.channelName,
        message: this.newMessage.value,
        eventType: "message"
      }, (cb) => {

        if (cb.status === "ok") {
          this.scrollToBottom();
        } else {

        }
      })
      // this.therapistChatService.sendNewMessage({
      //   channelName: this.selectedThreadData.channelName,
      //   message: this.newMessage.value
      // }).subscribe((res) => {
      //   this.newMessages.unshift({
      //     sentBy: "user",
      //     message: res["message"],
      //     _id: res["messageId"],
      //     time: moment().format('HH:mm')
      //   })
      // })
    } else {
      const data = new FormData();
      data.append("upload", this.selectedAttachment);
      data.append(
        "channelName",
        this.chatThreads[this.selectedChatIndex].channelName
      );
      data.append("fileName", this.selectedAttachment.name);
      data.append("size", this.formatBytes(this.selectedAttachment.size))
      this.isUploadingAttachment = true
      this.onUploadAttachmentAPISERVICE = this.therapistChatService.sendAttachment(data, () => errorCallback()).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            var eventTotal = event.total ? event.total : 0;
            this.progress = Math.round(event.loaded / eventTotal * 100);
            // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // console.log('Image Upload Successfully!', event.body);
            let res = event.body;
            this.isUploadingAttachment = false
            this.progress = 0
            this.selectedAttachment = null;
            this.therapistChatService.sendMessage({
              channelName: this.selectedThreadData.channelName,
              message: null,
              eventType: "message",
              attachment: res.attachment,
              messageId: res.messageId,
              sentBy: "therapist",
            }, (cb) => {
              // console.log(cb)
            })
        }
      })
      const errorCallback = () => {
        this.isUploadingAttachment = false
        this.progress = 0
        this.selectedAttachment = null;

      }
    }
    this.newMessage.reset();
  }

  changeThread(data, index) {
    this.isChatOpen = true;
    console.log("change chat")
    this.selectedThreadData = data;
    this.selectedChatIndex = index;
    this.newMessages = [];
    if (this.chatThreads[this.selectedChatIndex].lastMessage?.isTyping) {
      this.chatThreads[this.selectedChatIndex].lastMessage.isTyping = false
    }
    if (this.chatThreads[this.selectedChatIndex]['lastMessage']?.isUnread) {
      this.chatThreads[this.selectedChatIndex]['lastMessage'] = {
        ...this.chatThreads[this.selectedChatIndex]['lastMessage'],
        isUnread: false
      };
    }
    this.getMessages(this.selectedThreadData.channelName);
    this.newMessage.reset();
    this.selectedAttachment = null;
  }

  setRemoteTyping(channelName) {
    this.isRemoteTyping = true;
    if (this.chatThreads[this.selectedChatIndex].channelName == channelName) {
      if (!this.chatThreads[this.selectedChatIndex].lastMessage?.isTyping) {
        this.chatThreads[this.selectedChatIndex].lastMessage = {
          ...this.chatThreads[this.selectedChatIndex].lastMessage,
          isTyping: true
        }
      }
      else {
        this.chatThreads[this.selectedChatIndex].lastMessage.isTyping = true
      }
    }

    this.scrollToBottom();
    if (this.remoteTypingTimer !== null) {
      clearTimeout(this.remoteTypingTimer);
    }
    this.remoteTypingTimer = setTimeout(() => {
      this.isRemoteTyping = false;
      if (!this.chatThreads[this.selectedChatIndex].lastMessage?.isTyping) {
        this.chatThreads[this.selectedChatIndex].lastMessage = {
          ...this.chatThreads[this.selectedChatIndex].lastMessage,
          isTyping: false
        }
      }
      else {
        this.chatThreads[this.selectedChatIndex].lastMessage.isTyping = false
      }
    }, 3000)
  }

  userTyping(event) {
    if (event.key) {
      if (event.key !== "Control" && event.key !== "Shift" && event.key !== 'CapsLock' && event.key !== "Alt" && this.newMessage.value !== '') {
        this.therapistChatService.sendTypingEvent({
          channelName: this.selectedThreadData.channelName,
          message: null,
          sentBy: "therapist",
          eventType: "typing"
        }, (data) => {
          // console.log('done', data)
        })
      }
    }
  }

  onStatusUpdate(data) {
    data.roomNames.forEach((roomName) => {
      let index = this.chatThreads.findIndex((chat) => chat.channelName === roomName)
      if (index > -1) {
        if (!this.chatThreads[index].therapistId?.isOnline) {
          this.chatThreads[index].therapistId = {
            ...this.chatThreads[index].therapistId,
            isOnline: data.status === 'online' ? true : false
          }
        }
        else {
          this.chatThreads[index].therapistId.isOnline = data.status === 'online' ? true : false
        }
      }
    })
  }

  async initChatClient(chatToken) {
    this.attachmentShimmer = false;
    this.conversationsClient = await ConversationsClient.create(chatToken);
    this.conversationsClient.on("connectionStateChanged", (state) => {
      // do something on connection state changed  console.log(state);
    });
    this.conversationsClient.on("conversationJoined", async (conversation) => {
      this.conversationsList.push(conversation);
      let that = this;
      let data = await conversation.getParticipants();
      data.forEach(async (participant) => {

        let participantData = await participant.getUser();
        that.updateOnlineOnLoad(participantData)
        participantData.on("updated", function (event) {
          // handleUserUpdate(event.user, event.updateReasons)
          that.handleUserUpdate(event.user)
        });
      });
      this.getMessagesOnload(conversation);
      conversation.on("messageAdded", (m) => {
        let isJSON = this.checkJson(m.state.body);
        if (!isJSON) {
          this.messages.push({
            message: m.state.body,
            isAuthor:
              m.state.author == "Therapist-" + this.userData._id ? true : false,
            time: m.state.timestamp,
            isAttachment: false,
          });
        } else {
          var atchMsgObj = JSON.parse(m.state.body);
          if (atchMsgObj.isAttachment) {
            this.messages.push({
              message: m.state.body,
              isAuthor:
                m.state.author == "Therapist-" + this.userData._id
                  ? true
                  : false,
              time: m.state.timestamp,
              isAttachment: true,
              fileName: atchMsgObj.fileName,
              filePath: atchMsgObj.filePath,
              thumbnailImageKey: atchMsgObj.thumbnailImageKey,
              fileSize: atchMsgObj.fileSize,
              playVideo: false
            });
          }
          else {
            this.messages.push({
              message: m.state.body,
              isAuthor:
                m.state.author == "Therapist-" + this.userData._id ? true : false,
              time: m.state.timestamp,
              isAttachment: false,
            });
          }

        }
        this.scrollToBottom();
      });
      this.RightProfileView = false;
      this.attachmentShimmer = false;
    });
  }

  updateOnlineOnLoad(data) {
    try {
      this.chatThreads.forEach((thread, index) => {
        let role = data.state.identity.split('-')[0]
        if (role === 'user') {
          let userId = data.state.identity.split('-')[1];
          if (userId && thread?.userId !== null && userId === thread.userId?._id) {
            this.chatThreads[index]['isOnline'] = data.state.online === true ? true : false;
          }
          if (this.selectedUserData._id === userId) {
            this.selectedUserData['isOnline'] = data.state.online === true ? true : false;
          }
        }
      })
    }
    catch (e) {
    }
  }

  handleUserUpdate(user) {
    this.chatThreads.forEach(conversation => {
      let userId = user.state.identity.split('-')[1]
      if (conversation.userId) {
        if (conversation.userId._id === userId) {
          conversation.isOnline = user.isOnline
        }
      }
      if (this.selectedUserData._id === userId) {
        this.selectedUserData['isOnline'] = user.isOnline;
      }
    })
  }

  async getMessagesOnload(conversation) {
    this.messages = [];
    var self = this;
    this.selectedUserData = this.chatThreads[0].userId;
    this.selectedUserData['isOnline'] = false;
    if (
      conversation.channelState.uniqueName ==
      this.chatThreads[this.selectedChatIndex].channelName
    ) {
      this.currentConMessagePaginator = await conversation.getMessages();
      this.pushMessages(this.currentConMessagePaginator);
      this.isLoading = false;
      this.chatShimmer = false;
      this.scrollToBottom();
      if (this.messages.length == 0) {
        this.isConversationEmpty = true;
      }
    }
  }

  pushMessages(messagePaginator) {
    messagePaginator.items.forEach((messageObj) => {
      let isJSON = this.checkJson(String(messageObj.state.body));
      if (!isJSON) {
        this.messages.push({
          message: String(messageObj.state.body),
          isAuthor:
            messageObj.state.author == "Therapist-" + this.userData._id
              ? true
              : false,
          time: messageObj.state.timestamp,
          isAttachment: false,
        });
      } else {
        var atchMsgObj = JSON.parse(messageObj.state.body);
        if (atchMsgObj.isAttachment) {
          // let attUrl = this.chatThreads[0]?.attachments && this.chatThreads[0]?.attachments.filter((at) => at.key == atchMsgObj.filePath)

          this.messages.push({
            message: atchMsgObj?.message,
            isAuthor:
              messageObj.state.author == "Therapist-" + this.userData._id
                ? true
                : false,
            time: messageObj.state.timestamp,
            isAttachment: true,
            fileName: atchMsgObj.fileName,
            filePath: atchMsgObj.filePath,
            thumbnailImageKey: atchMsgObj.thumbnailImageKey,
            fileSize: atchMsgObj.fileSize,
            isVideo: atchMsgObj.isVideo ? atchMsgObj.isVideo : false,
            playVideo: false
          });
        } else {
          this.messages.push({
            message: atchMsgObj.message,
            isAuthor:
              messageObj.state.author == "Therapist-" + this.userData._id
                ? true
                : false,
            time: messageObj.state.timestamp,
            isAttachment: false,
          });
        }
      }
    });
  }

  unshiftMessage(messagePaginator) {
    messagePaginator.items = messagePaginator.items.reverse();
    messagePaginator.items.forEach((messageObj) => {
      let isJSON = this.checkJson(messageObj.state.body);
      if (!isJSON) {
        this.messages.unshift({
          message: messageObj.state.body,
          isAuthor:
            messageObj.state.author == "Therapist-" + this.userData._id
              ? true
              : false,
          time: messageObj.state.timestamp,
          isAttachment: false,
        });

        // if (atchMsgObj.isAttachment == true) {
        //   this.messages.unshift({
        //     message: atchMsgObj?.message,
        //     isAuthor:
        //       messageObj.state.author == "Therapist-" + this.userData._id
        //         ? true
        //         : false,
        //     time: messageObj.state.timestamp,
        //     isAttachment: true,
        //     fileName: atchMsgObj.fileName,
        //     filePath: atchMsgObj.filePath,
        //     thumbnailImage: atchMsgObj.thumbnailImage,
        //     fileSize: atchMsgObj.fileSize,
        //     isVideo: atchMsgObj.isVideo ? atchMsgObj.isVideo : false,
        //     playVideo: false,
        //   });
        // }

      } else {
        var atchMsgObj = JSON.parse(messageObj.state.body);
        if (atchMsgObj.isAttachment) {
          this.messages.unshift({
            message: atchMsgObj?.message,
            isAuthor:
              messageObj.state.author == "Therapist-" + this.userData._id
                ? true
                : false,
            time: messageObj.state.timestamp,
            isAttachment: true,
            fileName: atchMsgObj.fileName,
            filePath: atchMsgObj.filePath,
            thumbnailImage: atchMsgObj.thumbnailImage,
            fileSize: atchMsgObj.fileSize,
            isVideo: atchMsgObj.isVideo ? atchMsgObj.isVideo : false,
            playVideo: false,
          });
        } else {
          this.messages.unshift({
            message: atchMsgObj?.message,
            isAuthor:
              messageObj.state.author == "Therapist-" + this.userData._id
                ? true
                : false,
            time: messageObj.state.timestamp,
            isAttachment: false,
          });
        }
      }
    });
  }

  checkTyping() { }


  checkViewSource(filename) {
    let data = filename.split('.').pop();
    if (data === "jpeg" || data === "png" || data === "jpg" || data === "svg" || data === "webp") {
      this.viewFullSizeCircle = true;
      return "View Full Size";
    }
    else {
      this.viewFullSizeCircle = false;
      return ""
    }
  }


  checkExtension(filename) {

    let data = filename.split('.').pop();
    data = data.toLowerCase();
    switch (data) {
      case "pdf":
        return "../../../assets/images/new_pdf.png";
        break;
      case "doc":
        return "../../../assets/images/new_doc.png";
        break;
      case "zip":
        return "../../../assets/images/new_zip.png";
        break;
      case "txt":
        return "../../../assets/images/new_txt.png";
        break;
      case "xls":
        return "../../../assets/images/new_xls.png";
        break;
      case "csv":
        return "../../../assets/images/new_csv.png";
        break;
      case "mov":
        return "../../../assets/images/new_video.png";
        break;
      case "avchd":
        return "../../../assets/images/new_video.png";
        break;
      case "flv":
        return "../../../assets/images/new_video.png";
        break;
      case "fv4":
        return "../../../assets/images/new_video.png";
        break;
      case "swf":
        return "../../../assets/images/new_video.png";
        break;
      case "webm":
        return "../../../assets/images/new_video.png";
        break;
      case "mp4":
        return "../../../assets/images/new_video.png";
        break;
      case "wvm":
        return "../../../assets/images/new_video.png";
        break;
      case "mkv":
        return "../../../assets/images/new_video.png";
        break;
      case "avi":
        return "../../../assets/images/new_video.png";
        break;
      default:
        return "../../../assets/images/new_attachment.png";
    }
  }

  checkJson(str) {
    try {
      JSON.parse(str);
    } catch (error) {
      return false;
    }
    return true;
  }






  doResetPage() {
    this.skip = 1
  }
  changeChatThread(index) {
    this.isChatOpen = true;
    this.isLoading = true;
    this.isConversationEmpty = false;
    this.selectedChatIndex = index;
    this.doResetPage()
    this.messages = [];
    this.selectedUserData = this.chatThreads[this.selectedChatIndex].userId;
    this.conversation = this.conversationsList.filter(
      (item) =>
        item.channelState.uniqueName ===
        this.chatThreads[this.selectedChatIndex].channelName
    )[0];
    this.conversationsList.forEach(async (conversation, idx) => {
      if (
        conversation.channelState.uniqueName ==
        this.chatThreads[this.selectedChatIndex].channelName
      ) {
        let messagePaginator = await this.conversationsList[idx].getMessages();
        let data = await this.conversationsList[idx].getParticipants();
        data.forEach(async (participant) => {
          let participantData = await participant.getUser();
          if (participantData.state.identity.split('-')[0] === 'user') {
            this.selectedUserData['isOnline'] = participantData.isOnline;
          }
        });
        this.currentConMessagePaginator = messagePaginator;
        this.pushMessages(messagePaginator);
        this.scrollToBottom();
        this.isLoading = false;

        if (this.chatThreads[this.selectedChatIndex]['lastMessage']?.isUnread) {
          this.chatThreads[this.selectedChatIndex]['lastMessage'] = {
            ...this.chatThreads[this.selectedChatIndex]['lastMessage'],
            isUnread: false
          };
        }
        if (this.messages.length == 0) {
          this.isConversationEmpty = true;
        } else {
          this.isConversationEmpty = false;
        }
      }
    });
  }

  onKeypressEvent(event: any) {
    if (this.messageToSend.invalid) {
      this.isSendingMessage = false;
    } else {
      this.isSendingMessage = true;
    }
    this.conversationsList.forEach((conversation, idx) => {
      if (
        conversation.channelState.uniqueName ==
        this.chatThreads[this.selectedChatIndex].channelName
      ) {
        this.conversationsList[idx].typing();
      }
    });
  }

  scrollToBottom() {
    try {
      // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      let lastMessage = document.getElementById('lastMessageId');
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    } catch (err) { }
  }

  async loadOlderMessages() {
    if (this.currentConMessagePaginator.hasPrevPage) {
      let newPagin = await this.currentConMessagePaginator.prevPage();
      this.unshiftMessage(newPagin);
      this.currentConMessagePaginator = newPagin;
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  // 
  onSendMessage() {
    let lastTimeStr = moment().format("DD_MMM_YYYY HH:mm");
    if (this.selectedAttachment == null) {
      if (this.messageToSend.invalid) {
        return;
      }
      this.isSendingMessage = true;
      let conversationMessage = this.messageToSend.value.toString();
      this.conversationsList.forEach((conversation, idx) => {
        if (
          conversation.channelState.uniqueName ==
          this.chatThreads[this.selectedChatIndex].channelName
        ) {
          this.conversationsList[idx].sendMessage(this.messageToSend.value);
          this.therapistChatService.updateLastMessage(this.chatThreads[this.selectedChatIndex].channelName, { message: this.messageToSend.value, sentBy: 'therapist' }).subscribe((res) => {
            this.chatThreads[this.selectedChatIndex]["lastMessage"]["message"] = this.messageToSend.value
            this.messageToSend.setValue("");

          })
          this.therapistChatService
            .updateLastMessageAt(
              this.chatThreads[this.selectedChatIndex].channelName,
              { time: moment().format("DD_MMM_YYYY HH:mm") }
            )
            .subscribe((res) => {
              this.chatThreads[this.selectedChatIndex]["lastMessageAt"] =
                moment(lastTimeStr, "DD_MMM_YYYY HH:mm");

            });
          if (this.isConversationEmpty) {
            this.isConversationEmpty = false
          }
          this.isSendingMessage = false;
        }
      });
    } else if (this.selectedAttachment != null) {
      const data = new FormData();
      data.append("upload", this.selectedAttachment);
      data.append(
        "channelName",
        this.chatThreads[this.selectedChatIndex]?.channelName
      );
      data.append("fileName", this.selectedAttachment.name);
      this.isUploadingAttachment = true
      this.therapistChatService.sendAttachment(data, () => errorCallback()).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            var eventTotal = event.total ? event.total : 0;
            this.progress = Math.round(event.loaded / eventTotal * 100);
            // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // console.log('Image Upload Successfully!', event.body);

            let res = event.body;

            let attchMsgObj = {
              isAttachment: true,
              fileName: this.selectedAttachment?.name,
              filePath: res["fileKey"],
              thumbnailImageKey: res["thumbnailImageKey"],
              thumbnailImage: (`${this.imgUrl}/thumbnails/${res["thumbnailImageKey"]}`).trim(),
              fileSize: this.formatBytes(this.selectedAttachment.size),
              isVideo: res['isVideo'],
              playVideo: false
            };
            this.isUploadingAttachment = false
            this.progress = 0
            this.selectedAttachment = null;
            this.scrollToBottom();
            if (this.isConversationEmpty) {
              this.isConversationEmpty = false
            }
            this.conversationsList.forEach((conversation, idx) => {
              if (
                conversation.channelState.uniqueName ==
                this.chatThreads[this.selectedChatIndex]?.channelName
              ) {
                this.conversationsList[idx]?.sendMessage(
                  JSON.stringify(attchMsgObj)
                );
                this.selectedAttachment = null;
                this.isSendingMessage = false;
                this.chatThreads[this.selectedChatIndex]?.attachments.unshift(
                  { ...attchMsgObj, isShown: true }
                );
                this.therapistChatService
                  .updateLastMessageAt(
                    this.chatThreads[this.selectedChatIndex]?.channelName,
                    { time: moment().format("DD_MMM_YYYY HH:mm") }
                  )
                  .subscribe((res) => {
                    this.chatThreads[this.selectedChatIndex]["lastMessageAt"] =
                      moment(lastTimeStr, "DD_MMM_YYYY HH:mm");

                  });
                this.therapistChatService.updateLastMessage(this.chatThreads[this.selectedChatIndex].channelName, { message: 'Attachment - ' + attchMsgObj.fileName, sentBy: 'therapist' }).subscribe((res) => {
                  this.chatThreads[this.selectedChatIndex]["lastMessage"]["message"] =
                    'Attachment - ' + attchMsgObj.fileName;
                })
              }
            });
            setTimeout(() => {
              this.progress = 0;
            }, 1500);

        }


      });
      const errorCallback = () => {
        this.isUploadingAttachment = false
        this.progress = 0
        this.selectedAttachment = null;

      }
    }
  }

  addAttachment(event) {
    let tempFile = <File>event.target.files[0];
    this.isSendingMessage = true;
    if (tempFile.size > 20971520) {
      this.alertService.alert(
        new BootstrapAlert(
          "File size should be less than 20 MB",
          "alert-danger"
        )
      );
      this.isSendingMessage = false;
      return;
    }
    this.selectedAttachment = tempFile;
  }


  cancelAttachment() {
    try {
      this.selectedAttachment = null;
      this.isSendingMessage = false;
      this.isUploadingAttachment = false
      if (this.onUploadAttachmentAPISERVICE) {
        // cancelling upload attachment
        this.onUploadAttachmentAPISERVICE?.unsubscribe();
      }
    }
    catch (e) {
      this.selectedAttachment = null;
      this.isSendingMessage = false;
      this.isUploadingAttachment = false
    }

  }
  async getSignedUrlVideo(index) {
    let data = await this.therapistChatService
      .getDownloadUrl(this.messages[index].filePath)
      .toPromise();
    this.messages[index]["videoUrl"] = data["url"];
    this.messages[index]["playVideo"] = true;
  }

  downloadAttachment(key, fileName, isSidebar = false) {
    let index = `${key}-${isSidebar ? 'sidebar' : 'chatbar'}`
    try {
      // @ts-ignore
      if (!this.isDownloading && !this.isDownloading?.key) {
        this.isDownloading[index] = {}
      }
      let downloadStatusObj = {
        status: true,
        id: key,
      }
      if (!this.isDownloading[index]) {
        this.isDownloading[index] = {}
      }
      this.isDownloading[index] = downloadStatusObj

      this.therapistChatService.getDownloadUrl(key).subscribe((data) => {
        let url = data["url"];
        this.getBlob(url).then((blob) => {
          this.saveAs(blob, fileName, key, isSidebar);
        });
      }, (err) => {
        if (this.isDownloading[index] && this.isDownloading[index].status && this.isDownloading[index].id == key) {
          this.isDownloading[index] = {
            status: false,
            id: '',
          }
        }
      });
    }
    catch (e) {

      if (this.isDownloading[key] && this.isDownloading[key].status && this.isDownloading[key].id == key) {
        this.isDownloading[key] = {
          status: false,
          id: '',
        }
      }
    }
  }





  getBlob(url) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };
      xhr.send();
    });
  }

  saveAs(blob, filename, key, isSidebar) {
    let index = `${key}-${isSidebar ? 'sidebar' : 'chatbar'}`
    try {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      //  

      if (this.isDownloading[index] && this.isDownloading[index].status && this.isDownloading[index].id == key) {
        this.isDownloading[index] = {
          status: false,
          id: '',
        }
      }
      //  
    }
    catch (e) {
      if (this.isDownloading[index] && this.isDownloading[index].status && this.isDownloading[index].id == key) {
        this.isDownloading[index] = {
          status: false,
          id: ''
        }
      }
    }
  }

  openTherapistProfile() {
    this.isProfileOpen = true;
    this.isChatOpen = false;
    this.isChatListOpen = false;
  }

  closeTherapistProfile() {
    this.isProfileOpen = false;
    this.isChatOpen = true;
    this.isChatListOpen = false;
  }

  closeChat() {
    this.isChatOpen = false;
    this.isChatOpen = false;
    this.isChatListOpen = true
  }

  onSearchChange(searchQuery) {
    this.isSearch = true;
    try {
      if (searchQuery.length > 0) {
        let searchData = this.searchArray.filter(data => data.userId?.name.toLowerCase().includes(searchQuery.toLowerCase()))
        this.chatThreads = searchData;
        this.chatThreads.forEach((thread) => {
          if (thread?.lastMessageAt) {
            thread.lastMessageAt = thread?.lastMessageAt
          }
        });
      } else {
        this.chatThreads = this.searchArray
        this.isSearch = false;
      }
    }
    catch (e) {
      this.isSearch = false;
      this.chatThreads = this.searchArray
    }
  }

  replaceURLs(message) {
    if (!message) return;

    var urlRegex = /[a-zA-z\/\:]*[.]+[a-zA-z]{2,4}/g;

    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      let data = '<a href="' + hyperlink + '" target="_blank" style="text-decoration: underline; color: inherit" rel="noopener" >' + url + '</a>'
      return data;
      // return hyperlink;
    });
  }

  toggleSeeAllAttachments() {
    this.seeAllAttachments = !this.seeAllAttachments;
    if (this.seeAllAttachments) {
      if (this.chatThreads[this.selectedChatIndex]["attachments"]) {
        this.chatThreads[this.selectedChatIndex]["attachments"] =
          this.chatThreads[this.selectedChatIndex]["attachments"].map(
            (attach) => {
              let newObj = { ...attach };
              newObj["isShown"] = true;
              return newObj;
            }
          );
      }
    } else {
      if (this.chatThreads[this.selectedChatIndex]["attachments"]) {
        this.chatThreads[this.selectedChatIndex]["attachments"] =
          this.chatThreads[this.selectedChatIndex]["attachments"].map(
            (attach, index) => {
              let isShow = true;
              if (index > 5) {
                isShow = false;
              }
              let newObj = { ...attach, isShown: isShow };
              return newObj;
            }
          );
      }
    }
  }
}
