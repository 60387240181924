import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { ReactiveFormsModule } from "@angular/forms";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "../app-routing.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { TherapistDashboardComponent } from "./therapist-dashboard/therapist-dashboard.component";
import { ProfileComponent } from "./profile/profile.component";
import { SessionsComponent } from "./sessions/sessions.component";
import { MyChatsComponent } from "./my-chats/my-chats.component";
import { ClientNotesComponent } from "./client-notes/client-notes.component";
import { DiscussionForumComponent } from "./discussion-forum/discussion-forum.component";
import { HelpdeskComponent } from "./helpdesk/helpdesk.component";
import { ContractRelatedComponent } from "./contract-related/contract-related.component";
import { TalkTherapyRelatedComponent } from "./talk-therapy-related/talk-therapy-related.component";
import { PaymentRelatedComponent } from "./payment-related/payment-related.component";
import { PlatformRelatedComponent } from "./platform-related/platform-related.component";
import { AccountRelatedComponent } from "./account-related/account-related.component";
import { BenefitsRelatedComponent } from "./benefits-related/benefits-related.component";
import { RaiseNewRequestComponent } from "./raise-new-request/raise-new-request.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ViewOldRequestsComponent } from "./view-old-requests/view-old-requests.component";
import { ViewSingleTicketComponent } from "./view-single-ticket/view-single-ticket.component";
import { TherapistStartDiscussionForumComponent } from "./therapist-start-discussion-forum/therapist-start-discussion-forum.component";
import { TherapistAnswerDiscussionForumComponent } from "./therapist-answer-discussion-forum/therapist-answer-discussion-forum.component";
import { ClientNotesViewComponent } from "./client-notes-view/client-notes-view.component";
import { SharedModule } from "../shared/shared.module";
import { JoinSessionComponent } from "./join-session/join-session.component";
import { ViewCalendarComponent } from "./view-calendar/view-calendar.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { NgxEditorModule } from "ngx-editor";
import { RecurringScheduleComponent } from './recurring-schedule/recurring-schedule.component';
import { CreateNotesComponent } from './create-notes/create-notes.component';
import { TherapistHeaderComponent } from './therapist-header/therapist-header.component';
import { BootstrapAlertModule } from "../shared/ng-bootstrap-alert/bootstrap-alert.module";
import { NotificationsComponent } from './notifications/notifications.component';
import { ReportsNewComponent } from './reports-new/reports-new.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TherapistHolidaysComponent } from './therapist-holidays/therapist-holidays.component';
import { SessionsReportComponent } from './sessions-report/sessions-report.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { SessionJoinComponent } from './session-join/session-join.component';
import { SessionJoinCallComponent } from './session-join-call/session-join-call.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    DashboardComponent,
    TherapistDashboardComponent,
    ProfileComponent,
    SessionsComponent,
    MyChatsComponent,
    ClientNotesComponent,
    DiscussionForumComponent,
    HelpdeskComponent,
    ContractRelatedComponent,
    TalkTherapyRelatedComponent,
    PaymentRelatedComponent,
    PlatformRelatedComponent,
    AccountRelatedComponent,
    BenefitsRelatedComponent,
    RaiseNewRequestComponent,
    ChangePasswordComponent,
    ViewOldRequestsComponent,
    ViewSingleTicketComponent,
    TherapistStartDiscussionForumComponent,
    TherapistAnswerDiscussionForumComponent,
    ClientNotesViewComponent,
    JoinSessionComponent,
    ViewCalendarComponent,
    RecurringScheduleComponent,
    CreateNotesComponent,
    TherapistHeaderComponent,
    NotificationsComponent,
    ReportsNewComponent,
    UserProfileComponent,
    TherapistHolidaysComponent,
    SessionsReportComponent,
    SessionJoinComponent,
    SessionJoinCallComponent,
  ],
  imports: [
    NgxSkeletonLoaderModule,
    CommonModule,
    AppRoutingModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    BsDatepickerModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    NgxEditorModule,
    BootstrapAlertModule,
    Ng2TelInputModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
})
export class TherapistModule { }
