<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container mt-3">
    <p class="disclaimerTxt">
      <strong> <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for screening and
      monitoring symptom severity and cannot replace a clinical assessment and
      diagnosis. You are encouraged to share these results with a mental health
      professional for a better understanding.
    </p>
    <div class="report-user mt-2">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user">
      <h3>Congratulations on taking that first step!</h3>
      <p class="mt-3"><strong>Your Score:</strong></p>
      <p>Narcissism: {{ narcissismScore }}</p>
      <p class="mt-2"><strong>Your Result:</strong></p>
      <p>
        Your responses indicate you are <span>{{ narcissismPhrase }} <span class="scrollTxt"
            (click)="scrollToElem('target')">Detailed Analysis</span> </span>
      </p>
      <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
    </div>
  </div>

  <div class="container graph-section mt-3">
    <div>
      <div>
        <p class="mb-3" *ngIf="narcissismScore >= 0.3 && narcissismScore <=0.5">
          Your results may not be a sign of concern, but read on to find out a more detailed analysis of your score.  Felicity is here to assist you in improving your mental well-being.
        </p>
        <p class="mb-3" *ngIf="narcissismScore >= 0.6 && narcissismScore <= 0.7">
          Your results may be slightly concerning, but do not worry too much - Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.
        </p>
        <p class="mb-3" *ngIf="narcissismScore >= 0.8">
          Seeing the report can be overwhelming, but Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.
        </p>
      </div>

      <h5>Understanding Your Narcissism Assessment</h5>
      <div class="graph-right">
        <img src="../../../assets/images/free-report-graphs/narcissim.png" />
      </div>
    </div>
  </div>

  <div class="container scores-section mb-4">
    <div class="about-scores report-user">
      <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
      <p class=" mb-3">
        The possible range of scores is from 0 to 1, where any point less than 0.5 is considered the normal range of narcissistic personality, whereas 0.6 and higher are considered to be falling within the range of having higher narcissistic tendencies.
      </p>
      <p class="para_scores mb-3" *ngIf="narcissismScore < 0.3 ">
        A score that falls below 0.3 is a low score means that you have a tendency to focus on others more than the average person, sometimes even at the expense of your own self-esteem. A healthy level of narcissism is essential, since having a strong self-regard and holding your morals, values, and beliefs to a good standard are all qualities that involve putting yours self-worth above what others make it out to be. To have enough faith in yourself, and a good amount of self-love, can even get you through difficult times - it is not wrong to accentuate and revel in your good qualities. If you are struggling with doing the same, you can reach out to the mental health practitioners at Felicity to help you come up with interventions that can better guide you on the path of self-love.
      </p>
      <p class="para_scores mb-3" *ngIf="narcissismScore >= 0.3 && narcissismScore <=0.5">
        A score that falls between 0.3 and 0.5 means that the level of narcissistic qualities exhibited by you fall within the healthy range. Those with healthy narcissistic tendencies have a good self-esteem, and do not require constant external validation to feel good about themselves. Relationship-wise, you are secure within yourself. Though you may not like conflict or criticism - as is human nature - you are able to participate in a healthy dialogue when things go wrong, instead of being highly reactive to the situation. Even people who fall within the normal range of narcissistic tendencies are capable of moments of narcissistic behaviour. Everyone has the capacity to inflate achievement and discard responsibilities; but in people with healthy narcissism, these things do not last for an extended period. A healthy level of narcissism thus means that you have a strong self regard, authentic self-concept - you hold your own morals, values, and beliefs to a good standard - and are able to admire others while also accepting admiration for your achievements. 
      </p>
      <p class="para_scores mb-3" *ngIf="narcissismScore >= 0.6 && narcissismScore <=0.6">
        A score that falls between 0.6 and 0.7 is a moderate score on the narcissism scale, which means that you may have more narcissistic tendencies than the average person. Although the level of narcissism present in you is subtle, it does exist and might have started affecting your daily routine. There is a lack of empathy and it seems like a foreign feeling to people falling under the moderate range of narcissism tendencies. Apart from this, they will be unaware of how they might potentially hurt the feelings of others in the process. Holding grudges is found commonly in this range of scores for the tiniest of issues. Lastly, there can be a possibility of delusions of grandeur and a high regard for self with an incapacity to accept setbacks and losses. If you lie in this range of score and find certain similarities with the above-mentioned characteristics, consider seeking help to walk towards a mentally healthier path. The mental health professionals in Felicity can help you identify, acknowledge, and deal with your problems that are preventing you from living a healthy personal and social life.
      </p>
      <p class="para_scores mb-3" *ngIf="narcissismScore >= 0.8 ">
        A score above 0.8 means that you may have more narcissistic tendencies than the average person. That is, you may notice excessive approval-seeking behaviour from those around you in yourself, and have a harder time dealing with criticism to the point of being argumentative and overly defensive. A high scorer on the narcissism scale may also excessively rely on others for self-esteem management. People with high narcissism tend to be plagued by insecurity, since it is suspected that high levels of narcissism develop in people partly because of experiencing neglectful or overprotective households as a child - this can really worsen the quality of life of the person in adulthood. If you feel that you are preoccupied with how you look in the eyes of others most of the time, but are always looking for competition in others and wanting to feel superior to them in order to feel like you have achieved something, then you might want to talk to a licensed mental health professional to figure out whether your narcissistic personality is impacting your capacity to life a healthy life, and with developing healthy interpersonal relationships. You can reach out to the mental health practitioners at Felicity for understanding your personality better, and finding strategies that work for you for managing your tendencies in an effective way, so you can live a healthy life.
      </p>
    </div>
  </div>

  <!-- TODO: Add CSS -->
  <div class="container about-scores">
    <div class="">
      <h5 class="mb-3" id="target">Detailed Analysis</h5>
      <p class="mb-3">
        The Narcissistic Personality Inventory-16 (NPI-16) is a unidimensional scale, that is, it does
        not consist of any of the subscales that might be measured in other versions (13, 15, 40 - each with an
        increasing number of questions in the inventory). Some of the key traits of those with high narcissism
        tendencies are as follows:
      </p>
      <p class="mb-3">
        <strong>Authority:</strong> Being preoccupied with fantasies about power, success, and beauty. They tend to
        create and believe exaggerated, unrealistic narratives around their success, relationships, even how good they
        look to help them feel special and in control.

      </p>
      <p class="mb-3">
        <strong>Superiority/Entitlement:</strong> Having an inflated sense of self-importance and entitlement. Those
        with Narcissism need constant reassurance of their success. They expect special treatment - whether it be in
        favours or apologies - they think they deserve to have it, and everyone else around them should comply.

      </p>
      <p class="mb-3">
        <strong>Vanity:</strong> However, even when being insecure and needing constant acknowledgement of their
        achievements, people high on the Narcissism spectrum have a high sense of their own accomplishments. Because of
        this inflated sense of capabilities, they might also be prone to embellish things about themselves, their life,
        and their talents.

      </p>
      <p class="mb-3">
        <strong>Exploitativeness:</strong> People with high Narcissism tendencies tend to manipulate their
        relationships, and instead of forming deeper friendships or romantic relationships for the people, they form
        them out of their selfish needs and desires, whatever those might be. They often don’t think twice about using
        or exploiting other people to achieve their own ends - whether maliciously or obliviously. They care about their
        relationships and the people in their life on a superficial level - if they elevate their social status, or make
        them look or feel good, for instance - and they don’t really think about how their behavior might affect them.

      </p>
      <p class="mb-3">
        <strong>Exhibitionism:</strong> People with high Narcissism tendencies who are high on exhibitionism, unlike
        those who care too much about what others think, don’t tend to be insecure at all. They are often carelessly
        rude and crude to others, and tend to not notice how people react to it either.
      </p>
      <p class="mb-3">
        Ultimately, all types of narcissists lack object constancy, meaning when they are angry with you, they can't see
        that in the context of your relationship. If they are mad about something you did, all you will see is their
        hatred for you, and their wish to hurt you. If you think you are exhibiting any of these signs, and wish to
        change for the better, you can always reach out to a licensed practitioner to find out solutions that work for
        you. This was just a brief outline of interpretation laid out for you based on existing research. It cannot be
        substituted for an authentic clinical diagnosis, so please consult a mental health professional for customized
        routes to a healthier you .
      </p>
    </div>
  </div>
  <div class="container">
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
  <!-- <div class="disclaimer-section">
        <div class="head-disclaimer">
          <h3>Disclaimer</h3>
          <p>
            This tool should be used for screening and monitoring symptom severity
            and cannot replace a clinical assessment and diagnosis. You are
            encouraged to share these results with a mental health professional.
            Felicity disclaims any liability, loss or risk incurred as a consequence
            , directly or indirectly, from the use of this assessment & it's
            results.
          </p>
        </div>
      </div> -->
</div>