<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container-fluid" *ngIf="firstPart">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <br />
            <div class="sectionHeading">
              <h1><b>Social Anxiety Assessment</b></h1>
              <hr />
            </div>
          </div>
          <div class="col-md-3 paddingCss">
            <div class="divTagCss">
              <img class="img-fluid imageCss" src="../../../assets/images/Social-anxiety.png" />
            </div>
          </div>
          <div class="col-md-8 paddingCss">
            <div class="textInside">
              <p>
                Welcome to Felicity's online mental health test. It's free,
                quick, anonymous & 100% confidential.
                <br />
              </p>
              <h2 class="htag mt-3"><b>Introduction</b></h2>
              <p>The Liebowitz Social Anxiety Scale is a 24-item self-report tool used to assess the degree of social anxiety or social phobia experienced by the individual. Social anxiety is a type of mental health condition  that creates extreme distress to an individual when they step out in social situations. Such individuals may have a fear of meeting new people, socialising or even stepping out of their homes. Developed by Leibowitz and colleagues (1987) is used widely to measure the severity of social anxiety symptoms. </p>
              <br>
              <h2 class="htag"><b>Instruction</b></h2>
              <ol>
                <li>This measure assesses the way that social phobia plays a role in your life across a variety of situations.</li>
                <li>Read each situation carefully and answer two questions about that situation.</li>
                <li>The <strong>first question</strong> asks how anxious or fearful you feel in the situation.</li>
                <li>The <strong>second question</strong> asks how often you avoid the situation.</li>
                <li>If you come across a situation that you ordinarily do not experience, we ask that you imagine "what if you were faced with that situation," and then rate the degree to which you would fear this hypothetical situation and how often you would tend to avoid it. Please base your ratings on the way that the situations have affected you <strong>in the last week.</strong></li>
              </ol>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="submitButtonClass text-center">
      <button class="thm-2" (click)="ShowHide()">Next</button>
    </div>
    <br />
  </div>
  <div class="container-fluid" *ngIf="secondPart">
    <form [formGroup]="freeReportForm" name="freeReportForm">
      <ul class="unstyled main-body">
        <div class="question-section">
          <h5>{{ questions[currentIndex].questionText }}</h5>
          <p><strong>Fear</strong></p>
          <ul class="unstyled options_list" id="question_{{ questions[currentIndex].qno }}">
            <div *ngFor="let answer of fear_answers[currentIndex]; let idx1 = index" class="align-options">
              <li>
                <label class="labelCss">
                  <span class="options_listing">{{ answer.answerText }}</span>
                  <br />
                  <input type="radio" class="align-input" value="{{ idx1 }}" name="{{ questions[currentIndex].label }}fear"
                    formControlName="{{ questions[currentIndex].label }}fear" />
                </label>
              </li>
            </div>
          </ul>
          <br>
          <p><strong>Avoidance</strong></p>
          <ul class="unstyled options_list" id="question_{{ questions[currentIndex].qno }}">
            <div *ngFor="let answer of avoidance_answers[currentIndex]; let idx1 = index" class="align-options">
              <li>
                <label class="labelCss">
                  <span class="options_listing">{{ answer.answerText }}</span>
                  <br />
                  <input type="radio" class="align-input" value="{{ idx1 }}" name="{{ questions[currentIndex].label }}avoidance"
                    formControlName="{{ questions[currentIndex].label }}avoidance">
                </label>
              </li>
            </div>
          </ul>
        </div>
        <div class="form-btn">
          <button *ngIf="currentIndex == 0" type="button" class="btn-back" (click)="instruction1()">
            BACK
          </button>
          <button *ngIf="currentIndex > 0" type="button" class="btn-back" (click)="previousPage()">
            BACK
          </button>
          <button *ngIf="questions.length - 1 != currentIndex" class="btn btn-submit thm-2" (click)="currentIndexInc()">
            Next
          </button>
          <button *ngIf="questions.length - 1 == currentIndex" class="btn btn-submit" type="submit" data-toggle="modal"
            data-target="#myModal" (click)="showModal()" [disabled]="!freeReportForm?.valid">
            Submit
          </button>
          <div class="container-progress">
            <div [hidden]="!(progressPercentage > 0)" class="progressBarCls"
              [ngStyle]="{ 'width' : progressPercentage + '%' }"></div>
          </div>
        </div>
      </ul>
    </form>
  </div>
  <div class="modal fade" id="myModal" *ngIf="!hideModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Free Report</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <p>
            Great! You're almost done. Please enter these details to generate
            report.
          </p>
          <form [formGroup]="userForm2" name="userForm2">
            <input id="name" formControlName="fullname" name="fullname" placeholder="Name" [ngClass]="{
                'has-error':
                  !userForm2.controls.fullname.valid &&
                  userForm2.controls.fullname.touched,
                'has-success':
                  userForm2.controls.fullname.valid &&
                  userForm2.controls.fullname.touched
              }" />
            <input type="email" id="email" formControlName="email" name="email" placeholder="xyz@gmail.com" [ngClass]="{
                'has-error':
                  !userForm2.controls.email.valid &&
                  userForm2.controls.email.touched,
                'has-success':
                  userForm2.controls.email.valid &&
                  userForm2.controls.email.touched
              }" />
            <div class="phoneHolder">
              <input type="tel" id="phone" formControlName="phone" name="phone" class="form-control phoneControl"
                [ngClass]="{
                  'has-error':
                    userForm2.controls.phone.errors?.invalidPhone ||
                    (userForm2.controls.phone.errors?.required &&
                      userForm2.controls.phone.touched)
                }" ng2TelInput (hasError)="checkError($event)" (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                  initialCountry: userCountryCode.countryCode
                }" />
            </div>
            <div class="errorContainer" *ngIf="userForm2.controls.phone.errors?.invalidPhone">
              <p>Please enter a valid phone number.</p>
            </div>
          </form>
          <div class="modal-footer">
            <button type="submit" class="btn-submit" value="Submit" (click)="onSubmit()" data-dismiss="modal"
              [disabled]="!userForm2?.valid">
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>