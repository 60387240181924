import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BookingService } from '../booking.service';
import {
  createLocalTracks, LocalTrack, connect, createLocalVideoTrack, createLocalAudioTrack, RemoteTrack, RemoteParticipant, Track, TwilioError, LocalVideoTrack,
} from 'twilio-video'
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { UserVideoService } from '../user-video.service';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
export type Devices = MediaDeviceInfo[];
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-session-join-call',
  templateUrl: './session-join-call.component.html',
  styleUrls: ['./session-join-call.component.css']
})
export class SessionJoinCallComponent implements OnInit {
  readonly constant_text = {
    CONNECTING: 'Connecting...',
    CONNECTED: 'Connected...',
    WAITING_THERAPIST: 'Your therapist will join soon...',
    FAILED: 'Something went wrong while joining this session. Please try again!',
  }
  readonly device_status_text = {
    STARTED: 'STARTED',
    NOT_STARTED: 'NOT_STARTED',
    STARTING: 'STARTING',
    CHECKING: 'Getting Ready...',
    CAMERA_NOT_FOUND: 'CAMERA NOT FOUND',
    MIC_NOT_FOUND: 'MIC_NOT_FOUND',
    PERMISSION_DENIED: 'PERMISSION DENIED'
  }
  roomName = "";
  isLoading: boolean = false;
  sessionDetails: any;
  remainingMinutes;
  status: string = "";
  localScreenTrack: any;
  mediaDevices: MediaDeviceInfo[];
  availableMicrophones: MediaDeviceInfo[];
  availableCameras: MediaDeviceInfo[];
  isMicAvailable: any;
  isCamAvailable: boolean;
  isCameraON: boolean;
  isMicON: boolean;
  localTracks = [];
  localVideoTrack: any;
  localAudioTrack: any;
  remainingTimeText: string = "";
  isVideoCallJoining: boolean = false;
  videoToken: Object;
  room: any;
  connected: boolean;
  remoteParticipantName: string;
  isRemoteVideoMuted: boolean = true;
  isRemoteAudioMuted: boolean = true;
  remoteVideoTracks: any
  imageUrl = environment.apiUrl;
  isRemoteJoined: boolean = false;
  isFullScreen: boolean = false;
  videoCallStatus: any;
  deviceInitialization: string = this.device_status_text.NOT_STARTED;
  screenShareOn: boolean = false;
  isRemoteScreenSharingEnabled: boolean = false;
  remoteScreenShare: boolean = false;
  isRemotePictureInPicture: boolean = false;
  doShowSwapButton: boolean = false;
  isRemoteScreenShareInInputPreview: boolean = false;
  deviceInfo = null;
  MobileText: string;
  mobileScreen: boolean = false;
  responsiveRoute: string;
  constructor(
    private actRoute: ActivatedRoute,
    private videoService: UserVideoService,
    private bookingService: BookingService,
    private router: Router,
    private confirmationService: ConfirmationDialogService,
    private alertService: BootstrapAlertService,
    private sideNavService: SideNavBarService,
    private deviceService: DeviceDetectorService
  ) { }

  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  @ViewChild("videCallBox") divRef;
  ngOnInit(): void {
    // this.sideNavService.hideHeaderActions()
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.actRoute.queryParamMap.subscribe((params) => {
      this.roomName = params.get("sessionId");
      this.responsiveRoute = environment.deepLinkUser + this.roomName;
      this.isLoading = true;
      // this.videoService.getVideoToken().subscribe((videoToken) => {
      //   this.videoToken = videoToken;
      // })

      this.bookingService.getSession(this.roomName).subscribe((data: any) => {
        this.sessionDetails = data.booking
        console.log("🚀 ~ file: session-join-call.component.ts ~ line 103 ~ SessionJoinCallComponent ~ this.bookingService.getSession ~ this.sessionDetails", this.sessionDetails);
        // if (this.deviceService.os.toLocaleLowerCase().includes('ios')) {
        //   this.MobileText = "App Store";
        //   this.mobileScreen = true;
        // }
        // else if (this.deviceService.os.includes('Android') || this.deviceService.os.includes('android')) {

        //   this.MobileText = "Play Store";
        //   this.mobileScreen = true;
        // }
        // remaining time
        this.doHandleTimeRemaining()

        // check the status of session
        this.doCheckSessionAvaibility()
      }, err => {
        if (err.status === 404) {
          this.status = 'NOT_FOUND'
        }
        this.isLoading = false;
      }
      )
    })
  }
  async doHandleTimeRemaining() {
    let startDate = moment(this.sessionDetails.startDate);
    let timeDiff = this.bookingService.calcTimeDiff(
      startDate["_d"],
      new Date()
    );
    let timeDiffKey = Object.keys(timeDiff);
    timeDiffKey.map((key) => {
      if (timeDiff[key] > 0) {
        if (timeDiff[key] > 1) {
          this.remainingTimeText += timeDiff[key] + " " + key + " ";
        } else {
          this.remainingTimeText +=
            timeDiff[key] + " " + key.slice(0, -1) + " ";
        }
      }
    })
    return
  }

  openLink() {
    window.open(this.responsiveRoute, '_blank');
  }

  doCheckSessionAvaibility() {
    let now = moment(new Date());
    const sessionStartDate = moment(this.sessionDetails.startDate);

    this.remainingMinutes = moment.duration(sessionStartDate.diff(now));

    this.remainingMinutes = this.remainingMinutes.asMinutes();
    if (this.status == "CALL_ENDED") {
      this.isLoading = false;
    }
    else if (this.remainingMinutes <= -90) {

      this.isLoading = false;
      this.status = "PAST_MEETING";
      setTimeout(() => {
        this.router.navigateByUrl("user-dashboard/sessions");
      }, 3000)

    }
    else if (this.remainingMinutes <= 30) {
      this.status = "ABOUT_TO_START"
      if (!this.mobileScreen) {
        // this.status = "ABOUT_TO_START";
        // this.doHandleDeviceInitialization()
      } else {
        // this.status = "MOBILE_SCREEN"
      }
      this.isLoading = false;

    } else {

      this.isLoading = false;
      // if (!this.mobileScreen) {
        this.status = "NOT_STARTED";
      // } else {
      //   this.status = "MOBILE_SCREEN";
      // }

    }
  }

  async doHandleDeviceInitialization() {
    // this.sideNavService.showHeaderActions()
    try {
      this.deviceInitialization = this.device_status_text.CHECKING
      this.mediaDevices = await navigator.mediaDevices.enumerateDevices();
      this.availableMicrophones = this.mediaDevices.filter(
        (device) => device.kind === "audioinput"
      );
      this.availableCameras = this.mediaDevices.filter(
        (device) => device.kind === "videoinput"
      );
      // check for available devices length
      // if available camera === 0, then we could not find any camera attached to your system

      // if availableMicrophones === 0, then we could not find any mic attached to your system

      //if both are 0, then we show popup
      // this.localTracks =
      await createLocalVideoTrack().then((tracks => {
        this.localVideoTrack = tracks
        this.localTracks.push(tracks)
        let localMediaContainer = document.getElementById("inputPreview");
        let lvt = this.localVideoTrack.attach(localMediaContainer);
        // lvt.style.transform = "scale(-1,1)";
        this.isCamAvailable = true;
        this.isCameraON = true;
        this.deviceInitialization = this.device_status_text.STARTED
      })).catch(async (error) => {
        let errorMessage = error.toString();
        if (errorMessage.includes('Requested device not found')) {
          console.log("Requested device not found Camera")
          if (!this.availableMicrophones?.length) {
            this.deviceInitialization = this.device_status_text.MIC_NOT_FOUND
          }
          if (!this.availableMicrophones?.length) {
            this.deviceInitialization = this.device_status_text.CAMERA_NOT_FOUND
          }
        }
        if (errorMessage.includes('Permission denied')) {
          // @ts-ignore
          navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
            if (permissionStatus?.state == "denied") {
              debugger;
              if (permissionStatus?.state == "denied") {
                this.isCamAvailable = false
                this.isMicON = false
                this.isCameraON = false
                this.deviceInitialization = this.device_status_text.PERMISSION_DENIED
              }
              permissionStatus.onchange = async () => {
                debugger;
                if (permissionStatus.state == 'granted') {
                  debugger;
                  createLocalVideoTrack().then((videoTrack) => {
                    this.localVideoTrack = videoTrack
                    if (this.localVideoTrack) {
                      if (!this.localTracks) {
                        this.localTracks = []
                      }
                      if (!this.connected) {
                        debugger;
                        this.localTracks.push(this.localVideoTrack)
                        let localMediaContainer = this.connected == true ? document.getElementById("inputPreviewMin") : document.getElementById("inputPreview");
                        // @ts-ignore
                        localMediaContainer?.src = ""
                        let lvt = this.localVideoTrack.attach(localMediaContainer);
                        lvt.style.transform = "scale(-1,1)";
                        this.isCameraON = true
                      }
                      else {
                        debugger;
                        this.isCameraON = false
                      }
                      this.isCamAvailable = true
                      this.deviceInitialization = this.device_status_text.STARTED
                    }
                  }).catch(eV => {
                    debugger;
                    this.isCamAvailable = false
                    this.isMicON = false
                    this.deviceInitialization = this.device_status_text.CAMERA_NOT_FOUND
                  });

                }
              };
            }
          })
        }
      });




      await createLocalAudioTrack().then((tracks) => {
        this.localAudioTrack = tracks
        this.localTracks.push(tracks)
        this.isMicAvailable = true;
        this.isMicON = true;
      }).catch(async (error) => {
        let errorMessage = error.toString();
        if (errorMessage.includes('Requested device not found')) {
          if (!this.availableMicrophones?.length) {
            this.deviceInitialization = this.device_status_text.MIC_NOT_FOUND
          }
        }
        if (errorMessage.includes('Permission denied')) {
          // @ts-ignore
          navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
            if (permissionStatus?.state == "denied") {
              permissionStatus.onchange = async () => {
                if (permissionStatus.state == 'granted') {
                  this.localAudioTrack = await createLocalAudioTrack().then((track) => {
                    this.localAudioTrack = track
                    this.isMicAvailable = true;
                    if (!this.connected) {
                      if (!this.localTracks) {
                        this.localTracks = []
                      }
                      this.localTracks.push(track)
                      this.isMicON = true;
                      if (this.isMicAvailable && this.isCamAvailable) {
                        this.deviceInitialization = this.device_status_text.STARTED
                      }
                    }
                    else {
                      this.isMicON = false
                    }
                  }).catch(eV => {
                    this.isCamAvailable = false
                    this.isMicON = false
                    this.deviceInitialization = this.device_status_text.CAMERA_NOT_FOUND
                    console.log('retry local video failed!', eV.name)
                  });
                }
              };
            }

          })
        }
      })
    }
    catch (e) {
      console.log(e)
    }
  }

  doRetryCameraTracks() {
    createLocalVideoTrack().then((videoTrack) => {
      this.localVideoTrack = videoTrack
      if (this.localVideoTrack) {
        if (!this.localTracks) {
          this.localTracks = []
        }
        if (!this.connected) {
          debugger;
          this.localTracks.push(this.localVideoTrack)
          let localMediaContainer = this.connected == true ? document.getElementById("inputPreviewMin") : document.getElementById("inputPreview");
          // @ts-ignore
          localMediaContainer?.src = ""
          let lvt = this.localVideoTrack.attach(localMediaContainer);
          lvt.style.transform = "scale(-1,1)";
          this.isCameraON = true
        }
        else {
          debugger;
          this.isCameraON = false
        }
        this.isCamAvailable = true
        this.deviceInitialization = this.device_status_text.STARTED
      }
    }).catch(eV => {
      console.log(eV);
      debugger;
      this.isCamAvailable = false
      this.isMicON = false
      this.deviceInitialization = this.device_status_text.PERMISSION_DENIED
      this.confirmationService
        .confirm(
          "Your camera is blocked",
          eV.toString().includes("Requested device not found") ? "Check your system settings to make sure that a camera is available. If not, plug one in.You might then need to restart your browser." : "Felicity requires access to your camera. Click the camera blocked icon in your browser's address bar.",
          "Retry",
          "Dismiss"
        ).subscribe((res) => {
          if (res) {
            // this.doCheckSessionStillAccessible();
            window.location.reload();
          }
        })
    });

    // this.retryMedia('video');
  }

  async doRetryMicTracks() {
    this.localAudioTrack = await createLocalAudioTrack().then((track) => {
      this.localAudioTrack = track
      this.isMicAvailable = true;
      if (!this.connected) {
        if (!this.localTracks) {
          this.localTracks = []
        }
        this.localTracks.push(track)
        this.isMicON = true;
        if (this.isMicAvailable && this.isCamAvailable) {
          this.deviceInitialization = this.device_status_text.STARTED
        }
      }
      else {
        this.isMicON = false
      }
    }).catch((eV) => {
      this.confirmationService
        .confirm(
          "Your mic is blocked",
          eV.toString().includes("Requested device not found") ? "Check your system settings to make sure that a mic is available. If not, plug one in.You might then need to restart your browser." : "Felicity requires access to your mic. Click the mic blocked icon in your browser's address bar.",
          "Retry",
          "Dismiss"
        ).subscribe(async (res) => {
          if (res) {
            // this.doCheckSessionStillAccessible();
            window.location.reload();
          }
        })
    })

    // this.retryMedia('video');
  }

  doGetDeviceWarningText() {

    if (!this.isMicAvailable && !this.isCamAvailable) {
      return "Seems like Felicity doesn't have mic and camera permission. Therapist won't be see and hear you. Would you like to join anyways?"
    }
    if (!this.isMicAvailable && this.isCamAvailable) {
      return "Seems like Felicity doesn't have mic permission. Therapist won't be able to hear you. Would you like to join anyways?"
    }
    if (!this.isCamAvailable && this.isMicAvailable) {
      return "Seems like Felicity doesn't have camera permission. Therapist won't be able to see you. Would you like to join anyways?"
    }

  }
  async doHandleJoinCall() {
    if (this.status === "ABOUT_TO_START") {
      // if (!this.isMicAvailable || !this.isCamAvailable) {
      //   const warningText = await this.doGetDeviceWarningText()

      //   this.confirmationService
      //     .confirm(
      //       "Are you sure?",
      //       warningText,
      //       "Yes",
      //       "No"
      //     )
      //     .subscribe((res) => {
      //       if (res) {
      //         this.doCheckSessionStillAccessible();
      //       }
      //     });
      // } else {
      this.doCheckSessionStillAccessible();
      // }
    } else if (this.status === "NOT_STARTED") {
      this.doCheckSessionStillAccessible();
    }
  }

  doCheckSessionStillAccessible() {
    if (this.status == "ABOUT_TO_START") {
      // this.status = "STARTED"
      // this.doStartSession(this.roomName);
      if (!this.sessionDetails.meetingRoom) {
        this.alertService.alert(
          new BootstrapAlert(
            "Please wait... Your room is being created",
            "alert-warning"
          )
        )
        this.bookingService.getSession(this.roomName).subscribe((data: any) => {
          this.sessionDetails = data.booking
          if (this.sessionDetails.meetingRoom) {
            window.open(`https://therapist.fuzzycircuit.com/?room=${this.sessionDetails.meetingRoom.roomName}&userCode=${this.sessionDetails.userId._id}&source=main`)
          } else {
            this.alertService.alert(
              new BootstrapAlert(
                "Please try again after some time",
                "alert-warning"
              )
            )
          }
        })
      } else {
        window.open(`https://therapist.fuzzycircuit.com/?room=${this.sessionDetails.meetingRoom.roomName}&userCode=${this.sessionDetails.userId._id}&source=main`)
      }
    } else if (this.status == "NOT_STARTED") {

      let startDate = moment(this.sessionDetails.startDate);
      let now = moment(new Date());

      this.remainingMinutes = moment.duration(startDate.diff(now));
      this.remainingMinutes = this.remainingMinutes.asMinutes();

      if (this.remainingMinutes < 10) {

        this.status = "ABOUT_TO_START";
        // const localMediaContainer = document.getElementById("inputPreview");
        // this.localVideoTrack.attach(localMediaContainer);

      } else {

        this.alertService.alert(
          new BootstrapAlert(
            "You can join session when 30 minutes are remaining",
            "alert-warning"
          )
        );

      }
    }
  }
  doUpdateCallStatusText(type = 0) {
    if (type == 0) {
      this.videoCallStatus = this.constant_text.CONNECTING
    }
    if (type == 1) {
      this.videoCallStatus = this.constant_text.CONNECTED
      setTimeout(() => {
        this.videoCallStatus = this.constant_text.WAITING_THERAPIST
      }, 500)
    }
    if (type == 2) {
      this.videoCallStatus = this.constant_text.WAITING_THERAPIST
    }
    if (type == 3) {
      this.videoCallStatus = this.constant_text.FAILED
    }

  }

  async doStartScreenSharing() {
    if (!this.isRemoteScreenSharingEnabled) {
      if (!this.localScreenTrack || this.localScreenTrack?.isStopped) {

        // @ts-ignore
        let stream = await navigator.mediaDevices.getDisplayMedia({ video: { frameRate: 15 } })
        this.localScreenTrack = new LocalVideoTrack(stream.getTracks()[0], { name: 'screen-share', logLevel: 'info' })
        // let screenShareEl = document.getElementById('screenShare')
        // let remoteParticipantVideo = document.getElementById('remoteParticipant')
        // if (remoteParticipantVideo) {
        //   remoteParticipantVideo.style.display = 'none'
        // }

        // this.localScreenTrack.attach(screenShareEl)
        this.room.localParticipant.publishTrack(this.localScreenTrack);
        this.localScreenTrack.mediaStreamTrack.onended = () => {
          this.doStopScreenSharing()
        }
        this.screenShareOn = true;
      }
    }
  }


  async doMinMaxFunc(boolData) {
    let maxScreen = document.getElementById('ThisIsMaximizedScreen');
    let minScreen = document.getElementById('ThisIsMinimizedScreen');
    if (boolData == "doMax") {
      maxScreen.setAttribute('style', 'display:block');
      minScreen.setAttribute('style', 'display:none');
    }
    if (boolData == "doMin") {
      maxScreen.setAttribute('style', 'display:none');
      minScreen.setAttribute('style', 'display:block');
    }
  }

  async doUserSwap() { }

  async doTherapistMinMaxFunc(boolData) {
    let maxScreen = document.getElementById('ThisIsTherapistMaximizedScreen');
    let minScreen = document.getElementById('ThisIsTherapistMinimizedScreen');
    if (boolData == "doMax") {
      maxScreen.setAttribute('style', 'display:block');
      minScreen.setAttribute('style', 'display:none');
    }
    if (boolData == "doMin") {
      maxScreen.setAttribute('style', 'display:none');
      minScreen.setAttribute('style', 'display:block');
    }
  }

  doSwapRemotePreview() {
    console.log("Swap Remote Preview Called");
    debugger;

    if (!this.isRemoteVideoMuted && this.remoteScreenShare) {
      debugger;
      try {
        this.room.participants.forEach(p => {
          if (this.isRemoteScreenShareInInputPreview) {
            // Screen Share Is In Small Screen
            // Swap To Screen Share In Big Screen
            // 1. Detech Screen Share from InputPreview(Small Screen) & Camera from RemoteParticipant
            p.videoTracks.forEach(track => {
              // User Camera
              if (track?.track.kind == "video" && track?.track.name !== "screen-share") {
                let remoteParticipant = document.getElementById('remoteParticipant')
                track?.track.detach(remoteParticipant)

              }
              // Screen Share
              if (track?.track && track?.track.name == "screen-share") {
                let attachDom = document.getElementById('InputPreviewMin2')
                track?.track.detach(attachDom)
              }
            })
            // 2. Attach Screen Share to RemoteParticipant && userCamera To InputPreview
            p.videoTracks.forEach(track => {
              // User Camera
              if (track?.track.kind == "video" && track?.track.name !== "screen-share") {
                let attachDom = document.getElementById('InputPreviewMin2')
                track?.track.attach(attachDom)
              }
              // Screen Share
              if (track?.track && track?.track.name == "screen-share") {
                let remoteParticipant = document.getElementById('remoteParticipant')
                track?.track.attach(remoteParticipant)
              }
            })
            this.isRemoteScreenShareInInputPreview = !this.isRemoteScreenShareInInputPreview;
          } else {
            // UserCamera Is In Small Screen
            // Swap To UserCamera In Big Screen
            // 1. Detech UserCamra from inputPreview & Screen Share from remote
            p.videoTracks.forEach(track => {
              // User Camera
              if (track?.track.kind == "video" && track?.track.name !== "screen-share") {
                let attachDom = document.getElementById('InputPreviewMin2')
                track?.track.detach(attachDom)
              }
              // Screen Share
              if (track?.track && track?.track.name == "screen-share") {
                let remoteParticipant = document.getElementById('remoteParticipant')
                track?.track.detach(remoteParticipant)
              }
            })
            // 2. Attach userCamera to RemoteParticipant(BigScreen) && ScreenShare To InputPreview(SmallScreen)
            p.videoTracks.forEach(track => {
              // User Camera
              if (track?.track.kind == "video" && track?.track.name !== "screen-share") {
                let remoteParticipant = document.getElementById('remoteParticipant')
                track?.track.attach(remoteParticipant)
              }
              // Screen Share
              if (track?.track && track?.track.name == "screen-share") {
                let attachDom = document.getElementById('InputPreviewMin2')
                track?.track.attach(attachDom)
              }
            })
            this.isRemoteScreenShareInInputPreview = !this.isRemoteScreenShareInInputPreview;
          }
        })



      } catch (err) {
        console.log(err);
      }
    }
    // this.room.participants.forEach(p => {
    //   console.log("p", p);
    //   p.videoTracks.forEach(track => {
    //     console.log("track", track);

    //     if (track?.track && track?.track.name !== "screen-share") {
    //       debugger
    //       console.log("track?.track", track?.track);
    //       let remoteParticipant = document.getElementById('remoteParticipant')
    //       track?.track.detach(remoteParticipant)
    //       let attachDom = document.getElementById('InputPreviewMin2')
    //       track?.track.attach(attachDom)
    //     }
    //   })
    // });
  }

  async doStopScreenSharing() {
    // add stop screen sharign logic here
    this.localScreenTrack.disable()
    // this.localScreenTrack.stop();
    setTimeout(() => {
      debugger;
      this.room.localParticipant.videoTracks.forEach(p => {
        if (p.track.name == "screen-share") {
          debugger;
          this.localScreenTrack.stop();
          // p.track.unpublishTrack(this.localScreenTrack);
          this.room.localParticipant.unpublishTrack(this.localScreenTrack);
          debugger;
        }
      })
      debugger;
      // this.room.localParticipant.unpublishTrack(this.localScreenTrack);
    }, 80)
    this.screenShareOn = false;
  }

  async doStartSession(roomName: string) {
    this.sideNavService.hideHeaderActions()
    let token = this.videoToken["token"]
    this.doUpdateCallStatusText(0)
    if (token) {
      connect(token, {
        name: roomName,
        tracks: this.localTracks ? this.localTracks : [],
        preferredVideoCodecs: ['VP8', 'H264'],
        audio: this.isCameraON ?? false,
        video: this.isMicON ?? false
      }).then((room) => {
        // @ts-ignore
        this.status = "STARTED";
        console.log('started')
        let that = this
        this.room = room;
        that.connected = true;
        this.doUpdateCallStatusText(1)
        this.videoService.setUserStartedAt(this.roomName).subscribe(() => { })
        // this.localVideoTrack.detach();
        if (this.localVideoTrack) {
          this.localVideoTrack.detach(document.getElementById("inputPreview"));
          let lvt = this.localVideoTrack.attach(document.getElementById("inputPreviewMin"));
          // lvt.style.transform = "scale(-1,1)";
        }
        room.participants.forEach(participantConnected);

        room.on('participantConnected', (p) => participantConnected(p));

        room.on('participantDisconnected', (participant) => that.participantDisconnected(participant, that))

        navigator.mediaDevices.ondevicechange = (event) => {
          // this.onDeviceChanged(event);
          // that.updateDeviceList("in connect")
        };


        // that.isLeaveCall = true;

        function participantConnected(participant) {
          that.remoteParticipantName = participant.identity;
          that.isRemoteJoined = true;
          if (participant.tracks?.size == 0) {
            that.isRemoteVideoMuted = true
            that.isRemoteAudioMuted = true
          }
          participant.on('trackSubscribed', track => {
            if (track.name == 'screen-share') {
              that.isRemoteScreenSharingEnabled = true
              that.remoteScreenShare = true;
              setTimeout(() => {
                that.room.participants.forEach(p => {
                  p.videoTracks.forEach(track => {
                    if (track?.track && track?.track.name !== "screen-share") {
                      console.log("track?.track", track?.track);
                      let remoteParticipant = document.getElementById('remoteParticipant')
                      track?.track.detach(remoteParticipant)
                      let attachDom = document.getElementById('InputPreviewMin2')
                      track?.track.attach(attachDom)
                    }
                  })
                });
              }, 100)
            }
            // [ . ]Incomplete In Therapist
            if (track.name == 'screen-share' && track.kind == "video" && track.isEnabled) {
              that.doShowSwapButton = true;
            }
            let remoteParticipant = document.getElementById('remoteParticipant')
            track.attach(remoteParticipant);
            if (track.kind == "video" && track.isEnabled) {
              let remoteParticipant = document.getElementById('remoteParticipant')
              // remoteParticipant.style.transform = "scale(-1,1)";
              track.attach(remoteParticipant);
              that.isRemoteVideoMuted = false
            }
            if (track.kind == "video" && track.isEnabled) {
              that.isRemoteVideoMuted = false
            }
            if (track.kind == "audio" && track.isEnabled) {
              that.isRemoteAudioMuted = false
            }
            track.on('enabled', (remoteTrack: RemoteTrack, participant: RemoteParticipant) => {
              let remoteParticipant = document.getElementById('remoteParticipant')
              track.attach(remoteParticipant);
              if (remoteTrack.kind == "video") {
                that.isRemoteVideoMuted = false;
              }
              if (remoteTrack.kind == "audio") {
                that.isRemoteAudioMuted = false;
                console.log('appending audio to track')
                // let audioElement = track.attach();
                // document.body.appendChild(audioElement);
              }
            });
            track.on('disabled', (track: RemoteTrack, participant: RemoteParticipant) => {
              if (track.kind == "video") {
                that.isRemoteVideoMuted = true
              }
              if (track.kind == "audio") {
                that.isRemoteAudioMuted = true
              }
            });
          });
          participant.on('trackUnsubscribed', (track) => {
            debugger
            if (track.name == 'screen-share') {
              setTimeout(() => {
                let screenShareEl = document.getElementById('remoteParticipant')
                track.detach(screenShareEl)
                // @ts-ignore
                screenShareEl.src = ""
              }, 100)
              // if (track.hasOwnProperty('stop')) {
              //   track.stop()
              // }
              // if (track.mediaStreamTrack) {
              //   track.mediaStreamTrack.stop()
              // }

              that.isRemoteScreenSharingEnabled = false
              that.remoteScreenShare = false;
              that.doShowSwapButton = false;


              // check is remote video enabled than attach it again
              doCheckAndAttachRemotePreview()
            }
            if (track.kind == "video" && track.isEnabled && track.name !== 'screen-share') {
              setTimeout(() => {
                let remoteParticipant = document.getElementById('remoteParticipant')
                // remoteParticipant.style.transform = "scale(-1,1)";
                track.attach(remoteParticipant);
                that.isRemoteVideoMuted = false

              }, 100)
            }
            if (track.kind == "audio") {
              that.isRemoteAudioMuted = true
              track.detach()
            }
            else if (track.kind == "video" && track.name !== 'screen-share') {
              debugger
              that.isRemoteVideoMuted = true
              track.detach()
            }
          });

          function doCheckAndAttachRemotePreview() {
            if (that.isRemoteVideoMuted == false) {
              that.room.participants.forEach(p => {
                console.log("p", p);
                p.videoTracks.forEach(track => {
                  console.log("track", track);
                  let remoteParticipant = document.getElementById('remoteParticipant')
                  if (track?.track) {
                    console.log("track?.track", track?.track);
                    track?.track.attach(remoteParticipant)
                  }
                })
              });
            }
          }
        };
      }).catch(err => {
        this.doUpdateCallStatusText(3)
        console.log('error', err)
        // this.status = "error:" + err.message
      });
    }
  }

  participantDisconnected(participant, that) {
    // document.getElementById(participant.sid).remove();
    console.log('participant disconnected', participant);
    that.remoteParticipantName = '';
    that.isRemoteJoined = false;
    that.isRemoteAudioMuted = true;
    that.isRemoteVideoMuted = true;

  }

  fullScreenToggle() {
    let videoCallBox = document.getElementById("videCallBox");
    this.isFullScreen = !this.isFullScreen;
    let that = this
    if (this.isFullScreen) {
      if (videoCallBox.requestFullscreen) {

        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);
        videoCallBox.requestFullscreen();

      }
    } else {
      document.exitFullscreen();
    }
    function exitHandler() {

      // @ts-ignore
      if (!document.fullscreenElement && !document?.webkitIsFullScreen && !document?.mozFullScreen && !document?.msFullscreenElement) {
        if (that.isFullScreen) {
          that.isFullScreen = false
        }

        document.removeEventListener('fullscreenchange', () => { });
        document.removeEventListener('webkitfullscreenchange', () => { });
        document.removeEventListener('mozfullscreenchange', () => { });
        document.removeEventListener('MSFullscreenChange', () => { });

      }
    }

  }
  doEnabledPictureInPicture() {
    if (this.isRemoteJoined) {
      this.isRemotePictureInPicture = true;
      const rmeote = document.getElementById('remoteParticipant')
      console.log(rmeote)
      // rmeote.setAttribute('poster', '{{ imageUrl }}/{{ sessionDetails?.userId?.imageUrl }}')
      // @ts-ignore
      rmeote?.requestPictureInPicture();
      // @ts-ignore
      window.addEventListener('leavepictureinpicture', (event) => {
        this.doDisablePictureInPicture()
      });
    }
  }
  doDisablePictureInPicture() {
    // @ts-ignore
    if (document.pictureInPictureElement) {
      // @ts-ignore
      document.exitPictureInPicture();
      // document.getElementById('remoteParticipant')?.requestPictureInPicture();
    }
    this.isRemotePictureInPicture = false;
  }
  endCall() {
    this.sideNavService.showHeaderActions()
    if (this.room) {

      this.room.disconnect();
      this.doDisablePictureInPicture();
      this.isRemoteJoined = false
      this.isRemoteVideoMuted = true
    }
    if (this.localVideoTrack) {

      this.localVideoTrack?.stop();
      this.localVideoTrack?.detach();

    }
    if (this.localAudioTrack) {

      this.localAudioTrack?.stop();
      this.localVideoTrack?.detach();

    }
    if (this.localTracks?.length > 0) {

      this.localTracks?.forEach((track) => {
        track.stop();
        track.detach();
      });
    }
    this.status = "CALL_ENDED";
    // this.videoService.statsLogger({ sessionId: this.roomName, deviceInfo: this.deviceInfo, eventName: "Room Disconnected - User" }).subscribe((res) => { });

  }
  async doToggleCamera() {
    // Assuming that this function will only be called when localVideoTrack is available
    // When the user is in preview mode
    if (!this.connected) {
      if (this.isCameraON) {
        this.localVideoTrack.disable();
        this.localVideoTrack.stop();
        this.isCameraON = false;
      } else {
        this.localVideoTrack = await createLocalVideoTrack();
        let localMediaContainer = document.getElementById("inputPreview");
        let lvt = this.localVideoTrack.attach(localMediaContainer);
        lvt.style.transform = "scale(-1,1,)"; // TODO: add option in ui "Mirror my video"
        this.isCameraON = true;
      }

    } else {
      // When this user is in session
      if (this.isCameraON) {
        this.localVideoTrack.disable();
        // this.localVideoTrack.stop();
        this.isCameraON = false;
        // to get Profile picture to rempte participant 
        setTimeout(() => {
          this.localVideoTrack.stop();
          this.room.localParticipant.unpublishTrack(this.localVideoTrack);
        }, 80)
      } else {
        this.localVideoTrack = await createLocalVideoTrack();
        let localMediaContainer = document.getElementById("inputPreviewMin");
        let lvt = this.localVideoTrack.attach(localMediaContainer);
        lvt.style.transform = "scale(-1,1,)"; // TODO: add option in ui "Mirror my video"
        this.isCameraON = true;
        this.room.localParticipant.publishTrack(this.localVideoTrack);
      }
    }
  }

  async doToggleMic() {
    if (!this.connected) {
      if (this.isMicON) {
        this.localAudioTrack.disable();
        this.localAudioTrack.stop();
        this.isMicON = false;
      } else {
        this.localAudioTrack = await createLocalAudioTrack();
        this.isMicON = true;
      }
    } else {
      if (this.isMicON) {
        this.localAudioTrack.disable();
        this.localAudioTrack.stop();
        this.isMicON = false;
        this.room.localParticipant.unpublishTrack(this.localAudioTrack);
      } else {
        this.localAudioTrack = await createLocalAudioTrack();
        this.room.localParticipant.publishTrack(this.localAudioTrack);
        this.isMicON = true;
      }
    }
  }


  handleMediaError(error: any) {
    console.log(error.name)
  }
  rejoin() {
    this.sideNavService.showHeaderActions()
    this.status = "ABOUT_TO_START";
    this.doHandleDeviceInitialization();
  }

  ngOnDestroy() {
    if (this.connected) {
      this.endCall()
    }
    this.isRemotePictureInPicture = false
    this.isRemoteAudioMuted = true
    this.sideNavService.showHeaderActions()
    window.location.reload();
  }

}
