<div (click)="closenavbar()">
    <div class="container-fluid" *ngIf="firstPart">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <br />
              <div class="sectionHeading">
                <h1><b>Caregiver Burnout Assessment</b></h1>
                <hr />
              </div>
            </div>
            <div class="col-md-3 mt-2 paddingCss">
              <div class="divTagCss">
                <img
                  class="img-fluid imageCss"
                  src="../../../assets/images/caregiver,-Burnout-stress.png"
                />
              </div>
            </div>
            <div class="col-md-8 paddingCss">
              <div class="textInside">
                <p>
                  Welcome to Felicity's online mental health test. It's free,
                  quick, anonymous & 100% confidential.
                  <br />
                </p>
                <br>
                <h2 class="htag"><b>Introduction</b></h2>
                <p>Burnout is a state of physical, emotional, and mental exhaustion. In caregivers, it can look like a shift from a loving and cheerful attitude to one that is uncaring and negative. When you don't get the support you require or when you try to take on more than you are capable of, whether physically or financially, you may experience symptoms of burnout. To avoid the negative effects of caregiving, it is essential to identify family caregivers who feel overwhelmed by the experience. The Zarit Burden Interview was originally created in the 1980s to assist caregivers and mental health specialists in assessing caregiver stress and burnout for caregivers of people with Alzheimer’s. Family caregivers who are worried about their own wellness can use this quick survey, consisting of 22 questions, to get a valid and objective assessment of the level of stress they are experiencing, much as professional psychologists and therapists do to examine clients who are providing care for loved ones. 
                </p>
                <h2 class="htag mt-4"><b>Instruction</b></h2>
                <p>In this self-report questionnaire, there are no right or wrong answers. Please indicate your responses to the following statements on a scale of 0 to 4, where 0 indicates ‘never’, and 4 indicates ‘nearly always’.</p>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="submitButtonClass text-center">
        <button class="thm-2" (click)="ShowHide()">Next</button>
      </div>
      <br />
    </div>
    <div class="container-fluid" *ngIf="secondPart">
      <form [formGroup]="freeReportForm" name="freeReportForm">
        <ul class="unstyled main-body">
          <div class="question-section">
            <h5>{{ questions[currentIndex].questionText }}</h5>
            <ul
              class="unstyled options_list"
              id="question_{{ questions[currentIndex].qno }}"
            >
              <div
                *ngFor="let answer of answers[currentIndex]; let idx1 = index"
                class="align-options"
              >
                <li>
                  <label class="labelCss">
                    <span class="options_listing">{{ answer.answerText }}</span>
                    <br />
                    <input
                      type="radio"
                      class="align-input"
                      value="{{ idx1 }}"
                      name="{{ questions[currentIndex].label }}"
                      formControlName="{{ questions[currentIndex].label }}"
                    />
                  </label>
                </li>
              </div>
            </ul>
          </div>
          <div class="form-btn">
            <button
              *ngIf="currentIndex == 0"
              type="button"
              class="btn-back"
              (click)="instruction1()"
            >
              BACK
            </button>
            <button
              *ngIf="currentIndex > 0"
              type="button"
              class="btn-back"
              (click)="previousPage()"
            >
              BACK
            </button>
            <button
              *ngIf="questions.length - 1 != currentIndex"
              class="btn btn-submit thm-2"
              (click)="currentIndexInc()"
            >
              Next
            </button>
            <button
              *ngIf="questions.length - 1 == currentIndex"
              class="btn btn-submit"
              type="submit"
              data-toggle="modal"
              data-target="#myModal"
              (click)="showModal()"
              [disabled]="!freeReportForm?.valid"
            >
              Submit
            </button>
            <div class="container-progress">
              <div [hidden]="!(progressPercentage > 0)" class="progressBarCls" [ngStyle]="{ 'width' : progressPercentage + '%' }"></div>
          </div>
          </div>
        </ul>
      </form>
    </div>
    <div class="modal fade" id="myModal" *ngIf="!hideModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Free Report</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <p>
              Great! You're almost done. Please enter these details to generate
              report.
            </p>
            <form [formGroup]="userForm2" name="userForm2">
              <input
                id="name"
                formControlName="fullname"
                name="fullname"
                placeholder="xyz"
                [ngClass]="{
                  'has-error':
                    !userForm2.controls.fullname.valid &&
                    userForm2.controls.fullname.touched,
                  'has-success':
                    userForm2.controls.fullname.valid &&
                    userForm2.controls.fullname.touched
                }"
              />
              <input
                type="email"
                id="email"
                formControlName="email"
                name="email"
                placeholder="xyz@gmail.com"
                [ngClass]="{
                  'has-error':
                    !userForm2.controls.email.valid &&
                    userForm2.controls.email.touched,
                  'has-success':
                    userForm2.controls.email.valid &&
                    userForm2.controls.email.touched
                }"
              />
              <div class="phoneHolder">
                <input
                  type="tel"
                  id="phone"
                  formControlName="phone"
                  name="phone"
                  class="form-control phoneControl"
                  [ngClass]="{
                    'has-error':
                      userForm2.controls.phone.errors?.invalidPhone ||
                      (userForm2.controls.phone.errors?.required &&
                        userForm2.controls.phone.touched)
                  }"
                  ng2TelInput
                  (hasError)="checkError($event)"
                  (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }"
                />
              </div>
              <div class="errorContainer" *ngIf="userForm2.controls.phone.errors?.invalidPhone">
                <p>Please enter a valid phone number.</p>
              </div>
            </form>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn-submit"
                value="Submit"
                (click)="onSubmit()"
                data-dismiss="modal"
                [disabled]="!userForm2?.valid"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  