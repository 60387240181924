<div (click)="closenavbar()">
  <div class="container-fluid" *ngIf="firstPart">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <br />
            <div class="sectionHeading">
              <h1><b>Financial Stress Assessment</b></h1>
              <hr />
            </div>
          </div>
          <div class="col-md-3 paddingCss">
            <div class="divTagCss">
              <img class="img-fluid imageCss" src="../../../assets/images/Financial-stress.png" />
            </div>
          </div>
          <div class="col-md-8 paddingCss">
            <div class="textInside">
              <p>
                Welcome to Felicity's online mental health test. It's free,
                quick, anonymous & 100% confidential.
                <br />
              </p>
              <h2 class="htag mt-3"><b>Introduction</b></h2>
              Excessive financial stress is frequently linked with other mental
              health issues such as depression and anxiety. With an increasing
              amount of study in the field of financial stress in recent years,
              there have been many developments in understanding how it affects
              other domains of one’s life – be it in young adulthood or in a
              later stage of life. Financial stress can affect other parts of an
              individual’s life, like their work or academic performance, and
              their relationship with friends and family - since maintaining
              social relationships to an extent requires some kind of financial
              stability - as well as their overall life satisfaction. The
              InCharge Financial Distress/Financial Well-Being Scale is one such
              measure that gives an idea of how much an individual’s financial
              condition might be affecting them. Developed by Aimee Prawitz and
              Thomas Garban in 2006, the instrument is a short questionnaire
              that assesses personal financial concepts that any adult could
              relate to.
            </div>
            <br />
            <div class="textInside">
              <h2 class="htag"><b>Instructions</b></h2>
              <p>
                In this self-report questionnaire, there are no right or wrong
                answers. You just need to select the option that most accurately
                fits your situation. There are a total of 8 questions, which
                should take you a maximum of 10 minutes to complete. The range
                of responses is from 1-10, where 1 indicates the lower end of
                the financial stress scale, and 10 indicates higher distress in
                the sentences given.
              </p>
              <label for="customRange1" class="form-label">Example range</label>
              <!-- <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
             {{value}} -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="submitButtonClass text-center">
      <button class="thm-2" (click)="ShowHide()">Next</button>
    </div>
    <br />
  </div>
  <div class="container-fluid" *ngIf="secondPart">
    <form [formGroup]="freeReportForm" name="freeReportForm">
      <ul class="unstyled main-body">
        <div class="question-section">
          <h5>{{ questions[currentIndex].questionText }}</h5>
          <!-- <input type="range" min="1" max="10" name="{{ questions[currentIndex].label }}"
            formControlName="{{ questions[currentIndex].label }}"> -->
          <span *ngFor="let question of questions; let i = index">
            <span *ngIf="i == currentIndex">
              <ngx-slider [value]="questions[currentIndex].label" [options]="options"
                name="{{ questions[currentIndex].label }}" formControlName="{{ questions[currentIndex].label }}"
                id="question_{{ questions[currentIndex].qno }}">
              </ngx-slider>
            </span>
          </span>
        </div>
        <div class="form-btn mt-5">
          <button *ngIf="currentIndex == 0" type="button" class="btn-back" (click)="instruction1()">
            BACK
          </button>
          <button *ngIf="currentIndex > 0" type="button" class="btn-back" (click)="previousPage()">
            BACK
          </button>
          <button *ngIf="questions.length - 1 != currentIndex" class="btn btn-submit thm-2" (click)="currentIndexInc()">
            Next
          </button>
          <button *ngIf="questions.length - 1 == currentIndex" class="btn btn-submit" type="submit" data-toggle="modal"
            data-target="#myModal" (click)="showModal()" [disabled]="!freeReportForm?.valid">
            Submit
          </button>
          <div class="container-progress">
            <div [hidden]="!(progressPercentage > 0)" class="progressBarCls"
              [ngStyle]="{ width: progressPercentage + '%' }"></div>
          </div>
        </div>
      </ul>
    </form>
  </div>
  <div class="modal fade" id="myModal" *ngIf="!hideModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Free Report</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <p>
            Great! You're almost done. Please enter these details to generate
            report.
          </p>
          <form [formGroup]="userForm2" name="userForm2">
            <input id="name" formControlName="fullname" name="fullname" placeholder="Name" [ngClass]="{
                'has-error':
                  !userForm2.controls.fullname.valid &&
                  userForm2.controls.fullname.touched,
                'has-success':
                  userForm2.controls.fullname.valid &&
                  userForm2.controls.fullname.touched
              }" />
            <input type="email" id="email" formControlName="email" name="email" placeholder="xyz@gmail.com" [ngClass]="{
                'has-error':
                  !userForm2.controls.email.valid &&
                  userForm2.controls.email.touched,
                'has-success':
                  userForm2.controls.email.valid &&
                  userForm2.controls.email.touched
              }" />
            <div class="phoneHolder">
              <input type="tel" id="phone" formControlName="phone" name="phone" class="form-control phoneControl"
                [ngClass]="{
                  'has-error':
                    userForm2.controls.phone.errors?.invalidPhone ||
                    (userForm2.controls.phone.errors?.required &&
                      userForm2.controls.phone.touched)
                }" ng2TelInput (hasError)="checkError($event)" (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                  initialCountry: userCountryCode.countryCode
                }" />
            </div>
            <div class="errorContainer" *ngIf="userForm2.controls.phone.errors?.invalidPhone">
              <p>Please enter a valid phone number.</p>
            </div>
          </form>
          <div class="modal-footer">
            <button type="submit" class="btn-submit" value="Submit" (click)="onSubmit()" data-dismiss="modal"
              [disabled]="!userForm2?.valid">
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>