<div (click)="closenavbar()">
  <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
  <div class="container-fluid" *ngIf="firstPart">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <br />
            <div class="sectionHeading">
              <h1><b>Adverse Childhood Experiences Assessment</b></h1>
              <hr />
            </div>
          </div>
          <div class="col-md-3 paddingCss">
            <div class="divTagCss">
              <img
                class="img-fluid imageCss"
                src="../../../assets/images/ace-report-img.webp"
              />
            </div>
          </div>
          <div class="col-md-8 paddingCss">
            <div class="textInside">
              <p>
                Welcome to Felicity's online mental health test. It's free,
                quick, anonymous & 100% confidential.
                <br />
              </p>
              <br>
              <h2 class="htag"><b>Introduction</b></h2>
              <p>Adverse Childhood Experiences (ACEs) Questionnaire is a self-report tool used to measure childhood trauma. There are a number of traumatic experiences a person might have had in their early to late childhood. This 10-item questionnaire helps understand them in detail. Developed by Felitti et. al. (1998), it has been used widely in assessing this delicate concept. </p>
              <p>
                The ACE Questionnaire is built to determine the level of risk for various mental health conditions.
              </p>
            </div>
            <br />
            <div class="textInside">
              <h2 class="htag"><b>Instructions</b></h2>
              <p>
                This Questionnaire will be asking you some questions about events that happened during your childhood; specifically, the first 18 years of your life. 
              </p>
              <p>In this self-report questionnaire, there are no right or wrong answers. You have to read each question carefully and answer them accurately according to how you feel.<br>Mark yes, even if one of the options is true for you. </p>
              <p>
                The information you provide by answering these questions explores how those problems may be impacting the challenges you are experiencing today.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="submitButtonClass text-center">
      <button class="thm-2" (click)="ShowHide()">Next</button>
    </div>
    <br />
  </div>
  <div class="container-fluid" *ngIf="secondPart">
    <form [formGroup]="freeReportForm" name="freeReportForm">
      <ul class="unstyled main-body">
        <h4><b>WHILE GROWING UP DURING THE FIRST 18 YEARS OF YOUR LIFE:</b></h4>
        <div class="question-section">
          <h5>{{ questions[currentIndex].questionText }}</h5>
          <div *ngIf="questions[currentIndex].subquestion">
            <div
              *ngFor="
                let que of questions[currentIndex].subquestion;
                let idx2 = index
              "
            >
              <h5>{{ que }}</h5>
              <p
                *ngIf="idx2 &lt; ((questions[currentIndex].subquestion.length)-1)"
                style="text-align: center"
              >
                OR
              </p>
            </div>
          </div>
          <ul
            class="unstyled options_list"
            id="question_{{ questions[currentIndex].qno }}"
          >
            <div
              *ngFor="let answer of answers[currentIndex]; let idx1 = index"
              class="align-options"
            >
              <li>
                <label class="labelCss">
                  <span class="options_listing">{{ answer.answerText }}</span>
                  <br />
                  <input
                    type="radio"
                    class="align-input"
                    value="{{ answer.value }}"
                    name="{{ questions[currentIndex].label }}"
                    formControlName="{{ questions[currentIndex].label }}"
                  />
                </label>
              </li>
            </div>
          </ul>
        </div>
        <div class="form-btn">
          <button
            *ngIf="currentIndex == 0"
            type="button"
            class="btn-back"
            (click)="instruction1()"
          >
            BACK
          </button>
          <button
            *ngIf="currentIndex > 0"
            type="button"
            class="btn-back"
            (click)="previousPage()"
          >
            BACK
          </button>
          <button
            *ngIf="questions.length - 1 != currentIndex"
            class="btn btn-submit thm-2"
            (click)="currentIndexInc()"
          >
            Next
          </button>
          <button
            *ngIf="questions.length - 1 == currentIndex"
            class="btn btn-submit"
            type="submit"
            data-toggle="modal"
            data-target="#myModal"
            (click)="showModal()"
            [disabled]="!freeReportForm?.valid"
          >
            Submit
          </button>
          <div class="container-progress">
            <div
              [hidden]="!(progressPercentage > 0)"
              class="progressBarCls"
              [ngStyle]="{ width: progressPercentage + '%' }"
            ></div>
          </div>
        </div>
      </ul>
    </form>
  </div>
  <div class="modal fade" id="myModal" *ngIf="!hideModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Free Report</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <p>
            Great! You're almost done. Please enter these details to generate
            report.
          </p>
          <form [formGroup]="userForm2" name="userForm2">
            <input
              id="name"
              formControlName="fullname"
              name="fullname"
              placeholder="name"
              [ngClass]="{
                'has-error':
                  !userForm2.controls.fullname.valid &&
                  userForm2.controls.fullname.touched,
                'has-success':
                  userForm2.controls.fullname.valid &&
                  userForm2.controls.fullname.touched
              }"
            />
            <input
              type="email"
              id="email"
              formControlName="email"
              name="email"
              placeholder="xyz@gmail.com"
              [ngClass]="{
                'has-error':
                  !userForm2.controls.email.valid &&
                  userForm2.controls.email.touched,
                'has-success':
                  userForm2.controls.email.valid &&
                  userForm2.controls.email.touched
              }"
            />
            <div class="phoneHolder">
              <input
                type="tel"
                id="phone"
                formControlName="phone"
                name="phone"
                class="form-control phoneControl"
                [ngClass]="{
                  'has-error':
                    userForm2.controls.phone.errors?.invalidPhone ||
                    (userForm2.controls.phone.errors?.required &&
                      userForm2.controls.phone.touched)
                }"
                ng2TelInput
                (hasError)="checkError($event)"
                (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)"
                (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                  initialCountry: userCountryCode.countryCode
                }"
              />
            </div>
            <div class="errorContainer" *ngIf="userForm2.controls.phone.errors?.invalidPhone">
              <p>Please enter a valid phone number.</p>
            </div>
          </form>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn-submit"
              value="Submit"
              (click)="onSubmit()"
              data-dismiss="modal"
              [disabled]="!userForm2?.valid"
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
