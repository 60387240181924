<div (click)="closenavbar()">
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user">
      <h3>Knowing yourself is the first step towards a healthy life</h3>
      <p><strong>Result:</strong></p>
      <p>
        You have been diagnosed with
        <span>{{ depressionFinal }}</span> and <span>{{ anxietyFinal }}</span
        >.
      </p>
      <p><strong>Score:</strong></p>
      <p>Depression Assessment : {{ depressionScore }}</p>
      <p>Anxiety Assessment : {{ anxietyScore }}</p>
      <button type="button" class="btn-report" (click)="reportData()">
        Email Report
      </button>
    </div>
  </div>

  <div class="graph-section">
    <div class="graph-left">
      <h5>Understanding Your Anxiety Assessment</h5>
      <img src="../../../assets/images/graph1.png" />
    </div>
    <div class="graph-right">
      <h5>Understanding Your Depression Assessment</h5>
      <img src="../../../assets/images/graph2.png" />
    </div>
  </div>

  <div class="container scores-section">
    <div class="about-scores report-user">
      <p class="para_scores">
        Scores greater than 15 on either assessment indicate a moderately severe
        to severe condition that warrants active treatment with psychotherapy,
        medications or a combination of both. It is highly recommended that you
        speak to a counselling therapist who will conduct a clinical interview &
        a thorough mental health examination including assessment of your level
        of distress & functional impairment.
      </p>
      <p class="para_scores">
        Scores ranging from 5-15 on either of the assessment indicate a mild to
        moderate condition that requires clinical judgement to determine the
        neccesity of treatment.
      </p>
      <p class="para_scores">
        Scores less than 5 indicate minimal symptoms & it is recommended that
        you monitor your condition on a regular basis.
      </p>
    </div>
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../contact-us">Contact Us</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../our-counselors">View Our Counselors</a>
      </button>
    </div>
    <div class="suggestion">
      <p>
        Symptoms of a mental health condition show that it is time to start a
        conversation with someone you trust! These symptoms can worsen over time
        & can cause disruptions in your day-to-day life & relationships.
      </p>
      <p class="seek_help"><strong>Seek help today!</strong></p>
    </div>
    <div class="helpfull-btn">
      <button class="get-started-btn start-btn-new">
        <a routerLink="../sign-up">Get Started</a>
      </button>
      <button class="how-work">
        <a routerLink="../how-it-works">How Counselling Works</a>
      </button>
    </div>
  </div>

  <div class="disclaimer-section">
    <div class="head-disclaimer">
      <h3>Disclaimer</h3>
      <p>
        This tool should be used for screening and monitoring symptom severity
        and cannot replace a clinical assessment and diagnosis. You are
        encouraged to share these results with a mental health professional.
        Felicity disclaims any liability, loss or risk incurred as a consequence
        , directly or indirectly, from the use of this assessment & it's
        results.
      </p>
    </div>
  </div>
</div>
