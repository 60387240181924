import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistAuthService } from "../therapist-auth.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: "app-therapist-login",
  templateUrl: "./therapist-login.component.html",
  styleUrls: ["./therapist-login.component.css"],
})
export class TherapistLoginComponent implements OnInit {
  isShowPassword: Boolean = false;
  authStatusSub: Subscription;
  auth2;
  isLoading = false;
  constructor(
    private sidenavService: SideNavBarService,
    private therapistAuth: TherapistAuthService
  ) { }
  loginForm = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
    ]),

    password: new FormControl("", Validators.required),
    rememberMe: new FormControl(false),
  });
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void { }

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  trimInput(){
    this.loginForm.patchValue({
      email : String(this.loginForm.value.email).trim()
    })
  }

  getFormControls() {

    return this.loginForm.controls;
  }

  showPasswordToggle() {
    this.isShowPassword = !this.isShowPassword;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    let userData = this.getFormControls();
    this.therapistAuth.loginTherapist(
      userData.email.value,
      userData.password.value,
      () => this.stopLoader()
    );
  }
  stopLoader() {
    this.isLoading = false
  }
}
