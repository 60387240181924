import { Component, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { AuthService } from 'src/app/auth/auth.service';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
@Component({
  selector: 'app-new-landing-page',
  templateUrl: './new-landing-page.component.html',
  styleUrls: ['./new-landing-page.component.scss']
})
export class NewLandingPageComponent implements OnInit {
  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2') slickModal2: SlickCarouselComponent;
  isLoggedIn: boolean = false;
  constructor(private sideNavService: SideNavBarService, private authService: AuthService) { }

  ngOnInit(): void {
    this.isLoggedIn=this.authService.getIsAuth()
  }


  therapistsData = [
    {
      name: "Puja Roy",
      title: "M.Sc in Health Psychology (Gold Medalist)",
      fileName: "pura-roy-slide.webp",
      id: "603b643a37e19c36ed87dfa4"
    },
    {
      name: "Sadaf Mir",
      title: "Master\'s in Clinical Psychology",
      fileName: "sadaf-mir-slide.webp",
      id: "619378a71192ee11e85d1d28"
    },
    {
      name: "Sakina Palitanawala",
      title: "MA in Clinical Psychology",
      fileName: "sakina-slide.webp",
      id: "60d5712353481e07848c98f6"
    },
    {
      name: "Dr. V S Ananthakrishnan",
      title: "MBBS, MD (Psychiatry)",
      fileName: "anantha-krishnan-slide.webp",
      id: "607fcf80a14fe9519e363e4c"
    },
    {
      name: "Zoha Merchant",
      title: "M.Phil. in Psychology",
      fileName: "zoha-slide.webp",
      id: "61b837471ff9c716c12706e3"
    }
  ]


  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        }
      }
    ]
  };




  slides = [
    {
      id: "1",
      MainTitle: "First Steps",
      featuredImage: "../../../../assets/images/plans-pricing/explore-plan-1.svg",
      sessionsCount: "3",
      titleDesc: "Vent out, discuss your issues & create a plan with your therapist",
      pricing: "2699",
      planId: "plan_b"
    },
    {
      id: "2",
      MainTitle: "Elevate",
      featuredImage: "../../../../assets/images/plans-pricing/first-steps-plan-2.svg",
      sessionsCount: "6",
      titleDesc: "Identify thought patterns, break negative thought loops & initiate positive ones",
      pricing: "5099",
      planId: "plan_p"
    },
    {
      id: "3",
      MainTitle: "Mind Your Mind",
      featuredImage: "../../../../assets/images/plans-pricing/mind-your-mind-plan-3.svg",
      sessionsCount: "12",
      titleDesc: "Initiate behavioural changes, learn how to manage your thoughts & emotions",
      pricing: "9599",
      planId: "plan_c"
    },

  ];
  // slideConfig = { "slidesToShow": 4, "slidesToScroll": 4 };



  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  closeNavBar(){
    this.sideNavService.closesidenav()
  }

  closeOtherCheckBox(id) {
    switch (id) {
      case 'collapsible1':
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible4');
        this.closeCheckBox('collapsible5');
        break;
      case 'collapsible2':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible4');
        this.closeCheckBox('collapsible5');
        break;
      case 'collapsible3':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible4');
        this.closeCheckBox('collapsible5');
        break;
      case 'collapsible4':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible5');
        break;
      case 'collapsible4':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible5');
        break;
      case 'collapsible5':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible4');
        break;
      default:
        break;
    }
  }

  closeCheckBox(id: string) {
    document.getElementById(id)['checked'] = false
  }

  // slickInit(e) {
  //   console.log('slick initialized');
  // }

  // breakpoint(e) {
  //   console.log('breakpoint');
  // }

  // afterChange(e) {
  //   console.log('afterChange');
  // }

  // beforeChange(e) {
  //   console.log('beforeChange');
  // }


  slickInit(e) {
    // console.log("slick initialized");
  }

  breakpoint(e) {
    // console.log("breakpoint");
  }

  afterChange(e) {
    // console.log("afterChange");
  }

  beforeChange(e) {
    // console.log("beforeChange");
  }

}
