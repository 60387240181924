<main class="topMenuMargin" (click)="closenavbar()">
  <section class="our-counsellors-hero-section">
    <div class="container-main-banner">
      <div class="our-counsellors-hero-text-wrapper">
        <h2 class="our-counsellors-hero-title">
          We have the best professionals - {{regionData?.verifiedString}}, who can help you
          heal!
        </h2>
        <p class="our-counsellors-hero-text">
          Not sure how to choose a {{regionData?.therapistStr}}?
        </p>
        <a
          class="primaryBtn"
          *ngIf="isLoggedIn"
          [routerLink]="['../questions']"
          [queryParams]="{ type: 'general' }"
          (click)="getRecommendation()"
          >Get a Recommendation</a
        >
        <a
          class="primaryBtn"
          *ngIf="!isLoggedIn"
          [routerLink]="['../sign-up']"
          [queryParams]="{ redirect: 'questions' }"
          (click)="getRecommendation()"
          >Get a recommendation</a
        >
      </div>
    </div>
  </section>
  <section class="our-counselling-therapists-section">
    <div class="filter-wrapper py-5">
      <div class="container-main-banner">
        <div class="filter-title-div">
          <h4 class="filter-title">Filter</h4>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="filters-main-holder">
                <form
                  [formGroup]="searchForm"
                  (ngSubmit)="getCounsellors(true)"
                  class="filterHolder"
                >
                  <input
                    type="text"
                    placeholder="Available Date"
                    [ngClass]="{ borderOrange: searchForm.value.date !== '' }"
                    class="filter-data filterItem"
                    bsDatepicker
                    (bsValueChange)="doApplyFilter()"
                    [bsConfig]="{
                      dateInputFormat: 'DD MMM Y',
                      containerClass: 'theme-orange'
                    }"
                    formControlName="date"
                    [minDate]="today"
                  />
                  <select
                  [ngClass]="{ borderOrange: searchForm.value.slot !== '' }"
                    class="filter-data slotSelect filterItem"
                    formControlName="slot"
                    placeholder="Slot"
                    (change)="doApplyFilter()"
                  >
                    <option value="" selected disabled>Select a slot</option>
                    <option *ngFor="let slot of slots" value="{{ slot }}">
                      {{ slot }}
                    </option>
                  </select>
                  <ng-select
                  [ngClass]="{ borderOrange: searchForm.value.specialization !== '' }"
                    class="specializationHolder filter-data filterItem"
                    formControlName="specialization"
                    placeholder="Specialization"
                    (change)="doApplyFilter()"
                    [clearable]="searchForm.value.specialization ? true : false"
                  >
                    <ng-option value="" selected disabled
                      >Specialization</ng-option
                    >
                    <ng-option
                      *ngFor="let area of specializeArea"
                      value="{{ area }}"
                      >{{ area }}</ng-option
                    >
                  </ng-select>
                  <ng-select 
                  [ngClass]="{ borderOrange: searchForm.value.language !== '' }"
                    class="specializationHolder filter-data filterItem"
                    formControlName="language"
                    placeholder="Language"
                    (change)="doApplyFilter()"
                    [clearable]="searchForm.value.language ? true : false"
                    *ngIf="regionData?.showLanguageFilter"
                  >
                    <ng-option value="" selected disabled>Language</ng-option>
                    <ng-option
                      *ngFor="let language of languages"
                      value="{{ language }}"
                      >{{ language }}</ng-option
                    >
                  </ng-select>
                </form>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 d-flex" style="flex-wrap: wrap">
              <div class="filterHolder search">
                <div
                  class="filter-search-wrapper position-relative mt-2 mt-sm-0 flex-x"
                >
                  <form
                    ngClass="responsive-form"
                    [formGroup]="searchForm"
                    (ngSubmit)="doApplyFilter()"
                  >
                    <div
                    [ngClass]="{ borderOrange: searchForm.value.search !== '' }"
                      style="
                        background: white;
                        min-width: 250px;
                        border-radius: 10px;
                        margin-right: 20px;
                      "
                    >
                      <input
                        type="text"
                        placeholder="Search..."
                        class="filter-search"
                        formControlName="search"
                        (keyup.enter)="doApplyFilter()"
                        (keyup)="getCounsellorsDebounce(true)"
                      />
                      <a (click)="doApplyFilter()" class="filter-search-icon"
                        ><i class="fa fa-search"></i
                      ></a>
                    </div>
                  </form>
                </div>
                <div
                  class="filter-search-wrapper-action position-relative mt-2 mt-sm-0 flex-x"
                >
                  <!-- <button class="btn filter-btn" (click)="doApplyFilter()">
                    Apply Filter
                  </button> -->
                  <button
                    *ngIf="isQueryParams"
                    class="btn reset-btn"
                    (click)="doResetFilter()"
                  >
                    Reset filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div *ngIf="isShimmerLoader; else loadingFalse">
        <div class="row mt-md-4 mt-lg-5">
          <div class="col-md-6 col-lg-4">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '15px',
                height: '300px',
                width: '95%',
                'margin-top': '30px',
                'margin-left': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-md-6 col-lg-4">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '15px',
                height: '300px',
                width: '95%',
                'margin-top': '30px',
                'margin-left': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-md-6 col-lg-4">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '15px',
                height: '300px',
                width: '95%',
                'margin-top': '30px',
                'margin-left': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-md-6 col-lg-4">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '15px',
                height: '300px',
                width: '95%',
                'margin-top': '30px',
                'margin-left': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-md-6 col-lg-4">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '15px',
                height: '300px',
                width: '95%',
                'margin-top': '30px',
                'margin-left': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-md-6 col-lg-4">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '15px',
                height: '300px',
                width: '95%',
                'margin-top': '30px',
                'margin-left': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <ng-template #loadingFalse>
        <div class="row mt-md-4 mt-lg-5">
          <div
            class="col-md-6 col-lg-4"
            *ngFor="let therapist of therapists; let inx = index"
          >
            <div class="counselling-therapist">
              <div class="counselling-therapist-profile">
                <img
                  src="{{ therapist?.imageURL }}"
                  alt="{{ therapist?.firstname }}"
                  class="counsellor-therapist-img"
                  onerror="this.src='../../../../assets/images/user.png';"
                />
                <div class="counselling-therapist-info">
                  <h3 class="counselling-therapist-name">
                    {{ therapist?.firstname }} {{ therapist?.lastname }}
                  </h3>
                  <p
                    class="counselling-therapist-title"
                    *ngIf="
                      therapist?.jobqualification == 'Other (Please Mention)';
                      else elsepart
                    "
                  >
                    {{ therapist?.jobqualificationOtherOptionAnswer }}
                  </p>
                  <ng-template #elsepart>
                    <p class="counselling-therapist-title">
                      {{ therapist?.jobqualification }}
                    </p>
                  </ng-template>
                </div>
              </div>
              <div class="counselling-therapist-detail mt-4">
                <img
                  src="../../../../assets/images/star-icon.svg"
                  alt="star icon"
                />
                <div class="detail-content">
                  <h4 class="details-title">Specialization</h4>
                  <p
                    class="details-desc"
                    *ngIf="therapist?.specializeareas !== null"
                  >
                    {{ therapist?.specializeareas?.join(", ") }}
                    <span
                      *ngIf="
                        !therapist.showMoreToggle &&
                        therapist?.specializeareasMore?.length > 0
                      "
                      >...
                    </span>
                    <span *ngIf="therapist?.specializeareasMore?.length > 0"
                      ><span *ngIf="therapist.showMoreToggle"> , </span>
                      <span
                        class="readMoreSection"
                        [ngClass]="{
                          showReadMoreSec: therapist.showMoreToggle
                        }"
                        >{{ therapist?.specializeareasMore.join(", ") }}</span
                      >
                      <a
                        class="readMoreToggle"
                        *ngIf="!therapist.showMoreToggle"
                        (click)="toggleShowMore(inx)"
                        >Show More</a
                      ><a
                        *ngIf="therapist.showMoreToggle"
                        class="readMoreToggle"
                        (click)="toggleShowMore(inx)"
                        >Show Less</a
                      ></span
                    >
                  </p>
                  <p
                    class="details-desc"
                    *ngIf="therapist?.specializeareas === null"
                  >
                    -
                  </p>
                </div>
              </div>
              <div class="counselling-therapist-detail mt-2">
                <img
                  src="../../../../assets/images/next-available-at.svg"
                  alt="available icon"
                />
                <div class="detail-content">
                  <h4 class="details-title">
                    <span *ngIf="!isDateFilterEnabled">Next</span> Available at
                  </h4>
                  <p class="details-desc" *ngIf="therapist?.nextAvailableAt">
                    {{ therapist?.nextAvailableAt }}
                  </p>
                  <p class="details-desc" *ngIf="!therapist?.nextAvailableAt">
                    -
                  </p>
                </div>
              </div>
              <div class="therapist-btns">
                <a
                  routerLink="../community-talk-therapists-detail/{{
                    therapist?._id
                  }}"
                  class="therapist-btn profile-btn"
                >
                  View Profile</a
                >
                <a
                  (click)="bookNow(therapist?._id)"
                  class="therapist-btn therapist-book-btn"
                  >Book Session</a
                >
              </div>
            </div>
          </div>
          <div
            class="col-12 d-flex justify-content-center"
            *ngIf="showNext && !isLoading"
          >
            <div class="pagination">
              <button
                class="page-btn nextBtnCss"
                (click)="nextPage()"
                *ngIf="showNext"
              >
                Load More {{regionData?.counsellorTherapistStrCap}}s
              </button>
            </div>
          </div>
          <div
            class="col-12 d-flex justify-content-center loadingSpinner"
            *ngIf="isLoading"
          >
            <div
              class="spinner-border"
              style="width: 2rem; height: 2rem"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
  <section class="start-your-journey">
    <h3>Kick-start your mental wellness journey with Felicity today!😊</h3>
    <div class="journeyBtn">
      <a
        class="therapist-btn profile-btn"
        routerLink="../sign-up"
        *ngIf="!isLoggedIn"
        >Sign Up</a
      >
      <a
        class="therapist-btn profile-btn"
        routerLink="../user-dashboard"
        *ngIf="isLoggedIn"
        >Sign Up</a
      >
      <a
        class="therapist-btn therapist-book-btn"
        routerLink="../online-counseling-psychologists/plans-pricing"
        >View Plans</a
      >
    </div>
  </section>
  <section class="testimonial-section">
    <h2 class="section-title">What People Say About <span>Felicity</span></h2>
    <div class="container">
      <div class="testimonial-slider-wrapper">
        <ngx-slick-carousel
          class="carousel testimonial-slider"
          #slickModal1="slick-carousel"
          [config]="testimonialsSlideConfig"
          (init)="slickInit($event)"
          (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)"
        >
          <div
            class="testimonial-slider-item"
            ngxSlickItem
            *ngFor="let review of userReviews"
          >
            <img
              src="../../../../assets/images/blog/quote.png"
              alt=""
              class="quote-sign"
            />
            <p class="client-name">{{ review.name }}</p>
            <!-- Client Name -->
            <span class="client-title">{{ review.work }}</span>
            <!-- Client Title -->
            <div class="client-stars">
              <fa-icon [icon]="faStar"></fa-icon>
              <fa-icon [icon]="faStar"></fa-icon>
              <fa-icon [icon]="faStar"></fa-icon>
              <fa-icon [icon]="faStar"></fa-icon>
              <fa-icon [icon]="faStar"></fa-icon>
            </div>
            <p class="client-review">
              {{ review.text }}
            </p>
          </div>
        </ngx-slick-carousel>
        <button
          aria-label="Previous"
          class="prev"
          (click)="prevSlideTestimonials()"
        >
          <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>
        <button
          aria-label="Next"
          class="next"
          (click)="nextSlideTestimonials()"
        >
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
      </div>
    </div>
  </section>
</main>
