import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";
import { Notification } from "./notification";

const notificationsUrl = environment.apiUrl + "/therapist/notifications/";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  socket: any;
  readonly url = environment.socketUrl;
  constructor(private http: HttpClient) {
    this.socket = io.io(this.url);
  }

  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: Notification) => {
        subscriber.next(data);
      });
    });
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  getNotifications() {
    return this.http.get<Notification[]>(
      notificationsUrl + "get-notifications"
    );
  }

  notificationPagin(skip, limit) {
    return this.http.get<any>(
      notificationsUrl + "notificationPagin?skip=" + skip + "&limit=" + limit
    );
  }

  markAsRead(idArray) {
    return this.http.post(notificationsUrl + "markAsRead", {
      idArray: idArray,
    });
  }

  getUnreadNotifications() {
    return this.http.get(notificationsUrl + "newNotifications");
  }
}
