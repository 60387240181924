<div class="topMenuMargin" (click)="closenavbar()">
    <div class="container mt-3">
      <p class="disclaimerTxt">
        <strong> <i class="fa fa-exclamation-triangle"></i>  Disclamer: </strong>This tool should be used for screening and
        monitoring symptom severity and cannot replace a clinical assessment and
        diagnosis. You are encouraged to share these results with a mental health
        professional for a better understanding.
      </p>
    <div class="report-user mt-3">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
    </div>
    <div class="report-section">
      <div class="img-section">
        <img src="../../../assets/images/meet.svg" />
      </div>
      <div class="report-user">
        <h3>Congratulations on taking that first step!</h3>
        <p class="mt-3"><strong>Your Score:</strong></p>
        <p>Loneliness: {{ lonelinessScore }}</p>
        <p class="mt-2"><strong>Your Result:</strong></p>
        <p>
          Your score indicates a <span>{{ lonelinessPhrase }} <span class="scrollTxt" (click)="scrollToElem('target')">Detailed Analysis</span> </span>
        </p>
        <br>
        <br>
        <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
      </div>
    </div>
  
    <div class="container graph-section mt-3">
      <div>
        <div>
          <p class="mb-3" *ngIf="lonelinessScore >= 20 && lonelinessScore <= 34">
            Your results are not a sign of concern, but read on to find out a more detailed analysis of your score as well as a few suggestions from Felicity that you can always use to improve your lifestyle.
          </p>
          <p class="mb-3" *ngIf="lonelinessScore >= 35 && lonelinessScore <= 64">
            Your results may be slightly concerning. Read the report to find out more suggestions on how you can improve your lifestyle, and, concurrently, your feelings of loneliness.
          </p>
          <p class="mb-3" *ngIf="lonelinessScore >= 65 && lonelinessScore <= 80">
            Seeing the report can be overwhelming, but Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere, to bring you on the path to having a more fulfilling life.

          </p>
        </div>
  
        <h5>Understanding your Loneliness Assessment</h5>
        <div class="graph-right">
          <img src="../../../assets/images/free-report-graphs/loneliness.png" />
        </div>
      </div>
    </div>
  
    <div class="container scores-section">
      <div class="about-scores report-user">
        <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
        <p class=" mb-3">
          The range of scores falls between 20 to 80, with 20 being the lowest score for loneliness, and 80 being the highest.
        </p>
        <p class="para_scores mb-3" *ngIf="lonelinessScore >= 20 && lonelinessScore <= 34">
          A low scorer on the loneliness scale, falling between 20-34, is predicted to have high scores in other areas of social mobility. This score also implies that you are socially connected enough to have people to rely on in times of need. Loneliness arises more as a result of not having somebody to fall back on during difficult times, be it financially, in illness, or just when we need someone to give us any kind of advice. Your perceived sense of loneliness being low, the chances are that you have someone around you that you can openly share your problems with, and your other interactions are fulfilling enough for you. Though you may still experience bouts of feeling lonely during times of change - as does everyone - you have the social and psychological resources to get back on your feet quickly.
        </p>
        <p class="para_scores mb-3" *ngIf="lonelinessScore >= 35 && lonelinessScore <= 49">
          A score between 35-49 indicates that you are experiencing moderate levels of loneliness. People generally fall into the category of feeling moderately lonely, and it could be because of a strained relationship, having social connections but not feeling like you ‘belong’, or not having a single person you can rely on without doubt or expectations. Loneliness can increase your chances of developing other negative mental health conditions if you experience loneliness in the long term. Though solitude is important for maintaining a sense of self and learning to be alone is also beneficial to one’s overall well-being, consider making little changes in your life - like trying out something new, going to a new place to eat - to meet new people, or indulge in volunteer work, which can give you a sense of purpose. If you would like to talk to someone about anything that’s bothering you, and you don’t think you have any support around you, you can always get in touch with Felicity.
        </p>
        <p class="para_scores mb-3" *ngIf="lonelinessScore >= 50 && lonelinessScore <= 64">
          A score between 50-64 suggests that you are experiencing slightly higher degrees of loneliness than the average individual. If there have been a few changes in your life recently - like moving across cities or countries, a recent fallout with a friend or a breakup with a romantic partner, or changes at school or work which have put you in an isolated situation - then that could possibly explain this high degree of loneliness. It is absolutely normal to experience spikes in loneliness at certain points in our life, however, if you think back and find that you haven’t experienced any alarming change in your life that could have led you to feeling this way, then you should reach out to a certified mental health professional so you can work on your feelings of loneliness. Ongoing loneliness is related to higher possibilities of developing other mental health conditions, so it is best that you take charge of your feelings as soon as you can. If you are willingly isolating, or do not have access to support and reliable friendships around you, then finding encouraging communities online is the next best option, if you want to work on your social relationships. However, you should consider getting in touch with a psychologist or psychotherapist at Felicity, with the assurance that you get the best help you need to develop strategies to decrease your feelings of loneliness
        </p>
        <p class="para_scores mb-3"  id="target" *ngIf="lonelinessScore > 65">
          A score above 65 indicates a high level of loneliness. At this point, your loneliness seems to have become an ongoing condition for a long period, and the social isolation you are experiencing is not healthy. You may be experiencing unrelenting feelings of being alone, separated, or divided from others, with the thought that it is impossible to form deeper relationships than you already have. Ongoing loneliness can even affect the most outgoing of persons - though it is common in that sense, it is not something that should be ignored, as loneliness can impact various facets of your life apart from your social relationships. Your work/academics, self-esteem and self-worth, energy levels in your body (feelings of burnout and fatigue are most common in people with high loneliness, especially when trying to make conversation with others) - all of these are impacted by your feelings of loneliness. The possibility of developing mental health conditions like depression and anxiety, as well as insomnia, are common for people who experience high levels of loneliness. Reaching out to a mental health professional is suggested for those who lie in this range, who can help you through this difficult time in your life by providing you with changes that you can make in your life, to ensure you can make the best out of the social relationships you form and engage with people in a positive and healthy way, and know that you’re not alone in this. You can reach out to the professionals at Felicity any time, from anywhere.
        </p>
      </div>
    </div>
  
    <!-- TODO: Add CSS -->
    <div class="container about-scores">
      <div class="">
        <h5 class="mb-3">Detailed Analysis</h5>
        <p class="mb-3">
          The UCLA Loneliness Scale is a unidimensional measure of loneliness. According to studies, those who score high on the UCLA Loneliness Scale struggle in other areas connected to social mobility. For instance, people with greater loneliness scores have poorer friendships and romantic relationships than those with lower scores (Russell et al., 1996). Poor relationships, according to other studies, can be both a cause and an effect of poverty. Job burnout, chronic illnesses, and physical inactivity are all linked to loneliness. Social mobility is, in turn, hampered by employment and health issues.
        </p>
        <p class="mb-3">
          Do take note that this was just a brief interpretation laid out for you based on existing research. It cannot be substituted for an authentic clinical diagnosis that caters to you specifically, so please consult a mental health professional for customised routes to a healthier you.
        </p>
      </div>
    </div>
  <div class="container">
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
    <!-- <div class="disclaimer-section">
        <div class="head-disclaimer">
          <h3>Disclaimer</h3>
          <p>
            This tool should be used for screening and monitoring symptom severity
            and cannot replace a clinical assessment and diagnosis. You are
            encouraged to share these results with a mental health professional.
            Felicity disclaims any liability, loss or risk incurred as a consequence
            , directly or indirectly, from the use of this assessment & it's
            results.
          </p>
        </div>
      </div> -->
  </div>
    