import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { NgSelectModule } from "@ng-select/ng-select";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutModule } from "@angular/cdk/layout";
import { HeaderComponent } from "./header/header.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UserLoginComponent } from "./auth/user-login/user-login.component";
import { UserSignupComponent } from "./auth/user-signup/user-signup.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FooterComponent } from "./footer/footer.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { GuestModule } from "./guest/guest.module";
import { AuthTokenInterceptor } from "./auth-token.interceptor";
import { UserModule } from "./user/user.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { TherapistLoginComponent } from "./therapist-auth/therapist-login/therapist-login.component";
import { TherapistModule } from "./therapist/therapist.module";
import { SharedModule } from "./shared/shared.module";
import { CalendarComponent } from "./calendar/calendar.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin
import interactionPlugin from "@fullcalendar/interaction"; // a plugin
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ErrorInterceptor } from "./error.interceptor";
import { BootstrapAlertModule } from "./shared/ng-bootstrap-alert/bootstrap-alert.module";
import { PostTraumaticStressDisorderTestComponent } from './free-reports/post-traumatic-stress-disorder-test/post-traumatic-stress-disorder-test.component';
import { AngryTestComponent } from './free-reports/angry-test/angry-test.component';
import { AdverseChildhoodExperiencesQuestionnaireComponent } from './free-reports/adverse-childhood-experiences-questionnaire/adverse-childhood-experiences-questionnaire.component';
import { ObsessiveCompulsiveDisorderTestComponent } from './free-reports/obsessive-compulsive-disorder-test/obsessive-compulsive-disorder-test.component';
import { DepressionTestComponent } from './free-reports/depression-test/depression-test.component';
import { AnxietyTestComponent } from "./free-reports/anxiety-test/anxiety-test.component";
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { WhatsappChatComponent } from './whatsapp-chat/whatsapp-chat.component';
import { ForgotPasswordComponent as UserForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ForgotPasswordComponent as TherapistForgotPasswordComponent } from './therapist-auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent as UserResetPassword } from './auth/reset-password/reset-password.component';
import { ResetPasswordComponent as TherapistResetPassword } from './therapist-auth/reset-password/reset-password.component';
import { AngularFireModule } from "@angular/fire";
import { environment } from "src/environments/environment";
import { AdCampaignComponent } from './guest/ad-campaign/ad-campaign.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AdhdTestComponent } from "./free-reports/adhd-test/adhd-test.component";
import { GriefTestComponent } from './free-reports/grief-test/grief-test.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { AnxietyTestGadComponent } from './free-reports/anxiety-test-gad/anxiety-test-gad.component';
import { AnxietyTestHamiltonComponent } from './free-reports/anxiety-test-hamilton/anxiety-test-hamilton.component';
import { RelationshipTestComponent } from './free-reports/relationship-test/relationship-test.component';
import { AvailableSlotsDialogComponent } from './available-slots-dialog/available-slots-dialog.component';
// import { NgxShimmerLoadingModule } from  'ngx-shimmer-loading';
// import { ShimmerModule } from '@sreyaj/ng-shimmer';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LottieModule } from 'ngx-lottie';
import { GoogleChartsModule } from 'angular-google-charts';
import { PageNotFound } from './page-not-found/page-not-found.component';
import { PersonalityAssessmentTestComponent } from './free-reports/personality-assessment-test/personality-assessment-test.component';
import { SatisfactionWithLifeTestComponent } from './free-reports/satisfaction-with-life-test/satisfaction-with-life-test.component';
import { SocialAnxietyTestComponent } from "./free-reports/social-anxiety-test/social-anxiety-test.component";
import { NarcissismTestComponent } from './free-reports/narcissism-test/narcissism-test.component';
import { AdultAdhdTestComponent } from "./free-reports/adult-adhd-test/adult-adhd-test.component";
import { ChildAdhdTestComponent } from "./free-reports/child-adhd-test/child-adhd-test.component";
import { LonelinessTestComponent } from './free-reports/loneliness-test/loneliness-test.component';
import { HelpdeskRedirectionComponent } from './helpdesk-redirection/helpdesk-redirection.component';

import { EatingDisordersTestComponent } from './free-reports/eating-disorders-test/eating-disorders-test.component';
import { WorkLifeBalanceTestComponent } from "./free-reports/work-life-balance-test/work-life-balance-test.component";
import { AcademicStressTestComponent } from './free-reports/academic-stress-test/academic-stress-test.component';
import { PsychologicalDistressTestComponent } from './free-reports/psychological-distress-test/psychological-distress-test.component';
import { CaregiverStressTestComponent } from './free-reports/caregiver-stress-test/caregiver-stress-test.component';

import { FinancialStressTestComponent } from './free-reports/financial-stress-test/financial-stress-test.component';
import { SelfEsteemTestComponent } from './free-reports/self-esteem-test/self-esteem-test.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CorporateLandingComponent } from './corporate-landing/corporate-landing.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { AuthHandlerComponent } from './auth-handler/auth-handler.component';
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}



FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UserLoginComponent,
    UserSignupComponent,
    FooterComponent,
    TherapistLoginComponent,
    CalendarComponent,
    PostTraumaticStressDisorderTestComponent,
    AngryTestComponent,
    AdverseChildhoodExperiencesQuestionnaireComponent,
    ObsessiveCompulsiveDisorderTestComponent,
    DepressionTestComponent,
    AnxietyTestComponent,
    WhatsappChatComponent,
    UserForgotPasswordComponent,
    TherapistForgotPasswordComponent,
    UserResetPassword,
    TherapistResetPassword,
    AdhdTestComponent,
    AdultAdhdTestComponent,
    ChildAdhdTestComponent,
    GriefTestComponent,
    AnxietyTestGadComponent,
    AnxietyTestHamiltonComponent,
    SocialAnxietyTestComponent,
    RelationshipTestComponent,
    AvailableSlotsDialogComponent,
    PageNotFound,
    PersonalityAssessmentTestComponent,
    SelfEsteemTestComponent,
    SatisfactionWithLifeTestComponent,
    NarcissismTestComponent,
    WorkLifeBalanceTestComponent,
    AcademicStressTestComponent,
    PsychologicalDistressTestComponent,
    CaregiverStressTestComponent,
    EatingDisordersTestComponent,
    FinancialStressTestComponent,
    LonelinessTestComponent,
    HelpdeskRedirectionComponent,
    CorporateLandingComponent,
    GetInTouchComponent,
    AuthHandlerComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxSliderModule,
    AppRoutingModule, SlickCarouselModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    LayoutModule,
    NgbModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    GuestModule,
    UserModule,
    TherapistModule,
    SharedModule,
    FullCalendarModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgSelectModule,
    BootstrapAlertModule,
    SocialLoginModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    Ng2TelInputModule,
    NgImageFullscreenViewModule,
    LottieModule.forRoot({ player: playerFactory }),
    GoogleChartsModule,
    // ShimmerModule
    // NgxShimmerLoadingModule

    // provideFirebaseApp(() => initializeApp({
    //   apiKey: "AIzaSyBe4r3nOpOeq0Wg_R3X868r2x0gHjllb6o",
    //   authDomain: "felicity-3a815.firebaseapp.com",
    //   projectId: "felicity-3a815",
    //   storageBucket: "felicity-3a815.appspot.com",
    //   messagingSenderId: "61689101115",
    //   appId: "1:61689101115:web:0f88f111fc165aec11551e",
    //   measurementId: "G-7SMDF3CLE1"
    // }))
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent],
})
export class AppModule { }
