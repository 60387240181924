import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RegionSelectionService } from "../region-selection.service";

const reportUrl = environment.apiUrl + "/guest/free-report";
@Injectable({
  providedIn: "root",
})
export class FreeReportService {
  constructor(private http: HttpClient, private regionSelectionService: RegionSelectionService) {}

  private salesIQData(email = "", name = "", phone = "") {
    if (email) {
      window["$zoho"].salesiq.visitor.email(email);
    }
    if (name) {
      window["$zoho"].salesiq.visitor.name(name);
    }
    if (phone) {
      window["$zoho"].salesiq.visitor.contactnumber(phone);
    }
  }

  generateReport(ansObj, test) {
    if(localStorage.getItem('regionData')){
      let regionData = JSON.parse(localStorage.getItem('regionData'));
      ansObj['regionCode'] = regionData['regionCode']
    }
    this.salesIQData(ansObj?.email, ansObj?.fullname, ansObj?.phone);
    if(localStorage.getItem('GTMcid')){
      ansObj['GTMcid'] = localStorage.getItem('GTMcid')
    }
    if(localStorage.getItem("partner_source")){
      ansObj["partner_source"] = localStorage.getItem("partner_source")
    }
    return this.http.post(reportUrl + "/" + test, ansObj);
  }
  generateReportForOCD(ansObj) {
    this.salesIQData(ansObj?.email, ansObj?.fullname, ansObj?.phone);
    if(localStorage.getItem('GTMcid')){
      ansObj['GTMcid'] = localStorage.getItem('GTMcid')
    }
    if(localStorage.getItem("partner_source")){
      ansObj["partner_source"] = localStorage.getItem("partner_source")
    }
    return this.http.post(reportUrl + "/ocd", ansObj);
  }
  getReport(id) {
    return this.http.get(reportUrl + "/" + id);
  }
  getReportByEmail(email) {
    return this.http.get(reportUrl + "/get-report-email/" + email);
  }
  sendReportToEmail(data) {
    return this.http.post(reportUrl + "/freeReportDownload", data);
  }
}
