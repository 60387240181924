import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { FreeReportService } from "../../free-report.service";
import clevertap from "clevertap-web-sdk";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-post-traumatic-stress-disorder-test-results",
  templateUrl: "./post-traumatic-stress-disorder-test-results.component.html",
  styleUrls: ["./post-traumatic-stress-disorder-test-results.component.css"],
})
export class PostTraumaticStressDisorderTestResultsComponent implements OnInit {
  pclFinal = "";
  pclScore;
  itemId;
  subScore1 = 0;
  subScore2 = 0;
  subScore3 = 0;
  subScore4 = 0;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private actRoute: ActivatedRoute,
    private freeReportService: FreeReportService,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.pclScore = res["report"]["reportDetails"]["score"];
        let answersArray = Object.values(res['report']["reportDetails"]["answers"]);
        console.log(answersArray)
        this.subScore1 = Number(answersArray[0]) + Number(answersArray[1]) + Number(answersArray[2]) + Number(answersArray[3]) + Number(answersArray[4])
        this.subScore2 = Number(answersArray[5]) + Number(answersArray[6])
        this.subScore3 = Number(answersArray[7]) + Number(answersArray[8]) + Number(answersArray[9]) + Number(answersArray[10]) + Number(answersArray[11]) + Number(answersArray[12]) + Number(answersArray[13])
        this.subScore4 = Number(answersArray[14]) + Number(answersArray[15]) + Number(answersArray[16]) + Number(answersArray[17]) + Number(answersArray[18]) + Number(answersArray[19])
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "OCD",
            "Score" : this.pclScore
          })
        }
      });
    });
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'ocd'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
