import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import { COMMON_DATA } from "src/app/shared/common";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistSignupService } from "../../therapist-signup.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: "app-become-therapist",
  templateUrl: "./become-therapist.component.html",
  styleUrls: ["./become-therapist.component.css"],
})
export class BecomeTherapistComponent implements OnInit {
  userData = {
    jobqualification: "'Other (Please Mention)'",
    jobqualificationOtherOptionAnswer: "qwerty",
    WorkingElseWhereApartFelicity:
      "'Yes, I will continue with my employment as a counsellor (please mention employer)'",
    WorkingElseWhereApartFelicityOtherEmployer: "qwerty qwerty qwerty",
  };
  message = "ChatMessage message";
  isLoading = false;
  firstPart: boolean = true;
  secondPart: boolean = false;
  thirdPart: boolean = false;
  showSelectedLanguages = false;
  becometherapistForm1: FormGroup;
  becometherapistForm2: FormGroup;
  becometherapistForm3: FormGroup;
  selectedLanguageArray = [];
  selectedSpecializeAreas = [];
  selectedtherapeuticExperties = [];
  selected;
  selectedOption;
  ages = COMMON_DATA.ages;
  gender = COMMON_DATA.gender;
  jobqualification = COMMON_DATA.jobqualification;
  counsellingexperience = COMMON_DATA.counsellingexperience;
  counsellingFelicityPerWeek = COMMON_DATA.counsellingFelicityPerWeek;
  WorkingElseWhereApartFelicity = COMMON_DATA.WorkingElseWhereApartFelicity;
  specializeareas1 = COMMON_DATA.specializeareas1;
  language = COMMON_DATA.language;
  ChatMessage = COMMON_DATA.ChatMessage;
  TimeSlott = COMMON_DATA.TimeSlott;
  AMORPM = COMMON_DATA.AMORPM;
  TimeZone = COMMON_DATA.TimeZone;
  therapeuticExpertiesOptions = COMMON_DATA.therapeuticExpertiesOptions;
  selectedResume: File = null;
  uploadResumeObj = null;
  showUploadWarning = false;
  showBack: boolean = true;
  constructor(
    private sidenavService: SideNavBarService,
    private confirmationService: ConfirmationDialogService,
    private therapistService: TherapistSignupService,
    private router: Router,
    private metaSeo: SeoMetaService
  ) { }


  doShowBackButton() {
    this.showBack = true;
  }


  doHideBackButton() {
    this.showBack = false;
  }
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  ngOnInit() {
    this.initForms();
    this.metaSeo.updateTitle("Become a Counseling Therapist with Felicity | Apply Now");
    this.metaSeo.updateMetaInfo("Become an online therapist & counselor with Felicity. Start your practice from home at zero cost. Apply today.", "How to start psychology practice, how to start therapy practice, how to start counseling practice")
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  initForms() {
    this.becometherapistForm1 = new FormGroup({
      firstname: new FormControl("", Validators.required),
      lastname: new FormControl("", Validators.required),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
      ]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      location: new FormControl(""),
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        ),
      ]),
    });
    this.becometherapistForm2 = new FormGroup({
      age: new FormControl("", Validators.required),
      gender: new FormControl("", Validators.required),
      jobqualification: new FormControl("", Validators.required),
      jobqualificationOtherOptionAnswer: new FormControl(),
      counsellingexperience: new FormControl("", Validators.required),
      counsellingFelicityPerWeek: new FormControl("", Validators.required),
      WorkingElseWhereApartFelicity: new FormControl("", Validators.required),
      WorkingElseWhereApartFelicityOtherEmployer: new FormControl(),
      AboutPerson: new FormControl("", Validators.required),
      specializeareas: new FormControl("", Validators.required),
      therapeuticExperties: new FormControl()
    });
    this.becometherapistForm3 = new FormGroup({
      language: new FormControl("", Validators.required),
      ChatMessage: new FormControl("", Validators.required),
      TimeSlott: new FormControl("", Validators.required),
      TimeZone: new FormControl("", Validators.required),
      RequiredInfrastructureForVideoCallDevice: new FormControl(
        false,
        Validators.required
      ),
      RequiredInfrastructureForVideoCallInternet: new FormControl(
        false,
        Validators.required
      ),
      RequiredInfrastructureForVideoCallEnvironment: new FormControl(
        false,
        Validators.required
      ),
      audioTherapy: new FormControl(
        false,
        Validators.required
      ),
      videoTherapy: new FormControl(
        false,
        Validators.required
      ),
      chatTherapy: new FormControl(
        false,
        Validators.required
      )
    });
  }


  onSubmit() {
    if (this.becometherapistForm1.invalid || this.becometherapistForm2.invalid || this.becometherapistForm3.invalid || this.uploadResumeObj === null) {
      if (this.uploadResumeObj === null) {
        this.showUploadWarning = true;
      }
      return;
    }
    // this.isLoading = true;

    this.confirmationService
      .confirm(
        "Are you sure you want to submit the application?",
        "",
        "Yes",
        "Cancel"
      )
      .subscribe((res) => {

        if (res) {

          // this.isLoading = false
          this.isLoading = true;
          this.doHideBackButton()
          let data = {
            ...this.becometherapistForm1.value,
            ...this.becometherapistForm2.value,
            ...this.becometherapistForm3.value,
          };
          data["specializeareas"] = this.selectedSpecializeAreas;
          data["selectedLanguageArray"] = this.selectedLanguageArray;
          data["therapeuticExperties"] = this.selectedtherapeuticExperties
          data["resume"] = this.uploadResumeObj
          this.therapistService.signUp(data).subscribe((res) => {

            this.isLoading = true
            this.router.navigate([
              "counseling-therapy-clinical-psychology-jobs/get-onboard",
            ]);
            this.isLoading = false;
          }, (err) => {

            this.doShowBackButton()
            this.isLoading = false;
          });
        }
        else {
          this.isLoading = false
          this.doShowBackButton()
        }
      });

  }


  addLanguageToArray() {
    let indexInArray = null;
    let languageToAdd = this.becometherapistForm3.value.language;
    if (this.becometherapistForm3.value.language) {
      this.selectedLanguageArray.find(function (language, index) {
        if (languageToAdd == language.language) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedLanguageArray.push({
          language: this.becometherapistForm3.value.language,
          fluency: "",
        });
      }
    }
  }
  removeLanguageFromArray(languageToRemove) {
    let indexInArray = null;
    this.selectedLanguageArray.find(function (language, index) {
      if (languageToRemove == language.language) {
        indexInArray = index;
      }
    });
    if (indexInArray != null) {
      this.selectedLanguageArray.splice(indexInArray, 1);
      if (this.selectedLanguageArray.length == 0) {
        this.showSelectedLanguages = false;
      }
    }
  }
  addSpecializeAreaToArray() {
    let indexInArray = null;
    let areaToAdd = this.becometherapistForm2.value.specializeareas;
    if (this.becometherapistForm2.value.specializeareas) {
      this.selectedSpecializeAreas.find(function (area, index) {
        if (areaToAdd == area) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedSpecializeAreas.push(areaToAdd);
      }
    }
  }
  removeSpecializeAreaFromArray(areaToRemove) {
    let indexInArray = null;
    this.selectedSpecializeAreas.find(function (area, index) {
      if (areaToRemove == area) {
        indexInArray = index;
      }
    });
    if (indexInArray != null) {
      this.selectedSpecializeAreas.splice(indexInArray, 1);
    }
  }
  addtherapeuticExperties() {
    let indexInArray = null;
    let areaToAdd = this.becometherapistForm2.value.therapeuticExperties;
    if (this.becometherapistForm2.value.therapeuticExperties) {
      this.selectedtherapeuticExperties.find(function (area, index) {
        if (areaToAdd == area) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedtherapeuticExperties.push(areaToAdd);
      }
    }
  }
  removeTherapeuticExpertiesFromArray(areaToRemove) {
    let indexInArray = null;
    this.selectedtherapeuticExperties.find(function (area, index) {
      if (areaToRemove == area) {
        indexInArray = index;
      }
    });
    if (indexInArray != null) {
      this.selectedtherapeuticExperties.splice(indexInArray, 1);
    }
  }
  indexTracker(index: number, value: any) {
    return index;
  }
  ShowHide = function () {
    this.firstPart = false;
    this.secondPart = true;
    this.thirdPart = false;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  showold2part = function () {
    this.firstPart = true;
    this.secondPart = false;
    this.thirdPart = false;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  ShowHideForNextPart = function () {
    this.firstPart = false;
    this.secondPart = false;
    this.thirdPart = true;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  showold3part = function () {
    this.firstPart = false;
    this.secondPart = true;
    this.thirdPart = false;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  uploadResume(event) {
    this.selectedResume = <File>event.target.files[0];
    const data = new FormData();
    data.append("upload", this.selectedResume);
    this.therapistService.uploadResume(data).subscribe((res) => {
      this.uploadResumeObj = res;
      this.showUploadWarning = false;
    })
  }

  showUploadBox() {
    this.uploadResumeObj = null;
  }

}
