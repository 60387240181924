<router-outlet></router-outlet>
<div class="welcomeBoxWrap">
  <div class="row justify-content-center">
    <div class="contentBoxWrapper col-md-6 col-sm-6">
      <h3>Need Help?</h3>
      <p>We are here for you.</p>
      <div class="buttonClass">
        <a routerLink="../requestform" class="thm-2">Raise New Request</a>
      </div>
    </div>
    <div class="welcomeImage col-md-6 col-sm-6">
      <img
        src="../../../assets/images/lapt.svg"
        class="img-fluid"
        draggable="false"
        alt=""
      />
    </div>
  </div>
</div>
<br />
<div class="faquchaticon">
  <div class="row justify-content-center">
    <div
      class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper"
      routerLink="../viewRequest"
    >
      <div class="contentBoxImg moreCss">
        <img
          src="../../../assets/images/eye.svg"
          class="img-fluid"
          draggable="false"
          alt=""
        />
        <h3 class="h3TagClass">View Old Requests</h3>
      </div>
    </div>
    <div
      class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper web-view"
    >
      <div class="contentBoxImg moreCss">
          <img
          src="../../../assets/images/whatsapp-qr.png"
          class="img-fluid"
          draggable="false"
          alt=""
        />
        <h3 class="h3TagClass">Chat With Us</h3>
      </div>
    </div>
    <div
    class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper mobile-view"
  >
    <div class="contentBoxImg moreCss">
      <a href="https://wa.me/916377327550">
        <img src="../../../assets/images/icons8-whatsapp.svg" class="img-fluid" draggable="false" alt="">
      <h3 class="h3TagClass">Chat With Us</h3>
    </a>
    </div>
  </div>
    <div
      class="col-lg-4 col-md-6 col-sm-6 contentBoxWrapper"
    >
    <div class="contentBoxImg moreCss">
        <a href="tel:+916377327550">
        <img
          src="../../../assets/images/call.svg"
          class="img-fluid"
          draggable="false"
          alt=""
        />
        <h3 class="h3TagClass">+916377327550</h3>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="sectionPadding-top">
  <div class="frequentlyAskedQues">
    <div class="row">
      <div class="col-md-6">
        <div class="sectionHeading">
          <h2>Frequently Asked Questions</h2>
          <hr />
        </div>
      </div>
    </div>
  </div>

  <div class="faqWrap">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Contract related</h4>
          <ul>
            <li>
              <a routerLink="../contract-related-faq"
                >Practicing outside felicity</a
              >
            </li>
            <li>
              <a routerLink="../contract-related-faq">Client interaction</a>
            </li>
            <li>
              <a routerLink="../contract-related-faq">Employment contract</a>
            </li>
          </ul>
          <div class="viewMore">
            <a routerLink="../contract-related-faq">See More</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Talk therapy related</h4>
          <ul>
            <li>
              <a routerLink="../talk-therapy-faq">My way or felicity way</a>
            </li>
            <li>
              <a routerLink="../talk-therapy-faq"
                >Expectations from therapists</a
              >
            </li>
            <li><a routerLink="../talk-therapy-faq">Safety and security</a></li>
          </ul>
          <div class="viewMore">
            <a routerLink="../talk-therapy-faq">See More</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Payment related</h4>
          <ul>
            <li>
              <a routerLink="../payment-related-faq">How much per session</a>
            </li>
            <li>
              <a routerLink="../payment-related-faq">When do I getpaid</a>
            </li>
            <li>
              <a routerLink="../payment-related-faq">How will I be paid</a>
            </li>
          </ul>
          <div class="viewMore">
            <a routerLink="../payment-related-faq">See More</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row extraCss">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Platform related</h4>
          <ul>
            <li>
              <a routerLink="../platform-related-faq">Selection criteria</a>
            </li>
            <li>
              <a routerLink="../platform-related-faq">Infra requirements</a>
            </li>
            <li>
              <a routerLink="../platform-related-faq">Evaluation criteria</a>
            </li>
          </ul>
          <div class="viewMore">
            <a routerLink="../platform-related-faq">See More</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Account related</h4>
          <ul>
            <li>
              <a routerLink="../account-related-faq"
                >Profile creation & update</a
              >
            </li>
            <li><a routerLink="../account-related-faq">Deleting account</a></li>
            <li>
              <a routerLink="../account-related-faq">Deactivating account</a>
            </li>
          </ul>
          <div class="viewMore">
            <a routerLink="../account-related-faq">See More</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="faqContentBox">
          <h4>Benefits related</h4>
          <ul>
            <li>
              <a routerLink="../benefits-related-faq"
                >Own practice v/s felicity</a
              >
            </li>
            <li>
              <a routerLink="../benefits-related-faq">Referring therapists</a>
            </li>
            <li>
              <a routerLink="../benefits-related-faq">Referring customers</a>
            </li>
          </ul>
          <div class="viewMore">
            <a routerLink="../benefits-related-faq">See More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
