import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { COMMON_DATA } from "src/app/shared/common";
import { ProfileService } from "../profile.service";
import clevertap from "clevertap-web-sdk";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.css"],
})
export class QuestionsComponent implements OnInit {
  questionsType = "general";
  ages = COMMON_DATA.ages;
  genders = COMMON_DATA.gender;
  moment = COMMON_DATA.moment;
  seekingHelp = COMMON_DATA.seekingHelp;
  therapyBefore = COMMON_DATA.therapyBefore;
  therapistgenderpreference = COMMON_DATA.therapistgenderpreference;
  language = COMMON_DATA.language;
  firstsessionAt = COMMON_DATA.firstsessionAt;
  relatesToChild = COMMON_DATA.relatesToChild;
  specializeareas1 = COMMON_DATA.specializeareasOptions;
  preferredMediumOptions = COMMON_DATA.preferredMediumOptions;
  selected;
  seekingHelpOption;
  selectedOptionspecializeareas;
  selectedLanguageArray = [];
  TimeSlott = [
    "7 AM - 11 AM",
    "11 AM - 3 PM",
    "3 PM - 7 PM",
    "7 PM - 11 PM",
    "11 PM - 3 AM",
    "3 AM - 7 AM",
  ];
  questionsForm: FormGroup;
  showSpecificArea = false;
  constructor(
    private actRoute: ActivatedRoute,
    private profileService: ProfileService,
    private router: Router,
    private alertService: BootstrapAlertService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  onSubmit() {

    if (this.questionsForm.invalid) {
      return this.alertService.alert(
        new BootstrapAlert(
          "Please Fill The Form",
          "alert-danger"
        )
      );;
    }
    let userData = this.questionsForm.value;
    delete userData["language"];
    userData["selectedLanguageArray"] = this.selectedLanguageArray;
    this.profileService.updateProfile(userData).subscribe((data) => {
      if (clevertap) {
        clevertap.event.push("Recommendation Form Submitted");
      }
      if (data["success"]) {
        if (this.questionsType == "general") {
          this.router.navigateByUrl("/our-recommendation?plan=general");
        }
        if (this.questionsType == "child") {
          this.router.navigateByUrl("/our-recommendation?plan=children");
        }
      }
    });
  }

  addLanguageToArray() {
    let indexInArray = null;
    let languageToAdd = this.questionsForm.value.language;
    if (this.questionsForm.value.language) {
      this.selectedLanguageArray.find(function (language, index) {
        if (languageToAdd == language.language) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedLanguageArray.push({
          language: this.questionsForm.value.language,
          fluency: "",
        });
      }
    }
  }

  removeLanguageFromArray(languageToRemove) {
    let indexInArray = null;
    this.selectedLanguageArray.find(function (language, index) {
      if (languageToRemove == language.language) {
        indexInArray = index;
      }
    });
    if (indexInArray != null) {
      this.selectedLanguageArray.splice(indexInArray, 1);
    }
  }

  indexTracker(index: number, value: any) {
    return index;
  }
  initForm() {
    if (this.questionsType == "general") {
      this.questionsForm = new FormGroup({
        specializeArea: new FormControl("", Validators.required),
        therapistgenderpreference: new FormControl("", Validators.required),
        language: new FormControl(""),
        firstsessionAt: new FormControl("", Validators.required),
        TimeSlott: new FormControl("", Validators.required),
        specifyNeed: new FormControl(""),
        preferredMedium: new FormControl("", Validators.required)
      });
      this.populateForm("general");
    }
    if (this.questionsType == "child") {
      this.questionsForm = new FormGroup({
        gender: new FormControl("", Validators.required),
        seekingHelp: new FormControl("", Validators.required),
        specializeArea: new FormControl(""),
        therapyBefore: new FormControl("", Validators.required),
        therapistgenderpreference: new FormControl("", Validators.required),
        language: new FormControl(""),
        firstsessionAt: new FormControl("", Validators.required),
        TimeSlott: new FormControl("", Validators.required),
        relatedToChild: new FormControl("", Validators.required),
        specifyNeed: new FormControl(""),
      });
      this.populateForm("child");
    }
  }

  populateForm(type: string = "general") {
    this.profileService.getProfile().subscribe((data) => {

      this.selectedLanguageArray = data.selectedLanguageArray;
      if (type === "general") {
        this.questionsForm.setValue({
          specializeArea: data.specializeArea ? data.specializeArea : null,
          therapistgenderpreference: data.therapistgenderpreference
            ? data.therapistgenderpreference
            : null,
          language: null,
          firstsessionAt: data.firstsessionAt ? data.firstsessionAt : null,
          TimeSlott: data.TimeSlott ? data.TimeSlott : null,
          specifyNeed: data.specifyNeed ? data.specifyNeed : null,
          preferredMedium: data.preferredMedium ? data.preferredMedium : null,
        });
      }
      if (type === "child") {
        this.questionsForm.setValue({
          age: data.age ? data.age : null,
          gender: data.gender ? data.gender : null,
          seekingHelp: data.seekingHelp ? data.seekingHelp : null,
          specializeArea: data.specializeArea ? data.specializeArea : null,
          therapyBefore: data.therapyBefore ? data.therapyBefore : null,
          therapistgenderpreference: data.therapistgenderpreference
            ? data.therapistgenderpreference
            : null,
          language: null,
          firstsessionAt: data.firstsessionAt ? data.firstsessionAt : null,
          TimeSlott: data.TimeSlott ? data.TimeSlott : null,
          relatedToChild: data.relatedToChild ? data.relatedToChild : null,
          specifyNeed: data.specifyNeed ? data.specifyNeed : null,
        });
      }
    });
  }

  onSpecializeAreaChanged(event) {
    event.forEach((area) => {
      if (area === 'Not Sure') {
        this.showSpecificArea = true;
      }
    })
  }

  showOptions() {
    this.showSpecificArea = false;
    let indexInArray = -1;
    if (this.questionsForm.value.specializeArea.length > 0) {
      this.questionsForm.value.specializeArea.find((area, index) => {
        if (area === 'Not Sure') {
          indexInArray = index
        }
      })
    }
    if (indexInArray > -1) {
      let tempArray = this.questionsForm.value.specializeArea
      tempArray.splice(indexInArray, 1)
      this.questionsForm.patchValue({
        specializeArea: tempArray
      })
    }
  }
}
