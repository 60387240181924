interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  {
    name: "dev-payu",
    src: "https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js",
  },
  {
    name: "prod-payu",
    src: "https://checkout-static.citruspay.com/bolt/run/bolt.min.js",
  },
  {
    name: "razor-pay",
    src: "https://checkout.razorpay.com/v1/checkout.js"
  },
  {
    name : "zoho-ma",
    src : ""
  },
  {
    name : "stripe",
    src : "https://js.stripe.com/v3/"
  }
];
