<!-- Web View -->

<section class="webView banner" id="topSection">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col-lg-8 col-xl-8 contentContainer">
        <div class="bannerHeading">
          <p class="mainHeading ChronicleDisplay">
            Trust is the foundation of our company
          </p>
          <p class="subHeading ChronicleDisplay">
            Let us grow your business together
          </p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 col-xl-4 bannerFormContainer">
        <div class="bannerBox">
          <h1 class="ChronicleDisplay">Let us call you!</h1>
          <form class="customInputForm mt-2" [formGroup]="corporateForm">
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Full name <span *ngIf="
                  corporateForm.controls.name.errors?.required &&
                  corporateForm.controls.name.touched
                " style="font-size: 16px;color: red;" class="errorContainer">
                  is required*
                </span></label>
              <input type="email" class="form-control customInput" formControlName="name" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Designation <span *ngIf="
                corporateForm.controls.designation.errors?.required &&
                corporateForm.controls.designation.touched
              " style="font-size: 16px;color: red;" class="errorContainer">
                  is required*
                </span></label>
              <input type="email" class="form-control customInput" formControlName="designation" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Official email address <span *ngIf="
                corporateForm.controls.officeEmailId.errors?.required &&
                corporateForm.controls.officeEmailId.touched
              " style="font-size: 16px;color: red;" class="errorContainer">
                  is required*
                </span>
                <span *ngIf="
                corporateForm.controls.officeEmailId.errors?.pattern &&
                corporateForm.controls.officeEmailId.touched
              " style="font-size: 16px;color: red;" class="errorContainer">
                  is invalid*
                </span>
              </label>
              <input type="email" class="form-control customInput" formControlName="officeEmailId" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Phone no. <span *ngIf="
                corporateForm.controls.phone.errors?.required &&
                corporateForm.controls.phone.touched
              " style="font-size: 16px;color: red;" class="errorContainer">
                  is required*
                </span>
                <span *ngIf="
              corporateForm.controls.phone.errors?.pattern &&
              corporateForm.controls.phone.touched
            " style="font-size: 16px;color: red;" class="errorContainer">
                  is invalid*
                </span></label>
              <input type="phone" class="form-control customInput" formControlName="phone" id="exampleInputEmail1"
                ng2TelInput (hasError)="checkError($event)" (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                  initialCountry: userCountryCode.countryCode
                }" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Name of Organization <span *ngIf="
                corporateForm.controls.companyName.errors?.required &&
                corporateForm.controls.companyName.touched
              " style="font-size: 16px;color: red;" class="errorContainer">
                  is required*
                </span></label>
              <input type="email" class="form-control customInput" formControlName="companyName" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Preferred time for callback</label>
              <span style="display: flex; justify-content: space-between;">
                <span class="inputDate">
                  <input type="text" class="form-control customInput" bsDatepicker [bsConfig]="bsConfig"
                    autocomplete="off" formControlName="preferredDate" placeholder="Date" />
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </span>
                <span class="inputDate">
                  <select class="form-control customInput" formControlName="preferredTime">
                    <option value="" disabled selected>Time</option>
                    <option *ngFor="let slot of timeSlots" value="{{slot}}">{{slot}}</option>
                  </select>
                  <fa-icon [icon]="faClock"></fa-icon>
                </span>
              </span>
            </div>

            <button type="submit" class="btn btn-primary btn-block customBtn mt-2" (click)="onSubmit()">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="webView sliderSection">
  <!-- [ + ] Company Heading -->
  <div (click)="closenavbar()" class="sliderHeading mt-100">
    <h1 class="allCenter ChronicleDisplay">Brands That Trust Us</h1>
  </div>

  <!-- [ + ] Company Images -->
  <div (click)="closenavbar()" class="company_img_slider">
    <div class="container-fluid">
      <div class="sliderConf">
        <ngx-slick-carousel class="carousel testimonial-slider" #slickModal1="slick-carousel"
          [config]="testimonialsSlideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
          <div class="" ngxSlickItem *ngFor="let company of companyImages; let idx = index">
            <div class="allCenter sliderImage">
              <img src="{{ company.cardimages }}" class="img-fluid" />
            </div>
          </div>
        </ngx-slick-carousel>
        <div class="d-flex flex-row justify-content-center align-items-center">
          <button aria-label="Previous" class="prev sliderIndicator mx-2" (click)="prevSlideTestimonials()">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
          </button>
          <button aria-label="Next" class="next sliderIndicator mx-2" (click)="nextSlideTestimonials()">
            <fa-icon [icon]="faChevronRight"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="webView" style="margin-top: 100px"></div>

<!-- Mobile,Tablet View -->
<section class="mobView">
  <div class="banner">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12 contentContainer allCenter">
          <div class="bannerHeading">
            <p class="mainHeading">Trust is the foundation of our company</p>
            <p class="subHeading">Let us grow your business together</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="mobView">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12 contentContainer">
        <div class="bannerBox">
          <h1 class="ChronicleDisplay">Let us call you!</h1>
          <form class="customInputForm mt-2" [formGroup]="corporateForm">
            <div class="form-group">
              <label class="customlbl">Full name <span *ngIf="
                corporateForm.controls.name.errors?.required &&
                corporateForm.controls.name.touched
              " style="font-size: 12px;color: red; margin-left: 2px;" class="errorContainer"> is required*
                </span></label>
              <input type="email" class="form-control customInput" formControlName="name" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Designation <span *ngIf="
                corporateForm.controls.designation.errors?.required &&
                corporateForm.controls.designation.touched
              " style="font-size: 12px;color: red; margin-left: 2px;" class="errorContainer">
                  is required*
                </span></label>
              <input type="email" class="form-control customInput" formControlName="designation" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Official email address <span *ngIf="
                corporateForm.controls.officeEmailId.errors?.required &&
                corporateForm.controls.officeEmailId.touched
              " style="font-size: 12px;color: red; margin-left: 2px;" class="errorContainer">
                  is required*
                </span>
                <span *ngIf="
                corporateForm.controls.officeEmailId.errors?.pattern &&
                corporateForm.controls.officeEmailId.touched
              " style="font-size: 12px;color: red; margin-left: 2px;" class="errorContainer">
                  is invalid*
                </span></label>
              <input type="email" class="form-control customInput" formControlName="officeEmailId" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Phone no. <span *ngIf="
                corporateForm.controls.phone.errors?.required &&
                corporateForm.controls.phone.touched
              " style="font-size: 12px;color: red; margin-left: 2px;" class="errorContainer">
                  is required*
                </span>
                <span *ngIf="
              corporateForm.controls.phone.errors?.pattern &&
              corporateForm.controls.phone.touched
            " style="font-size: 12px;color: red; margin-left: 2px;" class="errorContainer">
                  is invalid*
                </span></label>
              <input type="phone" class="form-control customInput" formControlName="phone" ng2TelInput
                (hasError)="checkError($event)" (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                  initialCountry: userCountryCode.countryCode
                }" />
            </div>
            <div class="form-group">
              <label class="customlbl">Name of Organization <span *ngIf="
                corporateForm.controls.companyName.errors?.required &&
                corporateForm.controls.companyName.touched
              " style="font-size: 12px;color: red; margin-left: 2px;" class="errorContainer">
                  is required*
                </span></label>
              <input type="email" class="form-control customInput" formControlName="companyName" />
            </div>
            <div class="form-group">
              <label class="customlbl" for="exampleInputEmail1">Preferred time for callback</label>
              <span style="display: flex; justify-content: space-between;">
                <span class="inputDate">
                  <input type="text" class="form-control customInput" bsDatepicker [bsConfig]="bsConfig"
                    autocomplete="off" formControlName="preferredDate" placeholder="Date" />
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </span>
                <span class="inputDate">
                  <select class="form-control customInput" formControlName="preferredTime">
                    <option value="" disabled selected>Time</option>
                    <option *ngFor="let slot of timeSlots" value="{{slot}}">{{slot}}</option>
                  </select>
                  <fa-icon [icon]="faClock"></fa-icon>
                </span>
              </span>
            </div>

            <button type="submit" class="btn btn-primary btn-block customBtn mt-2" (click)="onSubmit()">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="mobView" style="margin-top: 100px">
  <!-- [ + ] Company Heading -->
  <div (click)="closenavbar()" class="allCenter" style="width: 100vw">
    <h1 class="ChronicleDisplay companyHeading">Brands That Trust Us</h1>
  </div>
  <div class="row" style="width: 100vw">
    <div class="imageContainer">
      <div *ngFor="let item of companyImages" class="img-demo">
        <img src="{{ item.cardimages }}" class="img-fluid specificimg" />
      </div>
    </div>
  </div>
</section>
<div class="mobView" style="margin-top: 100px"></div>