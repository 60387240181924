import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const backendUrl = environment.apiUrl + '/therapist/schedule-session/';
@Injectable({
  providedIn: 'root'
})
export class AvailableSlotsService {

  constructor(private http: HttpClient) { }
  
  getSlotsForTherapist(startDate, endDate){
    return this.http.get( backendUrl + "timeslots" +'?startDate=' + startDate + '&endDate=' + endDate)
  }
  getSlotsForTherapistV2(startDate, endDate){
    return this.http.get( backendUrl + "v2/timeslots" +'?startDate=' + startDate + '&endDate=' + endDate)
  }

  addSlot(slotObj){
    return this.http.put( backendUrl + 'addTimeslot', slotObj )
  }

  deleteSlot(slotObj){
    return this.http.post( backendUrl + 'removeSlot', slotObj )
  }

  createRecurringSchedule(data){
    return this.http.post( backendUrl + 'recurring', data )
  }
  createRecurringScheduleV2(data,startDate,endDate){
    return this.http.post( backendUrl + 'v2/recurring'+'?startDate=' + startDate + '&endDate=' + endDate, data )
  }

  getRecurringSchedule(){
    return this.http.get(backendUrl + 'recurring')
  }
}
