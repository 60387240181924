import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const therapistScheduleUrl = environment.apiUrl + '/therapist/schedule-session/timeslots';
@Injectable({
  providedIn: 'root'
})
export class AvailableSlotsService {

  constructor(private http : HttpClient) { }

  getSlots(id, startDate, endDate){
    return this.http.get( therapistScheduleUrl + id + '?startDate=' + startDate + '&endDate=' + endDate)
  }

  getSlotsForTherapist(startDate, endDate){
    return this.http.get( therapistScheduleUrl + '?startDate=' + startDate + '&endDate=' + endDate)
  }
}
