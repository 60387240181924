import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TawkWidgetComponent } from "./tawk-widget/tawk-widget.component";
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { NoSanitizationPipe } from "./pipes/no-sanitization.pipe";
import { LazyLoadImageDirective } from './lazy-load-image.directive';
import { DateFromNow } from "./pipes/date-from-now.pipe";

@NgModule({
  declarations: [TawkWidgetComponent, DateAgoPipe, DateFromNow, NoSanitizationPipe, LazyLoadImageDirective],
  imports: [CommonModule],
  exports: [TawkWidgetComponent, DateAgoPipe, DateFromNow, NoSanitizationPipe, LazyLoadImageDirective],
})
export class SharedModule { }
