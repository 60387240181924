<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container mt-3">
    <p class="disclaimer-section"><strong>Disclamer: </strong>This tool is intended to be a screening tool and to monitor the likelihood
      that someone has ADHD. It cannot replace a clinical assessment and diagnosis. You are advised to share these
      results with a mental health professional for a better understanding.</p>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <ng-container>
      <div class="report-user mt-4">
        <p><strong>Your child's score</strong></p>
        <br>
        <p>Based on your response, your child's score is {{adhdScore}}</p>
        <p>To understand this score in detail, please get in touch with a healthcare provider who can help you interpret
          the score. This tool is generally paired with a clinical interview with the child’s parent or primary
          caretaker, done by clinicians trained in ADHD. </p>
        <br>
      </div>
    </ng-container>
  </div>
  <br />

  <div class="container about-scores">
    <h5>Demographics</h5>
    <table class="table table-hover">
      <tr>
        <td>Sex of the child</td>
        <td class="text-center">{{sex}}</td>
      </tr>
      <tr>
        <td>Age</td>
        <td class="text-center">{{age}}</td>
      </tr>
      <tr>
        <td>Grade</td>
        <td class="text-center">{{grade}}</td>
      </tr>
      <tr>
        <td>Completed By</td>
        <td class="text-center">{{completedBy}}</td>
      </tr>
    </table>
  </div>

  <div class="container graph-section mt-3">

    <div class="graph-right">
      <img src="../../../assets/images/free-report-graphs/child-adhd.png" />
    </div>
  </div>

  <div class="container about-scores">
    <div class="">
      <h5 class="mb-3 mt-4">Detailed Analysis</h5>
      <p class="mb-3">ASRS-6 is a screening tool for ADHD. This is made up of 2 components: Inattentive Presentation and
        Hyperactive/Impulsive Presentation
      </p>
      <p class="mb-3"><strong>Inattention subscale: </strong>With this subscale, your child may experience trouble
        staying focused and might easily get distracted or bored, leaving the task incomplete. Losing items like books,
        homework, toys, and stationery is a very frequent problem. Trouble staying organised or making plans; difficulty
        in following instructions and remembering things, often leading to careless mistakes. There is also a noted lack
        in communication skills, especially listening skills. Based on your response, your child's score is
        {{inattentiveness}}.</p>
      <p class="mb-3"><strong>Hyperactivity/Impulsivity subscale: </strong>Children in this subscale might be noticed
        engaging in constant fidgeting and squirming. They might have trouble sitting still and relaxing or playing
        quiet games. They are also short tempered and talk excessively. There is also a constant need to move around and
        climb on inappropriate things. Based on your response, your child's score is {{hyperactivity}}.</p>

      <p>
        <strong>
          Note: </strong>These scores are based on your responses and they do not represent a clinical diagnosis for your child.
          You should definitely consult a trained mental health professional to understand these scores and explain to
          them about your child’s current status. They would be the best person to guide you with a proper assessment,
          diagnosis and treatment plan.
      </p>
    </div>
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
  <br />
</div>