import { Component, OnInit } from '@angular/core';
import { SeoMetaService } from 'src/app/seo-meta.service';
import { SideNavBarService } from 'src/app/side-nav-bar.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private sidenavService:SideNavBarService, private metaSeo : SeoMetaService) { }

  ngOnInit(): void {
    this.metaSeo.updateTitle("Terms & Conditions | Felicity")
  }
  closenavbar(){
    this.sidenavService.closesidenav();
  }
}
