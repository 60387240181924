<div (click)="closenavbar()">
    <div class="container-fluid" *ngIf="firstPart">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <br />
              <div class="sectionHeading">
                <h1><b>Adult ADHD Assessment</b></h1>
                <hr />
              </div>
            </div>
            <div class="col-md-3 paddingCss">
              <div class="divTagCss">
                <img class="img-fluid imageCss" src="../../../assets/images/ADULT ADHD.png" />
              </div>
            </div>
            <div class="col-md-8 paddingCss">
              <div class="textInside">
                <p>
                  Welcome to Felicity's online mental health test. It's free,
                  quick, anonymous & 100% confidential.
                  <br />
                </p>
                <br>
                <h2 class="htag"><b>Introduction</b></h2>
                The Adult Self-Report Scale (ASRS) is a test developed by the World Health Organization (WHO) to help you
                recognize the signs and symptoms of adult ADHD. The ASRS contains 18 questions that are ranked on a scale
                of 0 to 4 and it takes 10 minutes to complete. This scale has been developed by Kessler, Adler and
                colleagues (2005) and has been widely used as a part of assessing ADHD symptoms.
              </div>
              <br />
              <div class="textInside">
                <h2 class="htag"><b>Instructions</b></h2>
                <p>
                  The test is divided into 2 parts.
                </p>
                <br />
                In this self-report questionnaire, there are no right or wrong answers. You have to read each question
                carefully and answer them accurately according to how you feel. Please answer the questions below, rating
                yourself on each of the criteria shown using the 5-point scale. As you answer each question, choose the
                option that best describes how you have felt and conducted yourself over the past 6 months.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="submitButtonClass text-center">
        <button class="thm-2" (click)="ShowHide()">Next</button>
      </div>
      <br />
    </div>
    <div class="container-fluid" *ngIf="secondPart">
      <form [formGroup]="freeReportForm" name="freeReportForm">
        <ul class="unstyled main-body">
          <div class="question-section">
            <h3>Part A</h3>
            <h5>
              <b>{{ questions[currentIndex].questionHeading }}</b>
            </h5>
            <h5>{{ questions[currentIndex].questionText }}</h5>
            <ul class="unstyled options_list" id="question_{{ questions[currentIndex].qno }}">
              <div *ngFor="let answer of answers[currentIndex]; let idx1 = index" class="align-options">
                <li>
                  <label class="labelCss">
                    <input type="radio" class="align-input" value="{{ idx1 }}" name="{{ questions[currentIndex].label }}"
                      formControlName="{{ questions[currentIndex].label }}" />
                    &nbsp;<span class="options_listing">{{
                      answer.answerText
                      }}</span>
                  </label>
                </li>
              </div>
            </ul>
          </div>
          <div class="form-btn">
            <button *ngIf="currentIndex == 0" type="button" class="btn-back" (click)="instruction1()">
              BACK
            </button>
            <button *ngIf="currentIndex > 0" type="button" class="btn-back" (click)="previousPage()">
              BACK
            </button>
            <button *ngIf="questions.length - 1 != currentIndex" class="btn btn-submit thm-2" (click)="currentIndexInc()">
              Next
            </button>
            <button *ngIf="questions.length - 1 == currentIndex" class="btn btn-submit" type="button"
              (click)="ShowHide1()" [disabled]="!freeReportForm?.valid">
              Next
            </button>
            <div class="container-progress">
              <div [hidden]="!(progressPercentage > 0)" class="progressBarCls"
                [ngStyle]="{ 'width' : progressPercentage + '%' }"></div>
            </div>
          </div>
        </ul>
      </form>
    </div>
    <div class="container-fluid" *ngIf="thirdPart">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <br />
              <div class="sectionHeading">
                <h1><b>Adult Attention Deficit Hyperactivity Disorder Test</b></h1>
                <hr />
              </div>
            </div>
            <div class="col-md-3 paddingCss">
              <div class="divTagCss">
                <img class="img-fluid imageCss" src="../../../assets/images/ADULT ADHD.png" />
              </div>
            </div>
            <div class="col-md-8 paddingCss">
              <div class="textInside">
                <h2 class="htag"><b>Part B</b></h2>
                <p>In this self-report questionnaire, there are no right or wrong answers. You have to read each question carefully and answer them accurately according to how you feel.  Please answer the questions below, rating yourself on each of the criteria shown using the 5-point scale. As you answer each question, choose the option that best describes how you have felt and conducted yourself over the past 6 months.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="submitButtonClass text-center">
        <button type="button" class="btn-back thm-2" (click)="previousPage1()">
          BACK
        </button>
        <button class="btn btn-submit thm-2" type="button" (click)="nextTo4()">
          Next
        </button>
      </div>
      <br />
    </div>
    <div class="container-fluid" *ngIf="fourthPart">
      <form [formGroup]="freeReportForm1" name="freeReportForm1">
        <ul class="unstyled main-body">
          <div class="question-section">
            <h4>Part B</h4>
            <h5>
              <b>{{ questions1[currentIndex].questionHeading }}</b>
            </h5>
            <h5>{{ questions1[currentIndex].questionText }}</h5>
            <ul class="unstyled options_list" id="question_{{ questions1[currentIndex].qno }}">
              <div *ngFor="let answer of answers1[currentIndex]; let idx1 = index" class="align-options">
                <li>
                  <label class="labelCss">
                    <input type="radio" class="align-input" value="{{ idx1 }}" name="{{ questions1[currentIndex].label }}"
                      formControlName="{{ questions1[currentIndex].label }}" />
                    &nbsp;<span class="options_listing">{{
                      answer.answerText
                      }}</span>
                  </label>
                </li>
              </div>
            </ul>
          </div>
          <div class="form-btn">
            <button *ngIf="currentIndex == 0" type="button" class="btn-back" (click)="instruction()">
              BACK
            </button>
            <button *ngIf="currentIndex > 0" type="button" class="btn-back" (click)="previousPage()">
              BACK
            </button>
            <button *ngIf="questions1.length - 1 != currentIndex" class="btn btn-submit thm-2" (click)="currentIndexInc()">
              Next
            </button>
            <button *ngIf="questions1.length - 1 == currentIndex" class="btn btn-submit" type="button" data-toggle="modal"
              data-target="#myModal" (click)="showModal()" [disabled]="!freeReportForm1?.valid">
              Submit
            </button>
            <div class="container-progress">
              <div [hidden]="!(progressPercentage1 > 0)" class="progressBarCls"
                [ngStyle]="{ 'width' : progressPercentage1 + '%' }"></div>
            </div>
          </div>
        </ul>
      </form>
    </div>
    <div class="modal fade" id="myModal" *ngIf="!hideModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Free Report</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <p>
              Great! You're almost done. Please enter these details to generate
              report.
            </p>
            <form [formGroup]="userForm2" name="userForm2">
              <input id="name" formControlName="fullname" name="fullname" placeholder="Name" [ngClass]="{
                  'has-error':
                    !userForm2.controls.fullname.valid &&
                    userForm2.controls.fullname.touched,
                  'has-success':
                    userForm2.controls.fullname.valid &&
                    userForm2.controls.fullname.touched
                }" />
              <input type="email" id="email" formControlName="email" name="email" placeholder="xyz@gmail.com" [ngClass]="{
                  'has-error':
                    !userForm2.controls.email.valid &&
                    userForm2.controls.email.touched,
                  'has-success':
                    userForm2.controls.email.valid &&
                    userForm2.controls.email.touched
                }" />
              <div class="phoneHolder">
                <input type="tel" id="phone" formControlName="phone" name="phone" class="form-control phoneControl"
                  [ngClass]="{
                    'has-error':
                      userForm2.controls.phone.errors?.invalidPhone ||
                      (userForm2.controls.phone.errors?.required &&
                        userForm2.controls.phone.touched)
                  }" ng2TelInput (hasError)="checkError($event)" (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }" />
              </div>
              <div class="errorContainer" *ngIf="userForm2.controls.phone.errors?.invalidPhone">
                <p>Please enter a valid phone number.</p>
              </div>
            </form>
            <div class="modal-footer">
              <button type="button" class="btn-submit" value="Submit" (click)="onSubmit()" data-dismiss="modal"
                [disabled]="!userForm2?.valid">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>