import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { FreeReportService } from "../../free-report.service";

@Component({
  selector: "app-final-result",
  templateUrl: "./final-result.component.html",
  styleUrls: ["./final-result.component.css"],
})
export class FinalResultComponent implements OnInit {
  depressionFinal = "";
  anxietyFinal = "";
  depressionScore;
  anxietyScore;
  itemId;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private actRoute: ActivatedRoute,
    private reportService: FreeReportService,
    private sidenavService: SideNavBarService
  ) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit() {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      // this.reportService.getReport(this.itemId).subscribe((data) => {
      //   if (data) {
      //     this.depressionScore = data["data"]["depressionScore"];
      //     this.anxietyScore = data["data"]["anxietyScore"];
      //     this.depressionfinalcal();
      //     this.anxietyfinalcal();
      //   }
      // });
    });
  }
  reportData() {
    this.confirmationService
      .confirm(
        "Congratulations! Your free report will be sent to your mail address.",
        "",
        "Ok",
        "close"
      )
      .subscribe((res) => {
        if (res) {
        }
      });
  }
  depressionfinalcal() {
    if (this.depressionScore >= 0 && this.depressionScore <= 4) {
      this.depressionFinal = "None or Minimal Depression";
    } else if (this.depressionScore >= 5 && this.depressionScore <= 9) {
      this.depressionFinal = "Mild Depression";
    } else if (this.depressionScore >= 10 && this.depressionScore <= 14) {
      this.depressionFinal = "Moderate Depression";
    } else if (this.depressionScore >= 15 && this.depressionScore <= 19) {
      this.depressionFinal = "Moderately Severe Depression";
    } else {
      this.depressionFinal = "Severe Depression";
    }
  }
  anxietyfinalcal() {
    if (this.depressionScore >= 0 && this.depressionScore <= 4) {
      this.anxietyFinal = "None or Minimal Anxiety";
    } else if (this.depressionScore >= 5 && this.depressionScore <= 9) {
      this.anxietyFinal = "Mild Anxiety";
    } else if (this.depressionScore >= 10 && this.depressionScore <= 15) {
      this.anxietyFinal = "Moderate Anxiety";
    } else {
      this.anxietyFinal = "Severe Anxiety";
    }
  }
}
