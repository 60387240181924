<div class="sectionPadding" (click)="closenavbar()">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-4">
        <div class="teamBoxing teamImageWrap">
          <!-- <img src="/images/{{ RecommndedTherapist.image }} "draggable="false" class="img-fluid imageCss"> -->
          <img
            src="{{ imageUrl }}/{{user?.imageUrl}}"
            draggable="false"
            class="img-fluid imageCss"
            [ngClass]="{ 'cltNotes' : isClientNotes }"
          />
        </div>
      </div>
      <div class="col-lg-5 col-md-7 col-sm-8">
        <div class="teamContentBox">
          <h4>{{ user?.name }}</h4>
          <div class="teameachContentDiv fontsize" *ngIf="user?.age">
            <h5>Age</h5>
            <p class="pTagcss">
              {{ user?.age }}
            </p>
          </div>
          <div class="teameachContentDiv fontsize" *ngIf="user?.gender">
            <h5>Gender</h5>
            <p class="pTagcss">
              {{ user?.gender }}
            </p>
          </div>
          <div class="teameachContentDiv" *ngIf="user?.selectedLanguageArray?.length > 0">
            <h5>Languages Spoken</h5>
            <p class="pTagcss">
              {{ user?.selectedLanguageArray }}
            </p>
          </div>
          <div class="teameachContentDiv" *ngIf="user?.therapyBefore">
            <h5>Have you taken counselling therapy before?</h5>
            <p class="pTagcss">{{ user?.therapyBefore }}</p>
          </div>
          <div class="teameachContentDiv" *ngIf="user?.doAtMoment">
            <h5>What do you do at the moment?</h5>
            <p class="pTagcss" *ngIf="user?.doAtMoment != 'Others'">
              {{ user?.doAtMoment }}
            </p>
            <p class="pTagcss" *ngIf="user?.doAtMoment == 'Others'">
              {{ user?.otherdoAtMoment }}
            </p>
          </div>
          <div class="teameachContentDiv" *ngIf="user?.seekingHelp">
            <h5>Are you seeking help for something Specific?</h5>
            <p
              class="pTagcss"
              *ngIf="user?.seekingHelp != 'I want counselling treatment for a specific mental health condition.'"
            >
              {{ user?.seekingHelp }}
            </p>
            <p
              class="pTagcss"
              *ngIf="
                user?.specializeArea != 'Others' &&
                user?.seekingHelp == 'I want counselling treatment for a specific mental health condition.'
              "
            >
              {{ user?.specializeArea }}
            </p>
            <p class="pTagcss" *ngIf="user?.specializeArea == 'Others'">
              {{ user?.specifyNeed }}
            </p>
          </div>
          <div class="teameachContentDiv" *ngIf="user?.therapistgenderpreference">
            <h5>Do you have gender preference for your Therapist?</h5>
            <p class="pTagcss">
              {{ user?.therapistgenderpreference }}
            </p>
          </div>
          <div class="teameachContentDiv" *ngIf="user?.TimeSlott">
            <h5>What is your preferred time for session??</h5>
            <p class="pTagcss">
              {{ user?.TimeSlott }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-7 col-sm-8">
        <div class="teamContentBox">
          <h4>Free Report Data</h4>
          <span *ngFor="let results of testResults; let inx = index;">
            <div class="teameachContentDiv1" *ngIf="results?.reportType === 'anxiety'">
              <h5>
                Anxiety Score:
                <span class="spanTagcss">
                  {{ results?.reportDetails?.score }}
                </span>
              </h5>
              <a
              *ngIf="results?.reportDetails.answers"
                class="aTagCss"
                (click)="dialogForAnswers('anxiety', inx)"
                data-toggle="modal"
                data-target="#dialogForAnswers"
              >
                Show individual responses
              </a>
            </div>
            <div class="teameachContentDiv1" *ngIf="results?.reportType === 'depression'">
              <h5>
                Depression Score:
                <span class="spanTagcss">
                  {{ results?.reportDetails?.score }}
                </span>
              </h5>
              <a
                class="aTagCss"
                *ngIf="results?.reportDetails.answers"
                (click)="dialogForAnswers('depression', inx)"
                data-toggle="modal"
                data-target="#dialogForAnswers"
              >
                Show individual responses
              </a>
            </div>
            <div class="teameachContentDiv1" *ngIf="results?.reportType === 'ace'">
              <h5>
                Adverse Childhood Experiences Score:
                <span class="spanTagcss">{{ results?.reportDetails?.score }}</span>
              </h5>
              <a
                class="aTagCss"
                *ngIf="results?.reportDetails.answers"
                (click)="dialogForAnswers('ace', inx)"
                data-toggle="modal"
                data-target="#dialogForAnswers"
              >
                Show individual responses
              </a>
            </div>
            <div class="teameachContentDiv1" *ngIf="results?.reportType === 'anger'">
              <h5>
                Angry Score:
                <span class="spanTagcss">
                  {{ results?.reportDetails?.score }}
                </span>
              </h5>
              <a
                class="aTagCss"
                *ngIf="results?.reportDetails.answers"
                (click)="dialogForAnswers('anger', inx)"
                data-toggle="modal"
                data-target="#dialogForAnswers"
              >
                Show individual responses
              </a>
            </div>
            <div class="teameachContentDiv1" *ngIf="results?.reportType === 'ocd'">
              <h5>Obsessive Compulsive Disorder Score</h5>
              <span class="spanTagcss">
                Compulsion Score: {{ results?.reportDetails?.obsessionScore
                }}<br />Obsession Score:
                {{ results?.reportDetails?.compulsionScore }} </span
              ><br />
              <a
                class="aTagCss"
                *ngIf="results?.reportDetails.answers"
                (click)="dialogForAnswers('ocd', inx)"
                data-toggle="modal"
                data-target="#dialogForAnswers"
              >
                Show individual responses
              </a>
            </div>
            <div class="teameachContentDiv1" *ngIf="results?.reportType === 'ptsd'">
              <h5>
                Post Traumatic Stress Disorder Score:
                <span class="spanTagcss">
                  {{ results?.reportDetails?.score }}
                </span>
              </h5>
              <a
                class="aTagCss"
                *ngIf="results?.reportDetails.answers"
                (click)="dialogForAnswers('ptsd', inx)"
                data-toggle="modal"
                data-target="#dialogForAnswers"
              >
                Show individual responses
              </a>
            </div>
          </span>
          <div class="modal fade" id="dialogForAnswers">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Answers
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                  <li
                    *ngFor="let question of questions; let idx = index"
                    class="question-section"
                  >
                    <h5 style="padding-left: 0px">
                      {{ question.questionText }}
                    </h5>
                    <div>
                      <p>{{ answerText[idx].answerText }}</p>
                    </div>
                  </li>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button type="submit" class="btn thm-2" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
