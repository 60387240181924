import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from '../../free-report.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-psychological-distress-test-results',
  templateUrl: './psychological-distress-test-results.component.html',
  styleUrls: ['./psychological-distress-test-results.component.css']
})
export class PsychologicalDistressTestResultsComponent implements OnInit {
  depressionScore = 0;
  psychologicalDistressScore = 0;
  anxietyScore = 0;
  psychologicalDistressPhrase = "";
  itemId;

  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  answersArray: any[]
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.psychologicalDistressScore = res["report"]["reportDetails"]["score"];
        let report = res['report'];
        this.answersArray = Object.values(report.reportDetails.answers)

        this.depressionScore = parseInt(this.answersArray[0],10) + parseInt(this.answersArray[3],10) + parseInt(this.answersArray[6],10) + parseInt(this.answersArray[7],10) + parseInt(this.answersArray[8],10) + parseInt(this.answersArray[9],10);

        this.anxietyScore = parseInt(this.answersArray[1],10) + parseInt(this.answersArray[2],10) + parseInt(this.answersArray[4],10) + parseInt(this.answersArray[5],10);
 
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "Psychological Distress",
            "Score" : this.psychologicalDistressScore
          })
        }
        this.psychologicalDistressPhrasecal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'psychological-distress'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  psychologicalDistressPhrasecal() {
    if (this.psychologicalDistressScore >= 10 && this.psychologicalDistressScore <= 19) {
      this.psychologicalDistressPhrase = "be well.";
    } else if (this.psychologicalDistressScore >= 20 && this.psychologicalDistressScore <= 24) {
      this.psychologicalDistressPhrase = "have mild distress.";
    } else if (this.psychologicalDistressScore >= 25 && this.psychologicalDistressScore <= 29) {
      this.psychologicalDistressPhrase = "have moderate distress.";
    } else {
      this.psychologicalDistressPhrase = "have severe distress.";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
