<div class="topMenuMargin">
    <div class="container-fluid">
        <div class="main-holder-section-wh">
            <div class="heading-section-wh">
                <div class="buttons-holder-wh">
                    <button [ngClass]="{'active-tab-wh' : activeTab === 'library' }" (click)="onChangeTab('library')">Library</button><button [ngClass]="{'active-tab-wh' : activeTab === 'community' }" (click)="onChangeTab('community')">Community</button>
                </div>
            </div>
            <div class="tab-holder-wh">
                <app-felicity-blog *ngIf="activeTab === 'library'"></app-felicity-blog>
                <app-discussion-forum *ngIf="activeTab === 'community'"></app-discussion-forum>
            </div>
        </div>
    </div>
</div>