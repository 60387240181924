<div class="headerContainer">
  <app-dashboard-header></app-dashboard-header>
</div>
<div class="pageWrapper sideNav">
  <div *ngIf="isHeaderActions" class="leftSideBarWrapper mobileNav" [ngClass]="{ showSideNav: isSideNav }">
    <div class="sideBarBox">
      <div class="sideBarFelicityLogo">
        <a routerLink="">
          <img src="../../../assets/images/logo-white.svg" draggable="false" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="sideBarMenu">
        <ul *ngIf="isHeaderActions">
          <li class="list" routerLinkActive="active">
            <a routerLink="dashboard" (click)="closeSidenav()">Dashboard</a>
            <a routerLink="view-old-plans" style="display: none" (click)="closeSidenav()"></a>
          </li>
          <li class="list" routerLinkActive="active">
            <a routerLink="profile" (click)="closeSidenav()">Profile</a>
            <a routerLink="changepassword" style="display: none" (click)="closeSidenav()">Change Password</a>
          </li>
          <li class="list" routerLinkActive="active">
            <a routerLink="sessions" (click)="closeSidenav()">Sessions</a>
            <a routerLink="join-session" style="display: none" (click)="closeSidenav()"></a>
          </li>
          <li class="list" routerLinkActive="active">
            <a routerLink="my-chats" (click)="closeSidenav()">My Chats</a>
          </li>
          <li class="list" routerLinkActive="active">
            <a routerLink="moodboard" (click)="closeSidenav()">Mood Journal</a>
            <a routerLink="add-thoughts" style="display: none" (click)="closeSidenav()"></a>
            <a routerLink="moodcheckin" style="display: none" (click)="closeSidenav()"></a>
          </li>
          <li class="list" routerLinkActive="active">
            <a routerLink="discussion" (click)="closeSidenav()">Discussion Forum</a>
            <a routerLink="userdiscussionform" style="display: none" (click)="closeSidenav()">Discussion Forum</a>
            <a routerLink="answerDiscussion" style="display: none" (click)="closeSidenav()"></a>
          </li>
          <li class="list" routerLinkActive="active">
            <a routerLink="helpdesk" (click)="closeSidenav()">Helpdesk</a>
            <a routerLink="requestform" style="display: none" (click)="closeSidenav()">Helpdesk</a>
            <a routerLink="viewRequest" style="display: none" (click)="closeSidenav()">Helpdesk</a>
            <a routerLink="subscription-faq" style="display: none" (click)="closeSidenav()">Helpdesk</a>
            <a routerLink="talk-therapy-faq" style="display: none" (click)="closeSidenav()">Helpdesk</a>
            <a routerLink="program-related-faq" style="display: none" (click)="closeSidenav()">Helpdesk</a>
            <a routerLink="account-related-faq" style="display: none" (click)="closeSidenav()">Helpdesk</a>
            <a routerLink="technology-related-faq" style="display: none" (click)="closeSidenav()">Helpdesk</a>
            <a routerLink="viewsingleticket" style="display: none" (click)="closeSidenav()">Helpdesk</a>
          </li>
          <!-- <li class="list"><a  >Logout</a></li> -->
        </ul>
      </div>
    </div>
    <div class="sideBarBottomImage">
      <img src="../../../assets/images/sidebarFoot.svg" class="img-fluid" draggable="false" alt="" />
    </div>
  </div>
  <div class="rightSideBodyWrapper">
    <div class="headWrapper">
      <div class="row justify-content-between">
        <div class="col-12">
          <div class="headMenuWrapper">
            <ul *ngIf="isHeaderActions">
              <li>
                <a routerLink="notifications"><img src="../../../assets/images/bell.png" draggable="false"
                    style="z-index: 999999999; cursor: pointer" class="img-fluid" /><span class="bellCircle">{{
                    newNotifications }}</span></a>
              </li>
              <li (click)="logOut()">
                <a style="cursor: pointer"><img src="../../../assets/images/logout.svg" draggable="false"
                    style="z-index: 999999999; cursor: pointer" class="img-fluid" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bodyPageWrapper" [ngClass]="{ dashboardNoPadding: isDashboard, chatTabCss: isChatTab }"
      (click)="!this.isMeetingSection && closeSidenav()">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>