import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from '../../free-report.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-academic-stress-test-results',
  templateUrl: './academic-stress-test-results.component.html',
  styleUrls: ['./academic-stress-test-results.component.css']
})
export class AcademicStressTestResultsComponent implements OnInit {
  academicStressScore = 0;
  itemId;
  workloadScore = 0;
  perceptionScore = 0;
  expectationScore = 0;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  answersArray: any[]
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.academicStressScore = res["report"]["reportDetails"]["score"];
        let report = res['report'];
        this.answersArray = Object.values(report.reportDetails.answers)

        this.expectationScore = parseInt(this.answersArray[5],10) + parseInt(this.answersArray[8],10) + parseInt(this.answersArray[12],10) + parseInt(this.answersArray[13],10);

        this.workloadScore = parseInt(this.answersArray[3],10) + parseInt(this.answersArray[4],10) + parseInt(this.answersArray[9],10) + parseInt(this.answersArray[10],10) + parseInt(this.answersArray[11],10) +parseInt(this.answersArray[12],10) + parseInt(this.answersArray[14],10) + parseInt(this.answersArray[15],10) + parseInt(this.answersArray[16],10)

        this.perceptionScore = parseInt(this.answersArray[0],10) + parseInt(this.answersArray[1],10) + parseInt(this.answersArray[2],10) + parseInt(this.answersArray[6],10) + parseInt(this.answersArray[7],10) + parseInt(this.answersArray[17],10)
 
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "Academic Stress",
            "Score" : this.academicStressScore
          })
        }
        // this.depressionfinalcal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'academic-stress'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  // depressionfinalcal() {
  //   if (this.depressionScore >= 0 && this.depressionScore <= 4) {
  //     this.depressionFinal = "negligible level of depression";
  //   } else if (this.depressionScore >= 5 && this.depressionScore <= 9) {
  //     this.depressionFinal = "mild level of depression";
  //   } else if (this.depressionScore >= 10 && this.depressionScore <= 14) {
  //     this.depressionFinal = "moderate level of depression";
  //   } else if (this.depressionScore >= 15 && this.depressionScore <= 19) {
  //     this.depressionFinal = "moderately severe level of depression";
  //   } else {
  //     this.depressionFinal = "severe level of depression";
  //   }
  // }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
