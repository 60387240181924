<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ title }}</h4>
  </div>
  <div class="modal-body">
    {{ message }}
    <div class="btnHolder">
      <button class="btn btn-accept" (click)="accept()">{{ btnOkText }}</button>
    </div>
  </div>
</div>
