<div class="bodyPageWrap">
  <div style="display: flex;align-items: center;margin-bottom: 20px;">
    <span><a routerLink="../helpdesk" style="font-size:16px; color: #07006e; cursor: pointer;text-decoration: underline;">Helpdesk</a></span>
    <span style="font-size:16px">&nbsp;>&nbsp;</span>
    <span style="font-size:16px; color: #808080;">Payment Related FAQ's</span> 
  </div>
    <div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div class="sectionHeading">
              <h2>Payment Related FAQ's</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div id="1" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
                <h4>How much do I get paid?</h4>
              <p class="ptagCss">
                You are paid Rs. 300 for a session of 45 minutes. In case of cancellation by the client without a heads up you are compensated for your time.</p>
            </div>
          </div>
          <div id="2" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
                <h4>When do I get paid?</h4>
              <p class="ptagCss">You are paid post the session once the client has submitted the rating. In case of a session rated 1 or 2 we refund a client’s money completely and you will not be paid.</p>
              </div>
          </div>
          <div id="3" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
                <h4>How do I get paid?</h4>
              <p class="ptagCss">
                  We do not share any personal information with a third party without consent. Also, we use banking grade encryption to ensure chatroom privacy is not breached. Also, you will be practicing therapy from the safe confines of your home.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div>
              <h3>
                Have More Questions?
                <span class="spanCss">(Access following heads)</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../contract-related-faq">Contract Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../talk-therapy-faq">Talk therapy Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../platform-related-faq">Platform Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
              <div class="questionWrapper">
                <b><a routerLink="../account-related-faq">Account Related</a></b>
              </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12">
              <div class="questionWrapper">
                <b><a routerLink="../benefits-related-faq">Benefits Related</a></b>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  