import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FullCalendarComponent, CalendarOptions } from "@fullcalendar/angular";
import { AvailableSlotsService } from "../available-slots.service";
import * as moment from 'moment';
@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.css"],
})
export class CalendarComponent implements OnInit {
  therapistId = "";
  events = [];
  eventsAndSlots;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    headerToolbar: {
      center: "title prev next",
      start: "",
      end: "",
    },
    events: [],
    dateClick : this.handleDayClicks.bind(this),
    eventClick : this.handleEventClicks.bind(this),
    datesSet : this.handleDates.bind(this),
    showNonCurrentDates : false,
    eventBackgroundColor : 'transparent',
    eventTextColor : '#F68A40',
    eventBorderColor : 'transparent'
  };
  constructor(
    private actRoute: ActivatedRoute,
    private availableSlotsService: AvailableSlotsService
  ) {}

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.therapistId = params.get("id");
    });
  }

  convertDate(date){
  }

  handleDates(args : any){
    this.events = [];
    let startDate = this.dateFormatterForQuery(args.startStr)
    let endDate = this.dateFormatterForQuery(args.endStr)
    this.availableSlotsService
    .getSlots(this.therapistId, startDate, endDate)
    .subscribe((data) => {
      this.eventsAndSlots = data["availability"]
      let now = moment()
      data["availability"].forEach((element) => {
        let date = element.date
        let dayEvent = moment(date,'DD_MM_YYYY')
        if(moment(now).isSameOrBefore(dayEvent, "day")){
          this.events = this.events.concat({
            id: element._id,
            title: "Available",
            start: dayEvent['_d'],
            allDay : true,
          });
        }
      });
      this.calendarOptions.events = this.events
    });
  }

  dateFormatterForQuery(date: string){
    date = date.substring(0,10);
    let splitDate = date.split("-")
    let finalDateString = ''
    finalDateString = splitDate[2] + '_' + splitDate[1] + '_' + splitDate[0]
    return finalDateString;
  }

  handleDayClicks(args : any){
  }

  handleEventClicks( args : any ){
    this.findSlotById(args.event._def.publicId)
  }

  findSlotById(id){
    let eventSlots = this.eventsAndSlots.find(event => event._id == id);
    let slots = eventSlots.slots;
  }
}
