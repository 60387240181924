import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from "../../free-report.service";
import { ViewportScroller } from "@angular/common";
@Component({
  selector: "app-anxiety-test-results",
  templateUrl: "./anxiety-test-results.component.html",
  styleUrls: ["./anxiety-test-results.component.css"],
})
export class AnxietyTestResultsComponent implements OnInit {
  anxietyFinal = "";
  report;
  anxietyScore;
  itemId;
  answersArray = []
  bodily = 40;
  cognitive = 60;
  title = 'Anxiety Analysis';
   type = 'PieChart';
   data = [
      ['Bodily Symptoms', this.bodily],
      ['Cognition and Emotional', this.cognitive], 
   ];
   options = {    
   };
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) { 
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res["report"]
        this.anxietyScore = res["report"]["reportDetails"]["score"];
        this.answersArray = Object.values(this.report.reportDetails.answers);
        this.bodily = parseInt(this.answersArray[0],10) + parseInt(this.answersArray[1],10) + parseInt(this.answersArray[2],10) + parseInt(this.answersArray[6],10)
        this.cognitive = parseInt(this.answersArray[3],10) + parseInt(this.answersArray[4],10) + parseInt(this.answersArray[5],10)
        this.data = [
          ['Bodily Symptoms', this.bodily],
          ['Cognition and Emotional', this.cognitive], 
       ];
        if (clevertap) {
          clevertap.event.push("Free Report Ended", {
            "Report Name": "Anxiety",
            "Score": this.anxietyScore
          })
        }
        this.anxietyfinalcal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id: this.itemId,
      reportType: 'anxiety'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if (res) {
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  anxietyfinalcal() {
    if (this.anxietyScore >= 0 && this.anxietyScore <= 4) {
      this.anxietyFinal = " negligible level of anxiety.";
    } else if (this.anxietyScore >= 5 && this.anxietyScore <= 9) {
      this.anxietyFinal = " mild level of anxiety.";
    } else if (this.anxietyScore >= 10 && this.anxietyScore <= 15) {
      this.anxietyFinal = " moderate level of anxiety.";
    } else {
      this.anxietyFinal = " severe level of anxiety.";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
