import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HelpDeskService } from "../help-desk.service";

@Component({
  selector: "app-view-old-requests",
  templateUrl: "./view-old-requests.component.html",
  styleUrls: ["./view-old-requests.component.css"],
})
export class ViewOldRequestsComponent implements OnInit {
  isLoading = true;
  currentFilter = "OPEN";
  ticketdetails;

  isRequestLoading = true;
  filteredTickets = [];
  constructor(
    private helpdeskService: HelpDeskService,
    private router: Router
  ) { }

  ngOnInit() {
    this.helpdeskService.getOldRequests().subscribe((data) => {
      this.ticketdetails = data;
      this.onFilterRequests(this.currentFilter);
      this.isRequestLoading = false;
    }, (err) => {
      this.isRequestLoading = false;
    });
  }

  onFilterRequests(status) {
    this.isLoading = true;
    this.filteredTickets = [];
    this.currentFilter = status;
    this.filteredTickets = this.ticketdetails.filter(
      (ticket) => ticket.status == status
    );
    this.isLoading = false;
    this.isRequestLoading = false;
  }
  redirectTo(id) {
    this.router.navigateByUrl("user-dashboard/viewsingleticket?ticketId=" + id);
  }
}
