<div class="bodyPageWrap">
    <div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div class="sectionHeading">
              <h2>Technology Related FAQ's</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div id="1" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>
                What should i do to have a ideal experience of the Felicity
                platform?
              </h4>
  
              <p class="ptagCss">
                Although Felicity can be accessed from any browser pr device but
                for an even ideal experience use the latest version of Google
                Chrome for the web and any of our Android and iOS apps for mobile.
              </p>
            </div>
          </div>
          <div id="2" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>
                I was trying to login, but my screen suddenly froze.What should i
                do?
              </h4>
  
              <p class="ptagCss">
                Please check your internet connection, if case the connection is
                working properly try refreshing the screen and try again. In case
                the connection is working properly and you are still facing
                issues, please contact our helpline and someone will be with you
                shortly.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div >
              <h3>
                Have More Questions?
                <span  class="spanCss">(Access following heads)</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../subscription-faq">Subscription Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../talk-therapy-faq">Talk therapy Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../program-related-faq">Program Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../account-related-faq">Account Related</a></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>