<div class="paddingTermpage" (click)="closenavbar()">
  <div class="container">
    <div class="pageHeading">
      <h2>Terms Of Service</h2>
      <hr />
      <h3>Felicity I Orgfit Global Consultancy Private Limited</h3>
      <h4>Last Updated: 8th June 2020</h4>
    </div>
    <div class="contentClass">
      <h3>CONTEXT</h3>
      <p>
        The Website is owned and operated by Felicity (registered as: Orgfit
        Global Consultancy Private Limited) (“Company”), a private limited
        company, incorporated under the provisions of the Companies Act, 2013
        and having its registered office at D-80, Sapphire Space, Ghiya Marg,
        Bani Park, Jaipur– 302016.
      </p>
      <p>
        These terms of usage (“Terms”) govern your use of the Website and
        Services provided therein (“Services”). By using or visiting the
        Website, or by using any content or information provided as part of the
        Website/ Services, you shall be deemed to have read, understood, and
        accepted to be bound by these Terms. These Terms are a legal contract
        between you and Felicity (registered as: Orgfit Global Consultancy
        Private Limited), it is important that you review the Terms carefully
        before accessing or using the Website. These Terms are not intended to
        alter in any way or limit the terms or conditions of any other agreement
        that you may have with Felicity, including without limitation the
        privacy policy ("Privacy Policy").
      </p>
      <h3>APPLICABILITY</h3>
      <p>
        For the purpose of these Terms, wherever the context so requires "You",
        “Your” or “User” shall mean any natural person who uses the Website for
        availing the Services. The term “We”, “Us”, “Our”, “Felicity” shall mean
        the Company (registered as: Orgfit Global Consultancy Private Limited),
        its employees, and authorised agents that perform any services on the
        Company’s behalf.
      </p>
      <p>
        If you are not an individual and are an incorporated/registered
        entity/institute/organisation seeking to avail the services of Felicity
        for your members/employees/students or any individuals registered with
        you for any services, you shall enter into an agreement/memorandum of
        understanding with Felicity for this purpose. Each individual availing
        the services of Felicity under such agreement/memorandum of
        understanding shall be bound by these Terms and Privacy Policy available
        here.
      </p>
      <p>
        These terms of service together with privacy policy, disclaimer and any
        other policy mentioned anywhere on the website together constitute your
        agreement with the website. If you are accessing Felicity via the mobile
        application, you will still be bound by the same agreement. In addition,
        there might be other terms imposed by the mobile application stores
        which will be applicable to the use of the mobile application. In such
        case, the term “website” will be deemed to refer to Felicity mobile
        application.
      </p>
      <p>
        Felicity’s role is that of an “intermediary” as defined under the
        Information Technology Act of 2000 and the rules thereunder. As an
        intermediary, Felicity has no responsibility and/or liability in respect
        of the user content, counsellor content, counsellor advice, interaction
        between any of the parties on the platform.
      </p>
      <h3>CHANGES TO THE TERMS AND CONDITIONS</h3>
      <p>
        We reserve the right to make changes to these Terms at any time. Any
        such modifications will become effective immediately upon posting to the
        Website and your continued use of the Website, and/or the Services
        constitutes your agreement to such modifications. You agree to
        periodically review the current version of these Terms of Use as posted
        on the Website.
      </p>
      <h3>WEBSITE FEATURES</h3>

      <ol>
        <li class="li-styling">
          The Website allows Users to connect and interact with counsellors or
          other individuals ("Therapist(s)") who provide counselling and therapy
          services.
        </li>
        <li class="li-styling">
          Felicity uses the information provided by the user and the counsellor
          to match the user to the relevant counsellor.
        </li>
        <li class="li-styling">
          Users can view the therapist platform calendar and book appointments
        </li>
        <li class="li-styling">
          Users and Counsellors can interaction via video calls, audio calls or
          chat messages on the website. Felicity does not share any contact
          details of one party with the other.
        </li>
        <li class="li-styling">
          Users and journal their thoughts and log in their mood on the website,
          this information can be accessed by the counsellor only with user’s
          consent
        </li>
        <li class="li-styling">
          Counsellors can make client notes on the platform and this information
          is saved by the website.
        </li>
        <li class="li-styling">
          Both users and counsellors can participate in the discussion forum on
          the website.
        </li>
        <li class="li-styling">
          Both users and counsellors and interact with Felicity customer support
          using the platform
        </li>
        <li class="li-styling">
          Users can take free assessments and read and share information from
          the Felicity blog by crediting Felicity for the same.
        </li>
      </ol>

      <h3>USER ELIGILIBILITY</h3>
      <p>
        Use of the Website is available only to persons over the age of eighteen
        (18). If you are a minor i.e. under the age of eighteen (18) years, you
        shall not register as a member of the Website and shall not sell or
        purchase any services on the Website, unless you are accessing this
        Website with parental guidance or guidance of a legal guardian. We
        reserve the right to terminate your membership and refuse to provide you
        with access to the Website if it is brought to our notice or if it is
        discovered that you are under the age of eighteen (18) years and are
        accessing this Website without parental consent and guidance or consent
        and guidance of a legal guardian. We will not be liable for any harm
        caused to you on account of any use of the Website without appropriate
        parental consent.
      </p>
      <h3>USER RESPONSIBILITY</h3>

      <ol>
        <li class="li-styling">
          In order to use the Website, you may be required to provide
          information about yourself including your name, email address and
          other personal information ("Personal Information"). You agree that
          any information you provide to Felicity on the Website will always be
          accurate, correct and up to date. You shall not impersonate someone
          else or provide account information, an email address or any other
          information that is not your own. The treatment of your Personal
          Information and other related information, shall be in accordance with
          the Felicity Privacy Policy.
        </li>
        <li class="li-styling">
          You are responsible for maintaining the confidentiality of your
          account details at all times. Further, you are responsible for all
          activities that occur under your account.
        </li>
        <li class="li-styling">
          You may use the Website for lawful purposes only. You shall not post
          or transmit through the Website any material which violates or
          infringes the rights of others, or which is threatening, abusive,
          defamatory, libellous, invasive of privacy or publicity rights,
          vulgar, obscene, profane or otherwise objectionable, contains
          injurious formulas, recipes, or instructions, which encourages conduct
          that would constitute a criminal offense, give rise to civil liability
          or otherwise violate any law.
        </li>
        <li class="li-styling">
          You agree not to engage in any of the following prohibited activities:
          <br />
          (i) copying, distributing, or disclosing any part of the Website in
          any medium; <br />
          (ii) transmitting spam, chain letters, or other unsolicited email;
          <br />
          (iii) attempting to interfere with, compromise the system integrity or
          security or decipher any transmissions to or from the servers running
          the Website; <br />
          (iv) taking any action that imposes, or may impose at our sole
          discretion an unreasonable or disproportionately large load on our
          infrastructure; <br />
          (v) uploading invalid data, viruses, worms, or other software agents
          through the Website; <br />
          (vi) collecting or harvesting any personally identifiable information,
          including account names, from the Website; <br />
          (vii) using the Website for any commercial purposes without having all
          necessary rights and licenses to the User Content; <br />
          (viii) impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, hiding or
          attempting to hide your identity; <br />
          (ix) interfering with the proper working of the Website; <br />
          (x) accessing any content on the Website through any technology or
          means other than those capabilities provided by the Website; or <br />
          (xi) bypassing the measures we may use to prevent or restrict access
          to the Website, including without limitation features that prevent or
          restrict use or copying of any content or enforce limitations on use
          of the Website or the content therein.
        </li>
        <li class="li-styling">
          You are solely responsible for your conduct and any data that you
          submit, post or display on or via the Website. Felicity shall have no
          liability for conduct in relation to your use of our Website. You are
          solely responsible for any legal consequences arising out of your
          inappropriate use of the website.
        </li>
        <li class="li-styling">
          You authorize Felicity to contact you via email id, smsor mobile
          (information provided by you in your interaction with the website) to
          share with you promotional, informational or any other content that it
          deems fit.
        </li>
      </ol>

      <h3>USER CONTENT ON THE WEBSITE</h3>
      <ol>
        <li class="li-styling">
          The Website provides Users the facility to create, share and post
          content (together, "User Content"). Felicity claims no ownership rights
          over User Content created by you. Felicity has the right (but not the
          obligation) in its sole discretion to remove any User Content that is
          stored via the Website.
        </li>
        <li class="li-styling">
          Felicity takes no responsibility and assumes no liability for any User
          Content that you or any other User or third party creates, stores,
          shares, posts or sends through the Website. You shall be solely
          responsible for your User Content and the consequences of posting,
          publishing it, or sharing it and you agree that we are only acting as a
          passive conduit for your online distribution and publication of your
          User Content. If your Content violates these Terms, you may bear legal
          responsibility for that content.
        </li>
        <li class="li-styling">
          You agree not to post, store, transmit, create or share any User Content
          that:
          <p class="ptag-styling">
            (i) may create a risk of harm, loss, physical or mental injury,
            emotional distress, death, disability, disfigurement, or physical or
            mental illness to you, to any other person, or to any animal; <br />
          </p>
          <p class="ptag-styling">
            (ii) may create a risk of any other loss or damage to any person or
            property; <br />
          </p>
          <p class="ptag-styling">
            (iii) seeks to harm or exploit children by exposing them to
            inappropriate content, asking for personally identifiable details or
            otherwise;<br />
          </p>
          <p class="ptag-styling">
            (iv) may constitute or contribute to a crime or tort; <br />
          </p>
          <p class="ptag-styling">
            (v) contains any information or content that we deem to be unlawful,
            harmful, abusive, racially or ethnically offensive, defamatory,
            infringing, invasive of personal privacy or publicity rights,
            harassing, humiliating to other people (publicly or otherwise),
            libellous, threatening, profane, or otherwise objectionable; <br />
          </p>
          <p class="ptag-styling">
            (vi) contains any information or content that is illegal (including,
            without limitation, the disclosure of insider information under
            securities law or of another party’s trade secrets); or<br />
          </p>
          <p class="ptag-styling">
            (vii) contains any information or content that you do not have a right
            to make available under any law or under contractual or fiduciary
            relationships. You agree that any User Content that you create, store,
            or share does not and will not violate third-party rights of any kind,
            including without limitation any Intellectual Property Rights (as
            defined below) or rights of privacy. Felicity reserves the right, but
            is not obligated, to reject and/or remove any User Content that
            Felicity believes, in its sole discretion, violates these provisions.
            For the purposes of these Terms, “Intellectual Property Rights” means
            all patent rights, copyright rights, mask work rights, moral rights,
            rights of publicity, trademark, trade dress and service mark rights,
            goodwill, trade secret rights and other intellectual property rights
            as may now exist or hereafter come into existence, and all Website
            therefore and registrations, renewals and extensions thereof, under
            the laws of any state, country, territory or other jurisdiction.
            <br />
          </p>
        </li>
        <li class="li-styling">
          In connection with your User Content, you affirm, represent, and warrant
          the following:
          <p class="ptag-styling">
            <b>a.</b> Your User Content and your use thereof as contemplated by
            these Terms and the Website will not violate any law or infringe any
            rights of any third party, including but not limited to any
            Intellectual Property Rights or privacy rights.
          </p>
          <p class="ptag-styling">
            <b>b.</b> To the extent that you use the Website for any commercial
            purpose and your User Content contains any copyrighted material that
            is not your original work or in which you do not otherwise own the
            copyright, you have obtained all rights, licenses, consents, and
            permissions necessary in order to use that copyrighted material in
            connection with the creation and/or dissemination of that User Content
            using the Website.
          </p>
          <p class="ptag-styling">
            <b>c.</b> Felicity may exercise the rights to your User Content
            granted under these Terms without liability for payment of any guild
            fees, residuals, payments, fees, or royalties payable under any
            collective bargaining agreement or otherwise.
          </p>
          <p class="ptag-styling">
            <b>d.</b> Felicity takes no responsibility and assumes no liability
            for any User Content that you or any other User or third party
            creates, stores, shares, posts, sends or otherwise makes available
            through the Website, whether directly or indirectly. You shall be
            solely responsible for your User Content and the consequences of
            posting, publishing it, or sharing it and you agree that Felicity is
            only acting a passive conduit for the distribution and publication of
            your User Content. If your Content violates these Terms, you may bear
            legal responsibility for that content.
          </p>
        </li>
        <li class="li-styling">
          If you share your User Content with Felicity and/or link your User
          Content to Felicity on a third party service and/or post content on any
          social media page owned and operated by Felicity, you expressly grant,
          and you represent and warrant that you have all rights necessary to
          grant, to Felicity a royalty-free, sublicensable, transferable,
          perpetual, irrevocable, non-exclusive, worldwide license to use,
          reproduce, modify, publish, list information regarding, edit, translate,
          distribute, syndicate, publicly perform, publicly display, and make
          derivative works of all such User Content and your name, voice, and/or
          likeness as contained in your User Content, in whole or in part, and in
          any form, media or technology, whether now known or hereafter developed,
          for use in connection with the Website and Felicity’s (and its
          successors’ and affiliates’) business, including without limitation for
          promoting and redistributing part or all of our Website (and derivative
          works thereof) in any media formats and through any media channels.
        </li>
      </ol>

      <h3>PROPRIETORY RIGHTS</h3>

      <ol>
        <li class="li-styling">
          The Website contains content owned or licensed by Felicity. Felicity
          owns and retains all rights in the Felicity Content. You will not
          remove, alter or conceal any copyright, trademark, service mark or
          other proprietary rights notices incorporated in or accompanying the
          Felicity Content and you will not reproduce, modify, adapt, prepare
          derivative works based on, perform, display, publish, distribute,
          transmit, broadcast, sell, license or otherwise exploit the Felicity
          Content.
        </li>
        <li class="li-styling">
          The Felicity name and logo are trademarks of Felicity, and may not be
          copied, imitated, or used, in whole or in part, without the prior
          written permission of Felicity, except with prior consent. In
          addition, all custom graphics, button icons and scripts are service
          marks, trademarks and/or trade dress of Felicity, and may not be
          copied, imitated, or used, in whole or in part, without prior written
          permission from Felicity.
        </li>
      </ol>

      <h3>PLAN VALIDAITY AND EXPIRY</h3>

      <ol>
        <li class="li-styling">
          While you can choose to start the plan anytime after purchase without
          any time limit, once you activate the plan (consume the first
          session), you need to consume the plan within 6 months.
        </li>
        <li class="li-styling">
          While Felicity will try to ensure that your assigned counsellor is
          available for the entire duration of your plan, it is possible that
          the same counsellor becomes unavailable during the course of the plan.
          Felicity is not liable to provide you a specific counsellor at any
          point and will assign you a counsellor at its discretion.
        </li>
        <li class="li-styling">
          While your counsellor chat access becomes active as soon as you
          purchase a plan, your chat access period is calculated from the date
          of the first session. Hence, from the date of your first session, you
          will have chat access for the number of chat access days mentioned in
          your plan.
        </li>
      </ol>

      <h3>CANCELLATIONS AND RESCHEDULING SESSIONS</h3>
      <p>
        You can cancel or reschedule a session booked on Felicity up to 24 hours
        before the scheduled time by requesting the same via the Felicity
        website. Post that rescheduling will not be permitted and the session
        will be deemed to be consumed by you even if you do not attend.
      </p>
      <h3>CHANGING AND CHOOSING COUNSELLORS</h3>

      <ol>
        <li class="li-styling">
          You can change the counsellor assigned to you on Felicity by
          requesting the same via the Felicity website given the following
          conditions:

          <p class="ptag-styling">
            • There is no scheduled session with the existing counsellor in the
            next 24 hours
          </p>
          <p class="ptag-styling">
            • Any scheduled sessions with the counsellor, who is unassigned to
            you will automatically stand cancelled and will be credited back to
            your account as “to be scheduled” sessions. You will have to then
            schedule them again with the new counsellor assigned.
          </p>
          <p class="ptag-styling">
            • Felicity does not guarantee the assignment of counsellor of your
            choice at any point. You are assignment a counsellor based on your
            needs and counsellor availability
          </p>
          <p class="ptag-styling">
            • Felicity reserves the right to change your counsellor at any point
            at its discretion, whether requested by you or not.
          </p>
        </li>
      </ol>

      <h3>USER- COUNSELLOR INTERACTIONS</h3>

      <ol>
        <li class="li-styling">
          Both the user and counsellor agree to maintain the decorum of a
          client/counsellor relationship and agree to not engage in any other
          form of interaction on the platform.
        </li>
        <li class="li-styling">
          In case of inappropriate behaviour by either party, Felicity will not
          be liable in any form. Involved parties alone and not Felicity will be
          responsible for any legal consequences arising out of such
          interactions.
        </li>
        <li class="li-styling">
          Both parties have the option to report inappropriate behaviour on the
          website to Felicity. In case a particular session is reported,
          Felicity reserves the right to remove the user or counsellor or both
          from the platform without discretion. Felicity’s decision in this
          regard will be final.
        </li>
        <li class="li-styling">
          Counsellor and NOT Felicity, is liable to maintain the confidentiality
          of any personal information shared by the user with the counsellor in
          video sessions. In case a counsellor breach the confidentiality
          agreement, counsellor alone and NOT Felicity will bear the legal or
          any other consequences.
        </li>
      </ol>

      <h3>PLAN CHANGES, CANCELLATIONS AND REFUNDS</h3>

      <ol>
        <li class="li-styling">
          You can request a cancellation and refund of the bought plan via the
          Felicity helpdesk. However, Felicity will review your request and may
          decide to refund the charges paid if it deems fit. The decision of
          Felicity on any such matter shall be final and binding. In case
          Felicity decides to refund your charges, it will do so at its
          convenience.
        </li>
        <li class="li-styling">
          In case you request a refund after consuming one or some sessions from
          the plan and Felicity decides to initiate a refund, you will be paid
          back the charges of the unconsumed sessions only. Used sessions will
          not be refunded. In case of multiple sessions purchased, the refund will be provided for the unused sessions
          and all consumed sessions will be charged as per the standard price of the single session plan. In case
          Felicity decides to refund your charges, it will do so at its convenience.
        </li>
        <li class="li-styling">
          In case, you wish to change the plan that you have bought –
          <p>
            a) an upgrade to the longer plan – Felicity will review your request
            and may decide to allow the plan upgrade by allowing you to pay the
            difference amount
          </p>
          <p>
            b) a step back to a smaller plan - Felicity will review your request
            and may decide to allow the plan step back by refunding the
            difference amount. In such cases, if Felicity decides to allow this,
            the actual amount paid per session will be refunded and not the
            standard cost of 1 session.
          </p>
        </li>
      </ol>

      <h3>PLAN PRICING</h3>

      <ol>
        <li class="li-styling">
          While the price of every plan is listed on the website, Felicity
          reserves the right to determine the selling price of the plan in every
          transaction. It can choose to apply discounts or premiums for specific
          users or groups of users.
        </li>
        <li class="li-styling">
          Felicity reserves its right to change the charges for Services, at any
          time, without the requirement of any prior intimation to the User. Any
          such change shall be binding and effective on the User.
        </li>
      </ol>

      <h3>PAYMENT</h3>

      <ol>
        <li class="li-styling">
          We may charge for the chat, audio, and/or video-based counselling/
          therapy sessions. Before availing any such Service, we will apprise
          the User of the applicable charges for the said Service. In addition
          to these Terms, a User shall also be bound by the terms (if any)
          mentioned for specific Service(s).
        </li>
        <li class="li-styling">
          User(s) can make payments through any of the following available
          options:
          <p class="ptag-styling">• Internet Banking</p>
          <p class="ptag-styling">• Debit/ Credit Cards.</p>
        </li>
        <li class="li-styling">
          The User agrees and accepts that all nuances and modalities relating
          to making payment using Internet Banking/ Debit/Credit Cards (“Virtual
          Payment Mode”) shall be separately governed by arrangement(s) / terms
          and conditions between the User and the relevant banks. We shall not
          be responsible, in any manner whatsoever, for any liability that may
          arise in relation to the Virtual Payment Modes (including any
          fraudulent transaction).
        </li>
        <li class="li-styling">
          While availing any of the payment method(s) available on the Website,
          we will not be responsible or assume any liability, whatsoever in
          respect of any loss or damage arising directly or indirectly to You
          due to (a) lack of authorization for any transactions; (b) any payment
          issues arising out of the transaction or (c) decline of such
          transaction for any reason.
        </li>
        <li class="li-styling">
          You understand, accept, and agree that the payment facility provided
          us, is neither a banking nor financial service.
        </li>
      </ol>

      <h3>DISCLAIMERS</h3>

      <ol>
        <li class="li-styling">
          Felicity is not in the business of providing psychiatric care or
          treatment for severe mental illnesses including but not limited
          suicidal thoughts.
        </li>
        <li class="li-styling">
          The advice or information provided by counsellors via the website is
          provided for informational purposes only and cannot be considered a
          substitute for examination by a doctor or other mental health
          professional. You are advised strongly against relying solely on, or
          make decisions based solely on advice provided by any counsellor.
        </li>
        <li class="li-styling">
          You understand and agree that although a Counsellor may be a qualified
          clinical or counselling psychologist or other mental health
          professional. Felicity disclaims any and all liability for any
          consultation and therapy services rendered by a Counsellor to you
          through the Website. You further acknowledge and agree that you take
          full responsibility for the decision to access a Expert through the
          Website and to continue to interact with such individual(s), and that
          the role of Felicity is strictly limited to providing access to such
          Counsellors to you.
        </li>
        <li class="li-styling">
          The website is not a suicide helpline platform. If you are considering
          or contemplating suicide or feel that you are a danger to yourself or
          to others, you may discontinue use of the services immediately at your
          discretion and please notify appropriate police or emergency medical
          personnel. You may find contacts of such emergency services here
        </li>
      </ol>

      <h3>PRIVACY AND SECURITY</h3>

      <ol>
        <li class="li-styling">
          You understand that by using the Website you consent to the
          collection, use and disclosure of your personally identifiable
          information and aggregate data as set forth in our Privacy Policy.
        </li>
        <li class="li-styling">
          You understand that Felicity cannot guarantee that unauthorized third
          parties will never be able to defeat our security measures or use your
          personal information for improper purposes. You acknowledge that you
          provide your personal information at your own risk.
        </li>
      </ol>

      <h3>WARRANTY</h3>

      <ol>
        <li class="li-styling">
          The website is provided on an “as is” basisand use of the website is
          at the user’s risk. To the maximum extent permitted by applicable law,
          the website is provided without warranties of any kind, whether
          express or implied, including, but not limited to, implied warranties
          of merchantability, fitness for a particular purpose, or
          non-infringement. No advice or information, whether oral or written,
          obtained by you from felicity or through the website will not create
          any warranty not expressly stated herein. Without limiting the
          foregoing, felicity, its subsidiaries, its affiliates, and its
          licensors do not warrant that the content found on the website is
          accurate, reliable or correct; that the website will meet your
          requirements; that the website will be available at any particular
          time or location, uninterrupted or secure; that any defects or errors
          will be corrected; or that the website is free of viruses or other
          harmful components. Any content downloaded or otherwise obtained
          through the use of the website is downloaded at your own risk and you
          will be solely responsible for any damage to your computer system or
          mobile device or loss of data that results from such download or your
          use of the website.
        </li>
        <li class="li-styling">
          Felicity does not warrant, endorse, guarantee, or assume
          responsibility for any product or service advertised or offered by a
          third party through the website or any hyperlinked website or service,
          and felicity will not be a party to or in any way monitor any
          transaction between you and third-party providers of products or
          services.
        </li>
      </ol>

      <h3>EXCEPTIONS AND LIMITATIONS</h3>
      <ol>
        <li class="li-styling">
          Felicity does not make any representation or warranty as to the
          quality or value of the services offered on the Website, or
          availability of Counsellor(s). Felicity does not implicitly or
          explicitly support or endorse any services on the Website. Felicity
          shall not be liable for any errors or omissions, whether on behalf of
          itself or third parties.
        </li>
        <li class="li-styling">
          While Felicity carries out background checks and verifications on all
          Counsellors, you understand and acknowledge that Felicity does not
          endorse, recommend, warrant or guarantee to qualifications, expertise,
          claims or background of any Counsellor, or any service, advice,
          opinion, recommendation provided by a Counsellor. Nothing contained in
          these Terms, the Website or on any third party site shall be
          considered as an endorsement, recommendation, referral, verification,
          warranty or guarantee with respect to (a) any Counsellor; (b) the
          Website or (c) any service, advice, opinion, recommendation made
          available via the Website or (d) the validity, accuracy, availability,
          completeness, safety, legality, quality or applicability of any
          information made available via the Website.
        </li>
        <li class="li-styling">
          You acknowledge that there will be occasions when the Website may be
          interrupted, including, without limitation, for scheduled maintenance
          or upgrades, for emergency repairs, or due to failure of
          telecommunications links and/or equipment.
        </li>
        <li class="li-styling">
          You agree that Felicity is not responsible for, and does not endorse,
          User Content posted within the Website. Felicity does not have any
          obligation to pre-screen, monitor, edit, or remove any User Content.
          If your User Content violates these Terms, you shall be solely
          responsible for any legal consequences with respect to such User
          Content.
        </li>
        <li class="li-styling">
          While Felicity is under no obligation to pre-screen any user or
          counsellor content, it reserves the right to remove any User Content
          from the Website for any reason, without prior notice. User Content
          removed from the Website may continue to be stored by Felicity,
          including, without limitation, in order to comply with certain legal
          obligations, but may not be retrievable without a valid court order.
          Felicity will not be liable to you for any modification, suspension,
          or discontinuation of the Website, or the loss of any User Content.
        </li>
        <li class="li-styling">
          We may, without prior notice, change the Website, stop providing the
          Website or features of the Website, to you or to Users generally, or
          create usage limits for the Website. We may permanently or temporarily
          terminate or suspend your access to the Website, or delete any User
          Content without notice and liability for any reason, including if in
          our sole determination you violate any provision of these Terms, or
          for no reason. Upon termination for any reason or no reason, you
          continue to be bound by these Terms.
        </li>
      </ol>

      <h3>TERMINATION</h3>
      <p>
        Felicity may terminate these Terms for any reason at any time. Felicity
        reserves the right, in its sole discretion, to restrict, suspend, or
        terminate your access to and use of the Website, with or without prior
        notice. Otherwise applicable sections of the Terms shall survive
        termination. In addition to any termination rights, we reserve the right
        to enforce and prosecute any violations of these Terms.
      </p>
      <h3>LINKS TO THIRD PARTY WEBSITES</h3>
      <p>
        The Website may contain links to other Websites ("Linked Sites"). The
        Linked Sites are not under the control of the Company. We are not
        responsible for the content of any Linked Site, including, without
        limitation to, any link contained in a Linked Site, or any changes or
        updates to a Linked Site.
      </p>
      <p>
        We are not responsible for any form of transmission, whatsoever,
        received by the User from any Linked Site. We are providing these links
        only for convenience, and the inclusion of any such link does not imply
        endorsement by the Website, of the Linked Sites or any association with
        its operators or owners including the legal heirs or assigns thereof.
      </p>
      <p>
        On accessing the Linked Sites, you shall be governed by the terms of
        use, privacy policy and such other additional policies of the Linked
        Sites. You further acknowledge and agree that we shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, advertising, products, services or other
        materials available on or through any Linked Sites or for any errors,
        defamatory content, libel, slander, omissions, falsehoods, obscene
        content, pornographic material, or any profanity contained therein.
      </p>
      <h3>INDEMNIFICATION</h3>

      <ol>
        <li class="li-styling">
          You shall indemnify and hold us harmless from and against any and all
          losses, damages, settlements, liabilities, costs, charges, assessments
          and expenses, as well as third party claims and causes of action,
          including, without limitation, attorneys’ fees, arising out of any
          breach by you of any of these Terms, or any use by you of the Website.
          You shall provide us with such assistance, without charge, as we may
          request in connection with any such defence, including, without
          limitation, providing us with such information, documents, records and
          reasonable access to you, as we deem necessary. You shall not settle
          any third-party claim or waive any defence without our prior written
          consent.
        </li>
        <li class="li-styling">
          In the event of a dispute regarding any transaction conducted via the
          website, you hereby relieve felicity, its affiliates, their respective
          officers, directors, shareholders, employees, sub-contractors and
          agents from all manner of actions, claims or demands and from any and
          all losses (direct, indirect, incidental or consequential), damages,
          costs or expenses, including, without limitation, court costs and
          attorneys’ fees, which member may have against one or more of the
          above.
        </li>
      </ol>

      <h3>LIMITED LIABILITY</h3>

      <ol>
        <li class="li-styling">
          In no event will felicity or its affiliates or any party involved in
          creating, producing, or delivering the website be liable for any
          direct, incidental, consequential, indirect, special, or punitive
          damages arising out of your access, use, misuse, or inability to use
          the website or any linked sites, or in connection with any failure of
          performance. These limitations apply whether the alleged liability is
          based on contract, tort, negligence, strict liability, or any other
          basis, even if felicity has been advised of the possibility of such
          damage.
        </li>
        <li class="li-styling">
          By use of the website and the services, the user acknowledges that
          he/she is solely responsible for any and all actions, liabilities,
          consequences, decisions, behaviours (“conduct”) arising out of or in
          connection with the use of the website and/or services, and shall in
          no way hold felicity and/or its affiliates responsible for such
          conduct.
        </li>
      </ol>

      <h3>APPLICABLE LAW AND DISPUTE RESOLUTION</h3>

      <ol>
        <li class="li-styling">
          This Agreement shall be governed by the laws of India, and the courts
          of Jaipur, Rajasthan shall have exclusive jurisdiction with respect to
          any dispute arising hereunder.
        </li>
        <li class="li-styling">
          In case of any dispute, claim or controversy arising out of or
          relating to these User Terms or the breach, termination, enforcement,
          interpretation or validity thereof or the use of the Site, the Service
          or the Application (collectively, “Disputes”) the parties shall
          attempt to settle the same amicably, through negotiation and
          consultation at such office of Felicity as Felicity may designate. In
          the event the dispute is not resolved internally between after at
          least 30 (thirty) days of negotiation, in good faith, the same shall
          be subject to binding and final arbitration in accordance with the
          Arbitration and Conciliation Act, 1996 as amended from time to time or
          in case the Arbitration and Conciliation Act, 1996 is no longer in
          force, as per any law relating to arbitration in force at the time of
          such reference. The reference shall be made to a sole arbitrator
          appointed by Felicity. The place of the arbitration shall be Jaipur,
          Rajasthan. The proceedings shall be undertaken in English. The
          arbitration award shall be final and binding on the parties.
        </li>
        <li class="li-styling">
          In accordance with Information Technology Act, 2000 and rules made
          there under, the name and contact details of the ‘Grievance Officer’
          are provided below:
          <p class="ptag-styling">
            Name: Pratika Khandelwal, Phone No: +917760960274, E-mail Address:
            pratika@felicity.care
          </p>
          <p class="ptag-styling">
            Postal Address: D-80, Sapphire Space, Ghiya Marg, Bani Park, Jaipur
            – 302016, Rajasthan, India
          </p>
        </li>
      </ol>

      <h3>GENERAL PROVISIONS</h3>

      <ol>
        <li class="li-styling">
          Survival: In the event of termination or expiration of these Terms for
          any reason, any provisions of these Terms that by their nature should
          survive termination of these Terms will survive termination of these
          Terms, unless contrary to the pertinent provisions herein stated.
        </li>
        <li class="li-styling">
          Severability: If any term or provision in these Terms is held to be
          either illegal or unenforceable, in whole or in part, under any
          enactment or rule of law, such term or provision or part shall to that
          extent be deemed not to form part of these Terms, but the validity and
          enforceability of the remainder of these Terms shall not be affected.
        </li>
        <li class="li-styling">
          Unenforceability: If any provision of these Terms or any word, phrase,
          clause, sentence, or other portion thereof should be held to be
          unenforceable or invalid for any reason, then provided that the
          essential consideration for entering into these Terms on the part of
          any Party is not unreasonably impaired, such provision or portion
          thereof shall be modified or deleted in such manner as to render these
          Terms as modified legal and enforceable to the maximum extent
          permitted under applicable laws.
        </li>
        <li class="li-styling">
          No Waiver: No delay or omission by either Party hereto to exercise any
          right or power occurring upon any noncompliance or default by the
          other party with respect to any of the terms of these Terms shall
          impair any such right or power or be construed to be a waiver thereof.
          The terms and conditions of these Terms may be waived or amended only
          in writing or mutual agreement of the Parties. A waiver by either of
          the Parties hereto of any of the covenants, conditions, or agreements
          to be performed by the other shall not be construed to be a waiver of
          any succeeding breach thereof or of any covenant, condition, or
          agreement herein contained (whether or not the provision is similar).
        </li>
        <li class="li-styling">
          Force Majeure: We shall not be liable for any failure to perform any
          obligations under this User Terms, if the performance is prevented,
          hindered or delayed by a Force Majeure Event and in such case our
          obligations under this User Terms shall be suspended for so long as
          the Force Majeure Event continues.
        </li>
        <li class="li-styling">
          Notices: Any notice required or permitted to be given to Felicity
          hereunder shall be in writing and sent or transmitted by (i)
          registered or certified mail; (ii) hand-delivery; (iii) email; or (iv)
          internationally recognized courier service, provided its receipt is
          acknowledged and, dispatched or sent or transmitted to the address
          specified Felicity. All notice required to be given under these Terms
          shall be addressed to:
          <p class="ptag-styling">
            Name: Felicity (registered as: Orgfit Global Consultancy Private
            Limited)
          </p>
          <p class="ptag-styling">
            Postal Address: D-80, Sapphire Space, Ghiya Marg, Bani Park, Jaipur
            – 302016, Rajasthan, India
          </p>
          <p class="ptag-styling">E-mail Address: pratika@felicity.care</p>
          <p class="ptag-styling">
            Felicity may give notice by means of a general notice on the
            Application, or by electronic mail to Your email address or a
            message on Your registered mobile number.
          </p>
        </li>
        <li class="li-styling">
          Advertisement: Felicity has the right to place advertisements and
          publicity materials of its choiceincluding those of parties other than
          itself on the website.
        </li>
        <li class="li-styling">
          Assignment: You may not assign or sub license your duties, obligations
          and rights under this agreement to any other party with prior written
          consent of Felicity
        </li>
      </ol>

      <h3>Telepsychiatry</h3>
      <p>
        By signing up, you consent to avail consultation via telemedicine. You agree that you know the potential risks,
        consequences and benefits of telepsychiatry consultation. You will ask doctor or pharmacist, if you do not
        understand any of the information provided in the prescription. You will stop prescribed medicines in case of
        any adverse reaction/side effects. Youu will contact the psychiatrist immediately or go to the nearest
        registered medical practitioner for in-person consult.
      </p>
      <p><strong>Note :</strong></p>
      <ol>
        <li class="li-styling">
          <p><strong>Accepting this will facilitate</strong></p>
          <p> a) To understand what information and history is required for effective consultation. Adequate and
            reliable information that is required for arriving at a provisional /definitive diagnosis.</p>
          <p> b) Arriving at diagnosis and planning treatment management than documentation.</p>
          <p> c) Psychiatrist to effectively spend time with the patient during consultation rather during the
            consultation.</p>
          <p> d) Please do remember that online consultation time is fixed per session. The average online consultation
            time is approximately 10 to 15 minutes, which is similar to in-person consultation time.</p>
          <p> e) Accepting this consent is optional for patient/family members.</p>
          <p> f) This proforma will be retained in the patient's file in the hospital along with a copy of all the
            investigations and a copy of the prescription.</p>
        </li>
        <li class="li-styling">
          <p><strong>Information to the patient and patient's family members</strong></p>
          <p> g) Presence of patient is advisable during the telemedicine consultation. This is as per the Telemedicine
            Practice Guidelines 2020 and Mental Healthcare Act, 2017</p>
          <p> h) If the patient is not willing to come for telepsychiatry consultation, please follow Mental Healthcare
            Act, 2017 and request for in-person consult or home visit by the mental health care professionals for Mental
            Capacity Assessment (please, check if home psychiatric service is available)</p>
          <p> i) The Telemedicine Practice Guidelines have stated (Code 4.1.1.2) that the patient / patient's family
            member will be responsible for the accuracy of the information shared with the doctor. Please read
            Telemedicine Practice Guidelines available online from MoHFW, New Delhi (Available online at
            https://www.mohfw.gov.in/pdf/Telemedicine.pdf)</p>
          <p> j) If symptoms are severe and/or severe side effects of medicines and/or emergency care is required,
            please do not wait for telemedicine consultation appointment. Please do in-person consultation at the
            nearest psychiatrist or registered medical practitioner at the earliest.</p>
          <p> k) Neither of the party (patient/patient’s party or psychiatrist) will not do audio or video recording,
            without prior explicit consent. Covert recordings (audio or video) are illegal since everyone being recorded
            must consent to be recorded. Explicit consent is must from all the parties.</p>
        </li>
      </ol>
    </div>
  </div>
</div>