<div class="headerWrapper">
  <div class="headingWrap">
    <div class="headingWrap-menu mobileView">
      <div class="headingSection1">
        <div class="logo">
          <a routerLink="">
            <img
              class="logoImage"
              src="../../assets/images/logo.webp"
              alt="felicity-logo"
            />
          </a>
        </div>
        <div class="headTryNow">
          <button
            class="buttonTheme2"
            *ngIf="userIsAuthenticated && isDashboardRedirect"
            id="getStartedHeader"
            (click)="getStarted()"
            routerLink="user-dashboard"
          >
            Get Started
          </button>
          <button
            class="buttonTheme2"
            *ngIf="userIsAuthenticated && !isDashboardRedirect"
            id="getStartedHeader"
            (click)="getStarted()"
            routerLink="online-counseling-psychologists/plans-pricing"
          >
            Get Started
          </button>
          <button
            class="buttonTheme2"
            *ngIf="!userIsAuthenticated"
            id="getStartedHeader"
            (click)="getStarted()"
            [routerLink]="['sign-up']"
            [queryParams]="{
              redirect: 'online-counseling-psychologists/plans-pricing'
            }"
          >
            Get Started
          </button>
        </div>
      </div>
      <div class="headingSection2">
        <div class="sidenav-container">
          <a id="sidenav-button" (click)="switchSideNav()"
            ><i class="fa fa-bars"></i
          ></a>
        </div>
      </div>
      <div
        class="sidenav-plate closeSideNav"
        [ngClass]="{ openSideNav: sideNav }"
      >
        <div class="sidenav-menuHolder">
          <ul class="list">
            <li>
              <a
                routerLink="online-counseling-psychologists/how-it-works"
                routerLinkActive="is-active"
                (click)="switchSideNav()"
                >How it works</a
              >
            </li>
            <li>
              <a
                routerLink="online-counseling-psychologists/plans-pricing"
                routerLinkActive="is-active"
                (click)="switchSideNav()"
                >Plans &amp; Pricing</a
              >
            </li>
            <li>
              <a
                routerLink="our-counselors"
                routerLinkActive="is-active"
                (click)="switchSideNav()"
                >Our Counselors</a
              >
            </li>
            <li>
              <a routerLink="wellness-hub" routerLinkActive="is-active" (click)="switchSideNav()">
                Wellness Hub
              </a>
            </li>
            <li>
              <a routerLink="corporates" routerLinkActive="is-active" (click)="switchSideNav()">
                Corporates
              </a>
            </li>
            <li
              *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
              class="signup-btn"
            >
              <button
                class="buttonTheme1 loginButton widthForButton"
                routerLink="sign-up"
                (click)="switchSideNav()"
              >
                Sign Up
              </button>
            </li>
            <li
              *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
              class="login-btn"
            >
              <button
                class="buttonTheme2 widthForButton"
                routerLink="login"
                (click)="switchSideNav()"
              >
                Login
              </button>
            </li>
            <li *ngIf="userIsAuthenticated">
              <button
                class="buttonTheme1 widthForButton"
                routerLink="user-dashboard"
                (click)="switchSideNav()"
              >
                My Dashboard
              </button>
            </li>
            <li *ngIf="therapistIsAuthenticated">
              <button
                class="buttonTheme1 widthForButton"
                routerLink="therapist-dashboard"
                (click)="switchSideNav()"
              >
                My Dashboard
              </button>
            </li>
          </ul>
          <div class="socialMediaContainer">
            <p>Follow Us On Social Media✨</p>
            <div class="socialMediaIconsHolder">
              <a
                target="_blank"
                href="https://www.facebook.com/felicitycareofficial/"
                ><i class="fa fa-facebook-official" aria-hidden="true"></i
              ></a>
              <a target="_blank" href="https://www.instagram.com/felicity_care/"
                ><i class="fa fa-instagram" aria-hidden="true"></i
              ></a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UC5JO-2aftdLYSUupZm2Ca1A"
              >
                <i class="fa fa-youtube-play" aria-hidden="true"></i
              ></a>
              <a target="_blank" href="https://twitter.com/CareFelicity">
                <i class="fa fa-twitter" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="menuHolder">
        <ul class="list">
          <li>
            <a
              routerLink="online-counseling-psychologists/how-it-works"
              routerLinkActive="is-active"
              >How it works</a
            >
          </li>
          <li>
            <a
              routerLink="online-counseling-psychologists/plans-pricing"
              routerLinkActive="is-active"
              >Plans &amp; Pricing</a
            >
          </li>
          <li>
            <a routerLink="our-counselors" routerLinkActive="is-active"
              >Our Counselors</a
            >
          </li>
          <li><a routerLink="wellness-hub" routerLinkActive="is-active">Wellness Hub</a></li>
          <li><a routerLink="corporates" routerLinkActive="is-active">Corporates</a></li>
          <!-- <li>
              <div class="dropdown">
                <a class="dropbtn">Community</a
                ><i class="fa fa-angle-down" aria-hidden="true"></i>
                <div class="dropdown-content">
                  <a style="font-size: 14px; color: #333" routerLink="blog"
                    >Happy Place - The Felicity Blog</a
                  >
                  <a
                    style="font-size: 14px; color: #333"
                    routerLink="/discussion-forum"
                    >Discussion Forum
                  </a>
                  <a
                    style="font-size: 14px; color: #333"
                    routerLink="our-counselors"
                    >Our Counselors</a
                  >
                  <a style="font-size: 14px; color: #333" routerLink="about-us"
                    >About Us</a
                  >
                </div>
              </div>
            </li> -->
          <!-- <li>
              <a routerLink="contact-us">Contact</a>
            </li> -->
          <li
            *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
            class="signup-btn"
          >
            <button
              class="buttonTheme1 loginButton widthForButton"
              routerLink="sign-up"
            >
              Sign Up
            </button>
          </li>
          <li
            *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
            class="login-btn"
          >
            <button class="buttonTheme2 widthForButton" routerLink="login">
              Login
            </button>
          </li>
          <li *ngIf="userIsAuthenticated" class="dashboard-btn">
            <a routerLink="user-dashboard">
              <button class="buttonTheme1 widthForButton">My Dashboard</button>
            </a>
          </li>
          <li *ngIf="therapistIsAuthenticated" class="dashboard-btn">
            <button
              class="buttonTheme1 widthForButton"
              routerLink="therapist-dashboard"
            >
              My Dashboard
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="headingWrap pcView">
      <div class="headingWrap-menu">
        <div class="logo">
          <a routerLink="">
            <img
              class="logoImage"
              src="../../assets/images/logo.png"
              alt="felicity-logo"
            />
          </a>
        </div>
        <div class="sidenav-container">
          <a id="sidenav-button" (click)="switchSideNav()"
            ><i class="fa fa-bars"></i
          ></a>
        </div>
        <div
          class="sidenav-plate closeSideNav"
          [ngClass]="{ openSideNav: sideNav }"
        >
          <div class="sidenav-menuHolder">
            <ul class="list">
              <li>
                <a
                  routerLink="online-counseling-psychologists/how-it-works"
                  routerLinkActive="is-active"
                  (click)="switchSideNav()"
                  >How it works</a
                >
              </li>
              <li>
                <a
                  routerLink="online-counseling-psychologists/plans-pricing"
                  routerLinkActive="is-active"
                  (click)="switchSideNav()"
                  >Plans &amp; Pricing</a
                >
              </li>
              <li>
                <a
                  routerLink="our-counselors"
                  routerLinkActive="is-active"
                  (click)="switchSideNav()"
                  >Our Counselors</a
                >
              </li>
                  <li><a routerLink="wellness-hub" routerLinkActive="is-active" (click)="switchSideNav()">Wellness Hub</a></li>
                  <li><a routerLink="corporates" routerLinkActive="is-active" (click)="switchSideNav()">Corporates</a></li>
              <li
                *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
                class="signup-btn"
              >
                <button
                  class="buttonTheme1 loginButton widthForButton"
                  routerLink="sign-up"
                  (click)="switchSideNav()"
                >
                  Sign Up
                </button>
              </li>
              <li
                *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
                class="login-btn"
              >
                <button
                  class="buttonTheme2 widthForButton"
                  routerLink="login"
                  (click)="switchSideNav()"
                >
                  Login
                </button>
              </li>
              <li *ngIf="userIsAuthenticated">
                <button
                  class="buttonTheme1 widthForButton"
                  routerLink="user-dashboard"
                  (click)="switchSideNav()"
                >
                  My Dashboard
                </button>
              </li>
              <li *ngIf="therapistIsAuthenticated">
                <button
                  class="buttonTheme1 widthForButton"
                  routerLink="therapist-dashboard"
                  (click)="switchSideNav()"
                >
                  My Dashboard
                </button>
              </li>
            </ul>
            <div class="socialMediaContainer">
              <p>Follow Us On Social Media✨</p>
              <div class="socialMediaIconsHolder">
                <a
                  target="_blank"
                  href="https://www.facebook.com/felicitycareofficial/"
                  ><i class="fa fa-facebook-official" aria-hidden="true"></i
                ></a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/felicity_care/"
                  ><i class="fa fa-instagram" aria-hidden="true"></i
                ></a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC5JO-2aftdLYSUupZm2Ca1A"
                >
                  <i class="fa fa-youtube-play" aria-hidden="true"></i
                ></a>
                <a target="_blank" href="https://twitter.com/CareFelicity">
                  <i class="fa fa-twitter" aria-hidden="true"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="menuHolder">
          <ul class="list">
            <li>
              <a
                routerLink="online-counseling-psychologists/how-it-works"
                routerLinkActive="is-active"
                >How it works</a
              >
            </li>
            <li>
              <a
                routerLink="online-counseling-psychologists/plans-pricing"
                routerLinkActive="is-active"
                >Plans &amp; Pricing</a
              >
            </li>
            <li>
              <a routerLink="our-counselors" routerLinkActive="is-active"
                >Our Counselors</a
              >
            </li>
            <li><a routerLink="wellness-hub" routerLinkActive="is-active">Wellness Hub</a></li>
            <li><a routerLink="corporates" routerLinkActive="is-active">Corporates</a></li>
            <!-- <li>
                <div class="dropdown">
                  <a class="dropbtn">Community</a
                  ><i class="fa fa-angle-down" aria-hidden="true"></i>
                  <div class="dropdown-content">
                    <a style="font-size: 14px; color: #333" routerLink="blog"
                      >Happy Place - The Felicity Blog</a
                    >
                    <a
                      style="font-size: 14px; color: #333"
                      routerLink="/discussion-forum"
                      >Discussion Forum
                    </a>
                    <a
                      style="font-size: 14px; color: #333"
                      routerLink="our-counselors"
                      >Our Counselors</a
                    >
                    <a style="font-size: 14px; color: #333" routerLink="about-us"
                      >About Us</a
                    >
                  </div>
                </div>
              </li> -->
            <!-- <li>
                <a routerLink="contact-us">Contact</a>
              </li> -->
            <li
              *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
              class="signup-btn"
            >
              <button
                class="buttonTheme1 loginButton widthForButton"
                routerLink="sign-up"
              >
                Sign Up
              </button>
            </li>
            <li
              *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
              class="login-btn"
            >
              <button class="buttonTheme2 widthForButton" routerLink="login">
                Login
              </button>
            </li>
            <li *ngIf="userIsAuthenticated" class="dashboard-btn">
              <a routerLink="user-dashboard">
                <button class="buttonTheme1 widthForButton">
                  My Dashboard
                </button>
              </a>
            </li>
            <li *ngIf="therapistIsAuthenticated" class="dashboard-btn">
              <button
                class="buttonTheme1 widthForButton"
                routerLink="therapist-dashboard"
              >
                My Dashboard
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
