import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../environments/environment";

const therapistBackendUrl = environment.apiUrl + "/therapist/login";
@Injectable({
  providedIn: "root",
})
export class TherapistAuthService {
  private isAuthenticated = false;
  private therapistToken: string;
  private tokenTimer: NodeJS.Timer;
  private therapistId: string;
  private authStatusListener = new Subject<boolean>();
  expiresIn: number = 86400000; //assuming token expires in 24hrs

  constructor(
    private http: HttpClient,
    private router: Router,
    private actRoute: ActivatedRoute
  ) { }

  getToken() {
    return this.therapistToken;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.therapistId;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  private saveAuthData(
    token: string,
    expirationDate: Date,
    therapistId: string,
    tzcode: string = ""
  ) {
    localStorage.clear();
    localStorage.setItem("therapistToken", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("therapistId", therapistId);
    if (tzcode !== "") {
      localStorage.setItem("therapistTzcode", tzcode);
    }
  }

  private getAuthData() {
    const token = localStorage.getItem("therapistToken");
    const expirationDate = localStorage.getItem("expiration");
    const therapistId = localStorage.getItem("therapistId");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: therapistId,
    };
  }

  private clearAuthData() {
    localStorage.removeItem("therapistToken");
    localStorage.removeItem("expiration");
    localStorage.removeItem("therapistId");
    localStorage.clear();
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setInterval(() => {
      this.http.get<any>(therapistBackendUrl + "/refresh-token").subscribe(
        (response) => {
          const token = response.token;
          this.isAuthenticated = true;
          this.therapistId = response.mainTherapist._id;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + this.expiresIn);
          let userTimezone = "";
          if (response?.mainTherapist?.TimeZone) {
            userTimezone = response.mainTherapist.TimeZone["tzCode"];
          }
          this.saveAuthData(
            token,
            expirationDate,
            this.therapistId,
            userTimezone
          );
          this.setAuthTimer(this.expiresIn);
        },
        (error) => {
          this.logout();
        }
      );
    }, duration * 1000);
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      // this.logout(); 
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.therapistToken = authInformation.token;
      this.isAuthenticated = true;
      this.therapistId = authInformation.userId;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    } else {
      this.logout();
    }
  }

  logout() {
    this.therapistToken = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.therapistId = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/therapist-login"]);
  }

  loginTherapist(email: string, password: string, stopLoader) {
    const reqData = {
      email: email,
      password: password,
    };
    this.http.post<any>(therapistBackendUrl + "/login", reqData).subscribe(
      (response) => {
        const token = response.token;
        this.therapistToken = token;
        if (token) {
          this.setAuthTimer(this.expiresIn);
          this.isAuthenticated = true;
          this.therapistId = response.mainTherapist._id;
          this.authStatusListener.next(true);
          let therapistTimezone = "";
          if (response?.mainTherapist?.TimeZone) {
            therapistTimezone = response.mainTherapist.TimeZone["tzCode"];
          }
          const now = new Date();
          const expirationDate = new Date(now.getTime() + this.expiresIn);
          if (localStorage.getItem("userToken")) {
            window.location.replace(
              environment.base_url + "/therapist-dashboard"
            );
          } else {
            let authRed = "";
            authRed = this.actRoute.snapshot.queryParams["authRed"];
            if (authRed) {
              this.router.navigateByUrl(authRed);
            } else {
              this.router.navigate(["/therapist-dashboard"]);
            }
          }
          stopLoader()
          this.saveAuthData(
            token,
            expirationDate,
            this.therapistId,
            therapistTimezone
          );
        }
      },
      (error) => {
        this.authStatusListener.next(false);
        stopLoader()
      }
    );
  }

  updatePassword(passwordData) {
    return this.http.put(
      environment.apiUrl + "/therapist/therapist/update-password",
      passwordData
    );
  }

  forgotPassword(data) {
    return this.http.post(therapistBackendUrl + "/forgotPassword", data);
  }

  verifyResetCode(code) {
    return this.http.get(therapistBackendUrl + "/resetPassword/" + code);
  }

  resetPassword(data) {
    return this.http.post(therapistBackendUrl + "/resetPassword", data);
  }
}
