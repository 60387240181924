import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { ContactService } from "../../contact.service";
import clevertap from "clevertap-web-sdk";
import { RegionSelectionService } from "src/app/region-selection.service";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
})
export class ContactUsComponent implements OnInit {
  Name: String = "";
  userCountryCode = {
    countryCode : "in",
    dialCode : "91"
  };
  regionData;
  constructor(
    private contactService: ContactService,
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private metaSeo : SeoMetaService,
    private regionSelectionService : RegionSelectionService
  ) {}
  contactform = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
    ]),
    phone: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
    ]),
    message: new FormControl("", Validators.required),
  });
  ngOnInit(): void {
    this.metaSeo.updateTitle("Contact Us | Felicity");
    this.regionSelectionService.getRegionDataListener().subscribe((data) => {
      this.regionData = data;
    })
    this.regionSelectionService.setRegionData(() => {});
  }
  checkError(valid) {
    if(!valid){
      this.contactform.controls["phone"].setErrors({ 'invalidPhone' : true })
    }
  }

  getNumber(event) {
  }

  async telInputObject(event) {
    // console.log(event);
    // event.setCountry("id");
    let regionData = await this.regionSelectionService.returnRegionData();
    event.setCountry(String(regionData["regionCode"]).toLowerCase());
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  getFormControls() {
    return this.contactform.controls;
  }
  onSubmit() {
    if (!this.contactform.valid) {
      return;
    }
    this.confirmationService
      .confirm(
        "Are you sure you want to request a callback?",
        "",
        "Yes",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          this.contactService
            .requestContact({ ...this.contactform.value, countryCode : this.userCountryCode})
            .subscribe((data) => {
              clevertap.event.push("Contact Form Submitted")
            });
        }
      });
  }
}
