import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup,Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { DiscussionForumService } from "src/app/user/discussion-forum.service";
import { environment } from "src/environments/environment";
import { BlogService } from "../../blog.service";
import { debounce } from 'lodash';
import clevertap from "clevertap-web-sdk";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { SubscriptionService } from "../../subscription.service";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
// import {Component} from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';



@Component({
  selector: "app-blog-list",
  templateUrl: "./blog-list.component.html",
  styleUrls: ["./blog-list.component.css"],

})
export class BlogListComponent implements OnInit {
  categories = [];
  items = [];
  paginObject = {};
  totalCount = 0;
  isLoading = false;
  isShimmerLoader = true;
  isSearch = true;
  pages = [1];
  showNext = false;
  pageNo = 1;
  imageUrl = environment.apiUrl;
  category = "";
  searchQueryTxt = new FormControl("");
  blogLoading: Boolean = true;

  constructor(
    private blogService: BlogService,
    private discussionForumService: DiscussionForumService,
    private sidenavService: SideNavBarService,
    private subscriptionService: SubscriptionService,
    private alertService: BootstrapAlertService,
    private actRoute: ActivatedRoute,
    private router: Router
  ) {
    this.searchQuery = debounce(this.searchQuery, 800);
   }

  // shimmer
  fakeArray(length: number): Array<any> {
    if (length >= 0) {
      return new Array(length);
    }
  }

  subsForm = new FormGroup({
    email: new FormControl("", Validators.required),
  });

  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    this.discussionForumService.getCategories().subscribe((data) => {
      this.categories = data["category"];
      this.isSearch = false;

    });
    this.paginObject = {
      page: this.pageNo,
      perPage: 10,
    };
    this.actRoute.queryParamMap.subscribe((params) => {
      this.category = params.get("category");
      if (this.category) {
        this.isShimmerLoader = true;
        this.paginObject["category"] = this.category;
      }
      if (params.get("search")) {
        this.searchQueryTxt.setValue(params.get("search"));
        this.paginObject["searchQuery"] = params.get("search");
      }
      this.isShimmerLoader = false;
      this.getBlogs();
    });
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }
  getBlogs(isPagin = false) {
    if (isPagin == false) {
      this.isShimmerLoader = true;
    }
    this.blogService.getBlogs(this.paginObject).subscribe((data) => {
      if (isPagin) {
        this.isShimmerLoader = false;
        this.items = this.items.concat(data["result"]);
      } else {
        this.items = data["result"];
      }
      this.isLoading = false;
      this.isShimmerLoader = false;
      this.totalCount = data["totalCount"];
      let pageCount = Math.ceil(this.totalCount / 10);
      if (pageCount != this.pages.length) {
        for (let i = 0; i < pageCount - 1; i++) {
          if (this.pages.indexOf(i + 2)) {
            this.pages.push(i + 2);
          }
        }
      }
      this.checkPage();
    });
  }

  checkPage() {
    if (this.pageNo === this.pages[this.pages.length - 1]) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }
  }
  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;
    this.isShimmerLoader = false;
    this.pageNo = this.pageNo + 1;
    this.paginObject["page"]++;
    this.getBlogs(true);
    this.checkPage();
  }

  setCategory(category) {
    this.isShimmerLoader = true;
    this.category = category;

    let paramObj: Params = { category: category };
    this.router.navigate([], {
      relativeTo: this.actRoute,
      queryParams: paramObj,
      queryParamsHandling: "merge",
    });

    this.isShimmerLoader = false;
  }

  clearCategory() {
    this.isShimmerLoader = true;
    this.category = "";
    if (this.paginObject["category"]) {
      delete this.paginObject["category"];
    }
    this.router.navigate([], {
      queryParams: {
        category: null,
      },
      queryParamsHandling: "merge",
    });
    this.isShimmerLoader = false;
  }

  searchQuery() {
    this.isShimmerLoader = true;
    if (this.searchQueryTxt.value.length > 0) {
      this.paginObject["searchQuery"] = this.searchQueryTxt.value;
    } else {
      if (this.paginObject["searchQuery"]) {
        delete this.paginObject["searchQuery"];
      }
      this.getBlogs();
    }
    let paramObj: Params = { search: this.searchQueryTxt.value };
    this.router.navigate([], {
      relativeTo: this.actRoute,
      queryParams: paramObj,
      queryParamsHandling: "merge",
    });
    this.isShimmerLoader = false;
  }

  clearSearch() {
    this.isShimmerLoader = true;
    this.searchQueryTxt.setValue("");
    if (this.paginObject["searchQuery"]) {
      delete this.paginObject["searchQuery"];
    }
    this.router.navigate([], {
      queryParams: {
        search: null,
      },
      queryParamsHandling: "merge",
    });
    this.isShimmerLoader = false;
  }

  getFormControls() {
    return this.subsForm.controls;
  }

  onSubmit() {
    this.subscriptionService
      .subscribe(this.subsForm.value.email)
      .subscribe((res) => {
        if (res) {
          clevertap.event.push("Email Subscribed");
          this.alertService.alert(
            new BootstrapAlert(
              "You have successfully subscribed!",
              "alert-success"
            )
          );
          this.subsForm.reset();
        }
      });
  }
}
