<div class="therapistsWrapper" (click)="closenavbar()">
  <div class="container">
    <div class="midTextWrapper">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="secondaryHeading">
            <h2>{{regionData?.meetTherapistStr}}</h2>
            <hr />
            <p>
              Meet the highly qualified team of some of the best names in
              psychology who deliver improved well-being to you.
            </p>
            <a
              routerLink="/our-counselors"
              class="buttonTheme1"
              style="cursor: pointer; color: white"
              >View All</a
            >
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="topRowTBox">
      <div class="row">
        <div class="col-md-6">
          <a
            routerLink="/community-talk-therapists-detail/{{regionData?.howItWorksTherapists[0].id}}"
            style="color: #021744"
          >
            <div class="therapistBox">
              <div class="aboutTBox">
                {{regionData?.howItWorksTherapists[0].name}}, {{regionData?.howItWorksTherapists[0].title}}
              </div>
              <div class="tBoxIcon">
                <img
                  src="{{regionData?.howItWorksTherapists[0].imgSrc}}"
                  alt="felicity online counselor psychologist sakina palitanawala"
                  class="img-fluid"
                  draggable="false"
                />
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-6">
          <div class="therapistBox rightBox">
            <a
              routerLink="/community-talk-therapists-detail/{{regionData?.howItWorksTherapists[1].id}}"
              style="color: #021744; display: block !important"
            >
              <div class="aboutTBox">{{regionData?.howItWorksTherapists[1].name}}, {{regionData?.howItWorksTherapists[1].title}}</div>
              <div class="tBoxIcon">
                <img
                  src="{{regionData?.howItWorksTherapists[1].imgSrc}}"
                  alt="felicity online counselor psychologist V S Ananthakrishnan"
                  class="img-fluid"
                  draggable="false"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomRowTBox">
      <div class="row">
        <div class="col-md-6">
          <a
            routerLink="/community-talk-therapists-detail/{{regionData?.howItWorksTherapists[2].id}}"
            style="color: #021744"
          >
            <div class="therapistBox">
              <div class="aboutTBox">
                {{regionData?.howItWorksTherapists[2].name}}, {{regionData?.howItWorksTherapists[2].title}}
              </div>
              <div class="tBoxIcon">
                <img
                  src="{{regionData?.howItWorksTherapists[2].imgSrc}}"
                  alt="felicity online counselor psychologist puja roy"
                  class="img-fluid"
                  draggable="false"
                />
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-6">
          <a
          routerLink="/community-talk-therapists-detail/{{regionData?.howItWorksTherapists[3].id}}"
          style="color: #021744"
        >
          <div class="therapistBox rightBox">
            <div class="aboutTBox">
              {{regionData?.howItWorksTherapists[3].name}}, {{regionData?.howItWorksTherapists[3].title}}
            </div>
            <div class="tBoxIcon">
              <img
                src="{{regionData?.howItWorksTherapists[3].imgSrc}}"
                alt="felicity online counselor psychologist angelina david"
                class="img-fluid"
                draggable="false"
              />
            </div>
          </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
