<div (click)="closenavbar()">
  <div class="forCorporatesWrapper">
    <div class="container">
      <div class="row imageMessagebox">
        <div class="col-md-7">
          <div class="backgroundImgMesg">
            <h3>Higher productivity through happier employees</h3>
            <p>Live Counselling Therapy Sessions with Top Experts</p>
            <a routerLink="/sign-up-demo" class="thm-2">Get A Demo</a>
          </div>
        </div>
        <div class="col-md-6 imageWrapper">
          <div class="imageWrapperclass">
            <img
              src="../../../assets/images/corporate-wellness-mental-health/happy-corporate-employees.svg"
              class="img-fluid"
              draggable="false"
              alt="mental health benefits for corporate employees"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="howItWorksBox sectionPadding">
    <div class="container">
      <div class="sectionHeadingstyle">
        <h2>How it works?</h2>
      </div>
      <div class="contentBoxWrapper">
        <div class="row justify-content-center">
          <div class="col-md-4 col-sm-12">
            <div class="individualContentBox">
              <div class="imageWrapclass">
                <img
                  src="../../../assets/images/corporate-wellness-mental-health/understand-employee-mental-health-need.svg"
                  class="img-fluid"
                  alt="identify employee mental wellness, mental health program for corporates"
                  draggable="false"
                />
              </div>
              <div class="contentInsideSinglebox">
                <h5>Understand</h5>
                <ul>
                  <li>Felicity gives a demo</li>
                  <li>
                    Corporate explains its needs - all employees, specific
                    subsets, max cap on sessions by 1 user etc
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="individualContentBox">
              <div class="imageWrapclass">
                <img
                  src="../../../assets/images/corporate-wellness-mental-health/agree-corporate-plan-pricing.svg"
                  class="img-fluid"
                  alt="corporate mental health plans and pricing, counseling sessions for employees"
                  draggable="false"
                />
              </div>
              <div class="contentInsideSinglebox">
                <h5>Agree</h5>
                <ul>
                  <li>
                    Agreement on a price per session and total subscription
                    value Payment
                  </li>
                  <li>Corp microsite & app access</li>
                  <li>Subscription activation</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="individualContentBox">
              <div class="imageWrapclass">
                <img
                  src="../../../assets/images/corporate-wellness-mental-health/manage-corporate-account.svg"
                  class="img-fluid"
                  alt="manage corporate counseling therapy account for employees, track progress, weekly reports, analysis"
                  draggable="false"
                />
              </div>
              <div class="contentInsideSinglebox">
                <h5>Use & Monitor</h5>
                <ul>
                  <li>
                    Employees log in using the corporate email id and can access
                    the platform via web/iOS/android
                  </li>
                  <li>Weekly data usage report & monthly feedback report</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sectionPadding">
    <div class="container">
      <div class="boxRoundCircle backGroundColor">
        <div class="sectionHeadingstyle">
          <h2>Become an employer of choice, go beyond traditional benefits</h2>
          <hr />
          <p>Try Felicity for your employees</p>
          <a routerLink="/sign-up-demo" class="thm-2">Get a Demo</a>
        </div>
      </div>
    </div>
  </div>
</div>
