import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { environment } from "src/environments/environment";
import { BookingService } from "../booking.service";
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import clevertap from "clevertap-web-sdk";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: "app-sessions",
  templateUrl: "./sessions.component.html",
  styleUrls: ["./sessions.component.css"],
})
export class SessionsComponent implements OnInit {
  textoverbanner = "";
  items = [];
  pageNo = 1;
  pages = [1];
  showNext = false;
  totalCount = 0;
  selectedCategory = "SCHEDULED";
  isLoading = true;
  isShimmerLoading = true;
  skip = 0;
  perPage = 6;
  imageUrl = environment.apiUrl;
  isCancelSessionLoading = {
    status: false,
    id: ''
  }
  userTimezone = 'Asia/Calcutta';
  constructor(
    private router: Router,
    private sessionsService: BookingService,
    private confirmationService: ConfirmationDialogService,
    private alertService: BootstrapAlertService
  ) { }
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    this.setCategory(this.selectedCategory);
    if (localStorage.getItem("userTzcode")) {
      this.userTimezone = localStorage.getItem("userTzcode")
    } else {
      this.userTimezone = momentTz.tz.guess();
    }
  }
  redirectTo(id) {
    this.router.navigateByUrl("user-dashboard/join-session?sessionId=" + id);
  }
  redirectToV2(id) {
    this.router.navigateByUrl("user-dashboard/session-join-user?sessionId=" + id);
  }

  redirectToV3(id) {
    this.router.navigateByUrl("user-dashboard/session-join-call-user?sessionId=" + id);
  }
  checkPage() {
    if (this.pageNo === this.pages[this.pages.length - 1]) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }
  }

  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;
    this.pageNo = this.pageNo + 1;
    this.getSessions(false);
    this.checkPage();
  }

  setPage(pageNo) {
    this.pageNo = pageNo;
    this.getSessions(false);
  }

  getSessions(isCategoryChange) {
    this.skip = (this.pageNo - 1) * this.perPage;
    this.sessionsService
      .sessionPagin(this.selectedCategory, this.skip, this.perPage)
      .subscribe((data) => {
        if (isCategoryChange) {
          this.items = [];
        }
        let processedData = [];
        data['result'].forEach((item) => {
          let newObj = { ...item }
          let startDateObj = momentTz.tz(newObj['startDate'], this.userTimezone)
          let endDateObj = momentTz.tz(newObj['endDate'], this.userTimezone)
          newObj['sessionDay'] = moment(startDateObj).format('dddd')
          newObj['sessionDate'] = moment(startDateObj).format('DD MMM yyyy')
          newObj['sessionStartTime'] = moment(startDateObj).format('hh:mm a')
          newObj['sessionEndTime'] = moment(endDateObj).format('hh:mm a')
          processedData.push(newObj)
        })
        this.items = this.items.concat(processedData);
        this.totalCount = data["totalCount"];
        if (this.selectedCategory == "SCHEDULED") {
          this.textoverbanner =
            "You have " + this.totalCount + " upcoming sessions";
        }
        if (this.selectedCategory == "COMPLETED") {
          this.textoverbanner = this.totalCount + " sessions completed so far";
        }
        let pageCount = Math.ceil(this.totalCount / 6);
        if (pageCount != this.pages.length) {
          for (let i = 0; i < pageCount - 1; i++) {
            if (this.pages.indexOf(i + 2)) {
              this.pages.push(i + 2);
            }
          }
        }
        this.checkPage();
        this.isLoading = false;
        this.isShimmerLoading = false;
      });
  }

  setCategory(category) {
    this.selectedCategory = category;
    this.getSessions(true);
  }

  cancelSession(session) {
    console.log(session)
    let now = moment();
    let startDate = moment(session.startDate);
    let diffInHours = moment.duration(startDate.diff(now));
    if (diffInHours.asHours() < 6) {
      this.confirmationService.confirm(
        "Are you sure?",
        "As less than 6 hours remain for your session to start, you are not allowed to cancel the session. Please contact the helpdesk for more information.",
        "Helpdesk",
        "Close"
      );
    } else {
      this.confirmationService
        .confirm(
          "Are you sure you want to cancel the session?",
          " ",
          "Yes",
          "No"
        )
        .subscribe((res) => {
          if (res === true) {
            this.isCancelSessionLoading.status = true
            this.isCancelSessionLoading.id = session._id
            this.sessionsService.cancelSession(session._id).subscribe((res) => {
              clevertap.event.push("Session Cancelled", {
                "Therapist Name": session.therapistId.firstname + " " + session.therapistId.lastname,
                "Session Date": momentTz.tz(session.startDate, "Asia/Calcutta").format("DD/MM/YYYY"),
                "Session Time": momentTz.tz(session.startDate, "Asia/Calcutta").format("hh:MM A")
              })
              this.confirmationService.confirm("Session cancelled successfully", "Would you like to reschedule the session?", "Yes", "No").subscribe((res1 => {
                if(res1 === true){
                  this.router.navigateByUrl('/view-counsellor-calendar/'+session.therapistId._id)
                }
              }))
              this.isCancelSessionLoading.status = false
              this.isCancelSessionLoading.id = ''
              this.items.forEach((element, index) => {
                if (element._id === session._id) {
                  this.items.splice(index, 1);
                  this.totalCount--;
                }
                if (this.selectedCategory == "SCHEDULED") {
                  this.textoverbanner =
                    "You have " + this.totalCount + " upcoming sessions";
                }
                if (this.selectedCategory == "COMPLETED") {
                  this.textoverbanner = this.totalCount + " sessions completed so far";
                }
              })
            }, err => {
              this.isCancelSessionLoading.status = false
              this.isCancelSessionLoading.id = ''
              this.alertService.alert(
                new BootstrapAlert(
                  err?.error.message || 'Something went wrong',
                  "alert-danger"
                )
              );
            });
          }
          if (res === true) {
          }
        });
    }
    // this.sessionsService.cancelSession(session._id)
  }
}
