import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { AuthService } from "../auth.service";
import { ViewChild, ElementRef } from "@angular/core";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from 'rxjs';
import { auth } from 'firebase/app';
import { BootstrapAlertService } from "../../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { RegionSelectionService } from "src/app/region-selection.service";
@Component({
  selector: "app-user-signup",
  templateUrl: "./user-signup.component.html",
  styleUrls: ["./user-signup.component.css"]
})
export class UserSignupComponent implements OnInit {
  @ViewChild("signUpRef", { static: true }) loginElement: ElementRef;
  isShowPassword: Boolean = false;
  authStatusSub: Subscription;
  Name: string = "";
  auth2;
  planForRedirection = null;
  isLoading = false;
  therapistRedirection = null;
  redirection = null;
  redirectObj = {}
  userCountryCode = {
    countryCode : 'in',
    dialCode : '91'
  };
  constructor(
    public authService: AuthService,
    private router: ActivatedRoute,
    private sidenavService: SideNavBarService,
    private route: Router,
    public afAuth: AngularFireAuth,
    private alertService: BootstrapAlertService,
    private regionSelectionService : RegionSelectionService
  ) {
  }
  signUpForm = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
    ]),
    phone: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
    ]),
    password: new FormControl("", [
      Validators.required,
      Validators.pattern(
        "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$"
      ),
    ]),
  });
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };
  regionData;

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  ngOnInit() {
    // this.authStatusSub = this.authService
    //   .getAuthStatusListener()
    //   .subscribe((authStatus) => { //redirection if the user is already logged in!!
    //     if(authStatus){
    //       this.route.navigate(['user-dashboard'])
    //     }
    //   });
    this.regionSelectionService.getRegionDataListener().subscribe((data) => {
      this.regionData = data;
    })
    this.regionSelectionService.setRegionData(() => {});
    if (this.authService.getIsAuth()) {
      this.route.navigate(['/user-dashboard'])
    }
    this.router.queryParams.subscribe((params) => {
      this.planForRedirection = params["plan"];
      this.therapistRedirection = params["therapist"];
      this.redirection = params["redirect"];
      if (this.planForRedirection) {
        this.redirectObj["plan"] = params["plan"];
      }
      if (this.therapistRedirection) {
        this.redirectObj["therapist"] = params["therapist"];
      }
      if (this.redirection) {
        this.redirectObj["redirect"] = params["redirect"];
      }
    });
    window.onload = (event) => {
      this.loadGTMCid();
    };
    this.loadGTMCid();
  }

  loadGTMCid() {
    if (window["dataLayer"]) {
      let dataLayer = window["dataLayer"];
      dataLayer.forEach((data) => {
        if (data["cid"]) {
          localStorage.setItem("GTMcid", data["cid"]);
        }
      });
    }
  }

  checkError(valid) {
    if (!valid) {
      this.signUpForm.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.signUpForm.patchValue({
      phone: phoneNew
    })
  }

  async telInputObject(event) {
    let regionData = await this.regionSelectionService.returnRegionData();
    event.setCountry(String(regionData["regionCode"]).toLowerCase());
  }

  onCountryChange(event) {
    this.userCountryCode['countryCode'] = event.iso2;
    this.userCountryCode['dialCode'] = event.dialCode;
  }

  getFormControls() {
    return this.signUpForm.controls;
  }

  showPasswordToggle() {
    this.isShowPassword = !this.isShowPassword;
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }
  onSubmit() {
    if (this.signUpForm.controls.phone.status === "INVALID") {
      this.alertService.alert(
        new BootstrapAlert("Please enter a valid phone number", "alert-danger")
      )
      return;
    }
    this.isLoading = true;
    let userData = this.getFormControls();
    this.authService.registerUser(
      userData.name.value,
      userData.email.value,
      userData.phone.value,
      userData.password.value,
      this.userCountryCode, () => this.stopLoader(),
      this.regionData["regionCode"]
    );
  }
  stopLoader() {
    this.isLoading = false
  }

  loginWithGoogle() {
    return this.AuthLogin(new auth.GoogleAuthProvider(), 'Google');
  }

  loginWithFacebook() {
    return this.AuthLogin(new auth.FacebookAuthProvider(), 'Facebook');
  }

  AuthLogin(provider, social) {
    return this.afAuth.signInWithPopup(provider)
      .then(async (result) => {
        let idToken = await result.user.getIdToken();
        this.authService.googleAuth({
          token: idToken,
          social: social,
          region : this.regionData["regionCode"]
        })
      }).catch((error) => {
      })
  }
}
