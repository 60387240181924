<div class="container" style="height: 100vh; width: 100vw; display: flex;  justify-content: center; align-items: center;">
      <div class="boxRound subsBox" *ngIf="!isLoading">
        <div class="text-404">
          <h2>
            404
          </h2>
          <p>Oops! Sorry page does not found</p>
        </div>
          <a href="/" type="submit" class="buttonTheme1 subsButton mt-5">
                Back To Home
              </a>
      </div>
    </div>