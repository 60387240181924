import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { AuthService } from "../auth.service";
import { ViewChild, ElementRef } from "@angular/core";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { ActivatedRoute, Router } from "@angular/router";
// import firebase from 'firebase/compat/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from 'rxjs';
import { auth } from 'firebase/app';

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: "app-user-login",
  templateUrl: "./user-login.component.html",
  styleUrls: ["./user-login.component.css"],
})
export class UserLoginComponent implements OnInit, OnDestroy {
  @ViewChild("loginRef", { static: true }) loginElement: ElementRef;
  isShowPassword: Boolean = false;
  authStatusSub: Subscription;
  auth2;
  Name: String = "";
  isLoading = false;
  planForRedirection = null;
  redirection = null;
  therapsitRedirection = null;
  authRed = null;
  googleUserData: Observable<any>;
  constructor(public authService: AuthService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private sidenavService: SideNavBarService,
    public afAuth: AngularFireAuth) { }
  loginForm = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
    ]),
    password: new FormControl("", Validators.required),
    rememberMe: new FormControl(false),
  });
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  trimInput(){
    this.loginForm.patchValue({
      email : String(this.loginForm.value.email).trim()
    })
  }

  
  ngOnInit(): void {
    if (this.authService.getIsAuth()) {
      this.router.navigate(['/user-dashboard'])
    }
    this.actRoute.queryParams.subscribe((params) => {
      if(params["source"]){
        localStorage.setItem("partner_source", params['source'])
      }
    })
    // this.googleInitialize();
  }

  loginWithGoogle() {
    return this.AuthLogin(new auth.GoogleAuthProvider(), 'Google');
  }

  loginWithFacebook() {
    return this.AuthLogin(new auth.FacebookAuthProvider(), 'Facebook');
  }

  AuthLogin(provider, social) {
    return this.afAuth.signInWithPopup(provider)
      .then(async (result) => {
        let idToken = await result.user.getIdToken();
        this.authService.googleAuth({
          token: idToken,
          social: social
        })
      }).catch((error) => {
      })
  }

  getFormControls() {
    return this.loginForm.controls;
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  showPasswordToggle() {
    this.isShowPassword = !this.isShowPassword;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    let userData = this.getFormControls();
    this.authService.loginUser(userData.email.value, userData.password.value, () => this.stopLoader());
  }
  stopLoader() {
    this.isLoading = false
  }

  // googleInitialize() {
  //   window["googleSDKLoaded"] = () => {
  //     window["gapi"].load("auth2", () => {
  //       this.auth2 = window["gapi"].auth2.init({
  //         client_id:
  //           "755399128822-5mdosqcuefas6qnedab5086541c9s4kv.apps.googleusercontent.com",
  //         cookie_policy: "single_host_origin",
  //         scope: "profile email",
  //       });
  //       this.prepareLogin();
  //     });
  //   };
  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "google-jssdk");
  // }

  // prepareLogin() {
  //   this.auth2.attachClickHandler(
  //     this.loginElement.nativeElement,
  //     {},
  //     (googleUser) => {
  //       let profile = googleUser.getBasicProfile();
  //       let email = profile.getEmail();
  //       this.authService.loginGoogleUser(email);
  //     },
  //     (error) => {
  //       alert(JSON.stringify(error, undefined, 2));
  //     }
  //   );
  // }

  ngOnDestroy() {
  }
}
