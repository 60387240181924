<div class="loading-screen">
    <h2 class="text-xl" *ngIf="status === 'waiting'">Please wait while we verify your request!</h2>
    <ng-lottie
    height="150px"
    *ngIf="status === 'waiting'"
    [options]="options"
    (animationCreated)="animationCreated($event)"
  ></ng-lottie>
    <h2 class="text-xl" *ngIf="status === 'success'">Yay! Verification successful! Redirecting...</h2>
    <h2 class="text-xl" *ngIf="status === 'failed'">Token Invalid or expired! Please login again from your portal</h2>
    <h2 class="text-xl" *ngIf="status === 'expired'">It looks like your session has expired! Please login again from your portal.</h2>
    <h2 class="text-xl" *ngIf="status === 'missing_id'">Required parameters are missing</h2>
</div>