import { Component, OnInit } from "@angular/core";
import { ProfileService } from "../profile.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { BootstrapAlertService } from "../../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { environment } from "src/environments/environment";
import { FileValidatorService } from "src/app/shared/file-validator.service";
import { COMMON_DATA } from "src/app/shared/common";
import clevertap from "clevertap-web-sdk";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService } from "src/app/auth/auth.service";
import { DeleteAccountDialogService } from "src/app/delete-account-dialog.service";


@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  userData;
  selectedProfileImage: File = null;
  userDataForm: FormGroup;
  isLoading = false;
  userName: string = "";
  isShimmerLoading = true;
  ages = COMMON_DATA.ages;
  gender = COMMON_DATA.gender;
  moment = COMMON_DATA.moment;
  seekingHelp = COMMON_DATA.seekingHelp;
  therapyBefore = COMMON_DATA.therapyBefore;
  therapistgenderpreference = COMMON_DATA.therapistgenderpreference;
  firstsessionAt = COMMON_DATA.firstsessionAt;
  language = COMMON_DATA.language;
  selectedLanguageArray;
  onLoadSelectedLanguageArray = [];
  bsConfig: Partial<BsDatepickerConfig>;
  imageUrl = environment.apiUrl;
  specializeareas1 = COMMON_DATA.specializeareasOptions;
  preferredMediumOptions = COMMON_DATA.preferredMediumOptions;
  selected;
  seekingHelpOption;
  selectedOptionspecializeareas;
  userCountryCode;
  showBtn: boolean = true;
  showSpecificArea = false;
  TimeSlott = [
    "7 AM - 11 AM",
    "11 AM - 3 PM",
    "3 PM - 7 PM",
    "7 PM - 11 PM",
    "11 PM - 3 AM",
    "3 AM - 7 AM",
  ];
  constructor(
    private profileService: ProfileService,
    private confirmationService: ConfirmationDialogService,
    private alertService: BootstrapAlertService,
    private uploadValidator: FileValidatorService,
    private DeleteAccountDialogService: DeleteAccountDialogService,
    private authService:AuthService

  ) { }

  doShowButton() {
    this.showBtn = true;
  }


  doHideButton() {
    this.showBtn = false;
  }

  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  ngOnInit() {
    this.initForms();
    // this.profileService.getFreeReportData().subscribe((res) => {
    //   console.log(res)
    // })
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    this.profileService.getProfile().subscribe((data) => {
      this.isShimmerLoading = false;
      this.userData = data;
      this.userName = data.name;
      this.selectedLanguageArray = data.selectedLanguageArray;
      this.onLoadSelectedLanguageArray = [...this.selectedLanguageArray];
      this.userDataForm.setValue({
        name: data.name ? data.name : null,
        email: data.email ? data.email : null,
        phone: data.phone ? data.phone : null,
        dob: data.dob ? new Date(data.dob) : null,
        age: data.age ? data.age : null,
        gender: data.gender ? data.gender : null,
        doAtMoment: data.doAtMoment ? data.doAtMoment : null,
        otherdoAtMoment: data.otherdoAtMoment ? data.otherdoAtMoment : null,
        seekingHelp: data.seekingHelp ? data.seekingHelp : null,
        specializeArea: data.specializeArea ? data.specializeArea : null,
        specifyNeed: data.specifyNeed ? data.specifyNeed : null,
        therapyBefore: data.therapyBefore ? data.therapyBefore : null,
        therapistgenderpreference: data.therapistgenderpreference
          ? data.therapistgenderpreference
          : null,
        language: null,
        preferredMedium: data.preferredMedium ? data.preferredMedium : null,
        firstsessionAt: data.firstsessionAt ? data.firstsessionAt : null,
        TimeSlott: data.TimeSlott ? data.TimeSlott : null,
      });
      if (this.userData["countryCode"]) {
        this.userCountryCode = this.userData["countryCode"];
        if (data.phone) {
          if (clevertap) {
            clevertap.profile.push({
              "Site": {
                "Phone": "+" + this.userCountryCode.dialCode + data.phone
              }
            })
          }
        }
      }
    }, (err) => {
      this.isShimmerLoading = false;
    });
  }

  checkError(valid) {
    if (!valid) {
      this.userDataForm.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.userDataForm.patchValue({
      phone: phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }

  initForms() {
    this.userDataForm = new FormGroup({
      name: new FormControl("", Validators.required),
      email: new FormControl(
        { value: "", disabled: true },
        Validators.required
      ),
      phone: new FormControl("", Validators.required),
      dob: new FormControl(Validators.required),
      age: new FormControl(),
      gender: new FormControl(),
      doAtMoment: new FormControl(),
      otherdoAtMoment: new FormControl(),
      seekingHelp: new FormControl(),
      specializeArea: new FormControl("", Validators.required),
      specifyNeed: new FormControl(),
      therapyBefore: new FormControl(),
      therapistgenderpreference: new FormControl("", Validators.required),
      language: new FormControl(),
      firstsessionAt: new FormControl("", Validators.required),
      preferredMedium: new FormControl("", Validators.required),
      TimeSlott: new FormControl("", Validators.required)
    });
  }

  uploadProfile(event) {
    this.selectedProfileImage = <File>event.target.files[0];
    const data = new FormData();
    data.append("upload", this.selectedProfileImage);
    if (
      this.uploadValidator.validateImage(this.selectedProfileImage.name) &&
      this.uploadValidator.validateSize(this.selectedProfileImage.size)
    ) {
      this.profileService.uploadProfileImage(data).subscribe((res) => {
        if (res) {
          this.alertService.alert(
            new BootstrapAlert(
              "Profile image updated successfully",
              "alert-success"
            )
          );
        }
      });
    } else {
      if (!this.uploadValidator.validateImage(this.selectedProfileImage.name)) {
        this.alertService.alert(
          new BootstrapAlert("Please select a valid image", "alert-danger")
        );
      }
      if (!this.uploadValidator.validateSize(this.selectedProfileImage.size)) {
        this.alertService.alert(
          new BootstrapAlert("File size is too large!", "alert-danger")
        );
      }
    }
  }

  onSubmit() {
    if (this.userDataForm.invalid) {
      return this.alertService.alert(
        new BootstrapAlert(
          "Please Fill The Form",
          "alert-danger"
        )
      );;
    }
    this.confirmationService
      .confirm(
        "Are you sure?",
        "This will update your profile",
        "Yes",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          if (this.userDataForm.controls.phone.status === "INVALID") {
            this.alertService.alert(
              new BootstrapAlert("Please enter a valid phone number", "alert-danger")
            )
            return;
          }
          this.isLoading = true;
          let userData = this.getDirtyValues(this.userDataForm);
          userData['countryCode'] = this.userCountryCode
          delete userData["language"];
          if (
            JSON.stringify(this.selectedLanguageArray) !==
            JSON.stringify(this.onLoadSelectedLanguageArray)
          ) {
            userData["selectedLanguageArray"] = this.selectedLanguageArray;
          }
          this.profileService.updateProfile(userData).subscribe((data) => {

            setTimeout(() => {

              this.isLoading = true
              this.alertService.alert(
                new BootstrapAlert(
                  "Profile Updated successfully",
                  "alert-success"
                )
              );
              this.isLoading = false;
            }, 1500);
          }, (err) => {

            this.isLoading = false;
          });
        } else {
          this.isLoading = false
        }
      });
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls)
          dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  addLanguageToArray() {
    let indexInArray = null;
    let languageToAdd = this.userDataForm.value.language;
    if (this.userDataForm.value.language) {
      this.selectedLanguageArray.find(function (language, index) {
        if (languageToAdd == language.language) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedLanguageArray.push({
          language: this.userDataForm.value.language,
          fluency: "",
        });
      }
    }
  }

  removeLanguageFromArray(languageToRemove) {
    let indexInArray = null;
    this.selectedLanguageArray.find(function (language, index) {
      if (languageToRemove == language.language) {
        indexInArray = index;
      }
    });
    if (indexInArray != null) {
      this.selectedLanguageArray.splice(indexInArray, 1);
    }
  }

  indexTracker(index: number, value: any) {
    return index;
  }

  onSpecializeAreaChanged(event) {
    event.forEach((area) => {
      if (area === 'Not Sure') {
        this.showSpecificArea = true;
      }
    })
  }

  showOptions() {
    this.showSpecificArea = false;
    let indexInArray = -1;
    if (this.userDataForm.value.specializeArea.length > 0) {
      this.userDataForm.value.specializeArea.find((area, index) => {
        if (area === 'Not Sure') {
          indexInArray = index
        }
      })
    }
    if (indexInArray > -1) {
      let tempArray = this.userDataForm.value.specializeArea
      tempArray.splice(indexInArray, 1)
      this.userDataForm.patchValue({
        specializeArea: tempArray
      })
    }
  }
  onDeleteAccount(){
    this.DeleteAccountDialogService
        .confirm(
          "Delete Account",
          "Yes, Delete my account",
          "Cancel"
        )
        .subscribe((res) => {
          if (res) {
            this.doDeleteAccount()          
          }
    })
  }
    doDeleteAccount(){
      this.authService.deleteUserAccount().subscribe((res) => {
        this.authService.logout()
      })
    }
}
