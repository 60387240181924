<div>
  <div class="contentBoxBlock">
    <h3>Purchased Plans</h3>
  </div>
  <div class="sectionPadding">
    <div class="sessionFilterWrap">
      <ul>
        <li (click)="setCategory('ACTIVE')">
          <label class="radio">
            <input type="radio" value="ACTIVE" name="plan" checked />
            <span>Active Plans</span>
          </label>
        </li>
        <li (click)="setCategory('EXPIRED')">
          <label class="radio">
            <input type="radio" name="plan" value="EXPIRED" />
            <span>Expired Plans</span>
          </label>
        </li>
      </ul>
    </div>
    <div *ngIf="isShimmerLoading">
      <div class="col-md-12">
      <ngx-skeleton-loader appearance="line" count="2" [theme]="{
        'border-radius': '12px',
        height: '11rem',
        width: '300px',
        display: 'flex !important',
        'margin-top': '3px',
        'margin-left' : '10px',
        'margin-right': '10px'
      }"></ngx-skeleton-loader>
    </div>

    <div class="col-md-12">
      <ngx-skeleton-loader appearance="line" count="2" [theme]="{
        'border-radius': '12px',
        height: '11rem',
        width: '300px',
        display: 'flex !important',
        'margin-top': '3px',
        'margin-left' : '10px',
        'margin-right': '10px'
      }"></ngx-skeleton-loader>
    </div>

    </div>
    <div class="row justify-content-between">
      <div class="col-md-6 col-sm-6 marginBottom" *ngFor="let eachplan of filteredResults"> 
        <div class="planBox">
          <div class="planImageHolder">
            <img src="../../../assets/images/plans-pricing/explore-plan-1.svg" alt="" class="img-fluid" />
          </div>
          <div class="planTextHolder">
            <h5>{{ eachplan.name }}</h5>
            <p>Sessions Available: {{ eachplan.sessions }}</p>
            <p>Chat Days Available: {{ eachplan.chatDaysAvailable }}</p>
            <p>
              Plan purchased On {{ eachplan.boughtOn | date: "longDate" }}
            </p>
          </div>
        </div>
      </div>
      <div class="noPlansTxt" *ngIf="NoPlans">
        <p>There are no plans in this category.</p>
      </div>
    </div>
  </div>
</div>