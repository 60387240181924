<div class="secPadding">
  <div class="container">
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="secondaryHeading">
      <h2>Forgot Password</h2>
      <hr />
    </div>
    <div class="frgtPassContainer justify-content-center">
      <div class="formRound">
        <form [formGroup]="forgotPassForm">
          <input
            type="text"
            placeholder="Your Email Address *"
            class="col-lg-12"
            formControlName="email"
            [ngClass]="{
              'has-error':
                !forgotPassForm.controls.email.valid &&
                forgotPassForm.controls.email.touched,
              'has-success':
                forgotPassForm.controls.email.valid &&
                forgotPassForm.controls.email.touched
            }"
          />
          <div
            *ngIf="
              forgotPassForm.controls.email.errors?.required &&
              forgotPassForm.controls.email.touched
            "
            class="errorContainer"
          >
            <p>Email is required</p>
          </div>
          <div
            *ngIf="
              forgotPassForm.controls.email.errors?.pattern &&
              forgotPassForm.controls.email.touched
            "
            class="errorContainer"
          >
            <p>Email is invalid</p>
          </div>
          <div
            class="text-center submitBtn"
            style="display: flex; justify-content: center; margin-left: -50px"
          >
            <div
              *ngIf="isLoading"
              class=""
              style="display: flex; justify-content: center"
            >
              <!-- <div
                class="spinner-border"
                style="
                  width: 2rem;
                  height: 2rem;
                  margin-left: 55px;
                  margin-top: 23px;
                "
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div> -->
              <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
            </div>
             <button
              class="buttonTheme1"
              style="margin-bottom: 50px; margin-top: 50px;"
              *ngIf="!isLoading"
              (click)="onSubmit()"
            >
              Submit
            </button>
            
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
