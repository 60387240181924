import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const clientNotesUrl = environment.apiUrl + "/therapist/therapist-notes/";
@Injectable({
  providedIn: 'root'
})
export class ClientNotesService {

  constructor(private http: HttpClient) { }

  getNotesPagin(){
    return this.http.get(clientNotesUrl + "clientNotesFolder")
  }

  getNotesByUser(userId: string){
    return this.http.get(clientNotesUrl + "getNotesForUser/" + userId)
  }

  getNoteById(noteId: string){
    return this.http.get(clientNotesUrl + noteId)
  }

  createNote(note: any){
    return this.http.post(clientNotesUrl, note)
  }

  updateNote(noteId: string, note: any){
    return this.http.put(clientNotesUrl + noteId, note)
  }

  getSharedJournals(userId: string){
    return this.http.get(clientNotesUrl + 'sharedJournals/' + userId)
  }

  getUserCompletedSessions(userId: string){
    return this.http.get(clientNotesUrl + 'get-user-sessions/' + userId)
  }
}
