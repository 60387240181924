<div>
    <div class="secondaryHeading">
        <h3>Notifications</h3>
    </div>
    <div class="notificaTable">
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th class="text-center">Notification</th>
                </tr>
                <tr *ngFor="let notification of notifications" [ngClass]="{'newNotify': !notification?.isRead }">
                    <td class="text-nowrap">
                        {{ notification.createdAt | date : " dd MMM y" }}
                    </td>
                    <td class="text-nowrap">
                        {{ notification.createdAt | date : "h:mm a" }}
                    </td>
                    <td>
                        {{notification.title}}
                    </td>
                </tr>
            </table>
        </div>
        <div class="text-center">
           <button *ngIf="showNext" (click)="nextPage()" class="buttonTheme1">Load Older Notifications</button>
        </div>
    </div>
</div>