<div class="bodyPageWrap">
  <div style="display: flex;align-items: center;margin-bottom: 20px;">
    <span ><a routerLink="../helpdesk" style="font-size:16px; color: #07006e; cursor: pointer;text-decoration: underline;">Helpdesk</a></span>
    <span style="font-size:16px">&nbsp;>&nbsp;</span>
    <span style="font-size:16px; color: #808080;">Talk therapy related</span> 
  </div>
  <div>
    <div class="frequentHeadWrap">
      <div class="row">
        <div class="col-md-12">
          <div class="sectionHeading">
            <h2>Talk therapy Related FAQ's</h2>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="frequentQuestionBox">
      <div class="row">
        <div id="1" class="col-lg-12 col-md-6 col-sm-6">
          <div class="questionWrap">
            <h4>Do I have the flexibility to use the therapy format I deem fit for the client?</h4>
            <p class="ptagCss">
                Yes. As a felicity therapist you have full leeway to use the style of therapy that you prefer. However, you are continuously evaluated by the clients after every session, so we give you feedback to improve and expect you to make changes in your style to better meet client expectations. Also, we do not promote hypnotherapy, past life regression and other disputed quarters of the psychology arena on this platform.
            </p>
          </div>
        </div>
        <div id="2" class="col-lg-12 col-md-6 col-sm-6">
          <div class="questionWrap">
            <h4>
                What is expected from me as a talk therapist at felicity?
            </h4>
            <p class="ptagCss">Highest level of professional decorum during the sessions and chats
                </p>
            <p class="ptagCss">
                Accurate analysis of client needs – you are expected to guide the clients in the right direction. If you feel a psychiatrist needs to be involved, please highlight that to the felicity admin and the client. Similarly, if a case is too complicated and you are struggling to handle it, highlight it to the admin.
            </p>
            <p class="ptagCss">Empathy for your clients – we expect you to go over and beyond the scheduled sessions to help clients – by responding to their chat messages time to time and spending ample time to prepare before starting a session.
                </p>
            <p class="ptagCss">Transparency with felicity – we see our talk therapists as the most important members of our team and keep their convenience as a top priority. In return we expect absolute honesty and commitment to client satisfaction.</p>
          </div>
        </div>
        <div id="3" class="col-lg-12 col-md-6 col-sm-6">
          <div class="questionWrap">
            <h4>How does felicity ensure my safety and security?</h4>
            <p class="ptagCss">
                We do not share any personal information with a third party without consent. Also, we use banking grade encryption to ensure chatroom privacy is not breached. Also, you will be practicing therapy from the safe confines of your home.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="frequentHeadWrap">
      <div class="row">
        <div class="col-md-12">
          <div>
            <h3>
              Have More Questions?
              <span class="spanCss">(Access following heads)</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="frequentQuestionBox">
      <div class="row">
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../contract-related-faq">Contract Related</a></b>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../payment-related-faq">Payment Related</a></b>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../platform-related-faq">Platform Related</a></b>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../account-related-faq">Account Related</a></b>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <div class="questionWrapper">
            <b><a routerLink="../benefits-related-faq">Benefits Related</a></b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
