<div class="bodyPageWrap">
    <div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div class="sectionHeading">
              <h2>Troubleshoot issues in your session</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div id="1" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>What to do if I can't be heard in the session?</h4>
              <ul>
                <li>
                    <strong>Restart your browser</strong>
                    <p>Mac computer settings might prevent Felicity from using the microphone. If this happens, restart Chrome Browser. If that doesn't work, restart your computer, then increase the microphone volume in your system’s settings.</p>
                    <strong>Unmute yourself</strong>
                    <p>Click on the crossed mic icon in red color to unmute yourself.</p>
                    <p>If that doesn't help, follow below steps to fix the issue : -</p>
                    <ol>
                        <li>
                            <strong>Unmute your Windows device</strong>
                            <p>Open the Sound settings and then click Recording. -> Double-click the microphone. -> Select Levels. -> Check that the microphone is turned on. -> To raise the volume, move the volume slider and click OK.</p>
                        </li>
                        <li>
                            <strong>Unmute your Mac device</strong>
                            <p>Go to System preferences and then click Sound and then Input.  ->  Make sure that the microphone is on. -> Raise the volume with the volume slider. The volume setting will save automatically.</p>
                        </li>
                        <li>
                            <strong>Unmute your Linux device</strong>
                            <p>Open the Sound settings and then click Input. -> Select the microphone device setting. -> Make sure that the microphone is on. -> To raise the volume, move the volume slider and click OK.</p>
                        </li>
                    </ol>
                </li>
              </ul>
            </div>
          </div>
          <div id="2" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>What to do if I can't be seen in the session?</h4>
              <ul>
                <li>
                    <p>Felicity needs permission to use your camera and microphone in your browser. You will be asked to allow access the first time you join a session.</p>
                    <p>You can change the setting to allow Felicity to use your camera and microphone, click the camera icon in the address bar and select <strong>Always allow</strong> option.</p>
                </li>
                <li>
                    <p>If you still have issues with your camera and microphone permissions, please follow the instructions below :</p>
                    <ol>
                        <li>
                            <strong>Enable camera access on macOS Mojave and later</strong>
                            <p>
                                On computers using macOS Mojave version 10.14 or later, you must allow camera access for your Chrome or Firefox® web browser. Otherwise, Felicity will not be able to access your video from your device.
                            </p>
                            <p>
                                Go to System Preferences and then Security and Privacy. -> Select Privacy and then Camera. -> Check the box for the application used for Meet (Google Chrome or Firefox).
                            </p>
                        </li>
                        <li>
                            <strong>Allow Google Chrome to access your camera</strong>
                            <p>
                                On computers using Chrome, you must allow the browser to access your camera.
                            </p>
                            <p>
                                Enter <i>chrome://settings/content/camera</i> in the Chrome address bar. -> Disable the setting 'Ask before accessing'. -> Under 'Allow', delete https://felicity.care if present. -> Refresh the Join session page and grant camera access when prompted.  
                            </p>
                        </li>
                    </ol>
                </li>
              </ul>
            </div>
          </div>
          <div id="3" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4><strong>What are the best practices I should follow for better experience during a session?</strong></h4>
              <ol>
                <li><p>Use a headset with a microphone.</p></li>
                <li>
                    <p>Make sure that your Bluetooth headset works with Felicity. Some Bluetooth headsets work well for normal audio calls but  poorly with Felicity.</p>
                </li>
                <li>
                    <p>Run an Internet speed test to ensure that you have sufficient bandwidth and low latency.</p>
                </li>
                <li><p>Close non-essential tabs.</p></li>
                <li>
                    <p>Close other apps.</p>
                </li>
                <li>
                    <p>If you are using a VPN, try to see if call quality improves. If it does, continue without the VPN</p>
                </li>
                <li>
                    <p>
                        Make sure you are sitting in calm corner, away from noise.
                    </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  