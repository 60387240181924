import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-plans-and-pricing",
  templateUrl: "./plans-and-pricing.component.html",
  styleUrls: ["./plans-and-pricing.component.css"],
})
export class PlansAndPricingComponent implements OnInit {
  therapistId: string;
  constructor(
    private sidenavService: SideNavBarService,
    private actRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.actRoute.queryParamMap.subscribe((params) => {
      this.therapistId = params.get("counselor");
      if (params.get("counselor")) {
        localStorage.setItem("counselor", params.get("counselor"));
      }
    });
  }
  tryNow() {
    if (this.therapistId) {
      localStorage.setItem("counselor", this.therapistId);
    }
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
}
