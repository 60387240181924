<div>
  <div>
    <div class="formRound">
      <div class="sectionPadding-top">
        <div class="secondaryHeading">
          <h3>Your Schedule</h3>
          <hr />
        </div>
        <div class="weComGQRoup">
          <h3>Select Timezone</h3>
          <div>
            <form [formGroup]="timeZoneForm">
              <ng-select
                class="form-control table"
                formControlName="TimeZone"
                [items]="TimeZone"
              ></ng-select>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-stripped">
              <div>
                <thead>
                  <tr>
                    <th class="stickyCol">Day</th>
                    <td *ngFor="let day of weekDays">{{ day.day }}</td>
                  </tr>
                </thead>
                <tbody class="tableScroll">
                  <tr *ngFor="let slot of timeSlots; let idx = index">
                    <td class="stickyCol">{{ slot.startTime }} - {{ slot.endTime }}</td>
                    <td
                      *ngFor="let day of weekDays"
                      (click)="updateSchedule(day.index, idx)"
                      class="selectable"
                      [ngClass]="{ Selected: day.timeSlots[idx].selected }"
                    ></td>
                  </tr>
                </tbody>
              </div>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="buttonTheme1" (click)="saveSchedule()">Save</button>
    </div>
  </div>
</div>
