<div class="bodyPageWrap" (click)="closenavbar()">
  <div *ngIf="doLoadingShimmer; else boxLoader">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        'border-radius': '5px',
        height: '240px',
        width: '100%',
        'margin-top': '3px',
        'margin-right': '10px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <ng-template #boxLoader>
    <div class="welcomeBoxWrap">
      <div class="row">
        <div class="contentBoxWrap col-md-8 col-sm-9">
          <h3>Something On Your Mind?</h3>
          <p>
            Ask and discuss with the community of users<br />
            from all across the globe
          </p>
          <div class="askAnonymouslyTag">
            <a routerLink="../userdiscussionform" class="thm-2"
              >Ask Anonymously</a
            >
          </div>
        </div>
        <div class="askAnonymouslyImage col-md-4 col-sm-3">
          <img
            src="../../../assets/images/chat.svg"
            class="img-fluid"
            draggable="false"
            alt=""
          />
        </div>
      </div></div
  ></ng-template>
  <div *ngIf="doLoadingShimmer; else catLoader">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        'border-radius': '5px',
        height: '50px',
        width: '20%',
        'margin-top': '3px',
        'margin-right': '10px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        'border-radius': '5px',
        height: '50px',
        width: '100%',
        'margin-top': '3px',
        'margin-right': '10px'
      }"
    ></ngx-skeleton-loader>
  </div>

  <ng-template #catLoader>
    <div class="sectionPadding-top">
      <div class="radioButtonWrap">
        <h2>
          <i
            ><img src="../../../assets/images/qa.svg" class="img-fluid" alt=""
          /></i>
          <span>Categories</span>
        </h2>
        <div class="eachRadioButtons">
          <span
            *ngFor="let category of categories"
            (click)="setCategory(category._id)"
          >
            <input
              class="inputCss"
              [checked]="category._id == selectedCategory"
              type="radio"
              name="userdetails"
              id="{{ category?._id }}"
            />
            &nbsp;
            <label>{{ category.name }}&nbsp;&nbsp;&nbsp;</label> </span
          ><br />
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="doLoadingShimmer; else formLoader">
    <ngx-skeleton-loader
      appearance="line"
      count="5"
      [theme]="{
        'border-radius': '12px',
        height: '150px',
        width: '100%',
        'margin-top': '15px',
        'margin-right': '10px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <ng-template #formLoader>
    <div>
      <div
        class="disscussionBoxWrap d-flex align-items-center flex-wrap"
        *ngFor="let x of items"
      >
        <div class="disscussionImageWrap" *ngIf="x.hasOwnProperty('userId')">
          <img
            src="{{ x?.userId?.imageUrl }}"
            class="img-fluid"
            onerror="this.src='../../../../assets/images/user.png'"
            alt=""
          />
        </div>
        <div
          class="disscussionImageWrap"
          *ngIf="x.hasOwnProperty('therapistId')"
        >
          <img
            src="{{ x?.therapistId?.imageUrl }}"
            class="img-fluid"
            onerror="this.src='../../../../assets/images/user.png'"
            alt=""
          />
        </div>
        <div class="disscussionContentWrap">
          <h3>{{ x?.topic }}</h3>
          <p [innerHTML]="x?.description | safe: 'html'"></p>

          <div
            class="disscussionMessage d-flex flex-wrap justify-content-between"
          >
            <div class="info" *ngIf="x.hasOwnProperty('userId')">
              Posted By
              <span class="forumtext">{{ x?.userId?.name }}</span>
            </div>
            <div class="info" *ngIf="x.hasOwnProperty('therapistId')">
              Posted By
              <span class="forumtext">{{ x?.therapistId?.firstname }}</span>
            </div>

            <div>
              {{ x?.replies }} Repl<span *ngIf="x?.replies > 1">ies</span
              ><span *ngIf="x?.replies <= 1">y</span>
            </div>
            <div>{{ x?.createdAt | dateAgo }}</div>
          </div>
        </div>
        <div class="disscussionAction">
          <div
            class="d-flex align-items-center justify-content-center flex-column"
          >
            <a (click)="redirectToAnswer(x?._id)" class="thm-2">View</a>
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            *ngIf="x.userId?._id"
          >
            <div *ngIf="x.userId?._id == userId">
              <a class="thm-2" (click)="redirectTo(x?._id, 'edit')">Edit</a>
            </div>
          </div>
        </div>
      </div>
      <div class="noPosts" *ngIf="items.length == 0 && !isLoading">
        There are no posts available for this category!
      </div>

      <div *ngIf="isLoading" class="d-flex justify-content-center">
        <!-- <div
          class="spinner-border"
          *ngIf="isLoading"
          style="width: 2rem; height: 2rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div> -->
        <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
        <h5 *ngIf="isLoading" class="loadingText">
          Please wait while we're loading...
        </h5>
      </div>
    </div></ng-template
  >
  <div class="text-center" *ngIf="!isLoading">
    <button *ngIf="showNext" (click)="nextPage()" class="buttonTheme1">
      Load Older Notifications
    </button>
  </div>
</div>
