import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const adCampaignUrl = environment.apiUrl + '/guest/ad-campaign/'
@Injectable({
  providedIn: 'root'
})
export class AdCampaignService {

  constructor(private http : HttpClient) { }

  applyCoupon(couponObj, initalPrice) {
    let finalPrice = initalPrice;
    let discount = 0;
    if (couponObj.offerClass == "Flat Discount") {
      if (couponObj.minAmount <= finalPrice) {
        let dicsountedAmount = finalPrice * (couponObj.discount / 100);
        dicsountedAmount = Math.round(dicsountedAmount * 100) / 100;
        discount = dicsountedAmount;
        finalPrice = Math.round((finalPrice - dicsountedAmount) * 100) / 100;
      }
    }
    if (couponObj.offerClass == "Cash Discount") {
      if (couponObj.minAmount <= finalPrice) {
        let dicsountedAmount = couponObj.discount;
        discount = dicsountedAmount;
        finalPrice = finalPrice - dicsountedAmount;
      }
    }

    if (couponObj.offerClass == "Flat Discount Upto") {
      if (couponObj.minAmount <= finalPrice) {
        let dicsountedAmount = finalPrice * (couponObj.discount / 100);
        dicsountedAmount = Math.round(dicsountedAmount * 100) / 100;
        if (dicsountedAmount > couponObj.discountUpto) {
          dicsountedAmount = couponObj.discountUpto;
        }
        discount = dicsountedAmount;
        finalPrice = finalPrice - dicsountedAmount;
      }
    }

    return {
      discount: discount,
      finalPrice: finalPrice,
    };
  }

  getCoupon(code, email) {
    return this.http.get(adCampaignUrl +"coupon/" +code + '?email=' + email);
  }

  initPayment(paymentObj) {
    if(localStorage.getItem('GTMcid')){
      paymentObj['GTMcid'] = localStorage.getItem('GTMcid')
    }
    return this.http.post(adCampaignUrl + "start-payment", paymentObj);
  }

  confirmPayment(paymentObj) {
    return this.http.post(adCampaignUrl + "confirm-payment", paymentObj)
  }

  getPlans(){
    return this.http.get(adCampaignUrl + "plans")
  }

  getElevatePlan(){
    return this.http.get(adCampaignUrl + "get-elevate")
  }

  getCounsellors(skip, limit, language){
    return this.http.get(adCampaignUrl + "counsellorsPagin?skip=" + skip + "&limit=" + limit + "&language=" + language)
  }


}
