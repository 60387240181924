<div class="topMenuMargin">
  <div class="secPadding">
      <div class="container">
        <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
        <div class="secondaryHeading">
          <h2>Reset Password</h2>
          <hr />
        </div>
        <div *ngIf="isValid" class="frgtPassContainer justify-content-center">
          <div class="formRound">
            <form [formGroup]="forgotPassForm">
              <input
                type="text"
                placeholder="New password..."
                class="col-lg-12"
                formControlName="password"
                [ngClass]="{
                  'has-error':
                    !forgotPassForm.controls.password.valid &&
                    forgotPassForm.controls.password.touched,
                  'has-success':
                    forgotPassForm.controls.password.valid &&
                    forgotPassForm.controls.password.touched
                }"
              />
              <div
                *ngIf="
                  forgotPassForm.controls.password.errors?.required &&
                  forgotPassForm.controls.password.touched
                "
                class="errorContainer"
              >
                <p>Password is required</p>
              </div>
              <div
                *ngIf="
                  forgotPassForm.controls.password.errors?.pattern &&
                  forgotPassForm.controls.password.touched
                "
                class="errorContainer"
              >
                <p>
                  Password must be minimum 8 characters, at least 1 upper case
                  letter, 1 lower case letter, 1 number and 1 special character
                </p>
              </div>
              <input
              type="text"
              placeholder="Confirm Password..."
              class="col-lg-12"
              formControlName="confirmPassword"
              [ngClass]="{
                'has-error':
                  !forgotPassForm.controls.confirmPassword.valid &&
                  forgotPassForm.controls.confirmPassword.touched,
                'has-success':
                  forgotPassForm.controls.confirmPassword.valid &&
                  forgotPassForm.controls.confirmPassword.touched
              }"
            />
            <div
              *ngIf="
                forgotPassForm.controls.confirmPassword.errors?.required &&
                forgotPassForm.controls.confirmPassword.touched
              "
              class="errorContainer"
            >
              <p>Password is required</p>
            </div>
            <div
              *ngIf="
                forgotPassForm.controls.confirmPassword.errors?.pattern &&
                forgotPassForm.controls.confirmPassword.touched
              "
              class="errorContainer"
            >
              <p>
                Password must be minimum 8 characters, at least 1 upper case
                letter, 1 lower case letter, 1 number and 1 special character
              </p>
            </div>
              <div class="text-center submitBtn">
                <button class="buttonTheme1" (click)="onSubmit()">Submit</button>
              </div>
            </form>
          </div>
        </div>
        <div *ngIf="!isValid" class="text-center">
          <p>Hmmm, Looks like you have an invalid or expired. If you want to request new reset code, <a routerLink="../../forgot-password">click here</a></p>
        </div>
      </div>
    </div>
</div>
  