import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UserLoginComponent } from "./auth/user-login/user-login.component";
import { UserSignupComponent } from "./auth/user-signup/user-signup.component";
import { AboutUsComponent } from "./guest/home/about-us/about-us.component";
import { ContactUsComponent } from "./guest/home/contact-us/contact-us.component";
import { HomePageComponent } from "./guest/home/home-page/home-page.component";
import { HowItWorksComponent } from "./guest/home/how-it-works/how-it-works.component";
import { ChildCounsellingComponent } from "./guest/home/plans-and-pricing/child-counselling/child-counselling.component";
import { CouplesCounsellingComponent } from "./guest/home/plans-and-pricing/couples-counselling/couples-counselling.component";
import { GeneralCounsellingComponent } from "./guest/home/plans-and-pricing/general-counselling/general-counselling.component";
import { PsychiatricCounsellingComponent } from "./guest/home/plans-and-pricing/psychiatric-counselling/psychiatric-counselling.component";
import { DisclaimerComponent } from "./guest/legal-stuff/disclaimer/disclaimer.component";
import { PrivacyPolicyComponent } from "./guest/legal-stuff/privacy-policy/privacy-policy.component";
import { TermsComponent } from "./guest/legal-stuff/terms/terms.component";
import { ForCorporatesComponent } from "./guest/quick-links/for-corporates/for-corporates.component";
import { ForCounselorsComponent } from "./guest/quick-links/for-counselors/for-counselors.component";
import { TherapistLoginComponent } from "./therapist-auth/therapist-login/therapist-login.component";
import { TherapistDashboardComponent } from "./therapist/therapist-dashboard/therapist-dashboard.component";
import { AccountRelatedComponent } from "./user/account-related/account-related.component";
import { AnswerDiscussionComponent } from "./user/answer-discussion/answer-discussion.component";
import { DashboardComponent } from "./user/dashboard/dashboard.component";
import { DisscussionForumComponent } from "./user/disscussion-forum/disscussion-forum.component";
import { HelpdeskComponent } from "./user/helpdesk/helpdesk.component";
import { MoodJournalComponent } from "./user/mood-journal/mood-journal.component";
import { MyChatsComponent } from "./user/my-chats/my-chats.component";
import { ProfileComponent } from "./user/profile/profile.component";
import { ProgramRelatedComponent } from "./user/program-related/program-related.component";
import { RaiseNewRequestComponent } from "./user/raise-new-request/raise-new-request.component";
import { SessionsComponent } from "./user/sessions/sessions.component";
import { SubscriptionRelatedComponent } from "./user/subscription-related/subscription-related.component";
import { TalkTherapyRelatedComponent } from "./user/talk-therapy-related/talk-therapy-related.component";
import { TechnologyRelatedComponent } from "./user/technology-related/technology-related.component";
import { UserDashboardComponent } from "./user/user-dashboard/user-dashboard.component";
import { UserDiscussionFormComponent } from "./user/user-discussion-form/user-discussion-form.component";
import { ViewOldRequestsComponent } from "./user/view-old-requests/view-old-requests.component";
import { ViewSingleTicketComponent } from "./user/view-single-ticket/view-single-ticket.component";
import { ChangePasswordComponent } from "./user/change-password/change-password.component";

import { DashboardComponent as TherapistDashboard } from "./therapist/dashboard/dashboard.component";
import { ProfileComponent as TherapistProfileComponent } from "./therapist/profile/profile.component";
import { SessionsComponent as TherapistSessionsComponent } from "./therapist/sessions/sessions.component";
import { MyChatsComponent as TherapistMyChatsComponent } from "./therapist/my-chats/my-chats.component";
import { ClientNotesComponent } from "./therapist/client-notes/client-notes.component";
import { DiscussionForumComponent as TherapistDiscussionForum } from "./therapist/discussion-forum/discussion-forum.component";
import { HelpdeskComponent as TherapistHelpDeskcomponent } from "./therapist/helpdesk/helpdesk.component";
import { UserAuthGuard } from "./auth/user-auth.guard";
import { TherapistAuthGuard } from "./therapist-auth/therapist-auth.guard";
import { PlansAndPricingComponent } from "./guest/home/plans-and-pricing/plans-and-pricing/plans-and-pricing.component";
import { CalendarComponent } from "./calendar/calendar.component";

import { ContractRelatedComponent } from "./therapist/contract-related/contract-related.component";
import { PaymentRelatedComponent } from "./therapist/payment-related/payment-related.component";
import { PlatformRelatedComponent } from "./therapist/platform-related/platform-related.component";
import { BenefitsRelatedComponent } from "./therapist/benefits-related/benefits-related.component";
import { AccountRelatedComponent as TherapistAccountRelatedComponent } from "./therapist/account-related/account-related.component";
import { TalkTherapyRelatedComponent as TherapistTalkTherapyRelatedComponent } from "./therapist/talk-therapy-related/talk-therapy-related.component";
import { RaiseNewRequestComponent as TherapistRaiseNewRequestComponent } from "./therapist/raise-new-request/raise-new-request.component";
import { ChangePasswordComponent as TherapistChangePasswordComponent } from "./therapist/change-password/change-password.component";
import { ViewOldRequestsComponent as TherapistViewOldRequestsComponent } from "./therapist/view-old-requests/view-old-requests.component";
import { ViewSingleTicketComponent as TherapistViewSingleTicketComponent } from "./therapist/view-single-ticket/view-single-ticket.component";
import { FelicityBlogComponent } from "./guest/home/felicity-blog/felicity-blog.component";
import { TherapistStartDiscussionForumComponent } from "./therapist/therapist-start-discussion-forum/therapist-start-discussion-forum.component";
import { TherapistAnswerDiscussionForumComponent } from "./therapist/therapist-answer-discussion-forum/therapist-answer-discussion-forum.component";
import { ClientNotesViewComponent } from "./therapist/client-notes-view/client-notes-view.component";

import { CommunityTalkTherapistsComponent } from "./guest/home/community-talk-therapists/community-talk-therapists.component";
import { BecomeTherapistComponent } from "./guest/quick-links/become-therapist/become-therapist.component";
import { OrderSummaryComponent } from "./user/order-summary/order-summary.component";
import { SignUpDemoComponent } from "./guest/quick-links/sign-up-demo/sign-up-demo.component";
import { ScheduleTherapistsDetailComponent } from "./guest/home/schedule-therapists-detail/schedule-therapists-detail.component";
import { DiscussionForumComponent as GuestDiscussionForumComponent } from "./guest/home/discussion-forum/discussion-forum.component";
import { ViewDiscussionComponent } from "./guest/home/view-discussion/view-discussion.component";
import { JoinSessionComponent } from "./user/join-session/join-session.component";
import { JoinSessionComponent as TherapistJoinSessionComponent } from "./therapist/join-session/join-session.component";
import { ViewCalendarComponent } from "./user/view-calendar/view-calendar.component";
import { ViewCalendarComponent as TherapistViewCalendar } from "./therapist/view-calendar/view-calendar.component";
import { BlogViewComponent } from "./guest/home/blog-view/blog-view.component";
import { MoodCheckInComponent } from "./user/mood-check-in/mood-check-in.component";
import { AddThoughtsComponent } from "./user/add-thoughts/add-thoughts.component";
import { FreeReportComponent } from "./guest/home/free-report/free-report.component";
import { FinalResultComponent } from "./guest/home/final-result/final-result.component";
import { RecurringScheduleComponent } from "./therapist/recurring-schedule/recurring-schedule.component";
import { CreateNotesComponent } from "./therapist/create-notes/create-notes.component";
import { AdverseChildhoodExperiencesQuestionnaireComponent } from "./free-reports/adverse-childhood-experiences-questionnaire/adverse-childhood-experiences-questionnaire.component";
import { AngryTestComponent } from "./free-reports/angry-test/angry-test.component";
import { ObsessiveCompulsiveDisorderTestComponent } from "./free-reports/obsessive-compulsive-disorder-test/obsessive-compulsive-disorder-test.component";
import { PostTraumaticStressDisorderTestComponent } from "./free-reports/post-traumatic-stress-disorder-test/post-traumatic-stress-disorder-test.component";
import { DepressionTestComponent } from "./free-reports/depression-test/depression-test.component";
import { AnxietyTestComponent } from "./free-reports/anxiety-test/anxiety-test.component";
import { AnxietyTestGadComponent } from "./free-reports/anxiety-test-gad/anxiety-test-gad.component";
import { AnxietyTestHamiltonComponent } from "./free-reports/anxiety-test-hamilton/anxiety-test-hamilton.component";
import { DepressionTestResultsComponent } from "./guest/home/depression-test-results/depression-test-results.component";
import { AnxietyTestResultsComponent } from "./guest/home/anxiety-test-results/anxiety-test-results.component";
import { AnxietyHamiltionTestResultsComponent } from "./guest/home/anxiety-hamiltion-test-results/anxiety-hamiltion-test-results.component";
import { SessionBookedComponent } from "./user/session-booked/session-booked.component";
import { AdverseChildhoodExperiencesTestResultsComponent } from "./guest/home/adverse-childhood-experiences-test-results/adverse-childhood-experiences-test-results.component";
import { AngryTestResultsComponent } from "./guest/home/angry-test-results/angry-test-results.component";
import { ObsessiveCompulsiveDisorderTestResultsComponent } from "./guest/home/obsessive-compulsive-disorder-test-results/obsessive-compulsive-disorder-test-results.component";
import { PostTraumaticStressDisorderTestResultsComponent } from "./guest/home/post-traumatic-stress-disorder-test-results/post-traumatic-stress-disorder-test-results.component";
import { ForgotPasswordComponent as UserForgotPassword } from "./auth/forgot-password/forgot-password.component";
import { ForgotPasswordComponent as TherapistForgotPasswordComponent } from "./therapist-auth/forgot-password/forgot-password.component";
import { ResetPasswordComponent as UserResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { ResetPasswordComponent as TherapistResetPasswordComponent } from "./therapist-auth/reset-password/reset-password.component";
import { NotificationsComponent as UserNotifications } from "./user/notifications/notifications.component";
import { NotificationsComponent as TherapistNotifications } from "./therapist/notifications/notifications.component";
import { QuestionsComponent } from "./user/questions/questions.component";
import { OurRecommendationComponent } from "./user/our-recommendation/our-recommendation.component";
import { ReportsNewComponent } from "./therapist/reports-new/reports-new.component";
import { UserProfileComponent } from "./therapist/user-profile/user-profile.component";
import { ViewOldPlansComponent } from "./user/view-old-plans/view-old-plans.component";
import { VerifyemailComponent } from "./guest/verifyemail/verifyemail.component";
import { TherapistHolidaysComponent } from "./therapist/therapist-holidays/therapist-holidays.component";
import { SessionsReportComponent } from "./therapist/sessions-report/sessions-report.component";
import { RatingComponent } from "./user/rating/rating.component";
import { TroubleshootSessionComponent } from "./user/troubleshoot-session/troubleshoot-session.component";
import { BlogListComponent } from "./guest/home/blog-list/blog-list.component";
import { RedirectComponent } from "./guest/home/redirect/redirect.component";
import { AdCampaignComponent } from "./guest/ad-campaign/ad-campaign.component";
import { CompleteProfileComponent } from "./user/complete-profile/complete-profile.component";
import { AdCampaignSignupComponent } from "./guest/ad-campaign-signup/ad-campaign-signup.component";
import { ThankYouComponent } from "./guest/thank-you/thank-you.component";
import { AdCampaignHomeComponent } from "./guest/ad-campaign-home/ad-campaign-home.component";
import { AdCampaignCounsellorsComponent } from "./guest/ad-campaign-counsellors/ad-campaign-counsellors.component";
import { TestIntegrationsComponent } from "./guest/test-integrations/test-integrations.component";
import { AdhdTestComponent } from "./free-reports/adhd-test/adhd-test.component";
import { AdhdTestResultsComponent } from "./guest/home/adhd-test-results/adhd-test-results.component";
import { GriefTestComponent } from "./free-reports/grief-test/grief-test.component";
import { GriefTestResultsComponent } from "./guest/home/grief-test-results/grief-test-results.component";
import { NewLandingComponent } from "./guest/new-landing/new-landing.component";
import { RelationshipTestComponent } from "./free-reports/relationship-test/relationship-test.component";
import { RelationshipTestResultsComponent } from "./guest/home/relationship-test-results/relationship-test-results.component";
import { SessionJoinComponent } from "./therapist/session-join/session-join.component";
import { DesignComponent } from "./guest/home/design/design.component";
import { NewLandingPageComponent } from "./guest/new-landing-page/new-landing-page.component";
import { PageNotFound } from './page-not-found/page-not-found.component'
import { HelpdeskRedirectionComponent } from "./helpdesk-redirection/helpdesk-redirection.component";
import { CorporateLandingComponent } from "./corporate-landing/corporate-landing.component";
import { GetInTouchComponent } from "./get-in-touch/get-in-touch.component";
import { EmailUnverifiedComponent } from "./guest/email-unverified/email-unverified.component";
import { WellnessHubComponent } from "./guest/home/wellness-hub/wellness-hub.component";
import { PaymentRedirectionComponent } from "./guest/payment-redirection/payment-redirection.component";
import { AuthHandlerComponent } from "./auth-handler/auth-handler.component";

const routes: Routes = [
  { path: "", component: HomePageComponent, data: { header: true } },
  {
    path: "verify-email/:code",
    component: VerifyemailComponent,
    data: { header: true },
  },
  {
    path : "unverified-email",
    component: EmailUnverifiedComponent,
    data : { header : true }
  },
  {
    path: "test-integrations-demo",
    component: TestIntegrationsComponent
  },

  {
    path: "online-counseling-psychologists/how-it-works",
    component: HowItWorksComponent,
    data: { header: true },
  },
  {
    path: "ad-campaign",
    component: AdCampaignComponent,
    data: { header: false }
  },
  {
    path: "new-landing",
    component: NewLandingComponent,
    data: { header: false },
  },
  {
    path: "new-signup",
    component: AdCampaignSignupComponent,
    data: { header: false }
  },
  {
    path: "campaign/home",
    component: AdCampaignHomeComponent,
    data: { header: false }
  },
  {
    path: "campaign/our-counsellor",
    component: AdCampaignCounsellorsComponent,
    data: { header: false }
  },
  {
    path: "thank-you",
    component: ThankYouComponent,
    data: { header: false }
  },
  { path: "login", component: UserLoginComponent, data: { header: true } },
  { path: "sign-up", component: UserSignupComponent, data: { header: true } },
  {
    path: "forgot-password",
    component: UserForgotPassword,
    data: { header: true },
  },
  {
    path: "forgot-password-therapist",
    component: TherapistForgotPasswordComponent,
    data: { header: true },
  },
  {
    path: "reset-password/:id",
    component: UserResetPasswordComponent,
    data: { header: true },
  },
  {
    path: "reset-password-therapist/:id",
    component: TherapistResetPasswordComponent,
    data: { header: true },
  },
  { path: "mental-health-community/about-the-company", component: AboutUsComponent, data: { header: true } },
  { path: "online-counseling-psychologists/contact-us", component: ContactUsComponent, data: { header: true } },
  {path : "payment-redirection", component : PaymentRedirectionComponent, data: { header: false }},
  {
    path: "online-counseling-psychologists/terms-of-service",
    component: TermsComponent,
    data: { header: true },
  },
  {
    path: "online-counseling-psychologists/privacy-policy",
    component: PrivacyPolicyComponent,
    data: { header: false },
  },
  {
    path: "online-counseling-psychologists/disclaimer",
    component: DisclaimerComponent,
    data: { header: true },
  },
  {
    path: "general-counselling-plans",
    component: GeneralCounsellingComponent,
    data: { header: true },
  },
  {
    path: "couple-counselling-plans",
    component: CouplesCounsellingComponent,
    data: { header: true },
  },
  {
    path: "child-counselling-plans",
    component: ChildCounsellingComponent,
    data: { header: true },
  },
  {
    path: "psychiatric-counsultation-plans",
    component: PsychiatricCounsellingComponent,
    data: { header: true },
  },
  {
    path: "online-counseling-psychologists/plans-pricing",
    component: GeneralCounsellingComponent,
    data: { header: true },
  },
  {
    path: "corporate-mental-wellness-programs/get-demo",
    component: ForCorporatesComponent,
    data: { header: true },
  },
  {
    path: "counseling-therapy-clinical-psychology-jobs/get-onboard",
    component: ForCounselorsComponent,
    data: { header: true },
  },
  {
    path: "counseling-therapy-clinical-psychology-jobs/get-onboard/questions",
    component: BecomeTherapistComponent,
    data: { header: true },
  },
  {
    path: "blog",
    component: FelicityBlogComponent,
    data: { header: true },
  },
  {
    path: "blog-view/:id",
    component: BlogViewComponent,
    data: { header: true },
  },
  {
    path: "all-blogs",
    component: BlogListComponent,
    data: { header: true },
  },
  {
    path: "free-report",
    data: { header: true },
    loadChildren: () => import("./free-reports/free-report-routing.module").then((m) => m.FreeReportsRoutingModule)
  },
  {
    path: "anxiety-test",
    redirectTo: "/free-report/anxiety-test",
    data: { header: true },
  },
  {
    path: "anxiety-test-gad",
    redirectTo: "/free-report/anxiety-test-gad",
    data: { header: true },
  },
  {
    path: "anxiety-test-hamilton",
    redirectTo: "/free-report/anxiety-test-hamilton",
    data: { header: true },
  },
  {
    path: "depression-test",
    redirectTo: "/free-report/depression-test",
    data: { header: true },
  },
  {
    path: "adverse-childhood-experiences-questionnaire",
    redirectTo: "/free-report/adverse-childhood-experiences-questionnaire",
    data: { header: true },
  },
  {
    path: "angry-test",
    redirectTo: "/free-report/angry-test",
    data: { header: true },
  },
  {
    path: "adhd-test",
    redirectTo: "/free-report/adhd-test",
    data: { header: true },
  },
  {
    path: "grief-test",
    redirectTo: "/free-report/grief-test",
    data: { header: true }
  },
  {
    path: "obsessive-compulsive-disorder-test",
    redirectTo: "/free-report/obsessive-compulsive-disorder-test",
    data: { header: true },
  },
  {
    path: "post-traumatic-stress-disorder-test",
    redirectTo: "/free-report/post-traumatic-stress-disorder-test",
    data: { header: true },
  },
  {
    path: "relationship-assessment-test",
    redirectTo: "/free-report/relationship-assessment-test",
    data: { header: true },
  },
  // {
  //   path: "final-result/:id",
  //   component: FinalResultComponent,
  //   data: { header: true },
  // },
  // {
  //   path: "depression-test-result/:id",
  //   component: DepressionTestResultsComponent,
  //   data: { header: true },
  // },
  // {
  //   path: "anxiety-test-result/:id",
  //   component: AnxietyTestResultsComponent,
  //   data: { header: true },
  // },
  // {
  //   path: "anxiety-hamilton-test-result/:id",
  //   component: AnxietyHamiltionTestResultsComponent,
  //   data: { header: true },
  // },
  // {
  //   path: "anxiety-test-result/:id",
  //   component: AnxietyTestResultsComponent,
  //   data: { header: true },
  // },
  // {
  //   path: "adverse-childhood-experiences-test-results/:id",
  //   component: AdverseChildhoodExperiencesTestResultsComponent,
  //   data: { header: true },
  // },
  // {
  //   path: "angry-test-results/:id",
  //   component: AngryTestResultsComponent,
  //   data: { header: true },
  // },
  // {
  //   path: "obsessive-compulsive-disorder-test-results/:id",
  //   component: ObsessiveCompulsiveDisorderTestResultsComponent,
  //   data: { header: true },
  // },
  // {
  //   path: "post-traumatic-stress-disorder-test-results/:id",
  //   component: PostTraumaticStressDisorderTestResultsComponent,
  //   data: { header: true },
  // },
  // {
  //   path : 'adhd-test-results/:id',
  //   component : AdhdTestResultsComponent,
  //   data : { header: true },
  // },
  // {
  //   path: "grief-test-results/:id",
  //   component : GriefTestResultsComponent,
  //   data : { header: true },
  // },
  // {
  //   path: "relationship-test-result/:id",
  //   component :  RelationshipTestResultsComponent,
  //   data : { header: true },
  // },
  {
    path: "our-counselors",
    component: CommunityTalkTherapistsComponent,
    data: { header: true },
  },
  {
    path: "sign-up-demo",
    component: SignUpDemoComponent,
    data: { header: true },
  },
  {
    path: "community-talk-therapists-detail/:id",
    component: ScheduleTherapistsDetailComponent,
    data: { header: true },
  },
  {
    path: "order-summary/:id",
    component: OrderSummaryComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: "complete-profile",
    component: CompleteProfileComponent,
    canActivate: [UserAuthGuard],
    data: { header: true }
  },
  {
    path: "wellness-forum",
    component: GuestDiscussionForumComponent,
    data: { header: true, title: "Wellness Forum | Felicity" },
  },
  {
    path: "view-discussion/:id",
    component: ViewDiscussionComponent,
    data: { header: true },
  },
  {
    path: "session-booked/:id",
    component: SessionBookedComponent,
  },
  { path: "our-recommendation", canActivate: [UserAuthGuard], component: OurRecommendationComponent },
  { path: "questions", canActivate: [UserAuthGuard], component: QuestionsComponent, data: { header: true } },
  {
    path: "user-dashboard",
    canActivate: [UserAuthGuard],
    data: { title: "My Dashboard | Felicity" },
    loadChildren: () => import("./user/user-routing.module").then((m) => m.UserRoutingModule)
  },
  {
    path: "corporates",
    component: CorporateLandingComponent,
    data: { header: true },
    children: [],
  },
  {
    path: "corporates/get-in-touch",
    component: GetInTouchComponent,
    data: { header: true },
  },
  {
    path: "wellness-hub",
    component: WellnessHubComponent,
    data: {header : true}
  },
  // {
  //   path: "user-dashboard",
  //   component: UserDashboardComponent,
  //   canActivate: [UserAuthGuard],
  //   children: [
  //     { path: "", redirectTo: "dashboard", pathMatch: "full" },
  //     { path: "dashboard", component: DashboardComponent },
  //     { path: "profile", component: ProfileComponent },
  //     { path: "sessions", component: SessionsComponent },
  //     { path: "my-chats", component: MyChatsComponent },
  //     { path: "moodboard", component: MoodJournalComponent },
  //     { path: "discussion", component: DisscussionForumComponent },
  //     { path: "notifications", component: UserNotifications },
  //     { path: "helpdesk", component: HelpdeskComponent },
  //     { path: "requestform", component: RaiseNewRequestComponent },
  //     { path: "viewRequest", component: ViewOldRequestsComponent },
  //     { path: "subscription-faq", component: SubscriptionRelatedComponent },
  //     { path: "talk-therapy-faq", component: TalkTherapyRelatedComponent },
  //     { path: "program-related-faq", component: ProgramRelatedComponent },
  //     { path: "account-related-faq", component: AccountRelatedComponent },
  //     { path: "technology-related-faq", component: TechnologyRelatedComponent },
  //     { path: "userdiscussionform", component: UserDiscussionFormComponent },
  //     { path: "answerDiscussion", component: AnswerDiscussionComponent },
  //     { path: "viewsingleticket", component: ViewSingleTicketComponent },
  //     { path: "changepassword", component: ChangePasswordComponent },
  //     { path: "join-session", component: JoinSessionComponent },
  //     { path: "moodcheckin", component: MoodCheckInComponent },
  //     { path: "add-thoughts", component: AddThoughtsComponent },
  //     { path: "view-old-plans", component: ViewOldPlansComponent },
  //     { path: "give-rating/:id", component: RatingComponent },
  //     { path : "troubleshoot", component: TroubleshootSessionComponent },
  //   ],
  // },
  {
    path: "view-counsellor-calendar/:id",
    component: ViewCalendarComponent,
    canActivate: [UserAuthGuard],
    data: { header: true },
  },
  {
    path: "therapist-login",
    component: TherapistLoginComponent,
    data: { header: true },
  },
  {
    path: "therapist-calendar/:id",
    component: CalendarComponent,
  },
  {
    path: "user-profile/:id",
    component: UserProfileComponent,
    canActivate: [TherapistAuthGuard],
    data: { header: true },
  },
  {
    path: "therapist-dashboard",
    // component: TherapistDashboardComponent,
    canActivate: [TherapistAuthGuard],
    loadChildren: () => import("./therapist/therapist-routing.module").then((m) => m.TherapistRoutingModule)
    // children: [
    //   { path: "", redirectTo: "dashboard", pathMatch: "full" },
    //   { path: "dashboard", component: TherapistDashboard },
    //   { path: "profile", component: TherapistProfileComponent },
    //   { path: "notifications", component: TherapistNotifications },
    //   { path: "sessions", component: TherapistSessionsComponent },
    //   { path: "my-chats", component: TherapistMyChatsComponent },
    //   { path: "client-notes", component: ClientNotesComponent },
    //   { path: "discussion", component: TherapistDiscussionForum },
    //   { path: "helpdesk", component: TherapistHelpDeskcomponent },
    //   { path: "contract-related-faq", component: ContractRelatedComponent },
    //   {
    //     path: "talk-therapy-faq",
    //     component: TherapistTalkTherapyRelatedComponent,
    //   },
    //   { path: "payment-related-faq", component: PaymentRelatedComponent },
    //   { path: "platform-related-faq", component: PlatformRelatedComponent },
    //   {
    //     path: "account-related-faq",
    //     component: TherapistAccountRelatedComponent,
    //   },
    //   { path: "benefits-related-faq", component: BenefitsRelatedComponent },
    //   { path: "requestform", component: TherapistRaiseNewRequestComponent },
    //   {
    //     path: "changepassword",
    //     component: TherapistChangePasswordComponent,
    //   },
    //   { path: "viewRequest", component: TherapistViewOldRequestsComponent },
    //   {
    //     path: "viewsingleticket",
    //     component: TherapistViewSingleTicketComponent,
    //   },
    //   {
    //     path: "therapistDiscussionForum",
    //     component: TherapistStartDiscussionForumComponent,
    //   },
    //   {
    //     path: "answerDiscussion",
    //     component: TherapistAnswerDiscussionForumComponent,
    //   },
    //   { path: "client-notes-view/:id", component: ClientNotesViewComponent },
    //   { path: "client-notes-edit/:id", component: CreateNotesComponent },
    //   { path: "create-client-note/:userId", component: CreateNotesComponent },
    //   { path: "join-session", component: TherapistJoinSessionComponent },
    //   { path: "view-calendar", component: TherapistViewCalendar },
    //   { path: "recurring-schedule", component: RecurringScheduleComponent },
    //   { path: "report", component: ReportsNewComponent },
    //   { path: "therapist-calender", component: TherapistHolidaysComponent },
    //   {path: "sessions-report", component: SessionsReportComponent },
    // ],
  },
  { path: "anxiety-control", component: DesignComponent, data: { header: true } },
  { path: "mind-therapy", component: NewLandingPageComponent, data: { header: true } },
  { path: "404", component: PageNotFound, data: { header: true } },
  { path: "raise-a-request", component: HelpdeskRedirectionComponent, data: { header: true } },
  { path: "auth-handler", component: AuthHandlerComponent },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFound,
    data: { header: true }
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: "top", initialNavigation: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
