import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactService } from "../contact.service";

@Component({
  selector: "app-verifyemail",
  templateUrl: "./verifyemail.component.html",
  styleUrls: ["./verifyemail.component.css"],
})
export class VerifyemailComponent implements OnInit {
  code : string= '';
  status = 'loading'
  msg =
    "Either Your Code Is Expired Or Code Is Invalid Now Redirecting Again To Sign Up";
  countDown = 5;
  intervalObj : NodeJS.Timeout;
  constructor(private verificationService : ContactService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get("code")
    this.verificationService.verifyEmail(this.code).subscribe((res) => {
      this.status = 'verified';
      this.intervalObj = setInterval(() => {
        this.updateCountDown();
      },1000)
    }, (err) => {
      this.status = 'error';
    })
  }

  updateCountDown() {
    this.countDown--;
    if(this.countDown === 1) {
      clearInterval(this.intervalObj)
        this.router.navigate(["/login"])
    }
  }

}
