import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';

const backendUrl = environment.apiUrl + '/quote'
@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private http : HttpClient) { }

  getQuote(key){
   return this.http.get(backendUrl+'/'+key)
  }
}
