import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FreeReportService } from "src/app/guest/free-report.service";
import { COMMON_DATA } from "src/app/shared/common";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { environment } from "src/environments/environment";
import { TherapistProfileService } from "../therapist-profile.service";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent implements OnInit {
  // let newString = languageArray.join();
  @Input() isClientNotes : boolean;
  languageString = "";
  userId = "";
  userEmail = "";
  testName = "";
  user = null;
  testResults = null;
  questions = null;
  options = null;
  answers = null;
  answerText = null;
  imageUrl = environment.apiUrl;

  constructor(
    private actRoute: ActivatedRoute,
    private sidenavService: SideNavBarService,
    private therapistProfileService: TherapistProfileService,
    private freeReportService: FreeReportService
  ) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit() {
    this.actRoute.paramMap.subscribe((params) => {
      this.userId = params.get("id");
      this.therapistProfileService
        .getUserProfile(this.userId)
        .subscribe((data) => {
          this.user = data;
          this.userEmail = data["email"];
          this.freeReportService
            .getReportByEmail(this.userEmail)
            .subscribe((report) => {
              this.testResults = report["report"];
            });
          let languageArray = [];
          if (this.user.selectedLanguageArray) {
            this.user.selectedLanguageArray.forEach((element) => {
              languageArray.push(element.language);
            });
            this.user.selectedLanguageArray = languageArray;
          }
        });
    });
  }
  dialogForAnswers(testName, inx) {
    this.testName = testName;
    switch (testName) {
      case "anxiety":
        this.questions = COMMON_DATA.anixety_questions;
        this.options = COMMON_DATA.anixety_options;
        this.answers = Object.values(this.testResults[inx]["reportDetails"]["answers"]);
        console.log(this.testResults[inx]["reportDetails"]["answers"])
        this.answerText = this.answers.map(
          (idx, index) => this.options[index][Number(idx)]
        );
        break;
      case "depression":
        this.questions = COMMON_DATA.depression_questions;
        this.options = COMMON_DATA.depression_options;
        this.answers = Object.values(this.testResults[inx]["reportDetails"]["answers"]);
        this.answerText = this.answers.map(
          (idx, index) => this.options[index][Number(idx)]
        );
        break;
      case "ace":
        this.questions = COMMON_DATA.ace_questions;
        this.options = COMMON_DATA.ace_options;
        this.answers = Object.values(this.testResults[inx]["reportDetails"]["answers"]);
        this.answerText = this.answers.map(
          (idx, index) => this.options[index][Number(idx)]
        );
        break;
      case "anger":
        this.questions = COMMON_DATA.angry_questions;
        this.options = COMMON_DATA.angry_options;
        this.answers = Object.values(this.testResults[inx]["reportDetails"]["answers"]);
        this.answerText = this.answers.map(
          (idx, index) => this.options[index][Number(idx)]
        );
        break;
      case "ptsd":
        this.questions = COMMON_DATA.ptsd_questions;
        this.options = COMMON_DATA.ptsd_options;
        this.answers = Object.values(this.testResults[inx]["reportDetails"]["answers"]);
        this.answerText = this.answers.map(
          (idx, index) => this.options[index][Number(idx)]
        );
        break;
      case "ocd":
        this.questions = COMMON_DATA.ocdt_questions.concat(
          COMMON_DATA.ocdt_questions1
        );
        this.options = COMMON_DATA.ocdt_options.concat(
          COMMON_DATA.ocdt_options1
        );
        this.answers = Object.values(this.testResults[inx]["reportDetails"]["answers"]);
        this.answerText = this.answers.map(
          (idx, index) => this.options[index][Number(idx)]
        );
        break;
    }
  }
  indexTracker(index: number, value: any) {
    return index;
  }
}
