<div style="display: flex; align-items: center; margin-bottom: 20px">
  <span
    ><a
      routerLink="../helpdesk"
      style="
        font-size: 16px;
        color: #07006e;
        cursor: pointer;
        text-decoration: underline;
      "
      >Helpdesk</a
    ></span
  >
  <span style="font-size: 16px">&nbsp;>&nbsp;</span>
  <span style="font-size: 16px; color: #808080">Need Help</span>
</div>
<div class="bodyPageWrap">
  <div class="formRound">
    <form [formGroup]="raiseNewRequest">
      <div class="profileSection">
        <div class="sectionHeading">
          <h3>Need Help</h3>
          <h5 class="h5ClassCss">We are here for you</h5>
        </div>
        <div class="eachOption">
          <h3>Select Your Issue</h3>
          <div>
            <ng-select
              formControlName="grievance"
              class="form-control"
              [items]="grievance"
              [ngClass]="{
                'has-error':
                  !raiseNewRequest.controls.grievance.valid &&
                  raiseNewRequest.controls.grievance.touched,
                'has-success':
                  raiseNewRequest.controls.grievance.valid &&
                  raiseNewRequest.controls.grievance.touched
              }"
            ></ng-select>
          </div>
          <div
            *ngIf="
              raiseNewRequest.controls.grievance.errors?.required &&
              raiseNewRequest.controls.grievance.touched
            "
            class="errorContainer"
          >
            <p>Grievance is required</p>
          </div>
        </div>
        <div class="eachOption">
          <h3>Please explain your query in detail</h3>
          <div>
            <textarea
              formControlName="description"
              class="textareaCss"
              [ngClass]="{
                'has-error':
                  !raiseNewRequest.controls.description.valid &&
                  raiseNewRequest.controls.description.touched,
                'has-success':
                  raiseNewRequest.controls.description.valid &&
                  raiseNewRequest.controls.description.touched
              }"
              id="description"
              name="description"
              rows="6"
              class="form-control"
            ></textarea>
          </div>
          <div
            *ngIf="
              raiseNewRequest.controls.description.errors?.required &&
              raiseNewRequest.controls.description.touched
            "
            class="errorContainer"
          >
            <p>Description is required</p>
          </div>
        </div>
      </div>
      <div class="submitbtnn text-center">
        <div *ngIf="isLoading" class="d-flex justify-content-center">
          <div
            class="spinner-border"
            style="width: 2rem; height: 2rem; margin-top: 23px"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
        </div>
        <button (click)="onSubmit()" class="thm-2" *ngIf="!isLoading">
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
