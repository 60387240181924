import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { NotificationService } from "src/app/user/notification.service";

@Component({
  selector: "app-dashboard-header",
  templateUrl: "./dashboard-header.component.html",
  styleUrls: ["./dashboard-header.component.css"],
})
export class DashboardHeaderComponent implements OnInit {
  newNotifications = 0;
  constructor(
    private authService: AuthService,
    private sideNavService: SideNavBarService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.notificationService.getUnreadNotifications().subscribe((data) => {
      this.newNotifications = data["count"];
    });
  }
  logOut() {
    this.authService.logout();
  }
  openSideNav() {
    this.sideNavService.opensidenav();
  }
}
