import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const backendUrl = environment.apiUrl + "/therapist/raise-request/";

@Injectable({
  providedIn: 'root'
})
export class TherapistRaiseRequestService {

  constructor(private http: HttpClient) { }

  raiseRequest(data){
    return this.http.post(backendUrl + 'raise-request', data)
  }

  getDownloadUrl(key){
    return this.http.get(backendUrl + 'download/' + key)
  }
}
