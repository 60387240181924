<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container-fluid">
    <div class="secondaryHeading">
      <h2>Our Blogs</h2>
      <hr />
      <p>
        Join our community and get access to exclusive content on mental
        wellness. <br />
        Ask questions, comment and share anonymously.
      </p>
    </div>

    <svg width="298" height="388" viewBox="0 0 298 388" fill="none" xmlns="http://www.w3.org/2000/svg" class="bgBubble"
      id="bubble1">
      <circle cx="104" cy="194" r="194" fill="url(#paint0_linear_199_3647)" />
      <defs>
        <linearGradient id="paint0_lzinear_199_3647" x1="199" y1="-1.23864e-05" x2="-67.5" y2="453"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#AD00A6" stop-opacity="0.4" />
          <stop offset="1" stop-color="#5B67CA" />
        </linearGradient>
      </defs>
    </svg>

    <svg width="298" height="388" viewBox="0 0 298 388" fill="none" xmlns="http://www.w3.org/2000/svg" class="bgBubble"
      id="bubble2">
      <circle cx="104" cy="194" r="194" fill="url(#paint0_linear_199_3647)" />
      <defs>
        <linearGradient id="paint0_linear_199_3647" x1="199" y1="-1.23864e-05" x2="-67.5" y2="453"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#AD00A6" stop-opacity="0.4" />
          <stop offset="1" stop-color="#5B67CA" />
        </linearGradient>
      </defs>
    </svg>

    <div class="mainHolder">
      <div class="blogCol">
        <div class="filterBox" *ngIf="category">
          <div *ngIf="isShimmerLoader"></div>
          <p class="mb-20">
            <strong>Filter :</strong><span class="filterCatBox" (click)="clearCategory()">{{ category }} <i
                class="fa fa-times"></i></span>
          </p>
        </div>
        <div *ngIf="isShimmerLoader"></div>
        <div class="filterBox" *ngIf="searchQueryTxt.value.length > 0">
          <p class="mb-20">
            <strong>Search :</strong><span class="filterCatBox" (click)="clearSearch()">{{ searchQueryTxt.value }} <i
                class="fa fa-times"></i></span>
          </p>
        </div>
        <div *ngIf="getBlogs; else getBlogs">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div *ngIf="isShimmerLoader">
                <div *ngFor="let item of fakeArray(2)">
                  <ngx-skeleton-loader appearance="line" [theme]="{
                      'border-radius': '5px',
                      height: '250px',
                      width: '300px',
                      border: '1px solid white',
                      'margin-left': '30px',
                      'margin-top': '15px',
                      'margin-bottom': '10px'
                    }">
                  </ngx-skeleton-loader><br />
                  <ngx-skeleton-loader appearance="line" [theme]="{
                      'border-radius': '5px',
                      height: '20px',
                      width: '300px',
                      border: '1px solid white',
                      'margin-left': '30px',
                      'margin-bottom': '1px'
                    }">
                  </ngx-skeleton-loader><br />
                  <ngx-skeleton-loader appearance="line" [theme]="{
                      'border-radius': '5px',
                      height: '20px',
                      width: '250px',
                      border: '1px solid white',
                      'margin-left': '50px',
                      'margin-bottom': '1px'
                    }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div *ngIf="isShimmerLoader">
                <div *ngFor="let item of fakeArray(2)">
                  <ngx-skeleton-loader appearance="line" [theme]="{
                      'border-radius': '5px',
                      height: '250px',
                      width: '300px',
                      border: '1px solid white',
                      'margin-left': '30px',
                      'margin-top': '15px',
                      'margin-bottom': '10px'
                    }">
                  </ngx-skeleton-loader><br />
                  <ngx-skeleton-loader appearance="line" [theme]="{
                      'border-radius': '5px',
                      height: '20px',
                      width: '300px',
                      border: '1px solid white',
                      'margin-left': '30px',
                      'margin-bottom': '1px'
                    }">
                  </ngx-skeleton-loader><br />
                  <ngx-skeleton-loader appearance="line" [theme]="{
                      'border-radius': '5px',
                      height: '20px',
                      width: '250px',
                      border: '1px solid white',
                      'margin-left': '30px',
                      'margin-bottom': '1px'
                    }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="blogHolder">
              <div class="blogItems" *ngFor="let blog of items" routerLink="../blog-view/{{ blog?.slug }}">
                <div class="photo shine" *ngIf="isShimmerLoader"></div>
                <div class="blogText">
                  <div class="blogDetails mt-2 mb-2">
                    <img style="height: 20px;"
                      src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678130-profile-alt-4-512.png" alt="">
                    <span class="mx-2">{{ blog?.owner }}</span> <span>&nbsp;&nbsp;&#x25cf; &nbsp;</span>
                    <span class="mx-2 blogCategory px32 py-1">{{blog?.category?.name}}</span>
                  </div>
                  <strong>
                    <h2>{{ blog?.title }}</h2>
                  </strong>
                  <span class="text-left">
                    {{ blog?.createdAt | date: "EEEE" }} <span>&nbsp;&nbsp;&#9679; &nbsp;&nbsp;</span>{{
                    blog?.createdAt | date:
                    "dd MMMM" }}
                  </span>
                </div>
                <img src="{{ blog.imageURL }}" class="img-fluid" alt="" />
              </div>

              <div *ngIf="showNext && !isLoading" class="loadMoreCss">
                <span (click)="nextPage()" *ngIf="!isLoading">Load More
                  <span class="ring2"><i class="fa fa-angle-down"></i>
                    <!-- <span id=spin></span> -->
                  </span>
                </span>
              </div>

              <div
            class="col-12 d-flex justify-content-center loadMoreCss"
            *ngIf="isLoading"
          >
            <span class="ring"><i class="fa fa-angle-down"></i>
              <span id=spin></span>
            </span>
          </div>
    
              <div class="flex-container" id="mobile-newsletter">
                <div class="boxRound subsBox d-flex flex-column align-items-md-center px-4 py-4">
                  <div class="secondaryHeading">
                    <h4 class="mt-2 mb-2">
                      Get our weekly Newsletter
                    </h4>
                    <span>Evidence-based guidance, up to-date resources, and first hand accounts to help you in your
                      mental
                      health journey.</span>
                  </div>
                  <div class="subsEmail formRound1">
                    <form [formGroup]="subsForm" (ngSubmit)="onSubmit()">
                      <div class="d-flex flex-column">
                        <input type="text" placeholder="Enter your email id" formControlName="email"
                          class="form-control mb-3 mt-3" />
                        <button id="signup" type="submit" class="buttonTheme1 subsButton">
                          Subscribe
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span *ngIf="isLoading" class="loading"></span> -->
            <!-- <p>Loading...</p> -->
          </div>
        </div>
        <ng-template #getBlogs>
          <label style="
              display: flex;
              justify-content: center;
              margin-top: 160px;
              font-size: 32px;
            ">No Blogs here</label>
        </ng-template>
      </div>
      <div class="catCol">
        <div *ngIf="isSearch">
          <ngx-skeleton-loader appearance="line" [theme]="{
              'border-radius': '5px',
              height: '50px',
              width: '270px',
              border: '1px solid white',
              'margin-left': '30px',
              'margin-top': '10px',
              'margin-bottom': '1px'
            }">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="10" appearance="line" [theme]="{
              'border-radius': '5px',
              height: '50px',
              width: '120px',
              border: '1px solid white',
              'margin-left': '30px',
              'margin-top': '10px',
              'margin-bottom': '1px'
            }">
          </ngx-skeleton-loader>
        </div>
        <div class="categories-holder mb-2" style="margin-bottom: 100px">
          <div class="gray-box" *ngIf="isSearch == false">
            <div style="position: relative">
              <form>
                <input class="search-input-text" type="text" placeholder="Search your keywords or queries..."
                  (keyup)="searchQuery()" [formControl]="searchQueryTxt" />
                <span class="search-icon"><i class="fa fa-search"></i></span>
              </form>
            </div>
            <h5 class="mt-3" id="categoriesMobile">Categories For You</h5>
            <div class="d-flex flex-wrap scrolling-wrapper-flexbox">
              <div *ngFor="let category of categories" class="search-tabs"
                style="margin: 16px 16px 0px 0px; cursor: pointer" (click)="setCategory(category?.name)">
                {{ category?.name }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex-container" id="desktop-newsletter">
            <div class="boxRound subsBox d-flex flex-column align-items-md-center px-4 py-4">
              <div class="secondaryHeading">
                <h4 class="mt-2 mb-2">
                  Get our weekly Newsletter
                </h4>
                <span>Evidence-based guidance, up to-date resources, and first hand accounts to help you in your mental
                  health journey.</span>
              </div>
              <div class="subsEmail formRound1">
                <form [formGroup]="subsForm" (ngSubmit)="onSubmit()">
                  <div class="d-flex flex-column">
                    <input type="text" placeholder="Enter your email id" formControlName="email"
                      class="form-control mb-3 mt-3" />
                    <button id="signup" type="submit" class="buttonTheme1 subsButton">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>