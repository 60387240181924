<div (click)="closenavbar()">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="sectionHeading">
          <h2>Therapy Intake Form</h2>
        </div>
      </div>
    </div>
    <div class="formRound">
      <form [formGroup]="intakeForm" name="intakeForm">
        <div class="sectionPadding-top">
          <div class="insideFormDivTags">
            <h3>How old are you?</h3>
            <div>
              <div class="table">
                <ng-select
                  class="form-control table"
                  [items]="ageOptions"
                  formControlName="age"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="insideFormDivTags">
            <h3>How do you identify yourself in terms of gender?</h3>
            <div>
              <div class="table">
                <ng-select
                  class="form-control table"
                  [items]="genderOptions"
                  formControlName="gender"
                  *ngIf="intakeForm.value.gender !== 'Let me type...'"
                ></ng-select>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="genderSelected"
                  [ngModelOptions]="{ standalone: true }"
                  *ngIf="intakeForm.value.gender === 'Let me type...'"
                  placeholder="Type here..."
                />
                <button
                  class="buttonTheme2 selectFromOptions"
                  *ngIf="intakeForm.value.gender === 'Let me type...'"
                  (click)="selectFromOptions()"
                >
                  Select from options
                </button>
              </div>
            </div>
          </div>
          <div class="insideFormDivTags">
            <h3>What are you seeking help for?</h3>
            <div *ngIf="!showSpecificArea">
              <div class="table">
                <ng-select
                  class="form-control table"
                  [items]="specializeareas1"
                  formControlName="seekingHelpFor"
                  [(ngModel)]="selectedOptionspecializeareas"
                  [multiple]="true"
                  [selectableGroup]="true"
                  [closeOnSelect]="false"
                  [selectableGroup]="true"
                  (change)="onSpecializeAreaChanged($event)"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="insideFormDivTags" *ngIf="showSpecificArea">
            <input
              class="form-control col-xl-9"
              type="text"
              formControlName="specifyNeed"
              placeholder="Type your issue here..."
            />
            <button
              class="buttonTheme2 selectFromOptions"
              *ngIf="showSpecificArea"
              (click)="showOptions()"
            >
              Select from options
            </button>
          </div>
          <div class="insideFormDivTags">
            <h3>Which city are you from?</h3>
            <div>
              <div class="table">
                <input
                  class="form-control"
                  formControlName="city"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="insideFormDivTags">
            <h3>What do you do at the moment?</h3>
            <div>
              <div class="table">
                <ng-select
                  class="form-control table"
                  [items]="moment"
                  formControlName="doAtTheMoment"
                  [(ngModel)]="selected"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="insideFormDivTags" *ngIf="selected == 'Others'">
            <h3>Name other :</h3>
            <input
              class="form-control table"
              formControlName="otherdoAtMoment"
              type="text"
            />
          </div>
          <div class="insideFormDivTags">
            <h3>Have you consulted a psychologist or a psychiatrist before?</h3>
            <div class="radioButton">
              <input
                type="radio"
                name="therapyBefore"
                formControlName="therapyBefore"
                value="Yes"
              />
              <span> Yes</span>
            </div>
            <div class="radioButton">
              <input
                type="radio"
                name="therapyBefore"
                formControlName="therapyBefore"
                value="No"
              />
              <span> No</span>
            </div>
          </div>
          <div class="insideFormDivTags">
            <h3>Are you on any medication for your mental health?</h3>
            <div class="radioButton">
              <input
                type="radio"
                name="medication"
                formControlName="medication"
                value="Yes"
              />
              <span> Yes</span>
            </div>
            <div class="radioButton">
              <input
                type="radio"
                name="medication"
                formControlName="medication"
                value="No"
              />
              <span> No</span>
            </div>
          </div>
          <div class="insideFormDivTags">
            <h3>
              In the last 14 days have you had any thoughts of harming yourself?
            </h3>
            <div class="radioButton">
              <input
                type="radio"
                name="selfHarm"
                formControlName="selfHarm"
                value="Yes"
              />
              <span> Yes</span>
            </div>
            <div class="radioButton">
              <input
                type="radio"
                name="selfHarm"
                formControlName="selfHarm"
                value="No"
              />
              <span> No</span>
            </div>
          </div>
          <div class="insideFormDivTags" *ngIf="intakeForm.value.selfHarm === 'Yes'">
            <h3>Emergency Contact Name : -</h3>
            <input type="text" class="form-control table" formControlName="emergencyContactName">
          </div>
          <div class="insideFormDivTags" *ngIf="intakeForm.value.selfHarm === 'Yes'">
            <h3>Emergency Contact Number : -</h3>
            <input type="text" class="form-control table" formControlName="emergencyContactDetails">
          </div>
          <div class="insideFormDivTags">
            <h3>
              Is there anything else that you would like your therapist to know
              before the session?
            </h3>
            <div class="textAreaHolder">
              <textarea
                name="extraInfo"
                rows="4"
                formControlName="extraInfo"
              ></textarea>
            </div>
          </div>
          <div class="submitButton text-center">
            <button (click)="onSubmit()" class="thm-2">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
