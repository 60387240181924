import { Component, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { faChevronLeft, faChevronRight, faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { SideNavBarService } from '../side-nav-bar.service';
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { COMMON_DATA } from '../shared/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog.service';
import { ContactService } from '../guest/contact.service';
import { BootstrapAlertService, BootstrapAlert } from '../shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.css']
})
export class GetInTouchComponent implements OnInit {
  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faCalendar = faCalendar;
  faClock = faClock;
  userCountryCode = {
    countryCode: "in",
    dialCode: "91"
  };
  timeSlots = COMMON_DATA.timeSlotsInSignUp;
  bsConfig: Partial<BsDatepickerConfig>;
  corporateForm: FormGroup;
  companyImages = [
    {
      cardimages: "../../assets/images/corporate/image_1.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_2.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_3.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_4.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_5.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_6.png"
    }
  ];
  testimonialsSlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    infinite: true,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 2,
          autoPlay: false,
          autoplaySpeed: 2000,
          infite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 1,
          autoPlay: true,
          autoplaySpeed: 2000,
          infite: true,
        }
      },
    ]
  }
  constructor(private sidenavService: SideNavBarService,     private confirmationService: ConfirmationDialogService,
    private corporateService: ContactService,
    private alertService: BootstrapAlertService,
    private viewportScroller: ViewportScroller
    ) { }

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    this.viewportScroller.scrollToAnchor('topSection')
    this.corporateForm = new FormGroup({
      name: new FormControl("", Validators.required),
      designation: new FormControl("", [
        Validators.required,
      ]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      companyName: new FormControl("", Validators.required),
      noOfEmployee: new FormControl(null),
      department: new FormControl(""),
      officeEmailId: new FormControl("", [
        Validators.required,
        Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
      ]),
      preferredDate: new FormControl(null, Validators.required),
      preferredTime: new FormControl("", Validators.required),
    });
  }
  slickInit(e) {
    // console.log("slick initialized");
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.corporateForm.patchValue({
      phone: phoneNew
    })
  }

  checkError(valid) {
    if (!valid) {
      this.corporateForm.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }

  breakpoint(e) {
    // console.log("breakpoint");
  }

  afterChange(e) {
    // console.log("afterChange");
  }

  beforeChange(e) {
    // console.log("beforeChange");
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  nextSlideTestimonials() {
    this.slickModal1.slickNext();
  }
  prevSlideTestimonials() {
    this.slickModal1.slickPrev();
  }

  onSubmit() {
    if(this.corporateForm.invalid){
      console.log(this.corporateForm)
      return;
    }
    console.log(this.corporateForm)
    this.confirmationService
    .confirm(
      "Are you sure you want to request for demo?",
      "",
      "Yes",
      "Cancel"
    )
    .subscribe((res) => {
      if (res) {
        let formValue = this.corporateForm.value;
        formValue.phone = `+${this.userCountryCode.dialCode}${formValue.phone}`;
        this.corporateService
          .requestCorporateDemo(formValue)
          .subscribe((res) => {
            if (res) {
              this.alertService.alert(
                new BootstrapAlert(
                  "We will get in touch with you soon!",
                  "alert-success"
                )
              );
              this.corporateForm.reset();
            }
          });
      }
    });
  }

}
