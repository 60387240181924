<div class="topMenuMargin" (click)="closenavbar()">
  <div class="d-flex align-items-center flex-column">
    <div class="heading-text">Your Happy Place!</div>
    <hr class="secHeadingHR">
  </div>
  <div class="d-flex justify-content-center">
    <div class="filterHolder" >
      <div class="searchContainer">
        <form>
          <input class="search-input-text" [formControl]="query" (keyup.enter)="onSearch()" (keyup)="onSearch()"
            type="search" placeholder="Search..." />
        </form>
      </div>
      <div class="categoryFilter">
        <ng-select [(ngModel)]="categoryFilter" class="menu-options" *ngIf="categories.length > 0"
          (change)="onSearch()" [clearable]="false">
          <ng-option value="" selected disabled>Category</ng-option>
          <ng-option *ngFor="let item of categories" value="{{ item.name }}">{{
            item.name
            }}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
  <div class="col-lg-6 col-md-6 col-12">
    <div *ngIf="isShimmerLoader">
  <ngx-skeleton-loader appearance="line" [theme]="{
    'border-radius': '5px',
    height: '33rem',
    width: '85%',
    border: '1px solid white',
    'margin-left': '30px',
    'margin-top': '15px',
    'margin-bottom': '10px'
  }">
</ngx-skeleton-loader><br />
<ngx-skeleton-loader appearance="line" [theme]="{
    'border-radius': '5px',
    height: '50px',
    width: '85%',
    border: '1px solid white',
    'margin-left': '30px',
    'margin-bottom': '1px'
  }">
</ngx-skeleton-loader><br />
<ngx-skeleton-loader appearance="line" [theme]="{
    'border-radius': '5px',
    height: '50px',
    width: '70%',
    border: '1px solid white',
    'margin-left': '30px',
    'margin-bottom': '1px'
  }">
</ngx-skeleton-loader>
  </div>
  </div>

<div class="col-lg-3 col-md-6 col-12">
  <div *ngIf="isShimmerLoader">
    <div *ngFor="let item of fakeArray(2)">
      <ngx-skeleton-loader appearance="line" [theme]="{
          'border-radius': '5px',
          height: '250px',
          width: '300px',
          border: '1px solid white',
          'margin-left': '30px',
          'margin-top': '15px',
          'margin-bottom': '10px'
        }">
      </ngx-skeleton-loader><br />
      <ngx-skeleton-loader appearance="line" [theme]="{
          'border-radius': '5px',
          height: '20px',
          width: '300px',
          border: '1px solid white',
          'margin-left': '30px',
          'margin-bottom': '1px'
        }">
      </ngx-skeleton-loader><br />
      <ngx-skeleton-loader appearance="line" [theme]="{
          'border-radius': '5px',
          height: '20px',
          width: '250px',
          border: '1px solid white',
          'margin-left': '30px',
          'margin-bottom': '1px'
        }">
      </ngx-skeleton-loader>
    </div>
  </div>
</div>

<div class="col-lg-3 col-md-6 col-12">
  <div *ngIf="isShimmerLoader">
    <div *ngFor="let item of fakeArray(2)">
      <ngx-skeleton-loader appearance="line" [theme]="{
          'border-radius': '5px',
          height: '250px',
          width: '300px',
          border: '1px solid white',
          'margin-left': '30px',
          'margin-top': '15px',
          'margin-bottom': '10px'
        }">
      </ngx-skeleton-loader><br />
      <ngx-skeleton-loader appearance="line" [theme]="{
          'border-radius': '5px',
          height: '20px',
          width: '300px',
          border: '1px solid white',
          'margin-left': '30px',
          'margin-bottom': '1px'
        }">
      </ngx-skeleton-loader><br />
      <ngx-skeleton-loader appearance="line" [theme]="{
          'border-radius': '5px',
          height: '20px',
          width: '250px',
          border: '1px solid white',
          'margin-left': '30px',
          'margin-bottom': '1px'
        }">
      </ngx-skeleton-loader>
    </div>
  </div>
</div>
</div>
  <div class="gray-background d-flex align-items-center flex-column">
    <div class="featured-container">
      <div class="hero-left-content" *ngIf="featuredBlog" routerLink="../blog-view/{{ featuredBlog?.slug }}">
        <div class="hero-left-image" routerLink="../blog-view/{{ featuredBlog?.slug }}" [ngStyle]="{
            background: 'url(' + featuredBlog.featuredURL + ')',
            'background-size': 'cover',
            'backgroud-repeat': 'no-repeat'
          }"></div>
        <div class="small-text" routerLink="../blog-view/{{ featuredBlog?.slug }}">
          {{ featuredBlog?.createdAt | date: "EEEE, dd MMMM" }}
        </div>
        <b class="heading-blog-detail" routerLink="../blog-view/{{ featuredBlog?.slug }}">{{ featuredBlog?.title }}</b>
      </div>
      <div class="hero-right-content">
        <div *ngFor="let item of latestBlogs">
          <div class="grid-image-1" routerLink="../blog-view/{{ item?.slug }}" [ngStyle]="{
              background: 'url(' + item.imageURL + ') no-repeat',
              'background-size': 'cover',
              'background-position': 'center'
            }"></div>
          <div class="small-text" routerLink="../blog-view/{{ item?.slug }}">
            {{ item?.createdAt | date: "EEEE, dd MMMM" }}
          </div>
          <b class="sub-text" routerLink="../blog-view/{{ item?.slug }}">{{
            item?.title
            }}</b>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="orange-button" routerLink="../all-blogs">
        View All Blogs
      </button>
    </div>
    <div class="top-category-container d-flex align-items-center flex-column">
      <div class="heading-text">Top Categories</div>
      <div class="top-category-flex">
        <div id="categoryScroll">
          <ul *ngIf="topCategories?.length > 0">
            <li *ngFor="let item of topCategories; let inx = index" class="cursorPointer"
              [routerLink]="['../all-blogs']" [queryParams]="{ category: item.name }">
              <a>
                <img src="{{ imageUrl }}/{{ item.image }}" alt="" />
              </a>
              <h2>{{ item.name }}</h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="wave-bg"></div>
  <div class="d-flex align-items-center flex-column">
    <div class="heading-text" style="margin-top: 0px">What Our Users Say</div>
  </div>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <ngx-slick-carousel class="carousel" #slickModal2="slick-carousel" [config]="testimonialsSlideConfig">
          <div class="carousel-card" *ngFor="let item of userReviews" ngxSlickItem>
            <div class="sub-text">
              <b>{{ item.name }}</b>
            </div>
            <div class="small-text" style="margin-top: 6px">
              {{ item.work }}
            </div>
            <div class="rating" style="margin-top: 30px"></div>
            <div class="sub-text card-content-width" style="margin-top: 30px">
              {{ item.text }}
            </div>
            <div class="quote"></div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center flex-column">
    <div class="heading-text">Free Mental Health Tests</div>
    <div class="health-container d-flex">
      <div id="categoryScroll">
        <ul *ngIf="freeReports?.length > 0">
          <li *ngFor="let item of freeReports; let inx = index" class="cursorPointer">
            <a>
              <img class="freeReportListImg" src="{{ item.image }}" alt="" routerLink="../{{ item.route }}" />
            </a>
            <p class="report-description" [ngClass]="{ 'showLess': !item.showMore, 'showMore': item.showMore}">
              {{item.desc}}
            </p>
            <p *ngIf="!item.showMore" class="showTxt" (click)="showMoreToggle(inx)">
              Show More
            </p>
            <p *ngIf="item.showMore" class="showTxt" (click)="showMoreToggle(inx)">
              Show Less
            </p>
            <button class="orange-button" routerLink="../{{ item.route }}">
              {{ item.name }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center flex-column">
    <div class="heading-text">Free Exercises</div>
    <div class="free-exercise-content">
      <div class="col-md-12">
        <ngx-slick-carousel class="carousel" #slickModal3="slick-carousel" [config]="youMayAlsoLikeConfig"
          class="mySwiper">
          <div ngxSlickItem *ngFor="let item of safeExerciseUrls">
            <div class="likeVideoHolder">
              <iframe [src]="item" class="you-may-like-videos" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
        </ngx-slick-carousel>
        <swiper [slidesPerView]="1" [spaceBetween]="30" [pagination]="{
            clickable: true,
            dynamicBullets: true
          }" [navigation]="true" [autoplay]="{
            delay: 3500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true
          }" [loop]="true" [loopFillGroupWithBlank]="true" [breakpoints]="{
            '640': {
              slidesPerView: 1
            },
            '768': {
              slidesPerView: 1
            },
            '1024': {
              slidesPerView: 2
            }
          }" class="mySwiper">
        </swiper>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center flex-column">
    <div class="heading-text">Expert Speak</div>
  </div>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <ngx-slick-carousel class="carousel" #slickModal1="slick-carousel" [config]="expertSpeaksConfig"
          class="mySwiper">
          <div swiperSlide *ngFor="let item of safeExpertUrls" ngxSlickItem>
            <div class="expertSlideHolder">
              <iframe [src]="item" class="expert-videos" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center flex-column">
    <div class="heading-text">Videos You May Like</div>
  </div>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <ngx-slick-carousel class="carousel" #slickModal3="slick-carousel" [config]="youMayAlsoLikeConfig"
          class="mySwiper">
          <div ngxSlickItem *ngFor="let item of safeVideoAlsoLike">
            <div class="likeVideoHolder">
              <iframe [src]="item" class="you-may-like-videos" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
  <div>
    <div class="container">
      <div class="boxRound subsBox">
        <div class="secondaryHeading">
          <h2>
            The latest mental health news and tips, delivered to your inbox
            weekly.
          </h2>
          <hr />
        </div>
        <div class="subsEmail formRound1">
          <form [formGroup]="subsForm" (ngSubmit)="onSubmit()">
            <div class="d-flex">
              <input type="text" placeholder="Email" formControlName="email"
                class="form-control" />
              <button type="submit" class="buttonTheme1 subsButton">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>