<div class="container-fluid" (click)="closenavbar()">
  <div class="row">
    <div class="secondaryHeading">
      <h2>ADHD Tests</h2>
      <hr />
      <p>
        Join our community and get access to exclusive content on mental
        wellness. <br />
        Take a Free test and get Your Report.
      </p>
    </div>
  </div>
  <div class="faquchaticon">
    <div class="row justify-content-center">
      <div class="col-sm-6 col-md-4 extraWidthSize">
        <a routerLink="../child-adhd-test" class="h3TagClass">
          <div class="contentBoxImg moreCss">
            <img
              src="../../../../assets/images/CHILD ADHD.png"
              class="img-fluid image"
              draggable="false"
              alt=""
            />
            <h3>Child ADHD<br />(ADHD-RS)</h3>
            <p>The ADHD Rating Scale - IV: Home Version is a parent-report measure, reports of which have to be shown to a healthcare provider who can guide best.</p>
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-md-4 extraWidthSize">
        <a routerLink="../adult-adhd-test" class="h3TagClass">
          <div class="contentBoxImg moreCss">
            <img
              src="../../../../assets/images/ADULT ADHD.png"
              class="img-fluid image"
              draggable="false"
              alt=""
            />
            <h3>The Adult Self-Report Scale <br />(ASRS)</h3>
            <p>ASRS is a test developed by the World Health Organization (WHO) to help you recognize the signs and symptoms of adult ADHD</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
