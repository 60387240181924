import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Editor } from "ngx-editor";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { DiscussionForumService } from "../discussion-forum.service";
import clevertap from "clevertap-web-sdk";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: "app-user-discussion-form",
  templateUrl: "./user-discussion-form.component.html",
  styleUrls: ["./user-discussion-form.component.css"],
})
export class UserDiscussionFormComponent implements OnInit, OnDestroy {
  editor: Editor;
  categoryitems = [];
  isLoading = false;
  discussionId = null;
  mode: string;
  constructor(
    private discussionService: DiscussionForumService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private confirmationService: ConfirmationDialogService,
    private router: Router
  ) {}
  discussionForm = new FormGroup({
    topic: new FormControl("", Validators.required),
    tags: new FormControl("", Validators.required),
    description: new FormControl("", Validators.required),
    isAnonymous: new FormControl(false, Validators.required),
  });
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    this.editor = new Editor();
    this.discussionService.getCategories().subscribe((data) => {
      this.categoryitems = data["category"];
    });
    this.actRoute.queryParamMap.subscribe((params) => {
      this.discussionId = params.get("discussionId");
      if (this.discussionId != null) {
        this.discussionService
          .getForumById(this.discussionId)
          .subscribe((data) => {
            this.discussionForm.setValue({
              topic: data["topic"],
              tags: data["tags"],
              description: data["description"],
              isAnonymous: data["isAnonymous"],
            });
          });
      }
    });
    this.actRoute.queryParams.subscribe((params) => {
      this.mode = params["mode"];
    });
  }

  onSubmit() {
    if (this.discussionForm.invalid) {
      return;
    }
    if (this.mode == "edit") {
      this.confirmationService
        .confirm(
          "Are you sure?",
          "This will update the discussion",
          "Yes, Proceed",
          "Cancel"
        )
        .subscribe((res) => {
          this.isLoading = true;
          if (res) {
            this.discussionService
              .updateDiscussionForumById(
                this.discussionId,
                this.discussionForm.value
              )
              .subscribe((data) => {
                if (data) {
                  this.alertService.alert(
                    new BootstrapAlert(
                      "Discussion was updated successfully",
                      "alert-success"
                    )
                  );
                }
              },(err)=>{
                this.isLoading = false;
              });
            this.router.navigateByUrl("user-dashboard/discussion");
          }
        });
    } else {
      this.confirmationService
        .confirm(
          "Are you sure?",
          "This will post a new discussion",
          "Yes, Proceed",
          "Cancel"
        )
        
        .subscribe((res) => {
          if (res) {
            this.discussionService
              .createDiscussion(this.discussionForm.value)
              .subscribe((data) => {
                clevertap.event.push("Discussion Started")
                if (data) {
                  this.alertService.alert(
                    new BootstrapAlert(
                      "Discussion was created successfully",
                      "alert-success"
                    )
                  );
                }
              });
            this.router.navigateByUrl("user-dashboard/discussion");
          }
        });
        this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
