<div class="secPadding" (click)="closenavbar()">
  <div class="container">
    <div class="secondaryHeading">
      <h2>Find A Plan That's Right For You</h2>
      <hr />
      <p>
        Cancel anytime. Same day full refund. Change your therapist anytime. No
        questions asked
      </p>
    </div>
    <div class="planBoxWrap">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-4 planRes">
          <div class="planBox">
            <div class="planContWrap">
              <div class="planIcon">
                <img
                  src="../../../../assets/images/plans-pricing/explore-plan-1.svg"
                  class="img-fluid"
                  draggable="false"
                  alt=""
                />
              </div>
              <div class="planConts">
                <h2>1 Couple Session</h2>
                <h5>( 1 hour of therapy )</h5>
                <div class="iconsHolder">
                  <img
                    src="../../../../assets/images/video.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                  <img
                    src="../../../../assets/images/mic.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                  <img
                    src="../../../../assets/images/chat.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="planConts">
                <h3>What to Expect ?</h3>
                <p>Understand dysfunctional relationship dynamics</p>
              </div>
              <div class="planPrice">
                <h2>INR 1499</h2>
                <p>( Price per session INR 1499 )</p>
              </div>
              <div class="planBuy">
                <a (click)="buyNow('plan_h')" class="buttonTheme1">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 planRes">
          <div class="planBox">
            <div class="planContWrap">
              <img
                src="../../../../assets/images/plans-pricing/recommended-tag.png"
                class="img-fluid recommendedTag"
                draggable="false"
                alt=""
              />
              <div class="planIcon">
                <img
                  src="../../../../assets/images/plans-pricing/couplescounselling-3hr.png"
                  class="img-fluid"
                  draggable="false"
                  alt=""
                />
              </div>
              <div class="planConts">
                <h2>3 Couple Sessions</h2>
                <h5>( 3 hours of therapy )</h5>
                <div class="iconsHolder">
                  <img
                    src="../../../../assets/images/video.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                  <img
                    src="../../../../assets/images/mic.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                  <img
                    src="../../../../assets/images/chat.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="planConts">
                <h3>What to Expect ?</h3>
                <p>Learn communication techniques to repair & rebuild trust</p>
              </div>
              <div class="planPrice">
                <h2>INR 3899</h2>
                <p>( Price per session INR 1299 )</p>
              </div>
              <div class="planBuy">
                <a (click)="buyNow('plan_i')" class="buttonTheme1">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 planRes">
          <div class="planBox">
            <div class="planContWrap">
              <div class="planIcon">
                <img
                  src="../../../../assets/images/plans-pricing/couplescounselling-6hr.png"
                  class="img-fluid"
                  draggable="false"
                  alt=""
                />
              </div>
              <div class="planConts">
                <h2>6 Couples Sessions</h2>
                <h5>( 6 hours of therapy )</h5>
                <div class="iconsHolder">
                  <img
                    src="../../../../assets/images/video.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                  <img
                    src="../../../../assets/images/mic.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                  <img
                    src="../../../../assets/images/chat.png"
                    alt=""
                    draggable="false"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="planConts">
                <h3>What to Expect ?</h3>
                <p>
                  Vent out, discuss your issues & create a plan with your talk
                  therapist
                </p>
              </div>
              <div class="planPrice">
                <h2>INR 7199</h2>
                <p>( Price per session INR 1199 )</p>
              </div>
              <div class="planBuy">
                <a (click)="buyNow('plan_j')" class="buttonTheme1">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="plansTip">
      Sessions do not expire & have lifetime validity. <br />
      Once bought you can schedule it at your convenience.
    </div>
    <div class="plansTip">
      Couldn't find the plan you are looking for? <br />
      No worries, please drop us a call on
      <a href="tel:+916377327550">+916377327550</a>, and we'll help you out.
    </div>
  </div>
  <div class="faqHolder">
    <div class="faqHeading">
      <h2>Frequently Asked Questions</h2>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div class="faqQue">
                <h4>How many sessions do we need?</h4>
              </div>
            </div>
            <div class="flip-card-back">
              <p>
                What you need is extremely specific to your own needs. However,
                we recommend a minimum of 3 sessions for anyone to experience
                meaningful change in their thoughts & behavior.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div class="faqQue">
                <h4>What if I don’t want to do a video session?</h4>
              </div>
            </div>
            <div class="flip-card-back">
              <p>
                You can choose your preferred medium for the session – we give
                all options for your 45 minutes of session – video, audio, or
                chat.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>Is there an EMI option?</h4>
            </div>
            <div class="flip-card-back">
              <p>Yes, you just have to choose it while checking out.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>
                Can we both access the same session link if we are in different
                locations?
              </h4>
            </div>
            <div class="flip-card-back">
              <p>Yes. You will get a link that will work for both.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>Can we do individual sessions in between?</h4>
            </div>
            <div class="flip-card-back">
              <p>
                Yes. Once you have bought a pack of sessions, you can use it as
                you deem fit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
