import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const contactBackendUrl = environment.apiUrl + "/guest/contact/";
const guestcallbackUrl = environment.apiUrl + "/guest/callback/";
const corporateUrl = environment.apiUrl + "/guest/corporate/";
const verificationUrl = environment.apiUrl + "/guest/verify-email/"
@Injectable({
  providedIn: "root",
})
export class ContactService {
  constructor(private http: HttpClient) {}

  requestContact(contactObj) {
    return this.http.post(contactBackendUrl, contactObj);
  }

  requestCallback(data){
    return this.http.post(guestcallbackUrl, data)
  }

  requestCorporateDemo(data){
    return this.http.post(corporateUrl + 'create' , data)
  }

  verifyEmail(code){
    return this.http.get(verificationUrl + code)
  }
}
