import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const userBackendUrl = environment.apiUrl + "/therapist/therapist/";
const imageUploadUrl =
  environment.apiUrl + "/therapist/therapist/uploadProfileImage";

@Injectable({
  providedIn: "root",
})
export class TherapistProfileService {
  constructor(private http: HttpClient) { }
  getProfile() {
    return this.http.get(userBackendUrl + "get-details");
  }

  getUserProfile(id) {
    return this.http.get(userBackendUrl + "get-userdetails/" + id);
  }

  getUserIntakeForm(id) {
    return this.http.get(userBackendUrl + "get-intake-form/" + id);
  }

  uploadProfileImage(imgData) {
    return this.http.post(imageUploadUrl, imgData);
  }

  updateProfile(userData) {
    return this.http.put(userBackendUrl + "update-details", userData);
  }

  uploadLastDegree(data) {
    return this.http.post(userBackendUrl + "last-degree-upload", data);
  }

  uploadExperienceLetter(data) {
    return this.http.post(userBackendUrl + "experience-letter", data);
  }

  uploadResume(data) {
    return this.http.post(userBackendUrl + "resume", data);
  }

  getReports() {
    return this.http.get(userBackendUrl + "get-report");
  }

  getDocuments(key) {
    return this.http.get(userBackendUrl + "get-documents/" + key)
  }
}
