<div class="sectionPadding" (click)="closenavbar()">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="signInDemoWrap">
          <img
            src="../../../../assets/images/corporate-wellness-mental-health/corporate-demo-sign-up.svg"
            class="img-fluid"
            draggable="false"
            alt="sign up for demo of felicity online counseling platform, employee mental health"
          />
          <h3>Happier Employees Are Productive Employees</h3>
        </div>
      </div>
      <div class="col-md-6">
        <div class="signInDemoForm formRound">
          <div class="formHeading">
            <div class="sectionHeading">
              <h2>Sign Up For Demo</h2>
              <hr />
            </div>
          </div>
          <form [formGroup]="signUpDemoForm" name="signUpDemoForm">
            <div class="form-group">
              <input
                type="text"
                placeholder="Name *"
                class="form-control"
                formControlName="name"
                [ngClass]="{
                  'has-error':
                    !signUpDemoForm.controls.name.valid &&
                    signUpDemoForm.controls.name.touched,
                  'has-success':
                    signUpDemoForm.controls.name.valid &&
                    signUpDemoForm.controls.name.touched
                }"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                placeholder="Designation *"
                class="form-control"
                formControlName="designation"
                [ngClass]="{
                  'has-error':
                    !signUpDemoForm.controls.designation.valid &&
                    signUpDemoForm.controls.designation.touched,
                  'has-success':
                    signUpDemoForm.controls.designation.valid &&
                    signUpDemoForm.controls.designation.touched
                }"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                placeholder="Company Name *"
                class="form-control"
                formControlName="companyName"
                [ngClass]="{
                  'has-error':
                    !signUpDemoForm.controls.companyName.valid &&
                    signUpDemoForm.controls.companyName.touched,
                  'has-success':
                    signUpDemoForm.controls.companyName.valid &&
                    signUpDemoForm.controls.companyName.touched
                }"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                id="phoneInput"
                formControlName="phone"
                name="phone"
                style="padding:11px 50px;"
                class="form-control phoneControl"
                ng2TelInput
                (hasError)="checkError($event)"
                (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)"
                (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                  initialCountry: userCountryCode.countryCode
                }"
              />

              <!-- 
                Add this class in input> phone if required
                [ngClass]="{
                  'has-error':
                    signUpDemoForm.controls.phone.errors?.invalidPhone ||
                    (signUpDemoForm.controls.phone.errors?.required &&
                      signUpDemoForm.controls.phone.touched)
                }"
                <div
                *ngIf="
                  signUpDemoForm.controls.phone.errors?.required &&
                  signUpDemoForm.controls.phone.touched
                "
                class="errorContainer"
              >
                <p>Phone number is required</p>
              </div> -->
              <div
                *ngIf="
                  signUpDemoForm.controls.phone.errors?.pattern &&
                  signUpDemoForm.controls.phone.touched
                "
                style="font-size: 14px;color: red;margin-left: 17px;"
                class="errorContainer"
              >
                <p>Phone number should contain only numbers</p>
              </div>
              <div
                *ngIf="
                signUpDemoForm.controls.phone.touched &&
                  signUpDemoForm.controls.phone.value.length <= 6
                "
                class="errorContainer"
                style="font-size: 14px;color: red;margin-left: 17px;"
              >
                <p>Phone number is not valid</p>
              </div>
            </div>
            <div class="form-group">
              <ng-select
                class="form-control"
                formControlName="noOfEmployee"
                placeholder="No. of Employees*"
                [items]="noOfEmployeeOptions"
                [ngClass]="{
                  'has-error':
                    !signUpDemoForm.controls.noOfEmployee.valid &&
                    signUpDemoForm.controls.noOfEmployee.touched,
                  'has-success':
                    signUpDemoForm.controls.noOfEmployee.valid &&
                    signUpDemoForm.controls.noOfEmployee.touched
                }"
              ></ng-select>
            </div>
            <div class="form-group">
              <input
                type="text"
                placeholder="Department *"
                class="form-control"
                formControlName="department"
                [ngClass]="{
                  'has-error':
                    !signUpDemoForm.controls.department.valid &&
                    signUpDemoForm.controls.department.touched,
                  'has-success':
                    signUpDemoForm.controls.department.valid &&
                    signUpDemoForm.controls.department.touched
                }"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                placeholder="Official Email ID *"
                class="form-control"
                formControlName="officeEmailId"
                [ngClass]="{
                  'has-error':
                    !signUpDemoForm.controls.officeEmailId.valid &&
                    signUpDemoForm.controls.officeEmailId.touched,
                  'has-success':
                    signUpDemoForm.controls.officeEmailId.valid &&
                    signUpDemoForm.controls.officeEmailId.touched
                }"
              />
            </div>
            <div class="form-group">
                <input
                  type="text"
                  placeholder="Preferred Date*"
                  class="form-control"
                  bsDatepicker
                  [bsConfig]="bsConfig"
                  formControlName="preferredDate"
                />
            </div>
            <div class="form-group">
              <ng-select
                class="form-control"
                formControlName="preferredTime"
                placeholder="Preferred Time For Callback*"
                [items]="timeSlots"
                [ngClass]="{
                  'has-error':
                    !signUpDemoForm.controls.preferredTime.valid &&
                    signUpDemoForm.controls.preferredTime.touched,
                  'has-success':
                    signUpDemoForm.controls.preferredTime.valid &&
                    signUpDemoForm.controls.preferredTime.touched
                }"
              ></ng-select>
            </div>
            {{ message }}
            <div class="form-group text-center submitbtnn">
              <button
                class="thm-2"
                type="submit"
                [disabled]="signUpDemoForm.invalid"
                (click)="onSubmit()"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
