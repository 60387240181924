<section class="header-section">
  <div class="logoHolder">
    <img src="../../../assets/images/logo.webp" alt="" class="img-fluid" />
  </div>
</section>
<section class="hero-section">
  <div class="container">
    <div class="hero-text-wrapper">
      <h1 class="hero-title">Talk. Resolve. Heal.</h1>
      <p class="hero-desc">
        Online Counselling Therapy With Top Psychologists <br />
        Anytime, Anywhere, Any device.
      </p>
      <div class="row align-items-center" #signUpBox>
        <div class="col-md-6 borderInRight">
          <div class="signUpForm formRound">
            <form
              [formGroup]="signUpForm"
              (ngSubmit)="onSubmit()"
              name="signUpForm"
            >
              <div class="form-group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  formControlName="name"
                  placeholder="Name *"
                  class="form-control"
                  [ngClass]="{
                    'has-error':
                      !signUpForm.controls.name.valid &&
                      signUpForm.controls.name.touched,
                    'has-success':
                      signUpForm.controls.name.valid &&
                      signUpForm.controls.name.touched
                  }"
                />
                <div
                  *ngIf="
                    signUpForm.controls.name.errors?.required &&
                    signUpForm.controls.name.touched
                  "
                  class="errorContainer"
                >
                  <p>Name is required</p>
                </div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  name="email"
                  id="email"
                  formControlName="email"
                  placeholder="Email *"
                  class="form-control"
                  [ngClass]="{
                    'has-error':
                      !signUpForm.controls.email.valid &&
                      signUpForm.controls.email.touched,
                    'has-success':
                      signUpForm.controls.email.valid &&
                      signUpForm.controls.email.touched
                  }"
                />
                <div
                  *ngIf="
                    signUpForm.controls.email.errors?.required &&
                    signUpForm.controls.email.touched
                  "
                  class="errorContainer"
                >
                  <p>Email is required</p>
                </div>
                <div
                  *ngIf="
                    signUpForm.controls.email.errors?.pattern &&
                    signUpForm.controls.email.touched
                  "
                  class="errorContainer"
                >
                  <p>Email is invalid</p>
                </div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="phoneInput"
                  formControlName="phone"
                  name="phone"
                  class="form-control phoneControl"
                  [ngClass]="{
                    'has-error':
                      signUpForm.controls.phone.errors?.invalidPhone ||
                      (signUpForm.controls.phone.errors?.required &&
                        signUpForm.controls.phone.touched)
                  }"
                  ng2TelInput
                  (hasError)="checkError($event)"
                  (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }"
                />
                <div
                  *ngIf="
                    signUpForm.controls.phone.errors?.required &&
                    signUpForm.controls.phone.touched
                  "
                  class="errorContainer"
                >
                  <p>Phone number is required</p>
                </div>
                <div
                  *ngIf="
                    signUpForm.controls.phone.errors?.pattern &&
                    signUpForm.controls.phone.touched
                  "
                  class="errorContainer"
                >
                  <p>Phone number should contain only numbers</p>
                </div>
                <div
                  *ngIf="
                    signUpForm.controls.phone.errors?.invalidPhone &&
                    signUpForm.controls.phone.touched
                  "
                  class="errorContainer"
                >
                  <p>Phone number is not valid</p>
                </div>
              </div>
              <div class="input-group">
                <input
                  [type]="isShowPassword ? 'text' : 'password'"
                  name="password"
                  id="password"
                  formControlName="password"
                  placeholder="Password *"
                  class="form-control"
                  [ngClass]="{
                    'has-error':
                      !signUpForm.controls.password.valid &&
                      signUpForm.controls.password.touched,
                    'has-success':
                      signUpForm.controls.password.valid &&
                      signUpForm.controls.password.touched
                  }"
                />
                <span class="eyeIcon">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-eye-slash': !isShowPassword,
                      'fa-eye': isShowPassword
                    }"
                    (click)="showPasswordToggle()"
                  ></i>
                </span>
              </div>
              <div
                *ngIf="
                  signUpForm.controls.password.errors?.required &&
                  signUpForm.controls.password.touched
                "
                class="errorContainer"
              >
                <p>Password is required</p>
              </div>
              <div
                *ngIf="
                  signUpForm.controls.password.errors?.pattern &&
                  signUpForm.controls.password.touched
                "
                class="errorContainer"
              >
                <p>
                  Password must be minimum 8 characters, 1 lower case letter, 1
                  number and 1 special character
                </p>
              </div>
              <div class="form-group">
                <div *ngIf="isLoading" class="d-flex justify-content-start">
                  <div
                    class="spinner-border"
                    style="
                      width: 2rem;
                      height: 2rem;
                      margin-left: 55px;
                      margin-top: 23px;
                    "
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
                </div>
                <button
                  *ngIf="!isLoading"
                  class="buttonTheme1 signUpBtn"
                  type="submit"
                  [disabled]="!signUpForm.valid"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="socialLoginWrap">
            <ul>
              <li #signUpRef>
                <a (click)="loginWithGoogle()">
                  <img
                    src="../../../assets/images/google.png"
                    class="img-fluid socialIcon"
                    draggable="false"
                    alt="social login google"
                  />
                  <span>Sign Up with Google</span>
                </a>
              </li>
              <li>
                <a (click)="loginWithFacebook()">
                  <img
                    src="../../../assets/images/facebook.png"
                    class="img-fluid socialIcon"
                    draggable="false"
                    alt="social login facebook"
                  />
                  <span>Sign Up with Facebook</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="counselling-session-section">
  <div class="container">
    <h2 class="section-title title-with-line">Choose Help. Not Suffering.</h2>
    <h3 class="section-sub-title">
      Counselling Therapy Sessions With <br />
      Licensed & Verified Experts
    </h3>
    <div class="row justify-content-md-center">
      <div class="col-md-8 text-center slider-col">
        <div class="counselorHolder fade-in">
          <ngx-slick-carousel
            class="counsellor-slider me-md-3"
            #slickModal="slick-carousel"
            [config]="slideConfig"
            (init)="slickInit($event)"
            (breakpoint)="breakpoint($event)"
            (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)"
          >
            <div
              class="item sldie"
              ngxSlickItem
              *ngFor="let therapist of therapistsData"
              routerLink="../community-talk-therapists-detail/{{
                therapist.id
              }}"
            >
              <img
                src="../../../../assets/images/homepage-slides/{{
                  therapist.fileName
                }}"
                alt="{{ therapist.name }}"
                class="slider-img w-100 borderRadius"
              />
              <div class="slider-info">
                <h3 class="counsellor-name">{{ therapist.name }}</h3>
                <p class="counsellor-title">{{ therapist.title }}</p>
              </div>
            </div>
          </ngx-slick-carousel>
          <div class="zIndexForBtn">
            <a class="primaryBtn counselorBtn" (click)="scroll(signUpBox)"
              >Sign Up</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="trust-section">
      <h2 class="section-title">Why Should You Trust Us?</h2>
      <hr class="hrDivider" />
    </div>
    <div class="row justify-content-around">
      <div class="col-md-2">
        <div class="trust-text">
          <h2>1 Lakh+</h2>
          <p>LIVES IMPACTED</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="trust-text">
          <h2>80+</h2>
          <p>EXPERT THERAPIST</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="trust-text">
          <h2>50,000+</h2>
          <p>HOURS OF THERAPY COMPLETED</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="trust-text">
          <h2>100+</h2>
          <p>DIAGNOSIVE TESTS</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="testimonial-section">
  <h2 class="section-title">What People Say About <span>Felicity</span></h2>
  <div class="container">
    <div class="testimonial-slider-wrapper">
      <ngx-slick-carousel
        class="carousel testimonial-slider"
        #slickModal1="slick-carousel"
        [config]="testimonialsSlideConfig"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)"
      >
        <div
          class="testimonial-slider-item"
          ngxSlickItem
          *ngFor="let review of userReviews"
        >
          <img
            src="../../../../assets/images/blog/quote.webp"
            alt=""
            class="quote-sign"
          />
          <p class="client-name">{{ review.name }}</p>
          <!-- Client Name -->
          <span class="client-title">{{ review.work }}</span>
          <!-- Client Title -->
          <div class="client-stars">
            <fa-icon [icon]="faStar"></fa-icon>
            <fa-icon [icon]="faStar"></fa-icon>
            <fa-icon [icon]="faStar"></fa-icon>
            <fa-icon [icon]="faStar"></fa-icon>
            <fa-icon [icon]="faStar"></fa-icon>
          </div>
          <p class="client-review">
            {{ review.text }}
          </p>
        </div>
      </ngx-slick-carousel>
      <button
        aria-label="Previous"
        class="prev"
        (click)="prevSlideTestimonials()"
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
      <button aria-label="Next" class="next" (click)="nextSlideTestimonials()">
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </div>
  </div>
</section>
<section class="platform-section">
  <div class="container-fluid">
    <div class="row justify-content-around">
      <div class="col-md-5">
        <div class="creative-holder">
          <h2>100% Private & Secure Platform</h2>
          <img
            src="../../../assets/images/creative-secure-platform.webp"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="creative-holder">
          <h2>English And All Regional Languages</h2>
          <img
            src="../../../assets/images/creative-language.webp"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>
    </div>
    <div class="row justify-content-around">
      <div class="col-md-5">
        <div class="creative-holder communication-block">
          <h2>All Communication modes</h2>
        </div>
      </div>
      <div class="col-md-5">
        <div class="session-icons-wrapper d-flex">
          <div class="session">
            <fa-icon [icon]="faVideo"></fa-icon>
            <span class="session-name d-block">Video Session</span>
          </div>
          <div class="session">
            <i class="fa fa-microphone"></i>
            <span class="session-name d-block">Audio Session</span>
          </div>
          <div class="session">
            <fa-icon [icon]="faCommentAlt"></fa-icon>
            <span class="session-name d-block">Chat Session</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="mental-health-section">
  <div class="custom-shape-divider-top-1639975762">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path
        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
        class="shape-fill"
      ></path>
    </svg>
  </div>
  <div class="container">
    <h2 class="section-title">Specialized Help For Your Mental Health</h2>
    <div class="row fade-in">
      <div class="col-12">
        <div class="mental-health-tag-wrapper">
          <a class="tag">Anxiety & Stress</a>
          <a class="tag">Depression</a>
          <a class="tag">Relationship Issues</a>
          <a class="tag">OCD</a>
          <a class="tag">Bipolar Disorder</a>
          <a class="tag">Psychosis</a>
          <a class="tag">Grief & Loss</a>
          <a class="tag">Trauma</a>
          <a class="tag">Couple Therapy</a>
          <a class="tag">LGBTQIA+</a>
          <a class="tag">ADHD & Teenage Issues</a>
          <a class="tag">Sexual Issues</a>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-3">
        <div class="specialBtn fade-in">
          <a (click)="scroll(signUpBox)" class="primaryBtn">Sign Up</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="report-section">
  <div class="custom-shape-divider-top-1639980888">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path
        d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
        class="shape-fill"
      ></path>
    </svg>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-4 order-2 from-left slide-in">
        <div class="test-img-holder">
          <img
            src="../../../../assets/images/free_report_asset.webp"
            alt="Test image"
            class="test-img"
          />
        </div>
      </div>
      <div
        class="col-md-7 order-1 d-flex align-items-center from-right slide-in"
      >
        <div class="report-text-content ms-md-5">
          <h3 class="section-inner-title">
            Take a Free Mental Health Test Today! Know How You Feel.
          </h3>
          <p class="section-inner-desc reportSectionTxt">
            Scientifically validated standard assessments - quickest way to
            determine if you are suffering from symptoms of any mental health
            disorder.
          </p>
          <a routerLink="free-report" class="primaryBtn report-btn"
            >Get a Free Report</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
<section class="how-it-works-section" id="how-it-works">
  <div class="container">
    <h2 class="section-title title-with-line">How It Works</h2>
    <hr class="hrDivider howItWorksMargin" />
    <div class="row">
      <div class="col-md-3 text-center">
        <div class="step">
          <img
            src="../../../../assets/images/how-it-works-booking.webp"
            alt=""
            class="step-img"
          />
          <div class="step-name-wrapper">
            <h3 class="step-name">Book A Counselling Plan</h3>
          </div>
          <fa-icon class="step-arrow" [icon]="faLongArrowAltRight"></fa-icon>
        </div>
      </div>
      <div class="col-md-3 text-center">
        <div class="step">
          <img
            src="../../../../assets/images/how-it-works-counselor.webp"
            alt=""
            class="step-img"
          />
          <div class="step-name-wrapper">
            <h3 class="step-name">Choose A Counselor & Pick A Time</h3>
          </div>
          <fa-icon class="step-arrow" [icon]="faLongArrowAltRight"></fa-icon>
        </div>
      </div>
      <div class="col-md-3 text-center">
        <div class="step">
          <img
            src="../../../../assets/images/how-it-works-chat.webp"
            alt=""
            class="step-img"
          />
          <div class="step-name-wrapper">
            <h3 class="step-name">Join The Session & Chat Afterwards</h3>
          </div>
          <fa-icon class="step-arrow" [icon]="faLongArrowAltRight"></fa-icon>
        </div>
      </div>
      <div class="col-md-3 text-center">
        <div class="step mb-0">
          <img
            src="../../../../assets/images/how-it-works-reschedule.webp"
            alt=""
            class="step-img"
          />
          <div class="step-name-wrapper">
            <h3 class="step-name">Reschedule Or Change Counselor If Needed</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="our-platform-section">
  <h2 class="section-title title-with-line">Our Platform</h2>
  <hr class="hrDivider" />
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div
        class="col-lg-12 col-xl-6 pe-lg-5 d-flex align-items-center justify-content-center"
      >
        <div class="our-plateform-img-wrapper">
          <img
            src="../../../../assets/images/our-platform-devices.webp"
            alt=""
            class="our-platform-img w-100"
          />
          <div
            class="download-app-wrapper d-md-flex align-items-center text-center text-md-start"
          >
            <img
              src="../../../../assets/images/Google-Play.webp"
              alt=""
              class="store-icon from-left slide-in"
              (click)="comingSoon()"
            />
            <img
              src="../../../../assets/images/App-Store.webp"
              alt=""
              class="store-icon from-right slide-in"
              (click)="comingSoon()"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-xl-6 ps-lg-5 mt-3">
        <div class="row mt-5">
          <div class="col-md-6 col-lg-4 col-xl-6 from-left slide-in">
            <div class="platform-content-wrapper">
              <img
                src="../../../../assets/images/our-platform-secure.webp"
                alt=""
                class="platform-icon"
              />
              <h4 class="platform-content-name">Secure platform</h4>
              <p class="platform-content-text">
                HIPAA compliant & 256-Bit SSL Secure
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-6 from-right slide-in">
            <div class="platform-content-wrapper">
              <img
                src="../../../../assets/images/our-platform-verified.webp"
                alt=""
                class="platform-icon"
              />
              <h4 class="platform-content-name">Verified Experts</h4>
              <p class="platform-content-text">
                Masters or higher degree, solid practice experience & 2 step
                background verification.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-6 from-left slide-in">
            <div class="platform-content-wrapper">
              <img
                src="../../../../assets/images/our-platform-matching.webp"
                alt=""
                class="platform-icon"
              />
              <h4 class="platform-content-name">Nuanced Matching</h4>
              <p class="platform-content-text">
                Counselling therapist is recommended based on your major
                concerns, preferred time, language, gender & age.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-6 from-right slide-in">
            <div class="platform-content-wrapper">
              <img
                src="../../../../assets/images/our-platorm-journal.webp"
                alt=""
                class="platform-icon"
              />
              <h4 class="platform-content-name">Mood Journal</h4>
              <p class="platform-content-text">
                Track your mental well-being by logging in your mood &
                journaling your thoughts.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-6 from-left slide-in">
            <div class="platform-content-wrapper">
              <img
                src="../../../../assets/images/our-platform-happy-place.webp"
                alt=""
                class="platform-icon"
              />
              <h4 class="platform-content-name">Happy Place</h4>
              <p class="platform-content-text">
                Unlimited wellness content blogs, meditation, breathing
                exercises, educational videos & podcasts
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-6 from-right slide-in">
            <div class="platform-content-wrapper">
              <img
                src="../../../../assets/images/our-platform-discussion.webp"
                alt=""
                class="platform-icon"
              />
              <h4 class="platform-content-name">Discussion Forum</h4>
              <p class="platform-content-text">
                Ask anything & initiate discussions with a community of
                like-minded users and psychologists.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="learnMoreHolder">
      <a class="primaryBtn" (click)="scroll(signUpBox)">Sign Up</a>
    </div>
  </div>
</section>
<section class="press-note-section">
  <h2 class="section-title title-with-line">We've been featured!</h2>
  <div class="container">
    <div class="happy-place-slider-wrapper">
      <ngx-slick-carousel
        class="carousel"
        #slickModal2="slick-carousel"
        [config]="testimonialsSlideConfig"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)"
      >
        <div class="happy-place-slider-item press-note-item" ngxSlickItem>
          <a
            href="https://yourstory.com/2020/07/startup-bharat-jaipur-based-felicity-bridging-mental-health/amp"
            target="_blank"
          >
            <img
              src="../../../../assets/images/website/ys.webp"
              class="w-100"
              alt=""
            />
            <div class="place-info">
              <p class="place-tag newsTitle">Press-release</p>
              <h3 class="place-title">
                Jaipur-based Felicity is bridging the gap between demand and
                supply for mental healthcare
              </h3>
            </div>
          </a>
        </div>
        <div class="happy-place-slider-item press-note-item" ngxSlickItem>
          <a
            href="https://theoutreach.in/these-7-startups-from-bharat-started-during-the-pandemic/"
            target="_blank"
          >
            <img
              src="../../../../assets/images/website/outreach.webp"
              class="w-100"
              alt=""
            />
            <div class="place-info">
              <p class="place-tag newsTitle">Press-release</p>
              <h3 class="place-title">
                These 7 startups from Bharat started during the pandemic
              </h3>
            </div>
          </a>
        </div>
        <div class="happy-place-slider-item press-note-item" ngxSlickItem>
          <a
            href="https://startupill.com/52-innovative-psychology-startups-worth-a-follow-in-2021/#Felicity"
            target="_blank"
          >
            <img
              src="../../../../assets/images/website/spill.webp"
              class="w-100"
              alt=""
            />
            <div class="place-info">
              <p class="place-tag newsTitle">Press-release</p>
              <h3 class="place-title">
                52 Innovative Psychology Startups Worth A Follow In 2021
              </h3>
            </div>
          </a>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>
