<bootstrap-alert alertPosition="top-center"></bootstrap-alert>
<div class="top-bar">
  <span class="web-view">
    <div class="web-title-section">
      <i class="fa fa-chevron-left" (click)="backClicked()"></i>
      <p>Order Summary</p>
      <hr>
    </div>
  </span>
</div>
<div class="main-holder-osc">
  <div class="column1">
    <span class="mobile-view">
      <div class="title-section">
        <i class="fa fa-chevron-left" (click)="backClicked()"></i>
        <p>Order Summary</p>
      </div>
    </span>
    <div class="plan-container">
      <p class="font-color-g font-size-b">{{ plan?.name }}</p>
      <p class="font-color-w font-size-s mt-2" *ngIf="plan?.no_of_sessions === 1">{{ plan?.no_of_sessions }} Video Session</p>
      <p class="font-color-w font-size-s" *ngIf="plan?.no_of_sessions !== 1">{{ plan?.no_of_sessions }} Video Sessions</p>
      <p class="font-color-w font-size-s" *ngIf="plan?.weeks_of_chat_access === 1">{{ plan?.weeks_of_chat_access }} Week of chat access</p>
      <p class="font-color-w font-size-s" *ngIf="plan?.weeks_of_chat_access !== 1">{{ plan?.weeks_of_chat_access }} Weeks of chat access</p>
      <p class="font-color-g font-size-s mt-2" *ngIf="regionData?.tag === 'IN'">INR {{plan?.price}}</p>
      <p class="font-color-g font-size-s mt-2" *ngIf="regionData?.tag !== 'IN' && regionData?.tag !== 'GB'">USD {{plan?.usdPrice}}</p>
      <p class="font-color-g font-size-s mt-2" *ngIf="regionData?.tag !== 'IN' && regionData?.tag === 'GB'">GBP {{plan?.gbPrice}}</p>
      <p class="font-color-w font-size-s">({{plan?.avgPrice}} / session)</p>
    </div>
    <div class="expect-container web-view mt-4">
      <h2>What you can expect</h2>
      <div class="info-timeline">
        <ul>
          <li><span class="timeline-circle"><img src="../../../assets/images/icons8-check-50.png" alt=""></span><p>{{expectStatement}}</p></li>
          <li><span class="timeline-circle"><img src="../../../assets/images/icons8-check-50.png" alt=""></span><p>Track your mood, maintain private journal, access unlimited wellness content, discussion forum & more on the Felicity app.</p></li>
          <li><span class="timeline-circle"><img src="../../../assets/images/icons8-check-50.png" alt=""></span><p>Round the clock assistance from the Felicity customer support team to provide you a seamless experience.</p></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="column2">
    <div class="promocode-section">
      <div class="promo-title">
        <p>Promo code (if any)</p>
      </div>
      <div class="promo-input mt-2">
                <input type="text" [formControl]="coupon" placeholder="Enter your code"> <i (click)="checkCoupon()" class="fa fa-chevron-right"></i>
      </div>
    </div>
    <div class="amount-section mt-4">
      <div class="amount-title">
        <p>Amount Payable</p>
      </div>
      <div class="amount-desc mt-2">
        <div class="amount-line">
          <p class="f-left">Total Amount</p>
          <p class="f-right" *ngIf="regionData?.tag === 'IN'">₹ {{plan?.price}}</p>
          <p class="f-right" *ngIf="regionData?.tag !== 'IN' && regionData?.tag !== 'GB'">$ {{plan?.usdPrice}}</p>
          <p class="f-right" *ngIf="regionData?.tag !== 'IN' && regionData?.tag === 'GB'">£ {{plan?.gbPrice}}</p>
        </div>
        <div class="amount-line" *ngIf="finalCoupon">
          <p class="f-left">Coupon Discount</p>
          <p class="f-right coupon-green">₹ {{finalCoupon}}</p>
        </div>
        <div class="amount-line">
          <p class="f-left">Final Amount</p>
          <p class="f-right" *ngIf="regionData?.tag === 'IN'">₹ {{ finalPrice }}</p>
          <p class="f-right" *ngIf="regionData?.tag !== 'IN' && regionData?.tag !== 'GB'">$ {{ finalPrice }}</p>
          <p class="f-right" *ngIf="regionData?.tag !== 'IN' && regionData?.tag === 'GB'">£ {{ finalPrice }}</p>
        </div>
      </div>
    </div>
    <div class="amount-section mt-4">
      <div class="amount-title">
        <p>User details</p>
      </div>
      <div class="details-section">
        <div class="detail-field mt-2">
          <p>Name</p>
          <input type="text" disabled value="{{name}}">
        </div>
        <div class="detail-field mt-2">
          <p>Phone number</p>
          <input type="text" disabled value="+{{dialCode}} {{phone}}">
        </div>
        <div class="detail-field mt-2" *ngIf="regionData?.tag === 'IN'">
          <p>State</p>
          <ng-select
          [items]="states"
          [(ngModel)]="selectedState"
          [closeOnSelect]="true"
        ></ng-select>
        </div>
      </div>
      <span class="web-view">
        <div class="make-payment-web mt-5">
          <button class="thm-2" (click)="initPayment()">Make Payment</button>
        </div>
      </span>
      <!-- <form id="address-form">
        <h3>Address</h3>
        <div id="address-element">
        </div>
      </form> -->
      <!-- <section style="margin-left: auto; margin-right: auto;">
        <form id="payment-form" (submit)="onSubmitEvent($event)">
          <div id="payment-element">
          </div>
          <button id="submit">
            <div class="spinner hidden" id="spinner"></div>
            <span id="button-text">Pay now</span>
          </button>
          <div id="payment-message" class="hidden"></div>
        </form>
      </section> -->
    </div>
  </div>
    <div class="make-payment-section mobile-view">
      <button class="thm-2" (click)="initPayment()">Make Payment</button>
    </div>
</div>

<div class="processingScreen" *ngIf="isPaymentProcessing">
  <div class="overlay-content">
    <div class="spinner-border loadingSpin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p>Please wait while we are processing your payment. Please do not press back or navigate away from this page.</p>
  </div>
</div>
