import { TherapistHolidaysService } from './../therapist-holidays.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { AvailableSlotsDialogService } from './../../available-slots-dialog.service';
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CalendarOptions, FullCalendarComponent } from "@fullcalendar/angular";
import { AvailableSlotsService } from "src/app/therapist/available-slots.service";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { TherapistProfileService } from "../therapist-profile.service";
import { FormControl, FormGroup } from "@angular/forms";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { COMMON_DATA } from "src/app/shared/common";
import { BootstrapAlertService } from "../../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { debounce } from 'lodash';
import * as _ from 'lodash';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { convertTZ } from 'src/app/shared/util/dateFunctions';


@Component({
  selector: "app-view-calendar",
  templateUrl: "./view-calendar.component.html",
  styleUrls: ["./view-calendar.component.css"],
})
export class ViewCalendarComponent implements OnInit, OnDestroy {
  getAvailSlotsNotifier = new Subject();
  therapistId = "";
  events;
  eventsAndSlots;
  slots = [];
  bookedslots = [];
  prevDayslots = [];
  nextDayslots = [];
  selectedDate = null;
  selectedSlot = null;
  holidaysArray = []
  weeklySlots = {}
  startDate = ''
  currentMonthDate = new Date()
  endDate = ''
  isLoader = true;
  hasDataLoader = false
  isMonthChangeLoader = false;
  TimeZone = COMMON_DATA.TimeZone;
  timeZoneForm = new FormGroup({
    TimeZone: new FormControl(""),
  });

  @ViewChild("calendar") calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    eventOrder: 'slotId',
    headerToolbar: {
      center: "",
      start: "title",
      // end: this.isCurrentMonthSelected() ? "next" : "prev next",
      end: "prev next",
    },
    events: [],
    dateClick: this.handleDayClicks.bind(this),
    eventClick: this.handleEventClicks.bind(this),
    datesSet: this.handleDates.bind(this),
    showNonCurrentDates: false,
    eventBackgroundColor: "transparent",
    eventTextColor: "#3788d8",
    eventBorderColor: "transparent",
    selectable: false,
  };

  addSlotForm: FormGroup;
  slotToAdd = null;

  constructor(
    private availableSlotsService: AvailableSlotsService,
    private confirmationService: ConfirmationDialogService,
    private availableslotsDialogService: AvailableSlotsDialogService,
    private profileService: TherapistProfileService,
    private therapistHolidaysService: TherapistHolidaysService,
    private alertService: BootstrapAlertService,
    private sidenavService: SideNavBarService
  ) {
  }
  ngOnDestroy(): void {
    this.getAvailSlotsNotifier.complete();
  }

  ngOnInit() {
    this.initForm();
    this.getAvailSlotsNotifier.pipe(debounceTime(500))
      .subscribe(data => this.handleDates(data));
  }

  getProfileData(_callBack) {
    if (this.timeZoneForm.value.TimeZone === "") {
      this.profileService.getProfile().subscribe((res) => {
        this.isLoader = true;
        if (res["TimeZone"]) {
          this.timeZoneForm.setValue({
            TimeZone: res["TimeZone"],
          });
        } else {
          let timeZone = momentTz.tz.guess();
          let zoneIndex = this.TimeZone.findIndex((tz) => tz.tzCode === timeZone);
          if (zoneIndex !== -1) {
            this.timeZoneForm.setValue({
              TimeZone: this.TimeZone[zoneIndex],
            });
          }
        }
        _callBack();
      })
    } else {
      _callBack();
    }
  }
  doGetHolidays() {
    this.therapistHolidaysService.getHolidays().subscribe((data) => {
      this.holidaysArray = data["holidays"]
      this.doHandleHolidaysData({ holidays: data["holidays"], status: true });
    }, (err) => {
      this.doHandleHolidaysData({ holidays: [], status: false, err });
    });
  }
  doHandleHolidaysData(holidayData) {
    if (holidayData && holidayData.status && holidayData.holidays?.length > 0) {
      holidayData.holidays && holidayData.holidays.map((holidayItem) => {
        this.pushToCalendarHolidayEvent(holidayItem)
      })
    }
    this.isLoader = false;
  }
  doUpdateHolidaysData(holidaysArr){
if(holidaysArr?.length){
  let events = []
      let temp = []
      Object.keys(this.calendarOptions.events).map((item) => {
        temp.push(this.calendarOptions.events[item])
      })
      events = [...temp]
  holidaysArr && holidaysArr.map((holidayDate)=>{
  let index=this.getDatetEventIndex(holidayDate)  
  let formmatedYearDate=moment(holidayDate, "DD_MM_YYYY").format('YYYY-MM-DD')
  if(index!==-1){
    events[index] = {
      id: holidayDate,
      isHoliday: true,
      type: 'Holiday',
      holidayId: holidayDate,
      date: formmatedYearDate,
      start: formmatedYearDate,
      end: formmatedYearDate,
      title: 'Holiday',
      display: 'background',
      color: '#d3d3d3',
      className: ['cursor-not-allowed', 'no-opacity'],
      editable: true,
      textColor: 'gray'
    }
  }
  else{
    events=events.concat({
      id: holidayDate,
      isHoliday: true,
      type: 'Holiday',
      holidayId: holidayDate,
      date:formmatedYearDate,
      start: formmatedYearDate,
      end: formmatedYearDate,
      title: 'Holiday',
      display: 'background',
      color: '#d3d3d3',
      className: ['cursor-not-allowed', 'no-opacity'],
      editable: true,
      textColor: 'gray'
    })
  }
  })
  this.calendarOptions.events =events
}
this.isLoader=false
  }
  getDatetEventIndex(holidayId) {
    // check start date and end date data available 
    let events = this.calendarOptions.events
    let index = -1
    Object.keys(events).map((item) => {
      if (events[item]?.id && events[item]?.id == holidayId) {
        index = parseInt(item)
      }
    })
    if (index) {
      return index
    }
    return index
  }
  isDateClickedEventHoliday(date) {
    // check start date and end date data available 
    let events = this.calendarOptions.events
    let dateF = moment(date, "DD_MM_YYYY")
    let formattedDate = momentTz.tz(dateF, this.getTimeZoneCode()).format("DD_MM_YYYY")
    let isFound = false
    Object.keys(events).map((item) => {
      let currentElement = events[item]
      if (currentElement?.type === 'Holiday') {
        let startDate = moment(currentElement?.start)
        let endDate = moment(currentElement?.end)
        let isBetween = dateF.isBetween(startDate, endDate, 'days')
        let isSame = dateF.isSame(startDate, 'days') || dateF.isSame(endDate, 'days')
        if (isBetween || isSame) {
          isFound = true
          return
        }
        if (currentElement?.id && currentElement?.id == formattedDate) {
          isFound = true
          return
        }
      }
    })
    return isFound
  }
  getTimeZoneCode = () => {
    let timeZoneCode = this.timeZoneForm?.value
      && this.timeZoneForm?.value?.TimeZone
      && this.timeZoneForm?.value?.TimeZone?.tzCode
      ? this.timeZoneForm?.value?.TimeZone?.tzCode
      : Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZoneCode
  }

  pushToCalendarHolidayEvent(data) {
    if (data) {
      let timeZoneCode = this.timeZoneForm?.value
        && this.timeZoneForm?.value?.TimeZone
        && this.timeZoneForm?.value?.TimeZone?.tzCode
        ? this.timeZoneForm?.value?.TimeZone?.tzCode
        : Intl.DateTimeFormat().resolvedOptions().timeZone;
      let startDate = momentTz.tz(data.startDate, timeZoneCode);
      let startDateFormatted = momentTz.tz(data.startDate, timeZoneCode).format("YYYY-MM-DD");
      let endDate = momentTz.tz(data.endDate, timeZoneCode)
      let endDateFormatted = momentTz.tz(data.endDate, timeZoneCode).format("YYYY-MM-DD");
      let formattedDate = momentTz.tz(data.startDate, timeZoneCode).format("YYYY-MM-DD");
      let events = this.calendarOptions.events

      let temp = []
      Object.keys(events).map((item) => {
        temp.push(events[item])
      })
      events = temp
      let dateDiff = endDate.diff(moment(startDate), 'days')
      if (dateDiff > 0) {
        let currentDate = startDate
        for (let i = 0; i <= dateDiff; i++) {
          if (i != 0) {
            currentDate = currentDate.add(1, 'days')
          }
          let index = this.getDatetEventIndex(currentDate.format("DD_MM_YYYY"))
          if (index !== -1) {
            events[index] = {
              id: currentDate.format("DD_MM_YYYY"),
              isHoliday: true,
              type: 'Holiday',
              holidayId: data._id,
              date: currentDate.format("YYYY-MM-DD"),
              start: currentDate.format("YYYY-MM-DD"),
              end: currentDate.format("YYYY-MM-DD"),
              title: 'Holiday',
              display: 'background',
              color: '#d3d3d3',
              className: ['cursor-not-allowed', 'no-opacity'],
              editable: true,
              textColor: 'gray'
            }
          }
          else {
            events = events.concat({
              id: currentDate.format("DD_MM_YYYY"),
              isHoliday: true,
              type: 'Holiday',
              holidayId: data._id,
              date: currentDate.format("YYYY-MM-DD"),
              start: currentDate.format("YYYY-MM-DD"),
              end: currentDate.format("YYYY-MM-DD"),
              title: 'Holiday',
              display: 'background',
              color: '#d3d3d3',
              className: ['cursor-not-allowed', 'no-opacity'],
              textColor: 'gray',
              editable: true,
            });
          }
        }
      }
      else {
        let index = this.getDatetEventIndex(momentTz.tz(data.startDate, timeZoneCode).format("DD_MM_YYYY"))
        if (index !== -1) {
          events[index] = {
            id: momentTz.tz(data.startDate, timeZoneCode).format("DD_MM_YYYY"),
            isHoliday: true,
            type: 'Holiday',
            holidayId: data._id,
            date: startDateFormatted,
            start: startDateFormatted,
            end: endDateFormatted,
            title: 'Holiday',
            display: 'background',
            color: '#d3d3d3',
            className: ['cursor-not-allowed', 'no-opacity'],
            editable: true,
            textColor: 'gray'
          }
        }
        else {
          events = events.concat({
            id: momentTz.tz(data.startDate, timeZoneCode).format("DD_MM_YYYY"),
            isHoliday: true,
            type: 'Holiday',
            holidayId: data._id,
            date: startDateFormatted,
            start: startDateFormatted,
            end: endDateFormatted,
            title: 'Holiday',
            display: 'background',
            color: '#d3d3d3',
            className: ['cursor-not-allowed', 'no-opacity'],
            textColor: 'gray',
            editable: true,
          });
        }
      }
      this.calendarOptions.events = events
    }
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  initForm() {
    this.addSlotForm = new FormGroup({
      slot: new FormControl(null),
    });
  }

  getMonthAvailSlots(args: any, startDate = this.startDate, endDate = this.endDate) {

    let startDateFromatted = this.dateFormatterForQuery(startDate);
    let endDateFormatted = this.dateFormatterForQuery(endDate);
    this.getAvailSlotsNotifier.next(true);
    if (this.hasDataLoader) {
      this.isMonthChangeLoader = true
    }
    this.availableSlotsService
      .getSlotsForTherapistV2(startDateFromatted, endDateFormatted)
      .pipe(takeUntil(this.getAvailSlotsNotifier))
      .subscribe((data) => {
        !this.hasDataLoader ? this.hasDataLoader = true : ''
        this.addSlotsToCalendar(data)
        // this.doGetHolidays()
        // @ts-ignore
        this.weeklySlots = data?.recurringWeeklySlots || {}
        // this.isLoader = false;
        if (this.hasDataLoader) {
          this.isMonthChangeLoader = false
        }
      }, error => {
        this.isLoader = false;
        if (this.hasDataLoader) {
          this.isMonthChangeLoader = false
        }
      })
  }
  onPrevClick() {
    let prevMonth = moment(new Date(this.currentMonthDate)).add(-1, 'month')
    let args = {
      startStr: prevMonth.clone().startOf('month').format(),
      endStr: prevMonth.clone().endOf('month').format()
    }
    this.handleDates(args)
  }
  onNextClick() {
    let nextMonth = moment(new Date(this.currentMonthDate)).add(1, 'month')
    let args = {
      startStr: nextMonth.clone().startOf('month').format(),
      endStr: nextMonth.clone().endOf('month').format()
    }
    this.handleDates(args)
  }
  handleDates(args: any) {
    // if (this.isCurrentMonthSelected()) {
    //   this.calendarOptions.headerToolbar = {
    //     ...this.calendarOptions.headerToolbar,
    //     end: "next"
    //   }
    // }
    // else {
    //   this.calendarOptions.headerToolbar = {
    //     ...this.calendarOptions.headerToolbar,
    //     end: "prev next"
    //   }
    // }
    this.handleDateChange(args)
  }
  handleDateChange(args) {
    this.getProfileData(() => {
      // this.isLoader = false;
      this.events = [];
      this.selectedDate = null;
      this.selectedSlot = null;
      this.startDate = args.startStr
      this.endDate = args.endStr
      if (args.startStr) {
        this.currentMonthDate = new Date(args.startStr)
      }
      if (this.startDate && this.endDate) {
        this.getMonthAvailSlots(args, this.startDate, this.endDate)
      }
    })
  }
  isCurrentMonthSelected() {
    if (this.currentMonthDate.getFullYear() === new Date().getFullYear()) {
      if (this.currentMonthDate.getMonth() === new Date().getMonth()) {
        return true;
      }
      return false
    }
    else {
      return false
    }

  }
  isSlotsAvailale(item, currentDate) {

    let current_am_pm = currentDate.format('A')
    let currentHour = parseInt(currentDate.format('hh'))

    let slotHours = parseInt(item.split(':')[0])
    let isSlotTimeflag = false
    if (current_am_pm.toLowerCase() == 'am' && item.toLowerCase().includes('pm')) {
      isSlotTimeflag = true
      return true
    }
    if (current_am_pm.toLowerCase() == 'am' && item.toLowerCase().includes('am')) {
      if (currentHour === 12 && currentHour > slotHours) {
        isSlotTimeflag = true
        return true
      }
      if (slotHours > currentHour) {
        if (slotHours !== 12) {
          isSlotTimeflag = true
          return true
        }
      }
    }
    if (current_am_pm.toLowerCase() == 'pm' && item.toLowerCase().includes('pm')) {
      if (currentHour === 12 && currentHour > slotHours) {
        isSlotTimeflag = true
        return true
      }

      if (slotHours > currentHour) {
        if (slotHours !== 12) {
          isSlotTimeflag = true
          return true
        }
      }
    }

    return false
  }
  addSlotsToCalendar(data) {
    let now = moment();
    let slotsData = [];
    data["availability"].forEach((availability) => {
      if (availability["slots"]?.length == 0) {
        this.events = this.calendarOptions.events
        let temp = []
        Object.keys(this.events).map((item) => {
          if (this.events[item].dateSlot !== availability.date) {
            temp.push(this.events[item])
          }
        })
        this.calendarOptions.events = temp
      }
      let dateObj = moment(availability.date, "DD_MM_YYYY");
      var currentDateObj = moment.utc().format();
      var currentDate = convertTZ(currentDateObj, this.timeZoneForm.value.TimeZone.tzCode).toString()
      let dateDifference = dateObj.diff(currentDate, 'days')
      let curentDatemoment = moment(currentDate)
      if (dateDifference == 0 && dateObj.format('D') == curentDatemoment.format('D')) {

        availability["slots"].forEach((slot) => {
          let slotData = moment.utc(
            availability["date"] + " " + slot,
            "DD_MM_YYYY HH:mm"
          );
          let slotString = momentTz
            .tz(slotData, this.timeZoneForm.value.TimeZone.tzCode)
            .format("DD_MM_YYYY HH:mm");
          let slotString_24hrs = momentTz
            .tz(slotData, this.timeZoneForm.value.TimeZone.tzCode)
            .format("hh:mm a");
          let slotDateDay = momentTz
            .tz(slotData, this.timeZoneForm.value.TimeZone.tzCode)
          // if this slot is for tommorrow 
          let isDateDifferenceInUTCSlot = curentDatemoment.diff(slotDateDay, 'days') == 0 && slotDateDay.format('D') !== curentDatemoment.format('D')


          let dateNSlot = slotString.split(" ");
          let dateIndex = slotsData.findIndex(
            (date) => String(date.date) === String(dateNSlot[0])
          );

          let isSlotAvailable = isDateDifferenceInUTCSlot ? true : this.isSlotsAvailale(slotString_24hrs, curentDatemoment)


          if (isSlotAvailable) {
            if (Number(dateIndex) === -1) {
              slotsData.push({
                date: dateNSlot[0],
                slots: [dateNSlot[1]],
              });
            } else {
              if (!slotsData[dateIndex].slots) {
                slotsData[dateIndex]["slots"] = []
              }
              slotsData[dateIndex]["slots"].push(dateNSlot[1]);
            }
          }
          else {
          }
        });
      }
      else {
        availability["slots"].forEach((slot) => {
          let slotData = moment.utc(
            availability["date"] + " " + slot,
            "DD_MM_YYYY HH:mm"
          );
          let slotString = momentTz
            .tz(slotData, this.timeZoneForm.value.TimeZone.tzCode)
            .format("DD_MM_YYYY HH:mm");
          let dateNSlot = slotString.split(" ");
          let dateIndex = slotsData.findIndex(
            (date) => String(date.date) === String(dateNSlot[0])
          );
          if (Number(dateIndex) === -1) {
            slotsData.push({
              date: dateNSlot[0],
              slots: [dateNSlot[1]],
            });
          } else {
            if (!slotsData[dateIndex].slots) {
              slotsData[dateIndex]["slots"] = []
            }
            slotsData[dateIndex]["slots"].push(dateNSlot[1]);
          }
        });
      }

      // slots

      // booked slots
      availability["bookedSlots"].forEach((slot) => {
        let slotData = moment.utc(
          availability["date"] + " " + slot,
          "DD_MM_YYYY HH:mm"
        );
        let slotString = momentTz
          .tz(slotData, this.timeZoneForm.value.TimeZone.tzCode)
          .format("DD_MM_YYYY HH:mm");
        let dateNSlot = slotString.split(" ");
        let dateIndex = slotsData.findIndex(
          (date) => String(date.date) === String(dateNSlot[0])
        );
        if (Number(dateIndex) === -1) {
          slotsData.push({
            date: dateNSlot[0],
            bookedSlots: [dateNSlot[1]],
          });
        } else {
          if (!slotsData[dateIndex]?.bookedSlots) {
            slotsData[dateIndex]["bookedSlots"] = []
          }
          slotsData[dateIndex]["bookedSlots"].push(dateNSlot[1]);
        }
      });


    });
    this.eventsAndSlots = slotsData;
    this.events = []
    this.eventsAndSlots.forEach((element) => {
      let date = element.date;
      let dayEvent = moment(date, "DD_MM_YYYY");
      let isExist = false, isBookedSlotExists = false
      let elementSlots = element.slots;
      let bookedSlots = element.bookedSlots;

      Object.keys(this.events).map((item) => {
        if (this.events[item].dateSlot == dayEvent.format('DD_MM_YYYY')) {
          if (this.events[item].type === 'availableSlots') {
            isExist = true
            if (elementSlots?.length > 0) {
              this.events[item].title = elementSlots?.length + ' Slots'
            }
          }
          if (this.events[item].type === 'bookedSlots') {
            isBookedSlotExists = true
            if (bookedSlots?.length > 0) {
              this.events[item].title = bookedSlots?.length + ' Booked'
            }
          }
        }
      })
      if (!isExist && element?.slots?.length > 0 && moment(now).isSameOrBefore(dayEvent, "day")) {
        if (element?.slots) {
          this.events = this.events.concat({
            slotId: moment(dayEvent).format("DD_MM_YYYY") + " a",
            id: moment(dayEvent).format("DD_MM_YYYY"),
            dateSlot: moment(dayEvent).format("DD_MM_YYYY"),
            title: element.slots?.length + ' Slots',
            slotsData: element.slots,
            type: 'availableSlots',
            bookedSlotsData: element?.bookedSlots || [],
            start: dayEvent["_d"],
            allDay: true,
            backgroundColor: '#3788d8',
            borderColor: '#3788d8'
          });
        }
      }
      if (!isBookedSlotExists && element?.bookedSlots?.length > 0 && moment(now).isSameOrBefore(dayEvent, "day")) {
        this.events = this.events.concat({
          slotId: moment(dayEvent).format("DD_MM_YYYY") + " b",
          id: moment(dayEvent).format("DD_MM_YYYY"),
          dateSlot: moment(dayEvent).format("DD_MM_YYYY"),
          title: element.bookedSlots?.length + ' Booked',
          slotsData: element.bookedSlots,
          type: 'bookedSlots',
          start: dayEvent["_d"],
          allDay: true,
          backgroundColor: '#f03737',
          borderColor: '#f03737'
        });
      }
    });
    this.calendarOptions.events = this.events;
    this.doUpdateHolidaysData(data["holidays"])
    
  }
  dateFormatterForQuery(date: string) {
    date = date.substring(0, 10);
    let splitDate = date.split("-");
    let finalDateString = "";
    finalDateString = splitDate[2] + "_" + splitDate[1] + "_" + splitDate[0];
    return finalDateString;
  }

  handleDayClicks(args: any) {
    let userClickedDate = args.date
    let isHoliday = this.isDateClickedEventHoliday(userClickedDate)
    if (!isHoliday && this.isSelectedDateNotPastDate(userClickedDate)) {
      let dateDiff = moment().diff(moment(args.date), 'days')
      if (dateDiff <= 0) {
        this.selectedDate = null;
        this.selectedSlot = null;
        this.findSlotByDay(args.dateStr);

        this.openSlotModal(userClickedDate)
      }
    }
  }
  openSlotModal(userClickedDate) {
    let currMonth = moment(new Date(this.currentMonthDate))
    let startDate = this.startDate || currMonth.clone().startOf('month').format()
    let endDate = this.endDate || currMonth.clone().endOf('month').format()

    this.availableslotsDialogService.open(
      "Manage Slots",
      userClickedDate,
      this.slots,
      this.bookedslots,
      startDate,
      endDate,
      this.prevDayslots,
      this.nextDayslots,
      this.weeklySlots,
      this.timeZoneForm.value.TimeZone
    ).subscribe((res) => {

      //@ts-ignore
      if (res && res.data && res.success) {
        //@ts-ignore
        let nextMonth = moment(new Date(this.currentMonthDate))
        let args = {
          startStr: nextMonth.clone().startOf('month').format(),
          endStr: nextMonth.clone().endOf('month').add(+1, 'days').format()
        }
        this.getMonthAvailSlots(args, args.startStr, args.endStr)
        // this.addSlotsToCalendar(res.data)
      }
    })
  }
  handleEventClicks(args: any) {
    let isHoliday = this.isDateClickedEventHoliday(args.event._def.publicId)
    if (!isHoliday && this.isSelectedDateNotPastDate(args.event._def.publicId)) {
      let date = moment(args.event._def.publicId, "DD_MM_YYYY");
      this.selectedSlot = null;
      this.slots = [];
      this.onlyFindSlotById(args.event._def.publicId);
      this.openSlotModal(date)
    }

  }
  isSelectedDateNotPastDate(date): boolean {
    let currentDate = moment(new Date)
    let selectedDate = moment(date, "DD_MM_YYYY")
    let isSameorAfter = selectedDate.isSameOrAfter(currentDate, 'days')
    if (isSameorAfter) {
      return true
    }
    return false
  }
  findSlotById(id) {
    this.slots = [];
    let eventSlots = this.eventsAndSlots.find((event) => event.date == id);
    this.selectedDate = moment(eventSlots.date, "DD_MM_YYYY");
    this.selectedDate = this.selectedDate._d;
    this.slots = this.slotObject(eventSlots.slots);
  }
  onlyFindSlotById(id) {
    this.bookedslots = []
    this.slots = [];
    let eventSlots = this.eventsAndSlots.find((event) => event.date == id);
    this.slots = eventSlots?.slots && eventSlots?.slots?.length ? this.slotObject(eventSlots.slots) : [];
    this.bookedslots = eventSlots?.bookedSlots && eventSlots?.bookedSlots?.length ? this.slotObject(eventSlots.bookedSlots) : []
  }

  findSlotByDay(dateStr) {
    this.slots = [];
    this.bookedslots = []
    let dateObj = moment(new Date(dateStr)).format('DD_MM_YYYY');
    let prevDateObj = moment(new Date(dateStr)).add(-1, 'days').format('DD_MM_YYYY');
    let nextDateObj = moment(new Date(dateStr)).add(+1, 'days').format('DD_MM_YYYY');
    let filterData = this.eventsAndSlots.filter((item) => {
      if (item.date == dateObj) {
        return item
      }
    })
    let temp = []
    Object.keys(this.events).map((item) => {
      temp.push(this.events[item])
    })

    let prevDatefilterData = temp.filter((item) => {
      if (item.dateSlot == prevDateObj) {
        return item
      }
    })
    let nextDatefilterData = temp.filter((item) => {
      if (item.dateSlot == nextDateObj) {
        return item
      }
    })
    if (filterData.length > 0) {
      this.slots = this.slotObject(filterData[0].slots);
      this.bookedslots = this.slotObject(filterData[0].bookedSlots ? filterData[0].bookedSlots : []);
    }

    if (prevDatefilterData.length > 0) {
      this.prevDayslots = this.slotObject(prevDatefilterData[0].slotsData);
    }
    if (nextDatefilterData.length > 0) {
      this.nextDayslots = this.slotObject(nextDatefilterData[0].slotsData);
    }
  }

  addSlot() {
    let slotIndex = this.slots.findIndex(
      (slot) => slot.slot === this.addSlotForm.value.slot
    );
    if (slotIndex > -1) {
      this.alertService.alert(
        new BootstrapAlert("Slot is already available", "alert-danger")
      );
      return;
    }
    let dateStr = moment(this.selectedDate).format("DD_MM_YYYY");
    let slotDate = moment(
      dateStr + " " + this.addSlotForm.value.slot,
      "DD_MM_YYYY HH:mm"
    );
    let utcDate = moment.utc(slotDate).format("DD_MM_YYYY HH:mm");
    let dateNSlot = utcDate.split(" ");

    this.slotToAdd = {
      slots: dateNSlot[1],
      date: dateNSlot[0],
    };
    this.availableSlotsService.addSlot(this.slotToAdd).subscribe((res) => {
      if (res) {
        let slotData = moment.utc(
          this.slotToAdd.date + " " + this.slotToAdd.slots,
          "DD_MM_YYYY HH:mm"
        );
        let slotString = momentTz
          .tz(slotData, this.timeZoneForm.value.TimeZone.tzCode)
          .format("DD_MM_YYYY HH:mm");
        let dateNSlot = slotString.split(" ");
        let dateIndex = this.eventsAndSlots.findIndex(
          (date) => String(date.date) === String(dateNSlot[0])
        );
        if (Number(dateIndex) === -1) {
          this.eventsAndSlots.push({
            date: dateNSlot[0],
            slots: [dateNSlot[1]],
          });
        } else {
          this.eventsAndSlots[dateIndex]["slots"].push(dateNSlot[1]);
        }
        this.findSlotByDay(
          moment(dateNSlot[0], "DD_MM_YYYY").format("YYYY-MM-DD")
        );
      }
    }, (error) => {
      if (error.status === 403) {
        this.alertService.alert(
          new BootstrapAlert("Another session is already booked at this slot", "alert-danger")
        );
      }
    });
  }

  deleteSlot(slotToDelete) {
    this.confirmationService
      .confirm(
        "Are you sure?",
        "Are you sure you want to delete this slot?",
        "Yes",
        "No"
      )
      .subscribe((res) => {
        if (res) {
          let dateStr = moment(this.selectedDate).format("DD_MM_YYYY");
          let slotDate = moment(
            dateStr + " " + slotToDelete.slot,
            "DD_MM_YYYY HH:mm"
          );
          let utcDate = moment.utc(slotDate).format("DD_MM_YYYY HH:mm");
          let dateNSlot = utcDate.split(" ");
          let slotDeleteObj = {
            slots: dateNSlot[1],
            date: dateNSlot[0],
          };
          this.availableSlotsService
            .deleteSlot(slotDeleteObj)
            .subscribe((res) => {
              if (res) {
                if (this.slots.indexOf(slotToDelete) > -1) {
                  this.slots.splice(this.slots.indexOf(slotToDelete), 1);
                }
              }
            });
        }
      });
  }

  slotObject(slotArray = []) {
    let newSlotArray = [];
    slotArray.forEach((slot) => {
      let slotDateObj = moment(slot, "hh:mm");
      let startString = moment(slotDateObj).format("hh:mm A");
      slotDateObj.add(1, "hour");
      let endString = moment(slotDateObj).format("hh:mm A");
      newSlotArray.push({
        slot: slot,
        startTime: startString,
        endTime: endString,
      });
    });
    return newSlotArray;
  }

  changeTimeZone(event) {
    if (event) {
      this.profileService
        .updateProfile(this.timeZoneForm.value)
        .subscribe((res) => {
          let currMonth = moment(new Date(this.currentMonthDate))
          let startDate = this.startDate || currMonth.clone().startOf('month').format()
          let endDate = this.endDate || currMonth.clone().endOf('month').format()
          let args = {
            startStr: startDate,
            endStr: endDate
          }
          if(event.tzCode){
            localStorage.setItem('therapistTzcode', event.tzCode);
          }
          this.handleDateChange(args)
          this.alertService.alert(
            new BootstrapAlert(
              "Time Zone was updated successfully",
              "alert-success"
            )
          );
        });
    }
  }
}