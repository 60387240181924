import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileValidatorService {
  validImgExt = ['jpg', 'png', 'jpeg','webp']
  maxSize = 4000000

  constructor() { }

  validateImage(name: String){
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (this.validImgExt.includes(ext.toLowerCase())) {
        return true;
    }
    else {
        return false;
    }
  }

  validateSize(fileSize : Number){
    if(fileSize > this.maxSize){
      return false;
    } else {
      return true;
    }
  }
}
