<div class="bodyPageWrap">
    <div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div class="sectionHeading">
              <h2>Program Related FAQ's</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div id="1" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>Is felicity the right platform for my needs?</h4>
  
              <p class="ptagCss">
                Felicity as a platform covers the entire spectrum of mental
                health. Whether you just want to destress and feel better or you
                are seeking help for a specific issue – you have come to the right
                platform.
              </p>
            </div>
          </div>
          <div id="2" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>How do I know which is the right program for me?</h4>
  
              <p class="ptagCss">
                Typically for you to experience a meaningful behavioural change,
                we recommend 36 sessions. However we have broken down our programs
                into smaller units so that you have the option of trying out the
                platform and experience some early improvements in wellbeing.
              </p>
            </div>
          </div>
          <div id="3" class="col-lg-12 col-md-6 col-sm-6">
            <div class="questionWrap">
              <h4>How can I be sure of the talk therapist’s credentials?</h4>
  
              <p class="ptagCss">
                We follow a very rigorous selection process to recruit therapists.
                We only hire people who have full time degrees in counselling
                psychology, clear our demo session requirement and 3 tier
                reference checks. Only the top 0.5% percentile make it to the
                final round. Also once onboarded the therapists are required to
                maintain a continuous 4 plus rating on a 5 point scale to continue
                on the platform.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentHeadWrap">
        <div class="row">
          <div class="col-md-12">
            <div >
              <h3>
                Have More Questions?
                <span class="spanCss">(Access following heads)</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="frequentQuestionBox">
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../subscription-faq">Subscription Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../talk-therapy-faq">Talk therapy Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../account-related-faq">Account Related</a></b>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="questionWrapper">
              <b><a routerLink="../technology-related-faq">Technology Related</a></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 