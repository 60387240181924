<div class="secPadding marginExtra" (click)="closenavbar()">
  <div class="container-fluid">
    <div class="row hideInDesktop">
      <div class="col-md-2">
        <div class="topImageHolder">
          <img
            src="../../../../assets/images/discussion forum.svg"
            class="img-fluid"
            draggable="false"
          />
        </div>
      </div>
      <div class="col-md-8 extraCss">
        <div class="secondaryHeading">
          <h2><b>India’s #1 Mental Health Support Community</b></h2>
          <hr />
          <h3 class="fontSize"><b>Ask, comment and share anonymously.</b></h3>
        </div>
      </div>
      <div class="col-md-2 extraCss">
        <div class="askAnonButton">
          <button
            type="submit"
            (click)="askQuestion()"
            class="buttonTheme1"
            data-toggle="modal"
            data-target="#myModal"
          >
            + &nbsp;&nbsp;Start a new discussion
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-2 extraPadding+ hideMobile">
        <div class="askAnonButton">
          <button
            (click)="askQuestion()"
            class="buttonTheme1"
            data-toggle="modal"
            data-target="#myModal"
          >
            <div class="row startDiscBox">
              <div class="col-md-3 colCss3"><b>+</b></div>
              <div class="col-md-9 colCss9"><b>Start a new discussion</b></div>
            </div>
          </button>
        </div>
        <br />
        <div class="categoryBoxHeader">
          <h3><b>Categories</b></h3>
          <hr />
        </div>
        <div style="padding: 8px">
          <ul class="categoryList">
            <label *ngFor="let category of categories">
              <button
                type="button"
                name="category"
                class="thm-3 extraThm-3"
                (click)="setCategory(category._id)"
              >
                <span>{{ category?.name }}</span>
              </button>
            </label>
          </ul>
        </div>
      </div>
      <div class="col-md-9 col-lg-9 marginCss">
        <div class="exploreBox css">
          <div class="hideDesktop">
            <div class="padding">
              <div class="forumBox">
                <br />
                <div *ngIf="forumData?.userId">
                  <div class="imgBox">
                    <img
                      src="{{
                        forumData?.userId?.imageUrl
                      }}"
                      class="img-fluid"
                      draggable="false"
                    />
                  </div>
                </div>
                <div *ngIf="forumData?.therapistId">
                  <div class="imgBox">
                    <img
                      src="{{
                        forumData?.therapistId?.imageUrl
                      }}"
                      class="img-fluid"
                      draggable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="forumText1">
                <p style="margin-bottom: 0px" *ngIf="forumData?.userId">
                  Posted By
                  <span class="forumTexta">{{ forumData.userId?.name }}</span>
                </p>
              </div>
            </div>
            <div>
              <p class="fontSizeColor">
                {{ forumData?.createdAt | date: "hh:mm a, dd MMMM, yyyy " }}
              </p>
            </div>
          </div>

          <div class="forumBox">
            <div class="forumContentBox">
              <span class="selected" *ngFor="let select of forumData?.tags">
                {{ select.name }}
              </span>
              <div class="forumText">
                <h5 class="h5Tag">
                  <b> {{ forumData?.topic }}</b>
                </h5>
              </div>
              <div
                [innerHTML]="forumData?.description | safe:'html'"
                class="forumText"
                style="display: block"
              ></div>
            </div>
          </div>
          <div class="hrDivTag1">
            <hr class="hrClass" />
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-7">
              <p class="alignLeft lastPart likeShareSection">
                <span
                  (click)="toggleLike(forumData._id)"
                  class="like-btn"
                  [ngClass]="{ 'like-active': forumData?.isLiked }"
                ></span>
                <span class="heartText"
                  >{{ forumData?.likedBy.length }} Like<span *ngIf="forumData?.likedBy.length > 1">s</span> &nbsp;&nbsp;<fa-icon
                    [icon]="faCommentAlt"
                    class="faiconinside"
                  ></fa-icon
                  >&nbsp;&nbsp;{{ forumData?.replies }} Comment<span *ngIf="forumData?.replies > 1">s</span> </span
                >
              </p>
            </div>
            <div class="col-md-6 col-lg-5 padding hideMobile">
              <div class="row">
                <div class="col-md-2 padding">
                  <div class="forumBox">
                    <br />
                    <div *ngIf="forumData?.userId">
                      <div class="imgBox">
                        <img
                          src="{{
                            forumData?.userId?.imageUrl
                          }}"
                          class="img-fluid"
                          draggable="false"
                        />
                      </div>
                    </div>
                    <div *ngIf="forumData?.therapistId">
                      <div class="imgBox">
                        <img
                          src="{{
                            forumData?.therapistId?.imageUrl
                          }}"
                          class="img-fluid"
                          draggable="false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 paddingPost hideMobile">
                  <div class="forumText1">
                    <p style="margin-bottom: 0px" *ngIf="forumData?.userId">
                      Posted By
                      <span class="forumTexta">{{
                        forumData.userId?.name
                      }}</span>
                    </p>
                  </div>
                  <div class="forumText1">
                    <p
                      style="margin-bottom: 0px"
                      *ngIf="forumData?.therapistId"
                    >
                      Posted By
                      <span class="forumTexta">{{
                        forumData.therapistId?.firstname
                      }}</span>
                    </p>
                  </div>
                  <p class="fontSizeColor">
                    {{ forumData?.createdAt | date: "hh:mm a, dd MMMM, yyyy " }}
                  </p>
                </div>
                <div class="col-md-2 paddingShare">
                  <i (click)="copyUrl()" class="fa fa-share-alt fa-2x"></i>
                </div>
              </div>
            </div>
          </div>
          <!-- without login or SignUp -->
          <div
            class="simpleDiv"
            *ngIf="!userIsAuthenticated && !therapistIsAuthenticated"
          >
            <div class="d-flex">
              <div class="statement">
                <p>&nbsp;&nbsp;&nbsp;Login or signup to leave a comment</p>
              </div>
              <div class="loginSignUppadding">
                <span (click)="addCommentCheck()" class="thm-4 extraThm-3"
                  >Login</span
                >&nbsp;
                <span (click)="signUp()" class="thm-2">Sign Up</span>
              </div>
            </div>
          </div>
          <br />
          <!-- comment bar section -->
          <div
            class="formRound"
            *ngIf="userIsAuthenticated || therapistIsAuthenticated"
          >
            <div class="row commentWrap">
              <div class="col-md-1 paddingExtra">
                <div class="forumBox">
                  <br />
                  <div *ngIf="forumData?.userId">
                    <div class="imgBox1">
                      <img
                        src="../../../../assets/images/user.png"
                        class="img-fluid"
                        draggable="false"
                      />
                    </div>
                  </div>
                  <div *ngIf="forumData?.therapistId">
                    <div class="imgBox1">
                      <img
                        src="../../../../assets/images/user.png"
                        class="img-fluid"
                        draggable="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-10 paddingExtra commentBox">
                <form>
                  <textarea
                    style="overflow: hidden"
                    class="form-control"
                    [formControl]="comment"
                    rows="5"
                    placeholder="Add a comment"
                  ></textarea>
                </form>
              </div>
              <div class="col-md-1 paddingExtra leftCss">
                <button class="button1" (click)="addComment()">
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="hrDivTag1">
            <br />
            <hr class="hrClass" />
            <br />
          </div>
          <div class="commentCss">Comments</div>
          <br />
          <div *ngFor="let comment of comments">
            <div class="row commentBoxWrap">
              <div class="col-md-10 commentInfo" style="padding-right: 0px">
                <div class="row">
                  <div class="col-md-1 leftPadding">
                    <div class="forumBox">
                      <br />
                      <div *ngIf="comment?.userId">
                        <div class="imgBox">
                          <img
                            src="{{ imageUrl }}/{{
                              comment?.userId?.imageUrl
                            }}"
                            class="img-fluid"
                            draggable="false"
                          />
                        </div>
                      </div>
                      <div *ngIf="comment?.therapistId">
                        <div class="imgBox">
                          <img
                            src="{{ imageUrl }}/{{
                              comment?.therapistId?.imageUrl
                            }}"
                            class="img-fluid"
                            draggable="false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-11 colmd10">
                    <p class="pTagStyle infoStyling" *ngIf="comment.userId">
                      {{ comment?.userId?.name }} &nbsp;
                      <span class="fontSizeColor">{{
                        comment?.createdAt | date: "hh:mm a, dd MMMM, yyyy "
                      }}</span>
                    </p>
                    <p
                      class="pTagStyle infoStyling"
                      *ngIf="comment.therapistId"
                    >
                      {{ comment?.therapistId?.firstname }} &nbsp;
                      <span class="fontSizeColor">{{
                        comment?.createdAt | date: "hh:mm a, dd MMMM, yyyy "
                      }}</span>
                    </p>
                    <p class="commentDesc" style="margin-bottom: 0px">
                      {{ comment.commentDescription }}
                    </p>
                    <p class="fontSizeColor">
                      {{ comment?.likedBy.length }} Like<span *ngIf="comment?.likedBy.length > 1">s</span>
                      <span (click)="reply(comment?._id)" class="fontSizeColor cursor1"
                        >Reply</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-2 extraPadding">
                <p class="alignLeft lastPart likeShareSection">
                  <span
                    (click)="toggleLikeOnComment(comment?._id)"
                    class="like-btn"
                    [ngClass]="{ 'like-active': comment?.isLiked }"
                  ></span>
                </p>
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
              <div class="replyBox formRound" *ngIf="comment.addReplyToggle">
                <form>
                  <textarea
                    style="overflow: hidden"
                    class="form-control"
                    [formControl]="replyToComment"
                    rows="1"
                    placeholder="Add a reply..."
                  ></textarea>
                </form>
              </div>
              <div class="paddingExtra leftCss" *ngIf="comment.addReplyToggle">
                <button class="button1" (click)="addReply(comment._id)">
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div
              class="viewAllReplies"
              *ngIf="!comment?.showMoreReplies && comment?.replies?.length > 0"
            >
              <p (click)="viewAllReplies(comment._id)">
                View all replies ({{ comment?.replies?.length }})
              </p>
            </div>
            <div *ngIf="comment.showMoreReplies && comment?.replies.length > 0">
              <div *ngFor="let reply of comment.replies">
                <div class="row commentBoxWrap replyCss">
                  <div class="col-md-10 commentInfo" style="padding-right: 0px">
                    <div class="row">
                      <div class="col-md-1 leftPadding">
                        <div class="forumBox">
                          <br />
                          <div *ngIf="reply?.userId">
                            <div class="imgBox">
                              <img
                                src="{{ imageUrl }}/{{
                                  reply?.userId?.imageUrl
                                }}"
                                class="img-fluid"
                                draggable="false"
                              />
                            </div>
                          </div>
                          <div *ngIf="reply?.therapistId">
                            <div class="imgBox">
                              <img
                                src="{{ imageUrl }}/{{
                                  reply?.therapistId?.imageUrl
                                }}"
                                class="img-fluid"
                                draggable="false"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-11 colmd10">
                        <p class="pTagStyle infoStyling" *ngIf="reply.userId">
                          {{ reply?.userId?.name }} &nbsp;
                          <span class="fontSizeColor">{{
                            reply?.createdAt | date: "hh:mm a, dd MMMM, yyyy "
                          }}</span>
                        </p>
                        <p
                          class="pTagStyle infoStyling"
                          *ngIf="reply.therapistId"
                        >
                          {{ reply?.therapistId?.firstname }} &nbsp;
                          <span class="fontSizeColor">{{
                            reply?.createdAt | date: "hh:mm a, dd MMMM, yyyy "
                          }}</span>
                        </p>
                        <p class="commentDesc" style="margin-bottom: 0px">
                          {{ reply.commentDescription }}
                        </p>
                        <p class="fontSizeColor" *ngIf="!reply.likedBy">
                          0 Like &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span
                            (click)="replyOnReply(comment._id, reply._id)"
                            class="fontSizeColor cursor1"
                            >Reply</span
                          >
                        </p>
                        <p class="fontSizeColor" *ngIf="reply.likedBy">
                          {{ reply?.likes }} Like<span *ngIf="reply.likes > 1">s</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span
                            (click)="replyOnReply(comment._id, reply._id)"
                            class="fontSizeColor cursor1"
                            >Reply</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 extraPadding">
                    <p class="alignLeft lastPart likeShareSection">
                      <span
                        (click)="toggleLikeOnComment(reply?._id)"
                        class="like-btn"
                        [ngClass]="{ 'like-active': reply?.isLiked }"
                      ></span>
                    </p>
                  </div>
                  <div class="replyOfReplyBox">
                    <div
                      class="formRound"
                      style="width: 100%"
                      *ngIf="reply?.addReplyToggle"
                    >
                      <form>
                        <textarea
                          style="overflow: hidden"
                          class="form-control"
                          [formControl]="replyToComment"
                          rows="1"
                          placeholder="Add a reply..."
                        ></textarea>
                      </form>
                    </div>
                    <div
                      class="paddingExtra leftCss"
                      *ngIf="reply.addReplyToggle"
                    >
                      <button
                        class="button1"
                        (click)="addReplyToReply(comment._id, reply._id)"
                      >
                        <i class="fa fa-paper-plane" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="viewAllReplies"
              *ngIf="comment.showMoreReplies && comment?.replies.length > 0"
            >
              <p (click)="viewAllReplies(comment._id)">
                Hide all repies ({{ comment?.replies.length }})
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="myModal" *ngIf="!hideModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="formRound">
            <form
              class="extraPadding"
              [formGroup]="discussionForm"
              name="userForm"
            >
              <div class="eachOptionsDivTag">
                <h3>What is it About?</h3>
                <div class="sectionWrap">
                  <input
                    type="text"
                    id="topic"
                    name="topic"
                    formControlName="topic"
                    class="form-control"
                    placeholder="Enter Topic of your choice"
                    required="required"
                    [ngClass]="{
                      'has-error':
                        !discussionForm.controls.topic.valid &&
                        discussionForm.controls.topic.touched,
                      'has-success':
                        discussionForm.controls.topic.valid &&
                        discussionForm.controls.topic.touched
                    }"
                  />
                  <div
                    *ngIf="
                      discussionForm.controls.topic.errors?.required &&
                      discussionForm.controls.topic.touched
                    "
                    class="errorContainer"
                  >
                    <p>Topic is required</p>
                  </div>
                </div>
              </div>
              <div class="eachOptionsDivTag extraCssforcategory">
                <div class="row">
                  <div class="col-md-12">
                    <div class="insideFormDivTags">
                      <h3>Choose Tags</h3>
                      <ng-select
                        formControlName="tags"
                        class="form-control"
                        [multiple]="true"
                        [selectableGroup]="true"
                        [closeOnSelect]="false"
                        [ngClass]="{
                          'has-error':
                            !discussionForm.controls.tags.valid &&
                            discussionForm.controls.tags.touched,
                          'has-success':
                            discussionForm.controls.tags.valid &&
                            discussionForm.controls.tags.touched
                        }"
                      >
                        <ng-option
                          *ngFor="let category of categories"
                          [value]="category._id"
                          >{{ category.name }}</ng-option
                        >
                      </ng-select>
                      <div
                        *ngIf="
                          discussionForm.controls.tags.errors?.required &&
                          discussionForm.controls.tags.touched
                        "
                        class="errorContainer"
                      >
                        <p>Please select minium one tag</p>
                      </div>
                    </div>
                    <!-- <div class="forumContentBox">
                      <span
                        class="selected"
                        *ngFor="
                          let tag of selectedTags;
                          let idx = index;
                          trackBy: indexTracker
                        "
                        >{{ tag.tag }}
                      </span>
                    </div> -->
                  </div>
                </div>
                <div class="eachOptionsDivTag">
                  <h3>Description</h3>
                  <div class="sectionWrap editor">
                    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                    <ngx-editor
                      [editor]="editor"
                      style="text-align: left"
                      [placeholder]="'Type here...'"
                      formControlName="description"
                      [ngClass]="{
                        'has-error':
                          !discussionForm.controls.description.valid &&
                          discussionForm.controls.description.touched,
                        'has-success':
                          discussionForm.controls.description.valid &&
                          discussionForm.controls.description.touched
                      }"
                    ></ngx-editor>
                  </div>
                  <div
                    *ngIf="
                      discussionForm.controls.description.errors?.required &&
                      discussionForm.controls.description.touched
                    "
                    class="errorContainer"
                  >
                    <p>Description is required</p>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <div class="anonymousBox" [formGroup]="discussionForm">
              <input type="checkbox" formControlName="isAnonymous" />
              Post Anonymously
            </div>
            <div class="forumBox">
              <br />
              <div class="imgBox">
                <img
                  src="../../../../assets/images/user.png"
                  class="img-fluid"
                  draggable="false"
                />
              </div>
            </div>
            &nbsp;
            <div class="submitbtnn text-center">
              <button (click)="onSubmit()" class="thm-2">Post</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
