import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import { COMMON_DATA } from "src/app/shared/common";
import { FreeReportService } from '../../free-report.service';
import clevertap from "clevertap-web-sdk";

@Component({
  selector: 'app-child-adhd-test-results',
  templateUrl: './child-adhd-test-results.component.html',
  styleUrls: ['./child-adhd-test-results.component.css']
})
export class ChildAdhdTestResultsComponent implements OnInit {
  itemId;
  report;
  inattentiveness = 0;
  hyperactivity = 0;
  adhdScore = 0;
  title = 'ADHD Analysis';
  type = 'PieChart';
  sex = '';
  grade = ''; 
  age = 0;
  completedBy = ''

  data = [
    ['Inattentive Presentation', this.inattentiveness],
    ['Hyperactive/Impulsive Presentation', this.hyperactivity],
  ];
  options = {
  };
  constructor(private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private actRoute: ActivatedRoute,
    private freeReportService: FreeReportService,
    private alertService: BootstrapAlertService) { }
  answersArray: any[];
  questions = COMMON_DATA.adhd_questions;
  questions1 = COMMON_DATA.adhd_questions1;

  ngOnInit(): void {
    // console.log(this.questions);
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res["report"]
        this.answersArray = Object.values(this.report.reportDetails.answers);
        this.answersArray.forEach(((ans) => {
          this.adhdScore += Number(ans);
        }))
        this.sex = this.report.demographic.sex;
        this.age = this.report.demographic.age;
        this.grade = this.report.demographic.grade;
        this.completedBy = this.report.demographic.completedBy;
        this.inattentiveness = parseInt(this.answersArray[0], 10) + parseInt(this.answersArray[2], 10) + parseInt(this.answersArray[4], 10) + parseInt(this.answersArray[6], 10) + parseInt(this.answersArray[8], 10) + parseInt(this.answersArray[10], 10) + parseInt(this.answersArray[12], 10) + parseInt(this.answersArray[14], 10) + parseInt(this.answersArray[16], 10)
        this.hyperactivity = parseInt(this.answersArray[1], 10) + parseInt(this.answersArray[3], 10) + parseInt(this.answersArray[5], 10) + parseInt(this.answersArray[7], 10) + parseInt(this.answersArray[9], 10) + parseInt(this.answersArray[11], 10) + parseInt(this.answersArray[13], 10) + parseInt(this.answersArray[15], 10) + parseInt(this.answersArray[17], 10)
        this.data = [
          ['Inattentive Presentation', this.inattentiveness],
          ['Hyperactive/Impulsive Presentation', this.hyperactivity],
        ];
        if (clevertap) {
          clevertap.event.push("Free Report Ended", {
            "Report Name": "Child ADHD",
            "Score": this.report.reportDetails.score
          })
        }
      });
    });
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  bookConsultation() {

  }

  reportData() {
    let reqObj = {
      id: this.itemId,
      reportType: 'child-adult'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if (res) {
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
}
