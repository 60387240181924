import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wellness-hub',
  templateUrl: './wellness-hub.component.html',
  styleUrls: ['./wellness-hub.component.css']
})
export class WellnessHubComponent implements OnInit {
  activeTab = 'library';
  constructor() { }

  ngOnInit(): void {
  }

  onChangeTab(tab: string) {
    this.activeTab = tab;
  }

}
