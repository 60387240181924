import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from "../../free-report.service";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-narcissism-test-results',
  templateUrl: './narcissism-test-results.component.html',
  styleUrls: ['./narcissism-test-results.component.css']
})
export class NarcissismTestResultsComponent implements OnInit {
  narcissismPhrase = "";
  report;
  narcissismScore;
  itemId;
  answersArray = []
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) { 
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res["report"]
        this.answersArray = Object.values(this.report.reportDetails.answers);
        this.narcissismScore = res["report"]["reportDetails"]["score"]/this.answersArray.length;
        this.narcissismScore = parseFloat(this.narcissismScore).toFixed(2)
        if (clevertap) {
          clevertap.event.push("Free Report Ended", {
            "Report Name": "Narcissism",
            "Score": this.narcissismScore
          })
        }
        this.narcissismfinalcal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id: this.itemId,
      reportType: 'narcissism'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if (res) {
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  narcissismfinalcal() {
    if (this.narcissismScore >= 0 && this.narcissismScore <= 0.3) {
      this.narcissismPhrase = "are low in narcissism.";
    } 
    else if (this.narcissismScore > 0.3 && this.narcissismScore <= 0.5 ) {
      this.narcissismPhrase = "fall within the normal range of narcissism..";
    } 
    else if (this.narcissismScore > 0.5 && this.narcissismScore < 0.8 ) {
      this.narcissismPhrase = "have moderate narcissistic tendencies.";
    } 
    else if (this.narcissismScore > 0.8) {
      this.narcissismPhrase = "are high in narcissism.";
    } 
    
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
