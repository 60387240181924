import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { environment } from "src/environments/environment";
import { BookingService } from "../booking.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';



@Component({
  selector: "app-sessions",
  templateUrl: "./sessions.component.html",
  styleUrls: ["./sessions.component.css"],
})
export class SessionsComponent implements OnInit {
  textoverbanner = "";
  items = [];
  pageNo = 1;
  pages = [1];
  showNext = false;
  totalCount = 0;
  selectedCategory = "SCHEDULED";
  isLoading = true;
  isShimmerLoading = true;
  skip = 0;
  perPage = 6;
  imageUrl = environment.apiUrl;
  userTimezone = 'Asia/Calcutta';

  constructor(
    private sessionsService: BookingService,
    private confirmationService: ConfirmationDialogService,
    private alertService: BootstrapAlertService,
    private router: Router
  ) { }
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    this.setCategory(this.selectedCategory);
    if (localStorage.getItem("therapistTzcode")) {
      this.userTimezone = localStorage.getItem("therapistTzcode")
    }
  }
  redirectTo(id) {
    this.router.navigateByUrl(
      "therapist-dashboard/join-session?sessionId=" + id
    );
  }
  redirectToV2(id) {
    this.router.navigateByUrl("therapist-dashboard/session-join?sessionId=" + id);
  }
  redirectToV3(id) {
    this.router.navigateByUrl("therapist-dashboard/session-join-call-therapist?sessionId=" + id);
  }
  cancelScheduled() {
    this.confirmationService
      .confirm(
        "You're not allowed to cancel",
        "Please contact the admin / Raise a ticket in helpdesk for any cancellation / Rescheduling requests.",
        "Take me to helpdesk",
        "Close"
      )
      .subscribe((res) => {
        if (res) {
          this.router.navigateByUrl("/therapist-dashboard/helpdesk");
        }
      });
  }
  checkPage() {
    if (this.pageNo === this.pages[this.pages.length - 1]) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }
  }

  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;
    // this.isShimmerLoading = true;
    this.pageNo = this.pageNo + 1;
    this.getSessions(false);
    this.checkPage();
  }

  setPage(pageNo) {
    this.pageNo = pageNo;
    this.getSessions(false);
  }

  getSessions(isCategoryChange) {
    this.skip = (this.pageNo - 1) * this.perPage;
    this.sessionsService
      .sessionPagin(this.selectedCategory, this.skip, this.perPage)
      .subscribe((data) => {
        if (isCategoryChange) {
          this.items = [];
        }
        let processedData = [];
        console.log(data['result']);
        data['result'].forEach((item) => {
          let newObj = { ...item }
          let startDateObj = momentTz.tz(newObj['startDate'], this.userTimezone)
          let endDateObj = momentTz.tz(newObj['endDate'], this.userTimezone)
          newObj['sessionDay'] = moment(startDateObj).format('dddd')
          newObj['sessionDate'] = moment(startDateObj).format('DD MMM yyyy')
          newObj['sessionStartTime'] = moment(startDateObj).format('hh:mm a')
          newObj['sessionEndTime'] = moment(endDateObj).format('hh:mm a')
          processedData.push(newObj)
        })
        this.items = this.items.concat(processedData);
        this.totalCount = data["totalCount"];
        if (this.selectedCategory == "SCHEDULED") {
          this.textoverbanner =
            "You have " + this.totalCount + " upcoming sessions";
        }
        if (this.selectedCategory == "COMPLETED") {
          this.textoverbanner =
            "You have completed " +
            this.totalCount +
            " sessions so far";
        }
        let pageCount = Math.ceil(this.totalCount / 6);
        if (pageCount != this.pages.length) {
          for (let i = 0; i < pageCount - 1; i++) {
            if (this.pages.indexOf(i + 2)) {
              this.pages.push(i + 2);
            }
          }
        }
        this.checkPage();
        this.isLoading = false;
        this.isShimmerLoading = false;
      }, (err) => {
        this.isShimmerLoading = false;

      });
  }

  setCategory(category) {
    this.selectedCategory = category;
    this.getSessions(true);
  }
}
