import { Component, OnInit } from '@angular/core';
import { ClientNotesService } from '../client-notes.service';

@Component({
  selector: 'app-client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.css']
})
export class ClientNotesComponent implements OnInit {
  items =[];
  isShimmerLoading = true;
  constructor(private notesService: ClientNotesService) { }

  ngOnInit(): void {
    this.notesService.getNotesPagin().subscribe((res) => {
      this.items = res['populatedNotesFolder']
      this.isShimmerLoading = false;
    })
  }

}
