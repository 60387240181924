<div class="headWRaprp">
  <div class="row justify-content-between headRwo">
    <div class="col-6">
      <div class="barTglbtn" (click)="openSideNav()">
        <i class="fa fa-bars fa-2x"></i>
      </div>
    </div>
    <div class="col-6">
      <div class="headmneuwRp">
        <ul>
          <li>
            <a routerLink="/therapist-dashboard/notifications" class="belnoti"
              ><img
                src="../../../assets/images/bell.png"
                draggable="false"
                class="img-fluid animated infinite tada"
              />
              <span class="bellCircle">{{newNotifications}}</span></a
            >
          </li>
          <li>
            <a (click)="logOut()" style="cursor: pointer"
              ><img
                src="../../../assets/images/logout.svg"
                draggable="false"
                class="img-fluid"
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
