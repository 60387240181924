import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "./auth.service";
@Injectable({
  providedIn: 'root'
})
export class UserAuthGuard implements CanActivate {
  constructor ( private authService: AuthService, private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.autoAuthUser();
    const isAuth = this.authService.getIsAuth();
    
    if(!isAuth){
      this.router.navigate(['/login'], { queryParams : { authRed : state.url } })
    }
    return isAuth;
  }
  
}
