<div class="sessionWrap sectionPadding">
  <div class="container">
    <div class="sectionHeading">
      <h2>Congratulations! Your session has been booked</h2>
      <hr />
    </div>
    <div class="sessionBoxWrap">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-6 col-sm-8">
          <div *ngIf="userDetailShimmer; else nouserDetailShimmer">
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '12px',
                height: '320px',
                width: '320px',
                display: 'flex !important',
                'margin-top': '3px',
                'margin-right': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <ng-template #nouserDetailShimmer>
            <div class="sessionContentWrap">
              <div class="consultImage">
                <!-- <img
                src="/images/{{ userData.therapistId.image }}"
                draggable="false"
                class="img-fluid"
                alt=""
              /> -->
                <img
                  src="../../../assets/images/user.png"
                  draggable="false"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <h3>
                {{ sessionData?.therapistId.firstname }}
                {{ sessionData?.therapistId.lastname }}
              </h3>
              <p><b>Session No. </b> - {{ sessionData?.SessionNo }}</p>
              <p>
                <b>{{ sessionData?.startDateDay }} </b> -
                {{ sessionData?.startDateUtc }} -
                {{ sessionData?.timeZoneDate }}
              </p>
              <p>
                <b>Time </b> - {{ sessionData?.startDateTime }} to
                {{ sessionData?.endDateTime }}
              </p>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="sessionNewWrap" style="margin-top: 30px">
        <div *ngIf="buttonShimmer; else nobuttonShimmer">
          <div
            style="display: flex; flex-direction: row; justify-content: center"
          >
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '12px',
                height: '60px',
                width: '200px',
                display: 'flex !important',
                'margin-top': '3px',
                'margin-right': '10px'
              }"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
              appearance="line"
              [theme]="{
                'border-radius': '12px',
                height: '60px',
                width: '200px',
                display: 'flex !important',
                'margin-top': '3px',
                'margin-right': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
        <ng-template #nobuttonShimmer>
          <a routerLink="../../user-dashboard" class="thm-2"
            >Go to Your Dashboard</a
          >&nbsp;
          <button (click)="secheduleanother()" class="thm-2">
            Schedule Another
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <br />
</div>
