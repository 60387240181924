import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { browser } from "protractor";
import { AuthService } from "src/app/auth/auth.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import { COMMON_DATA } from "src/app/shared/common";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistAuthService } from "src/app/therapist-auth/therapist-auth.service";
import { environment } from "src/environments/environment";
import { BlogService } from "../../blog.service";
import { DiscussionForumService } from "../../discussion-forum.service";
import { SubscriptionService } from "../../subscription.service";
import clevertap from "clevertap-web-sdk";
import { debounce } from 'lodash';


@Component({
  selector: "app-blog-view",
  templateUrl: "./blog-view.component.html",
  styleUrls: ["./blog-view.component.css"],
})
export class BlogViewComponent implements OnInit {
  categories = [];
  itemId = "";
  currentBlog = {
    title: "Gaslighting: What is it?",
    author: "Team Felicity",
    timeInMinutes: 3,
  };
  blogImageUrl = '';
  mentalHealthTests = COMMON_DATA.mentalHealthTests;
  category;
  frequentSearches = COMMON_DATA.frequentSearches;
  isClapped = false;
  claps = 0;
  previousPost = "Toxic Relationship Take A Massive Toll On You";
  nextPost = "The Different Psychotherapy Approaches";
  comment = new FormControl("");
  comments = 0;
  latestBlogs = [
  ];
  blogData = null;
  imageUrl = environment.apiUrl;
  currentUrl = encodeURIComponent(window.location.href);
  freeReports = [
    {
      name : 'Anxiety',
      image : '/assets/images/anxiety-test.jpg',
      route : 'anxiety-test'
    },
    {
      name: 'Depression',
      image : '/assets/images/depression-test.jpg',
      route : 'depression-test'
    },
    {
      name: 'ACE',
      image : '/assets/images/ACE.jpg',
      route : 'adverse-childhood-experiences-questionnaire'
    },
    {
      name : 'Anger',
      image : '/assets/images/Anger.jpg',
      route : 'angry-test'
    },
    // {
    //   name : 'Obsessive Compulsive Disorder Test',
    //   image : '/assets/images/OCD.jpg',
    //   route : 'obsessive-compulsive-disorder-test'
    // },
    // {
    //   name : 'Post Traumatic Stress Disorder (PTSD)',
    //   image : '/assets/images/PTSD Test.jpg',
    //   route : 'post-traumatic-stress-disorder-test'
    // }
  ]
  query = new FormControl("");

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private discussionForumService: DiscussionForumService,
    private sidenavService: SideNavBarService,
    private subscriptionService: SubscriptionService,
    private alertService: BootstrapAlertService,
    private blogService: BlogService,
    private authService : AuthService,
    private therapistAuthService: TherapistAuthService,
    private metaSeo : SeoMetaService

  ) {
    this.onSearch = debounce(this.onSearch, 500);
  }
  subsForm = new FormGroup({
    email: new FormControl("", Validators.required),
  });
  ngOnInit(): void {
    this.category = new FormControl("");
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.blogService.getBlogBySlug(this.itemId).subscribe((res) => {
        this.blogData = res;
        this.blogData['shareUrl'] = encodeURIComponent(this.blogData.imageURL)
        this.metaSeo.updateTitle(this.blogData?.metaTitle + " | Felicity");
        this.metaSeo.updateMetaInfo(this.blogData?.metaDescription, this.blogData?.metaKeywords, this.blogData?.metaTitle + " | Felicity")
        this.claps = this.blogData.likes
        if(clevertap){
          clevertap.event.push("Blog Viewed", {
            "Title" : this.blogData.title
          })
        }
      })
    });
    this.discussionForumService.getCategories().subscribe((data) => {
      this.categories = data["category"];
    });
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  onSearch(category : string = '') {
    this.router.navigate(['../all-blogs'], { queryParams : { search : this.query.value, category : category } })
  }


  changeCategory() {
  }

  onClap() {
    if(this.isClapped){
      this.isClapped = false;
      setTimeout(() => {
        this.isClapped = true;
      }, 10)
    } else {
      this.isClapped = true;
    }
    this.claps++;
    this.blogService.addClap(this.itemId).subscribe((res) => {
    })
  }
  getFormControls() {
    return this.subsForm.controls;
  }

  onSubmit() {
    this.subscriptionService
      .subscribe(this.subsForm.value.email)
      .subscribe((res) => {
        if (res) {
          clevertap.event.push("Email Subscribed")
          this.alertService.alert(
            new BootstrapAlert(
              "You have successfully subscribed!",
              "alert-success"
            )
          );
          this.subsForm.reset();
        }
      });
  }

  copyUrl(){    
  let selBox = document.createElement("textarea");
  selBox.style.position = "fixed";
  selBox.style.left = "0";
  selBox.style.top = "0";
  selBox.style.opacity = "0";
  selBox.value = window.location.href;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand("copy");
  document.body.removeChild(selBox);
  this.alertService.alert(
    new BootstrapAlert(
      "Successfully copied url to clipboard!",
      "alert-success"
    )
  );
  }
}
