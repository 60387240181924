<div (click)="closenavbar()">
  <div class="container-fluid" *ngIf="firstPart">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <br />
            <div class="sectionHeading">
              <h1><b>Obsessive Compulsive Disorder Assessment</b></h1>
              <hr />
            </div>
          </div>
          <div class="col-md-3 paddingCss">
            <div class="divTagCss">
              <img class="img-fluid imageCss" src="../../../assets/images/ocd-report-image.webp" />
            </div>
          </div>
          <div class="col-md-8 paddingCss">
            <div class="textInside">
              <p>
                Welcome to Felicity's online mental health test. It's free,
                quick, anonymous & 100% confidential.
                <br />
              </p>
              <br>
              <h2 class="htag"><b>Introduction</b></h2>
              <p>Yale-Brown Obsessive-Compulsive Scale (Y-BOCS) is a test to rate the severity of obsessive–compulsive
                disorder (OCD) symptoms. Developed by Goodman and colleagues in 1989, this scale has been widely used in
                various studies and research papers. The scale has 10 questions to be answered on a 5 point rating
                scale. It usually takes 5 to 8 minutes to answer all of the questions.</p>
              <p>This scale, which measures obsessions separately from compulsions, specifically measures the severity
                of symptoms of obsessive–compulsive disorder without being biased towards or against the type of content
                the obsessions or compulsions might present.</p>
            </div>
            <br />
            <div class="textInside">
              <h2 class="htag"><b>Instructions</b></h2>
              <p>In this self-report questionnaire, there are no right or wrong answers. You have to read each question carefully and answer them accurately according to how you feel. Answer the questions keeping in mind to what extent the statement describes you best. Please answer each item by clicking on the score that applies to you best.</p>
              <p>
                The test is divided into 2 parts containing 5 questions each.
              </p>
              <b><u>Questions 1 to 5 are about your obsessive thoughts</u></b
              ><br/>
              <strong>Obsession:</strong> These are unwanted ideas, images or impulses that intrude on
              thinking against your wishes and efforts to resist them. <br />
              They usually involve themes of harm, risk and danger. Common
              obsessions are excessive fears of contamination; recurring doubts
              about danger, extreme concern with order, symmetry, or exactness;
              fear of losing important things.<br />
              Please answer each item by clicking on the score that applies to
              you best.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="submitButtonClass text-center">
      <button class="thm-2" (click)="ShowHide()">Next</button>
    </div>
    <br />
  </div>
  <div class="container-fluid" *ngIf="secondPart">
    <form [formGroup]="freeReportForm" name="freeReportForm">
      <ul class="unstyled main-body">
        <div class="question-section">
          <h5>
            <b>{{ questions[currentIndex].questionHeading }}</b>
          </h5>
          <h5>{{ questions[currentIndex].questionText }}</h5>
          <ul class="unstyled options_list" id="question_{{ questions[currentIndex].qno }}">
            <div *ngFor="let answer of answers[currentIndex]; let idx1 = index" class="align-options">
              <li>
                <label class="labelCss">
                  <input type="radio" class="align-input" value="{{ idx1 }}" name="{{ questions[currentIndex].label }}"
                    formControlName="{{ questions[currentIndex].label }}" />
                  &nbsp;<span class="options_listing">{{
                    answer.answerText
                    }}</span>
                </label>
              </li>
            </div>
          </ul>
        </div>
        <div class="form-btn">
          <button *ngIf="currentIndex == 0" type="button" class="btn-back" (click)="instruction1()">
            BACK
          </button>
          <button *ngIf="currentIndex > 0" type="button" class="btn-back" (click)="previousPage()">
            BACK
          </button>
          <button *ngIf="questions.length - 1 != currentIndex" class="btn btn-submit thm-2" (click)="currentIndexInc()">
            Next
          </button>
          <button *ngIf="questions.length - 1 == currentIndex" class="btn btn-submit" type="button"
            (click)="ShowHide1()" [disabled]="!freeReportForm?.valid">
            Next
          </button>
          <div class="container-progress">
            <div [hidden]="!(progressPercentage > 0)" class="progressBarCls"
              [ngStyle]="{ 'width' : progressPercentage + '%' }"></div>
          </div>
        </div>
      </ul>
    </form>
  </div>
  <div class="container-fluid" *ngIf="thirdPart">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <br />
            <div class="sectionHeading">
              <h1><b>Obsessive Compulsive Disorder Assessment</b></h1>
              <hr />
            </div>
          </div>
          <div class="col-md-3 paddingCss">
            <div class="divTagCss">
              <img class="img-fluid imageCss" src="../../../assets/images/OCD.jpg" />
            </div>
          </div>
          <div class="col-md-8 paddingCss">
            <div class="textInside">
              <h2 class="htag"><b>Instructions</b></h2>
              <b><u>The next 5 questions are about your compulsive behaviors.</u></b><br />
              Compulsions are urges that people have to do something to lessen
              feelings of anxiety or other discomfort. <br />
              Often, they do repetitive, purposeful, intentional behaviours
              called rituals. The behavior itself may seem appropriate but it
              becomes a ritual when done to excess. Washing, checking,
              repeating, straightening, hoarding and many other behaviours can
              be rituals.
              <br />
              Please answer each item by clicking on the score that applies to
              you best.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="submitButtonClass text-center">
      <button type="button" class="btn-back thm-2" (click)="previousPage1()">
        BACK
      </button>
      <button class="btn btn-submit thm-2" type="button" (click)="nextTo4()">
        Next
      </button>
    </div>
    <br />
  </div>
  <div class="container-fluid" *ngIf="fourthPart">
    <form [formGroup]="freeReportForm1" name="freeReportForm1">
      <ul class="unstyled main-body">
        <div class="question-section">
          <h5>
            <b>{{ questions1[currentIndex].questionHeading }}</b>
          </h5>
          <h5>{{ questions1[currentIndex].questionText }}</h5>
          <ul class="unstyled options_list" id="question_{{ questions1[currentIndex].qno }}">
            <div *ngFor="let answer of answers1[currentIndex]; let idx1 = index" class="align-options">
              <li>
                <label class="labelCss">
                  <input type="radio" class="align-input" value="{{ idx1 }}" name="{{ questions1[currentIndex].label }}"
                    formControlName="{{ questions1[currentIndex].label }}" />
                  &nbsp;<span class="options_listing">{{
                    answer.answerText
                    }}</span>
                </label>
              </li>
            </div>
          </ul>
        </div>
        <div class="form-btn">
          <button *ngIf="currentIndex == 0" type="button" class="btn-back" (click)="instruction()">
            BACK
          </button>
          <button *ngIf="currentIndex > 0" type="button" class="btn-back" (click)="previousPage()">
            BACK
          </button>
          <button *ngIf="questions.length - 1 != currentIndex" class="btn btn-submit thm-2" (click)="currentIndexInc()">
            Next
          </button>
          <button *ngIf="questions.length - 1 == currentIndex" class="btn btn-submit" type="button" data-toggle="modal"
            data-target="#myModal" (click)="showModal()" [disabled]="!freeReportForm1?.valid">
            Submit
          </button>
          <div class="container-progress">
            <div [hidden]="!(progressPercentage1 > 0)" class="progressBarCls"
              [ngStyle]="{ 'width' : progressPercentage1 + '%' }"></div>
          </div>
        </div>
      </ul>
    </form>
  </div>
  <div class="modal fade" id="myModal" *ngIf="!hideModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Free Report</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <p>
            Great! You're almost done. Please enter these details to generate
            report.
          </p>
          <form [formGroup]="userForm2" name="userForm2">
            <input id="name" formControlName="fullname" name="fullname" placeholder="Name" [ngClass]="{
                'has-error':
                  !userForm2.controls.fullname.valid &&
                  userForm2.controls.fullname.touched,
                'has-success':
                  userForm2.controls.fullname.valid &&
                  userForm2.controls.fullname.touched
              }" />
            <input type="email" id="email" formControlName="email" name="email" placeholder="xyz@gmail.com" [ngClass]="{
                'has-error':
                  !userForm2.controls.email.valid &&
                  userForm2.controls.email.touched,
                'has-success':
                  userForm2.controls.email.valid &&
                  userForm2.controls.email.touched
              }" />
            <div class="phoneHolder">
              <input type="tel" id="phone" formControlName="phone" name="phone" class="form-control phoneControl"
                [ngClass]="{
                  'has-error':
                    userForm2.controls.phone.errors?.invalidPhone ||
                    (userForm2.controls.phone.errors?.required &&
                      userForm2.controls.phone.touched)
                }" ng2TelInput (hasError)="checkError($event)" (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                  initialCountry: userCountryCode.countryCode
                }" />
            </div>
            <div class="errorContainer" *ngIf="userForm2.controls.phone.errors?.invalidPhone">
              <p>Please enter a valid phone number.</p>
            </div>
          </form>
          <div class="modal-footer">
            <button type="button" class="btn-submit" value="Submit" (click)="onSubmit()" data-dismiss="modal"
              [disabled]="!userForm2?.valid">
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>