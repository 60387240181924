import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-child-counselling",
  templateUrl: "./child-counselling.component.html",
  styleUrls: ["./child-counselling.component.css"],
})
export class ChildCounsellingComponent implements OnInit {
  isLoggedIn = false
  constructor(private sidenavService: SideNavBarService,
    private authService: AuthService,
    private router: Router) {}

  ngOnInit(): void {
    this.isLoggedIn = this.authService.getIsAuth();
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  buyNow(planKey) {
    if (this.isLoggedIn) {
      this.router.navigate(["../order-summary/" + planKey]);
    } else {
      this.router.navigateByUrl("/sign-up?plan=" + planKey);
    }
  }
}
