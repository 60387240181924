import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import { ProfileService } from '../profile.service';
import { BootstrapAlertService } from "../../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.css']
})
export class CompleteProfileComponent implements OnInit {
  profileForm = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl({value:"", disabled: true}, Validators.required),
    phone : new FormControl("",[Validators.required])
  })
  planForRedirection: any;
  therapistRedirection: any;
  redirection: any;
  userCountryCode;

  constructor(
    private sidenavService: SideNavBarService,
    private profileService: ProfileService,
    private router: Router,
    private actRoute : ActivatedRoute,
    private alertService: BootstrapAlertService,
  ) { }

  ngOnInit(): void {
    this.profileService.getProfile().subscribe((profile) => {
      this.profileForm.setValue({
        name : profile.name ? profile.name : "",
        email : profile.email ? profile.email : "",
        phone : profile.phone ? profile.phone : ""
      })
      if (profile["countryCode"]) {
        this.userCountryCode = profile["countryCode"];
      }
    })
    this.actRoute.queryParams.subscribe((params) => {
      this.planForRedirection = params["plan"];
      this.therapistRedirection = params["therapist"];
      this.redirection = params["redirect"]
    });
  }

  checkError(valid) {
    if(!valid){
      this.profileForm.controls["phone"].setErrors({ 'invalidPhone' : true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.profileForm.patchValue({
      phone: phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  onSubmit(){
    if(this.profileForm.invalid){
      if(this.profileForm.controls.phone.status === "INVALID"){
        this.alertService.alert(
          new BootstrapAlert("Please enter a valid phone number", "alert-danger")
        )
        return;
      }
      return;
    }
    this.profileService.updateProfile({...this.profileForm.value, countryCode : this.userCountryCode}).subscribe((res) => {
      if (this.planForRedirection != null) {
        this.router.navigate(["/order-summary/" + this.planForRedirection]);
      } else if (this.therapistRedirection != null) {
        this.router.navigate([
          "/view-counsellor-calendar/" + this.therapistRedirection,
        ]);
      } else if (this.redirection != null) {
        this.router.navigate([this.redirection]);
      } else {
        this.router.navigate(["/user-dashboard/dashboard"]);
      }
    })
  }

}
