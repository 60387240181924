<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container mt-3">
    <p class="disclaimerTxt">
      <strong> <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for screening and
      monitoring symptom severity
      and cannot replace a clinical assessment and diagnosis. You are encouraged
      to share these results with a mental health professional for a better
      understanding.
    </p>
    <h3 class="mt-3">
      Getting to know yourself is the first step towards a mentally healthier
      life.
    </h3>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user mt-3">
      <h3>Congratulations on taking that first step!</h3>
      <p class="mt-3"><strong>Your Score:</strong></p>
      <p>Self-Esteem Assessment: {{reseScore}} </p>
      <p class="mt-2"><strong>Your Result:</strong></p>
      <p>
        <span>{{reseResult}}
          <span class="scrollTxt" (click)="scrollToElem('target')">Detailed Analysis</span></span>
      </p>
      <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
    </div>
  </div>

  <div class="container graph-section mt-3">
    <div>
      <div>
        <p class="mb-3" *ngIf="reseScore >= 0 && reseScore <= 15">
          Seeing the report can be overwhelming, but Felicity has got you covered. We offer a variety of services that
          you can access at any time, anywhere.
        </p>
        <p class="mb-3" *ngIf="reseScore >= 15 && reseScore <= 25">
          Your result lies within a normal range, which is optimum for maintaining a healthy lifestyle.
        </p>
        <p class="mb-3" *ngIf="reseScore >= 26 && reseScore <= 30">
          Your results may not be an imminent sign of concern, but read on to find out a more detailed analysis of your
          score as well as a few suggestions from Felicity that you can always use to improve your lifestyle.
        </p>
        <h5 class="mb-3">
          Understanding Your Self-Esteem Assessment
        </h5>
        <div class="container graph-section mt-3">
          <div class="graph-right">
            <img src="../../../assets/images/free-report-graphs/self-esteem.png" />
          </div>
        </div>
      </div>
      <!-- <h5>Understanding Your self-esteem assessment (Highlight the range of score the client lies in) 
</h5>
      <div class="graph-right">
        <img src="../../../assets/images/relationship-report-graph.webp" />
      </div> -->
    </div>
  </div>

  <div class="container scores-section mb-4">
    <div class="about-scores report-user">
      <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
      <p class="mb-3">
        The possible range of scores is 10 to 40, where 10 indicates least
        self-esteem and 40 indicates high self-esteem.
      </p>
      <p class="para_scores mb-3" id="target" *ngIf="reseScore >= 26 && reseScore <= 30">
        A score ranging from 26 to 30 indicates high levels of self-esteem. This
        state has to be dealt with extremely carefully as high self-esteem could
        lead to indulging in risk-taking behaviors. This might not always be the
        case as people who know how to balance themselves can handle it pretty
        well.
      </p>
      <p class="para_scores mb-3" *ngIf="reseScore >= 15 && reseScore <= 25">
        A score ranging from 15 to 25 is the normal level of self-esteem that
        one should ideally be in. It is an optimum range of scores that balances
        everything out on its own as one is neither too confident nor too
        underconfident. This is a state that one can try to maintain.
      </p>
      <p class="para_scores mb-3" *ngIf="reseScore < 15 ">
        A score of 15 and below raises concern as it indicates low self-esteem.
        Low self-esteem can affect all aspects of one’s life including social
        life, work/school life, interpersonal relationships, and more. It could
        also lead to one experiencing depression and anxiety along with the
        development of unhealthy habits like drinking, smoking, etc. It is
        advisory to seek help from a mental health professional who can help you
        understand the root of your low self-esteem and ways of improving it.
        Felicity can help you with this.
      </p>
    </div>
    <div class="about-scores mb-4">
      <div class="">
        <h5 class="mb-3">Detailed Analysis</h5>
        <p class="mb-3">
          The Rosenberg Self-Esteem Scale is a unidimensional scale and has one
          factor that it measures - global self-worth. Meaning the amount of
          worth one attaches to themselves using both positive and negative
          perceptions. If you feel there is a lack of self-esteem and that it is
          affecting your overall life, you can always get in touch with a
          trained mental health professional who can help you work on these
          issues in a systematic manner. Felicity has some of the best
          therapists who can assist you in this journey.
        </p>
      </div>
    </div>
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>

</div>