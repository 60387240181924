import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-program-related',
  templateUrl: './program-related.component.html',
  styleUrls: ['./program-related.component.css']
})
export class ProgramRelatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
