import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { MoodJournalService } from "../mood-journal.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";

@Component({
  selector: "app-mood-journal",
  templateUrl: "./mood-journal.component.html",
  styleUrls: ["./mood-journal.component.css"],
})
export class MoodJournalComponent implements OnInit {
  items = [];
  pageNo = 1;
  pages = [1];
  showNext = false;
  totalCount = 0;
  isLoading = true;
  isShimmerLoader = true;
  skip = 0;
  perPage = 6;
  constructor(
    private moodService: MoodJournalService,
    private confirmationService: ConfirmationDialogService,
    private alertService: BootstrapAlertService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.getJournals();
  }
  ShimmerLoader(length: number): Array<any> {
    if (length >= 0) {
      return new Array(length);
    }
  }
  redirectTo(id) {
    this.router.navigateByUrl("user-dashboard/add-thoughts?thoughtId=" + id);
  }
  deleteOne(id) {
    this.confirmationService
      .confirm(
        "Are you sure?",
        "Are you sure you want to delete this journal?",
        "Yes, Delete",
        "No"
      )
      .subscribe((res) => {
        if (res) {
          this.moodService.deleteJournal(id).subscribe((data) => {
            let index = this.items.findIndex(item => item._id === id);
            if (index > -1) {
              this.items.splice(index, 1);
            }
          });
        }
      });
  }
  ShareMoodBoardTherpy(id) {
    this.moodService.shareMoodJournal(id).subscribe((res) => {
      let index = this.items.findIndex(item => item._id === id);
      this.items[index].sharedStatus = true;
    })
    this.alertService.alert(
      new BootstrapAlert(
        "Your journal has been shared successfully with your therapist!",
        "alert-success"
      )
    );
  }
  UnShareMoodBoardTherpy(id) {
    this.moodService.unShareMoodJournal(id).subscribe((res) => {
      let index = this.items.findIndex(item => item._id === id);
      this.items[index].sharedStatus = false;
    })
    this.alertService.alert(
      new BootstrapAlert(
        "Your journal has been un-shared successfully with your therapist!",
        "alert-success"
      )
    );
  }
  checkPage() {
    if (this.pageNo === this.pages[this.pages.length - 1]) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }
  }

  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;
    // this.isShimmerLoader = true;
    this.pageNo = this.pageNo + 1;
    this.getJournals();
    this.checkPage();
  }

  setPage(pageNo) {
    this.pageNo = pageNo;
    this.getJournals();
  }

  getJournals() {
    this.skip = (this.pageNo - 1) * this.perPage;
    // this.isShimmerLoader = true;
    this.moodService.journalPagin(this.skip, this.perPage).subscribe((data) => {

      this.items = this.items.concat(data["result"]);
      this.totalCount = data["totalCount"];
      let pageCount = Math.ceil(this.totalCount / 6);
      if (pageCount != this.pages.length) {
        for (let i = 0; i < pageCount - 1; i++) {
          if (this.pages.indexOf(i + 2)) {
            this.pages.push(i + 2);
          }
        }
      }
      this.checkPage();
      this.isLoading = false;
      this.isShimmerLoader = false;
    });
  }
}
