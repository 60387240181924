<div (click)="closenavbar()" class="topMenuMargin">
  <div class="container mt-3">
    <p class="disclaimerTxt">
      <strong> <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong
      >This tool should be used for screening and monitoring symptom severity
      and cannot replace a clinical assessment and diagnosis. You are encouraged
      to share these results with a mental health professional for a better
      understanding.
    </p>
    <div class="report-user mt-3">
      <h3>
        Getting to know yourself is the first step towards a mentally healthier
        life.
      </h3>
    </div>
  </div>
  <div class="report-section">
    <div class="img-section">
      <img src="../../../assets/images/meet.svg" />
    </div>
    <div class="report-user">
      <h3>Congratulations on taking that first step!</h3>
      <p><strong>Score:</strong></p>
      <p>Your PCL-5 score is {{ pclScore }}. <span class="scrollTxt" (click)="scrollToElem('target')">Detailed Analysis</span></p>
      <br>
      <p>
        Based on the current psychometric work, a score higher than 38 indicates
        PTSD. However, when choosing a cutoff score, it is essential to consider
        the goals of the assessment.
      </p>
      <!-- <button type="button" class="btn-report" (click)="reportData()">
        Email Report
      </button> -->
    </div>
  </div>
  <div class="container graph-section mt-3">
    <div>
      <div>
        <p class="mb-3" id="target" *ngIf="pclScore < 38">
          Your results may not be a sign of concern, but read on to find out a more detailed analysis of your score to understand if you are still experiencing some of the symptoms. You can always get in touch with the mental health professionals at Felicity for further help. We offer a variety of services that you can access at any time, anywhere.</p>
        <p class="mb-3" id="target" *ngIf="pclScore >= 38">
          Seeing the report can be overwhelming, but Felicity has got you
          covered. We offer a variety of services that you can access at any
          time, anywhere.
        </p>
      </div>
    </div>
  </div>

  <div class="container about-scores">
    <div class="report-user">
      <h3 class="">Detailed Analysis</h3>
      <p class="mb-3">
        The possible range of scores for PCL-5 is 0 to 80, 0 being the least
        intense and 80 being the highest. PCL-5 has 4 subscales and each of them
        will be discussed with your scores on them.
      </p>
      <p class="mb-3">
        <strong>Re-experiencing:</strong>
        As the name suggests, re-experiencing means to get flashbacks of the
        trauma and experience physical, physiological and mental distress as a
        result of that. Experiencing recurring memories or thoughts of the
        trauma and nightmares are also possible. Your score on this subscale is
        {{ subScore1 }}, with 0 as the lowest score and 20 as the highest score.
      </p>
      <p class="mb-3">
        <strong>Avoidance:</strong>
        People try very hard to avoid situations that could lead them to the
        traumatic event. This means avoidance of the thoughts and feelings,
        avoidance of activity, avoidance of memory. Your score on this subscale
        is {{ subScore2 }}, with 0 as the lowest score and 8 as the highest
        score.
      </p>
      <p class="mb-3">
        <strong> Negative alterations in cognition and mood: </strong>
        This subscale entails hopelessness towards the future, negative thoughts
        about oneself and people around, difficulty in remembering things and
        other memory functions. There is also an evident difficulty in
        maintaining relationships. Your score on this subscale is
        {{ subScore3 }}, with 0 as the lowest score and 28 as the highest score.
      </p>
      <p class="mb-3">
        <strong>Hyper-arousal:</strong>This means a state of panic at all times
        along with paranoia and suspicion. It activates the body's fight or
        flight responses and keeps the person excessively alert of themselves
        and their surroundings at all times. This is a constant state of tension
        and can be extremely stressful for the person experiencing it. Your
        score on this subscale is {{subScore4}}, with 0 as the lowest score and 24 as the
        highest score.
      </p>
      <h5><strong>Note:</strong></h5>
      <p>Please note that this test only provides a provisional diagnosis and is not a substitute for clinical assessment. A clinician also does a subjective assessment using LEC-5 and Criterion A together with this test. This further improves the sensitivity of the interpretation. However, Criterion A and LEC-5 are highly subjective and hence not suitable for self-assessment. It is advisable to discuss these scores with a clinically licensed mental health professional who can more intricately assess your scores along with an interview for the same.</p>
    </div>
  </div>

  <div class="container scores-section">
    <div class="scores-btn-section">
      <button class="contact-us-btn">
        <a routerLink="../../../our-counselors">Book A Consultation</a>
      </button>
      <button class="counselling-therapists-btn">
        <a routerLink="../../">Take Another Free Report</a>
      </button>
    </div>
  </div>
  <br />
</div>
