<div (click)="closenavbar()" class="topMenuMargin">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <i class="fa fa-exclamation-triangle"></i><strong> Disclamer: </strong>This tool should be used for
            screening and
            monitoring symptom severity and cannot replace a clinical assessment and
            diagnosis. You are encouraged to share these results with a mental health
            professional for a better understanding.
        </p>
        <h3 class="mt-2">
            Getting to know yourself is the first step towards a mentally healthier
            life.
        </h3>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user mt-3">
            <h3>Congratulations on taking that first step!</h3>
            <p class="mt-3"><strong>Your Score:</strong> {{ eatingDisordersScore }}</p>
            <p class="mt-3"><strong>Your Result:</strong></p>
            <p ><span>{{ eatingDisordersPhrase }}</span>
            </p>
            <p>If you answered “yes” to two or more of the questions above, you should make an appointment for an
                assessment with an eating disorder professional, such as a therapist, dietitian, or physician. A
                professional can determine if you do, indeed, suffer from anorexia nervosa, bulimia nervosa, binge
                eating disorder, or any other eating disorder.</p>
            <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
        </div>
    </div>
    <div class="container mt-2">
        <p *ngIf="eatingDisordersScore >= 0 && eatingDisordersScore <= 1 ">Your results may not be a sign of concern, but read on to find out a more detailed analysis of your score. Felicity is here to assist you in improving your mental well-being.  </p>
        <p *ngIf="eatingDisordersScore >= 2 && eatingDisordersScore <= 5 ">Your results may be slightly concerning, but do not worry too much - Felicity has got you covered. We offer a variety of services that you can access at any time, anywhere.
        </p>
    </div>
    <div class="container graph-section mt-2">
        <h5>Understanding your Eating Disorder</h5>
    </div>
    <div class="container graph-section mt-3">
        <div class="graph-right">
            <img src="../../../assets/images/free-report-graphs/eating-disorder.png" />
        </div>
    </div>

    <div class="container about-scores">
        <div class="">
            <h5 class="mb-3">Detailed Analysis</h5>
            <p class="mb-3">Since the current assessment is only a screening test - which only presents a ‘likely case’
                of the condition - and nothing close to the accuracy a professional diagnosis provides, it is highly
                suggested that you seek consultation by a mental health professional to understand your results better.
                Even if you didn't respond “yes” to two or more of the questions but think you might have a problem or
                someone else is concerned about your eating or exercise habits, you should still get in touch with a
                professional because the SCOFF questionnaire may not detect all eating disorders or disordered eating
                behaviours. </p>
            <p class="mb-3">You can get in touch with the practitioners at Felicity, with the assurance that you will
                receive the best possible help and care.</p>
        </div>
    </div>
    <div class="container scores-section">
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
    <br />
</div>