<div class="topMenuMargin" (click)="closenavbar()">
  <div class="container">
    <div class="navigationSection">
      <button class="buttonTheme2" style="margin-top: 10px" (click)="goBack()">
        <fa-icon [icon]="faArrowLeft"></fa-icon> Back
      </button>
    </div>
    <div class="secondaryHeading">
      <h2>Find A Plan That's Right For You</h2>
      <hr />
    </div>
    <div class="planBoxWrap">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-4 planRes">
          <div class="planBox">
            <div class="planContWrap">
              <div class="planIcon">
                <img *ngIf="plansData" src="../../../../assets/images/plans-pricing/{{plansData[0]?.imageUrl}}"
                  class="img-fluid" draggable="false" alt="" />
              </div>
              <div class="planConts">
                <h2>{{plansData ? plansData[0]?.name : '' }}</h2>
                <h5>{{plansData ? plansData[0]?.no_of_sessions : '' }} Session</h5>
                <div class="iconsHolder">
                  <img src="../../../../assets/images/video.png" alt="" draggable="false" class="img-fluid" />
                  <img src="../../../../assets/images/mic.png" alt="" draggable="false" class="img-fluid" />
                  <img src="../../../../assets/images/chat.png" alt="" draggable="false" class="img-fluid" />
                </div>
                <p>( {{ plansData ? plansData[0]?.weeks_of_chat_access : '' }} week of chat access )</p>
              </div>
              <div class="planConts">
                <h3>What to Expect ?</h3>
                <p>
                  Vent out, discuss your issues & create a plan with your talk
                  therapist
                </p>
              </div>
              <div class="planPrice">
                <h2>{{plansData ? plansData[0]?.price : '' }}</h2>
                <p>( Price per session {{ plansData ? plansData[0]?.avgPrice : '' }} )</p>
              </div>
              <div class="planBuy">
                <a (click)="buyNow(plansData ? plansData[0]?.key : '')" class="buttonTheme1">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 planRes">
          <div class="planBox">
            <div class="planContWrap">
              <img src="../../../../assets/images/plans-pricing/recommended-tag.png" class="img-fluid recommendedTag"
                draggable="false" alt="" />
              <div class="planIcon">
                <img *ngIf="plansData" src="../../../../assets/images/plans-pricing/{{plansData[1]?.imageUrl}}"
                  class="img-fluid" draggable="false" alt="" />
              </div>
              <div class="planConts">
                <h2>{{ plansData ? plansData[1]?.name : '' }}</h2>
                <h5>{{ plansData ? plansData[1]?.no_of_sessions : '' }} Sessions</h5>
                <div class="iconsHolder">
                  <img src="../../../../assets/images/video.png" alt="" draggable="false" class="img-fluid" />
                  <img src="../../../../assets/images/mic.png" alt="" draggable="false" class="img-fluid" />
                  <img src="../../../../assets/images/chat.png" alt="" draggable="false" class="img-fluid" />
                </div>
                <p>( {{ plansData ? plansData[1]?.weeks_of_chat_access : '' }} weeks of chat access )</p>
              </div>
              <div class="planConts">
                <h3>What to Expect ?</h3>
                <p>
                  Identify thought patterns, break negative thought loops &
                  initiate positive ones
                </p>
              </div>
              <div class="planPrice">
                <h2>{{ plansData ? plansData[1]?.price : ''}}</h2>
                <p>( Price per session {{ plansData ? plansData[1]?.avgPrice : '' }} )</p>
              </div>
              <div class="planBuy">
                <a (click)="buyNow(plansData ? plansData[1]?.key : '')" class="buttonTheme1">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 planRes">
          <div class="planBox">
            <div class="planContWrap">
              <div class="planIcon">
                <img *ngIf="plansData" src="../../../../assets/images/plans-pricing/{{plansData[2]?.imageUrl}}"
                  class="img-fluid" draggable="false" alt="" />
              </div>
              <div class="planConts">
                <h2>{{ plansData ? plansData[2]?.name : '' }}</h2>
                <h5>{{ plansData ? plansData[2]?.no_of_sessions : '' }} Sessions</h5>
                <div class="iconsHolder">
                  <img src="../../../../assets/images/video.png" alt="" draggable="false" class="img-fluid" />
                  <img src="../../../../assets/images/mic.png" alt="" draggable="false" class="img-fluid" />
                  <img src="../../../../assets/images/chat.png" alt="" draggable="false" class="img-fluid" />
                </div>
                <p>( {{ plansData ? plansData[2]?.weeks_of_chat_access : '' }} weeks of chat access )</p>
              </div>
              <div class="planConts">
                <h3>What to Expect ?</h3>
                <p>
                  Initiate behavioural changes, learn how to manage your
                  thoughts & emotions
                </p>
              </div>
              <div class="planPrice">
                <h2>{{plansData ? plansData[2]?.price : '' }}</h2>
                <p>( Price per session {{ plansData ? plansData[2]?.avgPrice : '' }} )</p>
              </div>
              <div class="planBuy">
                <a (click)="buyNow(plansData ? plansData[2]?.key : '')" class="buttonTheme1">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="plansTip">
      Each session is 45 minutes long. <br>
      Sessions do not expire & have lifetime validity. <br />
      Once bought you can schedule it at your convenience.
    </div>
    <div class="plansTip" *ngIf="regionData?.tag === 'IN'">
      Couldn't find the plan you are looking for? <br />
      No worries, please drop us a call on
      <a href="tel:+916377327550">+916377327550</a>, and we'll help you out.
    </div>
    <div class="plansTip" *ngIf="regionData?.tag === 'intl'">
      Couldn't find the plan you are looking for? <br />
      No worries, please drop us a call on
      <a href="mailto:hola@felicity.care">hola@felicity.care</a>, and we'll help you out.
    </div>
  </div>
  <div class="faqHolder">
    <div class="faqHeading">
      <h2>Frequently Asked Questions</h2>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div class="faqQue">
                <h4>How many sessions do I need?</h4>
              </div>
            </div>
            <div class="flip-card-back">
              <p>
                What you need is extremely specific to your own needs. You can
                vent out & feel better in one session, however, we recommend a
                minimum of <span *ngIf="regionData?.tag === 'IN'">3</span><span *ngIf="regionData?.tag === 'intl'">12</span> sessions for anyone to experience meaningful change
                in their thoughts & behaviour.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div class="faqQue">
                <h4>What if I don’t want to do a video session?</h4>
              </div>
            </div>
            <div class="flip-card-back">
              <p>
                You can choose your preferred medium for the session – we give
                all options for your 45 minutes of session – video, audio or
                chat.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="regionData?.tag === 'IN'">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>Is there an EMI option?</h4>
            </div>
            <div class="flip-card-back">
              <p>Yes, you just have to choose it while checking out.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>When can I take the session?</h4>
            </div>
            <div class="flip-card-back">
              <p>
                Whenever you want! We have continuous appointments available
                round the clock.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>