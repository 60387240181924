import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const helpdeskUrl = environment.apiUrl + '/therapist/helpdesk/';
@Injectable({
  providedIn: 'root'
})
export class TherapistHelpdeskService {

  constructor(private http : HttpClient) { }

  createRequest(request){
    return this.http.post(helpdeskUrl + 'create', request);
  }

  getOldRequests(){
    return this.http.get(helpdeskUrl + 'read-all')
  }

  getRequestById(id){
    return this.http.get(helpdeskUrl + 'read/' +id)
  }

  requestCallback(){
    return this.http.get(helpdeskUrl + 'request-callback')
  }

  getReplies(id){
    return this.http.get(helpdeskUrl + 'get-replies/' + id)
  }

  addReply(id, reply){
    return this.http.post(helpdeskUrl + 'reply-to-ticket/' + id, reply)
  }
}
