import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { TherapistAuthService } from '../therapist-auth/therapist-auth.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-helpdesk-redirection',
  templateUrl: './helpdesk-redirection.component.html',
  styleUrls: ['./helpdesk-redirection.component.css']
})
export class HelpdeskRedirectionComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };
  constructor(private authService : AuthService, private therpaistAuthSerivce : TherapistAuthService, private router : Router) { }
  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    
    if(this.authService.getIsAuth()){
      this.router.navigateByUrl('/user-dashboard/requestform')
    } else if(this.therpaistAuthSerivce.getIsAuth()){
      this.router.navigateByUrl('/therapist-dashboard/requestform')
    } else {
      this.router.navigateByUrl('/online-counseling-psychologists/contact-us')
    }
  }

}
