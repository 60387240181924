import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from "../../free-report.service";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-satisfaction-with-life-test-results',
  templateUrl: './satisfaction-with-life-test-results.component.html',
  styleUrls: ['./satisfaction-with-life-test-results.component.css']
})
export class SatisfactionWithLifeTestResultsComponent implements OnInit {

  satisfactionPhrase = "";
  report;
  satisfactionScore;
  itemId;
  answersArray = []
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) { 
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res["report"]
        this.satisfactionScore = res["report"]["reportDetails"]["score"];
        this.answersArray = Object.values(this.report.reportDetails.answers);
        if (clevertap) {
          clevertap.event.push("Free Report Ended", {
            "Report Name": "Satisfaction with Life Scale",
            "Score": this.satisfactionScore
          })
        }
        this.satisfactionfinalcal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id: this.itemId,
      reportType: 'satisfaction-with-life'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if (res) {
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  satisfactionfinalcal() {
    if (this.satisfactionScore >= 5 && this.satisfactionScore <= 9) {
      this.satisfactionPhrase = "extremely dissatisfied with your life.";
    } else if (this.satisfactionScore >= 10 && this.satisfactionScore <= 14) {
      this.satisfactionPhrase = "dissatisfied with your life.";
    } else if (this.satisfactionScore >= 15 && this.satisfactionScore <= 19) {
      this.satisfactionPhrase = "slightly dissatisfied with life.";
    } 
    else if (this.satisfactionScore >= 15 && this.satisfactionScore <= 19) {
      this.satisfactionPhrase = "slightly dissatisfied with life.";
    } 
    else if (this.satisfactionScore == 20) {
      this.satisfactionPhrase = "neutral in terms of your satisfaction with life.";
    } 
    else if (this.satisfactionScore >= 21 && this.satisfactionScore <= 25) {
      this.satisfactionPhrase = "slightly satisfied with your life.";
    } 
    else if (this.satisfactionScore >= 26 && this.satisfactionScore <= 30) {
      this.satisfactionPhrase = "satisfied with your life.";
    } 
    else if (this.satisfactionScore >= 31 && this.satisfactionScore <= 35) {
      this.satisfactionPhrase = "extremely satisfied with your life.";
    } 
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }

}
