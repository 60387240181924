import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'app-auth-handler',
  templateUrl: './auth-handler.component.html',
  styleUrls: ['./auth-handler.component.css']
})
export class AuthHandlerComponent implements OnInit {
  sessionId = '';
  status = 'waiting';
  isExpired = '';
  redirection = '';
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }
  constructor(private activatedRoute : ActivatedRoute, private authService : AuthService ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.sessionId = params['sessionId'] || '';
      this.isExpired = params['isExpired'] || '';
      this.redirection = params['redirection'] || '';
      if(params["source"]){
        localStorage.setItem("partner_source", params['source'])
      }
      if(this.sessionId !== ''){
        this.authService.authWithToken(this.sessionId, this.redirection,(status: any)=> {
          this.status = status;
        })
      } else {
        this.status = 'missing_id'
      }
      if(this.isExpired !== ''){
        this.status = 'expired'
      }
    })
  }

}
