import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ScriptService } from 'src/app/script.service';

@Component({
  selector: 'app-test-integrations',
  templateUrl: './test-integrations.component.html',
  styleUrls: ['./test-integrations.component.css']
})
export class TestIntegrationsComponent implements OnInit {
  src1 = 'https://ma.zoho.in/js/zc.iframe.js';
  src2 = 'https://ajwijk.maillist-manage.in/ua/Optin?od=1a1e3db000687&zx=1df8d8a88e&lD=1f433071fafb6e1&n=11699f74d9507ca&sD=1f433071fb0995f'
  safeUrl1: SafeResourceUrl;
  safeUrl2: SafeResourceUrl;
  constructor(private scriptService: ScriptService,private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.safeUrl1 = this._sanitizer.bypassSecurityTrustResourceUrl(this.src1)
    this.safeUrl2 = this._sanitizer.bypassSecurityTrustResourceUrl(this.src2)
  }

}
