import * as moment from "moment";

export function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}
export const convertToSpecificTz = (time, offset) => {
    let toTZDate = moment().utcOffset(offset.replace(":", ""))
    let newDateTime = toTZDate.set({ hour: time, minute: 0 })
    let utcDate = newDateTime.utc()
    return utcDate
}
export const convertDateToUTC = (date, time, offset) => {
    let givenDate = new Date(date)
    let momentDate = moment(givenDate)
    let toTZDate = momentDate.utcOffset(offset.replace(":", ""))
    let newDateTime = toTZDate.set({
        date: givenDate.getDate(),
        month: givenDate.getMonth(),
        year: givenDate.getFullYear(),
        hour: time,
        minute: 0
    })
    // console.log(newDateTime.format())
    // console.log(newDateTime.utc().format())
    return newDateTime.utc()
}