import { Component, OnInit } from "@angular/core";
import { AfterViewInit, ElementRef, ViewChild } from "@angular/core";

import * as $ from "jquery";
import "magnific-popup";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.css"],
})
export class AboutUsComponent implements OnInit {
  slideIndex: number;
  constructor(private sidenavService: SideNavBarService, private metaSeo: SeoMetaService) {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  openModal() {
    document.getElementById("myModal").style.display = "block";
  }

  closeModal() {
    document.getElementById("myModal").style.display = "none";
  }
  currentSlide(n) {
    var i;
    this.slideIndex = n;
    var slides = document.getElementsByClassName(
      "mySlides"
    ) as HTMLCollectionOf<HTMLElement>;
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[this.slideIndex - 1].style.display = "block";
  }
  ngOnInit(): void {
    this.metaSeo.updateTitle("About Felicity - Top Online Counseling & Therapy Platform | Mental Health Community");
    this.metaSeo.updateMetaInfo("Felicity, literally meaning happiness, was conceived to make the world a happier place. Find out more why mental health matters to Felicity.", "Felicity, felicity mental health, felicity mental wellness, felicity online couneling, felicity online therapy")
  }
}
