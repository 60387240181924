import { BootstrapAlertService } from './../shared/ng-bootstrap-alert/bootstrap-alert.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import * as moment from 'moment';
import { AvailableSlotsService } from '../therapist/available-slots.service';
import * as momentTz from "moment-timezone";
import { BootstrapAlert } from '../shared/ng-bootstrap-alert/bootstrap-alert';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { convertDateToUTC } from '../shared/util/dateFunctions';





@Component({
  selector: 'app-available-slots-dialog',
  templateUrl: './available-slots-dialog.component.html',
  styleUrls: ['./available-slots-dialog.component.scss']
})
export class AvailableSlotsDialogComponent implements OnInit {
  maxDate: Date
  minDate: Date
  repeatTillItems = []
  isLoading = false;
  slotsArray = [
    { startTime: "12:00 AM", endTime: "1:00 AM", slot: "0:00", type: 'early' },
    { startTime: "1:00 AM", endTime: "2:00 AM", slot: "1:00", type: 'early' },
    { startTime: "2:00 AM", endTime: "3:00 AM", slot: "2:00", type: 'early' },
    { startTime: "3:00 AM", endTime: "4:00 AM", slot: "3:00", type: 'early' },
    { startTime: "4:00 AM", endTime: "5:00 AM", slot: "4:00", type: 'early' },
    { startTime: "5:00 AM", endTime: "6:00 AM", slot: "5:00", type: 'early' },
    { startTime: "6:00 AM", endTime: "7:00 AM", slot: "6:00", type: 'early' },
    { startTime: "7:00 AM", endTime: "8:00 AM", slot: "7:00", type: 'morning' },
    { startTime: "8:00 AM", endTime: "9:00 AM", slot: "8:00", type: 'morning' },
    { startTime: "9:00 AM", endTime: "10:00 AM", slot: "9:00", type: 'morning' },
    { startTime: "10:00 AM", endTime: "11:00 AM", slot: "10:00", type: 'morning' },
    { startTime: "11:00 AM", endTime: "12:00 PM", slot: "11:00", type: 'morning' },
    { startTime: "12:00 PM", endTime: "1:00 PM", slot: "12:00", type: 'day' },
    { startTime: "1:00 PM", endTime: "2:00 PM", slot: "13:00", type: 'day' },
    { startTime: "2:00 PM", endTime: "3:00 PM", slot: "14:00", type: 'day' },
    { startTime: "3:00 PM", endTime: "4:00 PM", slot: "15:00", type: 'day' },
    { startTime: "4:00 PM", endTime: "5:00 PM", slot: "16:00", type: 'day' },
    { startTime: "5:00 PM", endTime: "6:00 PM", slot: "17:00", type: 'evening' },
    { startTime: "6:00 PM", endTime: "7:00 PM", slot: "18:00", type: 'evening' },
    { startTime: "7:00 PM", endTime: "8:00 PM", slot: "19:00", type: 'evening' },
    { startTime: "8:00 PM", endTime: "9:00 PM", slot: "20:00", type: 'night' },
    { startTime: "9:00 PM", endTime: "10:00 PM", slot: "21:00", type: 'night' },
    { startTime: "10:00 PM", endTime: "11:00 PM", slot: "22:00", type: 'night' },
    { startTime: "11:00 PM", endTime: "12:00 AM", slot: "23:00", type: 'night' },
  ];
  weekDays = [
    {
      day: "Sunday",
      index: 0,
      timeSlots: JSON.parse(JSON.stringify([[]])),
    },
    {
      day: "Monday",
      index: 1,
      timeSlots: JSON.parse(JSON.stringify([])),
    },
    {
      day: "Tuesday",
      index: 2,
      timeSlots: JSON.parse(JSON.stringify([])),
    },
    {
      day: "Wednesday",
      index: 3,
      timeSlots: JSON.parse(JSON.stringify([])),
    },
    {
      day: "Thursday",
      index: 4,
      timeSlots: JSON.parse(JSON.stringify([])),
    },
    {
      day: "Friday",
      index: 5,
      timeSlots: JSON.parse(JSON.stringify([])),
    },
    {
      day: "Saturday",
      index: 6,
      timeSlots: JSON.parse(JSON.stringify([])),
    },
  ];
  slotsBookingForm: FormGroup
  constructor(private bsModalRef: BsModalRef,
    private slotsService: AvailableSlotsService,
    private alertService: BootstrapAlertService
  ) {

  }
  @Input() title: string;
  @Input() selectedDate: string;
  @Input() bookedSlots: Array<any>;
  @Input() selectedSlots: Array<any>
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() prevDateSlots: Array<any>;
  @Input() nextDateSlots: Array<any>;
  @Input() weeklySlots: Array<any>;
  @Input() userTimezone
  public onClose: Subject<boolean>;
  bsConfig: Partial<BsDatepickerConfig>;

  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    this.onClose = new Subject();
    this.bsConfig = Object.assign({}, { containerClass: "calender-slot-theme" });
    this.initForm()
    // console.log(this.weeklySlots)

  }



  public accept() {
    this.bsModalRef.hide()
    this.onClose.next(true);
  }

  public closeModal() {
    this.bsModalRef.hide();
    this.onClose.next(null);
  }
  public onSuccessCloseModal(data) {

    this.bsModalRef.hide();
    this.onClose.next(data);
  }
  initForm() {
    this.slotsBookingForm = new FormGroup({
      timeTags: new FormControl(""),
      repeatUntilDate: new FormControl(""),
      repeatType: new FormControl("WEEKLY_RECURRENCE"),
      isRepeatOptions: new FormControl(false, Validators.required),
      bookedSlots: new FormControl("")
    });
    let selectedDay = moment(new Date(this.selectedDate)).format('dddd')
    this.repeatTillItems = [
      { name: 'Daily', type: 'DAILY_RECURRENCE' },
      { name: 'Week Days(Mon-Fri)', type: 'WEEKDAY_RECURRENCE' },
      { name: `Every ${selectedDay}`, type: 'WEEKLY_RECURRENCE' },
    ];
    let dd = new Date(this.selectedDate)
    let defaultDate = dd.setMonth(dd.getMonth() + 2)
    this.slotsBookingForm.get('repeatUntilDate').setValue(new Date(defaultDate))
    this.assignMinAndMaxDate()

    if (this.selectedSlots) {
      let array = this.selectedSlots && this.selectedSlots.map((data) => data.slot)
      this.slotsBookingForm.get('timeTags').setValue(array)
    }
    if (this.bookedSlots) {
      let array = this.bookedSlots && this.bookedSlots.map((data) => data.slot)
      this.slotsBookingForm.get('bookedSlots').setValue(array)
    }


  }

  assignMinAndMaxDate() {
    var d = new Date(this.selectedDate);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + 1, month, day);
    this.maxDate = c
    this.minDate = new Date();
  }
  onChipClick(chip) {
    if (!this.isBookedSlotExists(chip)) {
      let isExists = this.slotsBookingForm.value.timeTags ? this.slotsBookingForm.value.timeTags.filter((data) => {
        return data == chip
      }) : false;
      if (!isExists?.length) {
        if (moment().format('DD-MM-YYYY') === moment(this.selectedDate).format('DD-MM-YYYY')) {
          if (parseInt(moment().format('HH')) < parseInt(chip.split(':')[0])) {
            let timeData = this.slotsBookingForm.value.timeTags || []
            timeData.push(chip)
            this.slotsBookingForm.get('timeTags').setValue(timeData)
          }
        }
        else {
          let timeData = this.slotsBookingForm.value.timeTags || []
          timeData.push(chip)
          this.slotsBookingForm.get('timeTags').setValue(timeData)
        }

      }
      else {
        if (moment().format('DD-MM-YYYY') === moment(this.selectedDate).format('DD-MM-YYYY')) {
          if (parseInt(moment().format('HH')) < parseInt(chip.split(':')[0])) {
            let timeData = this.slotsBookingForm.value.timeTags || []
            let filter = timeData && timeData.filter((data) => data !== chip);
            this.slotsBookingForm.get('timeTags').setValue(filter)
          }
        }
        else {
          let timeData = this.slotsBookingForm.value.timeTags || []
          let filter = timeData && timeData.filter((data) => data !== chip);
          this.slotsBookingForm.get('timeTags').setValue(filter)
        }
      }
    }
  }
  isBookedSlotExists(slot) {
    return this.slotsBookingForm?.value?.bookedSlots ? this.slotsBookingForm?.value?.bookedSlots.includes(slot) : false
  }
  isNormalSlotExists(slot) {
    return this.slotsBookingForm?.value?.timeTags ? this.slotsBookingForm?.value?.timeTags.includes(slot) : false
  }

  getChipClass(slot) {
    let timeSlots = this.slotsBookingForm?.value?.timeTags
    let bookedTimeSlots = this.slotsBookingForm?.value?.bookedSlots
    if (bookedTimeSlots && bookedTimeSlots.includes(slot)) {
      if (moment().format('DD-MM-YYYY') === moment(this.selectedDate).format('DD-MM-YYYY')) {
        return 'outer-chip-booked-active'
      }
      return 'outer-chip-booked-active'
    }
    else if (timeSlots && timeSlots.includes(slot)) {
      if (moment().format('DD-MM-YYYY') === moment(this.selectedDate).format('DD-MM-YYYY')) {
        if (parseInt(moment().format('HH')) > parseInt(slot.split(':')[0])) {
          return 'outer-chip-active-gray cursor-not-allowed'
        }
      }
      return 'outer-chip-active'
    }
    else {
      if (moment().format('DD-MM-YYYY') === moment(this.selectedDate).format('DD-MM-YYYY')) {
        if (parseInt(moment().format('HH')) >= parseInt(slot.split(':')[0])) {
          return 'outer-chip-active-gray cursor-not-allowed'
        }
      }
      return 'outer-chip'
    }
  }
  getChipTitle(slot) {
    // if ((this.isNormalSlotExists(slot) || this.isBookedSlotExists(slot))) {
    if (this.isBookedSlotExists(slot)) {
      return `This slot is already booked and it cannot be updated.`
    }
    else if (moment().format('DD-MM-YYYY') === moment(this.selectedDate).format('DD-MM-YYYY')) {
      if (parseInt(moment().format('HH')) > parseInt(slot.split(':')[0])) {
        return 'Past time Slot'
      }
    }
    // }
    else if (this.isNormalSlotExists(slot)) {
      return `${slot}`
    }

    else {
      return `${slot}`
    }
  }
  arrayToObject(array) {
    if (array.length > 0) {
      let object = {}
      array && array.map((data) => {
        if (data) {
          object[data.day] = [...data.slots]
        }
      })
      return object
    }

  }
  injectWeeklySlotsObject(array, isOnlyWeekends = false) {
    if (array.length > 0) {
      let object = {}
      array && array.map((data) => {
        if (data) {
          let weekslots = []
          if (isOnlyWeekends && (data.day == 'Saturday' || data.day == 'Sunday')) {
            weekslots = this.weeklySlots[data.day]

          }
          else {
            weekslots = this.weeklySlots[data.day]
          }
          if (weekslots?.length) {
            object[data.day] = [...data.slots, ...weekslots]
            object[data.day] = Array.from(new Set([...object[data.day]]))
          }
          else {

            object[data.day] = [...data.slots]
          }
        }
      })
      return object
    }
  }
  getCurrentWeek(date) {
    var currentDate = moment(date ? new Date(date) : new Date());

    var weekStart = currentDate.clone().startOf('isoWeek');
    var days = {};
    for (var i = 0; i <= 6; i++) {
      days[moment(weekStart).add(i, 'days').format('dddd')] = null
      days[moment(weekStart).add(i, 'days').format('dddd')] = moment(weekStart).add(i, 'days')
    }
    // console.log(days);
    return days
  }

  updateWeekdaySlotsObject(array) {
    if (array.length > 0) {
      let object = {}
      array && array.map((data) => {
        if (data.day == 'Saturday') {
          object[data.day] = [...this.weeklySlots[data.day]]
        }
        else if (data.day == 'Sunday') {
          let weeksArr = this.getCurrentWeek(this.selectedDate)
          let sundatDate = weeksArr['Sunday']
          this.weeklySlots[data.day].forEach((slot) => {
            let dateFromatted = moment(sundatDate).format('DD_MM_YYYY').toString()
            let slotData = moment.utc(
              dateFromatted + " " + slot,
              "DD_MM_YYYY HH:mm"
            );
            let slotString = momentTz
              .tz(slotData, this.userTimezone.tzCode)
            if (parseInt(slotString.format('D')) == parseInt(moment(sundatDate).format('D'))) {
              if (!object[data.day]) {
                object[data.day] = []
              }
              object[data.day].push(slot)
            }
          });
          if (!object[data.day]) {
            object[data.day] = []
          }
          object[data.day] = Array.from(new Set([...data.slots, ...object[data.day]]))
        }
        else if (data.day == 'Friday') {
          let weeksArr = this.getCurrentWeek(this.selectedDate)
          let fridayDate = weeksArr['Friday']
          this.weeklySlots[data.day].forEach((slot) => {
            let dateFromatted = moment(fridayDate).format('DD_MM_YYYY').toString()
            let slotData = moment.utc(
              dateFromatted + " " + slot,
              "DD_MM_YYYY HH:mm"
            );
            let slotString = momentTz
              .tz(slotData, this.userTimezone.tzCode)
            if (parseInt(slotString.format('D')) !== parseInt(moment(fridayDate).format('D'))) {
              if (!object[data.day]) {
                object[data.day] = []
              }
              object[data.day].push(slot)
            }
          });
          if (!object[data.day]) {
            object[data.day] = []
          }
          object[data.day] = Array.from(new Set([...data.slots, ...object[data.day]]))
        }
        else {
          object[data.day] = Array.from(new Set([...data.slots]))
        }
      })
      return object
    }
  }
  updateWeeklySlotsObject(array, day = '') {
    if (array.length > 0) {
      let object = {}
      array && array.map((data) => {
        if (data) {

          if (this.weeklySlots[data.day]?.length) {
            object[data.day] = [...data.slots, ...this.weeklySlots[data.day]]
            object[data.day] = Array.from(new Set([...object[data.day]]))
          }
          else {
            object[data.day] = [...data.slots]
          }
          if (day && day == data.day) {
            if (moment(this.selectedDate).format('dddd') == day) {
              object[data.day] = []
              let prevDate = moment(this.selectedDate).add(-1, 'days')
              let prevDateFormat = prevDate.format('dddd')
              // keep next day slots
              this.weeklySlots[data.day].forEach((slot) => {
                let dateFromatted = moment(this.selectedDate).format('DD_MM_YYYY').toString()
                let slotData = moment.utc(
                  dateFromatted + " " + slot,
                  "DD_MM_YYYY HH:mm"
                );
                let slotString = momentTz
                  .tz(slotData, this.userTimezone.tzCode)
                if (slotString.format('D') !== moment(this.selectedDate).format('D')) {
                  if (!object[data.day]) {
                    object[data.day] = []
                  }
                  object[data.day].push(slot)
                }
              });
              // keep prev day slots
              this.weeklySlots[prevDateFormat].forEach((slot) => {
                let dateFromatted = moment(prevDate).format('DD_MM_YYYY').toString()
                let slotData = moment.utc(
                  dateFromatted + " " + slot,
                  "DD_MM_YYYY HH:mm"
                );
                let slotString = momentTz
                  .tz(slotData, this.userTimezone.tzCode)
                // console.log(parseInt(slotString.format('D')), parseInt(moment(prevDate).format('D')))
                if (parseInt(slotString.format('D')) !== parseInt(moment(prevDate).format('D'))) {
                  let prevDateSlots = array && array.filter(d => d.day == prevDateFormat)
                  if (prevDateSlots && !prevDateSlots[0]?.slots?.includes(slot)) {
                    if (object[prevDateFormat]) {
                      object[prevDateFormat].filter(d => d !== slot)
                    }
                  }
                }
              });
              object[data.day] = Array.from(new Set([...data.slots, ...object[data.day]]))
            }
          }
        }
      })
      return object
    }
  }
  onSubmit() {
    if (this.slotsBookingForm.valid) {
      if (this.slotsBookingForm.value.timeTags?.length > 0) {
        let currentDate = moment();
        let weekStart = currentDate.clone().startOf("week");
        let reqPaylod
        let slotDates = [];
        this.weekDays.forEach((day) => {
          slotDates.push({
            day: day.day,
            slots: [],
          });
        })

        if (!this.slotsBookingForm.value.isRepeatOptions) {
          let selectedDateDay = moment(new Date(this.selectedDate)).format('dddd')

          let getIndex = null
          slotDates && slotDates && slotDates.map((d, i) => {
            if (d.day == selectedDateDay) {
              getIndex = i
            }
          })


          reqPaylod = {
            recurrenceType: 'NO_RECURRENCE',
            recurringWeeklySlots: {},
            slotsWithNoRecurrence: {
            },
            selectedDate: moment(new Date(this.selectedDate)).format('DD_MM_YYYY')

          }
          let formattedDate = moment(new Date(this.selectedDate)).format('DD_MM_YYYY')
          !reqPaylod.slotsWithNoRecurrence[formattedDate] ? reqPaylod.slotsWithNoRecurrence[formattedDate] = [] : ''
          reqPaylod.slotsWithNoRecurrence[formattedDate] = []
          reqPaylod.slotsWithNoRecurrence['before_' + formattedDate] = []
          this.slotsBookingForm.value.timeTags.forEach((slot) => {
            let currentDate = moment(this.selectedDate)
            let slotStr = slot.split(":");
            let updatedTime = currentDate.clone().set({
              hour: slotStr[0],
            });

            let slotUtcDate = convertDateToUTC(this.selectedDate, parseInt(slotStr[0]), this.userTimezone?.offset || new Date().getTimezoneOffset())
            let slotUtcStr = slotUtcDate.format("HH:mm");
            let slotDateDay = slotUtcDate.format('D')
            let currentDateDay = currentDate.format('D')
            if (parseInt(slotDateDay) - parseInt(currentDateDay) == 0) {
              reqPaylod.slotsWithNoRecurrence[formattedDate].push(slotUtcStr)
            }
            else {
              let formattedUtcDate = slotUtcDate.format('DD_MM_YYYY')

              !reqPaylod.slotsWithNoRecurrence['before_' + formattedDate] ? reqPaylod.slotsWithNoRecurrence['before_' + formattedDate] = [] : ''
              reqPaylod.slotsWithNoRecurrence['before_' + formattedDate].push(slotUtcStr)
            }

          });

          this.isLoading = true

        }

        // if weekdays than add slots from monday to friday
        else {
          if (this.slotsBookingForm.value.repeatType == 'WEEKDAY_RECURRENCE') {
            this.weekDays.forEach((day) => {
              if (day.index !== 0 && day.index !== 6) {
                let dayObj = weekStart.clone().add(day.index, "days");
                this.slotsBookingForm.value.timeTags.forEach((slot) => {
                  let currentDate = moment(this.selectedDate)
                  let slotStr = slot.split(":");
                  let updatedTime = currentDate.clone().set({
                    hour: slotStr[0],
                  });
                  let slotUtcDate = convertDateToUTC(this.selectedDate, parseInt(slotStr[0]), this.userTimezone?.offset || new Date().getTimezoneOffset())
                  let slotUtcStr = slotUtcDate.format("HH:mm");
                  let slotDateDay = slotUtcDate.format('D')
                  let currentDateDay = currentDate.format('D')
                  if (parseInt(slotDateDay) - parseInt(currentDateDay) == 0) {
                    let slotIndex = slotDates.findIndex(
                      (slotDate) => slotDate.day === day.day
                    );
                    slotDates[slotIndex].slots.push(slotUtcStr)
                    slotDates[slotIndex].slots = [...new Set(slotDates[slotIndex].slots)]
                  }
                  else {

                    slotDates && slotDates.map((d, i) => {
                      if (d.day == 'Sunday' && d.day !== 'Saturday' && d.day !== 'Monday') {
                        let dslots = d.slots ? [...d.slots] : []
                        dslots.push(slotUtcStr)
                        slotDates[i].slots = [...new Set(dslots)]
                      }
                      else if (d.day !== 'Sunday' && d.day !== 'Saturday' && d.day !== 'Friday') {

                        let dslots = d.slots ? [...d.slots] : []
                        dslots.push(slotUtcStr)
                        slotDates[i].slots = [...new Set(dslots)]
                      }
                      else {

                      }


                    })
                  }
                });

              }
            })
            // let slotsObjects = this.arrayToObject(slotDates)
            let injectedSlots = this.updateWeekdaySlotsObject(slotDates)
            reqPaylod = {
              recurrenceType: 'WEEKDAY_RECURRENCE',
              recurringWeeklySlots: { ...injectedSlots },
              slotsWithNoRecurrence: {
              },
              recurSlotsTillDate: moment(new Date(this.slotsBookingForm.value.repeatUntilDate)).format("DD_MM_YYYY"),
              selectedDate: moment(new Date(this.selectedDate)).format('DD_MM_YYYY')
            };
          }

          // if weekends than saturday and sunday
          // if(this.slotsBookingForm.value.repeatType=='Weekends'){
          //   this.weekDays.forEach((day)=>{
          //     if(day.index==0||day.index==6){
          //     let dayObj = weekStart.clone().add(day.index, "days");
          //     this.slotsBookingForm.value.timeTags.forEach((slot) => {
          //       let currentDate= moment(this.selectedDate)
          //       let slotStr = slot.split(":");
          //       let updatedTime=currentDate.clone().set({
          //         hour: slotStr[0],
          //       });
          //       let slotUtcDate = moment.utc(updatedTime);
          //         let slotUtcStr = slotUtcDate.format("HH:mm");
          //         let slotIndex = slotDates.findIndex(
          //           (slotDate) => slotDate.day === day.day
          //         );
          //         slotDates[slotIndex].slots.push(slotUtcStr)
          //     });

          //      reqPaylod = {
          //       startDate: moment(new Date(this.selectedDate)).utc().format("DD_MM_YYYY"),
          //       endDate:moment(new Date(this.slotsBookingForm.value.repeatUntilDate)).format("DD_MM_YYYY"),
          //       slots: slotDates,
          //     };
          //   } 
          //   })
          // }

          // if DAILY_RECURRENCE than add this slots to DAILY_RECURRENCE days of the week
          if (this.slotsBookingForm.value.repeatType == 'DAILY_RECURRENCE') {
            this.weekDays.forEach((day) => {
              this.slotsBookingForm.value.timeTags.forEach((slot) => {
                let currentDate = moment(this.selectedDate)
                let slotStr = slot.split(":");
                let slotUtcDate = convertDateToUTC(this.selectedDate, parseInt(slotStr[0]), this.userTimezone?.offset || new Date().getTimezoneOffset())
                let slotUtcStr = slotUtcDate.format("HH:mm");
                let slotDateDay = slotUtcDate.format('D')
                let currentDateDay = currentDate.format('D')
                if (parseInt(slotDateDay) - parseInt(currentDateDay) == 0) {
                  let slotIndex = slotDates.findIndex(
                    (slotDate) => slotDate.day === day.day
                  );
                  slotDates[slotIndex].slots.push(slotUtcStr)
                  slotDates[slotIndex].slots = [...new Set(slotDates[slotIndex].slots)]
                }
                else {
                  let utcDay = slotUtcDate.format('dddd')
                  let slotIndex = slotDates.findIndex(
                    (slotDate) => slotDate.day === utcDay
                  );
                  // push to all index if -5:30 utc 
                  slotDates && slotDates.map((d, i) => {
                    let dslots = d.slots ? [...d.slots] : []
                    dslots.push(slotUtcStr)
                    slotDates[i].slots = [...new Set(dslots)]
                  })
                  // slotDates[slotIndex].slots.push(slotUtcStr)
                  // slotDates[slotIndex].slots = [...new Set(slotDates[slotIndex].slots)]
                }
              });


            })
            let slotsObjects = this.arrayToObject(slotDates)
            reqPaylod = {
              recurrenceType: 'DAILY_RECURRENCE',
              recurringWeeklySlots: { ...slotsObjects },
              slotsWithNoRecurrence: {
              },
              recurSlotsTillDate: moment(new Date(this.slotsBookingForm.value.repeatUntilDate)).format("DD_MM_YYYY"),
              selectedDate: moment(new Date(this.selectedDate)).format('DD_MM_YYYY')
            };

          }

          if (this.slotsBookingForm.value.repeatType == 'WEEKLY_RECURRENCE') {
            let getIndex = null
            let selectedDateDay = moment(new Date(this.selectedDate)).format('dddd')
            slotDates && slotDates && slotDates.map((d, i) => {
              if (d.day == selectedDateDay) {
                getIndex = i
              }
            })
            this.weekDays.forEach((day) => {
              if (day.index == getIndex) {
                let dayObj = weekStart.clone().add(day.index, "days");
                this.slotsBookingForm.value.timeTags.forEach((slot) => {
                  let currentDate = moment(this.selectedDate)
                  let slotStr = slot.split(":");
                  let slotUtcDate = convertDateToUTC(this.selectedDate, parseInt(slotStr[0]), this.userTimezone?.offset || new Date().getTimezoneOffset())
                  let slotUtcStr = slotUtcDate.format("HH:mm");
                  let slotDateDay = slotUtcDate.format('D')
                  let currentDateDay = currentDate.format('D')
                  if (parseInt(slotDateDay) - parseInt(currentDateDay) == 0) {
                    let slotIndex = slotDates.findIndex(
                      (slotDate) => slotDate.day === day.day
                    );
                    slotDates[slotIndex].slots.push(slotUtcStr)
                  }
                  else {
                    let utcDay = slotUtcDate.format('dddd')
                    let slotIndex = slotDates.findIndex(
                      (slotDate) => slotDate.day === utcDay
                    );
                    slotDates[slotIndex].slots.push(slotUtcStr)
                  }
                });


              }
              else {

              }
            })
            let injectedSlots = this.updateWeeklySlotsObject(slotDates, selectedDateDay)
            reqPaylod = {
              recurrenceType: 'WEEKLY_RECURRENCE',
              recurringWeeklySlots: { ...injectedSlots },
              slotsWithNoRecurrence: {
              },
              recurSlotsTillDate: moment(new Date(this.slotsBookingForm.value.repeatUntilDate)).format("DD_MM_YYYY"),
              selectedDate: moment(new Date(this.selectedDate)).format('DD_MM_YYYY')
            };


          }
        }


        this.isLoading = true

        this.slotsService.createRecurringScheduleV2(reqPaylod, moment(new Date(this.startDate)).format('DD_MM_YYYY'), moment(new Date(this.endDate)).format('DD_MM_YYYY')).subscribe((res: object) => {
          // @ts-ignore
          if (res.success) {
            this.isLoading = false
            this.alertService.alert(
              // @ts-ignore
              new BootstrapAlert(res.message, "alert-success")
            );
            this.onSuccessCloseModal(res)
          }
        })
      }
      else {
        let reqPaylod = {
          recurrenceType: '',
          recurringWeeklySlots: {},
          slotsWithNoRecurrence: {},
          recurSlotsTillDate: '',
          selectedDate: moment(new Date(this.selectedDate)).format('DD_MM_YYYY')
        }


        if (this.slotsBookingForm.value.repeatType == 'WEEKDAY_RECURRENCE'
          || this.slotsBookingForm.value.repeatType == 'DAILY_RECURRENCE'
          || this.slotsBookingForm.value.repeatType == 'WEEKLY_RECURRENCE'
          && this.slotsBookingForm.value.isRepeatOptions) {
          let slotDates = [];
          this.weekDays.forEach((day) => {
            slotDates.push({
              day: day.day,
              slots: [],
            });
          })

          let slotsObjects = this.arrayToObject(slotDates)
          if (this.slotsBookingForm.value.repeatType == 'WEEKLY_RECURRENCE') {
            let date = moment(this.selectedDate)
            let day = date.format('dddd')
            slotsObjects = this.updateWeeklySlotsObject(slotDates, day)
          }
          if (this.slotsBookingForm.value.repeatType == 'WEEKDAY_RECURRENCE') {
            slotsObjects = this.updateWeekdaySlotsObject(slotDates)
          }
          reqPaylod = {
            recurrenceType: this.slotsBookingForm.value.repeatType,
            recurringWeeklySlots: { ...slotsObjects },
            slotsWithNoRecurrence: {},
            recurSlotsTillDate: this.slotsBookingForm.value.repeatUntilDate ? moment(new Date(this.slotsBookingForm.value.repeatUntilDate)).format("DD_MM_YYYY") : '',
            selectedDate: moment(new Date(this.selectedDate)).format('DD_MM_YYYY')
          }
        }
        else {
          // if (this.slotsBookingForm.value.repeatType == 'NO_RECURRENCE') {
          let formattedDate = moment(new Date(this.selectedDate)).format('DD_MM_YYYY')
          reqPaylod.recurrenceType = 'NO_RECURRENCE'
          reqPaylod.recurringWeeklySlots = {},
            reqPaylod.recurSlotsTillDate = ''
          reqPaylod.slotsWithNoRecurrence[formattedDate] = []
          reqPaylod.slotsWithNoRecurrence['before_' + formattedDate] = []

        }
        this.isLoading = true

        this.slotsService.createRecurringScheduleV2(reqPaylod, moment(new Date(this.startDate)).format('DD_MM_YYYY'), moment(new Date(this.endDate)).format('DD_MM_YYYY')).
          subscribe((res: object) => {
            this.isLoading = false
            this.alertService.alert(
              // @ts-ignore
              new BootstrapAlert(res.message, "alert-success")
            );
            this.onSuccessCloseModal(res)
          })
      }
    }
  }

  ngOnDestroy() {
  }
}
