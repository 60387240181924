import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const subscriptionUrl = environment.apiUrl + "/guest/subscribe";
const backendUrl = environment.apiUrl + "/guest/shortUrl/"

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http : HttpClient) { }

  subscribe(email){
    return this.http.get(subscriptionUrl + '/subscribe/' + email)
  }

  getFullUrl(url){
    return this.http.get(backendUrl+url)
  }
}
