import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { environment } from "src/environments/environment";
import { DiscussionForumService } from "../discussion-forum.service";
import clevertap from "clevertap-web-sdk";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';



@Component({
  selector: "app-answer-discussion",
  templateUrl: "./answer-discussion.component.html",
  styleUrls: ["./answer-discussion.component.css"],
})
export class AnswerDiscussionComponent implements OnInit {
  discussion;
  activeBtn = false;
  isLoaded = false;
  isLoading = false;
  comments = [];
  itemId: string;
  userId = null;
  imageUrl = environment.apiUrl;
  comment = new FormControl("");
  constructor(
    private actRoute: ActivatedRoute,
    private discussionService: DiscussionForumService,
    private alertService: BootstrapAlertService,
    private sidenavService: SideNavBarService
  ) { }
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit() {
    this.actRoute.queryParamMap.subscribe((params) => {
      this.itemId = params.get("discussionId");
      this.getDiscussion();
      this.getComments();
    });
    this.userId = localStorage.getItem("userId");
  }

  activeButton(event) {
    let data = event.trim();
    if (data.length > 0) {
      this.activeBtn = true;
    } else {
      this.activeBtn = false;
    }
  }

  closenavbar() {
    this.sidenavService.closesidenav()
  }

  addComment() {
    if (this.comment.value == "") { return }
    this.isLoading = true;
    this.activeBtn = false;
    this.activeButton(this.comment.value);
    let commentObj = {
      commentDescription: this.comment.value,
    };
    this.discussionService
      .postDiscussionComments(this.itemId, commentObj)
      .subscribe((data) => {
        if (data) {
          clevertap.event.push("Comment Added")
          this.alertService.alert(
            new BootstrapAlert("Comment added successfully", "alert-success")
          );
          this.getComments();
          this.isLoading = false;
          this.comment.setValue("");
        }
      });
  }
  toggleLike(id) {
    this.discussionService.toggleLikeOnDiscussion(id).subscribe((res) => {
      this.changeIsLiked(id);
    });
  }
  changeIsLiked(id) {
    if (this.discussion._id == id) {
      this.discussion["isLiked"] = !this.discussion["isLiked"];
      if (this.discussion["isLiked"]) {
        this.discussion.likes++;
      } else {
        this.discussion.likes--;
      }
    }
  }
  toggleLikeOnComment(id) {
    this.discussionService
      .toggleLikeOnDiscussionComment(id)
      .subscribe((res) => {
        this.changeIsLikedOnComment(id);
      });
  }
  changeIsLikedOnComment(id) {
    this.comments.forEach((comment, index) => {
      if (comment._id == id) {
        this.comments[index]["isLiked"] = !this.comments[index]["isLiked"];
        if (this.comments[index]["isLiked"]) {
          comment.likes++;
        } else {
          comment.likes--;
        }
      }
    });
  }
  getComments() {
    this.activeBtn = false;
    this.discussionService
      .getDiscussionComments(this.itemId)
      .subscribe((data) => {
        this.comments = [];
        this.comments = this.comments.concat(data);
        this.comments.forEach((comment, index1) => {
          this.comments[index1]["isLiked"] = false;
          if (this.userId != null) {
            comment.likedBy.forEach((like) => {
              if (this.userId == like) {
                this.comments[index1]["isLiked"] = true;
              }
            });
          }
        });
      });
  }

  getDiscussion() {
    this.discussionService.getForumById(this.itemId).subscribe((data) => {
      this.discussion = data;
      this.discussion["isLiked"] = false;
      if (this.userId != null) {
        this.discussion.likedBy.forEach((like) => {
          if (this.userId == like) {
            this.discussion["isLiked"] = true;
          }
        });
      }
      this.isLoaded = true;
    });
  }
}
