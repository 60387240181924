import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { Router } from "@angular/router";
import { FreeReportService } from "src/app/guest/free-report.service";
import { COMMON_DATA } from "src/app/shared/common";
import clevertap from "clevertap-web-sdk";
import { Options } from "@angular-slider/ngx-slider";
import { RegionSelectionService } from "src/app/region-selection.service";

@Component({
  selector: 'app-work-life-balance-test',
  templateUrl: './work-life-balance-test.component.html',
  styleUrls: ['./work-life-balance-test.component.css']
})
export class WorkLifeBalanceTestComponent implements OnInit {
  freeReportForm: FormGroup;
  userForm2: FormGroup;
  hideModal = true;
  firstPart: boolean = true;
  secondPart: boolean = false;
  currentIndex = 0;
  answers = COMMON_DATA.work_life_options;
  questions = COMMON_DATA.work_life_questions;
  progressPercentage = 0;
  userCountryCode = {
    countryCode: localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : "in",
    dialCode: localStorage.getItem("dialCode") ? localStorage.getItem("dialCode") : "91",
  };
  value: number;
  currentFormControlName = 'queNo1';
  options: Options = {
    floor: 1,
    ceil: 10,
    step: 1,
    showTicks: true,
    showTicksValues: true,
    showSelectionBar: true,
    stepsArray: [
      { value: 1, legend: 'Not at all ' },
      { value: 2 },
      { value: 3 },
      { value: 4, legend: 'Neutral' },
      { value: 5 },
      { value: 6 },
      { value: 7, legend: 'All the time' }
    ]
  };

  constructor(
    private reportService: FreeReportService,
    private router: Router,
    private sidenavService: SideNavBarService,
    private regionSelectionService: RegionSelectionService
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.initForms2();
    if (clevertap) {
      clevertap.event.push("Free Report Started", {
        "Report Name": "Work Life Balance"
      })
    }
  }
  checkError(valid) {
    if (!valid) {
      this.userForm2.controls["phone"].setErrors({ invalidPhone: true });
    }
  }

  getNumber(event) { }

  async telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
    let regionData = await this.regionSelectionService.returnRegionData();
    event.setCountry(String(regionData["regionCode"]).toLowerCase());
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }
  previousPage() {
    this.currentIndex--;
  }
  currentIndexInc() {
    this.currentIndex++;
    // console.log(this.freeReportForm.value);
    this.currentFormControlName = 'queNo2'
    // for(var i=1;i<=12;i++){
    //   var val = this.freeReportForm.controls['queNo'+i].value;
    //   this.freeReportForm.controls['queNo'+i].setValue(7 - (7 % val));
    //   console.log(val)
    // }
    // console.log(this.freeReportForm.value);
  }
  ShowHide = function () {
    this.firstPart = false;
    this.secondPart = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  instruction1 = function () {
    this.firstPart = true;
    this.secondPart = false;
  };
  showModal() {
    if (this.freeReportForm.invalid) {
      return;
    }
    this.userForm2.updateValueAndValidity();
    if(this.userForm2.valid) return this.onSubmit();
    this.hideModal = false;
  }

  onSubmit() {
    if (this.userForm2.invalid) {
      return;
    }
    let modObj = this.freeReportForm.value;
    var val = 0;
    for (var i = 1; i <= 12; i++) {
      val = modObj['queNo' + i]
      switch (val) {
        case 1:
          modObj['queNo' + i] = 7;
          break;
        case 2:
          modObj['queNo' + i] = 6;
          break;
        case 3:
          modObj['queNo' + i] = 5;
          break;
        case 4:
          modObj['queNo' + i] = 4;
          break;
        case 5:
          modObj['queNo' + i] = 3;
          break;
        case 6:
          modObj['queNo' + i] = 2;
          break;
        case 7:
          modObj['queNo' + i] = 1;
          break;
      }
    }
    let ansObj = {
      questionsAttempted: modObj,
      ...this.userForm2.value,
      countryCode: this.userCountryCode,
    };
    clevertap.profile.push({
      "Site": {
        "Name": this.userForm2.value.fullname,
        "Email": this.userForm2.value.email,
        "Phone": "+" + this.userCountryCode.dialCode + this.userForm2.value.phone
      }
    })
    this.reportService.generateReport(ansObj, "work-life-balance").subscribe((data) => {
      this.hideModal = true;
      this.router.navigate(["../free-report/work-life-balance-test-results/" + data["freeReportId"]]);
    });
  }

  initForms() {
    let group = {};
    this.questions.forEach((input_template, index) => {
      group[input_template["label"]] = new FormControl("", Validators.required);
    });
    this.freeReportForm = new FormGroup(group);
    this.freeReportForm.valueChanges.subscribe((data) => {
      let answers = 0;
      Object.values(data).forEach((value) => {

        if (value !== '') {
          answers++;
        }
      })
      // console.log("total answer",this.answers)
      this.progressPercentage = (answers / Object.values(data).length) * 100;
    })
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  initForms2() {
    this.userForm2 = new FormGroup({
      email: new FormControl(localStorage.getItem("email") ? localStorage.getItem("email") : "", [
        Validators.required,
        Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
      ]),
      phone: new FormControl(localStorage.getItem("phone") ? localStorage.getItem("phone") : "", Validators.required),
      fullname: new FormControl(localStorage.getItem("name") ? localStorage.getItem("name") : "", Validators.required),
    });
  }
  indexTracker(index: number, value: any) {
    return index;
  }

}
