<div class="container-fluid" (click)="closenavbar()">
  <div class="row">
    <div class="secondaryHeading">
      <h2>Anxiety Tests</h2>
      <hr />
      <p>
        Join our community and get access to exclusive content on mental
        wellness. <br />
        Take a Free test and get Your Report.
      </p>
    </div>
  </div>
  <div class="faquchaticon">
    <div class="row justify-content-center">
      <div class="col-sm-6 col-md-4 extraWidthSize">
        <a routerLink="../anxiety-test-gad" class="h3TagClass">
          <div class="contentBoxImg moreCss">
            <img
              src="../../../../assets/images/anxiety-gad-report-image.webp"
              class="img-fluid image"
              draggable="false"
              alt=""
            />
            <h3>Generalised Anxiety Disorder Assessment <br />(GAD-7)</h3>
            <p class="mrgnBtm">The GAD-7 is an easy self-report tool used to measure the severity of anxiety in general.</p>
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-md-4 extraWidthSize">
        <a routerLink="../anxiety-test-hamilton" class="h3TagClass">
          <div class="contentBoxImg moreCss">
            <img
              src="../../../../assets/images/anxiety-hamilton-report-image.webp"
              class="img-fluid image"
              draggable="false"
              alt=""
            />
            <h3>Hamilton Anxiety Rating Scale <br />(HAM-A)</h3>
            <p class="mrgnBtm">The HAM-A has been designed to measure the severity of Anxiety symptoms. </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<br />
