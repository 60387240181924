import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { environment } from "src/environments/environment";
import { DiscussionForumService } from "../discussion-forum.service";
import { ProfileService } from "../profile.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';



@Component({
  selector: "app-disscussion-forum",
  templateUrl: "./disscussion-forum.component.html",
  styleUrls: ["./disscussion-forum.component.css"],
})
export class DisscussionForumComponent implements OnInit {
  categories = [
    {
      name: "All Categories",
      _id: "",
    },
  ];
  items = [];
  pageNo = 1;
  pages = [1];
  showNext = false;
  totalCount = 0;
  paginObject = {};
  selectedCategory = "";
  isLoading = true;
  doLoadingShimmer = true;
  userId;
  imageUrl = environment.apiUrl;
  constructor(
    private discussionService: DiscussionForumService,
    private profileService: ProfileService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) { }
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit() {
    this.paginObject = {
      page: this.pageNo,
      perPage: 10,
    };
    this.getDiscussions();
    this.isLoading = false;
    this.discussionService.getCategories().subscribe((data) => {
      this.categories = this.categories.concat(data["category"]);
      this.doLoadingShimmer = false;
    });
    this.userId = localStorage.getItem("userId");
  }

  closenavbar() {
    this.sidenavService.closesidenav()
  }
  redirectTo(id, Mode) {
    this.router.navigateByUrl(
      "user-dashboard/userdiscussionform?discussionId=" + id + "&mode=" + Mode
    );
  }
  redirectToAnswer(id) {
    this.router.navigateByUrl(
      "user-dashboard/answerDiscussion?discussionId=" + id
    );
  }
  checkPage() {
    if (this.pageNo === this.pages[this.pages.length - 1]) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }
  }

  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;
    this.doLoadingShimmer = false;
    this.pageNo = this.pageNo + 1;
    this.paginObject["page"]++;
    this.getDiscussions();
    this.checkPage();
  }

  setPage(pageNo) {
    this.pageNo = pageNo;
    this.paginObject["page"] = this.pageNo;
    this.getDiscussions();
  }

  getDiscussions() {
    this.discussionService.getForums(this.paginObject).subscribe((data) => {
      this.items = this.items.concat(data["result"]);
      this.items.forEach((item, index1) => {
        this.items[index1]["isLiked"] = false;
        if (this.userId != null) {
          item.likedBy.forEach((like) => {
            if (this.userId == like) {
              this.items[index1]["isLiked"] = true;
            }
          });
        }
      });

      this.totalCount = data["totalCount"];
      let pageCount = Math.ceil(this.totalCount / 10);
      if (pageCount != this.pages.length) {
        for (let i = 0; i < pageCount - 1; i++) {
          if (this.pages.indexOf(i + 2)) {
            this.pages.push(i + 2);
          }
        }
      }
      this.checkPage();
      this.isLoading = false;
      this.doLoadingShimmer = false;
    });
  }

  setCategory(id) {
    this.items = [];
    if (id == "") {
      this.selectedCategory = "";
      delete this.paginObject["tags"];
      this.getDiscussions();
    } else {
      this.selectedCategory = id;
      this.paginObject["tags"] = id;
      this.getDiscussions();
    }
  }
}
