import { Component, OnInit } from "@angular/core";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { TherapistHelpdeskService } from "../therapist-helpdesk.service";

@Component({
  selector: "app-helpdesk",
  templateUrl: "./helpdesk.component.html",
  styleUrls: ["./helpdesk.component.css"],
})
export class HelpdeskComponent implements OnInit {
  constructor(
    private confirmationService: ConfirmationDialogService,
    private helpdeskService: TherapistHelpdeskService
  ) {}

  ngOnInit(): void {}

  openChat() {
    window["$zoho"].salesiq.floatbutton.visible("show");
    let chatElem = document.getElementById('zsiq_agtpic')
		chatElem.click();
  }
  requestCallback() {
    this.confirmationService
      .confirm(
        "Are you sure?",
        "You will get a callback from us if you continue",
        "Continue",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          this.helpdeskService.requestCallback().subscribe((data) => {
          });
        }
      });
  }
}
