<div class="topMenuMargin" (click)="closenavbar()">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <strong><i class="fa fa-exclamation-triangle"></i> Disclamer: </strong>This tool should be used for
            screening and
            monitoring symptom severity and cannot replace a clinical assessment and
            diagnosis. You are encouraged to share these results with a mental health
            professional for a better understanding.
        </p>
        <br>
        <div class="report-user">
            <h3>
                Getting to know yourself is the first step towards a mentally healthier
                life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <h3>Congratulations on taking that first step!</h3>
            <p class="mt-2"><strong>Your result:</strong></p>
            <p>Academic expectations stress = {{expectationScore}}</p>
            <p>Workload and examination stress = {{workloadScore}}</p>
            <p>Academic self-perception stress = {{perceptionScore}}</p>
            <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
        </div>
    </div>

    <!-- TODO: Add CSS -->
    <br />
    <div class="container about-scores">
        <div class="mt-4 mb-4">
            <h5 class="mb-3">Detailed Analysis</h5>
            <p class="mb-3">This scale is further made up of 4 subscales: <strong>Pressures to
                    Perform</strong>, <strong>Perceptions of Workload</strong>, <strong>Academic
                    Self-Perceptions</strong>, and <strong>Time Restraints</strong>.
            </p>

            <p class="mb-3"><strong>Pressures to Perform: </strong>The component includes any excessive
                demands from competitive peer pressure, parental expectations, and teacher evaluations of students'
                performance. It’s easy to get lost in the rat race, constantly trying to perform better than others,
                constantly improving to get something to be perfect. Listening to others’ critique about our own
                performance can also lead to putting excessive pressure on ourselves, which does not bode well in the
                long term. An optimal amount of stress may keep you going, and might even be useful in getting work done
                - but being under pressure for long periods of time only does more harm than good. Avoid trying to reach
                perfection, since doing that will only set you up for failure no matter how hard you try to succeed. The
                intense competition and your parents’ or guardians’ desires for you to be ‘successful’ also contribute
                immensely to the pressure you may feel. Instead of focusing on what others have to say, define what
                success looks like to you - this will not only help you figure out what you really want to do, but also
                might make you realise what you’re good at, and working with your parents or guardians to explain your
                goals to them will improve your relationship with them as well.</p>

            <p class="mb-3"><strong>Perceptions of Workload: </strong>This refers to pressures brought on by
                a heavy workload, extended assignments, and concerns about failing exams. Heavy academic workload can
                lead to anxiety if the stress builds up over long periods, and even burnout. If the workload - including
                extracurricular activities - is too much for the individual to bear, this has implications for their
                overall quality of life as well. Keeping a busy schedule and wanting to fit in all club activities,
                jobs, and academic workload might sound like a perfect start to a university freshman, however, managing
                so many things in your life alongside the growing difficulty of information learned in classes isn’t as
                easy as it seems. Taking the time to first adjust to your priorities - classwork - and then working
                around the time you have remaining after you dedicate sufficient time to this work, is something you can
                do to decrease your stress if your workload is too much. If the class is structured in a way that it
                inherently has way too many readings and assignments to complete, then focus on what’s difficult and get
                that out of the way before you begin on anything else. Managing your mental resources in this way will
                benefit in the long run.</p>

            <p class="mb-3"><strong>Academic Self-Perceptions: </strong>This aspect refers to an
                individual's self-confidence in their academic abilities, willingness and ability to succeed as a
                student, and confidence in their ability to succeed in their future careers. Your perception of how well
                you can perform as a student can influence academic performance. Those with a low academic self-concept
                may undervalue their talents, abilities, and aptitude, which may lead to rejecting opportunities that
                are presented to them or avoiding situations that may challenge them and make them anxious.
                Consequently, those with a high academic self-concept have been found to display better academic
                performance, and vice-versa. Learning is necessary to perform well in anything, and if you display
                characteristics of low academic self-concept, then that would translate to you not believing in your
                ability to learn content and perform well when it comes to graded components of any course. Your
                perception of yourself has repercussions on the effort you put into learning something new. Sometimes,
                it may be hard for one to have faith in themselves especially when faced with a subject that they
                dislike - in that case, try to reward even the smallest of achievements you might have made in a
                particular course. Keep in mind that your academic journey might look different than someone else’s, and
                so, refrain from comparing yourself to others. Identify where you lack, and try and make efforts to
                improve there: whether by getting feedback from your teachers, help from your friends or TAs, and
                remember that one bad grade does not define who you are as a person - let go of any unrealistic
                expectations that you may set on yourself.</p>

            <p class="mb-3"><strong>Time Restraints: </strong>This component refers to stress as a result of
                limited time allocated to classes, inability to finish homework, the difficulty to catch up if behind,
                and the limited time to wind up or relax. One of the most precious things to have when pursuing higher
                education is time. Developing time management skills is one of the best things you can do to keep
                yourself above ground when dealing with tons of responsibilities, from academics to extracurriculars,
                side jobs and having successful interpersonal relationships. Procrastination is a major problem that
                leads to academic stress - spending time on things that are not a priority will cause other, more
                important tasks to be pushed back, and the culmination of this would be a bad academic performance.
                Exercising control over your time by giving priority to things that need to be done, and doing them in a
                timely manner, will give you opportunities to explore your other interests without the fear of being
                left behind on classwork.</p>
        </div>
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>

    <!-- <div class="disclaimer-section">
      <div class="head-disclaimer">
        <h3>Disclaimer</h3>
        <p>
          This tool should be used for screening and monitoring symptom severity
          and cannot replace a clinical assessment and diagnosis. You are
          encouraged to share these results with a mental health professional.
          Felicity disclaims any liability, loss or risk incurred as a consequence
          , directly or indirectly, from the use of this assessment & it's
          results.
        </p>
      </div>
    </div> -->
</div>