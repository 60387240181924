import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { COMMON_DATA } from "src/app/shared/common";
import { HelpDeskService } from "../help-desk.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: "app-raise-new-request",
  templateUrl: "./raise-new-request.component.html",
  styleUrls: ["./raise-new-request.component.css"],
})
export class RaiseNewRequestComponent implements OnInit {
  grievance = COMMON_DATA.userHelpDesk;
  isSessionReport = false;
  isLoading = false;
  sessionId = ''

  constructor(
    private helpdeskService: HelpDeskService,
    public router: Router,
    private confirmationService: ConfirmationDialogService,
    private actRoute: ActivatedRoute,
  ) {}
  raiseNewRequest = new FormGroup({
    grievance: new FormControl("", Validators.required),
    description: new FormControl("", Validators.required),
  });
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    this.actRoute.queryParamMap.subscribe((params) => {
      if(params.get('report') === 'session'){
        this.isSessionReport = true
        this.sessionId = params.get('sessionId')
        this.raiseNewRequest.patchValue({
          grievance : 'Session Related'
        })
        this.raiseNewRequest.controls['grievance'].disable()
      }
    })
  }

  onSubmit() {
    if (this.raiseNewRequest.invalid) {
      return;
    }
    this.confirmationService
      .confirm(
        "Are you sure?",
        "This will raise a new request",
        "Yes, Proceed",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          let reqObj = {
            ...this.raiseNewRequest.value,
          }
          this.isLoading = true;
          if(this.isSessionReport){
            reqObj['SessionId'] = this.sessionId
          }
          this.helpdeskService
            .createRequest(reqObj)
            .subscribe((data) => {
              this.isLoading = false;
              this.router.navigate(["user-dashboard/viewRequest"]);
            }, (err) => {
              this.isLoading = false;
            });
          } else {
            this.isLoading = false;
          }
        });
    }
  }
