import { Component, OnInit } from '@angular/core';
import { SideNavBarService } from '../side-nav-bar.service';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.css']
})
export class WhatsappChatComponent implements OnInit {
  showWhatsappChat: boolean = true;
  constructor(private sideNavBarService: SideNavBarService) { }

  ngOnInit(): void {
    this.sideNavBarService.whatsappChatListener().subscribe((data) => {
      this.showWhatsappChat = data;
    })
  }

}
