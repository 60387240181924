import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { SideNavBarService } from "../side-nav-bar.service";
import { TherapistAuthService } from "../therapist-auth/therapist-auth.service";
import clevertap from "clevertap-web-sdk";
import { ProfileService } from "../user/profile.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private authListenerSubs: Subscription;
  userIsAuthenticated = false;
  sideNav = false;
  private therapistAuthListenerSubs: Subscription;
  therapistIsAuthenticated = false;
  private sidenavListener: Subscription;
  base_url = environment.base_url;
  isDashboardRedirect: boolean;
  constructor(
    public authService: AuthService,
    public therapistAuthService: TherapistAuthService,
    private sidenavService: SideNavBarService,
    private userService: ProfileService
  ) {}

  ngOnInit(): void {
    this.userIsAuthenticated = this.authService.getIsAuth();
    if(this.userIsAuthenticated){
      this.userService.getBookingAvailable().subscribe((res) => {
        let plans = res["bookingAvailable"];
        this.isDashboardRedirect = plans > 0;
      })
    }
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.userIsAuthenticated = isAuthenticated;
      });
    this.sidenavListener = this.sidenavService
      .sideNavBarListener()
      .subscribe((isOpen) => {
        this.sideNav = isOpen;
      });
    this.therapistIsAuthenticated = this.therapistAuthService.getIsAuth();
    this.authListenerSubs = this.therapistAuthService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.therapistIsAuthenticated = isAuthenticated;
      });
  }

  getStarted(){
    if(clevertap){
      clevertap.event.push("Get Started - Header")
    }
  }

  switchSideNav() {
    this.sideNav = !this.sideNav;
  }
  closeNavBar() {
    this.sidenavService.closesidenav();
  }
  ngOnDestroy() {
    if (this.authListenerSubs) {
      this.authListenerSubs.unsubscribe();
    }
    if (this.therapistAuthListenerSubs) {
      this.therapistAuthListenerSubs.unsubscribe();
    }
  }

  onLogout() {
    if (this.userIsAuthenticated) {
      this.authService.logout();
    }
    if (this.therapistIsAuthenticated) {
      this.therapistAuthService.logout();
    }
  }
}
