import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import clevertap from "clevertap-web-sdk";
import { FreeReportService } from '../../free-report.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-personality-assessment-test-results',
  templateUrl: './personality-assessment-test-results.component.html',
  styleUrls: ['./personality-assessment-test-results.component.css']
})
export class PersonalityAssessmentTestResultsComponent implements OnInit {
  PersonalityFinal = "";
  PersonalityScore;
  itemId;
  report;
  subScore1 = 0;
  subScore2 = 0;
  subScore3 = 0;
  subScore4 = 0;
  subScore5 = 0;
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {}
  answersArray: any[]
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.PersonalityScore = res["report"]["reportDetails"]["score"];
        let answersArray = Object.values(res['report']["reportDetails"]["answers"]);
        this.subScore1 = Number(answersArray[0]) + Number(answersArray[1]) + Number(answersArray[2])
        this.subScore2 = Number(answersArray[3]) + Number(answersArray[4]) + Number(answersArray[5])
        this.subScore3 = Number(answersArray[6]) + Number(answersArray[7]) + Number(answersArray[8]) 
        this.subScore4 = Number(answersArray[9]) + Number(answersArray[10]) + Number(answersArray[11])
        this.subScore5 = Number(answersArray[12]) + Number(answersArray[13]) + Number(answersArray[14])
        if(clevertap){
          clevertap.event.push("Free Report Ended", {
            "Report Name" : "Personality",
            "Score" : this.PersonalityScore
          })
        }
      });
    });
  }
  reportData() {
    let reqObj = {
      id : this.itemId,
      reportType : 'personality'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if(res){
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }
}
